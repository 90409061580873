import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ProductVariantsContext } from "../../../../../package/src/context/ProductVariantsContext";
import svgEdit from '../../../../../icons/variantEditor/edit.svg';
import svgDelete from '../../../../../icons/variantEditor/delete-red.svg';
import ButtonIconSimple from "./ButtonIconSimple";
import DialogMessages from "../../../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../../../UI/Dialog/DialogButtons";
import CustomButton from "../../../../../UI/Button";
import Dialog from "../../../../../UI/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import archiveProductVariants from "../../../graphql/mutations/archiveProductVariants";

const Wrapper = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const VariantEditDelete = ({ shopId, refetchMedia }) => {
  const [deleting, setDeleting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { onSetOpenEditor, variants, onRefetch, onSetVariants, onSetAttributeValues } = useContext(ProductVariantsContext);

  const handleCloseDialog = () => {
    if (!deleting) {
      setOpenDialog(false);
    }
  };

  const handleDeleteVariants = () => {
    setDeleting(true);

    apolloClient.mutate({
      mutation: archiveProductVariants,
      variables: {
        input: {
          shopId,
          variantIds: variants.map((k) => k.id),
        }
      }
    })
      .then(() => {
        enqueueSnackbar('Successfully removed variants', {variant: 'success'});
        onSetVariants([]);
        onSetAttributeValues([]);
        onRefetch([]);
        refetchMedia();
        setDeleting(false);
        setOpenDialog(false);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
        setDeleting(false);
      });
  };

  return (
    <Wrapper>
      <ButtonIconSimple
        picSrc={svgEdit}
        text='Edit'
        onClick={() => onSetOpenEditor(true)}
      />
      <ButtonIconSimple
        picSrc={svgDelete}
        onClick={() => setOpenDialog(true)}
      />

      <Dialog
        open={openDialog}
        handleClose={handleCloseDialog}
      >
        <DialogMessages
          title='Are you sure you want to delete all product variants?'
        />
        <DialogButtons justifyButtons='center'>
          <CustomButton
            width='166px'
            mytype='secondary'
            disabled={deleting}
            handleClick={handleCloseDialog}
          >
            {t('ui.cancel')}
          </CustomButton>
          <CustomButton
            width='166px'
            disabled={deleting}
            handleClick={handleDeleteVariants}
          >
            {t('ui.delete')}
          </CustomButton>
        </DialogButtons>
      </Dialog>
    </Wrapper>
  );
};

export default VariantEditDelete;
