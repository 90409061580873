import React from 'react';
import {MenuItem, makeStyles} from '@material-ui/core';
import {colors} from '../../constants';

const useStyles = makeStyles(() => ({
  root: {
    background: 'white',
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    color: colors.black,
    height: 42,

    '&:hover': {
      background: colors.white8,
    },
  }
}))

const CustomMenuItem = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <MenuItem 
      className={classes.root}
      {...props}
      ref={ref}
    >
      {props.children}
    </MenuItem>
  );
})

export default CustomMenuItem;
