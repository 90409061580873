import gql from "graphql-tag";

export default gql`
  query bulkProductExport($shopId: ID!) {
    bulkProductExport(shopId: $shopId) {
      products {
        externalId
        isVisible
        title
        subtitle
        shortDescription
        longDescription
        purchaseNotes
        isPublished
        facebookShareMsg
        twitterShareMsg
        directShareMsg
        tags
        brands
        categories
        relatedProducts
        imageIds
        variants {
          externalId
          isVisible
          attributeLabel
          attributeShortTitle
          fullTitle
          sku
          originCountry
          width
          length
          height
          weight
          price
          originalPrice
          inventoryInStock
          canBackorder
          imageIds
        }
      }
      totalCount
    }
  }
`;
