import gql from "graphql-tag";
import { Company } from "./fragments";

export const addCompany = gql`
  mutation addCompany($input: AddCompanyInput!) {
    addCompany(input: $input) {
      ${Company}
    }
  }
`;

export const updateMerchant = gql`
  mutation updateCompany($id: ID!, $input: UpdateCompanyInput!) {
    updateCompany(id: $id, input: $input) {
      ${Company}
    }
  }
`;

export const removeMerchant = gql`
  mutation remove($id: ID!) {
    removeCompany(id: $id) {
      ${Company}
    }
  }
`;

export const setCompanySubscriptionOverrides = gql`
  mutation setCompanySubscriptionOverrides(
    $id: ID!,
    $rateOverride: OverrideByPercentageInput,
    $commissionOverride: OverrideByNewValueInput,
  ) {
    setCompanySubscriptionOverrides(
      id: $id,
      rateOverride: $rateOverride,
      commissionOverride: $commissionOverride
    ) {
      rate {
        changePercentage
        startDate
        endDate
        disabled
        notes
      }
      commission {
        newValue
        startDate
        endDate
        disabled
        notes
      }
    }
  }
`;
