import React, { useEffect, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid, Tab } from "@material-ui/core";
import DateRangeSelector from "../../../package/src/DateRangeSelector";
import MerchantSelectorWithData from "../../../package/src/MerchantSelectorWithData";
import Totals from "../components/Totals";
import Orders from "../components/Orders";
import Subscriptions from "../components/Subscriptions";
import Transactions from "../components/Transactions";
import FormHeader from "../../../UI/Form/FormHeader";
import { useTranslation } from "react-i18next";
import TabsSmall from "../../../UI/Tabs/TabsSmall";

const FinancialsPage = ({viewer, history}) => {
  const godmode = viewer.type === "godmode";
  const { t } = useTranslation();

  const [reload, setReload] = useState(false);
  const [dateRange, setDateRange] = useState({startDate: null, endDate: null});
  const [selectedMerchants, setSelectedMerchants] = useState({
    selectedCompanyIds: !godmode ? [viewer.companyId] : [],
    selectedShopIds:
      !godmode
        ? [
          ...viewer.adminUIShops
            .filter(shop => shop.company._id === viewer.companyId)
            .map(shop => shop._id),
        ]
        : [],
  });
  const [tabValue, setTabValue] = useState('orders');

  const refetch = () => {
    setReload(prev => !prev);
  }

  useEffect(() => {
    refetch();
  }, [dateRange, selectedMerchants])

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Toolbar title={t('finance.balance')} />

      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <MerchantSelectorWithData
            shouldShowShopName
            viewer={viewer}
            selectedCompanyIds={selectedMerchants.selectedCompanyIds}
            onMerchantSelectionChange={setSelectedMerchants}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DateRangeSelector
            placeholder={t('finance.choose_period')}
            dateRange={dateRange}
            onChange={setDateRange}
          />
        </Grid>
      </Grid>

      <Totals
        dateRange={dateRange}
        companyIds={selectedMerchants.selectedCompanyIds}
        viewerType={viewer.type}
        reload={reload}
      />

      {
        godmode ?
          // Admin layout
          <>
            <TabsSmall
              value={tabValue}
              onChange={handleTabChange}
              minwidth='150px'
              style={{marginTop: 50}}
            >
              <Tab label={t('finance.orders')} value='orders'/>
              <Tab label={t('finance.subscriptions')} value='subs'/>
              <Tab label={t('finance.transactions')} value='trans'/>
            </TabsSmall>

            {
              tabValue === 'orders' &&
              <Orders
                dateRange={dateRange}
                shopIds={selectedMerchants.selectedShopIds}
                companyIds={selectedMerchants.selectedCompanyIds}
                viewer={viewer}
                history={history}
              />
            }

            {
              tabValue === 'subs' &&
              <Subscriptions
                dateRange={dateRange}
                companyIds={selectedMerchants.selectedCompanyIds}
                viewerType={viewer.type}
              />
            }

            {
              tabValue === 'trans' &&
              <Transactions
                dateRange={dateRange}
                companyIds={selectedMerchants.selectedCompanyIds}
                viewer={viewer}
              />
            }
          </> :

          // Merchant layout
          <>
            <FormHeader margin='48px 0 24px 0'>{t('finance.orders')}</FormHeader>
            <Orders
              dateRange={dateRange}
              shopIds={selectedMerchants.selectedShopIds}
              companyIds={selectedMerchants.selectedCompanyIds}
              viewer={viewer}
              history={history}
            />
          </>
      }
    </>
  );
}

export default FinancialsPage;
