import { createContext, useEffect, useMemo, useState } from "react";

export const LanguageContext = createContext({
  isRtl: false,
  language: 'en',
  onChangeLanguage: (val) => {},
});

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const saved = localStorage.getItem('i18nextLng');
    if (saved) {
      if (saved === 'ar') {
        document.body.dir = 'rtl';
      } else {
        document.body.dir = 'ltr';
      }
      setLanguage(saved);
    }
  }, []);

  const onChangeLanguage = (val) => {
    setLanguage(val);
  }

  const value = useMemo(() => {
    return {
      language,
      isRtl: language === 'ar',
      onChangeLanguage,
    }
  }, [
    language,
    onChangeLanguage,
  ])

  return (
    <LanguageContext.Provider value={value}>
      { children }
    </LanguageContext.Provider>
  )
};
