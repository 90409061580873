import moment from "moment";

/**
 * Spits the data in CSV format. Note that since the server might send more data
 * than the user is looking to display, we filter out the data that aren't in `fields`
 * @param {*} fields The fields passed by the user to display in Displayer datatable
 * @param {*} data All the data fetched from server after having been formatted according to user's needs
 * @param {*} fileName Name of csv file. Deduced automatically from graphql `__typename` if not passed
 */
const downloadCSV = (fields, data, fileName) => {
  // Specifies how to handle null/undefined values
  const replacer = (key, value) => (value === null || value === undefined ? "" : value);

  const headers = fields.map(field => field.name);
  const headerLabels = fields.map(field => field.label);

  if (!fileName) {
    // Try to infer it by finding `__typename` inside the data
    fileName = `${data[0]?.__typename}s`;
  }

  const csv = [
    headerLabels.join(","), // headers row first
    ...data.map(row => {
      return headers
        .map(fieldName => {
          return JSON.stringify(row[fieldName], replacer)?.replace(/\\"/g, '""');
        })
        .join(",");
    }),
  ].join("\r\n");

  const download = (content, mimeType, filename) => {
    let a = document.createElement("a");
    const blob = new Blob([content], { type: mimeType });
    const url = URL.createObjectURL(blob);
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    a.click();
  };

  download(csv, "text/csv", `${fileName} - ${moment().format("MM/DD/YYYY hh:mm:ss")}.csv`);
};

export default downloadCSV;
