import React, { forwardRef, useContext, useState } from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import InputErrorMessage from "./InputErrorMessage";
import { LanguageContext } from "../../package/src/context/LanguageContext";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const errorCss = css`
  background-color: ${colors.redLight10};
  border: 1px solid ${colors.redLight};

  & .Mui-focused {
    background-color: transparent;
  }
`;

const removeScrollbar = css`
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const StyledTextField = styled(TextField)`
  &.MuiFormControl-root {
    background-color: ${colors.inputNormal};
    border-radius: 21px;
    height: ${props => (props.multiline ? "auto" : "42px")};
    width: 100%;
    margin-bottom: 24px;
  }
  
  & .MuiInputBase-root {
    height: ${props => (props.multiline ? "auto" : "42px")};
    overflow-y: scroll;
    max-height: ${props => (props.multiline ? "auto" : "200px")};
    padding-right: 22px;

    input {
      padding: 0 10px 0 22px;
    }
    
    textarea {
      padding-left: 8px;
      ${removeScrollbar}
    }
    
    ${removeScrollbar}
  }

  & .Mui-focused {
    background-color: ${colors.inputFocus};
    border-radius: 21px;
  }

  fieldset {
    border: none;
  }
  
  ${props => props.error && errorCss}

  ${props =>
    props.custommargin &&
    css`
      &.MuiFormControl-root {
        margin: ${props.custommargin};
      }
    `}

  ${props =>
    props.isrtl === "true" &&
    css`
      & .MuiInputBase-root {
        padding-right: 0;
        padding-left: 22px;
      }

      input {
        padding: 0 22px 0 10px !important;
      }

      textarea {
        padding-left: 0;
        padding-right: 8px;
      }
    `}
`;

const FormInput = forwardRef(({ error, type, margin, marginError, ...other }, ref) => {
  const { isRtl } = useContext(LanguageContext);
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const getInputType = () => {
    if (type) {
      if (type === "password") {
        return showPassword ? "text" : "password";
      }

      return type;
    }

    return "text";
  };

  return (
    <>
      <StyledTextField
        error={Boolean(error)}
        custommargin={margin}
        {...other}
        type={getInputType()}
        variant="outlined"
        isrtl={String(isRtl)}
        ref={ref}
        inputProps={{
          autoComplete: "off",
        }}
        InputProps={{
          endAdornment: type === "password" && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {error ? (
        <InputErrorMessage marginerror={marginError}>{t(error.message)}</InputErrorMessage>
      ) : null}
    </>
  );
});

export default FormInput;
