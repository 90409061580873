import gql from "graphql-tag";

export default gql`
  mutation updateRelatedProducts($productId: ID!, $shopId: ID!, $relatedProductIds: [ID]!) {
    updateRelatedProducts(productId: $productId, shopId: $shopId, relatedProductIds: $relatedProductIds){
      _id
      relatedProducts {
       _id
      }
    }
  }
`;
