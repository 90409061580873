export const PlacesOnStreet = `
  placesOnStreets {
    street {
      id
      name
      places {
       id
      }
    }
    places {
      id
    }
  }
`
