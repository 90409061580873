export const generateLink = (tableData, rowId) => {
  const base = process.env.REACT_APP_PUBLIC_UI_URL;
  const result = {};

  const places = tableData.find(item => item._id === rowId)?.subscriptionPackage.placesOnStreets || [];

  for (let i = 0; i < places.length; i++) {
    const index = places[i].places.findIndex(k => k.holder?.holderId === rowId);

    if (index > -1) {
      result.streetId = places[i].street.id;
      result.placeId = places[i].places[index].id;
      break;
    }
  }

  return base + '/wosq?streetId=' + result.streetId + '&placeId=' + result.placeId + '&shopId=' + rowId;
}
