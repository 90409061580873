import moment from 'moment';

/**
 * @method formatDateRangeFilter
 * @private
 * @summary Determine a date range given a keyword such at `today`, `last7`.
 * @param {String} dateRangeKeyword - a date range keyword
 * @returns {Object} a object representing a date range filter
 * */
export function formatDateRangeFilter(dateRangeKeyword) {
  const dateRange = {};

  switch (dateRangeKeyword) {
    case "today":
      dateRange.gte = moment()
        .startOf("day")
        .toISOString();
      dateRange.lte = moment()
        .endOf("day")
        .toISOString();
      break;
    case "last7":
      dateRange.gte = moment()
        .subtract(7, "days")
        .toISOString();
      dateRange.lte = moment().toISOString();
      break;
    case "last30":
      dateRange.gte = moment()
        .subtract(30, "days")
        .toISOString();
      dateRange.lte = moment().toISOString();
      break;
    default:
      // Default to today's date range
      dateRange.gte = moment()
        .startOf("day")
        .toISOString();
      dateRange.lte = moment()
        .endOf("day")
        .toISOString();
      break;
  }

  return dateRange;
}

/**
 * @method isPaymentRiskElevated
 * @private
 * @summary Gets the riskLevel on each payment object of an order, and checks if any payment has an elevated risk.
 * @param {Object} order - order object
 * @param {Array} paymentIds - paymentIds to check
 * @returns {boolean} is there an elevated risk level value for any payment on this order?
 * */
export function isPaymentRiskElevated(order, paymentIds) {
  const paymentsToAssessRisk = (order.payments || []).filter(
    payment => paymentIds.includes(payment._id) && payment.mode !== "captured"
  );
  const isPaymentNormalRisk = paymentsToAssessRisk.every(
    payment => !payment.riskLevel || payment.riskLevel === "normal"
  );

  return !isPaymentNormalRisk;
}
