import React, { useCallback, useContext, useMemo, useState } from "react";
import { Grid, Hidden, Radio, makeStyles, RadioGroup } from "@material-ui/core";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "../../../package/src";
import { useTranslation } from "react-i18next";
import Toolbar from "../../../package/src/Toolbar";
import Dropdown from "../../../UI/Dropdown";
import Search from "../../../UI/Search/Search";
import DataTable from "../../../UI/DataTable/DataTable";
import StyledButton from "../../../UI/Button/Button";
import { constants, standardPageStyles } from "../../../constants";
import { modifyFilters } from "../helpers/modifiers";
import ordersQuery from "../graphql/queries/orders";
import svgEdit from "../../../icons/table-edit.svg";
import MenuItem from "../../../UI/MenuItem";
import FormControlLabel from "../../../UI/FormControlLabel";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import toggleArrayValues from "../../../package/src/utils/toggleArrayValues";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import svgRefund from "../../../icons/refund.svg";
import svgDelete from "../../../icons/delete.svg";
import Dialog from "../../../UI/Dialog/Dialog";
import DialogRefund from "../dialogs/OrderRefunds";
import DialogCancelOrder from "../dialogs/DialogCancelOrder";
import getShopQuery from "../graphql/queries/getShop";
import orderByReferenceId from "../graphql/queries/orderByReferenceId";
import { LanguageContext } from "../../../package/src/context/LanguageContext";
import { useOrderColumns } from "../hooks/useOrderColumns";
import useModifiers from "../hooks/useModifiers";

const useStyles = makeStyles(theme => ({
  ...standardPageStyles,
}));

const defaultFilters = {
  fulfillmentStatus: [],
  paymentStatus: [],
  status: '',
  createdAt: '',
};

const OrdersTable = ({ selectedShopIds: shopIds, viewer, history }) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { isRtl } = useContext(LanguageContext);
  const rtl = isRtl ? 'Rtl' : '';
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [reloadTable, setReloadTable] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [exporting, setExporting] = useState(false);
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [order, setOrder] = useState({});
  const columns = useOrderColumns();
  const { modifyData } = useModifiers();

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, filtersByKey }) => {
    if (!Array.isArray(shopIds) || shopIds.length === 0) {
      return;
    }

    setIsLoading(true);

    const filters = modifyFilters(filtersByKey);

    apolloClient.query({
      query: ordersQuery,
      variables: {
        shopIds,
        first: pageSize,
        offset: pageIndex * pageSize,
        filters: {
          searchField: globalFilter,
          ...filters,
        },
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        // Update the state with the fetched data as an array of objects and the calculated page count
        const total = res.data.orders.totalCount;
        setTableData(res.data.orders.nodes);
        setPageCount(Math.ceil(total / pageSize));
        setTotalCount(total);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [viewer])

  const handleFilterRadio = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleFilterCheckbox = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: toggleArrayValues(prev[field], value),
    }))
  }

  const filtersList = useMemo(() => [
    {
      isComplex: true,
      content: t('orders.status'),
      MenuItemsList: <RadioGroup
        onChange={(e) => handleFilterRadio('status', e.target.value)}
        value={filters.status}
      >
        <MenuItem><FormControlLabel value="completed" control={<Radio />} label={t('orders.completed')} /></MenuItem>
        <MenuItem><FormControlLabel value="canceled" control={<Radio />} label={t('orders.canceled')} /></MenuItem>
        <MenuItem><FormControlLabel value="new" control={<Radio />} label={t('orders.new')} /></MenuItem>
        <MenuItem><FormControlLabel value="processing" control={<Radio />} label={t('orders.processing')} /></MenuItem>
      </RadioGroup>
    },
    {
      isComplex: true,
      content: t('orders.payment'),
      itemsList: [
        {
          content: <FormControlLabel control={
            <Checkbox
              checked={filters.paymentStatus.includes('completed')}
              onChange={() => handleFilterCheckbox('paymentStatus', 'completed')}
            />
          } label={t('orders.completed')} />,
        },
        {
          content: <FormControlLabel control={
            <Checkbox
              checked={filters.paymentStatus.includes('created')}
              onChange={() => handleFilterCheckbox('paymentStatus', 'created')}
            />
          } label={t('orders.created')} />,
        },
      ],
    },
    {
      isComplex: true,
      content: t('orders.fulfillment'),
      itemsList: [
        {
          content: <FormControlLabel control={
            <Checkbox
              checked={filters.fulfillmentStatus.includes('processing')}
              onChange={() => handleFilterCheckbox('fulfillmentStatus', 'processing')}
            />
          } label={t('orders.processing')} />,
        },
        {
          content: <FormControlLabel control={
            <Checkbox
              checked={filters.fulfillmentStatus.includes('completed')}
              onChange={() => handleFilterCheckbox('fulfillmentStatus', 'completed')}
            />
          } label={t('orders.completed')} />,
        },
        {
          content: <FormControlLabel control={
            <Checkbox
              checked={filters.fulfillmentStatus.includes('new')}
              onChange={() => handleFilterCheckbox('fulfillmentStatus', 'new')}
            />
          } label={t('orders.new')} />,
        },
      ],
    },
    {
      isComplex: true,
      content: t('orders.date'),
      MenuItemsList: <RadioGroup
        onChange={(e) => handleFilterRadio('createdAt', e.target.value)}
        value={filters.createdAt}
      >
        <MenuItem><FormControlLabel value="today" control={<Radio />} label={t('orders.today')} /></MenuItem>
        <MenuItem><FormControlLabel value="last7" control={<Radio />} label={t('orders.last7days')} /></MenuItem>
        <MenuItem><FormControlLabel value="last30" control={<Radio />} label={t('orders.last30days')} /></MenuItem>
      </RadioGroup>
    },
    {
      isFooter: true,
      content: t('ui.clear_filters'),
      action: () => setFilters(defaultFilters),
    }
  ], [filters, t])

  const orderOptions = useMemo(() => [
    {
      value: constants.OPTIONS.orderDetails,
      label: t('orders.order_details'),
      icon: svgEdit,
      handleOptionClick: (rowId) => {
        const found = tableData.find(item => item.referenceId === rowId);
        history.push(`orders/${found.shop._id}/${rowId}`);
      },
    },
    {
      value: constants.OPTIONS.refundDetails,
      label: t('orders.refund_details'),
      icon: svgRefund,
      handleOptionClick: (rowId) => {
        setDialogName('refund');
        setDialogOpen(true);
        prepareDialog(rowId);
      },
    },
    {
      value: constants.OPTIONS.cancelOrder,
      label: t('orders.cancel_order'),
      icon: svgDelete,
      handleOptionClick: (rowId) => {
        setDialogName('cancel');
        setDialogOpen(true);
        prepareDialog(rowId);
      },
    },
  ], [tableData, history])

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: ordersQuery,
      variables: {
        shopIds,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyData(res.data.orders.nodes, 'export');
        const fields = columns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [viewer, shopIds, modifyData])

  const dataProps = useMemo(() => {
    return modifyData(tableData, '', orderOptions);
  }, [tableData, modifyData])

  const renderFilterAndExport = (myClass) => {
    return (
      <Grid item xs={12} lg={5} className={classes[myClass]}>
        <Dropdown
          title={t('ui.show')}
          itemsList={filtersList}
        />
        <StyledButton
          width='140px'
          mytype='secondary'
          handleClick={exportData}
          disabled={exporting || !tableData.length}
        >
          {t('ui.export')}
        </StyledButton>
      </Grid>
    )
  }

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const getShop = (shopId) => {
    return apolloClient.query({
      query: getShopQuery,
      variables: {
        id: shopId,
      },
      fetchPolicy: "network-only",
    });
  }

  const getOrderByReference = (language, shopId, rowId) => {
    return apolloClient.query({
      query: orderByReferenceId,
      variables: {
        id: rowId,
        language,
        shopId,
        token: null,
      },
      fetchPolicy: "network-only",
    })
  }

  const prepareDialog = (rowId) => {
    const found = tableData.find(item => item.referenceId === rowId);
    getShop(found.shop._id)
      .then(res => {
        return res.data.shop;
      })
      .then(res => {
        return getOrderByReference(res.language, res._id, rowId);
      })
      .then(res => {
        setOrder(res.data.order);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
  }

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'refund': return <DialogRefund
        order={order}
        declineAction={() => setDialogOpen(false)}
        refetch={refetch}
        setOrder={setOrder}
      />;
      case 'cancel': return <DialogCancelOrder
        declineAction={() => setDialogOpen(false)}
        order={order}
        refetch={refetch}
      />;
      default: return null;
    }
  }

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Toolbar title={t('orders.orders')} />

        {
          shopIds.length !== 0 && <>
            <Hidden mdDown>
              <Grid container className={classes.grid}>
                <Grid item lg={7} className={classes.gridEmpty}/>
                {renderFilterAndExport(`secondColumn${rtl}`)}
              </Grid>
            </Hidden>

            <Hidden lgUp>
              {renderFilterAndExport('secondColumnXS')}
            </Hidden>

            <Search
              handleChange={handleSearch}
              onSearchClick={refetch}
              placeholder={t('ui.search')}
              useDebounce
            />
          </>
        }

        {shopIds.length === 0 ? (
            <span className={classes.selectSomeShops}>{t('orders.please_select_shops')}</span>
          ) :
          <DataTable
            columns={columns}
            isLoading={isLoading}
            data={dataProps}
            handleFetchData={onFetchData}
            pageCount={pageCount}
            totalCount={totalCount}
            searchValue={searchValue}
            reloadTable={reloadTable}
            defaultSortField='createdAt'
            defaultSortOrder='desc'
            showCheckboxes={false}
            filtersByKey={filters}

            // styles for 2 rows upper panel
            maxHeight='calc(100vh - 370px)'
            maxHeightLaptop='calc(100vh - 440px)'
            maxHeightMobile='calc(100vh - 420px)'
          />
        }
      </Grid>

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>
  );
}

export default OrdersTable;



