import gql from "graphql-tag";

export default gql`
  mutation createVariantsFromAttributes($input: CreateVariantsFromAttributesInput!) {
    createVariantsFromAttributes(input: $input) {
      data {
        _id
        pricing {
          compareAtPrice {
            amount
          }
          price
        }
        sku
        barcode
        weight
        width
        length
        height
        media {
          _id
          URLs {
            original
            small
          }
          priority
        }
        attributes {
          attributeId
          value
          attribute {
            id
            name
            valueType
            inputType
            inputConfig {
              ...on ListInputConfig {
                allowedValues
              }
            }
          }
        }
      }
    }
  }
`;
