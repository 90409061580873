export const getStringFromArray = (arr) => {
  let result = '';
  arr.forEach((str, index) => {
    if (index < arr.length - 1) {
      result += `${str}, `;
    } else {
      result += str;
    }
  });
  return result;
};
