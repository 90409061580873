import svgDoor1 from '../../icons/WS-Icons/Doors/Doors-1.svg';
import svgDoor2 from '../../icons/WS-Icons/Doors/Doors-2.svg';
import svgDoor3 from '../../icons/WS-Icons/Doors/Doors-3.svg';
import svgDoor4 from '../../icons/WS-Icons/Doors/Doors-4.svg';
import svgDoor5 from '../../icons/WS-Icons/Doors/Doors-5.svg';

import svgWindows1 from '../../icons/WS-Icons/Windows/Windows-1.svg';
import svgWindows2 from '../../icons/WS-Icons/Windows/Windows-2.svg';
import svgWindows3 from '../../icons/WS-Icons/Windows/Windows-3.svg';
import svgWindows4 from '../../icons/WS-Icons/Windows/Windows-4.svg';
import svgWindows5 from '../../icons/WS-Icons/Windows/Windows-5.svg';

import svgFloorSecond1 from '../../icons/WS-Icons/Second_Floor/Second_Floor-1.svg';
import svgFloorSecond2 from '../../icons/WS-Icons/Second_Floor/Second_Floor-2.svg';
import svgFloorSecond3 from '../../icons/WS-Icons/Second_Floor/Second_Floor-3.svg';
import svgFloorSecond4 from '../../icons/WS-Icons/Second_Floor/Second_Floor-4.svg';
import svgFloorSecond5 from '../../icons/WS-Icons/Second_Floor/Second_Floor-5.svg';

import svgName1 from '../../icons/WS-Icons/Shop_Name/Shop_Name-1.svg';
import svgName2 from '../../icons/WS-Icons/Shop_Name/Shop_Name-2.svg';
import svgName3 from '../../icons/WS-Icons/Shop_Name/Shop_Name-3.svg';
import svgName4 from '../../icons/WS-Icons/Shop_Name/Shop_Name-4.svg';
import svgName5 from '../../icons/WS-Icons/Shop_Name/Shop_Name-5.svg';

import svgRoof1 from '../../icons/WS-Icons/Roof/Roof-1.svg';
import svgRoof2 from '../../icons/WS-Icons/Roof/Roof-2.svg';
import svgRoof3 from '../../icons/WS-Icons/Roof/Roof-3.svg';
import svgRoof4 from '../../icons/WS-Icons/Roof/Roof-4.svg';
import svgRoof5 from '../../icons/WS-Icons/Roof/Roof-5.svg';

import svgUmbrella1 from '../../icons/WS-Icons/Canopy/Canopy-1.svg';
import svgUmbrella2 from '../../icons/WS-Icons/Canopy/Canopy-2.svg';
import svgUmbrella3 from '../../icons/WS-Icons/Canopy/Canopy-3.svg';
import svgUmbrella4 from '../../icons/WS-Icons/Canopy/Canopy-4.svg';

export const OBJECT_MAP = {
  door: [
    {
      id: 'door_1',
      model: "door_1.glb",
      icon: svgDoor5,
    },
    {
      id: 'door_2',
      model: "door_2.glb",
      icon: svgDoor1,
    },
    {
      id: 'door_3',
      model: "door_3.glb",
      icon: svgDoor4,
    },
    {
      id: 'door_4',
      model: "door_4.glb",
      icon: svgDoor2,
    },
    {
      id: 'door_5',
      model: "door_5.glb",
      icon: svgDoor3,
    },
  ],
  floorfirst: [
    {
      id: 'floorfirst_1',
      model: "floorfirst_1.glb",
      icon: svgWindows5,
    },
    {
      id: 'floorfirst_2',
      model: "floorfirst_2.glb",
      icon: svgWindows1,
    },
    {
      id: 'floorfirst_3',
      model: "floorfirst_3.glb",
      icon: svgWindows2,
    },
    {
      id: 'floorfirst_4',
      model: "floorfirst_4.glb",
      icon: svgWindows3,
    },
    {
      id: 'floorfirst_5',
      model: "floorfirst_5.glb",
      icon: svgWindows4,
    },
  ],
  floorsecond: [
    {
      id: 'floorsecond_1',
      model: "floorsecond_1.glb",
      icon: svgFloorSecond5,
    },
    {
      id: 'floorsecond_2',
      model: "floorsecond_2.glb",
      icon: svgFloorSecond1,
    },
    {
      id: 'floorsecond_3',
      model: "floorsecond_3.glb",
      icon: svgFloorSecond2,
    },
    {
      id: 'floorsecond_4',
      model: "floorsecond_4.glb",
      icon: svgFloorSecond3,
    },
    {
      id: 'floorsecond_5',
      model: "floorsecond_5.glb",
      icon: svgFloorSecond4,
    },
  ],
  name: [
    {
      id: 'name_1',
      model: "name_1.glb",
      icon: svgName1,
    },
    {
      id: 'name_2',
      model: "name_2.glb",
      icon: svgName2,
    },
    {
      id: 'name_3',
      model: "name_3.glb",
      icon: svgName3,
    },
    {
      id: 'name_4',
      model: "name_4.glb",
      icon: svgName4,
    },
    {
      id: 'name_5',
      model: "name_5.glb",
      icon: svgName5,
    },
  ],
  roof: [
    {
      id: 'roof_1',
      model: "roof_1.glb",
      icon: svgRoof1,
    },
    {
      id: 'roof_2',
      model: "roof_2.glb",
      icon: svgRoof2,
    },
    {
      id: 'roof_3',
      model: "roof_3.glb",
      icon: svgRoof3,
    },
    {
      id: 'roof_4',
      model: "roof_4.glb",
      icon: svgRoof4,
    },
    {
      id: 'roof_5',
      model: "roof_5.glb",
      icon: svgRoof5,
    },
  ],
  umbrella: [
    {
      id: 'umbrella_1',
      model: "umbrella_1.glb",
      icon: svgUmbrella1,
    },
    {
      id: 'umbrella_2',
      model: "umbrella_2.glb",
      icon: svgUmbrella2,
    },
    {
      id: 'umbrella_3',
      model: "umbrella_3.glb",
      icon: svgUmbrella3,
    },
    {
      id: 'umbrella_4',
      model: "umbrella_4.glb",
      icon: svgUmbrella4,
    },
  ],
};
