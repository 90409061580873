import React, { useContext } from "react";
import styled from "styled-components";
import HeadingText from "../common/HeadingText";
import AttributeEditForm from "../AttributeEdit/AttributeEditForm";
import { ProductVariantsContext } from "../../../../../package/src/context/ProductVariantsContext";

const HeadingBox = styled('div')`
  margin: 8px 0 24px 0;
`;

const LayoutAttributeInit = () => {
  const { onCreateAttribute } = useContext(ProductVariantsContext);

  return (
    <div>
      <HeadingBox>
        <HeadingText>Select Attribute to create Variant</HeadingText>
      </HeadingBox>
      <AttributeEditForm
        onConfirm={onCreateAttribute}
        isInit
      />
    </div>
  );
};

export default LayoutAttributeInit;