import React, { useEffect, useState } from "react";
import { Container, makeStyles } from '@material-ui/core';
import FormCard from "../../../UI/Form/FormCard";
import FormHeader from "../../../UI/Form/FormHeader";
import Button from "../../../UI/Button/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import AssetHeading from "./AssetHeading";
import { useApolloClient, useLazyQuery } from "@apollo/react-hooks";
import { getSubscriptionPackageConfigurator } from "../graphql/queries";
import Icon from "../../../UI/Icon/Icon";
import doorSVG from "../../../icons/door.svg";
import firstFloorSVG from "../../../icons/firstfloor.svg";
import secondFloorSVG from "../../../icons/secondfloor.svg";
import nameSVG from "../../../icons/name.svg";
import roofSVG from "../../../icons/roof.svg";
import umbrellaSVG from "../../../icons/ubrella.svg";
import { ToggleButtonGroup } from "@material-ui/lab";
import StyledToggleButtonText from "./StyledToggleButtonText";
import AccessoryGroup from "./AccessoryGroup";
import { addPartsToPackage, removePartsToPackage } from "../graphql/mutations";
import { useSnackbar } from "notistack";
import createMutationInputs from "../utils/createMutationInputs";
import isEmptyObject from "../../../package/src/utils/isEmptyObject";
import convertPossibleKey from "../utils/convertPossibleKey";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  container: {
    padding: 0,
  },
  hr: {
    width: '100%',
    border: '1px solid #E9E9E9',
    margin: '20px 0',
  },
  toggleGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    marginBottom: "20px",
  },
  svgIcon: {
    overflow: "visible",
  },
}));

const ConfiguratorTab = ({ packageId }) => {
  const [accessory, setAccessory] = useState("doorIds");
  const [configObjClean, setConfigObjClean] = useState({});
  /*
  configObjClean is needed to determine what data to add using the 'allow' method and what to remove using the 'remove' method
  configObjClean - the default config
  configObj - the config changed by user
  configObjClean is refetching after submit
   */
  const [configObj, setConfigObj] = useState({});
  const [hasChanged, setHasChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [ getConfig, { data: partsRawData, refetch } ] = useLazyQuery(getSubscriptionPackageConfigurator, {
    fetchPolicy: "no-cache",
    variables: {
      id: packageId,
    },
    onError: (e) => {
      console.log(e.message);
    },
  });

  const addConfig = (input) => {
    if (isEmptyObject(input)) {
      return Promise.resolve();
    }

    return apolloClient.mutate({
      mutation: addPartsToPackage,
      variables: {
        id: packageId,
        input,
      },
    })
  }

  const removeConfig = (input) => {
    if (isEmptyObject(input)) {
      return Promise.resolve();
    }

    return apolloClient.mutate({
      mutation: removePartsToPackage,
      variables: {
        id: packageId,
        input,
      },
    })
  }

  useEffect(() => {
    getConfig();
  }, [])

  useEffect(() => {
    if (!partsRawData) {
      return;
    }

    const data = partsRawData.subscriptionPackage.configuratorParts;

    if (!data) {
      return;
    }

    const newData = {
      doorIds: data.doors?.map(item => item.id) || [],
      firstFloorIds: data.firstFloors?.map(item => item.id) || [],
      secondFloorIds: data.secondFloors?.map(item => item.id) || [],
      signAreaIds: data.signAreas?.map(item => item.id) || [],
      roofIds: data.roofs?.map(item => item.id) || [],
      umbrellaIds: data.umbrellas?.map(item => item.id) || [],
    }

    setConfigObj(newData);
    setConfigObjClean(newData);
  }, [partsRawData])

  const handleAccessory = (event, newAccessory) => {
    if (!newAccessory) {
      return;
    }

    setAccessory(newAccessory);
  };

  const handleChange = (arr) => {
    setConfigObj(prev => ({
      ...prev,
      [accessory]: arr,
    }));
    setHasChanged(true);
  }

  const handleSubmit = () => {
    setLoading(true);
    const { allowInput, removeInput } = createMutationInputs(configObj, configObjClean);

    addConfig(allowInput)
      .then(() => {
        return removeConfig(removeInput);
      })
      .then(() => {
        enqueueSnackbar(t('snackbar.successfully_updated'), {variant: 'success'});
        refetch();
      })
      .finally(() => {
        setLoading(false);
        setHasChanged(false);
      })
      .catch((e) => {
        const message = e.message;
        if (message.indexOf('ids is required') > -1 || message.indexOf('at least 1 values') > -1 ) {
          enqueueSnackbar(message.replace('GraphQL error: ', ''), {variant: 'error'});
        } else {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        }
      })
  }

  return (
    <>
      <Container className={classes.container}>
        <FormCard width='max-content' margin='0 auto'>
          <FormHeader>{t('configurator.assets_includes_in_subscription')}</FormHeader>
          <AssetHeading>{t('configurator.modules')}</AssetHeading>

          <ToggleButtonGroup
            size="large"
            aria-label="selected accessory"
            value={accessory}
            exclusive
            onChange={handleAccessory}
            className={classes.toggleGroup}
          >
            <StyledToggleButtonText value="doorIds" aria-label="Door">
              <Icon
                className={classes.svgIcon}
              >
                <img src={doorSVG} alt="" />
              </Icon>
              <p className='significant'>{t('configurator.door')}</p>
            </StyledToggleButtonText>

            <StyledToggleButtonText value="firstFloorIds" aria-label="first floor">
              <Icon
                className={classes.svgIcon}
              >
                <img src={firstFloorSVG} alt="" />
              </Icon>
              <p className='significant'>{t('configurator.floor_first')}</p>
            </StyledToggleButtonText>

            <StyledToggleButtonText value="secondFloorIds" aria-label="second floor">
              <Icon
                className={classes.svgIcon}
              >
                <img src={secondFloorSVG} alt="" />
              </Icon>
              <p className='significant'>{t('configurator.window')}</p>
            </StyledToggleButtonText>

            <StyledToggleButtonText value="signAreaIds" aria-label="shop name">
              <Icon
                className={classes.svgIcon}
              >
                <img src={nameSVG} alt="" />
              </Icon>
              <p className='significant'>{t('configurator.name')}</p>
            </StyledToggleButtonText>

            <StyledToggleButtonText value="roofIds" aria-label="Roof">
              <Icon
                className={classes.svgIcon}
              >
                <img src={roofSVG} alt="" />
              </Icon>
              <p className='significant'>{t('configurator.roof')}</p>
            </StyledToggleButtonText>

            <StyledToggleButtonText value="umbrellaIds" aria-label="umbrella">
              <Icon
                className={classes.svgIcon}
              >
                <img src={umbrellaSVG} alt="" />
              </Icon>
              <p className='significant'>{t('configurator.umbrella')}</p>
            </StyledToggleButtonText>

          </ToggleButtonGroup>

          <hr className={classes.hr}/>
          <AssetHeading optionalText={configObj[accessory]?.length || null}>{t('configurator.variants')}</AssetHeading>

          <AccessoryGroup
            possibleKey={convertPossibleKey(accessory)}
            handleChange={handleChange}
            currentValues={configObj[accessory] || []}
          />
        </FormCard>
      </Container>

      <SaveButtons margin='24px 0' justifyContent='flex-end'>
        <Button
          width='180px'
          disabled={!hasChanged || loading}
          handleClick={handleSubmit}
        >
          {t('ui.save_changes')}
        </Button>
      </SaveButtons>
    </>
  );
};

export default ConfiguratorTab;
