import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import SubHeading from "./SubHeading";
import LinkButton from "./LinkButton";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../context/AuthContext";

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

export const ResendCode = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [attribute, setAttribute] = useState('');

  const { typedEmail } = useContext(AuthContext);

  useEffect(() => {
    let text = "It may take time to receive your code. Have’t received it?";
    if (attribute === 'SMS') {
      text = "Successfully sent authentication code. Please check your phone";
    } else if (attribute === 'EMAIL') {
      text = "Successfully sent authentication code. Please check your email";
    }
    setMessage(text);
  }, [attribute]);

  const onResendCode = async () => {
    setLoading(true);

    if (typedEmail) {
      Auth.resendSignUp(typedEmail)
        .then((res) => {
          if (res) {
            console.log(res);
            setIsError(false);
            const attr = res.CodeDeliveryDetails?.DeliveryMedium;
            setAttribute(attr);
          }
        })
        .catch((err) => {
          console.log(err);
          setMessage(err.message);
          setIsError(true);
          setAttribute('');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Wrapper>
      <SubHeading sx={{ marginBottom: '5px'}} error={isError.toString()}>
        {message}
      </SubHeading>
      <LinkButton
        onClick={onResendCode}
        disabled={loading}
      >
        Resend code
      </LinkButton>
    </Wrapper>
  );
};

export default ResendCode;
