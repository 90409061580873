import React, { useCallback, useContext, useMemo, useState } from "react";
import { Grid, Hidden, makeStyles } from "@material-ui/core";
import Dialog from "../../../UI/Dialog/Dialog";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import Search from "../../../UI/Search/Search";
import StyledButton from "../../../UI/Button/Button";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import DataTable from "../../../UI/DataTable/DataTable";
import { shopsQuery, shopSubscriptionOverrides } from "../graphql/queries";
import { merchantShopsQuery } from "../../merchants/graphql/queries";
import svgEdit from "../../../icons/table-edit.svg";
import svgPercent from "../../../icons/percent.svg";
import svgApprove from "../../../icons/table-approve.svg";
import svgDecline from "../../../icons/close-red.svg";
import svgBan from "../../../icons/block.svg";
import svgSent from "../../../icons/reset.svg";
import svgCopy from '../../../icons/copy.svg';
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import { generateLink } from "../helpers";
import OverrideFormLayout from '../../../package/src/OverrideForm';
import {
  approveShop,
  banShop,
  declineShop,
  sendShopToApprove, setShopSubscriptionOverrides,
} from "../graphql/mutations";
import { useHistory } from "react-router-dom";
import PrimaryAppBar from "../../../package/src/PrimaryAppBar/PrimaryAppBar";
import { LanguageContext } from "../../../package/src/context/LanguageContext";
import { useShopColumns } from "../hooks/useShopColumns";
import { useTranslation } from "react-i18next";
import { useModifiers } from "../hooks/useModifiers";
import { constants } from "../../../constants";

const useStyles = makeStyles(theme => ({
  secondColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
    paddingLeft: 20,
  },
  secondColumnRtl: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
    paddingRight: 20,
  },
  secondColumnXS: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    margin: '28px 0',
  },
}));

const getCompanyName = (viewer) => {
  if (viewer) {
    if (Array.isArray(viewer.adminUIShops) && viewer.adminUIShops.length) {
      return viewer.adminUIShops[0].company.name || '';
    }
  }
  
  return '';
}

const ShopsPage = ({viewer}) => {
  const [searchValue, setSearchValue] = useState('');
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [reloadTable, setReloadTable] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [rowId, setRowId] = useState('');
  const [link, setLink] = useState('');
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const { isRtl } = useContext(LanguageContext);
  const rtl = isRtl ? 'Rtl' : '';
  const columns = useShopColumns();
  const { t } = useTranslation();
  const { modifyData } = useModifiers();

  const godmode = viewer?.type === "godmode";
  const merchantId = godmode ? null : viewer?.companyId;
  const companyName = getCompanyName(viewer);

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
    setIsLoading(true);

    const company = {};

    if (merchantId) {
      company.companyIds = [merchantId];
    }

    apolloClient.query({
      query: merchantId ? merchantShopsQuery : shopsQuery,
      variables: {
        ...company,
        shopIds:
          (viewer?.adminUIShops &&
            viewer.adminUIShops.length &&
            viewer.adminUIShops.map(shop => shop._id)) ||
          [],
        filter: globalFilter,
        first: pageSize,
        limit: (pageIndex + 1) * pageSize,
        offset: pageIndex * pageSize,
        sortOrder,
        sortBy,
      },
      fetchPolicy: "no-cache",
    })
      .then(res => {
        const total = res.data.shopsWithFilters.totalCount;
        setTableData(res.data.shopsWithFilters.nodes);
        setPageCount(Math.ceil(total / pageSize));
        setTotalCount(total);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [viewer, merchantId]);

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const mutateShopStatus = useCallback((action, shopId) => {
    let mutation;
    switch (action) {
      case 'approve': {
        mutation = approveShop;
        break;
      }
      case 'decline': {
        mutation = declineShop;
        break;
      }
      case 'ban': {
        mutation = banShop;
        break;
      }
      case 'sent': {
        mutation = sendShopToApprove;
      }
    }

    apolloClient.mutate({
      mutation,
      variables: {
        shopId,
      }
    })
      .then(res => {
        enqueueSnackbar(t('shops.changed_shop_status_success'), {variant: 'success'});
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
  }, [])

  const shopOptions = useMemo(() => [
    {
      value: constants.OPTIONS.editShop,
      label: t('shops.edit_shop'),
      icon: svgEdit,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        history.push(`/shops/edit/${rowId}`);
      },
    },
    {
      value: constants.OPTIONS.approve,
      label: t('shops.approve'),
      icon: svgApprove,
      handleOptionClick: (rowId) => {
        mutateShopStatus("approve", rowId);
      },
    },
    {
      value: constants.OPTIONS.decline,
      label: t('shops.decline'),
      icon: svgDecline,
      handleOptionClick: (rowId) => {
        mutateShopStatus("decline", rowId);
      },
    },
    {
      value: constants.OPTIONS.ban,
      label: t('shops.ban'),
      icon: svgBan,
      handleOptionClick: (rowId) => {
        mutateShopStatus("ban", rowId);
      },
    },
    {
      value: constants.OPTIONS.sentToApprove,
      label: t('shops.sent_to_approve'),
      icon: svgSent,
      handleOptionClick: (rowId) => {
        mutateShopStatus("sent", rowId);
      },
    },
    {
      value: constants.OPTIONS.editOverrides, // godmode only
      label: t('shops.edit_overrides'),
      icon: svgPercent,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName("overrides");
        setDialogOpen(true);
      },
    },
    {
      value: constants.OPTIONS.copyLink, // only if the shop has the place at map
      label: t('shops.copy_link'),
      icon: svgCopy,
      handleOptionClick: (rowId) => {
        const link = generateLink(tableData, rowId);
        navigator.clipboard.writeText(link)
          .then(() => {
            setLink(link);
            setDialogName("copyLink");
            setDialogOpen(true);
          })
      },
    },
  ], [godmode, tableData, t]);

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: shopsQuery,
      variables: {
        shopIds:
          (viewer?.adminUIShops &&
            viewer.adminUIShops.length &&
            viewer.adminUIShops.map(shop => shop._id)) ||
          [],
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyData(godmode, res.data.shopsWithFilters.nodes, 'export');
        const fields = columns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [viewer, godmode, modifyData])

  const dataProps = useMemo(() => {
    return modifyData(godmode, tableData, '', shopOptions);
  }, [tableData, shopOptions, modifyData])

  const DialogCopyLink = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('shops.link_copied')}
          message={<a
            href={link}
          >
            {link}
          </a>}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => setDialogOpen(false)}
          >
            {t('ui.ok')}
          </StyledButton>
        </DialogButtons>
      </>
    )
  }, [link, t])

  const onBackButtonClick = () => {
    history.push('/merchants');
  }

  const handleCreateShop = () => {
    history.push('/shops/create');
  }

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'overrides': return <OverrideFormLayout
          id={rowId}
          query={shopSubscriptionOverrides}
          mutation={setShopSubscriptionOverrides}
          queryOutput='shopSubscriptionOverrides'
          mutationOutput='setShopSubscriptionOverrides'
        />;
      case 'copyLink': return DialogCopyLink;
      default: return null;
    }
  }

  const renderTitle = () => {
    if (!merchantId) {
      return t('shops.shops');
    }
    let title = t('shops.all_shops');
    if (companyName) {
      title += ` (${companyName})`;
    }
    return title;
  }

  const renderSecondColumn = (myClass) => {
    return <Grid item xs={12} lg={5} className={classes[myClass]}>
      <StyledButton
        width='140px'
        mytype='secondary'
        handleClick={exportData}
        disabled={exporting || !tableData.length}
      >
        {t('ui.export')}
      </StyledButton>

      <StyledButton
        width='220px'
        handleClick={handleCreateShop}
      >
        {t('shops.create_shop')}
      </StyledButton>
    </Grid>
  }

  return (
    <>
      <Grid container>
        <PrimaryAppBar
          title={renderTitle()}
          onBackButtonClick={onBackButtonClick}
          shouldShowBackButton={Boolean(merchantId)}
        />

        <Grid item xs={12} md={7}>
          <Search
            handleChange={handleSearch}
            onSearchClick={refetch}
            placeholder={t('ui.search')}
            useDebounce
            minWidth='270px'
            maxWidth='1168px'
          />
        </Grid>

        <Hidden mdDown>
          {renderSecondColumn(`secondColumn${rtl}`)}
        </Hidden>

        <Hidden lgUp>
          {renderSecondColumn('secondColumnXS')}
        </Hidden>

        <DataTable
          columns={columns}
          isLoading={isLoading}
          data={dataProps}
          handleFetchData={onFetchData}
          pageCount={pageCount}
          totalCount={totalCount}
          searchValue={searchValue}
          reloadTable={reloadTable}
          defaultSortField='createdAt'
          defaultSortOrder='desc'
          showCheckboxes={false}

          // styles for 1 row upper panel
          maxHeight='calc(100vh - 280px)'
          maxHeightLaptop='calc(100vh - 380px)'
          maxHeightMobile='calc(100vh - 360px)'
        />

      </Grid>
      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>
  );
}

export default ShopsPage;
