import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { allProductBrandsQuery } from "./graphql/queries";
import { useSnackbar } from "notistack";
import { colors } from "../../../constants";
import Autocomplete from "../../../UI/Autocomplete";
import TextField from "../../../UI/TextField/TextField";
import Popper from '../../../UI/Popper';
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  label: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    color: colors.black,
    marginLeft: 16,
  },
}));

/**
 * TagSelectorWithData
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function BrandSelectorWithData({onChange, value, placeholder, ...other}) {
  const { enqueueSnackbar } = useSnackbar();
  const classesLocal = useStyles();
  const [list, setList] = useState([]);
  const { t } = useTranslation();

  const { data } = useQuery(allProductBrandsQuery, {
    variables: {
      productBrandIds: [],
      first: 200,
    },
    fetchPolicy: "network-only",
    onError: (e) => {
      enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
      console.log(e.message);
    }
  });

  useEffect(() => {
    if (data) {
      const options = data?.productBrands.nodes;
      if (options) {
        const result = options
          .map(node => ({
            id: node._id,
            label: node.name,
          }));
        setList(result);
      }
    }
  }, [data])

  const handleChange = (_, val) => {
    if (!val) {
      return;
    }
    onChange(val.id);
  }

  const inputRenderer = params => {
    return <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
      }}
      placeholder={placeholder || ''}
    />;
  }

  const PopperComponent = (props) => (
    <Popper {...props}>
      {props.children}
    </Popper>
  );

  const optionRenderer = (option) => {
    return <span className={classesLocal.label}>{option.label}</span>;
  };

  const getOptionLabel = (option) => {
    const compareOption = typeof option === 'string' ? option : option.id;
    return list.find(item => item.id === compareOption)?.label || '';
  }

  const getOptionSelected = (option) => {
    return option.id === value;
  }

  return (
    <Autocomplete
      options={list}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleChange}
      placeholder={placeholder || ''}
      renderInput={inputRenderer}
      PopperComponent={PopperComponent}
      renderOption={optionRenderer}
      value={value}
      {...other}
    />
  );
}

BrandSelectorWithData.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default BrandSelectorWithData;
