import React, { useCallback, useEffect, useState } from "react";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import { Controller, useForm } from "react-hook-form";
import FormInput from "../../../UI/Form/FormInput";
import { Form } from '../../../UI/Form/MyForm';
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { GlobalSchema } from "../schemas";
import DateRangeSelector from "../../../package/src/DateRangeSelector";
import {Grid} from '@material-ui/core';
import Button from "../../../UI/Button/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
// import discountCodeQuery from '../graphql/queries/discountCode';
// import createDiscountCode from "../graphql/mutations/createDiscountCode";
// import updateDiscountCode from "../graphql/mutations/updateDiscountCode";
// import { useApolloClient } from "@apollo/react-hooks";
// import { useSnackbar } from "notistack";
// import Select from "../../../UI/Select/Select";
import ExceptionSelector from "./ExceptionSelector";
import styled from 'styled-components';
import formatDateRange from "../../../package/src/utils/formatDateRange";
import { useTranslation } from "react-i18next";

const WrappedContainer = styled(Grid)`
  margin-bottom: 16px;
`

const GlobalDiscountForm = ({eventId, page, declineAction, refetch}) => {
  // const apolloClient = useApolloClient();
  // const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [exceptions, setExceptions] = useState([]);

  const { control, handleSubmit, formState: { errors, isDirty }, reset, watch } = useForm({
    resolver: yupResolver(GlobalSchema),
    defaultValues: {
      name: '',
      description: '',
      percentage: 0,
      period: {startDate: null, endDate: null},
      exceptions: [],
    },
  });

  useEffect(() => {
    // if (page === 'edit') {
    //   setLoading(true);
    //   getSale()
    //     .then(res => {
    //       const data = res?.data?.discountCode;
    //       if (!data) {
    //         return;
    //       }
    //       const products = data.conditions?.productDetails?.map(product => product._id) || [];
    //
    //       let type = '';
    //       if (data.calculation?.method === 'discount') {
    //         type = 'percentage';
    //       } else if (data.calculation?.method === 'credit') {
    //         type = 'flat';
    //       }
    //
    //       const resetValues = {
    //         code: data.code,
    //         period: {
    //           startDate: data.conditions?.order?.startDate || null,
    //           endDate: data.conditions?.order?.endDate || null,
    //         },
    //         type,
    //         amount: data.discount || 0,
    //         products,
    //       }
    //
    //       reset(resetValues);
    //     })
    //     .catch((e) => {
    //       enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
    //       console.log(e.message);
    //     })
    //     .finally(() => setLoading(false))
    // }
  }, [])

  // const getEvent = () => {
  //   return apolloClient.query({
  //     query: discountCodeQuery,
  //     variables: {
  //       id: eventId,
  //     },
  //     fetchPolicy: "network-only",
  //   })
  // }

  const handleNumberChange = (e, func) => {
    let value = e.target.value;
    if (value < 0) value = 0;
    func(value);
  }

  const mutateEvent = useCallback((payload) => {
    // if (page === 'edit') {
    //   return apolloClient.mutate({
    //     mutation: updateDiscountCode,
    //     variables: {
    //       input: {
    //         shopId,
    //         discountCodeId: eventId,
    //         discountCode: payload,
    //       },
    //     },
    //   })
    // }
    // return apolloClient.mutate({
    //   mutation: createDiscountCode,
    //   variables: {
    //     input: {
    //       shopId,
    //       discountCode: payload,
    //     },
    //   },
    // })
  }, [eventId])

  const onSubmit = useCallback((formData) => {
    console.log(formData);

    // mutateEvent(payload)
    //   .then(() => {
    //     enqueueSnackbar(`Sale ${page === 'edit' ? 'edited' : 'created'} successfully`, {variant: 'success'});
    //     setLoading(false);
    //     declineAction();
    //     refetch();
    //   })
    //   .catch((e) => {
    //     enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
    //     console.log(e.message);
    //     setLoading(false);
    //   })
  }, [mutateEvent])

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeader>{page === 'create' ? t('discount.create_global_discount') : t('discount.edit_global_discount')}</FormHeader>
      <InputLabel error={errors.name} disabled={loading}>{t('discount.name')}</InputLabel>
      <Controller
        control={control}
        name='name'
        render={({ field }) => <FormInput
          placeholder={t('discount.write_event_name')}
          value={field.value}
          onChange={field.onChange}
          error={errors.name}
          disabled={loading}
        />}
      />

      <InputLabel
        error={errors.type}
        disabled={loading}
        optionalText={t('ui.optional')}
      >
        {t('discount.description')}
      </InputLabel>
      <Controller
        name="description"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('discount.tell_about_event')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
          multiline
          rows={4}
        />}
      />

      <InputLabel optionalText='%' disabled={loading}>{t('discount.percentage')}</InputLabel>
      <Controller
        control={control}
        name='percentage'
        render={({ field }) => <FormInput
          value={field.value}
          onChange={(e) => handleNumberChange(e, field.onChange)}
          type='number'
          error={errors.amount}
          disabled={loading}
        />}
      />

      <InputLabel disabled={loading}>{t('discount.validity_period')}</InputLabel>
      <Controller
        name="period"
        control={control}
        render={({ field }) => <DateRangeSelector
          placeholder={t('discount.validity_period')}
          dateRange={formatDateRange(field.value)}
          onChange={field.onChange}
          disabled={loading}
        />}
      />

      <WrappedContainer container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Button
            width='100%'
            handleClick={() => {}}
            disabled={loading}
          >
            {t('discount.all_available_shops')}
          </Button>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Button
            width='100%'
            handleClick={() => {}}
            disabled={loading}
          >
            {t('discount.all_unavailable_shops')}
          </Button>
        </Grid>
      </WrappedContainer>

      <InputLabel disabled={loading}>{t('discount.exceptions')}</InputLabel>
      <Controller
        name="exceptions"
        control={control}
        render={({ field }) => <ExceptionSelector
          placeholder={t('discount.choose_exceptions')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
          options={exceptions}
        />}
      />

      <SaveButtons style={{ marginTop: 48 }}>
        <Button
          disabled={!isDirty || loading}
          type='submit'
          width='180px'
        >
          {page === 'edit' ? t('ui.save_changes') : t('ui.create')}
        </Button>
        <Button
          mytype='third'
          width='180px'
          handleClick={declineAction}
          disabled={loading}
        >
          {t('ui.cancel')}
        </Button>
      </SaveButtons>
    </Form>
  );
}

export default GlobalDiscountForm;
