import React, { useContext, useEffect, useState } from "react";
import TextField from '../../../UI/TextField';
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Autocomplete from '../../../UI/Autocomplete';
import { IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import Checkbox from '../../../UI/Checkbox';
import Popper from '../../../UI/Popper';
import { groupBy } from "lodash";
import Button from '../../../UI/Button';
import {colors} from '../../../constants';
import svgAdd from '../../../icons/add.svg';
import svgVertical from '../../../icons/vertical.svg';
import { LanguageContext } from "../context/LanguageContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '7px 0',
    paddingRight: 18,
  },
  btnContainerRtl: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '7px 0',
    paddingLeft: 18,
  },
  label: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    color: colors.black,
    marginLeft: 16,
  },
  labelRtl: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    color: colors.black,
    marginRight: 16,
  },
  tagEditorVertical: {
    marginRight: 14,
  }
}));

const orderItemsByGroupId = items => {
  const groupedItems = groupBy(items, item => {
    return item.groupId;
  });

  items = [];
  for (var key in groupedItems) {
    if (groupedItems.hasOwnProperty(key)) {
      items.push(...groupedItems[key]);
    }
  }

  return items;
};

/**
 * Returns an array of user's selections (even if `isMultiSelect` is false in
 * which case it returns an array of 1 element)
 * @param {*} param0
 */
const SelectAllAutocomplete = ({
  items,
  value,
  label,
  placeholder,
  selectAllLabel,
  limitTags,
  onChange,
  classes,
  isMultiSelect = true,
  error,
  validateOnChange,
  disabled,
  style,
  canAddNewItem,
  handleAddToDB,
  alwaysSelectAll = true,
}) => {
  const { isRtl } = useContext(LanguageContext);
  const classesLocal = useStyles();
  const { t } = useTranslation();

  // Contains all selected options
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (value?.length) {
      setSelectedOptions(items.filter(item => value.includes(item.value)));
    } else if (alwaysSelectAll) {
      setSelectedOptions(items.filter(item => item.selected));
    }
  }, [items, value, alwaysSelectAll]);

  // Contains the options that were selected when the user first opened the dropdown
  const [selectedOptionsOnOpen, setSelectedOptionsOnOpen] = useState([]);

  // Whether or not the dropdown is open
  const [open, setOpen] = useState(false);

  // This is needed because for some reason `groupBy` prop of `<Autocomplete/>`
  // fails to do proper grouping without first sorting the items by group
  items = orderItemsByGroupId(items);

  const allSelected = items.length === selectedOptions.length;
  const handleToggleOption = selectedOptions => setSelectedOptions(selectedOptions);
  const handleClearOptions = () => {
    setSelectedOptions([]);
  };
  const getOptionLabel = option => `${option.label}`;

  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptionsLocal, reason) => {
    if (!Array.isArray(selectedOptionsLocal)) {
      // This means `isMultiSelect` is false, so the returned value is not
      // an array --> make it an array of 1 element so that nothing breaks
      selectedOptionsLocal = [selectedOptionsLocal];
    }

    if (reason === "select-option" || reason === "remove-option") {
      if (validateOnChange) {
        if (!validateOnChange(selectedOptionsLocal)) {
          return;
        }
      }
      if (selectedOptionsLocal.find(option => option.value === "select-all")) {
        handleToggleSelectAll();
      } else {
        handleToggleOption && handleToggleOption(selectedOptionsLocal);

        // If the dropdown is open, the state will be lifted up when the
        // dropdown closes (see `onClose`). So only lift state up if dropdown
        // is closed OR if this is a single select
        if (!open || !isMultiSelect) {
          setOpen(false);
          return onChange(selectedOptionsLocal);
        }
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
      return onChange([]);
    }
  };

  const onOpen = (event, reason) => {
    setOpen(true);
    setSelectedOptionsOnOpen(selectedOptions);
  };

  const onClose = (event, reason) => {
    setOpen(false);

    // We only lift state up if we find a difference between the selections of
    // the user prior to opening the dropdown and afterwards
    const diff1 = selectedOptions.filter(e => !selectedOptionsOnOpen.includes(e));
    const diff2 = selectedOptionsOnOpen.filter(e => !selectedOptions.includes(e));

    // If any of the two difference arrays isn't empty, it means there's a difference
    // between selection before and after --> lift state up to force a render
    if (diff1.length !== 0 || diff2.length !== 0) return onChange(selectedOptions);
  };

  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      items.length > 0 && option.value === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};

    // We don't show a checkbox if single select
    return (
      <>
        {isMultiSelect && !canAddNewItem && (
          <Checkbox
            checked={selected}
            {...selectAllProps}
          />
        )}
        <span className={isRtl ? classesLocal.labelRtl : classesLocal.label}>
          {getOptionLabel(option)}
        </span>
      </>
    );
  };

  const handleAdd = (params) => {
    handleAddToDB(params.inputProps.value.trim());
  }

  const inputRenderer = params => {
    const myParams = { ...params };

    if (canAddNewItem) {
      myParams.InputProps.endAdornment = (
        <InputAdornment>
          <img src={svgVertical} alt='' className={classesLocal.tagEditorVertical}/>
          <IconButton
            size='small'
            onClick={() => handleAdd(params)}
          >
            <img src={svgAdd} alt=''/>
          </IconButton>
        </InputAdornment>
      );
      myParams.InputProps.onKeyDown = event => {
        if (event.key === 'Enter') {
          handleAdd(params);
        }
      }
    }

    return <TextField
      {...myParams}
      placeholder={placeholder}
    />;
  }

  const filter = createFilterOptions();
  const myValue = isMultiSelect ? selectedOptions : selectedOptions[0];
  // todo при isMultiSelect === false Autocomplete не отображает value

  // This Component is needed to show the Apply button at the bottom of the options
  const PopperComponent = (props) => (
    <Popper
      {...props}
      isrtl={String(isRtl)}
    >
      {props.children}
      {
        isMultiSelect && !canAddNewItem &&
        <div className={isRtl ? classesLocal.btnContainerRtl : classesLocal.btnContainer}>
          <Button width='120px' height='40px'>
            {t('ui.apply')}
          </Button>
        </div>
      }
    </Popper>
  );

  return (
    <Autocomplete
      open={open}
      multiple={isMultiSelect}
      fullWidth={true}
      classes={classes}
      limitTags={limitTags}
      options={items}
      groupBy={items => items.groupId}
      value={myValue}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, val) => option.value === val.value}
      filterOptions={(options, params) => {
        // Only show the "select all" checkbox if we have 2 or more items
        if (items.length > 1 && isMultiSelect && !canAddNewItem) {
          const filtered = filter(options, params);
          return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
        } else {
          const filtered = filter(options, params);
          return [...filtered];
        }
      }}
      onChange={handleChange}
      onOpen={onOpen}
      onClose={onClose}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
      PopperComponent={PopperComponent}
      error={error}
      disabled={disabled}
      style={style}
    />
  );
};

SelectAllAutocomplete.defaultProps = {
  limitTags: 3,
  items: [],
  //selectedValues: [],
  getOptionLabel: val => val,
};

export default SelectAllAutocomplete;
