import { v4 } from "uuid";

/**
 * Prepare an attribute value from the server for writing to the state
 * @returns {string}
 * @param inputType
 * @param value
 */

const parseAttributeValue = (inputType, value) => {
  let parsed = JSON.parse(value);
  switch(inputType) {
    case 'colorPicker': {
      return {
        id: v4(),
        hex: parsed.value,
        name: parsed.name,
      };
    }
    case 'datePicker': {
      return {
        id: v4(),
        date: parsed,
        name: '',
      };
    }
    default: return parsed;
  }
};

export default parseAttributeValue;
