import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function triggerScene(eventType, data) {
  const event = new CustomEvent(eventType.valueOf(), { detail: data });
  document.dispatchEvent(event);
}

const useStyles = makeStyles(() => ({
  formFileUpload: {
    height: "100%",
    width: "100%",
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
  },
  inputFileUpload: {
    display: "none",
  },
  uploadButton: {
    cursor: "pointer",
    padding: "0.25rem",
    color: "#4270FF",
    fontSize: "16px",
    border: "none",
    fontFamily: "Mulish",
    backgroundColor: "transparent",
    "&:hover": {
      textDecorationLine: "underline",
    },
  },
  labelFileUpload: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontFamily: "Mulish",
    borderWidth: "3px",
    borderRadius: "1rem",
    borderStyle: "dashed",
    borderColor: "#BFBFBF",
    backgroundColor: "#F3F3F3",
    padding: "50px 60px",
  },
}));

function DragDropFile() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      let img = e.target.files[0];
      triggerScene("imgChanged", { img: img });
    }
  };

  const handleChange = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      triggerScene("imgChanged", { img: img });
      e.target.value = null;
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form id="formFileUpload" className={classes.formFileUpload} onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}>
      <input
        ref={inputRef}
        className={classes.inputFileUpload}
        type="file"
        id="inputFileUpload"
        multiple={false}
        onChange={handleChange}
        accept="image/jpg, image/png, image/jpeg, image/gif"
      />
      <label id="label-file-upload" className={classes.labelFileUpload} htmlFor="inputFileUpload">
        <div>
          <button className={classes.uploadButton} onClick={onButtonClick}>{t('common.click_to_upload')}</button>
          {t('common.or_drag_it_here')}
          <br />
          {t('common.image_formats_list')}
        </div>
      </label>
      {dragActive &&
        <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag}
             onDrop={handleDrop} />}
    </form>
  );
}

export default DragDropFile;
