import React, { useContext, useState } from "react";
import styled from "styled-components";
import Dialog from "../../../../UI/Dialog";
import LayoutEmptyVariants from "./Layouts/LayoutEmptyVariants";
import LayoutEditor from "./Layouts/LayoutEditor";
import { ProductVariantsContext } from "../../../../package/src/context/ProductVariantsContext";
import LayoutTable from "./Layouts/LayoutTable";
import DialogMessages from "../../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import CustomButton from "../../../../UI/Button";
import { useTranslation } from "react-i18next";
import useVariantsUpdate from "./hooks/useVariantsUpdate";

const Wrapper = styled('div')`
  width: 100%;
`;

const VariantEditor = ({ productId, shopId, refetchMedia }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const { t } = useTranslation();

  const {
    openEditor,
    onCancelChanges,
    onSetOpenEditor,
    variants,
    isChanged,
  } = useContext(ProductVariantsContext);

  const { onDone } = useVariantsUpdate(productId, shopId, refetchMedia);

  const handleCancel = () => {
    if (isChanged) {
      setOpenDialog(true);
    } else {
      onCancelChanges();
      onSetOpenEditor(false);
    }
  };

  const handleDontSaveChanges = () => {
    setOpenDialog(false);
    onCancelChanges();
    onSetOpenEditor(false);
  };

  const handleSaveChanges = () => {
    setOpenDialog(false);
    onDone();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Wrapper>
      {
        !openEditor &&
        (variants.length ?
          <LayoutTable
            isEditable={false}
            shopId={shopId}
            refetchMedia={refetchMedia}
          /> :
          <LayoutEmptyVariants />)
      }

      <Dialog
        open={openEditor}
        handleClose={handleCancel}
        hideCloseIcon
        paperHeight='100vh'
      >
        <LayoutEditor
          onCancel={handleCancel}
          onDone={onDone}
          shopId={shopId}
          refetchMedia={refetchMedia}
        />
      </Dialog>

      <Dialog
        open={openDialog}
        handleClose={handleCloseDialog}
      >
        <DialogMessages
          title='You have unsaved changes'
          message='Do you want to save your changes before exiting?'
        />
        <DialogButtons justifyButtons='center'>
          <CustomButton
            width='166px'
            mytype='secondary'
            handleClick={handleDontSaveChanges}
          >
            Don't save
          </CustomButton>
          <CustomButton
            width='166px'
            handleClick={handleSaveChanges}
          >
            {t('ui.save')}
          </CustomButton>
        </DialogButtons>
      </Dialog>
    </Wrapper>
  );
};

export default VariantEditor;