import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";

const WindowRegPageBG = styled(Box)`
  width: 100%;
  height: 100%;
  position: fixed;
  overscroll-behavior-y: none;
  background: linear-gradient(196.47deg, #FBFCFF 4.13%, #EDEFFD 99.71%);
  background-size: cover;
`;

const Elipse1WrapperBG = styled(Box)`
	position: absolute;
	left: 0;
	bottom: 5%;
	width: calc((291px*0.7) + ((291*2) - (291*0.7)) * ((100vw - 1024px) / (3840 - 1024)));
	height: calc((347px*0.7) + ((347*2) - (347*0.7)) * ((100vh - 720px) / (2100 - 720)));
`;

const Elipse11WrapperBG = styled(Box)`
	position: absolute;
	right: 0%;
	top: -1%;
	width: calc((453px*0.5) + ((453*2) - (453*0.7)) * ((100vw - 1024px) / (3840 - 1024)));
	height: calc((453px*0.5) + ((453*2) - (453*0.7)) * ((100vh - 720px) / (2100 - 720)));
`;

const Saly1leftcenterWrapperBG = styled(Box)`
	position: absolute;
	left: 7%;
	bottom: 31%;
	width: calc((323px*0.5) + ((323*2) - (323*0.7)) * ((100vw - 1024px) / (3840 - 1024)));
	height: calc((281px*0.5) + ((281*2) - (281*0.7)) * ((100vh - 720px) / (2100 - 720)));
`;

const Saly1righttopWrapperBG = styled(Box)`
	position: absolute;
	right: 16.67%;
	top: -4.07%;
	width: calc((327px*0.5) + ((327*2) - (327*0.7)) * ((100vw - 1024px) / (3840 - 1024)));
	height: calc((288px*0.5) + ((288*2) - (288*0.7)) * ((100vh - 720px) / (2100 - 720)));
`;

const Saly22leftbottomWrapperBG = styled(Box)`
	position: absolute;
	left: 34.74%;
	right: 62.64%;
	top: 87.11%;
	bottom: 7.12%;
	width: calc((50px*0.5) + ((50*2) - (50*0.7)) * ((100vw - 1024px) / (3840 - 1024)));
	height: calc((62px*0.5) + ((62*2) - (62*0.7)) * ((100vh - 720px) / (2100 - 720)));
`;

const Saly22lefttopWrapperBG = styled(Box)`
	position: absolute;
	left: 31.96%;
	right: 65.07%;
	top: 11.53%;
	bottom: 81.93%;
	width: calc((57px*0.5) + ((57*2) - (57*0.7)) * ((100vw - 1024px) / (3840 - 1024)));
	height: calc((70px*0.5) + ((70*2) - (70*0.7)) * ((100vh - 720px) / (2100 - 720)));
`;

const Saly22rightbottomWrapperBG = styled(Box)`
	position: absolute;
	left: 92.88%;
	right: 4.51%;
	top: 72.87%;
	bottom: 21.36%;
	width: calc((50px*0.5) + ((50*2) - (50*0.7)) * ((100vw - 1024px) / (3840 - 1024)));
	height: calc((62px*0.5) + ((62*2) - (62*0.7)) * ((100vh - 720px) / (2100 - 720)));
`;

const ImageBox = styled('img')`
  width: 100%;
  height: 100%;
`;

const LayoutBackground = ({ children, sx }) => {
  return (
    <WindowRegPageBG sx={sx}>
      <Elipse1WrapperBG>
        <ImageBox
          src='/auth/background/Ellipse1.svg'
          alt=''
        />
      </Elipse1WrapperBG>
      <Elipse11WrapperBG>
        <ImageBox
          src='/auth/background/Ellipse_11.svg'
          alt='123'
        />
      </Elipse11WrapperBG>
      <Saly1leftcenterWrapperBG>
        <ImageBox
          src='/auth/background/Saly-1-left-center.png'
          alt=''
        />
      </Saly1leftcenterWrapperBG>
      <Saly1righttopWrapperBG>
        <ImageBox
          src='/auth/background/Saly-1-right-top.png'
          alt=''
        />
      </Saly1righttopWrapperBG>
      <Saly22leftbottomWrapperBG>
        <ImageBox
          src='/auth/background/Saly-22-left-bottom.png'
          alt=''
        />
      </Saly22leftbottomWrapperBG>
      <Saly22lefttopWrapperBG>
        <ImageBox
          src='/auth/background/Saly-22-left-top.png'
          alt=''
        />
      </Saly22lefttopWrapperBG>
      <Saly22rightbottomWrapperBG>
        <ImageBox
          src='/auth/background/Saly-22-right-bottom.png'
          alt=''
        />
      </Saly22rightbottomWrapperBG>
      {children}
    </WindowRegPageBG>
  );
};

export default LayoutBackground;
