import gql from "graphql-tag";

export default gql`
  query getAccountTypes {
    getAccountTypes {
      name
      description
      slug
    }
  }
`;
