import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const useProductCategoriesColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'name',
      label: t('product.product_category'),
      isBold: true,
    },
    {
      name: 'createdBy',
      label: t('product.created_by'),
      sortable: 'accountId',
    },
    {
      name: 'email',
      label: t('product.email'),
      sortable: false,
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    {
      name: 'options',
    }
  ], [t]);
};

export default useProductCategoriesColumns;
