import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { OverrideSchema } from "./schema";
import FormHeader from "../../../UI/Form/FormHeader";
import CheckboxContainer from "../../../UI/Checkbox/CheckboxContainer";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import DateRangeSelector from "../DateRangeSelector";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import StyledButton from "../../../UI/Button/Button";
import { Form } from "../../../UI/Form/MyForm";
import React, { useEffect } from "react";
import formatDateRange from "../utils/formatDateRange";
import { useTranslation } from "react-i18next";

const OverrideForm = ({ loading, title, data, sx, onSubmit }) => {
  const { t } = useTranslation();

  const { control, handleSubmit, formState: { errors, isDirty }, reset } = useForm({
    resolver: yupResolver(OverrideSchema),
    defaultValues: {
      enable: false,
      percentage: '',
      period: {startDate: null, endDate: null},
      notes: '',
    },
  });

  useEffect(() => {
    if (data) {
      reset({
        ...data,
      });
    }
  }, [data]);

  const handleNumberChange = (e, func) => {
    let value = e.target.value;
    if (value < 0) value = 0;
    func(value);
  }

  const onSubmitHandler = (formData) => {
    onSubmit(formData)
      .then((res) => {
        // конвертация данных (для универсальности)
        const newData = {...res};
        if (newData.enabled !== undefined) {
          newData.disabled = !newData.enabled;
        }
        if (newData.percent) {
          newData.changePercentage = newData.percent;
        }
        if (newData.period) {
          newData.startDate = newData.period.start;
          newData.endDate = newData.period.end;
        }

        // solves problem: 'save' staying enabled after submit
        reset({
          enable: !newData.disabled,
          percentage: newData.changePercentage ? newData.changePercentage : newData.newValue,
          period: {
            startDate: newData.startDate,
            endDate: newData.endDate,
          },
          notes: newData.notes || '',
        })
      });
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmitHandler)}
      style={sx}
    >
      <FormHeader>{title}</FormHeader>
      <CheckboxContainer>
        <Controller
          name="enable"
          control={control}
          render={({ field }) => <Checkbox
            checked={field.value}
            onChange={field.onChange}
            disabled={loading}
          />}
        />
        {t('common.enable_override')}
      </CheckboxContainer>

      <InputLabel disabled={loading} error={errors.percentage}>{t('common.change_percentage')}</InputLabel>
      <Controller
        name="percentage"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('common.change_percentage_lowercase')}
          value={field.value}
          onChange={(e) => handleNumberChange(e, field.onChange)}
          disabled={loading}
          type='number'
          error={errors.percentage}
        />}
      />
      <InputLabel disabled={loading} error={errors.period}>{t('common.override_period')}</InputLabel>
      <Controller
        name="period"
        control={control}
        render={({ field }) => <DateRangeSelector
          placeholder={t('common.choose_override_period')}
          dateRange={formatDateRange(field.value)}
          onChange={field.onChange}
          disabled={loading}
          error={errors.period}
        />}
      />
      <InputLabel optionalText={t('ui.optional')} disabled={loading}>{t('common.notes')}</InputLabel>
      <Controller
        name="notes"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('common.write_notes_here')}
          multiline
          rows={4}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
        />}
      />

      <DialogButtons marginTop='16px'>
        <StyledButton
          width='180px'
          type="submit"
          disabled={!isDirty || loading}
        >
          {t('ui.save')}
        </StyledButton>
      </DialogButtons>
    </Form>
  );
}

export default OverrideForm;
