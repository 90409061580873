export const modifyInvitationsData = (data, method, options) => {
  return data.map(item => {
    const result = {
      id: item._id,
      name: item.firstName + ' ' + item.lastName,
      email: item.email,
      phoneNumber: item.phoneNumber,
      createdAt: item.createdAt,
    }

    // if table
    if (method !== 'export' && options) {
      result.options = options;
      return result;
    }

    // if 'export' button
    result.__typename = item.__typename;
      return result;
  });
}
