import * as JSON_KEYS from "../constants/NameConsts";
import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import BaseFont from "three/examples/fonts/gentilis_bold.typeface.json";
import Street from "./Street";
import StreetConnection from "./StreetConnection";
import CityBuilder from "./CityBuilder";
import {
  HOUSE_FREE,
  HOUSE_FREE_COLOR,
  HOUSE_TAKEN,
  STREET_HEIGHT,
  STREET_WIDTH,
  WAY_OFFSET,
  HOUSE_HEIGHT,
  HOUSE_WIDTH,
  MARGIN,
  // MODULES_API_PATH,
  WAY_ADDITION_COLOR,
  WAY_ADDITION_HEIGHT,
  WAY_ADDITION_WIDTH,
  WAY_MATERIAL,
  DEFAULT_STREET_HOUSE_COUNT,
} from "../constants/constants";
import triggerScene from "../utils/triggerScene";

export default class City {
  streets; // Array of Street
  showedStreets; // Array of street's uuid need to be showed in subscription mode
  connections; // Array of StreetConnection

  streets3d;
  streetNames3d;
  streetCategories3d;
  // connections3d; coming later

  currentShopId; // string

  sceneStreets; // THREE.Scene
  sceneHouses; // THREE.Scene
  font; // THREE.Font

  houses3d; // array of Three.Mesh
  additionPlanes3d;
  housesNumbers; // array of Three.Mesh
  way; // three.mesh
  wayAdditions; // array of Three.Mesh
  isSubscriptionMode; // boolean. 'true' if path is 'Subscription - Edit Subscription Package - Map'
  placesOnStreets;

  locales;

  constructor(
    jsonData,
    sceneStreets,
    sceneHouses,
    isSubscriptionMode,
    placesOnStreets,
    showedStreets,
    locales
  ) {
    const fontLoader = new FontLoader();
    this.font = fontLoader.parse(BaseFont);
    this.placesOnStreets = placesOnStreets;
    this.sceneStreets = sceneStreets;
    this.sceneHouses = sceneHouses;
    this.locales = locales;
    this.streets = [];
    this.streets3d = [];
    this.streetNames3d = [];
    this.streetCategories3d = [];
    this.connections = [];
    this.houses3d = [];
    this.housesNumbers = [];
    this.additionPlanes3d = [];
    this.showedStreets = showedStreets;
    this.isSubscriptionMode = isSubscriptionMode;
    for (const jStreet of jsonData[JSON_KEYS.STREETS]) {
      let street = new Street(jStreet);
      this.streets.push(street);
      this.addStreet3d(street);
    }
    for (const jConnection of jsonData[JSON_KEYS.CONNECTIONS]) {
      let con = new StreetConnection(jConnection);
      this.connections.push(con);
      for (const street of this.streets) {
        if (street.beginPoint && con.pointUuids.includes(street.beginPoint.uuid)) {
          street.beginPoint.connectionUuid = con.uuid;
        }
        if (street.endPoint && con.pointUuids.includes(street.endPoint.uuid)) {
          street.endPoint.connectionUuid = con.uuid;
        }
      }
    }
    const cityBuilder = new CityBuilder();
    cityBuilder.buildCity(this.connections, this.streets, this.streets3d, this.streetNames3d, this.streetCategories3d);
    this.createHousesView(this.sceneHouses, DEFAULT_STREET_HOUSE_COUNT);
  }

  freeHouse(placement) {
    if (!placement) {
      return;
    }
    for (let street of this.streets) {
      if (street.uuid === placement.streetId) {
        street.houses[placement.placeId].holderId = null;
        street.houses[placement.placeId].houseType = HOUSE_FREE;
        if (street.uuid === this.getSelectedStreet().uuid) {
          street.houses[placement.placeId].updateHouseView(this.houses3d[placement.placeId], this.currentShopId, this.isSubscriptionMode, false);
        }
        return;
      }
    }
  }

  takeHouse(placement, holderId) {
    for (let street of this.streets) {
      if (street.uuid === placement.streetId) {
        street.houses[placement.placeId].holderId = holderId;
        street.houses[placement.placeId].houseType = HOUSE_TAKEN;
        if (street.uuid === this.getSelectedStreet().uuid) {
          street.houses[placement.placeId].updateHouseView(this.houses3d[placement.placeId], this.currentShopId, this.isSubscriptionMode, false);
        }

      }
    }
  }

  getCenter() {
    return this.way.position;
  }

  checkPlaceSubscription(streetId, placeId) {
    for(const street of this.placesOnStreets) {
      if (street.street.id === streetId) {
        for (const place of street.places) {
          if (placeId === place.id) {
            return false;
          }
        }
      }
    }
    return true;
  }

  updateCurrentHolderPlacement(newPlacement) {
    this.freeHouse(this.getCurrentHolderHousePlacement());
    this.takeHouse(newPlacement, this.currentShopId);
  }

  updateHousesBySelectedStreet(houseNumbers) {
    // houseNumbers is an array, that contents 20 integer elements.
    const street = this.getSelectedStreet();

    if (!street) {
      return;
    }

    this.setHousesView(street);

    if (this.isSubscriptionMode) {
      street.houses.forEach(house => {
        house.updateHouseViewBySelection();
      });
      this.updateHouseNumbers(houseNumbers);
    }
  }

  updateHouseNumbers(houseNumbers) {
    for (let i = 0; i < this.housesNumbers.length; i++) {
      this.sceneHouses.remove(this.housesNumbers[i]);
    }
    this.housesNumbers = [];
    for (let i = 0; i < houseNumbers.length; i++) {
      this.addHouseNumber(this.houses3d[i], houseNumbers[i].toString());
    }
  }

  setHouseNumbersVisibility() {
    for (const houseNumber of this.housesNumbers) {
      houseNumber.visible = this.isSubscriptionMode;
    }
  }

  setHousesView(street) {
    this.setHouseNumbersVisibility();
    this.setStaticStreetHousesView(street);
    for (let i = 0; i < street.houses.length; i++) {
      const checkResult = this.checkPlaceSubscription(street.uuid, i.toString());
      street.houses[i].updateHouseView(this.houses3d[i], this.currentShopId, this.isSubscriptionMode, checkResult);
    }
  }

  setStaticStreetHousesView(street) {
    const housesCount = street.houses.length;
    const houses2Count = housesCount + housesCount % 2;
    for (let i = 0; i < this.houses3d.length; i++) {
      this.houses3d[i].visible = street.isRegular || (i<housesCount);
      this.additionPlanes3d[i].visible = street.isRegular || (i<houses2Count);
    }
    const scale = (houses2Count) / 20;
    this.way.scale.set(scale, 1, 1);
    let summaryXSize = houses2Count / 2 * HOUSE_WIDTH + (houses2Count / 2 - 1) * MARGIN;
    let baseXSize = 10 * HOUSE_WIDTH + 9 * MARGIN;
    let dx = summaryXSize/2 - baseXSize/2;
    this.way.position.setX(dx);
    this.way.updateMatrixWorld();
  }

  createHousesView(sceneHouses, housesCount) {
    let marginSummarySize = (housesCount / 2 - 1) * MARGIN;
    let summaryXSize = housesCount / 2 * HOUSE_WIDTH + marginSummarySize;
    let summaryYSize = 3 * HOUSE_HEIGHT + 2 * MARGIN;

    const geometry = new THREE.PlaneGeometry(summaryXSize, HOUSE_WIDTH);
    this.way = new THREE.Mesh(geometry, WAY_MATERIAL);
    this.way.userData.housesView = true;
    sceneHouses.add(this.way);

    const wayAdditionGeometry = new THREE.PlaneGeometry(WAY_ADDITION_WIDTH, WAY_ADDITION_HEIGHT);
    const wayAdditionMaterial = new THREE.MeshBasicMaterial({ color: WAY_ADDITION_COLOR });
    for (let i = 0; i < housesCount; i = i + 1) {
      let addMargin = MARGIN;
      if (i <= 1) {
        addMargin = 0;
      }

      const geometry = new THREE.PlaneGeometry(HOUSE_WIDTH, HOUSE_HEIGHT);
      const material = new THREE.MeshBasicMaterial({ color: HOUSE_FREE_COLOR });
      let plane = new THREE.Mesh(geometry, material);
      let addPlane = new THREE.Mesh(wayAdditionGeometry, wayAdditionMaterial);
      plane.userData.housesView = true;
      plane.userData.houseId = i;
      if (i % 2 === 0) {
        plane.position.set(
          -summaryXSize / 2 + HOUSE_WIDTH / 2 + i / 2 * (HOUSE_WIDTH + MARGIN),
          summaryYSize / 2 - HOUSE_HEIGHT / 2 + MARGIN / 2);

        addPlane.position.set(
          plane.position.x + WAY_OFFSET + WAY_ADDITION_WIDTH / 2,
          plane.position.y - HOUSE_HEIGHT / 2 - 2.25 * MARGIN);
      } else {
        plane.position.set(
          -summaryXSize / 2 + HOUSE_WIDTH / 2 + (i - 1) / 2 * (HOUSE_WIDTH + MARGIN),
          -summaryYSize / 2 + HOUSE_HEIGHT / 2 - MARGIN / 2);

        addPlane.position.set(
          plane.position.x + WAY_OFFSET + WAY_ADDITION_WIDTH / 2,
          plane.position.y + HOUSE_HEIGHT / 2 + 2.25 * MARGIN);
        plane.rotateZ(Math.PI);
      }
      this.additionPlanes3d.push(addPlane);
      sceneHouses.add(addPlane);
      sceneHouses.add(plane);
      this.houses3d.push(plane);
    }
  }

  getCurrentHolderHousePlacement() {
    if (!this.currentShopId) {
      return null;
    }
    for (const street of this.streets) {
      for (const house of street.houses) {
        if (house.holderId === this.currentShopId) {
          return {
            placeId: house.id.toString(),
            streetId: street.uuid,
          };
        }
      }
    }
    return null;
  }

  getSelectedStreet(id) {
    if (id) {
      return this.streets.find(street => street.uuid === id);
    }

    for (const street of this.streets) {
      if (street.selected) {
        return street;
      }
    }

    return null;
  }

  getHouseById(id) {
    let street = this.getSelectedStreet();
    return street.houses[id];
  }

  estimateOffsetForText3d(text3d) {
    text3d.geometry.computeBoundingBox();
    if (text3d.geometry.boundingBox) {
      text3d.userData.offsetX = -0.5 * (text3d.geometry.boundingBox.max.x - text3d.geometry.boundingBox.min.x);
      text3d.userData.offsetY = -0.5 * (text3d.geometry.boundingBox.max.y - text3d.geometry.boundingBox.min.y);
    }
  }

  addHouseNumber(houseObject, text) {
    const textGeo = new TextGeometry(text, {
      bevelThickness: 0,
      bevelSize: 0,
      font: this.font,
      size: 0.3 * HOUSE_HEIGHT,
      height: 0,
      curveSegments: 20,
      bevelEnabled: true,
    });
    const textMaterial = new THREE.MeshBasicMaterial({
      color: 0x4270FF,
    });
    const text3d = new THREE.Mesh(textGeo, textMaterial);
    this.estimateOffsetForText3d(text3d);
    text3d.position.set(
      houseObject.position.x + text3d.userData.offsetX,
      houseObject.position.y + text3d.userData.offsetY,
      0.01);
    text3d.userData.houseId = houseObject.userData.houseId;
    text3d.visible = this.isSubscriptionMode;
    this.housesNumbers.push(text3d);
    this.sceneHouses.add(text3d);
    return text3d;
  }

  removeHouseNumber(id) {
    let toRemove = null;
    for (const houseNumber3d of this.housesNumbers) {
      if (houseNumber3d.userData.houseId === id) {
        toRemove = houseNumber3d;
      }
    }
    const index = this.housesNumbers.indexOf(toRemove, 0);
    if (index > -1) {
      this.housesNumbers.splice(index, 1);
    }
    this.sceneHouses.remove(toRemove);
  }

  addStreet3d(street) {
    const baseVector = new THREE.Vector3(1, 0, 0);
    const vec = street.vec();
    const angle = baseVector.angleTo(vec);
    const geometry = new THREE.PlaneGeometry(STREET_WIDTH, STREET_HEIGHT);
    geometry.rotateZ(angle);
    const material = new THREE.MeshBasicMaterial({ color: 0xefefef });
    const plane = new THREE.Mesh(geometry, material);
    plane.userData.uuid = street.uuid;
    this.sceneStreets.add(plane);
    this.streets3d.push(plane);
    const text = this.addStreetName3d(street, angle);
    const textCategory = street.hasCategory() ? this.addStreetCategory3d(street, angle) : null;
    const found = this.showedStreets.find(s => s === street.uuid);
    if (this.isSubscriptionMode && !found) {
      plane.visible = false;
      text.visible = false;
      if (textCategory) textCategory.visible = false;
    }
  }

  makeTextMesh(text, uuid, size) {
    const textGeo = new TextGeometry(text, {
      bevelThickness: 0,
      bevelSize: 0,
      font: this.font,
      size: size,
      height: 0,
      curveSegments: 20,
      bevelEnabled: true,
    });
    const textMaterial = new THREE.MeshBasicMaterial({
      color: 0xBFBFBF,
    });
    let text3d = new THREE.Mesh(textGeo, textMaterial);
    this.estimateOffsetForText3d(text3d);
    text3d.userData.uuid = uuid;
    return text3d;
  }

  addStreetName3d(street, angle) {
    const text3d = this.makeTextMesh(street.name, street.uuid, 0.04);
    this.streetNames3d.push(text3d);
    this.sceneStreets.add(text3d);
    text3d.rotateZ(angle);
    return text3d;
  }

  addStreetCategory3d(street, angle) {
    const text3d = this.makeTextMesh(street.categories[0], street.uuid, 0.034)
    this.streetCategories3d.push(text3d);
    this.sceneStreets.add(text3d);
    text3d.rotateZ(angle);
    return text3d;
  }

  selectStreet(uuid) {
    for (const street3d of this.streets3d) {
      street3d.material.color.setHex((street3d.userData.uuid === uuid) ? 0x4270ff : 0xefefef);
    }
    for (const street of this.streets) {
      street.selected = street.uuid === uuid;
    }
    for (const streetName of this.streetNames3d) {
      streetName.material.color.setHex((streetName.userData.uuid === uuid) ? 0xFFFFFF : 0xBFBFBF);
    }
    for (const stCategory of this.streetCategories3d) {
      stCategory.material.color.setHex((stCategory.userData.uuid === uuid) ? 0xFFFFFF : 0xBFBFBF);
    }
  }

  onStreetClick(obj) {
    const oldSelectedId = this.getSelectedStreet()?.uuid;
    this.selectStreet(obj.userData.uuid);
    return [oldSelectedId, this.getSelectedStreet().uuid];
  }

  onHouseClick(obj) {
    let holderPlacement = this.getCurrentHolderHousePlacement();
    let selectedStreet = this.getSelectedStreet();
    let house = this.getHouseById(obj.userData.houseId);

    if (this.isSubscriptionMode) {
      house.toggleHouseSelection();
      house.updateHouseViewBySelection();
      triggerScene("eventSetSelectedHouse", {
        streetId: selectedStreet.uuid,
        placesIds: [house.uuid],
        selected: house.selected,
      });
    }

    if (house.houseType === HOUSE_FREE) {
      if (this.checkPlaceSubscription(selectedStreet.uuid, obj.userData.houseId.toString())){
        const event = new CustomEvent("handleUpgradeToolTipIsOpen", {
          detail: {
            streetId: selectedStreet.uuid,
            placeId: obj.userData.houseId,
            holderId: house.holderId,
          },
        });
        document.dispatchEvent(event);
      }
      else {
        const event = new CustomEvent("handleChooseToolTipIsOpen", {
          detail: {
            currentPlacement: holderPlacement,
            placeId: obj.userData.houseId,
            streetId: selectedStreet.uuid,
            holderId: house.holderId,
          },
        });
        document.dispatchEvent(event);
      }
    }

    if (house.houseType === HOUSE_TAKEN) {
      let text = this.locales?.location_occupied_message;
      if (this.currentShopId === house.holderId) {
        text = this.locales?.this_is_your_location;
      }

      const event = new CustomEvent("handleOccupiedToolTipIsOpen", {
        detail: {
          streetId: selectedStreet.uuid,
          placeId: obj.userData.houseId,
          text: text,
          holderId: house.holderId,
        },
      });
      document.dispatchEvent(event);
    }
  }
}
