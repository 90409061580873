import React, { useContext, useEffect } from "react";
import AuthenticationPage from "../AuthenticationPage";
import { ShopEditProvider } from "../context/ShopEditContext";
import { ProductVariantsProvider } from "../context/ProductVariantsContext";
import { SnackbarProvider } from "notistack";
import Dashboard from "../Dashboard";
import { AuthContext } from "../context/AuthContext";
import { Auth } from "aws-amplify";
import useLogin from "../AuthenticationPage/hooks/useLogin";

const AppPages = (props) => {
  const { viewer, onSetUser, onSetPage, onSetViewer } = useContext(AuthContext);

  const { login, loginWithGoogle } = useLogin();

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const code = params.get('code');

    loginWithGoogle(code)
      .then(() => {
        return Auth.currentAuthenticatedUser()
      })
      .then((user) => {
        return login(user, user.attributes?.email);
      })
      .catch((e) => {
        if (e?.code === 'UserNotConfirmedException') {
          onSetPage('verifyCode');
        } else if (e === 'The user is not authenticated') {
          onSetPage('login');
          onSetUser(null);
          onSetViewer(null);
        }
      });
  }, []);

  const {
    DashboardComponent: DashboardComponentProp,
    dashboardComponentProps,
    plugins,
    snackbarProviderProps: snackbarProps
  } = props;

  const DashboardComponent = DashboardComponentProp || Dashboard;

  const snackbarProviderProps = {
    maxSnack: 3,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    ...snackbarProps
  };

  if (viewer) {
    return (
      <ShopEditProvider>
        <ProductVariantsProvider>
          <SnackbarProvider {...snackbarProviderProps}>
            <DashboardComponent {...dashboardComponentProps} plugins={plugins} />
          </SnackbarProvider>
        </ProductVariantsProvider>
      </ShopEditProvider>
    );
  }

  return <AuthenticationPage />;
};

export default AppPages;
