import gql from "graphql-tag";

export const removeProductCategory = gql`
  mutation removeProductCategory($id: ID!) {
    removeProductCategory(id: $id) {
      _id
    }
  }
`;

export const updateProductCategory = gql`
  mutation updateProductCategory($id: ID!, $input: UpdateProductCategoryInput!) {
    updateProductCategory(id: $id, input: $input) {
      _id
    }
  }
`;

export const addProductCategory = gql`
  mutation addProductCategory($name: String!) {
    addProductCategory(name: $name) {
      _id
    }
  }
`;
