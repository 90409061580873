import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";

const useFinancialsColumns = () => {
  const { t } = useTranslation();

  const statColumns = useMemo(() => [
    {
      name: 'totals',
      label: t('finance.totals'),
      sortable: false,
      isBold: true,
    },
    {
      name: 'description',
      label: t('finance.description'),
      sortable: false,
    },
    {
      name: 'amount',
      label: t('finance.amount'),
      sortable: false,
    },
  ], [t]);

  const orderColumns = useMemo(() => [
    {
      name: 'id',
      label: t('finance.order_id'),
      sortable: '_id',
    },
    {
      name: 'status',
      label: t('finance.status'),
    },
    {
      name: 'date',
      label: t('finance.date'),
      sortable: false,
    },
    {
      name: 'shopperId',
      label: t('finance.shopper_id'),
      sortable: 'accountId',
    },
    {
      name: 'shopperName',
      label: t('finance.shopper_name'),
      sortable: false,
    },
    {
      name: 'merchantId',
      label: t('finance.merchant_id'),
      sortable: 'companyId',
    },
    {
      name: 'merchantName',
      label: t('finance.merchant_name'),
      sortable: false,
    },
    {
      name: 'shopId',
      label: t('finance.shop_id'),
    },
    {
      name: 'shopName',
      label: t('finance.shop_name'),
      sortable: false,
    },
    {
      name: 'shopCategory',
      label: t('finance.shop_category'),
      sortable: 'shopCategoryId',
    },
    {
      name: 'payment',
      label: t('finance.payment'),
      sortable: 'paymentMethod',
    },
    {
      name: 'total',
      label: t('finance.total'),
      sortable: false,
    },
    {
      name: 'shipping',
      label: t('finance.shipping'),
      sortable: false,
    },
    {
      name: 'items',
      label: t('finance.items'),
      postfix: ` (${t('finance.total')} - ${t('finance.shipping')})`,
      sortable: false,
    },
    {
      name: 'commission',
      label: t('finance.commission'),
      postfix: ' (%)',
      sortable: 'commissionPercentage',
    },
    {
      name: 'oBuyCut',
      label: t('finance.wondersouq_cut'),
      sortable: false,
    },
    {
      name: 'merchantCut',
      label: t('finance.merchant_cut'),
      sortable: false,
    },
    {
      name: 'pay',
      label: t('finance.pay'),
      sortable: false,
    },
    {
      name: 'collect',
      label: t('finance.collect'),
      sortable: false,
    },
    {
      name: 'options',
    }
  ], [t]);

  const getSubColumns = useCallback((isGodmode) => [
    {
      name: 'id',
      label: t('finance.order_id'),
      sortable: 'account',
    },
    ...(isGodmode
      ? [
        {
          name: "merchant",
          label: t('finance.merchant'),
          sortable: 'companyId',
        },
      ]
      : []),
    {
      name: 'changeOrRenewal',
      label: t('finance.change_or_renewal'),
      sortable: 'type',
    },
    {
      name: 'description',
      label: t('finance.description'),
    },
    {
      name: 'status',
      label: t('finance.status'),
      sortable: 'paymentStatus',
    },
    {
      name: 'amount',
      label: t('finance.amount'),
      postfix: ' (USD)',
      sortable: 'paymentAmount',
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
      sortable: 'date',
    },
  ], [t]);

  const getTransactionColumns = useCallback((isMerchant) => [
    {
      name: 'id',
      label: t('finance.transaction_id'),
      sortable: '_id',
    },
    {
      name: 'from',
      label: t('finance.from'),
      sortable: 'companyId', // not very accurate since sometimes `from` is oBuy
    },
    {
      name: 'to',
      label: t('finance.to'),
      sortable: 'companyId', // not very accurate since sometimes `from` is oBuy
    },
    {
      name: 'amount',
      label: t('finance.amount'),
      postfix: ' (USD)',
    },
    {
      name: 'payment',
      label: t('finance.payment'),
      sortable: 'paymentMethod',
    },
    {
      name: 'type',
      label: t('finance.type'),
    },
    {
      name: 'recordId',
      label: t('finance.subscription_record_id'),
      sortable: 'subscriptionId',
    },
    {
      name: 'notes',
      label: t('finance.payment_notes'),
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    ...(isMerchant
      ? []
      : [
        {
          name: "options",
          label: t('finance.merchant'),
          sortable: 'companyId',
        },
      ]),
  ], [t]);

  return {
    statColumns,
    orderColumns,
    getSubColumns,
    getTransactionColumns,
  }
};

export default useFinancialsColumns;
