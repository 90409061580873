import {IconNavMerchantsRequests} from '../../icons';
import MerchantRequestsPage from "./pages/MerchantRequestsPage";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 70,
    path: "/merchant-requests",
    MainComponent: MerchantRequestsPage,
    IconComponent: IconNavMerchantsRequests,
    navigationItemLabel: 'menu.merchant_requests',
    godmodeOnly: true,
    badge: "numPendingMerchantRequests", // the key inside 'viewer' to read the value of the badge from
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });
}
