import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #222222;
  margin: 15px 0;
  
  > span {
    color: #4270FF;
  }
`

const AssetHeading = ({ children, optionalText }) => {
  return (
    <Wrapper>
      { children }
      {
        optionalText || optionalText === 0 ?
          <span> ({optionalText})</span> :
          null
      }
    </Wrapper>
  );
};

export default AssetHeading;
