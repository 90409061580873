import React from 'react';
import {Checkbox} from '@material-ui/core';
import { SvgAlternateCheckboxOn, SvgAlternateCheckboxOff } from "../../icons";
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  & .MuiSvgIcon-root {
    fill: white;
  }
`

const CheckboxAlternate = (props) => {
  return (
    <StyledCheckbox
      checkedIcon={<SvgAlternateCheckboxOn/>}
      icon={<SvgAlternateCheckboxOff/>}
      {...props}
    />
  );
}

export default CheckboxAlternate;
