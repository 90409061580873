import React, { useContext } from "react";
import styled from "styled-components";
import { ShopEditContext } from "../../../../../package/src/context/ShopEditContext";
import StatusWait from "./statuses/StatusWait";
import StatusCompleted from "./statuses/StatusCompleted";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 608px;
  padding: 40px 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 4px solid #E9E9E9;
  background: #FFF;
`;

const PaymentStatusPage = () => {
  const {
    paymentError,
    processing,
    paymentStatus,
  } = useContext(ShopEditContext);

  const getComponent = () => {
    if (paymentError || processing) {
      return <StatusWait />;
    }

    switch (paymentStatus) {
      case 'pending': return <StatusWait />;
      case 'open': return <StatusCompleted />;
      default: return null;
    }
  }

  return (
    <Wrapper>
      {getComponent()}
    </Wrapper>
  );
};

export default PaymentStatusPage;