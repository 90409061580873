import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useDiscountsColumns = () => {
  const { t } = useTranslation();

  const columns = useMemo(() => [
    {
      name: 'code',
      label: t('discount.coupon_code'),
    },
    {
      name: 'type',
      label: t('discount.type'),
      sortable: 'calculationMethod',
    },
    {
      name: 'value',
      label: t('discount.value'),
      sortable: 'discount',
    },
    {
      name: 'status',
      label: t('discount.status'),
      sortable: false,
    },
    {
      name: 'appliesTo',
      label: t('discount.applies_to'),
      sortable: false,
    },
    {
      name: 'customerEligibility',
      label: t('discount.name'),
      sortable: false,
    },
    {
      name: 'accountLimit',
      label: t('discount.account_limit'),
    },
    {
      name: 'totalLimit',
      label: t('discount.limit_in_total'),
      sortable: 'redemptionLimit',
    },
    {
      name: 'period',
      label: t('discount.validity_period'),
      sortable: false,
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    {
      name: 'options',
    }
  ], [t]);

  const triggersColumns = useMemo(() => [
    {
      name: 'code',
      label: t('discount.code'),
    },
    {
      name: 'type',
      label: t('discount.type'),
      sortable: 'calculationMethod',
    },
    {
      name: 'value',
      label: t('discount.value'),
      sortable: 'discount',
    },
    {
      name: 'accountLimit',
      label: t('discount.account_limit'),
      sortable: false,
    },
    {
      name: 'totalLimit',
      label: t('discount.total_limit'),
      sortable: false,
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    {
      name: 'options',
    }
  ], [t]);

  const salesColumns = useMemo(() => [
    {
      name: 'name',
      label: t('discount.sale_name'),
      sortable: 'code',
    },
    {
      name: 'type',
      label: t('discount.type'),
      sortable: 'calculationMethod',
    },
    {
      name: 'value',
      label: t('discount.value'),
      sortable: 'discount',
    },
    {
      name: 'status',
      label: t('discount.status'),
      sortable: false,
    },
    {
      name: 'period',
      label: t('discount.validity_period'),
      sortable: false,
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    {
      name: 'options',
    }
  ], [t]);

  const globalColumns = useMemo(() => [
    {
      name: 'name',
      label: t('discount.name'),
      sortable: false,
    },
    {
      name: 'commission',
      label: t('discount.commission'),
      sortable: false,
    },
    {
      name: 'period',
      label: t('discount.validity_period'),
      sortable: false,
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
      sortable: false,
    },
    {
      name: 'options',
    }
  ], [t]);

  return {
    columns,
    triggersColumns,
    salesColumns,
    globalColumns,
  }
};
