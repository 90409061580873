import React, { useContext } from "react";
import FormHeader from "../../../../../UI/Form/FormHeader";
import styled from "styled-components";
import { mockPaymentMethods } from "../../../mocks";
import PaymentMethodCell from "./PaymentMethodCell";
import { ShopEditContext } from "../../../../../package/src/context/ShopEditContext";

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

const Methods = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 32px;
`;

const PaymentMethodSelector = () => {
  const {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
  } = useContext(ShopEditContext);

  return (
    <Wrapper>
      <FormHeader noHorizontalRule>Select your payment method</FormHeader>
      <Methods>
        {
          mockPaymentMethods.map((item) => <PaymentMethodCell
            key={item.id}
            picSrc={item.picSrc}
            text={item.title}
            selected={item.id === selectedPaymentMethod}
            onClick={() => setSelectedPaymentMethod(item.id)}
          />)
        }
      </Methods>
    </Wrapper>
  );
};

export default PaymentMethodSelector;