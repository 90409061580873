import React from 'react';
import {Popper} from '@material-ui/core';
import {colors} from '../../constants';
import styled, { css } from "styled-components";

const StyledPopper = styled(Popper)`
  box-shadow: 0px 4px 20px #D8DADF;
  border-radius: 20px;
  margin-top: 11px;
  background: white;

  & .MuiAutocomplete-paper {
    border: none;
    border-radius: 20px;
    margin: 0;
    box-shadow: none;

    & .MuiAutocomplete-listbox {
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        width: 5px;
        height: 62px;
        background: #E9E9E9;
        border-radius: 6px;
      }

      & .MuiAutocomplete-groupUl {
        li {
          height: 42px;
          padding-left: 8px;

          &:hover, &.MuiAutocomplete-option[data-focus="true"] {
            background-color: ${colors.white8};
          }
        }
      }

      & .MuiListSubheader-root {
        padding: 0 40px;
      }

      .MuiAutocomplete-option[data-focus="true"] {
        background-color: ${colors.white8};
      }
    }
  }
  
  ${props => props.isrtl === 'true' && css`
    & .MuiAutocomplete-paper {
      & .MuiAutocomplete-listbox {
        & .MuiAutocomplete-groupUl {
          li {
            padding-left: 0;
            padding-right: 8px;
          }
        }
      }
    }
  `}
`

const CustomPopper = (props) => {
  return <StyledPopper {...props} />
}

export default CustomPopper;
