import React, { useEffect, useState } from "react";
import getShopQuery from "../graphql/queries/getShop";
import { useSnackbar } from "../../../package/src";
import { useApolloClient } from "@apollo/react-hooks";
import orderByReferenceId from "../graphql/queries/orderByReferenceId";
import PageStatusMessage from "../../../UI/other/PageStatusMessage";
import OrderToolbarPrint from "./OrderToolbarPrint";
import styled from "styled-components";
import { Container } from "@material-ui/core";
import Button from "../../../UI/Button/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import FormCard from "../../../UI/Form/FormCard";
import InputLabel from '../../../UI/Form/InputLabel';
import { colors } from '../../../constants';
import Grid from "@material-ui/core/Grid";
import FormHeader from "../../../UI/Form/FormHeader";
import FormDetails from "../../../UI/Form/FormDetails";
import moment from "moment";
import HorizontalRule from "../../../UI/other/HorizontalRule";
import { parseAddress } from "../helpers/modifiers";
import { useTranslation } from "react-i18next";
import useDisplayStatuses from "../hooks/useDisplayStatuses";

const StyledContainer = styled(Container)`
  max-width: 1020px;
`

const StyledPayment = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

const Printable = styled.div`
  width: 100%;
  
  @media print {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 10000;
    height: 200%;
    margin: 0;
    border: 1px solid ${colors.black};
    padding: 3em;
  }
`

const OrderPrint = ({match: { params }}) => {
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const { t } = useTranslation();
  const { getDisplayStatuses } = useDisplayStatuses();

  const getShop = () => {
    return apolloClient.query({
      query: getShopQuery,
      variables: {
        id: params.shopId,
      },
      fetchPolicy: "network-only",
    });
  }

  const getOrder = (language, shopId) => {
    return apolloClient.query({
      query: orderByReferenceId,
      variables: {
        id: params._id,
        language,
        shopId,
        token: null,
      },
      fetchPolicy: "network-only",
    })
  }

  useEffect(() => {
    setIsLoading(true);
    getShop()
      .then(res => {
        return res.data.shop;
      })
      .then(res => {
        return getOrder(res.language, res._id);
      })
      .then(res => {
        const orderData = res.data.order;
        if (orderData) {
          setOrder(orderData);
        }
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [])

  const renderMessage = () => {
    if (isLoading) {
      return <PageStatusMessage>{t('ui.loading')}...</PageStatusMessage>;
    }
    if (!order) {
      return <PageStatusMessage>{t('orders.order_not_found')}</PageStatusMessage>;
    }
    return false;
  }

  const fullName = order?.fulfillmentGroups[0].data.shippingAddress.fullName;
  const address = order && parseAddress(order.fulfillmentGroups[0].data.shippingAddress);

  return (
    <>
      <OrderToolbarPrint shopId={params.shopId}  referenceId={params._id}/>
      <StyledContainer>
        <FormCard>
          {
            renderMessage() ||
            <Printable id='printable'>

              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <FormHeader noHorizontalRule>{order.shop.name}</FormHeader>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormDetails isList>
                    <p className='title title--marginPrint'>{t('orders.order_invoice')}</p>
                    <p className='text'><span>{t('orders.order_id')}:</span> {params._id}</p>
                    <p className='text'><span>{t('orders.date')}:</span> {moment(order.createdAt).format('MM.DD.YYYY')}</p>
                  </FormDetails>
                </Grid>
              </Grid>

              <HorizontalRule/>

              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <FormDetails isList>
                    <p className='title title--marginPrint'>{t('orders.shipping_address')}</p>
                    <p className='text'><span>{t('orders.full_name')}:</span> {fullName}</p>
                    <p className='text'><span>{t('orders.shipping_address')}:</span> {address}</p>
                  </FormDetails>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormDetails isList>
                    <p className='title title--marginPrint'>{t('orders.billing_address')}</p>
                    <p className='text'><span>{t('orders.full_name')}:</span> {fullName}</p>
                    <p className='text'><span>{t('orders.billing_address')}:</span> {address}</p>
                    <p className='text'><span>{t('orders.tax_number')}:</span> 1234567890</p>
                  </FormDetails>
                </Grid>
              </Grid>

              <HorizontalRule/>

              <FormDetails isList>
                <p className='title title--marginPrint'>{t('orders.payments')}</p>
                {
                  order.payments.map(payment => {
                    const { amount, displayName, processor, transactionId, _id, status } = payment;
                    return <StyledPayment key={_id}>
                      <FormDetails>
                        <p className="title">{displayName}</p>
                        <p className="title">{amount.displayAmount}</p>
                      </FormDetails>
                      <FormDetails isList style={{ marginBottom: 24 }}>
                        <p className="text"><span>{t('orders.processor')}:</span> {processor}</p>
                        <p className="text"><span>{t('orders.transaction_id')}:</span> {transactionId}</p>
                        <p className="text"><span>{t('orders.status')}:</span> {getDisplayStatuses(status)}</p>
                      </FormDetails>
                    </StyledPayment>;
                  })
                }
              </FormDetails>

              <HorizontalRule/>

              <FormDetails isList>
                <p className='title title--marginPrint'>{t('orders.fulfillment')}</p>
                {
                  order.fulfillmentGroups.map((group, index, arr) => {
                    const currentGroupCount = index + 1;
                    const method = group.selectedFulfillmentOption.fulfillmentMethod;
                    return <React.Fragment key={group._id}>
                      <InputLabel>{t('orders.fulfillment_group')} {currentGroupCount} {t('orders.of')} {arr.length}</InputLabel>
                      <FormDetails isList style={{ marginBottom: 24 }}>
                        <p className="text"><span>{t('orders.shipping_method')}:</span> {method.carrier} - {method.displayName}</p>
                        <p className="text"><span>{t('orders.tracking_number')}:</span> {group.trackingNumber || t('orders.not_available')}</p>
                      </FormDetails>
                      {
                        group.items.nodes.map(item => {
                          const { price, productVendor, quantity, subtotal, title, variantTitle, _id } = item;
                          return <React.Fragment key={_id}>
                            <FormDetails>
                              <p className="title">{title}</p>
                              <p className="title">{price.displayAmount}</p>
                            </FormDetails>

                            <FormDetails isContent>
                              <div className="check">
                                <p>{t('orders.vendor')}: {productVendor}</p>
                                <p>{t('orders.variant')}: {variantTitle}</p>
                                <p>{t('orders.quantity')}: {quantity}</p>
                              </div>
                              <p className="title title--margin4">{t('orders.total')}({quantity}): {subtotal.displayAmount}</p>
                            </FormDetails>
                          </React.Fragment>;
                        })
                      }

                      <HorizontalRule/>
                    </React.Fragment>;
                  })
                }
              </FormDetails>

              <Grid item xs={12} lg={6}>
                <FormDetails isList>
                  <p className='title title--marginPrint'>{t('orders.summary')}</p>
                  {
                    order.summary.itemTotal &&
                    <FormDetails>
                      <p className="text">{t('orders.items')}</p>
                      <p className="text"><span>{order.summary.itemTotal.displayAmount}</span></p>
                    </FormDetails>
                  }
                  {
                    order.summary.fulfillmentTotal &&
                    <FormDetails>
                      <p className="text">{t('orders.shipping')}</p>
                      <p className="text"><span>{order.summary.fulfillmentTotal.displayAmount}</span></p>
                    </FormDetails>
                  }
                  {
                    order.summary.taxTotal &&
                    <FormDetails>
                      <p className="text">{t('orders.tax')}</p>
                      <p className="text"><span>{order.summary.taxTotal.displayAmount}</span></p>
                    </FormDetails>
                  }
                  {
                    order.summary.discountTotal &&
                    <FormDetails>
                      <p className="text">{t('orders.discounts')}</p>
                      <p className="text"><span>{order.summary.discountTotal.displayAmount}</span></p>
                    </FormDetails>
                  }
                  {
                    order.summary.surchargeTotal &&
                    <FormDetails>
                      <p className="text">{t('orders.surcharges')}</p>
                      <p className="text"><span>{order.summary.surchargeTotal.displayAmount}</span></p>
                    </FormDetails>
                  }
                  {
                    order.summary.total &&
                    <FormDetails>
                      <p className="text">{t('orders.total')}</p>
                      <p className="text"><span>{order.summary.total.displayAmount}</span></p>
                    </FormDetails>
                  }
                </FormDetails>
              </Grid>

              <HorizontalRule/>

              <FormDetails>
                <p className="text">{t('orders.thank_you_for_order')}</p>
              </FormDetails>
            </Printable>
          }
        </FormCard>

        <SaveButtons margin='24px 0' justifyContent='flex-end'>
          <Button
            disabled={isLoading || !order}
            width='180px'
            onClick={() => window.print()}
          >
            {t('orders.print_invoice')}
          </Button>
        </SaveButtons>
      </StyledContainer>
    </>
  );
}

export default OrderPrint;
