import styled from "styled-components";
import { IconButton } from "@material-ui/core";

const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  background: ${props => props.active === 'true' ? '#E9EBF0' : '#F6F6F6'};
  
  &:hover {
    background: #E9EBF0;
  }
`;

export default StyledIconButton;
