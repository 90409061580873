import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";

// GraphQL
import { getShopCategoriesQuery } from "../graphql/queries";
import {
  removeShopCategoryMutation,
  updateShopCategoryMutation,
  addShopCategoryMutation,
} from "../graphql/mutations";

import Toolbar from "../../../package/src/Toolbar";
import Dialog from "../../../UI/Dialog/Dialog";
import { Grid, Hidden, makeStyles } from "@material-ui/core";
import StyledButton from "../../../UI/Button";
import Search from "../../../UI/Search";
import DataTable from "../../../UI/DataTable";
import moment from "moment";
import svgEdit from "../../../icons/table-edit.svg";
import svgDelete from "../../../icons/delete.svg";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import FormShopCategory from "../components/FormShopCategory";
import { constants } from "../../../constants";
import { useShopCategoriesColumns } from "../hooks/useShopCategoriesColumns";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  gridColumnLeft: {
    display: 'flex',
    gap: 20,
  },
  gridColumnRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  gridCreateMobile: {
    marginTop: 32,
  },
}));

// Component
const ShopCategoriesPage = ()  => {
  const [searchValue, setSearchValue] = useState('');
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [reloadTable, setReloadTable] = useState(false);
  const [rowId, setRowId] = useState('');
  const [formData, setFormData] = useState({});
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const columns = useShopCategoriesColumns();

  const categoryOptions = useMemo(() => [
    {
      value: constants.OPTIONS.editCategory,
      label: t('shops.edit_category'),
      icon: svgEdit,
      handleOptionClick: (rowId) => {
        const data = tableData.find(item => item._id === rowId);
        setFormData({
          name: data.name,
          description: data.description,
        })
        setRowId(rowId);
        setDialogName('edit');
        setDialogOpen(true);
      },
    },
    {
      value: constants.OPTIONS.deleteCategory,
      label: t('shops.delete_category'),
      icon: svgDelete,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('delete');
        setDialogOpen(true);
      },
    },
  ], [tableData, t]);

  const dataProps = useMemo(() => tableData.map(item => ({
      id: item._id,
      name: item.name,
      description: item.description,
      createdAt: moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a"),
      options: categoryOptions,
    })
  ), [tableData]);

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
      setIsLoading(true);
      apolloClient.query({
        query: getShopCategoriesQuery,
        variables: {
          shopCategoryIds: [],
          filter: globalFilter,
          first: pageSize,
          limit: (pageIndex + 1) * pageSize,
          offset: pageIndex * pageSize,
          sortOrder,
          sortBy,
        },
        fetchPolicy: "network-only",
      })
        .then(res => {
          // Update the state with the fetched data as an array of objects and the calculated page count
          const total = res.data.getShopCategories.totalCount;
          setTableData(res.data.getShopCategories.nodes);
          setPageCount(Math.ceil(total / pageSize));
          setTotalCount(total);
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setIsLoading(false))
    }, []);

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const exportData = () => {
    const fields = columns.filter(item => item.name !== 'options');
    downloadCSV(fields, tableData);
  };

  const renderNoButton = useMemo(() =>
      <StyledButton
        width='180px'
        mytype='secondary'
        handleClick={() => setDialogOpen(false)}
      >
        {t('ui.no')}
      </StyledButton>
    , [t])

  const updateCategory = useCallback((newFormData) => {
    setDialogOpen(false);
    apolloClient.mutate({
      mutation: updateShopCategoryMutation,
      variables: {
        id: rowId,
        input: newFormData,
      },
    })
      .then(res => {
        if (res) {
          refetch();
          enqueueSnackbar(t('shops.shop_category_update_success'), {variant: 'success'});
        }
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
  }, [rowId])

  const addCategory = useCallback((newFormData) => {
    setDialogOpen(false);
    apolloClient.mutate({
      mutation: addShopCategoryMutation,
      variables: {
        input: newFormData,
      },
    })
      .then(res => {
        if (res) {
          refetch();
          enqueueSnackbar(t('shops.shop_category_added_success'), {variant: 'success'});
        }
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
  }, [])

  const DialogDelete = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('shops.delete_category_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              setDialogOpen(false);
              apolloClient.mutate({
                mutation: removeShopCategoryMutation,
                variables: {
                  id: rowId,
                },
              })
                .then(res => {
                  if (res) {
                    refetch();
                    enqueueSnackbar(t('shops.delete_category_success'), {variant: "success"});
                  }
                })
                .catch((e) => {
                  enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                  console.log(e.message);
                })
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [rowId])

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'delete': return DialogDelete;
      case 'add':
      case 'edit': return <FormShopCategory
          confirmAction={String(dialogName) === 'edit' ? updateCategory : addCategory}
          initialProps={formData}
          dialogName={dialogName}
        />;
      default: return null;
    }
  }

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const renderCreateShop = () =>
    <StyledButton
      minwidth='240px'
      width='240px'
      handleClick={() => {
        setFormData({
          name: t('shops.new_category'),
          description: '',
        })
        setDialogName('add');
        setDialogOpen(true);
      }}
    >
      {t('shops.create_shop_category')}
    </StyledButton>

  return (
    <>
      <Grid container>
        <Toolbar title={t('shops.shop_categories')} />

        <Grid item xs={12} lg={7} className={classes.gridColumnLeft}>
          <Search
            handleChange={handleSearch}
            onSearchClick={refetch}
            placeholder={t('ui.search')}
            useDebounce
          />
          <StyledButton
            width='140px'
            mytype='secondary'
            handleClick={exportData}
          >
            {t('ui.export')}
          </StyledButton>
        </Grid>

        <Grid item xs={12} lg={5} className={classes.gridColumnRight}>
          <Hidden mdDown>
            {renderCreateShop()}
          </Hidden>
        </Grid>

        <Hidden lgUp >
          <Grid item xs={12} className={classes.gridCreateMobile}>
            {renderCreateShop()}
          </Grid>
        </Hidden>

        <DataTable
          columns={columns}
          isLoading={isLoading}
          data={dataProps}
          handleFetchData={onFetchData}
          pageCount={pageCount}
          totalCount={totalCount}
          searchValue={searchValue}
          reloadTable={reloadTable}
          showCheckboxes={false}
          defaultSortField='createdAt'
          defaultSortOrder='desc'
        />
      </Grid>
      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>
  );
};

ShopCategoriesPage.propTypes = {
  client: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      shopCategoryId: PropTypes.string,
    }),
  }),
  shopCategoryId: PropTypes.string,
};

export default ShopCategoriesPage;
