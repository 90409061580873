import React from 'react';
import styled from 'styled-components';

const StyledForm = styled.form`
  width: ${props => props.width ? props.width : '792px'};
  @media all and (max-width: 960px) {
    width: 100%;
  }
`

export function Form(props) {
  const { children, ...other } = props;
  return (
    <StyledForm autoComplete="off" {...other}>
      {children}
    </StyledForm>
  )
}