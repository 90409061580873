import * as JSON_KEYS from "../constants/NameConsts";
import StreetPoint from "./StreetPoint";
import House from "./House";
import * as THREE from "three";

export default class Street {
  name; // string
  categories; // array of string or null
  streetType; // string
  selected; // boolean
  beginPoint; // StreetPoint
  endPoint; // StreetPoint
  uuid; // string
  houses; // array of House
  isRegular; // boolean

  constructor(jsonData) {
    this.selected = false;
    this.name = jsonData[JSON_KEYS.NAME];
    this.uuid = jsonData[JSON_KEYS.UUID];
    this.streetType = jsonData[JSON_KEYS.TYPE];
    this.isRegular = (this.streetType === JSON_KEYS.STREET_REGULAR_TYPE);
    this.beginPoint = this.isRegular ? new StreetPoint(jsonData[JSON_KEYS.BEGIN_POINT]) : null;
    this.endPoint = this.isRegular ? new StreetPoint(jsonData[JSON_KEYS.END_POINT]) : null;
    this.houses = [];
    this.categories = jsonData[JSON_KEYS.STREET_CATEGORIES];
    let placementId = 0;
    for (const jHouse of jsonData[JSON_KEYS.HOUSES]) {
      const selected = jsonData[JSON_KEYS.SELECTED_PLACES]?.includes(jHouse.uuid);
      this.houses.push(new House(jHouse, placementId, selected));
      placementId += 1;
    }
  }

  hasCategory() {
    return this.categories.length > 0;
  }

  // Direction vector of street
  vec() {
    if (!this.isRegular) {
      return new THREE.Vector3(1, 0, 0);
    }
    return this.endPoint.getPoint().add(this.beginPoint.getPoint().negate());
  }

  points() {
    return [this.beginPoint, this.endPoint];
  }

  isConnectionHasBeginPoint(connection) {
    if (!this.isRegular) {
      return false;
    }
    return connection.pointUuids.includes(this.beginPoint.uuid);
  }

  updateAllHousesSelectionView(value) {
    this.houses.forEach(house => {
      house.selected = value;
      house.updateHouseViewBySelection();
    });
  }
}

