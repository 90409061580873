/**
 *
 * @param {*} total
 * @param {*} commissionPercentage 0-100
 * @param {*} payment online or offline
 * @param {*} viewerType merchant or godmode
 */
const calculateAccountingValues = (total, commissionPercentage, payment, viewerType) => {
  total = parseFloat(total);

  const commissionFactor = parseFloat(commissionPercentage) / 100;
  let godmodeCut = commissionFactor * total;
  let merchantCut = total - godmodeCut;

  let pay = 0;
  let collect = 0;

  if (viewerType === "merchant") {
    if (payment === "Offline") {
      pay = commissionFactor * total;
    } else {
      collect = (1 - commissionFactor) * total;
    }
  } else {
    // godmode
    if (payment === "Offline") {
      collect = commissionFactor * total;
    } else {
      pay = (1 - commissionFactor) * total;
    }
  }

  return {
    godmodeCut: Math.floor(godmodeCut * 100) / 100,
    merchantCut: Math.floor(merchantCut * 100) / 100,
    pay: Math.floor(pay * 100) / 100,
    collect: Math.floor(collect * 100) / 100,
  };
};

export default calculateAccountingValues;
