import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {Menu} from '@material-ui/core';
import MenuItem from '../../UI/MenuItem';
import styled from 'styled-components';
import { makeStyles } from "@material-ui/core/styles";
import ComplexMenuButton from "./ComplexMenuButton";
import { LanguageContext } from "../../package/src/context/LanguageContext";

const StyledDropdown = styled.div`
  min-width: 225px;
  position: relative;
`

const useStyles = makeStyles(() => ({
  dropdownStyle: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 20px #D8DADF',
    borderRadius: 20,
    marginLeft: -4,
  },
}))

const DropdownComplex = React.forwardRef(({title, itemsList = [], MenuItemsList, setId}, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const myId = `simple-menu-${setId}`;
  const { isRtl } = useContext(LanguageContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <StyledDropdown ref={ref}>
      <ComplexMenuButton
        onClick={handleClick}
        aria-owns={anchorEl ? myId : undefined}
        aria-haspopup="true"
        open={open}
        isRtl={isRtl}
      >
        {title ? title : null}
      </ComplexMenuButton>

      <Menu
        open={open}
        onClose={handleClose}
        id={myId}
        anchorEl={anchorEl}
        PopoverClasses={{ paper: classes.dropdownStyle }}
        getContentAnchorEl={null}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {
          MenuItemsList ?
          MenuItemsList :
          itemsList.map((item, index) => <MenuItem
              onClick={item.action}
              key={index}
              disabled={item.isDisabled}
            >
              {item.content}
            </MenuItem>)
        }
      </Menu>
    </StyledDropdown>
  );
})

DropdownComplex.propTypes = {
  title: PropTypes.string,
  itemsList: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]).isRequired,
    action: PropTypes.func,
    isDisabled: PropTypes.bool,
    itemsList: PropTypes.array,
  })),
  setId: PropTypes.number,
  MenuItemsList: PropTypes.element,
}

export default DropdownComplex;
