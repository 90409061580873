import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const useBrandsColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'name',
      label: t('brand.brand'),
      isBold: true,
    },
    {
      name: 'createdBy',
      label: t('brand.created_by'),
      sortable: 'accountId',
    },
    {
      name: 'email',
      label: t('brand.email'),
      sortable: false,
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    {
      name: 'options',
    }
  ], [t]);
};

export default useBrandsColumns;
