import React from "react";
import styled from "styled-components";
import { IconButton, Typography } from "@material-ui/core";

const StyledButton = styled(IconButton)`
  background: none;
  padding: 10px;
  border-radius: 0;
  min-width: 40px;
  
  &:hover {
    background: none;
  }
  
  .MuiIconButton-label {
    gap: 8px;
  }
`;

const StyledText = styled(Typography)`
  color: #9A9A9A;
  font-family: Fredoka, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;

const ButtonIconSimple = ({ picSrc, text, onClick = () => undefined }) => {
  return (
    <StyledButton
      onClick={onClick}
    >
      <img
        src={picSrc}
        alt=''
      />
      {
        text ? <StyledText>
          {text}
        </StyledText>
          : null
      }
    </StyledButton>
  );
};

export default ButtonIconSimple;
