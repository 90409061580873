export function cleanReaderResult(str) {
  const arr = [
    'data:image/png;base64,',
    'data:image/gif;base64,',
    'data:image/jpg;base64,',
    'data:image/jpeg;base64,',
  ];

  let result = str;

  arr.forEach((item) => {
    result = result.replace(item, '');
  });

  return result;
}
