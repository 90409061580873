export const mockLocations = [
  {
    name: 'Location 1',
    value: 'location1',
  },
  {
    name: 'Location 2',
    value: 'location2',
  },
]

export const mockCurrencies = [
  {
    name: 'USD',
    value: 'USD-id',
  },
]
