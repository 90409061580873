import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container } from "@material-ui/core";
import styled from 'styled-components';
import OrderToolbar from "../components/OrderToolbar";
import { useSnackbar } from "../../../package/src";
import { useApolloClient } from "@apollo/react-hooks";
import getShopQuery from "../graphql/queries/getShop";
import orderByReferenceId from "../graphql/queries/orderByReferenceId";
import OrderGroup from "../components/OrderGroup";
import splitOrderItemMutation from '../graphql/mutations/splitOrderItem';
import updateOrderFulfillmentGroupMutation from '../graphql/mutations/updateOrderFulfillmentGroup';
import OrderHeader from "../components/OrderHeader";
import OrderPayments from "../components/OrderPayments";
import captureOrderPaymentsMutation from "../graphql/mutations/captureOrderPayments";
import approveOrderPaymentsMutation from '../graphql/mutations/approveOrderPayments'
import cancelOrderItemMutation from '../graphql/mutations/cancelOrderItem';
import Dialog from "../../../UI/Dialog/Dialog";
import DialogCancelOrder from "../dialogs/DialogCancelOrder";
import PageStatusMessage from "../../../UI/other/PageStatusMessage";
import { useTranslation } from "react-i18next";

const StyledContainer = styled(Container)`
  max-width: 1000px;
  
  .headingLeft {
    display: flex;
    gap: 16px;
  }
  
  .headingRight {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
  
  .textOrder {
    font-family: 'Fredoka', sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: black;
    margin: 0 0 12px 0;
  }

  @media all and (max-width: 1279px) {
    .headingRight {
      justify-content: flex-start;
      margin: 0 0 32px 0;
    }
  }
`

const OrderContainer = ({match, history}) => {
  const orderId = match.params._id;
  const shopId = match.params.shopId;
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [order, setOrder] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const isOrder = Boolean(Object.keys(order).length);

  const refetch = () => {
    setRefresh(prev => !prev);
  }

  useEffect(() => {
    setIsLoading(true);
    getShop()
      .then(res => {
        return res.data.shop;
      })
      .then(res => {
        return getOrder(res.language, res._id);
      })
      .then(res => {
        const orderData = res.data.order;
        if (orderData) {
          setOrder(orderData);
        } else {
          setOrder(t('orders.order_not_found'));
        }
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [refresh, t])

  const getShop = () => {
    return apolloClient.query({
      query: getShopQuery,
      variables: {
        id: shopId,
      },
      fetchPolicy: "network-only",
    });
  }

  const getOrder = (language, id) => {
    return apolloClient.query({
      query: orderByReferenceId,
      variables: {
        id: orderId,
        language,
        shopId: id,
        token: null,
      },
      fetchPolicy: "network-only",
    })
  }

  const approvePayments = (paymentIdList, skip) => {
    if (skip) {
      return Promise.resolve();
    }
    return apolloClient.mutate({
      mutation: approveOrderPaymentsMutation,
      variables: {
        orderId: order._id,
        paymentIds: paymentIdList,
        shopId: order.shop._id
      }
    })
  }

  const cancelGroup = (item) => {
    console.log({ item, order });
    return apolloClient.mutate({
      mutation: cancelOrderItemMutation,
      variables: {
        cancelQuantity: item.quantity,
        itemId: item._id,
        orderId: order._id,
        reason: "Fulfillment group cancelled via Catalyst",
      },
    })
  }

  const saveQuantity = useCallback(({quantityNew, itemId, error}) => {
    if (error) {
      enqueueSnackbar(t('orders.only_decrease'), {variant: 'error'});
      return;
    }

    setIsLoading(true);
    apolloClient.mutate({
      mutation: splitOrderItemMutation,
      variables: {
        input: {
          itemId,
          newItemQuantity: quantityNew,
          orderId: order._id,
        }
      }
    })
      .then(() => {
        enqueueSnackbar(t('orders.decrease_quantity_success'), { variant: "success" });
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [order, t])

  const saveTrackingNumber = useCallback(({orderFulfillmentGroupId, tracking}) => {
    setIsLoading(true);
    apolloClient.mutate({
      mutation: updateOrderFulfillmentGroupMutation,
      variables: {
        orderFulfillmentGroupId,
        tracking,
        orderId: order._id,
      }
    })
      .then(() => {
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [order, t])

  const handleCapturePayments = useCallback((paymentIds) => {
    // We need to approve all payments first in order for them to be
    // allowed to be captured. This is a legacy workflow step, and we can
    // look into removing it in the future. For now, we just combined it into
    // the capture flow.
    const paymentIdsNeedingApproval = order.payments
      .filter(payment => paymentIds.includes(payment._id) && ["adjustments", "created"].includes(payment.status))
      .map(payment => payment._id);

    setIsLoading(true);
    approvePayments(paymentIdsNeedingApproval, paymentIdsNeedingApproval.length === 0)
      .then(() => {
        return apolloClient.mutate({
          mutation: captureOrderPaymentsMutation,
          variables: {
            orderId: order._id,
            paymentIds,
            shopId: order.shop._id
          }
        });
      })
      .then(() => {
        const message = t('orders.captured_payments_success', { count: paymentIds.length });
        enqueueSnackbar(message, {variant: 'success'});
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [order, t])

  const handleCancelGroup = useCallback(async(groups) => {
    setIsLoading(true);
    const successes = [];

    try {
      for (const node of groups.items.nodes) {
        const { data } = await cancelGroup(node);
        if (data) successes.push(data);
      }
    } catch(e) {
      enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
      console.log(e.message);
      setIsLoading(false);
    }

    if (successes.length) {
      setIsLoading(false);
      enqueueSnackbar(t('orders.cancel_group_success'), {variant: 'success'});
      refetch();
    }
  }, [order, t])

  const handleUpdateGroupStatus = useCallback((group, value) => {
    setIsLoading(true);
    apolloClient.mutate({
      mutation: updateOrderFulfillmentGroupMutation,
      variables: {
        orderFulfillmentGroupId: group._id,
        orderId: order._id,
        status: value,
      },
    })
      .then(() => {
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [order, t])

  const renderMessage = () => {
    // if (isLoading) {
    //   return <PageStatusMessage>loading...</PageStatusMessage>;
    // }
    if (typeof order === 'string') {
      return <PageStatusMessage>{order}</PageStatusMessage>;
    }
    return false;
  }

  const renderGroups = useMemo(() => {
    if (isOrder) {
      const totalGroupsCount = order.fulfillmentGroups.length;
      return order.fulfillmentGroups.map((group, index) => <OrderGroup
          group={group}
          key={group._id}
          currentGroupCount={index + 1}
          totalGroupsCount={totalGroupsCount}
          saveQuantity={saveQuantity}
          saveTrackingNumber={saveTrackingNumber}
          isLoading={isLoading}
          onCancelGroup={() => handleCancelGroup(group)}
          onUpdateGroupStatus={(value) => handleUpdateGroupStatus(group, value)}
        />);
    }
    return null;
  }, [order, isLoading, isOrder])

  const closeDialog = () => {
    setDialogOpen(false);
  }

  const handlePrint = () => {
    history.push(`/orders/print/${order.shop._id}/${orderId}`)
  }

  return (
    <>
      <OrderToolbar title={t('orders.order_details')} />
      {
        renderMessage() ||
        <StyledContainer>

          {
            isOrder && <OrderHeader
              order={order}
              orderId={orderId}
              isLoading={isLoading}
              cancelOrder={() => setDialogOpen(true)}
              handlePrint={handlePrint}
            />
          }

          { renderGroups }

          {
            isOrder &&
            <OrderPayments
              order={order}
              capturePayments={handleCapturePayments}
              isLoading={isLoading}
            />
          }
        </StyledContainer>
      }

      <Dialog
        open={dialogOpen}
        handleClose={closeDialog}
      >
        <DialogCancelOrder
          declineAction={closeDialog}
          order={order}
          refetch={refetch}
        />
      </Dialog>
    </>
  );
}

export default OrderContainer;
