import React, { useEffect, useMemo, useState } from "react";
import {Form } from '../../../UI/Form/MyForm';
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import Select from "../../../UI/Select/Select";
import Button from "../../../UI/Button/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import formatMoney from "../../../package/src/utils/formatMoney";
import { useTranslation } from "react-i18next";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import RefundRow from "./RefundRow";
import Dialog from "../../../UI/Dialog/Dialog";
import StyledButton from "../../../UI/Button/Button";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import createRefundMutation from '../graphql/mutations/createRefund';
import OrderPreviousRefunds from "./OrderPreviousRefunds";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "../../../package/src";
import { useReasonOptions } from "../hooks/useReasonOptions";

const paramsDefaults = {
  areAnyPaymentsRefundable: false,
  areAllPaymentsFullyRefunded: false,
  calculatedRefundTotalDisplay: '',
  orderPreviousRefundTotal: '',
  orderAmountAvailableForRefund: '',
  orderAmountAvailableForRefundDisplay: '',
}

const OrderRefunds = ({ order, declineAction, refetch, setOrder }) => {
  const { t } = useTranslation();
  const isOrder = Boolean(Object.keys(order).length);
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const [hasChanged, setHasChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refundTotal, setRefundTotal] = useState(0);
  const [params, setParams] = useState(paramsDefaults);
  const [reason, setReason] = useState('');
  const [amounts, setAmounts] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const reasonOptions = useReasonOptions();

  const handleInputChange = (field, val) => {
    // 'field' uses payments._id as the key
    setAmounts(prev => ({
      ...prev,
      [field]: val,
    }));
    setHasChanged(true);
  }

  useEffect(() => {
    const reducedRefundTotal = Object.keys(amounts)
      .map(paymentId => ({
        paymentId,
        amount: parseFloat(amounts[paymentId], 10),
      }))
      .filter(payment => payment.amount && payment.amount > 0)
      .reduce((acc, value) => acc + value.amount, 0);

    setRefundTotal(reducedRefundTotal);
  }, [amounts])

  useEffect(() => {
    if (isOrder) {
      setLoading(false);
      const payments = order.payments;
      const orderPreviousRefundTotal = order.refunds.reduce((acc, refund) => acc + refund.amount.amount, 0);
      const orderAmountAvailableForRefund = order.summary.total.amount - orderPreviousRefundTotal;

      setParams({
        areAnyPaymentsRefundable: payments.some(payment => payment.method.canRefund === true),
        areAllPaymentsFullyRefunded: payments.every(payment => payment.status === "refunded"),
        // calculated refund total from inputs
        calculatedRefundTotalDisplay: formatMoney(refundTotal, order.currencyCode),
        // previous refunds
        orderPreviousRefundTotal,
        // available to refund
        orderAmountAvailableForRefund,
        orderAmountAvailableForRefundDisplay: formatMoney(orderAmountAvailableForRefund, order.currencyCode),
      })
    }
  }, [isOrder, refundTotal])

  useEffect(() => {
    // clear the order after closing the dialog
    return () => setOrder({});
  }, [])

  const mutationRefund = (payload) => {
    return apolloClient.mutate({
      mutation: createRefundMutation,
      variables: payload,
    });
  }

  const handleCreateRefund = async() => {
    const successes = [];
    const isReason = reason !== '' && reason !== 'none';
    // turn form data into an array of payments that provide paymentID and amount
    // then filter out any amounts that are `null` or `0`
    const paymentsToRefund = Object.keys(amounts)
      .map(paymentId => ({
        paymentId,
        amount: parseFloat(amounts[paymentId], 10)
      }))
      .filter(payment => payment.amount && payment.amount > 0);

    try {
      for (const payment of paymentsToRefund) {
        const variables = {
          amount: payment.amount,
          orderId: order._id,
          paymentId: payment.paymentId
        };

        // Stripe will not accept an empty string or `none` value for the `reason` field,
        // so only include `reason` in the mutation only if its' value is set
        if (isReason) {
          variables.reason = reason;
        }
        const { data } = await mutationRefund(variables);
        if (data) successes.push(data);
      }
    } catch(e) {
      enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
      console.log(e.message);
    }

    if (successes.length) {
      declineAction();
      refetch();
    }
  }

  const renderCancel = useMemo(() => <Button
    mytype='third'
    width='180px'
    handleClick={declineAction}
    disabled={loading}
  >
    {t('ui.cancel')}
  </Button>, [loading])

  const renderMessage = () => {
    if (!isOrder) {
      return false;
    }
    if (!params.areAnyPaymentsRefundable) {
      return <>
        <DialogMessages
          message={t("orders.refunds_not_supported")}
          style={{ marginBottom: 32 }}
          messageMaxWidth='100%'
        />
        {renderCancel}
      </>
    } else if (params.areAllPaymentsFullyRefunded) {
      return <>
        <DialogMessages
          message={t("orders.all_payments_refunded")}
          style={{ marginBottom: 32 }}
          messageMaxWidth='100%'
        />
        {renderCancel}
      </>
    } else {
      return false;
    }
  }

  const buttonText = refundTotal > params.orderAmountAvailableForRefund
    ? params.orderAmountAvailableForRefundDisplay
    : params.calculatedRefundTotalDisplay;

  return (
    <>
      <Form>
        <FormHeader>{t("orders.refund")}</FormHeader>
        {
          renderMessage() ||
          <>
            {
              order.payments?.map(payment => <RefundRow
                key={payment._id}
                order={order}
                payment={payment}
                onChange={(val) => handleInputChange(payment._id, val)}
              />
              )
            }
            <InputLabel>{t('orders.reason')}</InputLabel>
            <Select
              placeholder={t('orders.choose_reason')}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              options={reasonOptions}
              disabled={loading}
            />

            <SaveButtons>
              <Button
                disabled={!hasChanged || loading || refundTotal === 0}
                minwidth='180px'
                onClick={() => {
                  setDialogOpen(true);
                }}
              >
                {t('orders.refund_amount', { amount: buttonText })}
              </Button>
              {renderCancel}
            </SaveButtons>
          </>
        }
      </Form>

      {
        order.refunds?.length ?
          <OrderPreviousRefunds order={order}/> : null
      }

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        <DialogMessages
          title={t("orders.refund")}
          message={t('orders.apply_refund_q', { refund: buttonText })}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              setDialogOpen(false);
              handleCreateRefund();
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          <StyledButton
            width='180px'
            mytype='secondary'
            handleClick={() => setDialogOpen(false)}
          >
            {t('ui.no')}
          </StyledButton>
        </DialogButtons>
      </Dialog>
    </>
  );
}

export default OrderRefunds;
