import styled from "styled-components";

const ColorCircleBorder = styled('div')`
  border: 2px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: absolute;
  left: 0;
  top: 0;
`;

export default ColorCircleBorder;
