import React, { useContext } from "react";
import { Icon, Typography } from "@material-ui/core";
import svgCompleted from '../../../../../../icons/payment-completed.svg';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import FormText from "../../../../../../UI/Form/FormText";
import Button from "../../../../../../UI/Button";
import { ShopEditContext } from "../../../../../../package/src/context/ShopEditContext";

const StyledIcon = styled(Icon)`
  width: 75px;
  height: 75px;
`;

const StatusText = styled(Typography)`
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: black;
`;

const StatusCompleted = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    setCurrentTab,
  } = useContext(ShopEditContext);

  const handleCreateShop = () => {
    setCurrentTab('info');
    history.push('/shops/create');
  }

  return (
    <>
      <StyledIcon>
        <img src={svgCompleted} alt=''/>
      </StyledIcon>
      <StatusText>
        {t('payment.payment_was_complete')}
      </StatusText>
      <FormText
        style={{ maxWidth: '472px', textAlign: 'center' }}
      >
        {t('payment.payment_complete_message')}
      </FormText>
      <Button
        width='200px'
        handleClick={handleCreateShop}
      >
        {t('payment.create_shop')}
      </Button>
    </>
  );
};

export default StatusCompleted;
