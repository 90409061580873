import {IconNavFinancials} from '../../icons';
import FinancialsPage from "./pages/FinancialsPage";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 80,
    path: "/financials/:tab",
    href: "/financials/0",
    MainComponent: FinancialsPage,
    IconComponent: IconNavFinancials,
    navigationItemLabel: 'menu.balance',
    collapse: {
      title: 'menu.financials',
      IconComponent: IconNavFinancials, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });
}
