import React, { useContext, useEffect, useState } from "react";
import LayoutContent from "../layouts/LayoutContent";
import { EntranceVariantsContainer, FormHeaderText, FormHeaderWrap, WosqHeading } from "../styles";
import LayoutForm from "../layouts/LayoutForm";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { LoginSchema } from "../schemas";
import { Form } from "../../../../UI/Form/MyForm";
import InputLabel from "../../../../UI/Form/InputLabel";
import FormInput from "../../../../UI/Form/FormInput";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import LinkButton from "../components/LinkButton";
import CustomButton from "../../../../UI/Button";
import HorizontalRuleWithText from "../components/HorizontalRuleWithText";
import LoginGoogle from "../components/LoginGoogle";
import ChooseCard from "../components/ChooseCard";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../context/AuthContext";
import useLogin from "../hooks/useLogin";
import RegError from "../components/RegError";
import { useTranslation } from "react-i18next";

const PageLogin = () => {
  const [loading, setLoading] = useState(false);
  const [cognitoError, setCognitoError] = useState(null);

  const { t } = useTranslation("reg");

  const { onSetTypedEmail, onSetPage } = useContext(AuthContext);

  const { login } = useLogin();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const watchFields = useWatch({ control, name: ["password", "email"] });

  useEffect(() => {
    setCognitoError(null);
  }, [watchFields]);

  const onSubmit = async formData => {
    setLoading(true);
    onSetTypedEmail(formData.email);

    Auth.signIn(formData.email, formData.password)
      .then(user => {
        return login(user, formData.email);
      })
      .catch(err => {
        if (err?.code === "UserNotConfirmedException") {
          onSetPage("verifyCode");
        } else {
          setCognitoError(err.message);
          console.log(err);
          setLoading(false);
        }
      });
  };

  const handleSignUp = () => {
    onSetPage("signup");
  };

  const handleForgotPassword = () => {
    onSetPage("forgotPassword");
  };

  return (
    <LayoutContent>
      <WosqHeading>{t("choose_method")}</WosqHeading>
      <LayoutForm>
        <FormHeaderWrap>
          <FormHeaderText>
            {t("login_as")} {t("merchant")}
          </FormHeaderText>
        </FormHeaderWrap>
        <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
          <InputLabel disabled={loading} error={errors.email}>
            {t("email")}
          </InputLabel>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormInput
                placeholder={t("write_your_email")}
                value={field.value}
                onChange={field.onChange}
                error={errors.email}
                disabled={loading}
              />
            )}
          />

          <InputLabel disabled={loading} error={errors.password}>
            {t("password")}
          </InputLabel>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormInput
                placeholder={t("write_password")}
                value={field.value}
                onChange={field.onChange}
                error={errors.password}
                disabled={loading}
                type="password"
              />
            )}
          />

          {cognitoError ? <RegError text={cognitoError} /> : null}

          <DialogButtons marginTop="0" justifyButtons="space-between">
            <LinkButton onClick={handleForgotPassword} disabled={loading}>
              {t("forgot_password")}
            </LinkButton>
            <CustomButton width="170px" type="submit" disabled={loading}>
              {t("login")}
            </CustomButton>
          </DialogButtons>

          <HorizontalRuleWithText innerText={t("or")} />

          <LoginGoogle text={t("google_signin")} loading={loading} />
        </Form>
      </LayoutForm>
      <EntranceVariantsContainer>
        <ChooseCard
          text={t("signup")}
          imgUrl="/auth/sign-up.png"
          handleClick={handleSignUp}
          disabled={loading}
        />
      </EntranceVariantsContainer>
    </LayoutContent>
  );
};

export default PageLogin;
