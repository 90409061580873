import React from 'react';
import styled from 'styled-components';

const StyledHR = styled.hr`
  border: 1px solid #E9E9E9;
  margin-bottom: 24px;
`

const CustomHR = (props) => <StyledHR {...props}/>

export default CustomHR;