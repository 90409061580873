import React from "react";
import { OBJECT_MAP } from "../../configurator/objectMap";
import ToggleButton from "../../configurator/components/StyledToggleButton";
import Icon from "../../../UI/Icon/Icon";
import { ToggleButtonGroup } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  toggleGroup: {
    flexWrap: "wrap",
    gap: "16px",
  },
  svgIcon: {
    overflow: "visible",
  },
}));

const AccessoryGroup = ({currentValues, handleChange, possibleKey}) => {
  const classes = useStyles();

  const onChange = (_, value) => {
    handleChange(value);
  }

  return (
    <ToggleButtonGroup
      aria-label="selected accessory"
      value={currentValues}
      className={classes.toggleGroup}
      onChange={onChange}
    >
      {OBJECT_MAP[possibleKey].map(fileName => (
        <ToggleButton value={fileName.id} key={fileName.id}>
          <Icon
            className={classes.svgIcon}
          >
            <img src={fileName.icon} alt="" />
          </Icon>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default AccessoryGroup;
