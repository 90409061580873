import { IconProductTypes } from "../../icons";
import CubeIcon from "mdi-material-ui/Cube";
import { constants } from "../../constants.js";
import ProductTypesPage from "./pages/ProductTypesPage";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 50,
    path: "/product-types",
    MainComponent: ProductTypesPage,
    IconComponent: IconProductTypes,
    navigationItemLabel: 'Product Types',
    showShopSelector: false,
    godmodeOnly: true,
    collapse: {
      title: 'menu.products',
      IconComponent: CubeIcon, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });
}
