import React, { useCallback, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import {
  mutationAddAttributeToType,
  mutationCreateProductType,
  mutationRemoveAttributeFromType,
  mutationUpdateProductType,
} from "../graphql/mutations";
import { Controller, useFormContext } from "react-hook-form";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import { Form } from "../../../UI/Form/MyForm";
import StyledButton from "../../../UI/Button/Button";
import { useTranslation } from "react-i18next";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import FormNote from "../../../UI/Form/FormNote";
import { useSnackbar } from "notistack";
import SelectAllAutocomplete from "../../../package/src/SelectAllAutocomplete";
import { intersection } from "lodash";

const EditProductType = ({ productType, attributes, loading, setLoading, onClose, onCreateAttribute }) => {
  const [list, setList] = useState([{
    label: '',
    value: '',
  }]);

  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormContext();

  useEffect(() => {
    if (attributes) {
      setList(attributes.map((k) => ({
        label: k.name,
        value: k.id,
      })));
    }
  }, [attributes]);

  const createProductType = async (input) => {
    return apolloClient.mutate({
      mutation: mutationCreateProductType,
      variables: {
        input,
      },
    });
  };

  const updateProductType = async (input, skip) => {
    if (skip) {
      return Promise.resolve('success');
    }
    return apolloClient.mutate({
      mutation: mutationUpdateProductType,
      variables: {
        input,
      },
    });
  };

  const addAttributeToType = async (input) => {
    return apolloClient.mutate({
      mutation: mutationAddAttributeToType,
      variables: {
        input,
      },
    });
  };

  const removeAttributeFromType = async (input) => {
    return apolloClient.mutate({
      mutation: mutationRemoveAttributeFromType,
      variables: {
        input,
      },
    });
  };

  const addRemoveAttributes = async (ids) => {
    const original = productType.variantAttributes?.map((k) => k.attribute.id) || [];
    const doNothing = intersection(original, ids);
    const toRemoveIds = original.filter((k) => !doNothing.includes(k));
    const toAddIds = ids.filter((k) => !doNothing.includes(k));
    const successes = [];
    if (!toRemoveIds.length || !toAddIds.length) {
      successes.push('1');
    }

    try {
      for (const id of toRemoveIds) {
        const { data } = await removeAttributeFromType({
          productTypeId: productType.id,
          attributeId: id,
        });
        if (data) successes.push(data);
      }
      for (const id of toAddIds) {
        const { data } = await addAttributeToType({
          productTypeId: productType.id,
          attribute: {
            id,
            isRequired: false,
          }
        });
        if (data) successes.push(data);
      }
    } catch(e) {
      return Promise.reject();
    }

    if (successes.length) {
      return Promise.resolve('success');
    }
  };

  const onSubmit = useCallback((formData) => {
    setLoading(true);

    if (productType) {
      Promise.allSettled([
        updateProductType({
          id: productType.id,
          name: formData.name,
        }, productType.name === formData.name),
        addRemoveAttributes(formData.attributeIds),
      ])
        .then((res) => {
          if (res[0].status === 'rejected') {
            enqueueSnackbar('Error while updating product type name', {variant: 'error'});
            console.log(res[0].reason);
          }
          if (res[1].status === 'rejected') {
            enqueueSnackbar('Error while updating product type attributes list', {variant: 'error'});
            console.log(res[1].reason);
          }
          if (res[0].value) {
            enqueueSnackbar('Successfully updated product type name', {variant: 'success'});
          }
          if (res[1].value) {
            enqueueSnackbar('Successfully updated product type attributes list', {variant: 'success'});
          }
          if (res[0].value || res[1].value) {
            onClose();
          }
        })
        .catch((e) => {
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createProductType({
        name: formData.name,
        attributes: formData.attributeIds.map((id) => ({
          id,
          isRequired: false,
        })),
      })
        .then(() => {
          enqueueSnackbar('Successfully created product type', {variant: 'success'});
          onClose();
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [productType]);

  const handleAutocompleteChange = (e, func) => {
    const result = e.map(item => item.value);
    func(result);
  }

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => checkKeyDown(e)}
    >
      <FormHeader>{productType ? 'Edit Product Type' : 'Create Product Type'}</FormHeader>
      <InputLabel error={errors.name} disabled={loading}>Name</InputLabel>
      <Controller
        name="name"
        control={control}
        render={({ field }) => <FormInput
          placeholder='Create Product Type, for Example, T-Shirt or vase'
          value={field.value}
          onChange={field.onChange}
          error={errors.name}
          disabled={loading}
        />}
      />

      <InputLabel error={errors.attributeIds} disabled={loading}>Select Attributes for the Product Type</InputLabel>
      <Controller
        name="attributeIds"
        control={control}
        render={({ field }) => <SelectAllAutocomplete
          placeholder='Select attributes'
          selectAllLabel="All attributes"
          onChange={(e) => handleAutocompleteChange(e, field.onChange)}
          items={list.map(item => ({
            ...item,
            selected: field.value.includes(item.value),
          }))}
          isMultiSelect
          disabled={loading}
          error={errors.attributeIds}
        />}
      />

      <InputLabel>Or Create New Attribute</InputLabel>
      <FormNote style={{ maxWidth: '465px' }}>The New Attribute will appear in the list of all attributes and will be linked to this type of product.</FormNote>

      <StyledButton
        width='183px'
        disabled={loading}
        onClick={onCreateAttribute}
        mytype='secondary'
      >
        Create Attribute
      </StyledButton>

      <DialogButtons>
        <StyledButton
          width={productType ? '165px' : '102px'}
          type="submit"
          disabled={!isDirty || loading}
        >
          {productType ? t('ui.save_changes') : t('ui.done')}
        </StyledButton>
      </DialogButtons>
    </Form>
  );
};

export default EditProductType;
