import React from "react";
import Toolbar from "../../../../package/src/Toolbar";
import styled from "styled-components";
import { Container, Grid } from "@material-ui/core";
import ProfileChangePassword from "./ProfileChangePassword";
import ProfileGeneral from "./ProfileGeneral";
import { useTranslation } from "react-i18next";

const StyledContainer = styled(Container)`
  max-width: 1000px;
`

const Profile = ({ viewer }) => {
  const { t } = useTranslation();

  return (
    <>
      <Toolbar title={t('settings.edit_profile')}/>
      <StyledContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7}>
            <ProfileGeneral
              viewer={viewer}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <ProfileChangePassword />
          </Grid>
        </Grid>

      </StyledContainer>
    </>
  );
}

export default Profile;
