import React, { useCallback, useState } from "react";
import Dropzone from "../../../UI/Dropzone/Dropzone";
import styled from 'styled-components';
import IconButtons from "../../../UI/IconButtons";
import { FileRecord } from "@reactioncommerce/file-collections";
import { setTagHeroMediaMutation } from "../lib/mutations";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import DropContainer from "../../../UI/Dropzone/DropContainer";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTranslation } from "react-i18next";

const StyledFilledContainer = styled.div`
  width: 100%;
  height: 150px;
  background: url(${props => props.bgUrl}) no-repeat center;
  background-size: contain;
  position: relative;
`

const StyledDropzone = styled.div`
  margin-bottom: 24px;
`

const TagDropzone = ({mediaURL, setMediaURL, viewerId, shopId, tagId}) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [isUploading, setIsUploading] = useState(false);

  const handleDeleteImage = useCallback(() => {
    apolloClient.mutate({
      mutation: setTagHeroMediaMutation,
      variables: {
        input: {
          id: tagId,
          shopId,
          fileRecord: null,
        },
      },
    })
      .then(() => {
        setMediaURL('');
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), { variant: 'error' });
        console.log(e.message);
      })
  }, [tagId, shopId, t])

  const handleDrop = useCallback((files) => {
    if (files.length === 0) return;

    if (!tagId) {
      alert(t('tags.save_tag_before_upload'));
      return;
    }

    setIsUploading(true);
    const reader = new FileReader();
    const file = files[0];
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // Paranthesis cause issues
      const newFileName = file.name.replace(")", "").replace("(", "");
      const myNewFile = new File([file], newFileName, { type: file.type });

      // Convert it to a FileRecord
      const fileRecord = FileRecord.fromFile(myNewFile);

      // Set metadata
      fileRecord.metadata = {
        createdBy: viewerId,
        shopId,
        type: "tag-hero-image",
      };

      // Do the upload. chunkSize is optional and defaults to 5MB
      fileRecord
        .upload()
        // We insert only AFTER the server has confirmed that all chunks were uploaded
        .then(() => {
          return apolloClient.mutate({
            mutation: setTagHeroMediaMutation,
            variables: {
              input: {
                id: tagId,
                shopId,
                fileRecord: fileRecord.document,
              },
            },
          })
        })
        .then(res => {
          let url = res.data.setTagHeroMedia.tag.heroMediaUrl;
          setMediaURL(url);
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), { variant: 'error' });
          console.log(e.message);
        })
        .finally(() => setIsUploading(false))
    }
  }, [viewerId, shopId, tagId, t])

  return (
    mediaURL ?
      <StyledFilledContainer bgUrl={mediaURL}>
        <IconButtons
          type='deleteImage'
          handleClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDeleteImage();
          }}
          style={{
            position: 'absolute',
            right: '11px',
            bottom: '11px',
          }}
        />
      </StyledFilledContainer> :
      <StyledDropzone>
        {
          isUploading ?
            <LinearProgress /> :
            <Dropzone
              accept="image/jpg, image/png, image/jpeg"
              onDrop={handleDrop}
            >
              <DropContainer/>
            </Dropzone>
        }
      </StyledDropzone>
  );
}

export default TagDropzone;
