import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { InputAdornment } from "@material-ui/core";
import FormInput from '../../../UI/Form/FormInput';
import Button from '../../../UI/Button';
import Popover from "@material-ui/core/Popover";
import moment from "moment";
import { IconDataRange } from "../../../icons";
import {colors} from '../../../constants';
import HorizontalRule from "../../../UI/other/HorizontalRule";
import { useTranslation } from "react-i18next";
import arabicLocale from 'date-fns/locale/ar-SA';
import englishLocale from 'date-fns/locale/en-GB';
import { LanguageContext } from "../context/LanguageContext";

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    background: #FFFFFF;
    box-shadow: 0 4px 20px #D8DADF;
    border-radius: 20px;
    padding: 6px;
    margin-top: 4px;
  }
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 0 0 8px 8px;
`;

const StyledDateRange = styled(DateRange)`
  margin-right: 5px;

  ${props => props.isArabic && css`
    .rdrNextPrevButton {
      transform: rotate(180deg) !important;
    }
    
    .rdrNextButton {
      > i {
        transform: translate(-3px) !important;
        margin: unset;
      }
    }
    
    .rdrDayStartOfMonth {
      transform: rotate(180deg) !important;
      .rdrDayNumber > span {
        transform: rotate(180deg) !important;
      }
    }
    
    .rdrDayStartOfWeek {
      transform: rotate(180deg) !important;
      .rdrDayNumber > span {
        transform: rotate(180deg) !important;
      }
    }

    .rdrDayEndOfWeek {
      transform: rotate(180deg) !important;
      .rdrDayNumber > span {
        transform: rotate(180deg) !important;
      }
    }

    .rdrDayEndOfMonth {
      transform: rotate(180deg) !important;
      .rdrDayNumber > span {
        transform: rotate(180deg) !important;
      }
    }
  `};
  
  .rdrDateDisplayWrapper {
    background: #FFFFFF;
    
    span {
      border-radius: 8px;
      
      input {
        font-family: 'Mulish', sans-serif;
        font-weight: 500;
        font-size: 15px;
        color: ${colors.black};
      }
    }
    
    ${props => props.onlyFromToday && css`
      .rdrDateInput:nth-of-type(1) {
        display: none;
      }
    `}
  }
`

/**
 * @name DateRangeSelector
 * @param {dateRange.startDate} Date (NOT STRING)
 * @param {dateRange.endDate} Date (NOT STRING)
 */
const DateRangeSelector = ({
  dateRange,
   /*
     IMPORTANT! Format of 'startDate' and 'endDate' must be Date() obj
     startDate: moment(ISOString).toDate(),
     endDate: moment(ISOString).toDate(),
   */
  onChange,
  placeholder,
  error,
  disabled,
  onlyFromToday,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRangeState, setDateRangeState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const myPlaceholder = placeholder || t('common.date_range');

  useEffect(() => {
    setDateRangeState([
      {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        key: "selection",
      },
    ]);
  }, [dateRange]);

  const id = isOpen ? "simple-popover" : undefined;
  const startDateReadable = dateRangeState[0]?.startDate
    ? moment(dateRangeState[0].startDate).format("DD.MM.YYYY")
    : t('common.start_of_time');
  const endDateReadable = dateRangeState[0]?.endDate
    ? moment(dateRangeState[0].endDate).format("DD.MM.YYYY")
    : t('common.end_of_time');

  const getDataError = (err) => {
    if (err?.startDate || err?.endDate) {
      return { message: t('errors.field_required') };
    }
  }

  const handleInputClick = (e) => {
    if (disabled) {
      return;
    }
    setIsOpen(true);
    setAnchorEl(e.currentTarget);
  }

  const handleChangeRange = (item) => {
    // For the time-being, we are assuming that the endDate ends at the
    // end of the selected day

    item.selection.endDate = moment(item.selection.endDate)
      .add(23, "h")
      .add(59, "m")
      .add(59, "s")
      .toDate();

    if (onlyFromToday) {
      item.selection.startDate = new Date();
    }

    setDateRangeState([item.selection]);
  }

  return (
    <>
      <FormInput
        onClick={handleInputClick}
        placeholder={myPlaceholder}
        value={`${startDateReadable} - ${endDateReadable}`}
        error={getDataError(error)}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconDataRange/>
            </InputAdornment>
          )
        }}
        disabled={disabled}
      />
      <StyledPopover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setIsOpen(false);
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <StyledDateRange
          isArabic={language === 'ar'}
          editableDateInputs
          onChange={handleChangeRange}
          moveRangeOnFirstSelection={false}
          ranges={dateRangeState}
          minDate={onlyFromToday && moment(new Date()).add(24, 'h').toDate()}
          onlyFromToday={onlyFromToday}
          locale={language === 'ar' ? arabicLocale : englishLocale}
          startDatePlaceholder={t('ui.early')}
          endDatePlaceholder={t('ui.continuous')}
        />

        <HorizontalRule />

        <ButtonsWrap>
          <Button
            onClick={() => {
              setIsOpen(false);
              onChange({
                startDate: dateRangeState[0].startDate,
                endDate: dateRangeState[0].endDate,
              });
            }}
            width='50%'
          >
            {t('ui.accept')}
          </Button>
          <Button
            onClick={() => {
              onChange({
                startDate: null,
                endDate: null,
              });
            }}
            mytype='secondary'
            width='50%'
          >
            {t('ui.clear')}
          </Button>
        </ButtonsWrap>
      </StyledPopover>
    </>
  );
};

DateRangeSelector.propTypes = {
  dateRange: PropTypes.shape({
    startDate: PropTypes.object,
    endDate: PropTypes.object,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DateRangeSelector;
