import React, { useState } from "react";
import StyledIconButton from "./StyledIconButton";
import dotsSvg from "../../../../../icons/dotsSmall.svg";
import MenuItem from "../../../../../UI/MenuItem";
import StyledMenu from "./StyledMenu";

const ProjectCellMenu = ({ onRename, onDelete }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(menuAnchorEl);

  const handleRename = () => {
    setMenuAnchorEl(null);
    onRename();
  };

  const handleDelete = () => {
    setMenuAnchorEl(null);
    onDelete();
  };

  return (
    <>
      <StyledIconButton
        onClick={event => {
          event.stopPropagation();
          setMenuAnchorEl(event.currentTarget);
        }}
        active={String(open)}
      >
        <img src={dotsSvg} alt='edit'/>
      </StyledIconButton>

      <StyledMenu
        anchorEl={menuAnchorEl}
        open={open}
        onClose={() => setMenuAnchorEl(null)}
      >
        <MenuItem
          onClick={handleRename}
        >
          Rename
        </MenuItem>
        <MenuItem
          onClick={handleDelete}
        >
          Delete
        </MenuItem>
      </StyledMenu>
    </>
  );
}

export default ProjectCellMenu;

