import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import Status from "../../../UI/Status";
import { cleanStatus } from "../helpers/modifiers";
import getStatus from "../../../package/src/utils/getStatus";
import { constants } from "../../../constants";

export const renderStatus = (statusObj, isExport) => {
  if (!statusObj.value) {
    return '';
  }
  if (isExport) {
    return statusObj.label;
  }
  return <Status
    type={statusObj.value}
    content={statusObj.label}
  />;
}

const useModifiers = () => {
  const { t } = useTranslation();

  const modifyData = useCallback((data, method, options) => {
    if (!data) {
      return [];
    }
    return data.map(item => {
      const statusText = cleanStatus(item.status);
      const fulfillmentText = cleanStatus(item.fulfillmentGroups[0].status);
      const allStatuses = t('statuses', { returnObjects: true });
      const statusObj = getStatus(allStatuses, statusText);
      const paymentObj = getStatus(allStatuses, item.payments[0].status);
      const fulfillmentObj = getStatus(allStatuses, fulfillmentText);
      const isExport = method === 'export';
      const result = {
        id: item.referenceId,
        shopName: item.shop.name,
        date: item.createdAt,
        status: renderStatus(statusObj, isExport),
        payment: renderStatus(paymentObj, isExport),
        fulfilment: renderStatus(fulfillmentObj, isExport),
        customer: '', // todo
        total: item.summary.total.displayAmount,
      }

      // if table
      if (!isExport && options) {
        result.options = options;
        const valueToDisable = statusText === 'canceled' ? constants.OPTIONS.cancelOrder : '';
        result.modifyOptions = (list) => {
          return list.map(option => {
            if (option.value === valueToDisable) {
              return {
                ...option,
                disabled: true,
              }
            }
            return option;
          });
        }
        return result;
      }

      // if 'export' button
      result.__typename = item.__typename;
      return result;
    });
  }, [t]);

  return {
    modifyData,
  }
};

export default useModifiers;
