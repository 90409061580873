import { constants } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

const useModifiers = () => {
  const { t } = useTranslation();

  const modifyData = useCallback((data, method, options) => {
    return data.map(item => {
      const result = {
        id: item._id,
        name: item.name,
        email: item.emailRecords[0].address,
        phoneNumber: item.phoneNumber,
        status: item.status,
        createdAt: item.createdAt,
      }

      // if table
      if (method !== 'export' && options) {
        result.options = options;

        result.modifyOptions = (list) => {
          return list.filter(option => {
            return (item.status === 'blocked' && option.value !== constants.OPTIONS.resetPassword && option.value !== constants.OPTIONS.blockShopper) ||
              (!(item.status === 'blocked') && option.value !== constants.OPTIONS.unblockShopper);
          })
        }

        return result;
      }

      // if 'export' button
      result.__typename = item.__typename;
      result.status = t(`shoppers.${item.status}`);
      return result;
    });
  }, [t]);

  return { modifyData };
}

export default useModifiers;
