import { OBJECT_MAP } from "../objectMap";
import { convertAccessoryPermits } from "./convertKeys";

export default function getAccessories(accessory, permittedModules) {
  const possibleKey = convertAccessoryPermits(accessory);
  const permitArray = permittedModules[possibleKey]?.map(item => item.id);
  const accessoriesMap = OBJECT_MAP[accessory].map(item => ({
    ...item,
    isPermit: permitArray?.includes(item.id),
  }));
  if (accessory === 'umbrella') {
    accessoriesMap.push({
     id: 'none_umbrella',
      model: null,
      icon: "/icons/crossed_umbrella.svg",
      isPermit: true,
    })
  }
  return accessoriesMap;
}
