import RestoreIcon from "@material-ui/icons/Restore";
import CashIcon from "mdi-material-ui/CashMultiple";
import FinancialHistoryRecordsPage from "./pages/FinancialHistoryRecordsPage.js";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock, registerSetting }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 80,
    path: "/financial-history-records",
    href: "/financial-history-records",
    MainComponent: FinancialHistoryRecordsPage,
    IconComponent: RestoreIcon,
    navigationItemLabel: 'menu.financial_history_records',
    godmodeOnly: true,
    showShopSelector: false,
    collapse: {
      title: 'menu.financials',
      IconComponent: CashIcon, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });
}
