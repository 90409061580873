import React, { useContext, useEffect } from "react";
import FormHeader from "../../../../UI/Form/FormHeader";
import styled from "styled-components";
import svgShop from "../../../../icons/quiz/Shop.svg";
import svgChevron from "../../../../icons/quiz/chevron-right-grey.svg";
import Button from "../../../../UI/Button";
import { ShopEditContext } from "../../../../package/src/context/ShopEditContext";
import BigBlueButton from "./BigBlueButton";
import { IconFacade, IconFacade3d } from "../../../../icons";
import { useApolloClient } from "@apollo/react-hooks";
import { shopQueryECommerce } from "../../graphql/queries";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { QuizColumn, QuizQuestionText, QuizShopCircle, QuizStyledPaper } from "./styles";

const Wrapper = styled('div')`
  margin-bottom: 40px;
`;

const Quiz = ({ shopId }) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    quiz: { step, experience, eCommerce },
    onQuizChange,
  } = useContext(ShopEditContext);

  const getShopData = async () => {
    return apolloClient.query({
      query: shopQueryECommerce,
      variables: {
        id: shopId,
      },
      fetchPolicy: "network-only",
    })
  }

  useEffect(() => {
    if (shopId) {
      onQuizChange('step', 2);
      getShopData()
        .then((res) => {
          const eCommerceEnabled = res?.data.shop.eCommerceEnabled;
          onQuizChange('eCommerce', Boolean(eCommerceEnabled));
          if (experience === '2d' && !eCommerceEnabled) {
            onQuizChange('experience', '3d');
          }
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
    }
  }, [shopId]);

  const handleFirstAnswer = (val) => {
    onQuizChange('eCommerce', val);
    onQuizChange('step', 2);
  };

  const handleSecondAnswer = (val) => {
    onQuizChange('experience', val);
  };

  const handleChangeStep = (val) => {
    onQuizChange('step', val);
  };

  return (
    <Wrapper>
      <FormHeader
        noHorizontalRule
        margin='0 0 24px 0 !important'
      >
        Let us help you choose package that suites your needs
      </FormHeader>
      <QuizStyledPaper>

        <QuizColumn>
          <QuizShopCircle>
            <img
              src={svgShop}
              alt=''
            />
          </QuizShopCircle>
          <QuizQuestionText
            selected={step === 1}
            disabled={Boolean(shopId)}
            onClick={() => !Boolean(shopId) && handleChangeStep(1)}
            style={{
              minWidth: '128px',
            }}
          >
            Do you have<br />E-Commerce?
          </QuizQuestionText>
          {
            step === 2
            && <>
              <img
                src={svgChevron}
                alt=''
              />
              <QuizQuestionText
                selected={step === 2}
                onClick={() => handleChangeStep(2)}
                style={{
                  minWidth: '240px',
                }}
              >
                Which shopping experience<br />do you prefer?
              </QuizQuestionText>
            </>
          }
        </QuizColumn>

        <QuizColumn>
          {
            step === 1
            ? <>
                <Button
                  width='100%'
                  mytype='miniBlue'
                  onClick={() => handleFirstAnswer(false)}
                >
                  No
                </Button>
                <Button
                  width='100%'
                  mytype='miniBlue'
                  onClick={() => handleFirstAnswer(true)}
                >
                  Yes
                </Button>
              </>
              : <>
                <BigBlueButton
                  text='Facade + 3D Interior'
                  selected={experience === '3d'}
                  IconSvg={IconFacade3d}
                  onClick={() => handleSecondAnswer('3d')}
                />
                <BigBlueButton
                  text='Facade'
                  selected={experience === '2d'}
                  IconSvg={IconFacade}
                  onClick={() => handleSecondAnswer('2d')}
                  disabledText='To choose this category you need to have e-commerce'
                  disabled={eCommerce === false}
                />
              </>
          }
        </QuizColumn>

      </QuizStyledPaper>
    </Wrapper>
  );
};

export default Quiz;
