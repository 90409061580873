import * as Yup from 'yup';

export const InviteSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('errors.field_required'),
  lastName: Yup.string()
    .required('errors.field_required'),
  email: Yup.string()
    .email('errors.invalid_email')
    .required('errors.field_required'),
  password: Yup.string()
    .required('errors.field_required')
    .min(8, 'errors.min_char8')
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\^$*.\[\]{}()?\-"!@#%&\/\\,><':;|_~`+=]).{8,}$/, 'errors.password_requirements'),
  groupSlugs: Yup.array(),
  merchantGroups: Yup.array(),
  shopIds: Yup.array()
    .min(1, 'errors.field_required'),
})

export const ProfileSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .trim()
    .required('errors.field_required'),
  newPassword: Yup.string()
    .trim()
    .min(8, 'errors.min_char8')
    .required('errors.field_required'),
  confirm: Yup.string()
    .trim()
    .oneOf([Yup.ref('newPassword'), null], "errors.password_match"),
})

export const SettingsGeneralSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('errors.field_required'),
  lastName: Yup.string()
    .required('errors.field_required'),
  email: Yup.string()
    .email('errors.invalid_email')
    .required("errors.field_required"),
  phoneNumber: Yup.string()
    .required('errors.field_required'),
})
