import React, { useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import chevron from '../../icons/chevron-down-black.svg';
import InputErrorMessage from "./InputErrorMessage";
import { colors } from '../../constants';
import { LanguageContext } from "../../package/src/context/LanguageContext";
import { useTranslation } from "react-i18next";

const WrappedPhoneInput = styled(PhoneInput)`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  margin-bottom: 24px;
  
  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    width: 50px;

    .PhoneInputCountrySelect {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0;
      opacity: 0;
      cursor: pointer;
    }

    .PhoneInputCountryIcon {
      width: 30px;
      
      &--border {
        border: 1px solid grey;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  
  .PhoneInputInput {
    width: 100%;
    height: 42px;
    border-radius: 50px;
    background-color: ${props => props.error === 'true' ? colors.redLight10 : '#F3F3F3'};
    border: 1px solid ${props => props.error === 'true' ? colors.redLight : '#F3F3F3'};
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #222222;
    padding-left: 22px;
    
    &:focus {
      border: ${props => props.error === 'true' ? `1px solid ${colors.redLight}` : 'none' };
      outline: none;
    }
  }
  
  ${props => props.isrtl === 'true' && css`
    .PhoneInputInput {
      padding-left: 0;
      padding-right: 22px;
    }
  `}
`;

const StyledImage = styled('div')`
  position: absolute;
  top: 0;
  left: 35px;
  height: 46px;
  display: flex;
  align-items: center;
  
  ${props => props.isrtl === 'true' && css`
    left: unset;
    right: 35px;
  `}
`;

const Wrapper = styled('div')`
  position: relative;
`;

const FormInputPhoneNumber = ({ value, onChange, placeholder, error, disabled, setPhoneError, phoneError }) => {
  const { isRtl } = useContext(LanguageContext);
  const { t } = useTranslation();

  useEffect(() => {
    const showInvalidError = value && { message: t('errors.invalid_phone_number') };
    const isPhoneError = !isValidPhoneNumber(value) && showInvalidError;

    if (isPhoneError) {
      setPhoneError(isPhoneError);
    } else if (error) {
      setPhoneError(error);
    } else {
      setPhoneError(null);
    }
  }, [value, error]);

  const handleChange = (val) => {
    setPhoneError(null);

    if (!val) {
      onChange('');
    } else {
      onChange(val);
    }
  }

  return (
    <Wrapper>
      <WrappedPhoneInput
        value={value}
        onChange={handleChange}
        international
        defaultCountry='US'
        countryCallingCodeEditable={false}
        placeholder={placeholder}
        disabled={Boolean(disabled)}
        error={String(Boolean(phoneError))}
        isrtl={String(isRtl)}
      />
      <StyledImage
        isrtl={String(isRtl)}
      >
        <img
          src={chevron}
          alt=''
          width='10px'
        />
      </StyledImage>
      {
        phoneError
          ? <InputErrorMessage>{t(phoneError.message)}</InputErrorMessage>
          : null
      }
    </Wrapper>

  );
};

export default FormInputPhoneNumber;
