import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useActivityHistoryColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'shopName',
      label: t('history.shop_name'),
      sortable: 'shopId',
    },
    {
      name: 'merchant',
      label: t('history.merchant'),
      sortable: 'companyId',
    },
    {
      name: 'email',
      label: t('history.email'),
      sortable: 'accountId',
    },
    {
      name: 'activity',
      label: t('history.activity'),
      sortable: 'operationName',
    },
    {
      name: 'variables',
      label: t('history.request_variables'),
      sortable: false,
    },
    {
      name: 'query',
      label: t('history.request_query'),
      sortable: false,
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    {
      name: 'options',
    }
  ], [t]);
};
