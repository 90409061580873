import { isArray } from "lodash";

const getStatus = (allStatuses, value) => {
  if (!value) {
    return {
      value: '',
      label: '',
    };
  }

  if (!isArray(allStatuses) || !allStatuses?.length) {
    return {
      value,
      label: value,
    };
  }

  return allStatuses.find((item) => item.value === value) || {
    value,
    label: value,
  };
}

export default getStatus;
