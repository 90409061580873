import gql from "graphql-tag";

export const financialHistoryRecordsGQL = gql`
  query financialHistoryRecords($companyIds: [ID], $genericFilters: GenericFilterInput) {
    financialHistoryRecords(companyIds: $companyIds, genericFilters: $genericFilters) {
      nodes {
        _id
        companyId
        company {
          _id
          name
        }
        createdAt
        numberOfReminderEmailsSent
        numberOfDaysInTheNegative
        balances {
          orders
          transactions
          subscriptions
          total
        }
      }
      totalCount
    }
  }
`;
