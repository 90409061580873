import React from 'react';
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  width: 100%;
  height: 42px;
  border: 1px solid #FFFFFF;
  border-radius: 24px;
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 42px;
  text-align: center;
  color: #FFFFFF;
`

const ActivePlan = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {t('subscription.active_plan')}
    </Wrapper>
  );
}

export default ActivePlan;
