import React, { useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import {TextField, InputAdornment} from '@material-ui/core';
import styled, { css } from "styled-components";
import {IconSearch} from '../../icons';
import {debounce} from 'lodash';
import { LanguageContext } from "../../package/src/context/LanguageContext";

const StyledTextField = styled(TextField)`
  background: #FFFFFF;
  box-shadow: 0px 7px 20px rgba(183, 195, 211, 0.25);
  border-radius: 47px;
  height: 42px;
  width: 100%;
  max-width: ${props => props.maxwidth};
  min-width: ${props => props.minwidth};

  & .MuiInputBase-root {
    height: 42px;
    padding-right: 22px;

    input {
      padding: 0 10px 0 22px;
    }

    & .MuiInputAdornment-root {
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  fieldset {
    border: none;
  }
  
  ${props => props.isrtl === 'true' && css`
    & .MuiInputBase-root {
      padding-right: 0;
      padding-left: 22px;

      input {
        padding: 0 22px 0 10px;
      }
    }
  `}
`

const CustomSearch = ({
  handleChange = () => {},
  useDebounce,
  onSearchClick,
  placeholder,
  maxWidth = 'auto',
  minWidth = 'auto',
  ...other
}) => {
  const { isRtl } = useContext(LanguageContext);
  const [innerValue, setInnerValue] = useState('');

  const innerChange = (e) => {
    const value = e.target.value;
    setInnerValue(value);
    if (useDebounce) {
      startDebounce(value);
    } else {
      handleChange(value);
    }
  }

  const startDebounce = useCallback(debounce(handleChange, 500), []);

  return (
    <StyledTextField
      variant="outlined"
      placeholder={placeholder}
      onChange={innerChange}
      value={innerValue}
      isrtl={String(isRtl)}
      InputProps={{
        endAdornment: (
          <InputAdornment
            onClick={onSearchClick}
          >
              <IconSearch/>
          </InputAdornment>
        )
      }}
      maxwidth={maxWidth}
      minwidth={minWidth}
      {...other}
    />
  );
}

CustomSearch.propTypes = {
  handleChange: PropTypes.func,
  useDebounce: PropTypes.bool,
  onSearchClick: PropTypes.func,
  placeholder: PropTypes.string,
}

export default CustomSearch;
