import React, { useContext, useState } from "react";
import { WosqDescriptionText, WosqHeading } from "../styles";
import LayoutContent from "../layouts/LayoutContent";
import ResendCode from "../components/ResendCode";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../context/AuthContext";
import EnterCodeForm from "../components/EnterCodeForm";
import { useTranslation } from "react-i18next";

const PageVerifyCode = () => {
  const [loading, setLoading] = useState(false);
  const [cognitoError, setCognitoError] = useState(null);
  const { t } = useTranslation("reg");

  const { typedEmail, onSetPage } = useContext(AuthContext);

  const handleSubmit = async code => {
    if (!typedEmail || !code) {
      return;
    }

    setLoading(true);

    Auth.confirmSignUp(typedEmail, code)
      .then(res => {
        if (res === "SUCCESS") {
          onSetPage("verified");
        }
      })
      .catch(e => {
        console.log(e);
        setCognitoError(e.message);
        setLoading(false);
      });
  };

  return (
    <LayoutContent>
      <WosqHeading>{t("auth_code")}</WosqHeading>
      <WosqDescriptionText>
        {t("welcome")}
        <br /> {t("to_complete_reg")}
      </WosqDescriptionText>
      <EnterCodeForm
        onSubmit={handleSubmit}
        loading={loading}
        cognitoError={cognitoError}
        headingText={t("auth_your_account")}
      />
      <ResendCode />
    </LayoutContent>
  );
};

export default PageVerifyCode;
