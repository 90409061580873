import React, { useContext } from "react";
import styled from "styled-components";
import FormHeader from "../../../UI/Form/FormHeader";
import { useTranslation } from "react-i18next";
import Select from "../../../UI/Select";
import { ShopEditContext } from "../context/ShopEditContext";
import {
  StreetMapBlackText,
  StreetMapBlueText,
  StreetMapGreyText,
  StreetMapHeading, StreetMapHorizontalRule,
  StreetMapRowBox, StreetMapScrollArea,
  StreetMapZoneBox,
} from "./styles";
import StreetMapTableRow from "./StreetMapTableRow";

const Wrapper = styled('div')`
  width: 100%;
  min-width: 350px;
  max-width: 600px;
  height: 70vh;
  background: #FFFFFF;
  box-shadow: 0 7px 20px rgba(183, 195, 211, 0.25);
  border-radius: 40px;
`;

const StreetMapSelector = ({
  isDetailed,
  streetsList,
  selectedStreet,
  setSelectedStreet,
  handleSelectAllHouses,
  currentHolderPlacement,
  placesOnStreets,
  subscriptionAvailableStreets,
  refetchStreets,
}) => {
  const { setZoneId, zoneOptions, zoneId } = useContext(ShopEditContext);

  const handleChangeZone = (e) => {
    setZoneId(e.target.value);
    if (refetchStreets) {
      refetchStreets({
        filter: {
          zoneId: e.target.value,
        }
      });
    }
  };

  const { t } = useTranslation();

  return (
    <Wrapper>
      <StreetMapHeading>
        <FormHeader margin='0 0 14px 0'>{t('street.choose_the_street')}</FormHeader>
        <StreetMapZoneBox>
          <StreetMapBlackText>Zone:</StreetMapBlackText>
          <Select
            placeholder='Zone'
            value={zoneId}
            onChange={handleChangeZone}
            options={zoneOptions}
            margin='0'
          />
        </StreetMapZoneBox>
        {
          isDetailed &&
          <>
            <StreetMapRowBox>
              <StreetMapGreyText>{t('street.chosen_street')}</StreetMapGreyText>
              <StreetMapGreyText>{t('street.house_number')}</StreetMapGreyText>
            </StreetMapRowBox>
            <StreetMapRowBox>
              <StreetMapBlueText>{(currentHolderPlacement!==null) ?
                streetsList.find(street => street.id === currentHolderPlacement.streetId)?.name || '' : ''}</StreetMapBlueText>
              <StreetMapBlueText>{(currentHolderPlacement!==null) ? currentHolderPlacement.placeId : ''}</StreetMapBlueText>
            </StreetMapRowBox>
            <StreetMapHorizontalRule />
          </>
        }
      </StreetMapHeading>

      <StreetMapScrollArea>
        {
          streetsList.filter(street => subscriptionAvailableStreets.find((s=>s === street.id))).map(street => {
            const houseQuantity = street.places.length;
            const availableHouseCount = street.places.reduce((acc, cur) => {
              return cur.status === 'none' ? acc + 1 : acc;
            }, 0);
            const foundStreet = placesOnStreets.find(item => item.street.id === street.id);
            const places = foundStreet?.places.map(place => place.id) || [];

            return <StreetMapTableRow
              key={street.id}
              isDetailed={isDetailed}
              isSelected={street.id === selectedStreet}
              name={street.name}
              houseQuantity={availableHouseCount}
              handleClick={() => setSelectedStreet(street.id)}
              handleSelectAllHouses={(isSelectAll) => handleSelectAllHouses({
                streetId: street.id,
                placesIds: street.places.map(place => place.id),
                selected: isSelectAll,
              })}
              isAllHousesSelected={houseQuantity === places.length}
            />;
          })
        }
      </StreetMapScrollArea>
    </Wrapper>
  );
}

export default StreetMapSelector;
