import React, { useContext, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { SettingsInfoSchema } from "../schemas";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import FormCard from "../../../UI/Form/FormCard";
import { Form } from '../../../UI/Form/MyForm';
import HorizontalRule from "../../../UI/other/HorizontalRule";
import Button from "../../../UI/Button/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import Select from "../../../UI/Select";
import { Grid } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../package/src/context/AuthContext";

const FormSettingsMerchantInfo = ({ loading, setLoading, attributes, countriesList, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);
  
  const { control, handleSubmit, formState: { errors, isDirty }, reset } = useForm({
    resolver: yupResolver(SettingsInfoSchema),
    defaultValues: {
      companyName: '',
      country: '',
      city: '',
      postcode: '',
      address: '',
      address2: '',
    },
  });

  useEffect(() => {
    if (!attributes) {
      return;
    }

    const result = {};

    result.companyName = attributes.profile || '';

    const countryValue = countriesList?.find(item => item.name === attributes.locale)?.value;
    if (countryValue) {
      result.country = countryValue;
    }

    result.city = attributes['custom:city'] || '';
    result.postcode = attributes['custom:postcode'] || '';
    result.address = attributes.address || '';
    result.address2 = attributes['custom:address_2'] || '';

    reset(result);
  }, [attributes, countriesList])

  const onSubmit = async (formData) => {
    if (!user) {
      return;
    }

    const {companyName, country, city, postcode, address, address2} = formData;
    setLoading(true);

    try {
      const res2 = await Auth.updateUserAttributes(user, {
        profile: companyName,
        locale: countriesList.find(item => item.value === country).name,
        'custom:city': city,
        'custom:postcode': postcode,
        address,
        ...(address2 ? {
          'custom:address_2': address2,
        } : {}),
      });

      if (res2) {
        enqueueSnackbar(t('snackbar.update_success'), { variant: "success" });
        refetch();
      } else {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        setLoading(false);
      }
    } catch (e) {
      enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
      console.log(e.message);
      setLoading(false);
    }
  }

  const handleDecline = () => {
    reset();
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      width='100%'
    >
      <FormCard>
        <FormHeader>{t('settings.company_info')}</FormHeader>
        <InputLabel disabled={loading} error={errors.companyName}>{t('settings.company_name')}</InputLabel>
        <Controller
          name="companyName"
          control={control}
          render={({ field }) => <FormInput
            placeholder={t('settings.write_your_company_name')}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            error={errors.companyName}
          />}
        />
        <InputLabel disabled={loading}>{t('settings.country')}</InputLabel>
        <Controller
          name="country"
          control={control}
          render={({ field }) => <Select
            placeholder={t('settings.country')}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            options={countriesList}
          />}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <InputLabel disabled={loading}>{t('settings.city')}</InputLabel>
            <Controller
              name="city"
              control={control}
              render={({ field }) => <FormInput
                placeholder={t('settings.city')}
                value={field.value}
                onChange={field.onChange}
                disabled={loading}
              />}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel disabled={loading}>{t('settings.postcode')}</InputLabel>
            <Controller
              name="postcode"
              control={control}
              render={({ field }) => <FormInput
                placeholder={t('settings.postcode')}
                value={field.value}
                onChange={field.onChange}
                disabled={loading}
              />}
            />
          </Grid>
        </Grid>

        <InputLabel disabled={loading}>{t('settings.address')} 1</InputLabel>
        <Controller
          name="address"
          control={control}
          render={({ field }) => <FormInput
            placeholder={t('settings.write_your_address')}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
          />}
        />
        <InputLabel disabled={loading} optionalText={t('ui.optional')}>{t('settings.address')} 2</InputLabel>
        <Controller
          name="address2"
          control={control}
          render={({ field }) => <FormInput
            placeholder={t('settings.write_your_address')}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
          />}
        />

        <HorizontalRule/>

        <SaveButtons>
          <Button
            width='50%'
            type='submit'
            disabled={!isDirty || loading}
          >
            {t('ui.save')}
          </Button>
          <Button
            width='50%'
            type='button'
            mytype='third'
            disabled={!isDirty || loading}
            handleClick={handleDecline}
          >
            {t('ui.cancel')}
          </Button>
        </SaveButtons>
      </FormCard>
    </Form>
  );
};

export default FormSettingsMerchantInfo;
