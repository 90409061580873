import React from 'react';
import styled from "styled-components";
import { colors } from "../../constants";
import { useTranslation } from "react-i18next";

const StyledSave = styled.div`
  button {
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: ${colors.blue};
    margin: 12px 0;
  }
  
  .cancel {
    color: ${colors.red};
    margin-left: 16px;
  }
`

const SaveButtonsSimple = ({onSaveClick, onCancelClick, disabled, ...other}) => {
  const { t } = useTranslation();

  return (
    <StyledSave {...other}>
      <button className='cancel' onClick={onCancelClick}>
        {t('ui.cancel')}
      </button>
      <button onClick={onSaveClick} disabled={disabled}>
        {t('ui.save')}
      </button>
    </StyledSave>
  );
}

export default SaveButtonsSimple;
