import React, { useCallback, useContext, useMemo, useState } from "react";
import Toolbar from "../../../../package/src/Toolbar";
import { Grid, Hidden, makeStyles } from "@material-ui/core";
import Dropdown from "../../../../UI/Dropdown";
import Search from "../../../../UI/Search";
import StyledButton from "../../../../UI/Button";
import DataTable from "../../../../UI/DataTable/DataTable";
import { colors, constants, standardPageStyles } from "../../../../constants";
import Dialog from "../../../../UI/Dialog/Dialog";
import { useSnackbar } from "notistack";
import { useApolloClient } from "@apollo/react-hooks";
import svgApprove from '../../../../icons/table-approve.svg';
import svgClose from '../../../../icons/table-close.svg';
import svgReset from '../../../../icons/reset.svg';
import accountsWithFilters from "../graphql/queries/accounts";
import getGroups from '../graphql/queries/groups';
import DialogInvite from './InviteShopMemberDialog';
import enableAccountMutation from "../graphql/mutations/enableAccountMutation";
import suspendAccountMutation from "../graphql/mutations/suspendAccountMutation";
import resetStaffPassword from "../graphql/mutations/resetStaffPassword";
import DialogMessages from "../../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import { LanguageContext } from "../../../../package/src/context/LanguageContext";
import { useTranslation } from "react-i18next";
import { useAccountsColumns } from "../hooks/useAccountsColumns";
import useModifiers from "../hooks/useModifiers";

const useStyles = makeStyles(theme => ({
  ...standardPageStyles,
  redText: {
    color: colors.red,
    fontWeight: 700,
  },
  secondColumn: {
    paddingLeft: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
  },
  secondColumnRtl: {
    paddingRight: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
  },
}));

const Accounts = ({ selectedShopIds: shopIds, viewer }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const godmode = viewer?.type === "godmode";
  const { isRtl } = useContext(LanguageContext);
  const rtl = isRtl ? 'Rtl' : '';
  const { t } = useTranslation();
  const { columns } = useAccountsColumns();
  const { modifyData } = useModifiers();

  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowId, setRowId] = useState('');
  const [tableData, setTableData] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const canInviteStaffMembers = !!viewer?.groups?.nodes.find(
    group => group.slug === "godmode-admin" || group.slug === "merchant-admin"
  );

  const fetchGroups = () => {
    return apolloClient.query({
      query: getGroups,
      variables: {
        shopId: shopIds[0],
      },
      fetchPolicy: "network-only",
    })
  }

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder }) => {
    if (shopIds.length !== 1) {
      return;
    }

    setIsLoading(true);
    fetchGroups()
      .then((res) => {
        const groups = res.data?.groups?.nodes || [];
        const groupIds = godmode
          ? groups.map(group => group._id) // if godmode return all groups
          : groups.filter(group => !group.slug.includes("godmode")).map(group => group._id); // if not, return non godmode groups

        return apolloClient.query({
          query: accountsWithFilters,
          variables: {
            groupIds,
            //filter: globalFilter,
            // todo
            first: pageSize,
            limit: (pageIndex + 1) * pageSize,
            offset: pageIndex * pageSize,
            sortOrder,
            sortBy: 'createdAt',
          },
          fetchPolicy: "network-only",
        })
          .then(res => {
            const result = modifyData({
              output: res.data.accountsWithFilters.nodes,
              groups,
            });

            const tableData = result.map(data => {
              return {
                ...data,
                options: [
                  {
                    value: constants.OPTIONS.enable,
                    label: t('account.enable'),
                    icon: svgApprove,
                    handleOptionClick: (rowId) => {
                      const accountId = result.find(item => item.id === rowId)?.accountId || '';
                      setRowId(accountId);
                      setDialogName('enableSingle');
                      setDialogOpen(true);
                    },
                  },
                  {
                    value: constants.OPTIONS.suspend,
                    label: t('account.suspend'),
                    icon: svgClose,
                    handleOptionClick: (rowId) => {
                      console.log(result);
                      const accountId = result.find(item => item.id === rowId)?.accountId || '';
                      setRowId(accountId);
                      setDialogName('suspendSingle');
                      setDialogOpen(true);
                    },
                  },
                  {
                    value: constants.OPTIONS.resetPassword,
                    label: t('account.reset_password'),
                    icon: svgReset,
                    handleOptionClick: (rowId) => {
                      const accountId = result.find(item => item.id === rowId)?.accountId || '';
                      setRowId(accountId);
                      setDialogName('resetSingle');
                      setDialogOpen(true);
                    },
                  },
                ],
              }
            })

            // Update the state with the fetched data as an array of objects and the calculated page count
            const total = res.data.accountsWithFilters.totalCount;
            setTableData(tableData);
            setPageCount(Math.ceil(total / pageSize));
            setTotalCount(total);
          })
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [godmode, t, modifyData])

  const mutateEnableSuspend = (action, accountId) => {
    return apolloClient.mutate({
      mutation: action === 'enable' ? enableAccountMutation : suspendAccountMutation,
      variables: {
        id: accountId,
        state: action === 'enable' ? 'declined' : null,
      },
    });
  }

  const mutateResetPassword = (accountId) => {
    return apolloClient.mutate({
      mutation: resetStaffPassword,
      variables: {
          id: accountId,
      },
    });
  }

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const itemsListActions = useMemo(() => [
    {
      content: t('account.enable'),
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('enable');
        setDialogOpen(true);
      },
    },
    {
      content: t('account.suspend'),
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('suspend');
        setDialogOpen(true);
      },
    },
    {
      content: t('account.reset_password'),
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('reset');
        setDialogOpen(true);
      },
    },
  ], [selectedRows, t])

  const renderNoButton = useMemo(() =>
      <StyledButton
        width='180px'
        mytype='secondary'
        handleClick={() => setDialogOpen(false)}
      >
        {t('ui.no')}
      </StyledButton>
    , [t])

  const handleInviteStaffMember = () => {
    setDialogName('invite');
    setDialogOpen(true);
  }

  const renderInviteButton = (myClass) => {
    if (canInviteStaffMembers) {
      return <Grid item xs={12} lg={5} className={classes[myClass]}>
        <StyledButton
          minwidth='160px'
          width='220px'
          handleClick={handleInviteStaffMember}
        >
          {t('account.invite_staff_member')}
        </StyledButton>
      </Grid>
    }
    return null;
  }

  const DialogEnableSuspendSingle = useMemo(() => {
    const action = dialogName?.replace('Single', '');
    const message = dialogName === 'enableSingle' ? t('account.enable_q') : t('account.suspend_q');
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={message}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              setDialogOpen(false);
              mutateEnableSuspend(action, rowId)
                .then(res => {
                  if (res) {
                    refetch();
                    enqueueSnackbar(t('snackbar.action_rows_success'), {
                      variant: 'success',
                    });
                  }
                })
                .catch((e) => {
                  enqueueSnackbar(e.message.replace('GraphQL error: ', ''), {variant: 'error'});
                  console.log(e.message);
                })
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [dialogName, rowId, t])

  const DialogEnableSuspend = useMemo(() => {
    const message = dialogName === 'enable' ? t('account.enable_q_many') : t('account.suspend_q_many');
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={message}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={async() => {
              setDialogOpen(false);
              const successes = [];

              try {
                for (const rowId of selectedRows) {
                  const accountId = tableData.find(item => item.id === rowId)?.accountId || '';
                  const {data} = await mutateEnableSuspend(dialogName, accountId);
                  if (data) successes.push(data);
                }
              } catch(e) {
                enqueueSnackbar(e.message.replace('GraphQL error: ', ''), {variant: 'error'});
                console.log(e.message);
              }

              if (successes.length) {
                refetch();
                enqueueSnackbar(t('snackbar.action_rows_success'), {
                  variant: 'success',
                });
              }
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [dialogName, selectedRows, tableData, t])

  const DialogResetSingle = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={t('account.reset_password_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              setDialogOpen(false);
              mutateResetPassword(rowId)
                .then(res => {
                  if (res) {
                    enqueueSnackbar(t('account.reset_password_success'), {
                      variant: 'success',
                    });
                  }
                })
                .catch((e) => {
                  enqueueSnackbar(e.message.replace('GraphQL error: ', ''), {variant: 'error'});
                  console.log(e.message);
                })
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [rowId, t])

  const DialogReset = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={t('account.reset_password_many_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={async() => {
              setDialogOpen(false);
              const successes = [];

              try {
                for (const rowId of selectedRows) {
                  const accountId = tableData.find(item => item.id === rowId)?.accountId || '';
                  const {data} = await mutateResetPassword(accountId);
                  if (data) successes.push(data);
                }
              } catch(e) {
                enqueueSnackbar(e.message.replace('GraphQL error: ', ''), {variant: 'error'});
                console.log(e.message);
              }

              if (successes.length) {
                enqueueSnackbar(t('account.reset_password_many_success'), {
                  variant: 'success',
                });
              }
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [selectedRows, tableData])

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'invite': return <DialogInvite
          declineAction={() => setDialogOpen(false)}
          apolloClient={apolloClient}
          enqueueSnackbar={enqueueSnackbar}
          allShops={viewer.adminUIShops}
          godmode={godmode}
        />;
      case 'enableSingle':
      case 'suspendSingle': return DialogEnableSuspendSingle;
      case 'enable':
      case 'suspend': return DialogEnableSuspend;
      case 'resetSingle': return DialogResetSingle;
      case 'reset': return DialogReset;
      default: return null;
    }
  }

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Toolbar title={t('account.staff_members')} />

        {
          shopIds.length === 1 && <>
            <Hidden mdDown>
              <Grid container className={classes.grid}>
                <Grid item lg={7} className={classes.gridEmpty}/>
                {renderInviteButton(`secondColumn${rtl}`)}
              </Grid>
            </Hidden>

            <Hidden lgUp>
              {renderInviteButton('secondColumnXS')}
            </Hidden>

            <div className={classes.secondRow}>
              <Dropdown
                title={t('ui.actions')}
                itemsList={itemsListActions}
              />
              <Search
                handleChange={handleSearch}
                onSearchClick={refetch}
                placeholder={t('ui.search')}
                useDebounce
              />
            </div>
          </>
        }

        <Grid item sm={12}>
          {shopIds.length !== 1 ? (
              <span className={classes.selectSomeShops}>{t('account.select_one_shop')}</span>
            ) :
            <DataTable
              columns={columns}
              isLoading={isLoading}
              data={tableData}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              handleFetchData={onFetchData}
              pageCount={pageCount}
              totalCount={totalCount}
              searchValue={searchValue}
              reloadTable={reloadTable}
              defaultSortOrder='desc'

              // styles for 2 rows upper panel + checkboxes
              maxHeight='calc(100vh - 390px)'
              maxHeightLaptop='calc(100vh - 460px)'
              maxHeightMobile='calc(100vh - 440px)'
            />
          }
        </Grid>

      </Grid>

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>

  );
}

export default Accounts;
