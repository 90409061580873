import { SvgIcon } from "@material-ui/core";
import React from "react";

function CircleIcon(props) {
  return (
    <div style={{
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    }}>
      <SvgIcon {...props}>
        <circle r="48%" cx="50%" cy="50%" />
      </SvgIcon>
    </div>
  );
}

export default CircleIcon;