import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useDiscounts = () => {
  const { t } = useTranslation();

  const typeOptions = useMemo(() => [
    {
      value: 'percentage',
      name: t('discount.percentage'),
    },
    {
      value: 'flat',
      name: t('discount.flat'),
    },
  ], [t]);

  const appliesOptions = useMemo(() => [
    {
      value: 'all',
      name: t('discount.all_products'),
    },
    {
      value: 'specific',
      name: t('discount.specific_products'),
    },
  ], [t]);

  const eligibilityOptions = useMemo(() => [
    {
      value: 'all',
      name: t('discount.everyone'),
    },
    {
      value: 'specific',
      name: t('discount.specific_customers'),
    },
  ], [t]);

  return {
    typeOptions,
    appliesOptions,
    eligibilityOptions,
  }
};

export default useDiscounts;
