import { Menu, styled } from "@material-ui/core";
import React from "react";
import { menuItemActiveCss } from "./NavigationDrawer";

const Wrapper = styled(Menu)(({ theme, isrtl }) => ({
  '& .MuiPaper-root': {
    minWidth: 180,
    background: 'linear-gradient(199.68deg, #415BCD -10.17%, #C17EE9 109.02%)',
    borderRadius: '20px',
    marginLeft: isrtl === 'true' ? '-8px' : '8px',
    '& .MuiListItem-root': {
      color: 'white',
      fontFamily: 'Fredoka',
      fontSize: '16px',
      fontWeight: 500,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active, &:hover': {
        background: menuItemActiveCss,
      },
    },
  },
}));

const ComplexMenu = ({ anchorEl, open, handleClose, isRtl, children, ...props }) => {
  return (
    <Wrapper
      id="demo-customized-menu"
      MenuListProps={{
        'aria-labelledby': 'demo-customized-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      elevation={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: isRtl ? 'left' : 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: isRtl ? 'right' : 'left',
      }}
      isrtl={String(isRtl)}
      {...props}
    >
      { children }
    </Wrapper>
  )
};

export default ComplexMenu;
