import * as Yup from "yup";

export const AttributesSchema = Yup.object().shape({
  name: Yup.string()
    .required('errors.field_required'),
  allowedValues: Yup.array()
    .when('inputType', {
      is: 'list',
      then: Yup.array().min(1, 'errors.field_required'),
    }),
});
