import gql from "graphql-tag";

export const activityHistoryRecordsQuery = gql`
  query activityHistoryRecords(
    $companyIds: [ID]
    $filter: String
    $after: ConnectionCursor
    $before: ConnectionCursor
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $offset: Int
    $sortOrder: SortOrder
    $sortBy: ActivityHistoryRecordSortByField
    $genericFilters: GenericFilterInput
  ) {
    activityHistoryRecords(
      companyIds: $companyIds
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
      offset: $offset
      sortOrder: $sortOrder
      sortBy: $sortBy
      genericFilters: $genericFilters
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        _id
        accountId
        account {
          _id
          name
          primaryEmailAddress
        }
        companyId
        company {
          _id
          name
        }
        shopId
        shop {
          _id
          name
        }
        operationName
        variables
        query
        createdAt
      }
      totalCount
    }
  }
`;
