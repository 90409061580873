import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { ordersQuery, totalShopsBalance } from "../graphql/queries";
import SelectAllAutocomplete from "../../../package/src/SelectAllAutocomplete";
import DataTable from "../../../UI/DataTable/DataTable";
import Search from "../../../UI/Search/Search";
import StyledButton from "../../../UI/Button/Button";
import { makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import { getFilters } from "../utils/getFilters";
import svgEdit from "../../../icons/table-edit.svg";
import svgRefund from "../../../icons/refund.svg";
import svgDelete from "../../../icons/delete.svg";
import Dialog from "../../../UI/Dialog/Dialog";
import DialogCancelOrder from "../../orders/dialogs/DialogCancelOrder";
import DialogRefund from '../../orders/dialogs/OrderRefunds';
import getShopQuery from "../../orders/graphql/queries/getShop";
import orderByReferenceId from "../../orders/graphql/queries/orderByReferenceId";
import useFinancialsColumns from "../hooks/useFinancialsColumns";
import { useTranslation } from "react-i18next";
import { constants } from "../../../constants";
import useFinancialsModifiers from "../hooks/useFinancialsModifiers";

const useStyles = makeStyles(theme => ({
  searchAndExport: {
    width: '100%',
    display: 'flex',
    gap: 20,
    marginTop: '32px',
  },
}));

const Orders = ({ dateRange, shopIds, companyIds, viewer }) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedShopIds, setSelectedShopIds] = useState(shopIds);
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState({});
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  const { orderColumns, statColumns } = useFinancialsColumns();
  const { modifyStatProps, modifyData } = useFinancialsModifiers();

  useEffect(() => {
    setSelectedShopIds(shopIds);
  }, [shopIds]);

  const { data, isLoading = false, refetch } = useQuery(totalShopsBalance, {
    variables: {
      shopIds: selectedShopIds,
      fromDate: dateRange?.startDate?.toISOString(),
      toDate: dateRange?.endDate?.toISOString(),
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch && refetch();
    refetchTable();
  }, [selectedShopIds]);

  const refetchTable = () => {
    setReloadTable(prev => !prev);
  }

  const shouldfetchOrders = Array.isArray(selectedShopIds) && selectedShopIds.length > 0;

  // `viewer.adminUIShops` contains ALL shops. We should filter it to include
  // only the ones that belong to company with IDs in `companyIds` because
  // these are the user's selections
  let adminUIShops = viewer?.adminUIShops || [];
  adminUIShops = adminUIShops.filter(shop => companyIds.includes(shop.company._id));

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
    if (!shouldfetchOrders) return;
    setIsTableLoading(true);
    const filters = getFilters(dateRange);

    apolloClient.query({
      query: ordersQuery,
      variables: {
        shopIds: selectedShopIds || [],
        filters: {
          ...filters,
          searchField: globalFilter,
        },
        first: pageSize,
        //limit: (pageIndex + 1) * pageSize,
        offset: pageIndex * pageSize,
        sortOrder,
        sortBy,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const total = res.data.orders.totalCount;
        setTableData(res.data.orders.nodes);
        setPageCount(Math.ceil(total / pageSize));
        setTotalCount(total);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsTableLoading(false))
  }, [selectedShopIds, shouldfetchOrders, dateRange, t]);

  const getShop = (shopId) => {
    return apolloClient.query({
      query: getShopQuery,
      variables: {
        id: shopId,
      },
      fetchPolicy: "network-only",
    });
  }

  const getOrderByReference = (language, shopId, rowId) => {
    return apolloClient.query({
      query: orderByReferenceId,
      variables: {
        id: rowId,
        language,
        shopId,
        token: null,
      },
      fetchPolicy: "network-only",
    })
  }

  const prepareDialog = (rowId) => {
    const found = tableData.find(item => item.referenceId === rowId);
    getShop(found.shop._id)
      .then(res => {
        return res.data.shop;
      })
      .then(res => {
        return getOrderByReference(res.language, res._id, rowId);
      })
      .then(res => {
        setOrder(res.data.order);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
  }

  const rowOptions = useMemo(() => [
    {
      value: constants.OPTIONS.orderDetails,
      label: t('finance.order_details'),
      icon: svgEdit,
      handleOptionClick: (rowId) => {
        const found = tableData.find(item => item.referenceId === rowId);
        const win = window.open(`/orders/${found.shop._id}/${rowId}`, "_blank");
        win.focus();
      },
    },
    {
      value: constants.OPTIONS.refundDetails,
      label: t('finance.refund_details'),
      icon: svgRefund,
      handleOptionClick: (rowId) => {
        setDialogName('refund');
        setDialogOpen(true);
        prepareDialog(rowId);
      },
    },
    {
      value: constants.OPTIONS.cancelOrder,
      label: t('finance.cancel_order'),
      icon: svgDelete,
      handleOptionClick: (rowId) => {
        setDialogName('cancel');
        setDialogOpen(true);
        prepareDialog(rowId);
      },
    },
  ], [tableData, t]);

  const exportData = useCallback(() => {
    setExporting(true);
    const filters = getFilters(dateRange);
    apolloClient.query({
      query: ordersQuery,
      variables: {
        shopIds: selectedShopIds || [],
        filters: {
          ...filters,
        },
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyData(res.data.orders.nodes, 'export', '', viewer.type);
        const fields = orderColumns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [viewer, dateRange, selectedShopIds, modifyData, orderColumns, t])

  const dataProps = useMemo(() => {
    return modifyData(tableData, '', rowOptions, viewer.type);
  }, [tableData, modifyData])

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'refund': return <DialogRefund
        order={order}
        declineAction={() => setDialogOpen(false)}
        refetch={refetchTable}
        setOrder={setOrder}
      />;
      case 'cancel': return <DialogCancelOrder
        declineAction={() => setDialogOpen(false)}
        order={order}
        refetch={refetchTable}
      />;
      default: return null;
    }
  }

  return (
    <>
      <SelectAllAutocomplete
        items={adminUIShops.map(shop => ({
          label: shop.name,
          value: shop._id,
          selected: selectedShopIds.includes(shop._id),
          groupId: shop.company.name,
        }))}
        limitTags={6}
        label={t('finance.select_shop_s')}
        placeholder={t('finance.search_for_shops')}
        selectAllLabel={t('finance.all_shops')}
        onChange={items => setSelectedShopIds(items.map(item => item.value))}
      />

      <DataTable
        columns={statColumns}
        isLoading={isLoading}
        data={modifyStatProps(data?.totalShopsBalance)}
        showCheckboxes={false}
      />

      <div className={classes.searchAndExport}>
        <Search
          handleChange={handleSearch}
          onSearchClick={refetchTable}
          placeholder={t('ui.search')}
          useDebounce
          minWidth='270px'
        />
        <StyledButton
          width='140px'
          mytype='secondary'
          handleClick={exportData}
          disabled={exporting || !tableData.length}
        >
          {t('ui.export')}
        </StyledButton>
      </div>

      <DataTable
        columns={orderColumns}
        isLoading={isTableLoading}
        data={dataProps}
        handleFetchData={onFetchData}
        pageCount={pageCount}
        totalCount={totalCount}
        searchValue={searchValue}
        reloadTable={reloadTable}
        defaultSortField='createdAt'
        defaultSortOrder='desc'
        showCheckboxes={false}

        // styles for 1 row upper panel
        maxHeight='calc(100vh - 280px)'
        maxHeightLaptop='calc(100vh - 380px)'
        maxHeightMobile='calc(100vh - 360px)'
      />

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>
  );
};

export default Orders;
