import React from 'react';
import styled from 'styled-components';

const StyledSaveButtons = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  margin: ${props => props.margin ? props.margin : '16px 0 0'};
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
`

const SaveButtons = ({children, ...other}) => {
  return (
    <StyledSaveButtons {...other}>
      {children}
    </StyledSaveButtons>
  );
}

export default SaveButtons;