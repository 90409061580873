import gql from "graphql-tag";

export const createMediaRecordAndImageURLsMutation = gql`
  mutation CreateMediaRecordAndImageURLs($input: CreateMediaRecordInput!, $imageSize: ImageSizeInput) {
    createMediaRecordAndImageURLs(input: $input, imageSize: $imageSize) {
      mediaRecord {
        _id
      }
    }
  }
`;

export const deleteMediaRecordMutation = gql`
  mutation deleteMediaRecord($input: DeleteMediaRecordInput!) {
    deleteMediaRecord(input: $input) {
      mediaRecord {
        _id
      }
    }
  }
`;
