import gql from "graphql-tag";

export const totalBalancesQuery = gql`
  query totalBalancesQuery($toDate: DateTime, $fromDate: DateTime, $companyIds: [ID]) {
    totalBalances(toDate: $toDate, fromDate: $fromDate, companyIds: $companyIds) {
      orders
      transactions
      subscriptions
      total
    }
  }
`;

export const createTransactionQuery = gql`
  mutation addTransactionBetweenMerchantAndGodmode(
    $input: AddTransactionBetweenMerchantAndGodmodeInput!
  ) {
    addTransactionBetweenMerchantAndGodmode(input: $input) {
      transactionBetweenMerchantAndGodmode {
        _id
      }
    }
  }
`;

export const updateTransactionMutation = gql`
  mutation updateTransactionBetweenMerchantAndGodmode(
    $id: ID!
    $input: UpdateTransactionBetweenMerchantAndGodmodeInput!
  ) {
    updateTransactionBetweenMerchantAndGodmode(id: $id, input: $input) {
      transactionBetweenMerchantAndGodmode {
        _id
      }
    }
  }
`;

export const transactionQuery = gql`
  query transactionQuery($id: ID) {
    transactionBetweenMerchantAndGodmode(id: $id) {
      _id
      companyId
      type
      subscriptionId
      recipient
      amount
      notes
    }
  }
`;
export const transactionsQuery = gql`
  query transactionsQuery(
    $companyIds: [ID]
    $transactionIds: [ID]
    $toDate: DateTime
    $fromDate: DateTime
    $filter: String
    $after: ConnectionCursor
    $before: ConnectionCursor
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $offset: Int
    $sortOrder: SortOrder
    $sortBy: TransactionBetweenMerchantAndGodmodeSortByField
  ) {
    transactionsBetweenMerchantAndGodmode(
      companyIds: $companyIds
      transactionIds: $transactionIds
      fromDate: $fromDate
      toDate: $toDate
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
      offset: $offset
      sortOrder: $sortOrder
      sortBy: $sortBy
    ) {
      nodes {
        _id
        companyId
        companyName
        amount
        paymentMethod
        type
        subscriptionId
        notes
        recipient
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

export const subscriptionHistoryRecords = gql`
  query subscriptionHistoryRecords(
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $before: ConnectionCursor
    $after: ConnectionCursor
    $offset: Int
    $sortOrder: SortOrder
    $sortBy: SubscriptionHistoryRecordSortByField
    $companyIds: [ID]
    $toDate: DateTime
    $fromDate: DateTime
    $filter: String
  ) {
    subscriptionHistoryRecords(
      first: $first
      last: $last
      before: $before
      after: $after
      offset: $offset
      sortOrder: $sortOrder
      sortBy: $sortBy
      companyIds: $companyIds
      toDate: $toDate
      fromDate: $fromDate
      filter: $filter
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        _id
        company {
          name
        }
        account {
          name
        }
        type
        description
        paymentAmount
        paymentStatus
        date
        changeFrom {
          title
        }
        changeTo {
          title
        }
      }
      totalCount
    }
  }
`;

export const ordersQuery = gql`
  query ordersQuery(
    $shopIds: [ID]
    $filters: OrderFilterInput
    $after: ConnectionCursor
    $before: ConnectionCursor
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $offset: Int
    $sortOrder: SortOrder
    $sortBy: OrdersSortByField
  ) {
    orders(
      shopIds: $shopIds
      filters: $filters
      after: $after
      before: $before
      first: $first
      last: $last
      offset: $offset
      sortOrder: $sortOrder
      sortBy: $sortBy
    ) {
      nodes {
        referenceId
        commissionPercentage
        status
        createdAt
        shopper {
          _id
          name
        }
        shop {
          _id
          name
          company {
            _id
            name
          }
          getShopCategory {
            _id
            name
          }
        }
        summary {
          total {
            amount
          }
          fulfillmentTotal {
            amount
          }
          itemTotal {
            currency {
              code
            }
          }
        }
        fulfillmentGroups {
          data {
            ... on ShippingOrderFulfillmentGroupData {
              shippingAddress {
                fullName
              }
            }
          }
        }
        payments {
          billingAddress {
            fullName
          }
          method {
            name
          }
        }
      }
      totalCount
    }
  }
`;

export const totalShopsBalance = gql`
  query totalShopsBalance($toDate: DateTime, $fromDate: DateTime, $shopIds: [ID]) {
    totalShopsBalance(shopIds: $shopIds, fromDate: $fromDate, toDate: $toDate) {
      merchantCut
      godmodeCut
      pay
      collect
      transaction
      shipping
      total
    }
  }
`;
