import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";

const getCss = (num) => {
  if (num > 0 && num < 5) {
    return css`
      background: rgba(223, 86, 86, 0.2);
      border-color: ${colors.redLight};
      color: ${colors.redLight};
    `;
  }

  if (num >= 5) {
    return css`
      background: rgba(98, 207, 122, 0.2);
      border-color: ${colors.grass};
      color: ${colors.grass};
    `;
  }
  return null;
}

const WrapperHouses = styled('div')`
  width: ${props => props.num > 99 ? '33px' : '26px'};
  height: 26px;
  background: rgba(191, 191, 191, 0.2);
  border: 1px solid ${colors.textGrey};
  border-radius: 4px;
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: ${colors.textGrey};
  user-select: none;
  
  ${props => props.num && getCss(props.num)};
`;

const HousesField = ({ num }) => {
  return (
    <WrapperHouses num={num}>
      {num}
    </WrapperHouses>
  );
};

export default HousesField;
