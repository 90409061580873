import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { ShopEditContext } from "../context/ShopEditContext";
import { getZonesQuery } from "./queries/queries";

const useZones = () => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { setZoneOptions, setZoneId } = useContext(ShopEditContext);

  const getZones = () => {
    return apolloClient.query({
      query: getZonesQuery,
      fetchPolicy: "no-cache",
      onError: (e) => {
        enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
        console.log(e.message);
      },
    });
  }

  useEffect(() => {
    getZones()
      .then((res) => {
        const zones = res.data.zones.data || [];
        setZoneOptions(zones.map((item) => ({
          value: item.id,
          name: item.name,
        })));
        const turkeyId = zones.find((d) => d.name === 'Turkey')?.id;
        if (turkeyId) {
          setZoneId(turkeyId);
        }
      });
  }, []);
};

export default useZones;
