export const SubscriptionPackage = `
_id
title
description
baseRate
isVisibleToMerchants
duration
commission
currency
createdAt
updatedAt
`;

export const SubscriptionPackageFull = `
locationId
categoriesIds
streetsIds
maxRooms

baseOverride {
  enabled
  percent
  period {
    start
    end
  }
  notes
}
`

// const BlockGibModel = `
//   model {
//     url
//     material
//     textures {
//       ao
//       normal
//     }
//   }
// `;

export const ConfiguratorParts = `
  roofs {
    id
    model {
      url
    }
  }
  signAreas {
    id
    model {
      url
    }
  }
  secondFloors {
    id
    model {
      url
    }
  }
  doors {
    id
    model {
      url
    }
  }
  umbrellas {
    id
    model {
      url
    }
  }
  firstFloors {
    id
    model {
      url
    }
  }
`
