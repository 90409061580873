import React from "react";
import Dropzone from "react-dropzone";

const CustomDropzone = ({ children, handleClick, ...dzoneProps }) => {
  return (
    <Dropzone {...dzoneProps}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input
            {...getInputProps()}
            onClick={handleClick}
          />
          {children}
        </div>
      )}
    </Dropzone>
  );
}

export default CustomDropzone;
