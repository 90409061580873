import { styled, Typography, useMediaQuery } from "@mui/material";
import awsmobile from "../../../../aws-exports";

const LoginGoogleWrapper = styled('div')`
  width: 100%;
  
  .clickableArea {
    margin: 0 auto;
    box-shadow: 0 2.42px 2.42px rgba(0, 0, 0, 0.168), 0 0 2.42px rgba(0, 0, 0, 0.084);
    width: 224px;
    height: 53px;
    display: flex;
    gap: 12px;
    cursor: ${(props) => (props.loading === 'true' ? 'default' : 'pointer')};
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
  }

  @media (max-width: 500px) {
    .clickableArea {
      width: 100%;
    }
  }
`;

const AuthText = styled(Typography)`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
	color: rgba(0, 0, 0, 0.54);

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const LoginGoogle = ({ text, loading }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const googlePicSize = isMobile ? '16px' : '20px';

  const handleClick = async () => {
    const domain = process.env.REACT_APP_HTTP_GOOGLE_AUTH;
    const clientId = awsmobile.aws_user_pools_web_client_id;
    const redirect = process.env.REACT_APP_PORTAL;
    window.location.href = `${domain}/oauth2/authorize?identity_provider=Google&response_type=code&client_id=${clientId}&redirect_uri=${redirect}&scope=aws.cognito.signin.user.admin email openid phone profile`;
  };

  return (
    <LoginGoogleWrapper
      loading={String(loading)}
    >
      <div className='clickableArea' onClick={handleClick}>
        <img
          src='/auth/GoogleLogo.svg'
          alt='google'
          style={{
            height: googlePicSize,
            width: googlePicSize,
          }}
        />
        <AuthText>{text}</AuthText>
      </div>
    </LoginGoogleWrapper>
  );
};

export default LoginGoogle;
