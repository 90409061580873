import React, { useContext, useEffect, useState } from "react";
import { FormHeaderText, FormHeaderWrap, WosqDescriptionText, WosqHeading } from "../styles";
import LayoutContent from "../layouts/LayoutContent";
import LayoutForm from "../layouts/LayoutForm";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { StartResetPasswordSchema } from "../schemas";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Form } from "../../../../UI/Form/MyForm";
import InputLabel from "../../../../UI/Form/InputLabel";
import FormInput from "../../../../UI/Form/FormInput";
import FormNote from "../../../../UI/Form/FormNote";
import RegError from "../components/RegError";
import CustomButton from "../../../../UI/Button";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import { AuthContext } from "../../context/AuthContext";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";

const PageForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [cognitoError, setCognitoError] = useState(null);

  const { t } = useTranslation("reg");

  const { onSetPage, onSetTypedEmail } = useContext(AuthContext);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(StartResetPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const watchFields = useWatch({ control, name: ["email"] });

  useEffect(() => {
    setCognitoError(null);
  }, [watchFields]);

  const onSubmit = formData => {
    setLoading(true);

    Auth.forgotPassword(formData.email)
      .then(() => {
        onSetTypedEmail(formData.email);
        onSetPage("resetPassword");
      })
      .catch(err => {
        setCognitoError(err.message);
        console.log(err);
        setLoading(false);
      });
  };

  const handleBack = () => {
    onSetPage("login");
  };

  return (
    <LayoutContent>
      <WosqHeading>{t("reset_password")}</WosqHeading>
      <WosqDescriptionText>
        {t("if_account_exists")} <br /> {t("check_spam")}
      </WosqDescriptionText>
      <LayoutForm>
        <FormHeaderWrap>
          <FormHeaderText>{t("reset_password")}</FormHeaderText>
        </FormHeaderWrap>
        <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
          <InputLabel error={errors.email} disabled={loading}>
            {t("email")}
          </InputLabel>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                placeholder={t("write_your_email")}
                error={errors.email}
                disabled={loading}
              />
            )}
          />
          <FormNote>{t("reset_password_description")}</FormNote>

          {cognitoError ? <RegError text={cognitoError} /> : null}

          <DialogButtons justifyButtons="space-between">
            <CustomButton
              width="170px"
              mytype="secondary"
              disabled={loading}
              handleClick={handleBack}
            >
              {t("ui.back")}
            </CustomButton>
            <CustomButton width="170px" type="submit" disabled={loading || !isValid}>
              {t("send_code")}
            </CustomButton>
          </DialogButtons>
        </Form>
      </LayoutForm>
    </LayoutContent>
  );
};

export default PageForgotPassword;
