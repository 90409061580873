import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ShopEditContext } from "../../../package/src/context/ShopEditContext";

const useSelectPackage = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    setSelectedPackageId,
    openSubscription,
    setPaymentStatus,
    packages,
    setCurrentTab,
  } = useContext(ShopEditContext);

  const hasActive = useMemo(() => packages.some(item => item.isActive), [packages]);

  const onChangeOrSelectPackage = useCallback((id) => {
    setSelectedPackageId(id);
    setCurrentTab('payment');
    if (hasActive) {
      // user want to change plan
      setPaymentStatus(null);
    }
  }, [hasActive])

  const onPackageCardClick = useCallback((id) => {
    if (openSubscription?._id) {
      setDialogOpen(true);
      return;
    }
    onChangeOrSelectPackage(id);
  }, [openSubscription, onChangeOrSelectPackage]);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return {
    onChangeOrSelectPackage,
    onPackageCardClick,
    dialogOpen,
    closeDialog,
  }
};

export default useSelectPackage;
