import React, { useContext } from "react";
import PropTypes from "prop-types";
import {Select, Icon} from '@material-ui/core';
import styled, {css} from 'styled-components';
import MenuItem from '../MenuItem';
import svgDown from "../../icons/chevron-down-black.svg";
import {colors} from '../../constants';
import { makeStyles } from "@material-ui/core/styles";
import InputErrorMessage from "../Form/InputErrorMessage";
import { LanguageContext } from "../../package/src/context/LanguageContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  dropdownStyle: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 20px #D8DADF',
    borderRadius: 20,
    marginTop: 15,
  }
}))

const errorCss = css`
  background-color: ${colors.redLight10};
  border: 1px solid ${colors.redLight};

  & .Mui-focused {
    background-color: transparent;
  }
`

const StyledSelect = styled(Select)`
  background: #FFFFFF;
  box-shadow: 0 7px 20px rgba(183, 195, 211, 0.25);
  height: 42px;
  width: 100%;
  margin-bottom: 24px;
  
  &.MuiOutlinedInput-root {
    border-radius: 47px;
  }
  
  > .MuiSelect-root {
    background: transparent;
    padding: 0 10px 0 22px;
  }

  fieldset {
    border: none;
  }
  
  .popupIcon {
    margin-right: 8px;
  }

  ${props => props.error && errorCss}

  ${props => props.custommargin && css`
    margin: ${props.custommargin};
  `}
  
  ${props => props.isrtl === 'true' && css`
    > .MuiSelect-root {
      padding: 0 22px 0 10px;
    }

    .popupIcon {
      margin-right: 0;
      margin-left: 8px;
    }
  `}
`;

const StyledRequired = styled('span')`
  color: red;
`;

const StyledEmpty = styled.p`
  color: ${colors.darkGrey};
  margin: 16px;
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
`;

const Placeholder = styled.p`
  color: #BFBFBF;
`;

const CustomSelect = ({options, value, placeholder, error, margin, ...other}) => {
  const classes = useStyles();
  const { isRtl } = useContext(LanguageContext);
  const { t } = useTranslation();

  return (
    <>
      <StyledSelect
        variant='outlined'
        IconComponent={() => <Icon className='popupIcon'><img src={svgDown} alt=''/></Icon>}
        renderValue={value === "" ? () => <Placeholder>{placeholder}</Placeholder> : undefined}
        value={value}
        displayEmpty
        error={Boolean(error)}
        MenuProps={{
          PopoverClasses: {paper: classes.dropdownStyle},
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          getContentAnchorEl: null,
        }}
        custommargin={margin}
        isrtl={String(isRtl)}
        {...other}
      >
        {
          options.length === 0 ?
            <StyledEmpty>{t('ui.no_options')}</StyledEmpty> :
            options.map((item, index) => <MenuItem
              key={index}
              value={item.value}
            >
              {item.name}{item.required ? <StyledRequired>&nbsp;*</StyledRequired> : ''}
            </MenuItem>)
        }
      </StyledSelect>
      {
        error ?
          <InputErrorMessage>{t(error.message)}</InputErrorMessage>
          : null
      }
    </>
  );
}

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default CustomSelect;
