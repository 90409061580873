import React from 'react';
import styled, {css} from 'styled-components';
import {colors} from '../../constants';
import IconHelp from "./IconHelp";

const noHRcss = css`
  border-bottom: none;
  margin: 0;
  
  .content {
    height: auto;
    margin: 0;
  }
`

const StyledFormHeader = styled.div`
  border-bottom: 1px solid #E9E9E9;
  width: 100%;
  margin: ${props => props.margin};
  
  .content {
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Fredoka', sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: black;
    margin-bottom: 12px;

    .optional {
      color: ${colors.textGrey};
    }
  }
  
  ${props => props.noHorizontalRule && noHRcss}
`

const FormHeader = ({children, optionalText, helpText, noHorizontalRule = false, margin = '0 0 24px 0'}) => {
  return (
    <StyledFormHeader
      margin={margin}
      noHorizontalRule={noHorizontalRule}
      helpText={Boolean(helpText)}
    >
      <div className='content'>
        {children}
        {
          optionalText ?
            <span className='optional'> ({optionalText})</span> : null
        }
        {
          helpText ?
            <IconHelp helpText={helpText}/> : null
        }
      </div>
    </StyledFormHeader>
  );
}

export default FormHeader;
