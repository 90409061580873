import {styled} from '@mui/material';

const Wrapper = styled('div')`
	width: 100%;
  padding: 43px 0 23px;
  text-align: center;
`;

const ImageBox = styled('img')`
	cursor: pointer;
  width: 260px;

  @media (max-width: 768px) {
    width: 178px;
  }
`;

const WosqHeader = () => {
  const handleClick = () => {
    window.location.href = process.env.REACT_APP_PUBLIC_UI_URL;
  };

  return (
    <Wrapper>
      <ImageBox
        src='/auth/wosq_logo.svg'
        alt='logo'
        height='28px'
        onClick={handleClick}
      />
    </Wrapper>
  );
};

export default WosqHeader;
