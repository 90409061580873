import parse from "csv-parse";

const importFiles = ({
  newFiles,
  setIsImportLoading,
  bulkImport,
  shopId,
  viewer,
  persistantSnackbar,
  refetch,
  t,
}) => {
  newFiles.map(file => {
    const output = [];
    const reader = new FileReader();

    reader.readAsText(file);
    reader.onloadend = () => {
      parse(reader.result, {
        trim: true,
        skip_empty_lines: true,
        delimiter: [","],
        columns: [
          "id",
          "parentId",
          "isVisible",
          "imageIds",
          "productTitle",
          "productSubTitle",
          "productShortDescription",
          "productLongDescription",
          "productPurchaseNotes",
          "productIsPublished",
          "facebookShareMessage",
          "twitterShareMessage",
          "directShareMessage",
          "productTags",
          "productBrands",
          "productCategories",
          "relatedProducts",
          "variantAttributeLabel",
          "variantShortTitleOrHex",
          "variantFullTitle",
          "variantSku",
          "variantOriginCountry",
          "variantWidth",
          "variantLength",
          "variantHeight",
          "variantWeight",
          "variantPrice",
          "variantOriginalPrice",
          "variantIventoryInStock",
          "variantCanBackorder",
        ],
      })
        .on("readable", function() {
          let record;

          while ((record = this.read())) {
            output.push(record);
          }
        })
        .on("error", error => {
          alert(error.message);
        })
        .on("end", async () => {
          let data = [];
          let errors = [];

          const parseToBoolean = value => {
            switch (value.toLowerCase()) {
              case "1":
              case "true":
                return true;

              default:
                return false;
            }
          };

          const splitIntoArray = value => {
            let createdArray = value && value !== "" ? value.split(";") : [];
            return createdArray.map(e => e.trim());
          };

          // Check the first line (table header)
          const titleObject = {
            id: "ID",
            parentId: "Parent ID",
            isVisible: "Is Visible",
            imageIds: "Image IDs (separated by semi-column. Priority left to right)",
            productTitle: "Product Title",
            productSubTitle: "Product Subtitle",
            productShortDescription: "Product Short Description",
            productLongDescription: "Product Long Discription",
            productPurchaseNotes: "Product Purchase Notes",
            productIsPublished: "Product Is Published",
            facebookShareMessage: "Facebook Share Message",
            twitterShareMessage: "Twitter Share Message",
            directShareMessage: "Direct Share Message",
            productTags: "Product Tags (separated by semi-column)",
            productBrands: "Product Brands (separated by semi-column)",
            productCategories: "Product Categories (separated by semi-column)",
            relatedProducts: "Related Product IDs (separated by semi-column)",
            variantAttributeLabel: "Variant Attribute Label",
            variantShortTitleOrHex: "Variant Short Title or Hex",
            variantFullTitle: "Variant Full Title",
            variantSku: "Variant SKU",
            variantOriginCountry: "Variant Origin Country",
            variantWidth: "Variant Width",
            variantLength: "Variant Length",
            variantHeight: "Variant Height",
            variantWeight: "Variant Weight",
            variantPrice: "Variant Price",
            variantOriginalPrice: "Variant Original Price",
            variantIventoryInStock: "Variant Items In Stock",
            variantCanBackorder: "Variant Can Be Backordered",
          };

          for (const property in titleObject) {
            const value = titleObject[property];

            if (output[0][property] !== value) {
              return alert(
                `Wrong column names. Please keep them exactly as they appear in the template. Error at column: ${value}`
              );
            }
          }

          // At first we only want to go through all prodcuts, that way we create them all and there is no way we
          // reach a variant that belongs to a product that hasn't been inserted into the data object yet
          output.forEach((row, rowIndex) => {
            // We skip the first line (table header)
            if (rowIndex === 0) {
              return;
            }

            // We only want rows without a parentId - products
            if (row.parentId) {
              return;
            }

            data.push({
              externalId: row.id.trim(),
              isVisible: parseToBoolean(row.isVisible.trim()),
              title: row.productTitle.trim(),
              subtitle: row.productSubTitle.trim(),
              shortDescription: row.productShortDescription.trim(),
              longDescription: row.productLongDescription.trim(),
              purchaseNotes: row.productPurchaseNotes.trim(),
              isPublished: parseToBoolean(row.productIsPublished.trim()),
              facebookShareMsg: row.facebookShareMessage.trim(),
              twitterShareMsg: row.twitterShareMessage.trim(),
              directShareMsg: row.directShareMessage.trim(),
              tags: splitIntoArray(row.productTags.trim()),
              brands: splitIntoArray(row.productBrands.trim()),
              categories: splitIntoArray(row.productCategories.trim()),
              relatedProducts: splitIntoArray(row.relatedProducts.trim()),
              imageIds: splitIntoArray(row.imageIds.trim()),
              variants: [],
            });
          });

          // Then we go through all the rows again, this time inserting only the variants in the variants array of the
          // product they belong to
          output.forEach((row, rowIndex) => {
            // We skip the first line (table header)
            if (rowIndex === 0) {
              return;
            }

            // We only want rows with a parentId - variants
            if (!row.parentId) {
              return;
            }

            // Let's find the product index that this variant belongs to
            const productIndex = data.findIndex(product => product.externalId === row.parentId);

            if (productIndex === -1) {
              return errors.push(
                `Could not find parentId ${row.parentId} of variant ${row.id}. Aborting import!`
              );
            }

            data[productIndex].variants.push({
              externalId: row.id.trim(),
              isVisible: parseToBoolean(row.isVisible.trim()),
              attributeLabel: row.variantAttributeLabel.trim(),
              attributeShortTitle: row.variantShortTitleOrHex.trim(),
              fullTitle: row.variantFullTitle.trim(),
              sku: row.variantSku.trim(),
              originCountry: row.variantOriginCountry.trim(),
              width: parseFloat(row.variantWidth.trim()),
              length: parseFloat(row.variantLength.trim()),
              height: parseFloat(row.variantHeight.trim()),
              weight: parseFloat(row.variantWeight.trim()),
              price: parseFloat(row.variantPrice.trim()),
              originalPrice: parseFloat(row.variantOriginalPrice.trim()),
              inventoryInStock: parseInt(row.variantIventoryInStock.trim()),
              canBackorder: parseToBoolean(row.variantCanBackorder.trim()),
              imageIds: splitIntoArray(row.imageIds.trim()),
            });
          });

          if (errors.length) {
            alert(errors[0]);

            return console.error(errors);
          }

          setIsImportLoading(true);

          try {
            const result = await bulkImport({
              variables: {
                companyId: viewer.companyId,
                shopId,
                products: data,
              },
            });

            setIsImportLoading(false);

            const { totalInputCount, totalMutatedCount } = result.data.bulkProductImport;

            persistantSnackbar(t('product.products_import_success', {
              count: totalMutatedCount,
              total: totalInputCount,
            }));

            refetch();
          } catch (error) {
            persistantSnackbar(error.toString().replace("GraphQL error:", ""), "error");
            console.error(error);
            setIsImportLoading(false);
          }
        });
    };
  });
};

export default importFiles;
