import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BillingListHeader from "./BillingListHeader";
import BillingCard from "../../../../settings/components/BillingCard";
import Dialog from "../../../../../UI/Dialog/Dialog";
import BillingForm from "../../../../settings/components/BillingForm";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";
import { stripeListPaymentMethodsQuery } from "../../../../settings/graphql/queries";
import useSubscriptionPayment from "../../../hooks/useSubscriptionPayment";
import { difference } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "../../../../../UI/Button/Button";

const PaymentBox = styled.div`
  width: 100%;
  height: 378px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E9E9E9;
    border-radius: 8px;
  }
`;

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

const LoadingBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const BillingList = ({ companyId, shopId, isFreePackage }) => {
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [payments, setPayments] = useState(null);

  const oldData = useRef([]);

  const { data, loading, refetch } = useQuery(stripeListPaymentMethodsQuery, {
    variables: {
      input: {
        companyId,
      },
    },
    fetchPolicy: "network-only",
  });

  const { onProceedPayment } = useSubscriptionPayment(shopId);

  useEffect(() => {
    if (!data) {
      return;
    }

    const result = data?.stripeListPaymentMethods || [];

    // при добавлении новой карты автоматически выделять её в меню
    const ids = result.map((k) => k.id);
    const oldIds = oldData.current.map((k) => k?.id).filter((d) => d);
    const newIds = difference(ids, oldIds);

    if (newIds.length === 1) {
      setSelectedId(newIds[0]);
    } else {
      // в противном случае, выделить default карту, если она есть
      const defaultCardId = result.find((k) => k.isDefault)?.id;
      if (defaultCardId) {
        setSelectedId(defaultCardId);
      }
    }

    setPayments(result);
    oldData.current = result;
  }, [data]);

  const handleAddCard = () => {
    setDialogOpen(true);
  }

  const closeDialog = () => {
    setDialogOpen(false);
  }

  return (
    <>
      {
        payments?.length &&
        <BillingListHeader
          onAddCart={handleAddCard}
        />
      }
      <PaymentBox>
        {
          loading
          ? <LoadingBox>
              <CircularProgress />
            </LoadingBox>
          : (
            payments?.length ? payments.map(card => <BillingCard
              key={card.id}
              brand={card.brand}
              isDefault={card.isDefault}
              cardNumber={card.lastFourDigits}
              handleClick={() => setSelectedId(card.id)}
              isSelected={card.id === selectedId}
            />) : <BillingForm
              cardId={selectedId}
              refetchPayments={refetch}
              closeDialog={closeDialog}
              companyId={companyId}
              style={{
                width: '100%',
              }}
              justifyButtons='flex-start'
            />)
        }
      </PaymentBox>

      {
        payments?.length
        && <ButtonBox>
          <Button
            width='191px'
            handleClick={() => onProceedPayment(selectedId)}
            disabled={!selectedId && !isFreePackage}
          >
            {t('payment.proceed_payment')}
          </Button>
        </ButtonBox>
      }

      <Dialog
        open={dialogOpen}
        handleClose={closeDialog}
      >
        <BillingForm
          cardId={selectedId}
          refetchPayments={refetch}
          closeDialog={closeDialog}
          companyId={companyId}
        />
      </Dialog>
    </>
  );
};

export default BillingList;