import styled from "styled-components";
import { Typography } from "@material-ui/core";

const HeadingText = styled(Typography)`
  color: #222;
  font-family: Fredoka, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`;

export default HeadingText;
