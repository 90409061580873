import { useEffect, useContext, useCallback } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { setAccessToken } from "../lib/graphql/initApollo";
import { viewerQuery } from "../graphql/queries/viewer.js";
import { Auth } from "aws-amplify";
import { SingleShopSelectorContext } from "../context/SingleShopSelectorContext";
import { MultiShopSelectorContext } from "../context/MultiShopSelectorContext";
import { MerchantSelectorContext } from "../context/MerchantSelectorContext";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router";

/**
 * Hook to get user permissions for the App component
 * @return {Object} Permissions
 */

export default function useAuth() {
  const history = useHistory();

  const { localStorageKey: localStorageSingleShopKey } = useContext(SingleShopSelectorContext);
  const { localStorageKey: localStorageMultiShopKey } = useContext(MultiShopSelectorContext);
  const { localStorageKey: localStorageMerchantKey } = useContext(MerchantSelectorContext);
  const { onSetUser, onSetPage, onSetViewer } = useContext(AuthContext);

  const logout = async () => {
    // Clear the selected shops from localStorage. Important, otherwise next
    // account to log in might have saved shops that aren't even his/hers

    const storage = { ...window.localStorage };

    return Auth.signOut()
      .then(() => {
        onSetPage("login");
        onSetUser(null);
        localStorage.removeItem(localStorageSingleShopKey);
        localStorage.removeItem(localStorageMultiShopKey);
        localStorage.removeItem(localStorageMerchantKey);
        onSetViewer(null);
        Object.keys(storage).forEach(key => {
          localStorage.removeItem(key);
        });
        history.replace("/");
      })
      .catch(e => {
        console.log(e);
      });
  };

  const [getViewer, { data: viewerData }] = useLazyQuery(viewerQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      // Can't find any more reliable way to check the status code from within this hook
      if (typeof error.message === "string" && error.message.includes("Received status code 401")) {
        // Token is expired or user was deleted from database
        logout();
      } else {
        console.error(error);
      }
    },
  });

  const getJwtToken = useCallback(async () => {
    const jwtToken = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
    await setAccessToken(jwtToken);
    if (jwtToken) {
      getViewer();
    }
  }, [getViewer]);

  useEffect(() => {
    if (viewerData) {
      onSetViewer(viewerData.viewer);
    }
  }, [viewerData]);

  return {
    logout,
    getJwtToken,
  };
}
