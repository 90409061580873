const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const getValue = (attr) => {
  switch(attr.inputType) {
    case 'datePicker':
    case 'colorPicker': return attr.value.name;
    default: return attr.value;
  }
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => {
    const attributes = {};
    el.attributeValues.forEach((attr) => {
      attributes[attr.attributeId] = getValue(attr);
    });
    const element = {
      ...el,
      ...attributes,
    };
    return [element, index];
  });

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};
