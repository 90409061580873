import React, { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import shoppersQuery from "../graphql/queries/shoppers";
import SelectAllAutocomplete from "../SelectAllAutocomplete";

/**
 * ShopperSelectorWithData
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function ShopperSelectorWithData({ shopIds, shouldShowEmail = false, value, onChange, ...other }) {
  const apolloClient = useApolloClient();
  const [shoppersList, setShoppersList] = useState([]);

  const handleChange = (val) => {
    onChange(val.map(item => item.value));
  }

  useEffect(() => {
    apolloClient.query({
      query: shoppersQuery,
      variables: {
        shopIds,
        filter: '',
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const options = res?.data?.shoppers?.nodes;
        if (options) {
          const result = options
            .map(shopper => {
              const appendEmail = shouldShowEmail ? ` - ${shopper?.primaryEmailAddress}` : "";

              return {
                value: shopper._id,
                label: `${shopper.name}${appendEmail}`,
                selected: value.includes(shopper._id),
              };
            });
          setShoppersList(result);
        }
      })
      .catch((e) => {
        console.log(e.message);
      })
  }, [shopIds])

  return (
    <SelectAllAutocomplete
      items={shoppersList}
      onChange={handleChange}
      {...other}
    />
  );
}

export default ShopperSelectorWithData;
