import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  background: #FFFFFF;
  box-shadow: 0 7px 20px rgba(183, 195, 211, 0.25);
  border-radius: 40px;
  padding: 32px;
  width: ${props => props.width ? props.width : '100%'};
  margin: ${props => props.margin ? props.margin : '0 0 16px 0'};
  
  @media all and (max-width: 768px) {
    width: 100%;
  }
`

const FormCard = ({children, style, width, margin}) => {
  return (
    <StyledDiv
      width={width}
      margin={margin}
      style={style}
    >
      {children}
    </StyledDiv>
  );
}

export default FormCard;
