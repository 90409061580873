/**
 * Determine if a variant with the same set of attributes exists on the server
 * @param oldValues
 * @param newValues
 */

const isSameAttributeValues = (oldValues, newValues) => {
  return oldValues.length === newValues.length &&
    newValues.every((newVal) => oldValues.some((oldVal) => {
      return oldVal.attributeId === newVal.attributeId && oldVal.value === newVal.value;
    }));
};

export default isSameAttributeValues;
