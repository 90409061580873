import gql from "graphql-tag";

export default gql`
  query product($productId: ID!, $shopId: ID!) {
    product(productId: $productId, shopId: $shopId) {
      _id
      media {
        _id
        productId
        variantId
        URLs {
          small
        }
        priority
      }
    }
  }
`;
