import * as JSON_KEYS from "../constants/NameConsts";
import * as THREE from "three";
import { TURNS } from "../constants/constants";
import getConnectionByPointUuid from "./getConnectionByPointUuid";
import getStreetByUuid from "./getStreetByUuid";
import isConnectInStart from "./isConnectInStart";
import generateUUID from "./generateUUID";

/*
 Let's say we have this street's configuration:

 ----------             ----------             ----------
 |STREET 1| [CONNECT A] |STREET 2| [CONNECT B] |STREET 2|
 ----------     -----   ----------             ----------
                | S |
                | T |
                | R |
                | E |
                | E |
                | T |
                |   |
                | 4 |
                -----

 In order to find all street connections (CONNECT A, CONNECT B) we need to
 traverse all street links to other streets and select unique link sets.

  Variable connections is a result of this recursive algorithm.

 */
export default function findAllConnections(connections, resultStreets, streets, id) {
  let street = getStreetByUuid(streets, id);
  let rStreet = getStreetByUuid(resultStreets, id);
  let coords = rStreet[JSON_KEYS.END_POINT][JSON_KEYS.COORDS];
  let startVec = new THREE.Vector3(coords[0], coords[1], coords[2]);
  let zAxis = new THREE.Vector3(0,0,1);
  if (street["startLinks"] !== null) {
    let connection = getConnectionByPointUuid(connections, rStreet[JSON_KEYS.BEGIN_POINT][JSON_KEYS.UUID]);
    for (const key of TURNS) {
      let value = street["startLinks"][key];
      if (value === null) {
        continue;
      }
      let target = getStreetByUuid(streets, value["targetId"]);
      let rTarget = getStreetByUuid(resultStreets, value["targetId"]);
      let isStartConnect = isConnectInStart(target, id);

      if (rTarget[JSON_KEYS.END_POINT] !== undefined) {
        continue;
      }
      let resultVec = new THREE.Vector3(startVec.x, startVec.y, startVec.z);
      switch (key) {
        case "f":
          break;
        case "l":
          resultVec.applyAxisAngle(zAxis, Math.PI/2);
          break;
        case "r":
          resultVec.applyAxisAngle(zAxis, -Math.PI/2);
          break;
        default:
          break;
      }
      resultVec.multiplyScalar((isStartConnect) ? -1 : 1);
      rTarget[JSON_KEYS.END_POINT] = {
        "isBegin": false,
        "coords": [
          resultVec.x,
          resultVec.y,
          resultVec.z,
        ],
        "uuid": generateUUID(),
      };
      connection[JSON_KEYS.STREET_UUIDS].push(rTarget[JSON_KEYS.UUID]);
      connection[JSON_KEYS.POINT_UUIDS].push(rTarget[isStartConnect ? JSON_KEYS.BEGIN_POINT : JSON_KEYS.END_POINT][JSON_KEYS.UUID]);

      findAllConnections(connections, resultStreets, streets, rTarget["uuid"]);
    }
  }
  if (street["endLinks"] !== null) {
    for (const key of TURNS) {
      let connection = getConnectionByPointUuid(connections, rStreet[JSON_KEYS.END_POINT][JSON_KEYS.UUID]);
      let value = street["endLinks"][key];
      if (value === null) {
        continue;
      }
      let target = getStreetByUuid(streets, value["targetId"]);
      let rTarget = getStreetByUuid(resultStreets, value["targetId"]);
      let isStartConnect = isConnectInStart(target, id);
      if (rTarget[JSON_KEYS.END_POINT] !== undefined) {

        continue;
      }
      let resultVec = new THREE.Vector3(startVec.x, startVec.y, startVec.z);
      switch (key) {
        case "f":
          break;
        case "l":
          resultVec.applyAxisAngle(zAxis, Math.PI/2);
          break;
        case "r":
          resultVec.applyAxisAngle(zAxis, -Math.PI/2);
          break;
        default:
          break;
      }
      resultVec.multiplyScalar((isStartConnect) ? 1 : -1);
      rTarget[JSON_KEYS.END_POINT] = {
        "isBegin": false,
        "coords": [
          resultVec.x,
          resultVec.y,
          resultVec.z,
        ],
        "uuid": generateUUID(),
      };
      connection[JSON_KEYS.STREET_UUIDS].push(rTarget[JSON_KEYS.UUID]);
      connection[JSON_KEYS.POINT_UUIDS].push(rTarget[isStartConnect ? JSON_KEYS.BEGIN_POINT : JSON_KEYS.END_POINT][JSON_KEYS.UUID]);
      findAllConnections(connections, resultStreets, streets, rTarget["uuid"]);
    }
  }
}
