import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import svgDate from '../../../icons/date.svg';
import PaperComponent from "./PaperComponent";
import StyledTextField from "./TextField";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { colors } from "../../../constants";

const Wrapper = styled('div')`
  width: 100%;
  margin: ${(props) => props.margin ? props.margin : '0'};
`;

const AdornmentButton = styled(({ ownerState, ...otherProps }) => <IconButton {...otherProps} />)`
  width: 40px;
  height: 40px;
`;

const ErrorMessage = styled('p')`
  color: ${colors.redLight};
  margin: 12px 0 0;
  font-family: 'Mulish', sans-serif;
  font-size: 13px;
`;

const CustomDatePicker = ({ value, onChange, placeholder = '', error, disabled, margin }) => {
  const {t} = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (val) => {
    const formattedDate = val ? dayjs(val).format('YYYY-MM-DD') : null;
    onChange(formattedDate);
  };

  const dateNow = dayjs(value).format('YYYY-MM-DD');

  return (
    <Wrapper margin={margin}>
      <DatePicker
        open={open}
        onClose={handleClose}
        disabled={disabled}
        format="DD/MM/YYYY"
        value={dayjs(dateNow)}
        onChange={handleChange}
        slots={{
          textField: StyledTextField,
          inputAdornment: () => <AdornmentButton onClick={handleOpen}>
            <img src={svgDate} alt='' />
          </AdornmentButton>,
          desktopPaper: PaperComponent,
        }}
        slotProps={{
          textField: {
            placeholder: value ? 'dd.mm.yyyy' : placeholder,
          },
          desktopPaper: {
            onClose: handleClose,
          },
        }}
        reduceAnimations
      />
      {
        error ?
          <ErrorMessage>{t(error.message)}</ErrorMessage>
          : null
      }
    </Wrapper>
  );
};

export default CustomDatePicker;
