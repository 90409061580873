import React from "react";
import styled from "styled-components";
import editSvg from '../../../../../icons/pencilSmall.svg';
import StyledIconButton from "./StyledIconButton";
import ProjectCellMenu from "./ProjectCellMenu";

const Wrapper = styled('div')`
  width: min-content;
  display: flex;
  gap: 8px;
  position: absolute;
  top: 16px;
  right: 16px;
`;

const ProjectCellButtons = ({ onMouseEnter, onMouseLeave, onDelete, onRename, onEdit }) => {
  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit();
  };

  return (
    <Wrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <StyledIconButton
        onClick={handleEdit}
      >
        <img src={editSvg} alt='edit'/>
      </StyledIconButton>
      <ProjectCellMenu
        onRename={onRename}
        onDelete={onDelete}
      />
    </Wrapper>
  );
};

export default ProjectCellButtons;
