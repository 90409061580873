import styled from "styled-components";
import { IconButton } from "@material-ui/core";

export const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    bottom: 16px;
    right: 16px;
    padding: 6px;
  }
`;
