export const constants = {
  conditionMessage:
    "Note that you don't have to set conditions. Any condition that you leave empty will simply be ignored.",
  productSelectionLabel: "Products - Tie this coupon to a specific set of products",
  shopperSelectionLabel: "Select the shoppers that will receive a random Coupon Code by email",
  dateRangeSelectionLabel: "Date Range - Give this coupon a validity period",
  discountAmountLabel: "Discount Amount *",
  discountAmountPlaceholder:
    "e.g. a value of 10 means 10% when method 'percentage' OR deduction of 10 in currency of purchase when method 'flat'",
  checkboxLabel: "Random Generation",
  generateButtonLabel: "Generate",
  createButtonLabel: "Create",
  numberOfCouponsLabel: "Number of Coupons to Generate *",
  numberOfCouponsPlaceholder: "How many coupon codes to generate",
  couponCodeLabel: "Coupon Code *",
  couponCodePageTitle: "Coupons",
  editCouponCodePageTitle: "Edit Coupon",
  editRatePageTitle: "Edit Sale",
  createCouponCodePageTitle: "Create Coupon",
  createRatePageTitle: "Create Sale",
  localGenerationTabLabel: "Local Generation",
  emailCampaignTabLabel: "Email Campaign",
  commenceCampaignBtnLabel: "Send Emails",
  rateLabelLabel: "Label *",
  rateLabelPlaceholder: "e.g. Winter Sale",
  productSelectionLabelForRates: "Products - Limit this sale to a specific set of products",
  successfulDiscountCodeGeneration: "Discount code(s) successfully generated",
  successfulSaleCreation: "Sale successfully created",
  successfulSaleUpdate: "Sale successfully updated",
  calculationMethods: [
    { label: "Flat", value: "credit" },
    { label: "Percentage", value: "discount" },
    // { label: "Sale", value: "sale" },
    // { label: "Shipping", value: "shipping" },
  ],
  discountMethods: {
    code: "code", // coupons
    rate: "rate", // sale
  },
  couponTriggerProductSelectionLabel:
    "Select the products that would trigger the coupon generation when purchased  (or leave empty to apply it to all products)",
  couponTriggerShopperSelectionLabel:
    "Select the shoppers that would trigger the coupon generation upon purchase (or leave empty to apply it to all shoppers)",
  couponTriggerCheckboxLabel: "Has this trigger been consumed?",
  couponTriggerDiscountIdLabel: "The discount ID that was generated when this trigger was consumed",
  couponTriggerDiscountIdPlaceholder: "Discount ID",
  couponTriggerDiscountAmountPlaceholder: "Leave empty to auto generate random code",
  couponTriggerMessage:
    "When certain shoppers purchase certain products, they will automatically receive Coupon Codes by email",
};
