import gql from "graphql-tag";

export const removeProductBrand = gql`
  mutation removeProductBrand($id: ID!) {
    removeProductBrand(id: $id) {
      _id
    }
  }
`;

export const updateProductBrand = gql`
  mutation updateProductBrand($id: ID!, $input: UpdateProductBrandInput!) {
    updateProductBrand(id: $id, input: $input) {
      _id
    }
  }
`;

export const addProductBrand = gql`
  mutation addProductBrand($name: String!) {
    addProductBrand(name: $name) {
      _id
    }
  }
`;
