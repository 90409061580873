import gql from "graphql-tag";
import { Shopper, ShopperInvitation } from "./fragments";

export const removeShopper = gql`
  mutation removeShopper($id: [ID!]!) {
    removeShopper(id: $id) {
      ... on SuccessfulRemoveShopperResult {
         _id
         status
      }
      ...on FailedRemoveShopperResult {
         _id
         status
         message
      }
    }
  }
`;

export const updateShopper = gql`
  mutation updateShopper($id: ID!, $input: UpdateShopperBaseInfoInput!) {
    updateShopper(id: $id, basicInfoInput: $input) {
      ${Shopper}
    }
  }
`;

export const updateBillingAddress = gql`
  mutation updateShopper($id: ID!, $input: AddressInput!) {
    updateShopper(id: $id, billingAddressInput: $input) {
      ${Shopper}
    }
  }
`;

export const updateShippingAddress = gql`
  mutation updateShopper($id: ID!, $input: AddressInput!) {
    updateShopper(id: $id, shippingAddressInput: $input) {
      ${Shopper}
    }
  }
`;

export const addShopper = gql`
  mutation addShopper($input: AddShopperInput!) {
    addShopper(input: $input) {
      ${ShopperInvitation}
    }
  }
`;

export const resetPassword = gql`
  mutation resetShopperPassword($id: ID!) {
    resetShopperPassword(input: {
      id: $id
    })
  }
`;

export const blockShopper = gql`
  mutation blockShopper($input: BlockShopperInput!) {
    blockShopper(input: $input)
  }
`;

export const unblockShopper = gql`
  mutation unblockShopper($input: UnBlockShopperInput!) {
    unblockShopper(input: $input)
  }
`;
