import gql from "graphql-tag";
import { PlacesOnStreet } from "./fragments";

export const availableStreetsQuery = gql`
 query availableStreets($filter: AvailableStreetsFilter) {
  availableStreets(page: 1, limit: 999, filter: $filter) {
    data {
      id
      name
      categories
      type
      startLinks {
        l {
          targetType
          targetId
        }
        r {
          targetType
          targetId
        }
        f {
          targetType
          targetId
        }
      }

      endLinks {
         l {
          targetType
          targetId
        }
        r {
          targetType
          targetId
        }
        f {
          targetType
          targetId
        }
      }

       places {
        id
        status
        stubInfo {
          stub {
            id
            model {
              url
              material
            }
          }
        }
        holder {
          holderId
          holderType
          holderName
          holderConfig {
            ...on ShopConfig {
              id
              version
            }
          }
        }
      }
    }
  }
}
`;

export const getSubscriptionPackageMap = gql`
  query subscriptionPackage($id: ID!) {
    subscriptionPackage(id: $id) {
      _id
      title
      streetsIds
      ${PlacesOnStreet}
    }
  }
`;

export const getSubscriptionPackagesForPlaces = gql`
  query getSubscriptionPackagesForPlaces($streetId: ID!) {
    getSubscriptionPackagesForPlaces(streetId: $streetId) {
      subscriptionPackages {
        _id
        title
      }
      place {
        id
      }
    }
  }
`
