import React from "react";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import Dialog from "../../../../UI/Dialog/Dialog";
import DialogMessages from "../../../../UI/Dialog/DialogMessages";
import StyledButton from "../../../../UI/Button/Button";
import { useTranslation } from "react-i18next";
import SubscriptionList from "./SubscriptionList";
import useSubscriptionPayment from "../../hooks/useSubscriptionPayment";
import useGetPackages from "../../hooks/useGetPackages";
import useSelectPackage from "../../hooks/useSelectPackage";

const SubscriptionTab = ({
  viewer,
  shopId,
}) => {
  const { t } = useTranslation();

  const companyId = viewer.companyId;
  const godmode = viewer.type === 'godmode';

  const { loading } = useGetPackages(companyId, godmode);

  const { onProceedPayment } = useSubscriptionPayment(shopId);

  const {
    onPackageCardClick,
    dialogOpen,
    closeDialog,
  } = useSelectPackage();

  return (
    <>
      <SubscriptionList
        loading={loading}
        shopId={shopId}
        onPackageCardClick={onPackageCardClick}
      />

      <Dialog
        open={dialogOpen}
        handleClose={closeDialog}
      >
        <DialogMessages
          title={t('ui.confirm')}
          message={t('subscription.sub_already_exists_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={async () => {
              closeDialog();
              await onProceedPayment(null);
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          <StyledButton
            width='180px'
            mytype='secondary'
            handleClick={closeDialog}
          >
            {t('ui.no')}
          </StyledButton>
        </DialogButtons>
      </Dialog>
    </>
  );
}

export default SubscriptionTab;
