import React, { useState } from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import UpgradeSubscriptionTooltip from "../../../shops/components/tooltips/UpgradeSubscriptionTooltip";

const useStyles = makeStyles(() => ({
  popper: {
    pointerEvents: 'all',
    '& .MuiTooltip-tooltipPlacementBottom': {
      margin: '8px 0',
      padding: '0',
    },
  },
  tooltip: {
    backgroundColor: 'transparent',
  }
}));

const ConfiguratorTooltip = ({ children, onUpgrade, shopId }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const openTooltipHandle = () => {
    setOpen(true);
  };

  const closeTooltipHandle = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      open={open}
      classes={{
        popper: classes.popper,
        tooltip: classes.tooltip
      }}
      title={<UpgradeSubscriptionTooltip
        shopId={shopId}
        onUpgrade={onUpgrade}
        onClickAway={closeTooltipHandle}
      />}
    >
      <div
        onClick={openTooltipHandle}
      >
        { children }
      </div>
    </Tooltip>
  );
};

export default ConfiguratorTooltip;
