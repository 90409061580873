import createVariantsFromAttributes from "../../../graphql/mutations/createVariantsFromAttributes";
import updateVariants from "../../../graphql/mutations/updateVariants";
import archiveProductVariants from "../../../graphql/mutations/archiveProductVariants";
import { linkMediaRecordsToVariantMutation } from "../../MediaGallerySelector/mutations";
import isSameAttributeValues from "../../../helpers/isSameAttributeValues";
import prepareVariantFields from "../../../helpers/prepareVariantFields";
import stringifyAttributeValue from "../../../helpers/stringifyAttributeValue";
import mapVariants from "../../../helpers/mapVariants";
import { cloneDeep, uniq } from "lodash";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { useContext } from "react";
import { ProductVariantsContext } from "../../../../../package/src/context/ProductVariantsContext";
import archiveMediaRecordMutation from "../../../graphql/mutations/archiveMediaRecordMutation";

const useVariantsUpdate = (productId, shopId, refetchMedia) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    onSetOpenEditor,
    onSetSaving,
    variants,
    variantAttributes,
    originVariants,
    attributeValues,
    onRefetch,
    originVariantParams,
  } = useContext(ProductVariantsContext);

  const handleCreateVariants = async (input) => {
    if (!input.length) {
      return Promise.resolve('success');
    }
    return apolloClient.mutate({
      mutation: createVariantsFromAttributes,
      variables: {
        input: {
          productId,
          shopId,
          variants: input,
        }
      }
    });
  };

  const handleUpdateVariants = async (input) => {
    if (!input.length) {
      return Promise.resolve('success');
    }
    return apolloClient.mutate({
      mutation: updateVariants,
      variables: {
        input: {
          productId,
          variants: input,
        }
      }
    });
  };

  const handleRemoveVariants = async (input) => {
    if (!input.length) {
      return Promise.resolve('success');
    }
    return apolloClient.mutate({
      mutation: archiveProductVariants,
      variables: {
        input: {
          shopId,
          variantIds: input,
        }
      }
    });
  };

  const handleLinkMedia = async (inputs) => {
    const successes = [];

    for (const input of inputs) {
      const { data } = await apolloClient.mutate({
        mutation: linkMediaRecordsToVariantMutation,
        variables: {
          input: {
            shopId,
            productId,
            variantId: input.variantId,
            mediaRecordIds: input.mediaIds,
          },
        },
      });
      if (data) successes.push(data);
    }

    if (successes.length === inputs.length) {
      return Promise.resolve('success');
    }

    return Promise.reject('error');
  };

  const handleRemoveMedia = async (mediaIds) => {
    if (!mediaIds.length) {
      return Promise.resolve();
    }

    const successes = [];

    for (const id of mediaIds) {
      const { data } = await apolloClient.mutate({
        mutation: archiveMediaRecordMutation,
        variables: {
          input: {
            shopId,
            mediaRecordId: id,
          },
        },
      });
      if (data) successes.push(data);
    }

    if (successes.length === mediaIds.length) {
      return Promise.resolve();
    }

    return Promise.reject('Error while archiving media');
  };

  // SAVING VARIANTS

  const onDone = async () => {
    // if the user has not selected the required attributes, then show a warning and stop saving

    const requiredAttributes = variantAttributes.filter((k) => k.isRequired);
    const attributeErrors = [];
    requiredAttributes.forEach((req) => {
      if (!attributeValues.find((k) => k.attributeId === req.attributeId)) {
        attributeErrors.push(req.attribute.name);
      }
    });

    if (attributeErrors.length) {
      attributeErrors.forEach((name) => {
        enqueueSnackbar(`Attribute ${name} is required`, {variant: 'error'});
      });
      return;
    }

    onSetSaving(true);

    const dimensionsUnit = originVariantParams.dimensionsUnit;
    const weightUnit = originVariantParams.weightUnit;

    const updateVariantsList = [];
    const createVariantsList = [];
    const removeVariantsIds = [];
    const removeMediaIds = [];

    // make a list of options for creating and updating
    // make a list of pictures that the user has deleted (replaced)

    variants.forEach((item) => {
      if (!item.isDelete) {
        const sameOrigin = originVariants.find((old) => isSameAttributeValues(old.attributeValues, item.attributeValues));
        if (sameOrigin) {
          // generate update list
          // if the set of attributes is the same, then add it to the update list

          updateVariantsList.push({
            ...item,
            id: sameOrigin.id,
            dimensionsUnit,
            weightUnit,
            media: item.media[0] ? [item.media[0]] : [], // we prevent the possibility of linking an extra image
          });

          // generate remove media list
          // if there were pictures in the old version that are no longer in the new version, then add them to the deletion list

          const oldMediaIds = sameOrigin.media.map((k) => k.id);
          const newMediaIds = item.media.map((k) => k.id);
          oldMediaIds.forEach((id) => {
            if (!newMediaIds.includes(id)) {
              removeMediaIds.push(id);
            }
          });
        } else {
          createVariantsList.push({
            ...item,
            dimensionsUnit,
            weightUnit,
          });
        }
      }
    });

    // make a list of options and pictures that the user deleted using a checkbox

    const updateIds = updateVariantsList.map((k) => k.id);
    originVariants.forEach((old) => {
      if (!updateIds.includes(old.id)) {
        removeVariantsIds.push(old.id);
        old.media.forEach((k) => {
          removeMediaIds.push(k.id);
        });
      }
    });

    // console.log({
    //   variants,
    //   originVariants,
    //   updateVariantsList,
    //   createVariantsList,
    //   removeVariantsIds,
    //   removeMediaIds,
    // });

    // preparing the create variants input for sending to the server

    const inputCreateVariants = createVariantsList.map((variant) => ({
      ...prepareVariantFields(variant),
      attributeValues: variant.attributeValues.map((attribute) => {
        return {
          attributeId: attribute.attributeId,
          value: stringifyAttributeValue(attribute),
        };
      }),
    }));

    // preparing the update variants input for sending to the server
    // all variants with the same attributes will be updated, even those that have no changes
    // preparing media input for binding to options: [{ variantId, mediaIds }]
    // media is updated only if there is at least 1 image in the array

    const inputUpdateVariants = [];
    const inputUpdateMedia = [];

    updateVariantsList.forEach((variant) => {
      inputUpdateVariants.push({
        variantId: variant.id,
        fields: prepareVariantFields(variant),
      });
      if (variant.media.length) {
        inputUpdateMedia.push({
          variantId: variant.id,
          mediaIds: variant.media.map((k) => k.id),
        });
      }
    });

    // console.log({
    //   inputCreateVariants,
    //   inputUpdateVariants,
    //   inputUpdateMedia,
    // });

    handleRemoveMedia(uniq(removeMediaIds))
      .then(() => {
        return Promise.allSettled([
          handleUpdateVariants(inputUpdateVariants),
          handleRemoveVariants(removeVariantsIds),
          handleLinkMedia(inputUpdateMedia),
        ])
          .then((res) => {
            if (res[0].status === 'rejected') {
              enqueueSnackbar('Error while updating variant', {variant: 'error'});
            }
            if (res[1].status === 'rejected') {
              enqueueSnackbar('Error while deleting variant', {variant: 'error'});
            }
            if (res[2].status === 'rejected') {
              enqueueSnackbar('Error while linking media image', {variant: 'error'});
            }
            if (res[0].value && inputUpdateVariants.length) {
              enqueueSnackbar('Successfully updated variants', {variant: 'success'});
            }
          });
      })
      .then(async () => {
        // the creation of a variant occurs only after all other mutations, so that up-to-date data is received from the server
        const { data, errors } = await handleCreateVariants(inputCreateVariants);
        if (errors) {
          enqueueSnackbar('Error while creating variant', {variant: 'error'});
          return;
        }
        const created = data?.createVariantsFromAttributes?.data || [];
        const createdMapped = mapVariants(created);
        if (createdMapped.length) {
          enqueueSnackbar('Successfully created variants', {variant: 'success'});
          return(createdMapped);
        } else {
          onRefetch(cloneDeep(updateVariantsList));
          refetchMedia();
          onSetOpenEditor(false);
        }
      })
      .then(async (res) => {
        // if new options have been created, then you need to link their pictures to them
        if (!res) {
          return;
        }
        const linkInputs = []; // [{ variantId, mediaIds }]
        const newOriginVariants = res.map((variant) => {
          const found = createVariantsList.find((old) => isSameAttributeValues(old.attributeValues, variant.attributeValues));
          if (found && found.media?.length) {
            linkInputs.push({
              variantId: variant.id,
              mediaIds: found.media.map((k) => k.id),
            });
            return {
              ...variant,
              media: found.media,
            }
          }
          return variant;
        });
        await handleLinkMedia(linkInputs);
        onRefetch(newOriginVariants);
        refetchMedia();
        onSetOpenEditor(false);
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        onSetSaving(false);
      });
  };
  
  return {
    onDone,
  }
};

export default useVariantsUpdate;
