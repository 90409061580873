import React, { useCallback, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { mutationCreateAttribute, mutationUpdateAttribute } from "../graphql/mutations";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { AttributesSchema } from "../schemas";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import { Form } from "../../../UI/Form/MyForm";
import StyledButton from "../../../UI/Button/Button";
import { useTranslation } from "react-i18next";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import Select from "../../../UI/Select/Select";
import FormNote from "../../../UI/Form/FormNote";
import useAttributes from "../hooks/useAttributes";
import SelectAllAutocomplete from "../../../package/src/SelectAllAutocomplete";
import { v4 } from "uuid";
import { useSnackbar } from "notistack";

const EditAttribute = ({ id, data, loading, setLoading, onClose }) => {
  const [list, setList] = useState([]);

  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { typeOptions } = useAttributes();

  const { control, handleSubmit, formState: { errors, isDirty }, reset } = useForm({
    resolver: yupResolver(AttributesSchema),
    defaultValues: {
      name: '',
      inputType: 'string',
      allowedValues: [],
    },
  });

  const watchType = useWatch({ control, name: 'inputType' });

  useEffect(() => {
    if (id && data) {
      const list = data.inputConfig?.allowedValues?.map((item) => ({
        value: v4(),
        label: item,
        selected: true,
      })) || [];

      reset({
        name: data.name,
        inputType: data.inputType,
        allowedValues: list.map((k) => k.value),
      });

      setList(list);
    }
  }, [id, data]);

  const createAttribute = async (input) => {
    return apolloClient.mutate({
      mutation: mutationCreateAttribute,
      variables: {
        input,
      },
    });
  };

  const updateAttribute = async (input) => {
    return apolloClient.mutate({
      mutation: mutationUpdateAttribute,
      variables: {
        input,
      },
    });
  };

  const onSubmit = useCallback((formData) => {
    setLoading(true);

    const listParams = {};

    if (formData.inputType === 'list') {
      const values = [];
      list.forEach((item) => {
        if (formData.allowedValues.includes(item.value)) {
          values.push(item.label);
        }
      });
      listParams.inputConfig = {
        listInputConfig: {
          allowedValues: values,
        },
      };
    }

    if (id) {
      updateAttribute({
        id,
        name: formData.name,
        inputType: formData.inputType,
        ...listParams,
      })
        .then(() => {
          enqueueSnackbar('Successfully updated attribute', {variant: 'success'});
          onClose();
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createAttribute({
        name: formData.name,
        inputType: formData.inputType,
        ...listParams,
      })
        .then((res) => {
          enqueueSnackbar('Successfully created attribute', {variant: 'success'});
          onClose(res.data?.createAttribute?.data);
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, list]);

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => checkKeyDown(e)}
    >
      <FormHeader>{id ? 'Edit Attribute' : 'Create Attribute'}</FormHeader>
      <InputLabel error={errors.name} disabled={loading}>Name</InputLabel>
      <Controller
        name="name"
        control={control}
        render={({ field }) => <FormInput
          placeholder='Create Attribute, for Example, Size'
          value={field.value}
          onChange={field.onChange}
          error={errors.name}
          disabled={loading}
        />}
      />

      <InputLabel error={errors.inputType} disabled={loading}>Choose Attribute type</InputLabel>
      <Controller
        name="inputType"
        control={control}
        render={({ field }) => <Select
          value={field.value}
          onChange={field.onChange}
          options={typeOptions}
          error={errors.inputType}
          disabled={loading}
          margin='0 0 8px 0'
        />}
      />
      <FormNote>The merchant will only be able to choose from the created values</FormNote>

      {
        watchType === 'list' &&
        <>
          <InputLabel error={errors.allowedValues} disabled={loading}>Available options</InputLabel>
          <Controller
            name="allowedValues"
            control={control}
            render={({ field }) => <SelectAllAutocomplete
              value={field.value}
              items={list}
              onChange={(val) => field.onChange(val.map(item => item.value))}
              handleAddToDB={(val) => {
                if (!val) return;
                setList((prev) => {
                  const id = v4();
                  const newValue = {
                    value: id,
                    label: val,
                    selected: true,
                  };
                  field.onChange([...field.value, id]);
                  return [...prev, newValue];
                });
              }}
              canAddNewItem
              alwaysSelectAll={false}
              placeholder='Add option'
              error={errors.allowedValues}
              disabled={loading}
            />}
          />
        </>
      }

      <DialogButtons>
        <StyledButton
          width={id ? '165px' : '102px'}
          type="submit"
          disabled={!isDirty || loading}
        >
          {id ? t('ui.save_changes') : t('ui.done')}
        </StyledButton>
      </DialogButtons>
    </Form>
  );
};

export default EditAttribute;
