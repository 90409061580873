import React, { useCallback, useEffect, useState } from "react";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import SelectAllAutocomplete from "../SelectAllAutocomplete";
import { GET_TAGS } from "./graphql/queries";
import { CREATE_TAG } from "./graphql/mutations";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

/**
 * TagSelectorWithData
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function TagSelectorWithData({shopId, onChange, value, ...other}) {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const [list, setList] = useState([]);

  const { data, refetch } = useQuery(GET_TAGS, {
    variables: { shopId },
    fetchPolicy: "network-only",
    onError: (e) => {
      enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
      console.log(e.message);
    }
  });

  const handleChange = (val) => {
    onChange(val.map(item => item.value));
  }

  const handleAddToDB = useCallback((newTag) => {
    if (!newTag) {
      return;
    }

    apolloClient.mutate({
      mutation: CREATE_TAG,
      variables: {
        input: {
          shopId,
          isVisible: true,
          name: newTag,
          displayTitle: newTag,
        },
      },
    })
      .then((res) => {
        const tag = res?.data?.addTag.tag;

        if (tag) {
          onChange([
            ...value,
            tag._id,
          ]);

          refetch();
        }
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
  }, [shopId, value, refetch, t])

  useEffect(() => {
    if (data) {
      const options = data?.tags.edges;
      if (options) {
        const result = options
          .map(({ node }) => ({
            value: node._id,
            label: node.name,
            selected: value.includes(node._id),
          }));
        setList(result);
      }
    }
  }, [data, value])

  return (
    <SelectAllAutocomplete
      items={list}
      onChange={handleChange}
      handleAddToDB={handleAddToDB}
      canAddNewItem
      {...other}
    />
  );
}

export default TagSelectorWithData;
