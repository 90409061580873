import {IconNavMerchants} from '../../icons';
import MerchantsPage from "./pages/MerchantsPage";
import ShopsPage from "../shops/pages/ShopsPage";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 68,
    path: "/merchants",
    MainComponent: MerchantsPage,
    IconComponent: IconNavMerchants,
    navigationItemLabel: 'menu.merchants',
    showShopSelector: false,
    godmodeOnly: true,
  });

  registerRoute({
    path: "/merchants/shops/:merchantId",
    MainComponent: ShopsPage,
    showShopSelector: false,
    godmodeOnly: true,
  });
}
