const config = {
  PUBLIC_GRAPHQL_API_URL: process.env.REACT_APP_PUBLIC_GRAPHQL_API_URL,
  PUBLIC_ROOT_URL: process.env.REACT_APP_PUBLIC_ROOT_URL,
  PUBLIC_I18N_BASE_URL: process.env.REACT_APP_PUBLIC_I18N_BASE_URL,
  PUBLIC_I18N_FALLBACK_LANGUAGE: process.env.REACT_APP_PUBLIC_I18N_FALLBACK_LANGUAGE,
  PUBLIC_FILES_BASE_URL: process.env.REACT_APP_PUBLIC_FILES_BASE_URL,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};

export default config;
