import React from "react";
import WosqHeader from "./components/WosqHeader";
import WosqFooter from "./components/WosqFooter";
import LayoutBackground from "./layouts/LayoutBackground";
import LayoutContainer from "./layouts/LayoutContainer";
import PagesList from "./pages";

const AuthenticationPage = () => {
  return (
    <LayoutBackground>
      <LayoutContainer>
        <WosqHeader />
        <PagesList />
        <WosqFooter />
      </LayoutContainer>
    </LayoutBackground>
  );
};

export default AuthenticationPage;
