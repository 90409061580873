export const MerchantRequest = `
  _id
  createdAt
  type
  reason
  state
  changeFrom
  changeTo
  shopId
  accountId
  shop {
    _id
    name
  }
  account {
    _id
    name
    primaryEmailAddress
  }
  fromShopCategory {
    _id
    name
  }
  toShopCategory {
    _id
    name
  }
`;
