import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import {makeStyles} from "@material-ui/core";
import InputLabel from '../../../../UI/Form/InputLabel';
import { getTags } from "./helpers";
import { ADD_TAGS_TO_PRODUCTS, REMOVE_TAGS_FROM_PRODUCTS } from "./mutations";
import Autocomplete from '../../../../UI/Autocomplete';
import TextField from '../../../../UI/TextField';
import Popper from '../../../../UI/Popper';
import StyledButton from '../../../../UI/Button';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    minWidth: 536,
    width: 792,
  },
  cardContainer: {
    alignItems: "center",
  },
  cardActions: {
    padding: theme.spacing(2),
    justifyContent: "flex-end",
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "block",
  },
  buttonsCont: {
    width: '100%',
    marginTop: '40px',
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
  }
}));

/**
 * TagSelector component
 * @param {Object} props Component props
 * @returns {React.Component} A React component
 */
function TagSelector(props) {
  const { selectedProductIds, shopIds, closeDialog } = props;

  const apolloClient = useApolloClient();
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchTags() {
      return getTags(apolloClient, '', shopIds[0]);
    }
    fetchTags()
    .then(r => setOptions(r))
  }, [])

  // eslint-disable-next-line consistent-return
  const handleTagsAction = option => {
    return new Promise((resolve, reject) => {
      const tagIds = selectedTags && selectedTags.map(({ value }) => value);
      const tags = selectedTags && selectedTags.map(({ label }) => label).join(", ");

      // Prevent user from executing action if he/she has not // yet selected at least one tag
      if (!tagIds.length) {
        enqueueSnackbar(t("admin.addRemoveTags.invalidSelection"), { variant: "warning" });
        closeDialog();
        return;
      }

      let mutationName;
      setIsLoading(true);

      function fetchData() {
        switch (option.type) {
          case "ADD":
            mutationName = "addTagsToProducts";
            return apolloClient.mutate({
              mutation: ADD_TAGS_TO_PRODUCTS,
              variables: {
                input: {
                  productIds: selectedProductIds,
                  shopId: shopIds[0],
                  tagIds,
                },
              },
            });

          case "REMOVE":
            mutationName = "removeTagsFromProducts";
            return apolloClient.mutate({
              mutation: REMOVE_TAGS_FROM_PRODUCTS,
              variables: {
                input: {
                  productIds: selectedProductIds,
                  shopId: shopIds[0],
                  tagIds,
                },
              },
            });
        }
      }

      fetchData()
      .then(res => {
        const {data, loading, error} = res;
        setIsLoading(loading);

        if (data && data[mutationName]) {
          // Notify user of performed action
          if (mutationName.startsWith("add")) {
            enqueueSnackbar(
              t("admin.addRemoveTags.addConfirmation", { count: selectedProductIds.length, tags })
            );
          } else {
            enqueueSnackbar(
              t("admin.addRemoveTags.removeConfirmation", { count: selectedProductIds.length, tags })
            );
          }
        }
  
        if (error) {
          enqueueSnackbar(t("admin.addRemoveTags.errorMessage"), { variant: "error" });
        }
      })
      .finally(() => {
        setSelectedTags([]);
        setIsLoading(false);
        closeDialog();
      });
    })
  };

  const handleChange = (e, value) => {
    setSelectedTags(value);
  }

  return (
    <div className={classes.cardRoot}>
      <InputLabel>Tags</InputLabel>

      <Autocomplete
        multiple
        options={options}
        getOptionLabel={option => option.label}
        renderInput={(params) => (
          <TextField {...params} placeholder="Search for tag" />
        )}
        value={selectedTags}
        PopperComponent={props => (
          <Popper {...props}>{props.children}</Popper>
        )}
        onChange={handleChange}
      />

      <div className={classes.buttonsCont}>
        <StyledButton
          width='180px'
          handleClick={() => {
            handleTagsAction({type: 'ADD'});
          }}
          disabled={isLoading}
        >
          Add
        </StyledButton>
        <StyledButton
          width='180px'
          mytype='secondary'
          handleClick={() => {
            handleTagsAction({type: 'REMOVE'});
          }}
          disabled={isLoading}
        >
          Remove
        </StyledButton>
      </div>
    </div>
  );
}

TagSelector.propTypes = {
  isVisible: PropTypes.bool,
  selectedProductIds: PropTypes.array,
  setVisibility: PropTypes.func,
  shopIds: PropTypes.array,
};

export default TagSelector;
