import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useAccountsColumns = () => {
  const { t } = useTranslation();

  const columns = useMemo(() => [
    {
      name: 'name',
      label: t('account.name'),
      sortable: false,
    },
    {
      name: 'email',
      label: t('account.email'),
      sortable: false,
    },
    {
      name: 'phoneNumber',
      label: t('account.phone_number'),
      sortable: false,
    },
    {
      name: 'groupName',
      label: t('account.group_name'),
      sortable: false,
    },
    {
      name: 'shopName',
      label: t('account.shop_name'),
      sortable: false,
    },
    {
      name: 'status',
      label: t('account.status'),
      sortable: false,
    },
    {
      name: 'options',
    },
  ], [t]);

  const columnsInvitations = useMemo(() => [
    {
      name: 'email',
      label: t('account.email'),
      sortable: false,
    },
    {
      name: 'shopName',
      label: t('account.shop_name'),
      sortable: false,
    },
    {
      name: 'groupName',
      label: t('account.group_name'),
      sortable: false,
    },
    {
      name: 'invitedBy',
      label: t('account.invited_by'),
      sortable: false,
    },
    {
      name: 'options',
    },
  ], [t]);

  return {
    columns,
    columnsInvitations,
  }
};
