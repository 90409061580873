import gql from "graphql-tag";
import discountCodeFragment from "../Fragments/DiscountCodeCommon";

export default gql`
  query discountCodesQuery(
    $shopId: ID!
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $before: ConnectionCursor
    $after: ConnectionCursor
    $offset: Int
    $filters: DiscountCodeFilterInput
  ) {
    discountCodes(
      shopId: $shopId
      first: $first
      last: $last
      before: $before
      after: $after
      offset: $offset
      filters: $filters
    ) {
      nodes {
        ...DiscountCodeCommon
      }
      totalCount
    }
  }
  ${discountCodeFragment}
`;
