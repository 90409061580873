import React, { useContext } from "react";
import PageStatusMessage from "../../../../UI/other/PageStatusMessage";
import PackageCard from "../packages/PackageCard";
import styled from "styled-components";
import { ShopEditContext } from "../../../../package/src/context/ShopEditContext";
import Quiz from "../quiz";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormCard from "../../../../UI/Form/FormCard";

const StyledCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  width: 100%;
  
  @media all and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SubscriptionList = ({ loading, onPackageCardClick, shopId }) => {
  const {
    packages,
  } = useContext(ShopEditContext);

  return (
    <FormCard>
      <Quiz
        shopId={shopId}
      />
      <StyledCardsContainer>
        {
          loading ?
            <PageStatusMessage>
              <CircularProgress />
            </PageStatusMessage> :
            packages.map(item => <PackageCard
              key={item.id}
              data={item}
              isActive={item.isActive}
              handleClick={() => onPackageCardClick(item.id)}
              shopId={shopId}
            />)
        }
      </StyledCardsContainer>
    </FormCard>
  );
};

export default SubscriptionList;
