import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  tooltip: {
    width: "218px",
    height: "inherit",
    maxHeight: "200px",
    borderRadius: "20px",
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    pointerEvents: "auto",
    padding: '10px',
    boxShadow: '0px 4px 10px rgba(0,0,0,0.05)',
  },
  arrow: {
    color: "#FFFFFF",
  },
}));

const CustomTooltip = ({title, isOpen, position, isEmpty}) => {
  const classes = useStyles();

  if (isEmpty) {
    return null;
  }

  return (
    <Tooltip
      open={isOpen}
      placement="top"
      classes={{
        arrow: classes.arrow,
        tooltip: classes.tooltip
      }}
      PopperProps={{
        anchorEl: {
          clientHeight: 0,
          clientWidth: 0,
          getBoundingClientRect: () => ({
            top: position.y,
            left: position.x,
            right: position.x,
            bottom: position.y,
            width: 0,
            height: 0,
          }),
        },
      }}
      // onOpen={() => handleOpen(true)}
      // onClose={() => handleOpen(false)}
      title={title}
      arrow
    >
      <div />
    </Tooltip>
  );
};

export default CustomTooltip;
