import styled from "styled-components";

const StyledLabel = styled.div`
  padding: 0;
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 18px;
`;

export default StyledLabel;
