export const convertAccessoryPermits = (key) => {
  switch (key) {
    case 'door': return 'doors';
    case 'floorfirst': return 'firstFloors';
    case 'floorsecond': return 'secondFloors';
    case 'name': return 'signAreas';
    case 'roof': return 'roofs';
    case 'umbrella': return 'umbrellas';
    default: return key;
  }
}
