import React from "react";
import InputLabel from "../../../../../UI/Form/InputLabel";
import FormInput from "../../../../../UI/Form/FormInput";

const EditText = ({ value, onChange }) => {
  const handleChange = (e) => {
    const val = e.target.value;
    const isValid = Boolean(val.trim());
    onChange(val, isValid);
  }

  return (
    <>
      <InputLabel>Value</InputLabel>
      <FormInput
        placeholder='Type text'
        onChange={handleChange}
        value={value}
        multiline
        rows={8}
        inputProps={{
          maxLength: 1000,
        }}
      />
    </>
  );
};

export default EditText;
