import React from 'react';
import styled from 'styled-components';
import FormDetails from "../../../UI/Form/FormDetails";
import { useTranslation } from "react-i18next";
import useDisplayStatuses from "../hooks/useDisplayStatuses";

const StyledPayment = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const OrderPayment = ({ payment }) => {
  const { t } = useTranslation();
  const { amount, captureErrorMessage, displayName, processor, riskLevel, status, transactionId } = payment;
  const { getDisplayStatuses, getRiskLevelText } = useDisplayStatuses();

  return (
    <StyledPayment>
      <FormDetails>
        <p className='title'>{displayName}</p>
        <p className='title'>{amount.displayAmount}</p>
      </FormDetails>

      <FormDetails isList style={{marginBottom: 24}}>
        {riskLevel && riskLevel !== "normal" && payment.mode !== "captured" && (
          <p className='text'>
            <span>{t('orders.payment_risk_level')}:</span> {getRiskLevelText(riskLevel)}
          </p>
        )}
        <p className='text'><span>{t('orders.processor')}:</span> {processor}</p>
        <p className='text'><span>{t('orders.transaction_id')}:</span> {transactionId}</p>
        <p className='text'><span>{t('orders.status')}:</span> {getDisplayStatuses(status)}</p>
        {captureErrorMessage && (
          <p className='text text--margin24'>
            <span>{t('orders.capture_error')}:</span> {captureErrorMessage}
          </p>
        )}
      </FormDetails>
    </StyledPayment>
  );
}

export default OrderPayment;
