import gql from "graphql-tag";

export const productBrands = gql`
  query productBrands(
    $productBrandIds: [ID]!
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $before: ConnectionCursor
    $after: ConnectionCursor
    $offset: Int
    $sortOrder: SortOrder
    $sortBy: ProductBrandSortByField
    $filter: String
    $genericFilters: GenericFilterInput
  ) {
    productBrands(
      productBrandIds: $productBrandIds
      first: $first
      last: $last
      before: $before
      after: $after
      offset: $offset
      sortOrder: $sortOrder
      sortBy: $sortBy
      filter: $filter
      genericFilters: $genericFilters
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        _id
        name
        createdAt
        updatedAt
        createdBy {
          name
          primaryEmailAddress
        }
      }
      totalCount
    }
  }
`;

export const productBrand = gql`
  query productBrand($id: ID!) {
    productBrand(id: $id) {
      name
    }
  }
`;
