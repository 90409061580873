import React, { useCallback, useEffect, useState } from "react";
import OverrideForm from "../../../package/src/OverrideForm/OverrideForm";
import { getPackage } from "../graphql/queries";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { updatePackage } from "../graphql/mutations";
import { useTranslation } from "react-i18next";

const FormSubscriptionOverride = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [initialRate, setInitialRate] = useState(null);

  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    if (!id) {
      return;
    }

    setLoading(true);

    apolloClient.query({
      query: getPackage,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    })
      .then(res => {
        const data = res.data.subscriptionPackage?.baseOverride;

        if (!data) {
          return;
        }

        setInitialRate({
          enable: data.enabled,
          percentage: data.percent || '',
          period: {
            startDate: new Date(data.period.start) || null,
            endDate: new Date(data.period.end) || null,
          },
          notes: data.notes || '',
        });
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setLoading(false))
  }, [id, t]);

  const onSubmit = useCallback(async (formData) => {
    setLoading(true);

    return apolloClient.mutate({
      mutation: updatePackage,
      variables: {
        id,
        input: {
          override: {
            enabled: formData.enable,
            percent: Number(formData.percentage),
            period: {
              start: formData.period.startDate?.toISOString() || '',
              end: formData.period.endDate?.toISOString() || '',
            },
            notes: formData.notes,
          }
        },
      },
    })
      .then((res) => {
        return res.data.updateSubscriptionPackage.baseOverride;
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, t]);

  return (
      <OverrideForm
        title={t('subscription.base_rate_override')}
        loading={loading}
        data={initialRate}
        onSubmit={onSubmit}
      />
  );
};

export default FormSubscriptionOverride;
