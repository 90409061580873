import gql from "graphql-tag";

export default gql`
  mutation updateVariants($input: UpdateVariantsInput!) {
    updateVariants(input: $input) {
      data {
        _id
      }
    }
  }
`;
