import React from 'react';
import styled, { css } from 'styled-components';
import {colors} from '../../constants';

const StyledDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.black};
  margin-bottom: 16px;
  user-select: none;
  
  ${props => props.margin && css`
    margin: ${props.margin};
  `}
`

const CheckboxContainer = ({children, margin, ...other}) => {
  return (
    <StyledDiv
      margin={margin}
      {...other}
    >
      {children}
    </StyledDiv>
  );
}

export default CheckboxContainer;
