import React from 'react';
import PropTypes from "prop-types";
import {Button} from '@material-ui/core';
import styled, {css} from 'styled-components';
import {colors} from '../../constants';

const getCss = (type) => {
  switch (type) {
    case 'secondary': return secondaryCss;
    case 'third': return thirdCss;
    case 'redSolid': return redSolidCss;
    case 'redHollow': return redHollowCss;
    case 'miniBlue': return miniCss;
    default: return null;
  }
}

const secondaryCss = css`
  background: ${colors.backgroundGrey};
  color: ${colors.darkGrey};

  &:hover {
    background: #F4F6FA;
  }

  &:focus {
    background: #E9EBF0;
  }
`;

const thirdCss = css`
  background: ${colors.brize};
  color: #FFFFFF;

  &:hover {
    background: #CFD5E8;
  }

  &:focus {
    background: #BFC7E0;
  }
`;

const redSolidCss = css`
  background: ${colors.red};
  color: #FFFFFF;

  &:hover {
    background: ${colors.redLight};
  }

  &:focus {
    background: ${colors.red};
  }
`;

const redHollowCss = css`
  background: #FFFFFF;
  color: ${colors.red};
  border: 1px solid ${colors.red};
  height: 38px;

  &:hover {
    color: ${colors.redLight};
    border-color: ${colors.redLight};
    background: #FFFFFF;
  }

  &:focus {
    color: ${colors.redLight};
    border-color: ${colors.redLight};
    background: #FFFFFF;
  }
`;

const miniCss = css`
  height: 40px;
  font-size: 16px;
`;

const StyledButton= styled(Button)`
  &.MuiButtonBase-root {
    border-radius: 50px;
    background: linear-gradient(180deg, #4270FF 0%, #164EFF 100%);
    font-family: Fredoka, sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    height: ${props => props.height};
    width: ${props => props.width};
    min-width: ${props => props.minwidth};

    &:hover {
      background: linear-gradient(180deg, #4874FF 0%, #3461EE 100%);
    }

    &:focus {
      background: linear-gradient(180deg, #3563F3 0%, #2C59E9 100%);
    }

    &.Mui-disabled {
      background: #E6E6E6;
      color: #C5C5C5;
    }
  }

  .MuiIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  ${props => css`
    &.MuiButtonBase-root {
      ${getCss(props.mytype)}
    }
  `}
  
  ${props => props.mysize === 'small' && css`
    &.MuiButtonBase-root {
      height: 32px;
      font-size: 16px;
    }
  `}
`

const CustomButton = React.forwardRef((props, ref) => {
  const {
    children, 
    handleClick, 
    width = 'auto',
    height = '42px',
    minwidth = 'unset',
    disabled,
    mytype = 'primary',
    mysize = 'normal',
    onClick,
    type = 'button',
    ...other
  } = props;
  return <StyledButton
          onClick={handleClick || onClick}
          width={width}
          height={height}
          minwidth={minwidth}
          disabled={disabled}
          mytype={mytype}
          mysize={mysize}
          type={type}
          ref={ref}
          {...other}
        >
          {children}
        </StyledButton>
})

CustomButton.propTypes = {
  children: PropTypes.node,
  handleClick: PropTypes.func,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  mytype: PropTypes.oneOf(['primary', 'secondary', 'third', 'redSolid', 'redHollow', 'miniBlue']),
  mysize: PropTypes.oneOf(['normal', 'small']),
  minwidth: PropTypes.string,
}

export default CustomButton;
