import { LINKS, TURNS } from "../constants/constants";

export default function isConnectInStart(street, connectUuid) {
  for (const link of LINKS) {
    if (street[link] !== null) {
      for (const key of TURNS) {
        let value = street[link][key];
        if (value === null) {
          continue;
        }
        if (value["targetId"] === connectUuid) {
          return link === "startLinks";
        }
      }
    }
  }
  return false;
}
