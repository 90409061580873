import gql from "graphql-tag";

export default gql`
  mutation updateSimpleInventory($input: UpdateSimpleInventoryInput!) {
    updateSimpleInventory(input: $input) {
      inventoryInfo {
        inventoryInStock
      }
    }
  }
`;
