import styled from "styled-components";
import { Button } from "@material-ui/core";

const AddButton = styled(Button)`
  margin: ${props => props.margin ? props.margin : '16px 0 0'};
  padding: 8px 0;
  
  .MuiButton-label {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &:hover {
    background: none;
  }
`;

export default AddButton;
