import React, {useState} from "react";
import i18next from "i18next";
import { Menu } from "@material-ui/core";
import MenuItem from '../../../UI/MenuItem';
import { useHistory } from "react-router-dom";
import { StyledIconButton } from '../ProfileMenu/ProfileMenu';
import {IconSettings} from '../../../icons';

/**
 * @summary A list settings for a shop
 * @returns {Node} React node
 */
export default function SettingsMenu({ routes: settingsRoutes }) {
  const history = useHistory();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  return (
    <>
      <StyledIconButton
        onClick={event => {
          setMenuAnchorEl(event.currentTarget);
        }}
      >
        <IconSettings/>
      </StyledIconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        {settingsRoutes &&
          Array.isArray(settingsRoutes) &&
          settingsRoutes.map(setting => (
            <MenuItem
              key={setting.name}
              onClick={() => {
                setMenuAnchorEl(null); // close menu
                history.push(setting.path);
              }}
            >
              {i18next.t(setting.navigationItemLabel)}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
