import bulkExportQuery from "../graphql/queries/bulkExport";
import moment from "moment";

const handleBulkExport = async ({
  shopIds,
  enqueueSnackbar,
  setIsImportLoading,
  apolloClient,
  persistantSnackbar,
  currentShop,
  t,
}) => {
  if (shopIds.length !== 1) {
    enqueueSnackbar(t('product.please_select_one_shop'), { variant: "error" });
    return;
  }

  setIsImportLoading(true);

  let data = null;

  try {
    ({ data } = await apolloClient.query({
      query: bulkExportQuery,
      variables: {
        shopId: shopIds[0],
      },
      fetchPolicy: "network-only",
    }));

    setIsImportLoading(false);

    const { products } = data.bulkProductExport;

    data = products || [];
  } catch (error) {
    persistantSnackbar(error.toString().replace("GraphQL error:", ""), "error");
    console.error(error);

    setIsImportLoading(false);
  }

  const NEWLINE = "\n";
  const noData = "";
  const arrayDelimeter = ";";
  let csvString = `ID,Parent ID,Is Visible,Image IDs (separated by semi-column. Priority left to right),Product Title,Product Subtitle,Product Short Description,Product Long Discription,Product Purchase Notes,Product Is Published,Facebook Share Message,Twitter Share Message,Direct Share Message,Product Tags (separated by semi-column),Product Brands (separated by semi-column),Product Categories (separated by semi-column),Related Product IDs (separated by semi-column),Variant Attribute Label,Variant Short Title or Hex,Variant Full Title,Variant SKU,Variant Origin Country,Variant Width,Variant Length,Variant Height,Variant Weight,Variant Price,Variant Original Price,Variant Items In Stock,Variant Can Be Backordered${NEWLINE}`;

  data?.forEach(
    ({
       externalId: productExternalId,
       isVisible,
       title,
       subtitle,
       shortDescription,
       longDescription,
       purchaseNotes,
       isPublished,
       facebookShareMsg,
       twitterShareMsg,
       directShareMsg,
       tags,
       brands,
       categories,
       relatedProducts,
       imageIds: productImageIds,
       variants,
     }) => {
      csvString += `${productExternalId},${noData},${isVisible},${productImageIds.join(
        arrayDelimeter
      )},${title},${subtitle},${shortDescription},${longDescription},${purchaseNotes},${isPublished},${facebookShareMsg},${twitterShareMsg},${directShareMsg},${tags.join(
        arrayDelimeter
      )},${brands.join(arrayDelimeter)},${categories.join(arrayDelimeter)},${relatedProducts.join(
        arrayDelimeter
      )},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${NEWLINE}`;

      variants.forEach(
        ({
           externalId: variantExternalId,
           isVisible,
           attributeLabel,
           attributeShortTitle,
           fullTitle,
           sku,
           originCountry,
           width,
           length,
           height,
           weight,
           price,
           originalPrice,
           inventoryInStock,
           canBackorder,
           imageIds: variantImageIds,
         }) => {
          csvString += `${variantExternalId},${productExternalId},${isVisible},${variantImageIds.join(
            arrayDelimeter
          )},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${attributeLabel},${attributeShortTitle},${fullTitle},${sku},${originCountry},${width},${length},${height},${weight},${price},${originalPrice},${inventoryInStock},${canBackorder}${NEWLINE}`;
        }
      );
    }
  );

  const download = (content, mimeType, filename) => {
    let a = document.createElement("a");
    const blob = new Blob([content], { type: mimeType });
    const url = URL.createObjectURL(blob);
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    a.click();
  };

  download(
    csvString,
    "text/csv",
    `${t('product.products')} - ${currentShop.name} - ${moment().format("MM/DD/YYYY hh:mm:ss")}.csv`
  );
};

export default handleBulkExport;
