/**
 * Данные на входе: массив с объектами-атрибутами.
 * Объекты атрибутов сдержат значение value - массив. У элементов массива может быть любой тип.
 * Данные на выходе: тот же массив с атрибутами, но value перемножены, а каждый элемент массива value теперь сам является value.
 * Например: 1x1 = 1, 2x2 = 4, 3x2 = 6 и т.д.
 * где множители - это кол-во элементов исходных массивов value, а произведение - это кол-во элементов результирующего массива
 * @param arrays
 * @returns {*[]}
 */

const processAttributeValues = (arrays) => {
  if (arrays.length === 0) {
    return [];
  }

  const restArrays = arrays.slice(1);
  const restCombinations = processAttributeValues(restArrays);
  const result = [];

  if (restCombinations.length === 0) {
    arrays[0].value.forEach((item) => {
      result.push([{
        attributeId: arrays[0].attributeId,
        attributeName: arrays[0].attributeName,
        inputType: arrays[0].inputType,
        value: item,
      }]);
    });
  } else {
    arrays[0].value.forEach((item) => {
      restCombinations.forEach((combination) => {
        result.push([
          {
            attributeId: arrays[0].attributeId,
            attributeName: arrays[0].attributeName,
            inputType: arrays[0].inputType,
            value: item,
          },
          ...combination,
        ]);
      });
    });
  }

  return result;
};

export default processAttributeValues;
