import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const CustomSvgButton = ({ svg, style }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={style}>
      <SvgIcon
        component={svg}
        style={{ width: 20, height: 20 }}
        viewBox="0 0 20 20"
      />
    </div>
  );
}

export default CustomSvgButton;
