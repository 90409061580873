import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";

const Wrapper = styled(Button)`
  height: 40px;
  background: #F6F6F6;
  border-radius: 20px;
  padding: 10px 32px;
  color: #9A9A9A;
  font-family: Fredoka, sans-serif;
  font-size: 16px;
  font-weight: 500;
`;

const ButtonWide = ({ children, onClick }) => {
  return (
    <Wrapper
      onClick={onClick}
    >
      { children }
    </Wrapper>
  );
};

export default ButtonWide;
