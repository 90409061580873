import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import {colors} from '../../constants';
import {SvgChevronDown} from '../../icons';

const StyledButton = styled.button`
  border: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  height: 42px;
  padding: 0 22px;
  display: flex;
  justify-content: ${props => props.isrtl === 'true' ? 'flex-end' : 'flex-start'};
  align-items: center;
  gap: 24px;
  background: ${props => props.open ? '#F3F6FF': 'white'};
  font-family: 'Mulish', sans-serif;
  font-weight: ${props => props.open ? 700 : 400};
  font-size: 15px;
  transition: .2s;
  color: ${colors.black};
  user-select: none;
  
  &:hover {
    background: #F3F6FF;
    font-weight: 700;
  }

  & .MuiSvgIcon-root {
    width: 14px;
    transition: .1s;
    transform: ${props => props.open ? 'rotate(-90deg)' : 'rotate(90deg)'};

    path {
      fill: ${colors.black};
      transition: .2s;
    }
  }
`

const ComplexMenuButton = ({children, isRtl, ...other}) => {
  return (
    <StyledButton
      isrtl={String(isRtl)}
      {...other}
    >
      {
        isRtl ?
          <>
            {children}
            <SvgChevronDown/>
          </> :
          <>
            <SvgChevronDown/>
            {children}
          </>
      }
    </StyledButton>
  );
}

ComplexMenuButton.propTypes = {
  onClick: PropTypes.func,
  open: PropTypes.bool,
}

export default ComplexMenuButton;
