import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ProductVariantsContext } from "../../../../../package/src/context/ProductVariantsContext";
import ButtonRounded from "./ButtonRounded";
import svgDelete from '../../../../../icons/variantEditor/delete.svg';
import PopoverInputNumber from "./PopoverInputNumber";
import DialogMessages from "../../../../../UI/Dialog/DialogMessages";
import CheckboxContainer from "../../../../../UI/Checkbox/CheckboxContainer";
import Checkbox from "../../../../../UI/Checkbox/Checkbox";
import DialogButtons from "../../../../../UI/Dialog/DialogButtons";
import CustomButton from "../../../../../UI/Button";
import Dialog from "../../../../../UI/Dialog/Dialog";
import { useTranslation } from "react-i18next";

const Wrapper = styled('div')`
  display: flex;
  gap: 8px;
`;

const VariantControls = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const {
    selectedVariants,
    variants,
    onUpdateVariantValue,
    onRemoveVariants,
    dontAsk,
    onSetDontAsk,
  } = useContext(ProductVariantsContext);

  const { t } = useTranslation();

  if (!selectedVariants.length) {
    return null;
  }

  const variant = variants.find((k) => k.id === selectedVariants[0]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSave = (fieldName, newVal) => {
    for (let i = 0; i < selectedVariants.length; i++) {
      onUpdateVariantValue(selectedVariants[i], fieldName, newVal);
    }
  };

  const handleConfirmDelete = () => {
    onRemoveVariants(selectedVariants);
    handleCloseDialog();
  };

  const initDelete = () => {
    if (dontAsk.deleteVariant) {
      onRemoveVariants(selectedVariants);
    } else {
      setOpenDialog(true);
    }
  };

  const handleChangeDontAsk = () => {
    onSetDontAsk('deleteVariant', !dontAsk.deleteVariant);
  };

  return (
    <Wrapper>
      <PopoverInputNumber
        buttonText='Change Price'
        isFloatNumber
        onSave={(val) => handleSave('price', val)}
        value={variant?.price ?? 0}
      />
      <PopoverInputNumber
        buttonText='Change Quantity'
        onSave={(val) => handleSave('quantity', val)}
        value={variant?.quantity ?? 0}
      />
      <ButtonRounded
        onClick={initDelete}
        imgSrc={svgDelete}
      />

      <Dialog
        open={openDialog}
        handleClose={handleCloseDialog}
      >
        <DialogMessages
          title={`Delete selected Variant${selectedVariants.length > 1 ? 's' : ''}?`}
          titleTextAlign='start'
        />
        <CheckboxContainer margin='16px 0 0'>
          <Checkbox
            checked={dontAsk.deleteVariant}
            onChange={handleChangeDontAsk}
          />
          Don't ask me again
        </CheckboxContainer>
        <DialogButtons justifyButtons='center'>
          <CustomButton
            width='166px'
            mytype='secondary'
            handleClick={handleCloseDialog}
          >
            {t('ui.cancel')}
          </CustomButton>
          <CustomButton
            width='166px'
            handleClick={handleConfirmDelete}
          >
            Delete
          </CustomButton>
        </DialogButtons>
      </Dialog>
    </Wrapper>
  );
};

export default VariantControls;
