import * as Yup from "yup";

export const OverrideSchema = Yup.object().shape({
  enable: Yup.boolean(),
  percentage: Yup.number()
    .typeError('errors.amount_must_be_number')
    .required("errors.field_required")
    .min(1, "errors.percentage_not_0"),
  period: Yup.object().shape({
    startDate: Yup.date().required("errors.field_required"),
    endDate: Yup.date().required("errors.field_required"),
  }),
  notes: Yup.string(),
})
