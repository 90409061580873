import { createContext, useCallback, useMemo, useState } from "react";

export const AuthContext = createContext({
  viewer: null,
  page: null,
  user: null,
  typedEmail: '',
  onSetViewer: (val) => {},
  onSetPage: (val) => {},
  onSetUser: (val) => {},
  onSetTypedEmail: (val) => {},
});

// PAGES:
// login, signup, verifyCode, mfaCode, verified, complete,
// forgotPassword, resetPassword, requiredChangePassword

export const AuthProvider = ({ children }) => {
  const [viewer, setViewer] = useState(null);
  const [page, setPage] = useState(null);
  const [user, setUser] = useState(null);
  const [typedEmail, setTypedEmail] = useState('');

  const onSetViewer = useCallback((val) => {
    setViewer(val);
  }, []);

  const onSetPage = useCallback((val) => {
    setPage(val);
  }, []);

  const onSetUser = useCallback((val) => {
    setUser(val);
  }, []);

  const onSetTypedEmail = useCallback((val) => {
    setTypedEmail(val);
  }, []);

  const value = useMemo(() => {
    return {
      viewer,
      page,
      user,
      onSetViewer,
      onSetPage,
      onSetUser,
      onSetTypedEmail,
      typedEmail,
    }
  }, [
    viewer,
    page,
    user,
    onSetViewer,
    onSetPage,
    onSetUser,
    onSetTypedEmail,
    typedEmail,
  ]);

  return (
    <AuthContext.Provider value={value}>
      { children }
    </AuthContext.Provider>
  )
};
