import gql from "graphql-tag";

export const queryAllAttributes = gql`
  query attributes($input: AttributesQueryInput) {
    attributes(input: $input) {
      data {
        id
        name
        valueType
        inputType
        inputConfig {
          ...on ListInputConfig {
            allowedValues
          }
        }
      }
    }
  }
`;

export const queryProductTypes = gql`
  query productTypes {
    productTypes {
      data {
        id
        name
        variantAttributes {
          attribute {
            id
            name
          }
        }
      }
    }
  }
`;
