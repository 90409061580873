import React, { useContext, useEffect, useState } from "react";
import styled from 'styled-components';
import FormCard from "../../../UI/Form/FormCard";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import { Controller, useFormContext } from "react-hook-form";
import PageLink from "../../../UI/other/PageLink";
import ProductMediaGallery from "../components/ProductMediaGallery";
import BrandSelectorWithData from "../../../package/src/BrandSelectorWithData";
import FormNote from "../../../UI/Form/FormNote";
import FormInput from "../../../UI/Form/FormInput";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import CheckboxContainer from "../../../UI/Checkbox/CheckboxContainer";
import { useTranslation } from "react-i18next";
import Select from "../../../UI/Select/Select";
import { Country } from "country-state-city";
import { ProductVariantsContext } from "../../../package/src/context/ProductVariantsContext";

const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const BlockDetails = ({ productId, shopId, refetchMedia }) => {
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [countriesList, setCountriesList] = useState([]);

  const { control, loading, handleOpenDialog, formState: { errors } } = useFormContext();
  const { t } = useTranslation();

  const { onUpdateOriginVariantParam } = useContext(ProductVariantsContext);

  useEffect(() => {
    const countries = Country.getAllCountries().map(item => ({
      name: item.name,
      value: item.isoCode,
    }));
    setCountriesList(countries);
  }, []);

  const handleOpenMediaGallery = () => {
    if (!productId) {
      handleOpenDialog();
      return;
    }
    setDialogName('gallery');
    setDialogOpen(true);
  }

  const handleNumberChange = (field, e, func) => {
    let value = e.target.value;
    if (value < 0) value = 0;
    onUpdateOriginVariantParam(field, value);
    func(value);
  }

  const handleQuantityChange = (field, e, func) => {
    let value = e.target.value;
    if (value < 0) value = 0;
    if (value > 999999) value = 999999;
    onUpdateOriginVariantParam(field, value);
    func(value);
  }

  const onChangeAttribute = (field, e, func) => {
    onUpdateOriginVariantParam(field, e.target.value);
    func(e);
  };

  return (
    <>
      <FormCard>
        <FormHeader>
          <StyledHeader>
            {t('product.images')}
            <PageLink
              handleClick={handleOpenMediaGallery}
              margin='0'
            >
              {t('product.open_media_gallery')}
            </PageLink>
          </StyledHeader>
        </FormHeader>

        <ProductMediaGallery
          productId={productId}
          shopId={shopId}
          dialogName={dialogName}
          setDialogName={setDialogName}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          refetchMedia={refetchMedia}
        />
      </FormCard>

      <FormCard>
        <FormHeader helpText={t('product.please_select_brand_for_product')}>{t('product.brand')}</FormHeader>
        <InputLabel disabled={loading}>{t('product.choose_brand')}</InputLabel>
        <Controller
          name="brand"
          control={control}
          render={({ field }) => <BrandSelectorWithData
            placeholder={t('product.choose_brand')}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
          />}
        />
        <InputLabel disabled={loading}>{t('product.origin_country')}</InputLabel>
        <Controller
          name="originCountry"
          control={control}
          render={({ field }) => <Select
            placeholder={t('product.choose_country')}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            options={countriesList}
          />}
        />
      </FormCard>

      <FormCard>
        <FormHeader>{t('product.price')}</FormHeader>
        <InputLabel disabled={loading}>{t('product.price')}</InputLabel>
        <Controller
          name="price"
          control={control}
          render={({ field }) => <FormInput
            type='number'
            value={field.value}
            onChange={(e) => handleNumberChange('price', e, field.onChange)}
            disabled={loading}
          />}
        />
        <InputLabel disabled={loading}>{t('product.compare_at_price')}</InputLabel>
        <Controller
          name="compareAtPrice"
          control={control}
          render={({ field }) => <FormInput
            type='number'
            value={field.value}
            onChange={(e) => handleNumberChange('compareAtPrice', e, field.onChange)}
            disabled={loading}
            margin='0 0 16px 0'
          />}
        />
        <FormNote style={{ margin: 0 }}>{t('product.original_price_msrp')}</FormNote>
      </FormCard>

      <FormCard>
        <FormHeader>{t('product.inventory')}</FormHeader>
        <InputLabel disabled={loading} error={errors.sku}>SKU</InputLabel>
        <Controller
          name="sku"
          control={control}
          render={({ field }) => <FormInput
            value={field.value}
            onChange={(e) => onChangeAttribute('sku', e, field.onChange)}
            disabled={loading}
            error={errors.sku}
          />}
        />
        <InputLabel disabled={loading}>{t('product.track_quantity')}</InputLabel>
        <Controller
          name="quantity"
          control={control}
          render={({ field }) => <FormInput
            value={field.value}
            onChange={(e) => handleQuantityChange('quantity', e, field.onChange)}
            disabled={loading}
            type='number'
          />}
        />
        <CheckboxContainer margin='0'>
          <Controller
            name="enableInventory"
            control={control}
            render={({ field }) => <Checkbox
              checked={field.value}
              onChange={field.onChange}
              disabled={loading}
            />}
          />
          {t('product.enable_inventory')}
        </CheckboxContainer>
        <CheckboxContainer margin='0'>
          <Controller
            name="allowBackorders"
            control={control}
            render={({ field }) => <Checkbox
              checked={field.value}
              onChange={field.onChange}
              disabled={loading}
            />}
          />
          {t('product.allow_backorders')}
        </CheckboxContainer>
      </FormCard>
    </>
  );
}

export default BlockDetails;
