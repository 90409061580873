import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import {Form} from '../../../UI/Form/MyForm';
import FormInput from "../../../UI/Form/FormInput";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import StyledButton from "../../../UI/Button";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {ShopCategorySchema} from '../schemas';
import { useTranslation } from "react-i18next";

const FormShopCategory = ({confirmAction, initialProps: init, dialogName}) => {
  const { t } = useTranslation();

  const { control, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(ShopCategorySchema),
    defaultValues: {
      name: '',
      description: '',
    },
  });

  useEffect(() => {
    setValue('name', init.name || '');
    setValue('description', init.description || '');
  }, [init])

  const onSubmit = (newData) => {
    confirmAction(newData);
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeader>{dialogName === 'edit' ? t('shops.edit_shop_category') : t('shops.add_shop_category')}</FormHeader>
      <InputLabel error={errors.name}>
        {t('shops.shop_category_name')}
      </InputLabel>
      <Controller
        name="name"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('shops.write_shop_category_name')}
          value={field.value}
          onChange={field.onChange}
          error={errors.name}
        />}
      />

      <InputLabel error={errors.description} optionalText={t('ui.optional')}>
        {t('shops.description')}
      </InputLabel>
      <Controller
        name="description"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('shops.tell_about_category')}
          value={field.value}
          onChange={field.onChange}
          error={errors.description}
          multiline
          rows={4}
        />}
      />

      <DialogButtons marginTop='16px'>
        <StyledButton
          width='180px'
          type="submit"
        >
          {dialogName === 'edit' ? t('ui.save') : t('ui.create')}
        </StyledButton>
      </DialogButtons>
    </Form>
  );
}

FormShopCategory.propTypes = {
  confirmAction: PropTypes.func.isRequired,
  dialogName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  initialProps: PropTypes.object,
}

export default FormShopCategory;
