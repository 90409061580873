import moment from "moment";
import React from "react";

export const modifyData = (data, method, options) => {
  return data.map(item => {
    const result = {
      id: item._id,
      fullName: item.name,
      email: item.email,
      phoneNumber: item.phoneNumber,
      shopName: 'todo',
      category: 'todo',
      description: item.description,
      createdAt: moment(item.createdAt).format("MM.DD.YYYY"),
    }

    // if table
    if (method !== 'export' && options) {
      result.options = options;
      return result;
    }

    // if 'export' button
    result.__typename = item.__typename;
      return result;
  });
}
