import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { cleanReaderResult } from "../utils/cleanReaderResult";

function createEnum(values) {
  const enumObject = {};
  for (const val of values) {
    enumObject[val] = val;
  }
  return Object.freeze(enumObject);
}

// TODO: this peace of shit needs refactoring
// the reason of that all is in api of backend, the field's names of which were changed several times.

export const SHOP_PARTS = createEnum([
  "door",
  "floorfirst",
  "floorsecond",
  "name",
  "roof",
  "umbrella"]);

export const SHOP_PARTS_WITHOUT_UMBRELLA = createEnum([
  "door",
  "floorfirst",
  "floorsecond",
  "name",
  "roof"]);

export const MAIN_SHOP_PARTS = createEnum([
  "floorfirst",
  "floorsecond",
  "name",
  "roof"]);

export const INSIDE_SHOP_PARTS = createEnum(["umbrella", "door"]);

export const SHOP_PARTS_INFO_MAP = new Map([
  [
    "firstFloorInfo", "floorfirst",
  ],
  [
    "secondFloorInfo", "floorsecond",
  ],
  [
    "signAreaInfo", "signarea",
  ],
  [
    "doorInfo", "door",
  ],
  [
    "roofInfo", "roof",
  ],
  [
    "umbrellaInfo", "umbrella",
  ],
]);

export const SHOP_PARTS_KEY_MAP = new Map([
  ["door", "door"],
  ["name", "sign"],
  ["roof", "roof"],
  ["floorsecond", "secondFloor"],
  ["floorfirst", "firstFloor"],
  ["umbrella", "umbrella"],
]);

export const BACKEND_TO_NAMES_MAP = new Map([
  ["door", "Door"],
  ["name", "SignArea"],
  ["roof", "Roof"],
  ["floorsecond", "SecondFloor"],
  ["floorfirst", "FirstFloor"],
  ["umbrella", "Umbrella"],
])

const DEFAULT_COLOR = "#e5cdc4";

class Shop {
  scene; // THREE.Scene
  assets;  // array of THREE.Mesh
  currentAssetsModelMap;
  currentAccessoryType;
  currentColorsMap;
  currentColor;
  currentTextureMap;
  currentBase64ImageMap;
  permittedModules;

  constructor(scene) {
    this.scene = scene;
    this.assets = [];
    this.currentAssetsModelMap = {};
    this.currentColorsMap = {};
    this.currentTextureMap = {};
    this.currentBase64ImageMap = {};
    this.currentAccessoryType = "Door";
    this.currentColor = DEFAULT_COLOR;
    this.permittedModules = {};

    for (const shopPart of Object.keys(SHOP_PARTS)) {
      let key = BACKEND_TO_NAMES_MAP.get(shopPart);
      this.currentColorsMap[key] = DEFAULT_COLOR;
    }
  }

  getUrlByModelId(mId) {
    for (const module of Object.keys(this.permittedModules)) {
      for (const obj of this.permittedModules[module]) {
        if (obj.id === mId) {
          return obj.model.url;
        }
      }
    }
    return '';
  }

  getTypeNameByModelId(mId) {
    for (const module of Object.keys(this.permittedModules)) {
      for (const obj of this.permittedModules[module]) {
        if (obj.id === mId) {
          return obj.__typename;
        }
      }
    }
    return '';
  }

  loadConfigurationFromBackEnd(jsonData, permittedModules) {
    this.permittedModules = permittedModules;
    if (jsonData === null) {
      return;
    }
    let layout = jsonData.shopConfig.layout;
    for (const partsKey of SHOP_PARTS_INFO_MAP.keys()) {
      if (layout[partsKey] === undefined || layout[partsKey] === null) {
        continue;
      }
      const object = layout[partsKey][partsKey.replace("Info", "")];
      const url = this.getUrlByModelId(object.id);
      const typeName = this.getTypeNameByModelId(object.id);
      this.currentAssetsModelMap[typeName] = object.id;
      if (layout[partsKey].payload.color !== undefined) {
        this.currentColorsMap[typeName] = layout[partsKey].payload.color;
        this.currentColor = layout[partsKey].payload.color;
      } else {
        this.currentColorsMap[typeName] = DEFAULT_COLOR;
      }
      this.loadModuleToScene(url, typeName, object.id).then((model) => {
        const url = layout[partsKey].payload.imageUrl;
        if (url) {this.setImageToAsset(typeName, url);}
      });
    }
    if (layout.firstFloorInfo !== undefined) {
      let firstFloorLayout = layout.firstFloorInfo.layoutInfo;
      if (firstFloorLayout !== undefined) {
        for (const partsKey of SHOP_PARTS_INFO_MAP.keys()) {
          if (firstFloorLayout[partsKey] === undefined || firstFloorLayout[partsKey] === null) {
            continue;
          }
          const object = firstFloorLayout[partsKey][partsKey.replace("Info", "")];
          const url = this.getUrlByModelId(object.id);
          const typeName = this.getTypeNameByModelId(object.id);
          this.currentAssetsModelMap[typeName] = object.id;
          let color = firstFloorLayout[partsKey].payload.color;
          if (color !== undefined) {
            this.currentColorsMap[typeName] = color;
            if (typeName !== 'Umbrella') this.currentColor = color;
          } else {
            this.currentColorsMap[typeName] = DEFAULT_COLOR;
          }
          this.loadModuleToScene(url, typeName, object.id).then((model) => {
            const url = firstFloorLayout[partsKey].payload.imageUrl;
            if (url) {this.setImageToAsset(typeName, url);}
          });
        }
      }
    }
    return this.currentAssetsModelMap;
  }

  getConfiguration() {
    let result = {};
    for (const mainShopPartKey of Object.keys(MAIN_SHOP_PARTS)) {
      if (mainShopPartKey in ["umbrella", "door"]){
        continue;
      }
      let key = BACKEND_TO_NAMES_MAP.get(mainShopPartKey);
      let assId = this.currentAssetsModelMap[key];
      if (assId === undefined) {
        continue;
      }
      result[SHOP_PARTS_KEY_MAP.get(mainShopPartKey)] = {
        id: assId,
        color: this.currentColorsMap[key],
      };
      if ((mainShopPartKey === "name") || (mainShopPartKey === "floorfirst")) {
        if (this.currentBase64ImageMap[key] !== undefined) {
          result[SHOP_PARTS_KEY_MAP.get(mainShopPartKey)].imageBase64 = this.currentBase64ImageMap[key];
        }
      }
    }
    result[SHOP_PARTS_KEY_MAP.get("floorfirst")]["layout"] = {};
    for (const firstFloorInside of ["umbrella", "door"]) {
      let key = BACKEND_TO_NAMES_MAP.get(firstFloorInside);
      let assId = this.currentAssetsModelMap[key];
      if (assId === undefined) {
        continue;
      }
      if (assId === null ) {
        result[SHOP_PARTS_KEY_MAP.get("floorfirst")]["layout"][firstFloorInside] = null;
      } else {
        result[SHOP_PARTS_KEY_MAP.get("floorfirst")]["layout"][firstFloorInside] = {
          id: assId,
          color: this.currentColorsMap[key],
        };
      }
      if (firstFloorInside === "door") {
        if (this.currentBase64ImageMap[key] !== undefined) {
          result[SHOP_PARTS_KEY_MAP.get("floorfirst")]["layout"][firstFloorInside].imageBase64 = this.currentBase64ImageMap[key];
        }
      }
    }
    return result;
  }

  getModelMap() {
    return this.currentAssetsModelMap;
  }

  getSettingsOfAsset(assetType) {
    let key = BACKEND_TO_NAMES_MAP.get(assetType);
    return {
      id: this.currentAssetsModelMap[assetType],
      color: this.currentColorsMap[key].toLowerCase(),
    };
  }

  loadModuleToScene(url, assetType, inputName) {
    let scene = this.scene;
    let path = url;
    let that = this;

    return new Promise((resolve, reject) => {
      if (url === "null.glb") {
        reject();
      } else {
        const loader = new GLTFLoader();

        loader.load(path, function(glb) {
          let model = glb.scene;
          let objects = [];
          model.traverse(function(object) {
            if (object instanceof THREE.Mesh) {
              objects.push(object);
            }
          });
          for (const object of objects) {
            object.castShadow = true;
            object.userData.assetType = assetType;
            object.userData.inputName = inputName;
            that.assets.push(object);
            let col = that.currentColorsMap[object.userData.assetType];
            object.material.envMapIntensity = 1;
            if (undefined !== col && object.userData.assetType !== 'Umbrella') {
              if ((object.material.name.endsWith("_mat") || (object.material.name.endsWith("_map"))) && !object.material.name.endsWith("img_mat")) {
                object.material.color.set(col);
              }
            }
            if (undefined !== that.currentTextureMap[object.userData.assetType]) {
              if (that.isImageModel(object)) {
                object.material.map = that.currentTextureMap[object.userData.assetType];
              }
            }
            object.material.needsUpdate = true;
            scene.add(object);

          }
          resolve({ model });
        }, undefined, function(error) {
          console.log(error);
          reject();
        });
      }


    });

  }

  setCurrentType(assetType) {
    this.currentAccessoryType = BACKEND_TO_NAMES_MAP.get(assetType);
  }

  setColorToAsset(inputAssetType, color) {
    const assetType = BACKEND_TO_NAMES_MAP.get(inputAssetType);
    for (const obj of this.assets) {
      if (obj.userData.assetType === assetType) {
        if ((obj.material.name.endsWith("_mat") || (obj.material.name.endsWith("_map"))) && !obj.material.name.endsWith("img_mat")) {
          obj.material.color.set(color);
          this.currentColorsMap[assetType] = color;
        }
      }
    }

  }

  setAnotherAsset(assetType, modelId) {
    for (const obj of this.assets) {
      if (obj.userData.assetType === assetType) {
        obj.visible = false;
      }
    }
    this.currentAssetsModelMap[assetType] = modelId;
    let anythingFound = false;
    for (const obj of this.assets) {
      if (obj.userData.inputName === modelId) {
        obj.visible = true;
        anythingFound = true;
      }
    }
    if (anythingFound) {
      return;
    }
    if (modelId === null) {
      return;
    }
    const url = this.getUrlByModelId(modelId);
    this.loadModuleToScene(url, assetType, modelId).then((model) => {
    });
  }

  setImageToAsset(assetType, imgURL) {
    for (const obj of this.assets) {
      if (obj.userData.assetType === assetType) {
        if (this.isImageModel(obj) && imgURL !== undefined) {
          let loader = new THREE.TextureLoader();
          loader.setCrossOrigin("");
          let texture = loader.load(imgURL);
          texture.flipY = false;
          this.currentTextureMap[assetType] = texture;
          obj.material.map = texture;
          obj.material.needsUpdate = true;
        }
      }
    }
  }

  setImageToCurrentAsset(img) {
    let userImageURL = URL.createObjectURL(img);
    this.setImageToAsset(this.currentAccessoryType, userImageURL);
    let reader = new FileReader();
    let that = this;
    reader.onloadend = function() {
      that.currentBase64ImageMap[that.currentAccessoryType] = cleanReaderResult(reader.result);
    };
    reader.readAsDataURL(img);
  }

  isImageModel(obj) {
    return obj.name.endsWith("_img");
  }

  verifyFullness() {
    let result = true;

    BACKEND_TO_NAMES_MAP.forEach((key) => {
      let assId = this.currentAssetsModelMap[key];
      if (!assId && key !== 'Umbrella') {
        result = false;
      }
    });

    return result;
  }
}

export default Shop;
