import styled from "styled-components";
import { Typography } from "@material-ui/core";

const CellComponentText = styled(Typography)`
  color: #222;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default CellComponentText;
