import {IconBlocks} from '../../icons';
import ProductCategoriesPage from "./pages/ProductCategoriesPage";
import CubeIcon from "mdi-material-ui/Cube";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 50,
    path: "/product-categories",
    MainComponent: ProductCategoriesPage,
    IconComponent: IconBlocks,
    navigationItemLabel: 'menu.categories',
    showShopSelector: false,
    godmodeOnly: true,
    collapse: {
      title: 'menu.products',
      IconComponent: CubeIcon, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });

  registerRoute({
    path: "/product-categories/create",
    MainComponent: ProductCategoriesPage,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });

  registerRoute({
    path: "/product-categories/edit/:productCategoryId",
    MainComponent: ProductCategoriesPage,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });
}
