import styled from "styled-components";

const LayoutForm = styled('div')`
  padding: 40px 40px 32px 40px;
  border: 5px solid #eaeaea;
  border-radius: 20px;
  background: #ffffff;
  max-width: 872px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 500px) {
    padding: 18px;
  }
`;

export default LayoutForm;
