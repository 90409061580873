import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useReasonOptions = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      value: 'none',
      name: t('orders.none'),
    },
    {
      value: 'requested_by_customer',
      name: t('orders.customer_request'),
    },
    {
      value: 'duplicate',
      name: t('orders.duplicate_payment'),
    },
    {
      value: 'fraudulent',
      name: t('orders.fraudulent'),
    },
  ], [t]);
};
