import React, { useCallback, useMemo, useState } from "react";
import Toolbar from "../../../../package/src/Toolbar";
import { Grid, makeStyles } from "@material-ui/core";
import Dropdown from "../../../../UI/Dropdown";
import Search from "../../../../UI/Search";
import StyledButton from "../../../../UI/Button";
import DataTable from "../../../../UI/DataTable/DataTable";
import { colors, constants, standardPageStyles } from "../../../../constants";
import Dialog from "../../../../UI/Dialog/Dialog";
import { useSnackbar } from "notistack";
import { useApolloClient } from "@apollo/react-hooks";
import svgReset from '../../../../icons/reset.svg';
import svgDelete from '../../../../icons/delete.svg';
import DialogMessages from "../../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import invitationsQuery from "../graphql/queries/invitations";
import resendEmailMutation from "../graphql/mutations/resendEmailMutation";
import cancelInvitationMutation from "../graphql/mutations/cancelInvitationMutation";
import { useAccountsColumns } from "../hooks/useAccountsColumns";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  ...standardPageStyles,
  redText: {
    color: colors.red,
    fontWeight: 700,
  },
  secondColumn: {
    paddingLeft: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
  },
  secondColumnRtl: {
    paddingRight: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
  },
}));

const AccountInvitations = ({ selectedShopIds: shopIds }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const { columnsInvitations } = useAccountsColumns();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder }) => {
      setIsLoading(true);
      apolloClient.query({
        query: invitationsQuery,
        variables: {
          shopIds,
          first: pageSize,
          //limit: (pageIndex + 1) * pageSize,
          offset: pageIndex * pageSize,
          sortOrder,
          sortBy: 'createdAt',
        },
        fetchPolicy: "network-only",
      })
        .then(res => {
          console.log(res.data.invitations.nodes)
          const total = res.data.invitations.totalCount;
          setTableData(res.data.invitations.nodes);
          setPageCount(Math.ceil(total / pageSize));
          setTotalCount(total);
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setIsLoading(false))
    }, [shopIds, t])

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const mutateResend = (id) => {
    return apolloClient.mutate({
      mutation: resendEmailMutation,
      variables: {
        invitationId: id,
      },
    })
  }

  const mutateCancel = (id) => {
    return apolloClient.mutate({
      mutation: cancelInvitationMutation,
      variables: {
        invitationId: id,
      },
    })
  }

  const rowOptions = useMemo(() => [
    {
      value: constants.OPTIONS.resendEmail,
      label: t('account.resend_email'),
      icon: svgReset,
      handleOptionClick: (rowId) => {
        mutateResend(rowId)
          .then(() => {
            enqueueSnackbar(t('account.resend_email_success'), { variant: "success" });
          })
          .catch((e) => {
            enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
            console.log(e.message);
          })
      },
    },
    {
      value: constants.OPTIONS.cancelInvitation,
      label: t('account.cancel_invitation'),
      icon: svgDelete,
      handleOptionClick: (rowId) => {
        mutateCancel(rowId)
          .then(() => {
            enqueueSnackbar(t('account.cancel_invitation_success'), { variant: "success" });
            refetch();
          })
          .catch((e) => {
            enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
            console.log(e.message);
          })
      },
    },
  ], [t])

  const itemsListActions = useMemo(() => [
    {
      content: t('account.resend_email'),
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('resend');
        setDialogOpen(true);
      },
    },
    {
      content: <span className={classes.redText}>{t('account.cancel_invitations')}</span>,
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('cancel');
        setDialogOpen(true);
      },
    },
  ], [selectedRows, t])

  const dataProps = useMemo(() => tableData.map(item => ({
    id: item._id,
    email: item.email,
    shopName: item.shop.name,
    groupName: item.groups.reduce((allGroups, group) => `${allGroups}, ${group.name}`, "").substring(2),
    invitedBy: item.invitedBy.emailRecords[0]?.address || '',
    options: rowOptions,
  })), [tableData])

  const renderNoButton = useMemo(() =>
      <StyledButton
        width='180px'
        mytype='secondary'
        handleClick={() => setDialogOpen(false)}
      >
        {t('ui.no')}
      </StyledButton>
    , [t])

  const DialogResend = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={t('account.resend_email_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={async () => {
              setDialogOpen(false);
              const successes = [];

              try {
                for (const id of selectedRows) {
                  const { data } = await mutateResend(id);
                  if (data) successes.push(data);
                }
              } catch(e) {
                enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                console.log(e.message);
              }

              if (successes.length) {
                enqueueSnackbar(t('account.resend_emails_success'), { variant: "success" });
              }
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    );
  }, [selectedRows, t]);

  const DialogCancel = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={t('account.cancel_invitations_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={async () => {
              setDialogOpen(false);
              const successes = [];

              try {
                for (const id of selectedRows) {
                  const { data } = await mutateCancel(id);
                  if (data) successes.push(data);
                }
              } catch(e) {
                enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                console.log(e.message);
              }

              if (successes.length) {
                refetch();
                enqueueSnackbar(t('account.cancel_invitations_success'), { variant: "success" });
              }
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    );
  }, [selectedRows, t]);

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'resend': return DialogResend;
      case 'cancel': return DialogCancel;
      default: return null;
    }
  }

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Toolbar title={t('account.invitations')} />

        {
          shopIds.length !== 0 && <>
            <div className={classes.secondRow}>
              <Dropdown
                title={t('ui.actions')}
                itemsList={itemsListActions}
              />
              <Search
                handleChange={handleSearch}
                onSearchClick={refetch}
                placeholder={t('ui.search')}
                useDebounce
              />
            </div>
          </>
        }

        <Grid item sm={12}>
          {shopIds.length === 0 ? (
              <span className={classes.selectSomeShops}>{t('account.lease_shops')}</span>
            ) :
            <DataTable
              columns={columnsInvitations}
              isLoading={isLoading}
              data={dataProps}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              handleFetchData={onFetchData}
              pageCount={pageCount}
              totalCount={totalCount}
              searchValue={searchValue}
              reloadTable={reloadTable}
              defaultSortOrder='desc'

              // styles for 2 rows upper panel + checkboxes
              maxHeight='calc(100vh - 390px)'
              maxHeightLaptop='calc(100vh - 460px)'
              maxHeightMobile='calc(100vh - 440px)'
            />
          }
        </Grid>

      </Grid>

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>

  );
}

export default AccountInvitations;
