import { useMemo } from "react";

export const useTableColumns = () => {
  return useMemo(() => [
    // {
    //   name: 'media',
    //   label: 'Media',
    // },
    {
      name: 'price',
      label: 'Price',
      inputType: 'number',
    },
    {
      name: 'compareAtPrice',
      label: 'Compare At Price',
      inputType: 'number',
    },
    {
      name: 'sku',
      label: 'SKU',
    },
    {
      name: 'barcode',
      label: 'Barcode',
    },
    {
      name: 'weight',
      label: 'Weight',
    },
    {
      name: 'width',
      label: 'Width',
    },
    {
      name: 'length',
      label: 'Length',
    },
    {
      name: 'height',
      label: 'Height',
    },
    {
      name: 'quantity',
      label: 'Quantity',
      inputType: 'number',
    },
  ], []);
};
