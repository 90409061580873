import gql from "graphql-tag";
import { SubscriptionPackage, SubscriptionPackageFull, ConfiguratorParts } from "./fragments";

export const getAllPackages = gql`
query subscriptionPackages($first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $offset: Int, $sortBy: SubscriptionPackageSortByField, $sortOrder: SortOrder, $filter: String, $genericFilters: GenericFilterInput, $filters: SubscriptionPackagesFilters) {
  subscriptionPackages(first: $first, last: $last, before: $before, after: $after, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder, filter: $filter, genericFilters: $genericFilters, filters: $filters) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      ${SubscriptionPackage}
    }
    totalCount
  }
}
`;

export const getPackage = gql`
  query subscriptionPackage($id: ID!) {
    subscriptionPackage(id: $id) {
      ${SubscriptionPackage}
      ${SubscriptionPackageFull}
    }
  }
`;

export const getPackageStreetIds = gql`
  query subscriptionPackage($id: ID!) {
    subscriptionPackage(id: $id) {
      _id
      title
      streetsIds
    }
  }
`;

export const getAvailableStreets = gql`
  query availableStreets($page: Int, $limit: Int = 999999) {
    availableStreets(page: $page, limit: $limit) {
      data {
        id
        name
        categories
        places {
          id
          status
        }
      }
    }
  }
`;

export const types = gql`
  query subscriptionTypes {
    subscriptionTypes {
      nodes {
        _id
        name
      }
    }
  }
`;

export const getSubscriptionPackageConfigurator = gql`
  query subscriptionPackage($id: ID!) {
    subscriptionPackage(id: $id) {
      _id
      title
      configuratorParts {
        ${ConfiguratorParts}
      }
    }
  }
`;

export const getShopCategoriesQuery = gql`
  query getShopCategories(
    $shopCategoryIds: [ID]
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $before: ConnectionCursor
    $after: ConnectionCursor
    $filter: String
  ) {
    getShopCategories(
      shopCategoryIds: $shopCategoryIds
      first: $first
      last: $last
      before: $before
      after: $after
      filter: $filter
    ) {
      nodes {
        _id
        name
        description
      }
    }
  }
`;
