export const COLORS = [
  "#63b79d",
  "#7ab3de",
  "#ffc4ab",
  "#f8e6ff",
  "#5c3748",
  "#e5cdc4"
];

export const sceneBaseSettings = {
  ambientIntensity: 1.0,
  ambientColor: 0xFFFFFF,
  axesHelperSize: 5,
  backgroundColor: 0xffffff,
  cameraFov: 60,
  cameraNear: 0.1,
  cameraFar: 60,
  controlsDampingFactor: 0.05,
  controlsMinDistance: 9,
  controlsMaxDistance: 10,
  controlsMaxPolarAngle: Math.PI / 2,
  directIntensity: 0.8 * Math.PI,
  directColor: 0xFFFFFF,
};
