import { useLazyQuery } from "@apollo/react-hooks";
import { getShop as getShopQuery } from "../graphql/queries/shop.js";

/**
 * React Hook that gets the globally current shop
 * @return {Object} Object with `shop` and `shopId` props
 */
export default function useCurrentShop(shopId) {
  const [getShop, { called, data, loading, refetch }] = useLazyQuery(
    getShopQuery,
    {
      fetchPolicy: "network-only",
    }
  );

  // Wait until we're sure we have a shop ID to call the query
  if (shopId && !called) {
    getShop({
      variables: { id: shopId },
    });
  }

  return {
    isLoadingShop: loading,
    refetchShop: refetch,
    shop: data && data.shop,
    shopId,
  };
}
