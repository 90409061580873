const createMutationInputs = (configObj, configObjClean) => {
  const allowInput = {}; // id variants to add
  const removeInput = {}; // id variants to remove

  // allowInput formation

  for (let module in configObj) {
    const oldArr = configObjClean[module];
    const newArr = configObj[module];

    if (!oldArr) {
      // if the module does not exist in the old version, then add it completely
      allowInput[module] = newArr;
    } else {
      // the module exists in the old version. Take only unique id
      // If is an empty array, then do not add
      const resultArr = newArr.filter(item => !oldArr.includes(item));
      if (resultArr.length) {
        allowInput[module] = resultArr;
      }
    }
  }

  // removeInput formation

  for (let module in configObjClean) {
    const oldArr = configObjClean[module];
    const newArr = configObj[module];

    if (!newArr) {
      // if the module does not exist in the new version, then add it completely
      removeInput[module] = oldArr;
    } else {
      // the module exists in the new version. Take only id's that are missing in the new version
      // If is an empty array, then do not add
      const resultArr = oldArr.filter(item => !newArr.includes(item));
      if (resultArr.length) {
        removeInput[module] = resultArr;
      }
    }
  }

  return {
    allowInput,
    removeInput
  }
}

export default createMutationInputs;
