import gql from "graphql-tag";
import { MerchantRequest } from "./fragments";

export const merchantRequestsQuery = gql`
  query merchantRequests($states: [MerchantRequestState], $first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $offset: Int, $filter: String, $sortBy: MerchantRequestSortByField, $sortOrder: SortOrder, $genericFilters: GenericFilterInput) {
    merchantRequests(states: $states, first: $first, last: $last, before: $before, after: $after, offset: $offset, filter: $filter, sortBy: $sortBy, sortOrder: $sortOrder, genericFilters: $genericFilters) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ${MerchantRequest}
      }
      totalCount
    }
  }
`;
