import React, { useEffect, useState } from "react";
import EditAttribute from "../../product-attributes/components/EditAttribute";
import EditProductType from "./EditProductType";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ProductTypesSchema } from "../schemas";

const EditProductTypeLayout = (props) => {
  const { attributes, loading, setLoading, productType } = props;
  const [isCreatingMode, setIsCreatingMode] = useState(false);
  const [innerAttributes, setInnerAttributes] = useState([]);

  const methods = useForm({
    resolver: yupResolver(ProductTypesSchema),
    defaultValues: {
      name: '',
      attributeIds: [],
    },
  });

  const { getValues, setValue, reset} = methods;

  const addCreatedAttributeToForm = (res) => {
    setInnerAttributes((prev) => [
      ...prev,
      {
        id: res.id,
        name: res.name,
      }
    ]);
    const currentAttributeIds = getValues('attributeIds');
    setValue(
      'attributeIds',
      [
        ...currentAttributeIds,
        res.id,
      ],
      { shouldDirty: true });
  };

  useEffect(() => {
    if (productType) {
      reset({
        name: productType.name,
        attributeIds: productType.variantAttributes?.map((k) => k.attribute.id) || [],
      });
    }
  }, [productType]);

  useEffect(() => {
    if (attributes) {
      setInnerAttributes(attributes.map((item) => ({
        id: item.id,
        name: item.name,
      })));
    }
  }, [attributes]);

  if (isCreatingMode) {
    return <EditAttribute
      loading={loading}
      setLoading={setLoading}
      onClose={(res) => {
        // после создания нового атрибута возвращаться на экран редактирования типа
        // и добавлять только что созданный атрибут в список
        setIsCreatingMode(false);
        if (res && res.id && res.name) {
          addCreatedAttributeToForm(res);
        }
      }}
    />;
  }

  return (
    <FormProvider
      {...methods}
    >
      <EditProductType
        {...props}
        attributes={innerAttributes}
        onCreateAttribute={() => setIsCreatingMode(true)}
      />
    </FormProvider>
  );
};

export default EditProductTypeLayout;