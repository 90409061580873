import React, { useContext, useState } from "react";
import HeadingText from "../common/HeadingText";
import styled from "styled-components";
import HideButton from "./HideButton";
import AttributeRow from "./AttributeRow";
import { ProductVariantsContext } from "../../../../../package/src/context/ProductVariantsContext";
import svgAdd from "../../../../../icons/variantEditor/add.svg";
import AddButtonText from "../common/AddButtonText";
import AddButton from "../common/AddButton";
import AttributeEditForm from "../AttributeEdit/AttributeEditForm";
import Dialog from "../../../../../UI/Dialog";
import DialogMessages from "../../../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../../../UI/Dialog/DialogButtons";
import CustomButton from "../../../../../UI/Button";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../../../UI/Checkbox";
import CheckboxContainer from "../../../../../UI/Checkbox/CheckboxContainer";
import { VariantScrollCss } from "../TableComponents/styles";

const Wrapper = styled('div')`
  padding-right: ${props => props.open ? '24px' : '0'};
  overflow: hidden;
`;

const HeadingBox = styled('div')`
  width: 100%;
  min-width: 200px;
  border-bottom: ${props => props.open ? '1px solid #E9E9E9' : 'none' };
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

const ListBox = styled('div')`
  max-height: calc(90vh - 167px);
  padding-bottom: 16px;
  overflow-y: auto;
  width: 376px;

  @media all and (max-width: 1340px) {
    width: 292px;
  }
  
  ${VariantScrollCss}
`;

const AttributeList = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [addId, setAddId] = useState('');
  const [addValue, setAddValue] = useState('');

  const { t } = useTranslation();

  const {
    toggleHideAttributeList,
    isHideAttributeList: hide,
    onChangeAddMode,
    addMode,
    attributeValues,
    onCreateAttribute,
    saving,
    variantsHaveChanged,
    dontAsk,
    onSetDontAsk,
  } = useContext(ProductVariantsContext);

  const handleAddAttribute = () => {
    onChangeAddMode(true);
  };

  const initAddingAttribute = (id, value) => {
    if (variantsHaveChanged && !dontAsk.addAttribute) {
      setOpenDialog(true);
      setAddId(id);
      setAddValue(value);
    } else {
      onCreateAttribute(id, value);
      onChangeAddMode(false);
    }
  };

  const handleCancelAddingAttribute = () => {
    onChangeAddMode(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmAddingAttribute = () => {
    onCreateAttribute(addId, addValue);
    onChangeAddMode(false);
    handleCloseDialog();
  };

  const handleChangeDontAsk = () => {
    onSetDontAsk('addAttribute', !dontAsk.addAttribute);
  };

  return (
    <Wrapper open={!hide}>
      <HeadingBox open={!hide}>
        {
          !hide && <HeadingText>Attributes</HeadingText>
        }
        <HideButton onClick={toggleHideAttributeList} open={!hide} />
      </HeadingBox>

      {
        !hide &&
        <ListBox>
          {
            attributeValues.map((item) => <AttributeRow
              key={item.id}
              data={item}
            />)
          }
          {
            addMode ?
              <AttributeEditForm
                onConfirm={initAddingAttribute}
                onCancel={handleCancelAddingAttribute}
              /> :
              <AddButton
                disableRipple
                onClick={handleAddAttribute}
                disabled={saving}
              >
                <img src={svgAdd} alt='+' />
                <AddButtonText>Add Attribute</AddButtonText>
              </AddButton>
          }
        </ListBox>
      }

      <Dialog
        open={openDialog}
        handleClose={handleCloseDialog}
      >
        <DialogMessages
          title='If you add an attribute, you will lose all changes that you have in Variants'
          titleMaxWidth='384px'
          titleTextAlign='start'
        />
        <CheckboxContainer margin='16px 0 0'>
          <Checkbox
            checked={dontAsk.addAttribute}
            onChange={handleChangeDontAsk}
          />
          Don't ask me again
        </CheckboxContainer>
        <DialogButtons justifyButtons='center'>
          <CustomButton
            width='166px'
            mytype='secondary'
            handleClick={handleCloseDialog}
          >
            {t('ui.cancel')}
          </CustomButton>
          <CustomButton
            width='166px'
            handleClick={handleConfirmAddingAttribute}
          >
            Add
          </CustomButton>
        </DialogButtons>
      </Dialog>
    </Wrapper>
  );
};

export default AttributeList;
