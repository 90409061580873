import React from "react";
import { useHistory } from "react-router-dom";
import PrimaryAppBar from "../../../package/src/PrimaryAppBar/PrimaryAppBar";

function OrderToolbar({title}) {
  const history = useHistory();

  return (
    <PrimaryAppBar
      title={title}
      onBackButtonClick={() => {
        history.push("/orders");
      }}
    />
  );
}

export default OrderToolbar;
