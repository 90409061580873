import gql from "graphql-tag";
import { Shop, ShopCategory, MerchantRequest } from "./fragments";
import { ShopSubscriptionPackage } from "../../merchants/graphql/fragments";

export const shopsQuery = gql`
  query shopsWithFilters($shopIds: [ID], $first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $offset: Int, $sortOrder: SortOrder, $sortBy: ShopsWithFiltersSortByField, $filter: String, $genericFilters: GenericFilterInput) {
    shopsWithFilters(shopIds: $shopIds, first: $first, last: $last, before: $before, after: $after, offset: $offset, sortOrder: $sortOrder, sortBy: $sortBy, filter: $filter, genericFilters: $genericFilters) {
      pageInfo {
        endCursor
        startCursor
      }
      nodes {
        ${Shop}
        ${ShopSubscriptionPackage}
      }
      totalCount
    }
  }
`;

export const shopQuery = gql`
  query shop($id: ID!) {
    shop(id: $id) {
      ${Shop}
    }
  }
`;

export const shopQueryECommerce = gql`
  query shop($id: ID!) {
    shop(id: $id) {
      _id
      eCommerceEnabled
    }
  }
`;

export const shopWithProductCategoriesQuery = gql`
  query shop($id: ID!) {
    shop(id: $id) {
      _id
      productCategories {
        _id
        name
      }
    }
  }
`;

export const shopWithProductBrandsQuery = gql`
  query shop($id: ID!) {
    shop(id: $id) {
      _id
      productBrands {
        _id
        name
      }
    }
  }
`;

export const shopCategoriesQuery = gql`
  query getShopCategories($shopCategoryIds: [ID], $first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $filter: String, $offset: Int) {
    getShopCategories(shopCategoryIds: $shopCategoryIds, first: $first, last: $last, before: $before, after: $after, filter: $filter, offset: $offset) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ${ShopCategory}
      }
      totalCount
    }
  }
`;

export const pendingMerchantRequestQuery = gql`
  query pendingMerchantRequest($shopId: ID!, $type: MerchantRequestType!) {
    pendingMerchantRequest(shopId: $shopId, type: $type) {
      ${MerchantRequest}
    }
  }
`;

export const allProductCategoriesQuery = gql`
  query productCategories(
    $productCategoryIds: [ID]!
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
  ) {
    productCategories(productCategoryIds: $productCategoryIds, first: $first, last: $last) {
      nodes {
        _id
        name
      }
    }
  }
`;

export const shopSubscriptionOverrides = gql`
  query shopSubscriptionOverrides($id: ID!) {
    shopSubscriptionOverrides(id: $id) {
      rate {
        changePercentage
        startDate
        endDate
        disabled
        notes
      }
      commission {
        newValue
        startDate
        endDate
        disabled
        notes
      }
    }
  }
`;

export const getCompanyWithSubscriptions = gql`
  query company($companyId: ID) {
    company(companyId: $companyId) {
      _id
      name
      subscriptions {
        _id
        status
        subscriptionPackageId
        shopId
        createdAt
      }
    }
  }
`

export const getShopCompanyId = gql`
  query shop($id: ID!) {
    shop(id: $id) {
      _id
      name
      company {
        _id
        name
      }
    }
  }
`

export const queryPackageCategories = gql`
  query subscriptionPackage($id: ID!) {
    subscriptionPackage(id: $id) {
      _id
      title
      categoriesIds
    }
  }
`;
