import React, { useRef, useState } from "react";
import { Tooltip } from "@material-ui/core";
import infoIcon from '../../../../icons/quiz/info.svg';
import PropTypes from "prop-types";
import {
  BigBlueButtonFlexBox,
  BigBlueButtonTooltipPaper,
  bigBlueButtonUseStyles,
  BigBlueButtonWrapper,
} from "./styles";

const BigBlueButton = ({
  IconSvg,
  text,
  selected,
  disabled,
  disabledText,
  onClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const wrapperRef = useRef(null);

  const classes = bigBlueButtonUseStyles();

  const handleOpen = () => {
    setAnchorEl(wrapperRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    if (!disabled) {
      onClick(e);
    }
  };

  return (
    <BigBlueButtonWrapper
      selected={selected}
      isdisabled={String(disabled)}
      onClick={handleClick}
      onMouseLeave={handleClose}
      disableRipple={disabled}
      ref={wrapperRef}
    >
      <BigBlueButtonFlexBox>
        { IconSvg() }
        {text}
        {
          disabled
          && <Tooltip
            open={open}
            placement='bottom'
            PopperProps={{
              anchorEl,
            }}
            classes={{
              popper: classes.popper,
              tooltip: classes.tooltip
            }}
            title={<BigBlueButtonTooltipPaper>
                {disabledText}
              </BigBlueButtonTooltipPaper>
            }
          >
            <img
              src={infoIcon}
              alt=''
              onMouseEnter={handleOpen}
              onTouchStart={handleOpen}
            />
          </Tooltip>
        }
      </BigBlueButtonFlexBox>
    </BigBlueButtonWrapper>
  );
};

BigBlueButton.propTypes = {
  IconSvg: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
  onClick: PropTypes.func,
};

export default BigBlueButton;
