import gql from "graphql-tag";

export const addShopConfig = gql`
  mutation addShopConfig($input: AddShopConfigInput!) {
    addShopConfig(input: $input)
  }
`;

export const updateShopConfig = gql`
  mutation updateShopConfig($input: UpdateShopConfigInput!) {
    updateShopConfig(input: $input) {
    id 
    version
    }
  }
 
`;