import React, { useState } from "react";
import FormCard from "../../../../UI/Form/FormCard";
import EditorTabProjectList from "./EditorTabProjectList";
import EditorTabStart from "./EditorTabStart";
import { useQuery } from "@apollo/react-hooks";
import { shopQuery } from "../../graphql/queries";

const EditorTab = ({ shopId }) => {
  const [isListPage, setIsListPage] = useState(false);
  const [rooms, setRooms] = useState([]);

  const { data } = useQuery(shopQuery, {
    variables: {
      id: shopId,
    }
  });

  const isList = isListPage || rooms.length > 0 || data?.shop?.rooms.data.length > 0;

  return (
    <FormCard>
      {
        isList
          ? <EditorTabProjectList
            shopId={shopId}
            rooms={data?.shop?.rooms.data.length > 0 ? data?.shop?.rooms.data : rooms}
          />
          : <EditorTabStart
            setRooms={setRooms}
            shopId={shopId}
            setIsListPage={setIsListPage}
          />
      }
    </FormCard>
  );
};

export default EditorTab;
