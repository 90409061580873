import gql from "graphql-tag";

export default gql`
  query accountsWithFilters(
    $groupIds: [ID]
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $before: ConnectionCursor
    $after: ConnectionCursor
    $offset: Int
    $sortBy: AccountSortByField
    $sortOrder: SortOrder
    $genericFilters: GenericFilterInput
  ) {
    accountsWithFilters(
      groupIds: $groupIds
      first: $first
      last: $last
      before: $before
      after: $after
      offset: $offset
      sortBy: $sortBy
      sortOrder: $sortOrder
      genericFilters: $genericFilters
    ) {
      nodes {
        _id
        createdAt
        phoneNumber
        emailRecords {
          address
          verified
        }
        disabled
        groups {
          nodes {
            _id
            name
            slug
            shop {
              _id
              name
            }
          }
        }
        name
      }
      totalCount
    }
  }
`;
