import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import FormHeader from "../../../UI/Form/FormHeader";
import { useTranslation } from "react-i18next";
import { colors } from '../../../constants';
import moment from "moment";
import HorizontalRule from "../../../UI/other/HorizontalRule";
import { useReasonOptions } from "../hooks/useReasonOptions";

const StyledLoading = styled.div`
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.black};
`

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  p {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.black};
    margin: .4em;

    span {
      font-weight: 700;
    }
  }
`

const OrderPreviousRefunds = ({order}) => {
  const { t } = useTranslation();
  const isOrder = Boolean(Object.keys(order).length);
  const [refunds, setRefunds] = useState([]);
  const reasonOptions = useReasonOptions();

  const getReasonName = (value) => {
    return reasonOptions.find(item => item.value === value)?.name || undefined;
  }

  useEffect(() => {
    if (isOrder) {
      // sort refunds by date, newest first, for display purposes
      const refunds = order.refunds
        .sort((refundA, refundB) => new Date(refundB.createdAt) - new Date(refundA.createdAt));
      setRefunds(refunds);
    }
  }, [isOrder])

  return (
    <>
      <FormHeader margin='48px 0 24px 0'>
        {t("orders.previous_refunds")}
      </FormHeader>
      {
        !isOrder ?
          <StyledLoading>{t('ui.loading')}...</StyledLoading> :
          <>
            {
              refunds.map((refund, index) => {
                const { amount, createdAt, paymentDisplayName, reason } = refund;
                const formattedDate = moment(createdAt).format("l");

                return <React.Fragment key={index}>
                  <StyledContent>
                    <div>
                      <p>{formattedDate}</p>
                      <p className='refunded'>{t("orders.refunded_to")} <span>{paymentDisplayName}</span></p>
                      <p>{t("orders.reason_for_refund")}: <span>
                          {getReasonName(reason) || t("orders.no_reason_provided")}
                        </span>
                      </p>
                      <br/>
                    </div>
                    <p><span>{amount.displayAmount}</span></p>
                  </StyledContent>

                  {index + 1 < refunds.length && <HorizontalRule/>}
                </React.Fragment>
              })
            }
          </>
      }
    </>
  );
}

export default OrderPreviousRefunds;
