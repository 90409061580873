import React from "react";
import styled from "styled-components";
import { SvgSortDown } from "../../../../../icons";
import { Box } from "@material-ui/core";

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: 4px;
  transition: .2s;
  transform: ${(props) => props.reversed ? 'rotate(180deg)' : 'rotate(0)'};
`;

const SortIconComponent = ({ reversed }) => {
  return (
    <Wrapper reversed={reversed}>
      <SvgSortDown />
    </Wrapper>
  );
};

export default SortIconComponent;
