import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Shop, { BACKEND_TO_NAMES_MAP, SHOP_PARTS, SHOP_PARTS_WITHOUT_UMBRELLA } from "./Shop";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import * as TEST_DATA from "./example.json";
import { sceneBaseSettings } from "../constants";

export class ConfiguratorScene extends THREE.EventDispatcher {
  width;
  height;
  rayCaster;
  pointer;
  renderer;
  camera;
  scene;
  controls;
  permittedModules;

  constructor(canvasRef) {
    super();
    this.permittedModules = {};
    this.width = 800;
    this.height = 600;
    this.renderer = new THREE.WebGLRenderer({ canvas: canvasRef });
    this.pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();
    this.rayCaster = new THREE.Raycaster();
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      sceneBaseSettings.cameraFov,
      this.width / this.height,
      sceneBaseSettings.cameraNear,
      sceneBaseSettings.cameraFar);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    // const hemilight = new THREE.HemisphereLight();
    // hemilight.position.set(0, 50, 0);
    // hemilight.color = new THREE.Color(0.87, 0.77, 0.61);
    // hemilight.groundColor = new THREE.Color(0.058, 0.058, 0.058);
    // hemilight.intensity = 0.57;
    //
    // const sunlight = new THREE.DirectionalLight();
    // sunlight.position.set(0, 10, -12);
    // sunlight.intensity = 0.57;

    this.scene.background = new THREE.Color(sceneBaseSettings.backgroundColor);
    this.axes = new THREE.AxesHelper(sceneBaseSettings.axesHelperSize);

    this.shop = new Shop(this.scene);

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.setClearColor(0xfffff);
    this.renderer.setPixelRatio(2);
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    // this.renderer.toneMappingExposure = 1;

    // this.scene.add(hemilight);
    // this.scene.add(sunlight);
    this.scene.add(this.axes);

    this.axes.visible = false;

    this.setCameraAndControls();

    this.getCubeMapTexture().then((envMap) => {
      this.scene.environment = envMap.envMap;
      this.update();
    });
  }

  getCubeMapTexture() {
    const path = "/temporal_assets/fouriesburg_mountain_cloudy_1k.hdr";

    if (!path) return Promise.resolve({ envMap: null });

    return new Promise((resolve, reject) => {
      new RGBELoader()
        .load(path, (texture) => {

          const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;

          resolve({ envMap });

        }, undefined, reject);

    });

  }

  setCameraAndControls() {
    this.controls.dampingFactor = sceneBaseSettings.controlsDampingFactor;
    this.controls.screenSpacePanning = false;
    this.controls.minDistance = sceneBaseSettings.controlsMinDistance;
    this.controls.maxDistance = sceneBaseSettings.controlsMaxDistance;
    this.controls.minPolarAngle = sceneBaseSettings.controlsMaxPolarAngle;
    this.controls.maxPolarAngle = sceneBaseSettings.controlsMaxPolarAngle + Math.PI / 18;
    this.controls.enablePan = false;
    this.controls.target.set(-0.75, 4.5, 0);
    this.controls.minAzimuthAngle = -Math.PI / 18;
    this.controls.maxAzimuthAngle = Math.PI / 18;
    this.camera.position.set(-0.75, 4.5, 9.5);
  }

  loadConfig(config, permittedModules) {
    return this.shop.loadConfigurationFromBackEnd(config, permittedModules);
  }

  getModelMap() {
    return this.shop.getModelMap();
  }

  getCurrentConfiguration() {
    return this.shop.getConfiguration();
  }

  getSettingsOfAsset(assetType) {
    return this.shop.getSettingsOfAsset(assetType);
  }

  setCurrentAccessoryType(accessoryType) {
    this.shop.setCurrentType(accessoryType);
  }

  setAllAccessoriesColor(color) {
    for (const accessoryType of Object.keys(SHOP_PARTS_WITHOUT_UMBRELLA)) {
      this.shop.setColorToAsset(accessoryType, color);
    }
  }

  setAccessoryModel(accessoryType, modelName, color) {
    const assetType = BACKEND_TO_NAMES_MAP.get(accessoryType);
    this.shop.setAnotherAsset(assetType, modelName);
  }

  setNewImage(img) {
    this.shop.setImageToCurrentAsset(img);
  }

  checkCanSafe() {
    return this.shop.verifyFullness();
  }

  onWindowResize(w, h) {
    let contenedor = document.getElementById("sceneContainerId");
    if (contenedor) {
      this.renderer.setSize(contenedor.clientWidth, contenedor.clientHeight);
      this.camera.aspect = contenedor.clientWidth / contenedor.clientHeight;
      this.camera.updateProjectionMatrix();
    }
  }

  update() {
    this.camera.updateMatrixWorld();
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.update.bind(this));
  }
}
