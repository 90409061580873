import React from 'react';
import PackageFeature from "./PackageFeature";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 58px; // prevent intersection with the 'change plan' button
`

const isEnabledFeature = (text) => {
  for (let i = 0; i < text.length; i++) {
    if (text[i] === '+') {
      return true;
    }
  }

  return false;
}

const getFirstLetterIndex = (text) => {
  for (let i = 0; i < text.length; i++) {
    if (/[\w*]/.test(text[i])) {
      return i;
    }
  }

  return false;
}

const regexSplitter = /(\*.*?\*)/g;

const getDescriptionArray = (rawText) => {
  if (!rawText) {
    return [];
  }

  const result = [];

  rawText.split('[').map(item => {
    // +] *Basic* locations on map
    if (!item) {
      return;
    }
    const isEnabled = isEnabledFeature(item);
    const startIndex = getFirstLetterIndex(item);
    const str = item.substring(startIndex).trimEnd();
    const Text = <p>
      {
        str
          .split(regexSplitter)
          .map((part, index) => {
            if (!part) {
              return null;
            }
            return part.search(regexSplitter) !== -1
              ? (<span key={index}>{part.substring(1, part.length - 1)}</span>)
              : part;
          })
      }
    </p>;

    result.push({
      isDisabled: !isEnabled,
      text: Text,
    });
  });

  return result;
}

const Description = ({ rawText }) => {
  return (
    <Wrapper>
      {
        getDescriptionArray(rawText)
          .sort((a, b) => a.isDisabled - b.isDisabled)
          .map((item, index) => <PackageFeature
            key={index}
            disabled={item.isDisabled}
            text={item.text}
          />)
      }
    </Wrapper>
  );
}

export default Description;
