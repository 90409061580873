import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from 'styled-components';
import FormHeader from "../../../../UI/Form/FormHeader";
import PageStatusMessage from "../../../../UI/other/PageStatusMessage";
import Search from "../../../../UI/Search/Search";
import DataTable from "../../../../UI/DataTable/DataTable";
import { mediaRecordsQuery } from "../../../media-gallery/graphql/queries";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { modifyData } from "../../../media-gallery/modifiers";
import StyledButton from "../../../../UI/Button/Button";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import { useTranslation } from "react-i18next";
import { useMediaColumns } from "../../../media-gallery/hooks/useMediaColumns";
import MediaGalleryUploader from "../../../media-gallery/components/MediaGalleryUploader";
import { Grid, makeStyles } from "@material-ui/core";

const Wrapper = styled('div')`
  max-width: 1000px;
`;

const useStyles = makeStyles(theme => ({
  grid: {
    height: '42px',
    margin: '16px 0 0 0',
  },
}));

const message = "If you can't find your image below, it could be because it has already been linked to another Product or Variant. In that case, you can upload a new one from the Media Gallery."

const MediaGallerySelectorDialog = ({ shopId, onSelect, onLink, existingIds, singleSelect = false }) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const columns = useMediaColumns();

  const [searchValue, setSearchValue] = useState('');
  const [reloadTable, setReloadTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);

  const filteredColumns = columns.filter(column => column.name !== 'options');

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
    setIsLoading(true);
    apolloClient.query({
      query: mediaRecordsQuery,
      variables: {
        shopId,
        filter: globalFilter,
        first: pageSize,
        limit: (pageIndex + 1) * pageSize,
        offset: pageIndex * pageSize,
        sortOrder,
        sortBy,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        // Update the state with the fetched data as an array of objects and the calculated page count
        const total = res.data.mediaRecords.totalCount;
        setTableData(res.data.mediaRecords.nodes);
        setPageCount(Math.ceil(total / pageSize));
        setTotalCount(total);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [shopId]);

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const dataProps = useMemo(() => {
    const filterExistingIds = tableData.filter(item => !existingIds.includes(item._id));
    return modifyData(filterExistingIds);
  }, [tableData, modifyData]);

  const handleSelect = () => {
    if (onLink) {
      onLink(selectedRows);
    } else if (onSelect) {
      onSelect(selectedRows.map((id) => {
        const pic = dataProps.find((k) => k.id === id);
        return {
          id,
          path: pic?.path || '',
        };
      }));
    }
  };

  return (
    <Wrapper>
      <FormHeader>{t('product.select_images')}</FormHeader>
      <PageStatusMessage>{message}</PageStatusMessage>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={12} sm={9}>
          <Search
            handleChange={handleSearch}
            onSearchClick={refetch}
            placeholder={t('ui.search')}
            useDebounce
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MediaGalleryUploader
            shopId={shopId}
            onSuccess={refetch}
          />
        </Grid>
      </Grid>

      <DataTable
        columns={filteredColumns}
        isLoading={isLoading}
        data={dataProps}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        handleFetchData={onFetchData}
        pageCount={pageCount}
        totalCount={totalCount}
        searchValue={searchValue}
        reloadTable={reloadTable}
        defaultSortField='uploadedAt'
        defaultSortOrder='desc'
        maxWidth='unset'
        maxHeight='calc(90vh - 387px)'
        maxHeightLaptop='calc(90vh - 387px)'
        singleSelect={singleSelect}
        showCheckboxes={!singleSelect}
      />

      <DialogButtons justifyButtons='flex-end' marginTop='20px'>
        <StyledButton
          width='180px'
          disabled={isLoading || selectedRows.length === 0}
          handleClick={handleSelect}
        >
          {t('ui.select')}
        </StyledButton>
      </DialogButtons>
    </Wrapper>
  );
}

export default MediaGallerySelectorDialog;
