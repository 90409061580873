import React from 'react';
import {TextField} from '@material-ui/core';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    border-radius: 47px;
    border: none;
    min-height: 42px;
    padding-top: 0;
    padding-bottom: 0;
  }
`

const CustomTextField = (props) => {
  return (
    <StyledTextField 
      {...props}
      variant="outlined" 
    />
  );
}

export default CustomTextField;