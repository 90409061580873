import React, { useState } from "react";
import PropTypes from "prop-types";
import Menu from '../Menu';
import MenuItem from '../../UI/MenuItem';
import styled from 'styled-components';
import MenuButton from "./MenuButton";
import DropdownComplex from "./DropdownComplex";
import MenuFooter from "../MenuItem/MenuFooter";

const StyledDropdown = styled.div`
  min-width: 225px;
  position: relative;
`

const Dropdown = ({title, itemsList = [], variant, styleButton = {}, disabled}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledDropdown>
      <MenuButton
        onClick={handleClick}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        open={open}
        variant={variant}
        style={styleButton}
        disabled={disabled}
      >
        {title ? title : null}
      </MenuButton>

      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        {
          itemsList.map((item, index) => {
            if (item.isComplex) {
              return <DropdownComplex
                key={index}
                setId={index}
                title={item.content}
                itemsList={item.itemsList}
                MenuItemsList={item.MenuItemsList}
              />
            } else if (item.isFooter) {
              return <MenuFooter
                key={index}
                onClick={(e) => {
                  handleClose();
                  item.action(e);
                }}
              >
                {item.content}
                </MenuFooter>
            } else if (!item.action) {
              return <div
                key={index}
                onClick={handleClose}
              >
                {item.content}
              </div>;
            } else {
              return <MenuItem
                onClick={(e) => {
                  handleClose();
                  item.action(e);
                }}
                key={index}
                disabled={item.isDisabled}
              >
                {item.content}
              </MenuItem>
            }
          })
        }
      </Menu>
    </StyledDropdown>
  );
}

Dropdown.propTypes = {
  title: PropTypes.string,
  itemsList: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]).isRequired,
    action: PropTypes.func,
    isDisabled: PropTypes.bool,
    isComplex: PropTypes.bool,
    isFooter: PropTypes.bool,
    itemsList: PropTypes.array,
  })),
  variant: PropTypes.oneOf(['standard', 'blue']),
}

export default Dropdown;
