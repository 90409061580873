import * as JSON_KEYS from "../constants/NameConsts";

export default function getConnectionByPointUuid(connections, uuid) {
  for(let connection of connections){
    if (connection[JSON_KEYS.POINT_UUIDS].includes(uuid)){
      return connection;
    }
  }
  return null;
}
