/**
 * Prepare the attribute value for sending the variant to the server
 * @param attr
 * @returns {string}
 */

const stringifyAttributeValue = (attr) => {
  let value = '';
  switch(attr.inputType) {
    case 'number': {
      const newValue = Number(attr.value);
      value = isNaN(newValue) ? 0 : newValue;
      break;
    }
    case 'colorPicker': {
      value = {
        value: attr.value.hex,
        name: attr.value.name,
      };
      break;
    }
    case 'datePicker': {
      value = attr.value.date;
      break;
    }
    default: {
      value = attr.value;
    }
  }
  return JSON.stringify(value);
};

export default stringifyAttributeValue;
