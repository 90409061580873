import Status from "../../../UI/Status";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

const formatDate = (date) => {
  return moment(date).format("DD.MM.YYYY");
}

const useModifiers = () => {
  const { t } = useTranslation();

  const getType = useCallback((method) => {
    if (method === 'credit') {
      return t('discount.flat');
    }
    if (method === 'discount') {
      return t('discount.percentage');
    }
    return '';
  }, [t]);

  const modifyData = useCallback((data, method, options) => {
    return data.map(item => {
      const isExport = method === 'export';
      const startDate = item.conditions?.order?.startDate;
      const endDate = item.conditions?.order?.endDate;
      const statusType = new Date(startDate) <= new Date() && new Date() <= new Date(endDate)
        ? 'Active'
        : 'Expired';
      const statusText = new Date(startDate) <= new Date() && new Date() <= new Date(endDate)
        ? t('discount.active')
        : t('discount.expired');

      const result = {
        id: item._id,
        code: item.code,
        type: getType(item.calculation.method),
        value: item.discount,
        status: isExport ? statusText : <Status
          content={statusText}
          type={statusType}
        />,
        appliesTo: item.conditions?.products?.length ? t('discount.specific_products') : t('discount.all_products'),
        customerEligibility: item.conditions?.audience?.length ? t('discount.specific_customers') : t('discount.everyone'),
        accountLimit: item.conditions?.accountLimit || '',
        totalLimit: item.conditions?.redemptionLimit || '',
        period: (startDate ? formatDate(startDate) : '[Start date]') + ' - ' + (endDate ? formatDate(endDate) : '[End date]'),
        createdAt: formatDate(item.createdAt),
      }

      // if table
      if (!isExport && options) {
        result.options = options;
        return result;
      }

      // if 'export' button
      result.__typename = item.__typename;
      return result;
    });
  }, [getType]);

  const modifyDataTriggers = useCallback((data, method, options) => {
    return data.map(item => {
      const result = {
        id: item._id,
        code: item.code,
        type: getType(item.calculation.method),
        value: item.discount,
        accountLimit: item.conditions?.accountLimit || '',
        totalLimit: item.conditions?.redemptionLimit || '',
        createdAt: formatDate(item.createdAt),
      }

      // if table
      if (method !== 'export' && options) {
        result.options = options;
        return result;
      }

      // if 'export' button
      result.__typename = item.__typename;
      return result;
    });
  }, [getType]);

  const modifyDataSale = useCallback((data, method, options) => {
    return data.map(item => {
      const isExport = method === 'export';
      const startDate = item.conditions?.order?.startDate;
      const endDate = item.conditions?.order?.endDate;
      const statusType = new Date(startDate) <= new Date() && new Date() <= new Date(endDate)
        ? 'Active'
        : 'Expired';
      const statusText = new Date(startDate) <= new Date() && new Date() <= new Date(endDate)
        ? t('discount.active')
        : t('discount.expired');

      const result = {
        id: item._id,
        name: item.code,
        type: getType(item.calculation.method),
        value: item.discount,
        status: isExport ? statusText : <Status
          content={statusText}
          type={statusType}
        />,
        period: (startDate ? formatDate(startDate) : '[Start date]') + ' - ' + (endDate ? formatDate(endDate) : '[End date]'),
        createdAt: formatDate(item.createdAt),
      }

      // if table
      if (!isExport && options) {
        result.options = options;
        return result;
      }

      // if 'export' button
      result.__typename = item.__typename;
      return result;
    });
  }, [getType]);

  const modifyDataEvent = useCallback((data, method, options) => {
    // todo
    return data;
  }, []);

  return {
    modifyData,
    modifyDataTriggers,
    modifyDataSale,
    modifyDataEvent,
  }
};

export default useModifiers;
