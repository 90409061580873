import React, { useEffect } from "react";
import styled from 'styled-components';
import {colors} from '../../../constants';
import Icon from "../../../UI/Icon";
import {Grid} from '@material-ui/core';
import svgList from '../../../icons/balance-list.svg';
import svgMoney from '../../../icons/balance-money.svg';
import svgBack from '../../../icons/balance-money-back.svg';
import svgShop from '../../../icons/balance-shop.svg';
import { useQuery } from "@apollo/react-hooks";
import { totalBalancesQuery } from "../graphql/queries";
import { formatTotal } from "../utils/formatTotal";
import { useTranslation } from "react-i18next";

const StyledTotalCard = styled(Grid)`
  display: flex;
  gap: 16px;
  padding: 18px 16px 18px 24px;
  background: #FFFFFF;
  box-shadow: 0 7px 20px rgba(183, 195, 211, 0.25);
  border-radius: 20px;
  border: 2px solid white;
  
  &:hover {
    background: #F3F6FF;
    border: 2px solid ${colors.blue};
    
    .icon {
      background: #FFFFFF;
    }
  }
`

const StyledIcon = styled(Icon)`
  &.MuiIcon-root {
    width: 52px;
    height: 52px;
    background: #F3F6FF;
    border-radius: 16px;
    cursor: default;
  }
`

const StyledData = styled.div`
  > p {
    font-family: 'Mulish', sans-serif;
    color: ${colors.black};
    margin: 0;
    word-break: break-all;
  }
  
  .title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 4px;
  }
  
  .content {
    font-weight: 700;
    font-size: 18px;
  }
`;

const Totals = ({ dateRange, companyIds, viewerType, reload }) => {
  const { t } = useTranslation();

  const loadingText =`${t('ui.loading')}...`;

  const { data, isLoading, refetch } = useQuery(totalBalancesQuery, {
    variables: {
      companyIds: companyIds || [],
      fromDate: dateRange?.startDate?.toISOString() || null,
      toDate: dateRange?.endDate?.toISOString() || null,
    },
    skip: !Array.isArray(companyIds) || !companyIds.length,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    try {
      refetch();
    } catch (error) {
      console.log(error);
    }
  }, [reload]);

  return (
    <>
      <Grid container spacing={2}>

        <Grid item xs={12} md={6} lg={3}>
          <StyledTotalCard>
            <StyledIcon className='icon'><img src={svgShop} alt=''/></StyledIcon>
            <StyledData>
              <p className='title'>{t('finance.orders')}</p>
              <p className='content'>{isLoading ? loadingText : formatTotal(data?.totalBalances?.orders, viewerType)}</p>
            </StyledData>
          </StyledTotalCard>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <StyledTotalCard>
            <StyledIcon className='icon'><img src={svgBack} alt=''/></StyledIcon>
            <StyledData>
              <p className='title'>{t('finance.subscriptions')}</p>
              <p className='content'>{isLoading ? loadingText : formatTotal(data?.totalBalances?.subscriptions, viewerType)}</p>
            </StyledData>
          </StyledTotalCard>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <StyledTotalCard>
            <StyledIcon className='icon'><img src={svgList} alt=''/></StyledIcon>
            <StyledData>
              <p className='title'>{t('finance.transactions')}</p>
              <p className='content'>{isLoading ? loadingText : formatTotal(data?.totalBalances?.transactions, viewerType)}</p>
            </StyledData>
          </StyledTotalCard>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <StyledTotalCard>
            <StyledIcon className='icon'><img src={svgMoney} alt=''/></StyledIcon>
            <StyledData>
              <p className='title'>{t('finance.totals')}</p>
              <p className='content'>{isLoading ? loadingText : formatTotal(data?.totalBalances?.total, viewerType)}</p>
            </StyledData>
          </StyledTotalCard>
        </Grid>
      </Grid>
    </>
  );
}

export default Totals;
