import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm, useWatch } from "react-hook-form";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import FormCard from "../../../UI/Form/FormCard";
import { Form } from '../../../UI/Form/MyForm';
import HorizontalRule from "../../../UI/other/HorizontalRule";
import Button from "../../../UI/Button/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import Select from "../../../UI/Select";
import { Grid } from "@material-ui/core";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import CheckboxContainer from "../../../UI/Checkbox/CheckboxContainer";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { SettingsShippingSchema } from "../schemas";
import { State, City }  from 'country-state-city';
import isEmptyObject from "../../../package/src/utils/isEmptyObject";
import { useSnackbar } from "notistack";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../package/src/context/AuthContext";

function isShippingSameAsBilling(data) {
  return data['custom:shippingName'] === data['custom:billingName'] &&
    data['custom:shippingSurname'] === data['custom:billingSurname'] &&
    data['custom:shippingCountry'] === data['custom:billingCountry'] &&
    data['custom:shippingProvince'] === data['custom:billingProvince'] &&
    data['custom:shippingCity'] === data['custom:billingCity'] &&
    data['custom:shippingStreet'] === data['custom:billingStreet'] &&
    data['custom:shippingHouseNumber'] === data['custom:billingHouseNumber'] &&
    data['custom:shippingPostcode'] === data['custom:billingPostcode'];
}

const FormSettingsMerchantShipping = ({ loading, setLoading, countriesList, attributes, refetch }) => {
  const [shippingProvinceList, setShippingProvinceList] = useState([]);
  const [shippingCityList, setShippingCityList] = useState([]);
  const [billingProvinceList, setBillingProvinceList] = useState([]);
  const [billingCityList, setBillingCityList] = useState([]);
  
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const { control, handleSubmit, formState: { isDirty, errors }, setValue, getValues, reset } = useForm({
    resolver: yupResolver(SettingsShippingSchema),
    defaultValues: {
      shippingNickname: '',

      shippingName: '',
      shippingSurname: '',
      shippingCountry: '',
      shippingProvince: '',
      shippingCity: '',
      shippingStreet: '',
      shippingHouseNumber: '',
      shippingPostcode: '',

      billingIsSameAsShipping: true,

      billingName: '',
      billingSurname: '',
      taxNumber: '', // optional
      billingCountry: '',
      billingProvince: '',
      billingCity: '',
      billingStreet: '',
      billingHouseNumber: '',
      billingPostcode: '',
    },
  });

  useEffect(() => {
    if (isEmptyObject(attributes)) {
      return;
    }
    
    reset({
      shippingNickname: attributes['custom:shippingNickname'] || '',
      
      shippingName: attributes['custom:shippingName'] || '',
      shippingSurname: attributes['custom:shippingSurname'] || '',
      shippingCountry: attributes['custom:shippingCountry'] || '',
      shippingProvince: attributes['custom:shippingProvince'] || '',
      shippingCity: attributes['custom:shippingCity'] || '',
      shippingStreet: attributes['custom:shippingStreet'] || '',
      shippingHouseNumber: attributes['custom:shippingHouseNumber'] || '',
      shippingPostcode: attributes['custom:shippingPostcode'] || '',

      billingIsSameAsShipping: isShippingSameAsBilling(attributes),

      billingName: attributes['custom:billingName'] || '',
      billingSurname: attributes['custom:billingSurname'] || '',
      billingCountry: attributes['custom:billingCountry'] || '',
      billingProvince: attributes['custom:billingProvince'] || '',
      billingCity: attributes['custom:billingCity'] || '',
      billingStreet: attributes['custom:billingStreet'] || '',
      billingHouseNumber: attributes['custom:billingHouseNumber'] || '',
      billingPostcode: attributes['custom:billingPostcode'] || '',
      taxNumber: attributes['custom:taxNumber'] || '', // optional
    })
  }, [attributes])

  const watchCheckbox = useWatch({ control, name: "billingIsSameAsShipping" });
  const watchShippingCountry = useWatch({ control, name: "shippingCountry" });
  const watchShippingProvince = useWatch({ control, name: "shippingProvince" });
  const watchBillingCountry = useWatch({ control, name: "billingCountry" });
  const watchBillingProvince = useWatch({ control, name: "billingProvince" });

  useEffect(() => {
    if (watchShippingCountry) {
      const states = State.getStatesOfCountry(watchShippingCountry).map(item => ({
        name: item.name,
        value: item.isoCode,
      }));
      setShippingProvinceList(states);

      const currentProvince = getValues('shippingProvince');
      if (!currentProvince || !states.find(item => item.value === currentProvince)) {
        setValue('shippingProvince', states[0]?.value || '');
      }
    }
  }, [watchShippingCountry])

  useEffect(() => {
    if (watchShippingProvince) {
      const cities = City.getCitiesOfState(watchShippingCountry, watchShippingProvince).map(item => ({
        name: item.name,
        value: item.name,
      }));
      setShippingCityList(cities);

      const currentCity = getValues('shippingCity');
      if (!currentCity || !cities.find(item => item.value === currentCity)) {
        setValue('shippingCity', cities[0]?.value || '');
      }
    }
  }, [watchShippingProvince, watchShippingCountry])

  useEffect(() => {
    if (watchBillingCountry) {
      const states = State.getStatesOfCountry(watchBillingCountry).map(item => ({
        name: item.name,
        value: item.isoCode,
      }));
      setBillingProvinceList(states);

      const currentProvince = getValues('billingProvince');
      if (!currentProvince || !states.find(item => item.value === currentProvince)) {
        setValue('billingProvince', states[0]?.value || '');
      }
    }
  }, [watchBillingCountry])

  useEffect(() => {
    if (watchBillingProvince) {
      const cities = City.getCitiesOfState(watchBillingCountry, watchBillingProvince).map(item => ({
        name: item.name,
        value: item.name,
      }));
      setBillingCityList(cities);

      const currentCity = getValues('billingCity');
      if (!currentCity || !cities.find(item => item.value === currentCity)) {
        setValue('billingCity', cities[0]?.value || '');
      }
    }
  }, [watchBillingProvince, watchBillingCountry])
  
  const onSubmit = useCallback(async (formData) => {
    if (!user) {
      return;
    }

    setLoading(true);

    const {
      shippingNickname, 
      shippingName, 
      shippingSurname, 
      shippingCountry,
      shippingProvince,
      shippingCity,
      shippingStreet,
      shippingHouseNumber,
      shippingPostcode,
      billingIsSameAsShipping,
      billingName,
      billingSurname,
      taxNumber, // optional
      billingCountry,
      billingProvince,
      billingCity,
      billingStreet,
      billingHouseNumber,
      billingPostcode,
    } = formData;
    
    try {
      const res = await Auth.updateUserAttributes(user, {
        'custom:shippingNickname': shippingNickname,
        'custom:shippingName': shippingName,
        'custom:shippingSurname': shippingSurname,
        'custom:shippingCountry': shippingCountry,
        'custom:shippingProvince': shippingProvince,
        'custom:shippingCity': shippingCity,
        'custom:shippingStreet': shippingStreet,
        'custom:shippingHouseNumber': shippingHouseNumber,
        'custom:shippingPostcode': shippingPostcode,
        ...(billingIsSameAsShipping ? {
          'custom:billingName': shippingName,
          'custom:billingSurname': shippingSurname,
          'custom:billingCountry': shippingCountry,
          'custom:billingProvince': shippingProvince,
          'custom:billingCity': shippingCity,
          'custom:billingStreet': shippingStreet,
          'custom:billingHouseNumber': shippingHouseNumber,
          'custom:billingPostcode': shippingPostcode,
        } : {
          'custom:billingName': billingName,
          'custom:billingSurname': billingSurname,
          'custom:billingCountry': billingCountry,
          'custom:billingProvince': billingProvince,
          'custom:billingCity': billingCity,
          'custom:billingStreet': billingStreet,
          'custom:billingHouseNumber': billingHouseNumber,
          'custom:billingPostcode': billingPostcode,
        }),
        'custom:taxNumber': taxNumber,
      });

      if (res) {
        enqueueSnackbar(t('snackbar.update_success'), { variant: "success" });
        refetch();
      } else {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        setLoading(false);
      }
    } catch (e) {
      enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
      console.log(e.message);
      setLoading(false);
    }
  }, [])

  const handleCheckbox = () => {
    setValue('billingIsSameAsShipping', !watchCheckbox);
  }

  const handleDecline = () => {
    reset();
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      width='100%'
    >
      <FormCard>
        <FormHeader>{t('settings.pickup_address')}</FormHeader>
        <InputLabel disabled={loading} error={errors.shippingNickname}>{t('settings.nickname')}</InputLabel>
        <Controller
          name="shippingNickname"
          control={control}
          render={({ field }) => <FormInput
            placeholder={t('settings.write_shipping_name')}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            error={errors.shippingNickname}
          />}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <InputLabel disabled={loading} error={errors.shippingName}>{t('settings.name')}</InputLabel>
            <Controller
              name="shippingName"
              control={control}
              render={({ field }) => <FormInput
                placeholder={t('settings.write_name')}
                value={field.value}
                onChange={field.onChange}
                disabled={loading}
                error={errors.shippingName}
              />}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel disabled={loading} error={errors.shippingSurname}>{t('settings.surname')}</InputLabel>
            <Controller
              name="shippingSurname"
              control={control}
              render={({ field }) => <FormInput
                placeholder={t('settings.write_surname')}
                value={field.value}
                onChange={field.onChange}
                disabled={loading}
                error={errors.shippingSurname}
              />}
            />
          </Grid>
        </Grid>

        <InputLabel disabled={loading} error={errors.shippingCountry}>{t('settings.country')}</InputLabel>
        <Controller
          name="shippingCountry"
          control={control}
          render={({ field }) => <Select
            placeholder={t('settings.choose_country')}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            options={countriesList}
            error={errors.shippingCountry}
          />}
        />

        <InputLabel disabled={loading} error={errors.shippingProvince}>{t('settings.province')}</InputLabel>
        <Controller
          name="shippingProvince"
          control={control}
          render={({ field }) => <Select
            placeholder={t('settings.choose_province')}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            options={shippingProvinceList}
            error={errors.shippingProvince}
          />}
        />

        <InputLabel disabled={loading} error={errors.shippingCity}>{t('settings.city')}</InputLabel>
        <Controller
          name="shippingCity"
          control={control}
          render={({ field }) => <Select
            placeholder={t('settings.choose_city')}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            options={shippingCityList}
            error={errors.shippingCity}
          />}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <InputLabel disabled={loading} error={errors.shippingStreet}>{t('settings.street')}</InputLabel>
            <Controller
              name="shippingStreet"
              control={control}
              render={({ field }) => <FormInput
                placeholder={t('settings.write_street')}
                value={field.value}
                onChange={field.onChange}
                disabled={loading}
                error={errors.shippingStreet}
              />}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel disabled={loading} error={errors.shippingHouseNumber}>{t('settings.house_number')}</InputLabel>
            <Controller
              name="shippingHouseNumber"
              control={control}
              render={({ field }) => <FormInput
                placeholder={t('settings.write_house_number')}
                value={field.value}
                onChange={field.onChange}
                disabled={loading}
                error={errors.shippingHouseNumber}
              />}
            />
          </Grid>
        </Grid>

        <InputLabel disabled={loading} error={errors.shippingPostcode}>{t('settings.postcode')}</InputLabel>
        <Controller
          name="shippingPostcode"
          control={control}
          render={({ field }) => <FormInput
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            error={errors.shippingPostcode}
          />}
        />

        <CheckboxContainer>
          <Checkbox
            checked={watchCheckbox}
            onChange={handleCheckbox}
          />
          {t('settings.billing_same_as_pickup')}
        </CheckboxContainer>

        <HorizontalRule/>

        <div
          style={{
            height: watchCheckbox ? 0 : 'unset',
            overflow: 'hidden',
          }}
        >
          <FormHeader noHorizontalRule margin='0 0 24px 0 !important'>{t('settings.billing_address')}</FormHeader>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <InputLabel disabled={loading} error={errors.billingName}>{t('settings.name')}</InputLabel>
              <Controller
                name="billingName"
                control={control}
                render={({ field }) => <FormInput
                  placeholder={t('settings.write_name')}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={loading}
                  error={errors.billingName}
                />}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputLabel disabled={loading} error={errors.billingSurname}>{t('settings.surname')}</InputLabel>
              <Controller
                name="billingSurname"
                control={control}
                render={({ field }) => <FormInput
                  placeholder={t('settings.write_surname')}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={loading}
                  error={errors.billingSurname}
                />}
              />
            </Grid>
          </Grid>

          <InputLabel disabled={loading} optionalText={t('ui.optional')}>{t('settings.tax_number')}</InputLabel>
          <Controller
            name="taxNumber"
            control={control}
            render={({ field }) => <FormInput
              placeholder={t('settings.write_tax_number')}
              value={field.value}
              onChange={field.onChange}
              disabled={loading}
            />}
          />

          <InputLabel disabled={loading} error={errors.billingCountry}>{t('settings.country')}</InputLabel>
          <Controller
            name="billingCountry"
            control={control}
            render={({ field }) => <Select
              placeholder={t('settings.choose_country')}
              value={field.value}
              onChange={field.onChange}
              disabled={loading}
              options={countriesList}
              error={errors.billingCountry}
            />}
          />

          <InputLabel disabled={loading} error={errors.billingProvince}>{t('settings.province')}</InputLabel>
          <Controller
            name="billingProvince"
            control={control}
            render={({ field }) => <Select
              placeholder={t('settings.choose_province')}
              value={field.value}
              onChange={field.onChange}
              disabled={loading}
              options={billingProvinceList}
              error={errors.billingProvince}
            />}
          />

          <InputLabel disabled={loading} error={errors.billingCity}>{t('settings.city')}</InputLabel>
          <Controller
            name="billingCity"
            control={control}
            render={({ field }) => <Select
              placeholder={t('settings.choose_city')}
              value={field.value}
              onChange={field.onChange}
              disabled={loading}
              options={billingCityList}
              error={errors.billingCity}
            />}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <InputLabel disabled={loading} error={errors.billingStreet}>{t('settings.street')}</InputLabel>
              <Controller
                name="billingStreet"
                control={control}
                render={({ field }) => <FormInput
                  placeholder={t('settings.write_street')}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={loading}
                  error={errors.billingStreet}
                />}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputLabel disabled={loading} error={errors.billingHouseNumber}>{t('settings.house_number')}</InputLabel>
              <Controller
                name="billingHouseNumber"
                control={control}
                render={({ field }) => <FormInput
                  placeholder={t('settings.write_house_number')}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={loading}
                  error={errors.billingHouseNumber}
                />}
              />
            </Grid>
          </Grid>

          <InputLabel disabled={loading} error={errors.billingPostcode}>{t('settings.postcode')}</InputLabel>
          <Controller
            name="billingPostcode"
            control={control}
            render={({ field }) => <FormInput
              value={field.value}
              onChange={field.onChange}
              disabled={loading}
              error={errors.billingPostcode}
            />}
          />
        </div>

        <SaveButtons>
          <Button
            width='50%'
            type='submit'
            disabled={!isDirty || loading}
          >
            {t('ui.save')}
          </Button>
          <Button
            width='50%'
            type='button'
            mytype='third'
            disabled={!isDirty || loading}
            handleClick={handleDecline}
          >
            {t('ui.cancel')}
          </Button>
        </SaveButtons>
      </FormCard>
    </Form>
  );
};

FormSettingsMerchantShipping.propTypes = {
  countriesList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default FormSettingsMerchantShipping;
