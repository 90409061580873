import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import Search from "../../../UI/Search/Search";
import StyledButton from "../../../UI/Button/Button";
import DataTable from "../../../UI/DataTable/DataTable";
import { makeStyles } from "@material-ui/core";
import { transactionsQuery } from "../graphql/queries";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import Dialog from "../../../UI/Dialog/Dialog";
import DialogCreateEditTransaction from "./TransactionsForm";
import useFinancialsColumns from "../hooks/useFinancialsColumns";
import { useTranslation } from "react-i18next";
import useFinancialsModifiers from "../hooks/useFinancialsModifiers";

const useStyles = makeStyles(theme => ({
  searchAndExport: {
    width: '100%',
    display: 'flex',
    gap: 20,
  },
}));

const Transactions = ({dateRange, companyIds, viewer}) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [exporting, setExporting] = useState(false);
  const [rowId, setRowId] = useState('');
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const viewerType = viewer.type;
  const isMerchant = viewerType !== "godmode";
  const { getTransactionColumns } = useFinancialsColumns();
  const { modifyTransData } = useFinancialsModifiers();

  const refetch = () => {
    setReload(prev => !prev);
  }

  useEffect(() => {
    refetch();
  }, [dateRange, companyIds, viewerType])

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
    setIsLoading(true);

    if (!companyIds.length) {
      setTableData([]);
      setPageCount(1);
      setTotalCount(1);
      setIsLoading(false);
      return;
    }

    apolloClient.query({
      query: transactionsQuery,
      variables: {
        companyIds,
        filter: globalFilter,
        first: pageSize,
        //limit: (pageIndex + 1) * pageSize,
        offset: pageIndex * pageSize,
        sortOrder,
        sortBy,
        fromDate: dateRange?.startDate?.toISOString() || null,
        toDate: dateRange?.endDate?.toISOString() || null,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        // Update the state with the fetched data as an array of objects and the calculated page count
        const total = res.data.transactionsBetweenMerchantAndGodmode.totalCount;
        setTableData(res.data.transactionsBetweenMerchantAndGodmode.nodes);
        setPageCount(Math.ceil(total / pageSize));
        setTotalCount(total);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [reload, t]);

  const getColumns = useMemo(() => {
    return getTransactionColumns(isMerchant);
  }, [isMerchant, getTransactionColumns])

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: transactionsQuery,
      variables: {
        companyIds,
        fromDate: dateRange?.startDate?.toISOString() || null,
        toDate: dateRange?.endDate?.toISOString() || null,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyTransData(res.data.transactionsBetweenMerchantAndGodmode.nodes, 'export', null);
        const fields = getColumns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [dateRange, companyIds, t, getColumns, modifyTransData])

  const rowOptions = useMemo(() => ({
      buttonType: 'edit',
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('edit');
        setDialogOpen(true);
      },
    }), [])

  const dataProps = useMemo(() => {
    return modifyTransData(tableData, '', rowOptions);
  }, [tableData]);

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const handleCreateTransaction = () => {
    setDialogName('create');
    setDialogOpen(true);
  }

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'create':
      case 'edit': return <DialogCreateEditTransaction
        transactionId={rowId}
        declineAction={() => setDialogOpen(false)}
        page={dialogName}
        refetch={refetch}
        viewer={viewer}
      />;
      default: return null;
    }
  }

  return (
    <>
      <div className={classes.searchAndExport}>
        <Search
          handleChange={handleSearch}
          onSearchClick={refetch}
          placeholder={t('ui.search')}
          useDebounce
          minWidth='270px'
        />
        <StyledButton
          width='140px'
          mytype='secondary'
          handleClick={exportData}
          disabled={exporting || !tableData.length}
        >
          {t('ui.export')}
        </StyledButton>
        <StyledButton
          width='220px'
          handleClick={handleCreateTransaction}
        >
          {t('finance.create_transaction')}
        </StyledButton>
      </div>

      <DataTable
        columns={getColumns}
        isLoading={isLoading}
        data={dataProps}
        handleFetchData={onFetchData}
        pageCount={pageCount}
        totalCount={totalCount}
        searchValue={searchValue}
        reloadTable={reload}
        defaultSortField='createdAt'
        defaultSortOrder='desc'
        showCheckboxes={false}

        // styles for 1 row upper panel
        maxHeight='calc(100vh - 280px)'
        maxHeightLaptop='calc(100vh - 380px)'
        maxHeightMobile='calc(100vh - 360px)'
      />

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>
  );
}

export default Transactions;
