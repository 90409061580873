import React from "react";
import styled from "styled-components";
import selectedSvg from '../../icons/tabSelected.svg';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const DisabledCircle = styled('div')`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #EFF1F6;
`;

const CustomTabLabel = ({ disabled, children }) => {
  return (
    <Wrapper>
      {
        disabled
        ? <DisabledCircle />
        : <img src={selectedSvg} alt='' />
      }
      { children }
    </Wrapper>
  );
};

export default CustomTabLabel;