import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled('div')`
  cursor: pointer;
  
  span {
    color: #BFBFBF;
    font-weight: 600;
  }
`;

const HideValueComponent = ({ value }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  if (value.difference > 0) {
    return (
      <Wrapper
        onClick={handleClick}
      >
        {
          open ?
            value.full
            : <>
              {value.short}
              <span> +{value.difference}</span>
            </>
        }
      </Wrapper>
    )
  }

  return value.full;
};

export default HideValueComponent;