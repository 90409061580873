import React from 'react';
import {MenuItem, makeStyles} from '@material-ui/core';
import {colors} from '../../constants';

const useStyles = makeStyles(() => ({
  root: {
    background: 'white',
    fontFamily: 'Mulish',
    fontWeight: 700,
    fontSize: 15,
    color: colors.blue,
    borderTop: '1px solid #E9E9E9',
    padding: '16px 12px 12px 22px',

    '&:hover': {
      background: colors.white8,
    },
  }
}))

const MenuFooter = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <MenuItem
      className={classes.root}
      {...props}
      ref={ref}
    >
      {props.children}
    </MenuItem>
  );
})

export default MenuFooter;
