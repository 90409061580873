import React, { useContext, useEffect } from "react";
import LayoutContent from "../layouts/LayoutContent";
import LayoutForm from "../layouts/LayoutForm";
import styled from "styled-components";
import { FormHeaderText } from "../styles";
import CustomButton from "../../../../UI/Button";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const PageVerified = () => {
  const { t } = useTranslation("reg");

  const { onSetPage, onSetTypedEmail } = useContext(AuthContext);

  useEffect(() => {
    onSetTypedEmail("");
  }, []);

  const handleConfirm = () => {
    onSetPage("login");
  };

  return (
    <LayoutContent>
      <LayoutForm>
        <Wrapper>
          <img src="/auth/verified.svg" alt="" />
          <FormHeaderText> {t("auth_verified")}</FormHeaderText>
          <DialogButtons marginTop="0" justifyButtons="center">
            <CustomButton width="170px" handleClick={handleConfirm}>
              {t("got_it")}
            </CustomButton>
          </DialogButtons>
        </Wrapper>
      </LayoutForm>
    </LayoutContent>
  );
};

export default PageVerified;
