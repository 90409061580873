import React from "react";
import styled from "styled-components";
import { IconButton, Typography } from "@material-ui/core";
import svgClose from '../../../icons/close-popover.svg';
import FormInput from "../../../UI/Form/FormInput";
import ColorCircle from "./ColorCircle";
import { HexColorPicker } from "react-colorful";

const HeaderBox = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E9E9E9;
  padding-bottom: 3px;
  margin-bottom: 12px;
`;

const HeadingText = styled(Typography)`
  color: #222;
  font-family: Fredoka, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`;

const CloseButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  
  &:hover {
    background: none;
  }
`;

const AddedColors = styled(Typography)`
  color: #BFBFBF;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const ColorsWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
`;

const StyledHexColorPicker = styled(HexColorPicker)`
  width: 100% !important;
  height: auto !important;
  margin-bottom: 12px;
  gap: 12px;
  
  .react-colorful__saturation {
    border-radius: 8px;
    height: 120px;
    flex-grow: 0;
    
    //.react-colorful__interactive {
    //  height: 112px;
    //}
  }
  
  .react-colorful__hue {
    height: 40px;
    border-radius: 20px;

    .react-colorful__pointer {
      width: 40px;
      height: 40px;
    }

    .react-colorful__interactive {
      width: calc(100% - 36px);
      margin-left: 18px;
    }
  }
`;

const PopoverContent = ({ onClose, addedColors, innerColor, setInnerColor }) => {
  const handleChange = (val) => {
    setInnerColor(val);
  };

  return (
    <>
      <HeaderBox>
        <HeadingText>Color</HeadingText>
        <CloseButton
          disableRipple
          onClick={onClose}
        >
          <img src={svgClose} alt='x' />
        </CloseButton>
      </HeaderBox>
      <StyledHexColorPicker color={innerColor} onChange={handleChange} />
      <FormInput
        value={innerColor}
        onChange={(e) => handleChange(e.target.value)}
        placeholder='HEX'
      />
      {
        addedColors.length ?
          <>
            <AddedColors>Added Colors</AddedColors>
            <ColorsWrap>
              {
                addedColors.map((item) => <ColorCircle
                  key={item.id}
                  hex={item.hex}
                />)
              }
            </ColorsWrap>
          </> : null
      }
    </>
  );
};

export default PopoverContent;
