import React from 'react';
import PropTypes from "prop-types";
import styled, {css} from 'styled-components';
import {colors} from '../../constants';
import {SvgChevronDown} from '../../icons';

const blueCss = css`
  background: ${props => props.open ? 'linear-gradient(180deg, #4874FF 0%, #3461EE 100%)' : colors.blueGradient};
  color: white;
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;

  & .MuiSvgIcon-root {
    path {
      fill: white;
    }
  }
`

const StyledButton = styled.button`
  outline: none;
  border: none;
  width: 100%;
  background: white;
  height: 42px;
  box-shadow: 0px 7px 20px rgba(183, 195, 211, 0.25);
  border-radius: 47px;
  cursor: ${props => props.disabled ? 'default': 'pointer'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.open ? colors.black : colors.textGrey};
  transition: .2s;
  user-select: none;

  & .MuiSvgIcon-root {
    width: 14px;
    transition: .1s;
    transform: ${props => props.open ? 'rotate(180deg)' : 'rotate(0)'};

    path {
      fill: ${props => props.open ? colors.black : colors.textGrey};
      transition: .2s;
    }
  }
  
  ${props => props.variant === 'blue' && blueCss}
`

const MenuButton = ({children, ...other}) => {

  return (
    <StyledButton
      {...other}
    >
      {children}
      <SvgChevronDown/>
    </StyledButton>
  );
}

MenuButton.propTypes = {
  onClick: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'blue']),
}

export default MenuButton;
