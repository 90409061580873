import * as THREE from "three";

export const STREET_VIEW_TYPE = "streets";
export const HOUSES_VIEW_TYPE = "houses";

export const mapSceneBaseSettings = {
  ambientIntensity: 1.0,
  ambientColor: 0xFFFFFF,
  axesHelperSize: 5,
  backgroundColor: 0xffffff,
  cameraFov: 60,
  cameraNear: 0.1,
  cameraFar: 60,
  controlsDampingFactor: 0.05,
  controlsMinDistance: 9,
  controlsMaxDistance: 10,
  controlsMaxPolarAngle: Math.PI / 2,
  directIntensity: 0.8 * Math.PI,
  directColor: 0xFFFFFF,
  maxZoom: 1.5,
  minZoom: 0.5,
};

export const STREET_WIDTH = 1;
export const STREET_HEIGHT = 0.228;

export const HOUSE_HEIGHT = 1.04;
export const HOUSE_WIDTH = 1.00;
export const MARGIN = 0.12;

export const WAY_ADDITION_HEIGHT = 0.04;
export const WAY_ADDITION_WIDTH = 0.24;
export const WAY_OFFSET = 0.13;

export const DEFAULT_STREET_HOUSE_COUNT = 20;

export const HOUSE_FREE = "HOUSE-IS-FREE";
export const HOUSE_TAKEN = "HOUSE-IS-TAKEN";

export const BASE_HOUSE_MATERIAL_COLOR = 0xffffff;
export const HOUSE_FREE_COLOR = 0x4270FF;
export const WAY_ADDITION_COLOR = 0xCECECE;

export const MODULES_API_PATH = "/temporal_assets/";

export const TURNS = ["l", "r", "f"];
export const LINKS = ["startLinks", "endLinks"];

let CURRENT_HOLDER_TEXTURE = new THREE.TextureLoader().load(`${MODULES_API_PATH}currentHolder.png`);
let FREE_HOUSE_TEXTURE = new THREE.TextureLoader().load(`${MODULES_API_PATH}freeHouseTexture.png`);
let TAKEN_HOUSE_TEXTURE = new THREE.TextureLoader().load(`${MODULES_API_PATH}taken.png`);
let SELECTED_HOUSE_TEXTURE = new THREE.TextureLoader().load(`${MODULES_API_PATH}selectedHouseTexture.png`);
let UPGRADE_HOUSE_TEXTURE_TOP = new THREE.TextureLoader().load(`${MODULES_API_PATH}upgradeHouseTop.png`);
let UPGRADE_HOUSE_TEXTURE_BOTTOM = new THREE.TextureLoader().load(`${MODULES_API_PATH}upgradeHouseBottom.png`);
let way_texture = new THREE.TextureLoader().load(MODULES_API_PATH + "mainHighwayTexture.png");

export const WAY_MATERIAL = new THREE.MeshBasicMaterial({ map: way_texture });
export const CURRENT_HOLDER_MATERIAL = new THREE.MeshBasicMaterial({map: CURRENT_HOLDER_TEXTURE });
export const SELECTED_HOUSE_MATERIAL = new THREE.MeshBasicMaterial({map: SELECTED_HOUSE_TEXTURE });
export const FREE_HOUSE_MATERIAL = new THREE.MeshBasicMaterial({map: FREE_HOUSE_TEXTURE });
export const TAKEN_MATERIAL = new THREE.MeshBasicMaterial({map: TAKEN_HOUSE_TEXTURE });
export const UPGRADE_HOUSE_MATERIAL_TOP = new THREE.MeshBasicMaterial({map: UPGRADE_HOUSE_TEXTURE_TOP });
export const UPGRADE_HOUSE_MATERIAL_BOTTOM = new THREE.MeshBasicMaterial({map: UPGRADE_HOUSE_TEXTURE_BOTTOM });
