import HistoryIcon from "@material-ui/icons/History";
import ActivityHistoryPage from "./pages/ActivityHistoryPage";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 90,
    path: "/activity-history",
    MainComponent: ActivityHistoryPage,
    IconComponent: HistoryIcon,
    navigationItemLabel: 'menu.user_activity_history',
    showShopSelector: false,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });

  registerRoute({
    path: "/activity-history/create",
    MainComponent: ActivityHistoryPage,
    showShopSelector: false,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });

  registerRoute({
    path: "/activity-history/edit/:shopCategoryId",
    MainComponent: ActivityHistoryPage,
    showShopSelector: false,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });
}
