import React, { useContext } from "react";
import InputLabel from "../../../../UI/Form/InputLabel";
import PackageCard from "../packages/PackageCard";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ShopEditContext } from "../../../../package/src/context/ShopEditContext";
import BillingList from "./components/BillingList";
import PaymentMethodSelector from "../payment/components/PaymentMethodSelector";
import FormCard from "../../../../UI/Form/FormCard";
import PaymentStatusPage from "./components/PaymentStatusPage";

const Content = styled.div`
  display: flex;
  gap: 40px;
`;

const PaymentTab = ({ companyId, shopId }) => {
  const { t } = useTranslation();

  const {
    packages,
    selectedPackageId,
    selectedPaymentMethod,
    processing,
    paymentError,
    paymentStatus,
  } = useContext(ShopEditContext);

  const packageData = packages.find(item => item.id === selectedPackageId);

  const showStatus =  paymentStatus === 'pending' || paymentStatus === 'open' || processing || paymentError;

  return (
    <FormCard>
      <Content>
        {
          showStatus
          ? <PaymentStatusPage /> :
            <div>
              <PaymentMethodSelector />
              {
                selectedPaymentMethod === 'Cart' ? <BillingList
                  companyId={companyId}
                  shopId={shopId}
                  isFreePackage={packageData?.baseRate === 0}
                /> : null
              }
            </div>
        }
        {
          packageData ?
            <div>
              <InputLabel>{t('subscription.selected_plan')}</InputLabel>
              <PackageCard
                data={packageData}
                isDetailed
                isActive
              />
            </div>
            : null
        }
      </Content>
    </FormCard>
  );
};

export default PaymentTab;
