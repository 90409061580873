import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {MenuItem, Icon} from '@material-ui/core';
import styled, { css } from "styled-components";
import {colors} from '../../constants';
import IconButtons from '../IconButtons';
import Menu from '../Menu';
import { LanguageContext } from "../../package/src/context/LanguageContext";

const StyledRowOptions = styled.div`
  cursor: pointer;

  .MuiInputBase-root {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;

    &.menuOption {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
`

const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-root {
    height: 42px;
    background: white;
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: ${colors.black};

    &:hover {
      background: ${colors.white8};
    }
  }

  .MuiIcon-root {
    height: 22px;
    margin-right: 16px;
    display: flex;
  }
  
  ${props => props.isrtl === 'true' && css`
    .MuiIcon-root {
      margin-right: 0;
      margin-left: 16px;
    }
  `}
`

const RowOptions = ({optionsComponents, rowId}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { isRtl } = useContext(LanguageContext);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (item) => {
    if (item.disabled) {
      return;
    }
    item.handleOptionClick(rowId);
    handleClose();
  }

  return (
    <StyledRowOptions>
      <IconButtons 
        type='dots'
        handleClick={handleOpen}
      />
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        {
          optionsComponents.map(item => <StyledMenuItem
            value={item.value}
            key={item.value}
            onClick={() => handleClick(item)}
            disabled={item.disabled}
            isrtl={String(isRtl)}
          >
            <Icon><img src={item.icon} alt=''/></Icon>
            {item.label}
          </StyledMenuItem>)
        }
      </Menu>
    </StyledRowOptions>
  );
}

RowOptions.propTypes = {
  optionsComponents: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    handleOptionClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
  })),
  rowId: PropTypes.string.isRequired,
}

export default RowOptions;
