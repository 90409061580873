import gql from "graphql-tag";

export const CREATE_TAG = gql`
  mutation addTagMutation($input: AddTagInput!) {
    addTag(input: $input) {
      tag {
        _id
        createdAt
        displayTitle
        position
        name
        slug
        isVisible
        heroMediaUrl
        metafields {
          description
          key
          namespace
          scope
          value
          valueType
        }
      }
    }
  }
`;