import styled from "styled-components";
import { Typography } from "@material-ui/core";

const AddButtonText = styled(Typography)`
  color: #4270FF;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-weight: 700;
`;

export default AddButtonText;
