import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants';

const Wrapper = styled.p`
  width: 100%;
  margin: 18px 0 32px 0;
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.black};
`

const FormText = ({ children, ...other}) => {
  return (
    <Wrapper {...other}>
      {children}
    </Wrapper>
  );
}

export default FormText;
