import React, { useEffect, useState } from "react";
import FormDetails from "../../../UI/Form/FormDetails";
import { IconButton } from "@material-ui/core";
import svgEdit from "../../../icons/table-edit.svg";
import FormInput from "../../../UI/Form/FormInput";
import SaveButtonsSimple from "../../../UI/Button/SaveButtonsSimple";
import { useTranslation } from "react-i18next";

const OrderItemSingle = ({ data, saveQuantity, isLastItem, isLoading }) => {
  const [editMode, setEditMode] = useState(false);
  const [qty, setQty] = useState(1);
  const { t } = useTranslation();

  const { price, productVendor, quantity, subtotal, title, variantTitle } = data;

  useEffect(() => {
    if (quantity !== null && quantity !== undefined) {
      setQty(quantity);
    }
  }, [])

  const handleCancel = () => {
    setQty(quantity);
    setEditMode(false);
  }

  const handleSave = () => {
    setEditMode(false);
    const quantityNew = Number(qty);
    saveQuantity({
      quantityNew,
      itemId: data._id,
      error: quantityNew >= quantity,
    })
  }

  return (
    <>
      <FormDetails>
        <p className='title'>{title}</p>
        <p className='title'>{price.displayAmount}</p>
      </FormDetails>

      <FormDetails isContent>
        <div className='check'>
          <p>{t('orders.vendor')}: {productVendor}</p>
          <p>{t('orders.variant')}: {variantTitle}</p>
          <p>{t('orders.quantity')}: {quantity}</p>
        </div>
        <p className='title title--margin4'>{t('orders.total')}: {subtotal.displayAmount}</p>
      </FormDetails>

      <FormDetails>
        <p className='title'>{t('orders.quantity')}</p>
        {
          editMode ? <SaveButtonsSimple
              onSaveClick={handleSave}
              onCancelClick={handleCancel}
              disabled={isLoading}
            /> :
            <IconButton
              onClick={() => setEditMode(true)}
            ><img src={svgEdit} alt=''/></IconButton>
        }
      </FormDetails>

      <FormInput
        disabled={!editMode || isLoading}
        value={qty}
        onChange={(e) => setQty(e.target.value)}
        type='number'
        style={{ marginBottom: isLastItem ? 0 : '24px' }}
      />
    </>
  )
}

export default OrderItemSingle;
