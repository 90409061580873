import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import propTypes from "prop-types";
import svgChecked from '../../../../../icons/tabChecked.svg';

const Wrapper = styled.div`
  display: flex;
  width: 279px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: #F6F6F6;
  position: relative;
  cursor: pointer;
  
  &:hover {
    background: #E9EBF0;
  }
`;

const MethodText = styled(Typography)`
  color: #222;
  text-align: center;
  font-family: Fredoka, sans-serif;
  font-size: 16px;
  font-weight: 500;
`;

const SelectedImg = styled.img`
  position: absolute;
  top: -8px;
  right: -8px;
`;

const PaymentMethodCell = ({ selected, onClick, picSrc, text }) => {
  return (
    <Wrapper
      onClick={onClick}
    >
      <img src={picSrc} alt='' />
      {
        text ? <MethodText>{text}</MethodText> : null
      }
      {
        selected && <SelectedImg src={svgChecked} alt='' />
      }
    </Wrapper>
  );
};

PaymentMethodCell.propTypes = {
  selected: propTypes.bool.isRequired,
  onClick: propTypes.func.isRequired,
  picSrc: propTypes.string.isRequired,
  text: propTypes.string,
}

export default PaymentMethodCell;
