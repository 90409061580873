import React, { forwardRef } from "react";
import CustomButton from "../../../../UI/Button";
import svgHelp from "../../../../icons/help-filled.svg";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { StyledIconButton } from "./StyledIconButton";

const Paper = styled.div`
  background: #FFF;
  display: flex;
  width: 268px;
  height: 184px;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  box-shadow: 0 7px 20px 0 rgba(183, 195, 211, 0.25);
  position: relative;
`;

const UpgradeText = styled(Typography)`
  color: #222;
  font-family: Mulish, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const ChooseBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  gap: 12px;
`;

const ChooseText = styled(Typography)`
  color: #9A9A9A;
  font-family: Mulish, sans-serif;
  font-size: 12px;
  font-weight: 700;
`;

const CardUpgrade = ({ onUpgrade, onChooseSub }, ref) => {
  return (
    <Paper ref={ref}>
      <UpgradeText>Upgrade your subscription<br />for this option</UpgradeText>
      <CustomButton
        width='100%'
        style={{
          height: '40px',
        }}
        onClick={onUpgrade}
      >
        Upgrade
      </CustomButton>
      <ChooseBox>
        <ChooseText>What subscription to choose?</ChooseText>
      </ChooseBox>
      <StyledIconButton
        onClick={onChooseSub}
      >
        <img src={svgHelp} alt='?'/>
      </StyledIconButton>
    </Paper>
  );
};

export default forwardRef(CardUpgrade);
