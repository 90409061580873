import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ColorCircle = styled('div')`
  width: 12px;
  height: 12px;
  border-radius: 6px;
`;

const ColorName = styled(Typography)`
  color: #222;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

const CellComponentColor = ({ hex, name }) => {
  return (
    <Wrapper>
      <ColorCircle style={{ background: hex }}/>
      <ColorName>{name}</ColorName>
    </Wrapper>
  );
};

export default CellComponentColor;
