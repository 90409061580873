import { styled, Typography } from "@mui/material";
import LanguageSwitch from "../../LanguageSwitch";
import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

const Wrapper = styled("div")`
  margin: 43px auto;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    margin: 26px 0;
  }
`;

const Container = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  max-width: 872px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Text = styled(Typography)`
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const LanguageBox = styled("div")`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const LanguageText = styled(Typography)`
  line-height: 1.5;
  color: rgb(154, 154, 154);
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

const date = new Date();

const WosqFooter = () => {
  const { t } = useTranslation("reg");

  const { page } = useContext(AuthContext);

  if (!page) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <LanguageBox>
          <LanguageText>{t("change_language")}:</LanguageText>
          <LanguageSwitch />
        </LanguageBox>
        <Text>Copyright &copy; {date.getFullYear()} WONDERSOUQ, all right reserved</Text>
      </Container>
    </Wrapper>
  );
};

export default WosqFooter;
