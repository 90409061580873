import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useShopCategoriesColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'name',
      label: t('shops.category_name'),
      isBold: true,
    },
    {
      name: 'description',
      label: t('shops.description'),
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    {
      name: 'options'
    }
  ], [t]);
};
