import React, { useCallback, useContext, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { makeStyles, Grid, Hidden } from "@material-ui/core";
import { constants, standardPageStyles } from "../../../constants";
import Search from "../../../UI/Search/Search";
import StyledButton from "../../../UI/Button";
import IconButtons from "../../../UI/IconButtons";
import DataTable from "../../../UI/DataTable/DataTable";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import svgEdit from "../../../icons/table-edit.svg";
import Dialog from "../../../UI/Dialog/Dialog";
import svgDelete from "../../../icons/delete.svg";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import couponTriggersQuery from "../graphql/queries/couponTriggers";
import removeCouponTrigger from "../graphql/mutations/removeCouponTrigger";
import DialogEditCreateTrigger from '../components/CouponTriggerForm';
import { LanguageContext } from "../../../package/src/context/LanguageContext";
import { useDiscountsColumns } from "../hooks/useDiscountsColumns";
import { useTranslation } from "react-i18next";
import useModifiers from "../hooks/useModifiers";

const useStyles = makeStyles(theme => ({
  ...standardPageStyles,
  deleteAndAdd: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  }
}));

/**
 * @summary Renders discounts page
 * @param {Object} props Component props
 * @return {React.Node} React node
 */
const CouponTriggersPage = ({selectedShopIds: shopIds}) => {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { triggersColumns: columns } = useDiscountsColumns();
  const { isRtl } = useContext(LanguageContext);
  const rtl = isRtl ? 'Rtl' : '';
  const { t } = useTranslation();
  const { modifyDataTriggers } = useModifiers();

  const [searchValue, setSearchValue] = useState('');
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [rowId, setRowId] = useState('');

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
    setIsLoading(true);
    apolloClient.query({
      query: couponTriggersQuery,
      variables: {
        shopIds,
        filter: globalFilter,
        first: pageSize,
        limit: (pageIndex + 1) * pageSize,
        offset: pageIndex * pageSize,
        sortOrder,
        sortBy,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        // Update the state with the fetched data as an array of objects and the calculated page count
        const total = res.data.couponTriggers.totalCount;
        setTableData(res.data.couponTriggers.nodes);
        setPageCount(Math.ceil(total / pageSize));
        setTotalCount(total);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [shopIds, t]);

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: couponTriggersQuery,
      variables: {
        shopIds,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyDataTriggers(res.data.couponTriggers.nodes, 'export', null);
        const fields = columns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [shopIds, t]);

  const rowOptions = useMemo(() => ([
    {
      label: t('discount.edit_trigger'),
      value: constants.OPTIONS.editTrigger,
      icon: svgEdit,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('edit');
        setDialogOpen(true);
      }
    },
    {
      label: t('discount.delete_trigger'),
      value: constants.OPTIONS.deleteTrigger,
      icon: svgDelete,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('deleteSingle');
        setDialogOpen(true);
      }
    },
  ]), [t])

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const handleDeleteTriggers = () => {
    setDialogName('delete');
    setDialogOpen(true);
  }

  const handleCreateTrigger = () => {
    setDialogName('create');
    setDialogOpen(true);
  }

  const dataProps = useMemo(() => {
    return modifyDataTriggers(tableData, '', rowOptions);
  }, [tableData])

  const renderNoButton = useMemo(() =>
      <StyledButton
        width='180px'
        mytype='secondary'
        handleClick={() => setDialogOpen(false)}
      >
        {t('ui.no')}
      </StyledButton>
    , [t])

  const deleteTrigger = (id) => {
    return apolloClient.mutate({
      mutation: removeCouponTrigger,
      variables: {
        id,
      },
    });
  }

  const DialogDeleteSingle = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={t('discount.delete_trigger_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              setDialogOpen(false);
              deleteTrigger(rowId)
                .then(res => {
                  if (res) {
                    refetch();
                    enqueueSnackbar(t('discount.delete_trigger_success'), {variant: 'success'});
                  }
                })
                .catch((e) => {
                  enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                  console.log(e.message);
                })
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [rowId, t])

  const DialogDelete = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={selectedRows.length > 1
            ? t('discount.delete_triggers_q', { count: selectedRows.length })
            : t('discount.delete_trigger_q')
          }
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={async () => {
              setDialogOpen(false);
              const successes = [];

              try {
                for (const recordId of selectedRows) {
                  const { data } = await deleteTrigger(recordId);
                  if (data) successes.push(data);
                }
              } catch(e) {
                enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                console.log(e.message);
              }

              if (successes.length) {
                refetch();
                enqueueSnackbar(
                  successes.length > 1 ?
                    t('discount.delete_triggers_success', { count: successes.length })
                    : t('discount.delete_trigger_success'),
                  {variant: 'success'}
                )
              }
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    );
  }, [selectedRows, t]);

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'create':
      case 'edit': return <DialogEditCreateTrigger
        shopId={shopIds[0]}
        apolloClient={apolloClient}
        enqueueSnackbar={enqueueSnackbar}
        page={dialogName}
        declineAction={() => setDialogOpen(false)}
        triggerId={rowId}
        refetch={refetch}
      />;
      case 'deleteSingle': return DialogDeleteSingle;
      case 'delete': return DialogDelete;
      default: return null;
    }
  }

  const renderColumn = (myClass) => {
    return <Grid item xs={12} lg={5} className={classes[myClass]}>
      <StyledButton
        width='140px'
        mytype='secondary'
        handleClick={exportData}
        disabled={exporting || !tableData.length}
      >
        {t('ui.export')}
      </StyledButton>
      <div className={classes.deleteAndAdd}>
        <IconButtons
          handleClick={handleDeleteTriggers}
          disabled={selectedRows.length === 0}
        />
        <StyledButton
          minwidth='160px'
          handleClick={handleCreateTrigger}
        >
          {t('discount.create_coupon_trigger')}
        </StyledButton>
      </div>

    </Grid>
  }

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Toolbar title={t('discount.coupon_triggers')} />

        {
          shopIds.length === 1 && <>
            <Hidden mdDown>
              <Grid container className={classes.grid}>
                <Grid item lg={7} className={classes.gridEmpty}/>
                {renderColumn(`secondColumn${rtl}`)}
              </Grid>
            </Hidden>

            <Hidden lgUp>
              {renderColumn('secondColumnXS')}
            </Hidden>

            <div className={classes.secondRow}>
              <Search
                handleChange={handleSearch}
                onSearchClick={refetch}
                placeholder={t('ui.search')}
                useDebounce
              />
            </div>
          </>
        }

        <Grid item sm={12}>
          {shopIds.length !== 1 ? (
              <span className={classes.selectSomeShops}>{t('discount.please_select_one_shop')}</span>
            ) :
            <DataTable
              columns={columns}
              isLoading={isLoading}
              data={dataProps}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              handleFetchData={onFetchData}
              pageCount={pageCount}
              totalCount={totalCount}
              searchValue={searchValue}
              reloadTable={reloadTable}
              defaultSortField='createdAt'
              defaultSortOrder='desc'

              // styles for 2 rows upper panel + checkboxes
              maxHeight='calc(100vh - 390px)'
              maxHeightLaptop='calc(100vh - 460px)'
              maxHeightMobile='calc(100vh - 440px)'
            />
          }
        </Grid>
      </Grid>
      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>
  );
}

export default CouponTriggersPage;
