import gql from "graphql-tag";

export default gql`
  query shop($id: ID!) {
    shop(id: $id) {
      _id
      language
    }
  }
`;
