import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const useProductTypesColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'name',
      label: 'Name',
      isBold: true,
      sortable: false,
    },
    {
      name: 'linkedAttributes',
      label: 'Linked Attributes',
      sortable: false,
      hideValues: true,
    },
    {
      name: 'options',
    }
  ], [t]);
};

export default useProductTypesColumns;
