import React from 'react';
import PropTypes from "prop-types";
import {Dialog} from '@material-ui/core';
import styled from 'styled-components';
import svgClose from '../../icons/close.svg';
import Icon from '../Icon';
import {colors} from '../../constants';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: linear-gradient(
        -75deg, 
        rgba(112, 21, 184, .5), 
        rgba(255, 182, 126, .5) 45%, 
        rgba(255, 182, 126, .5) 55%, 
        rgba(112, 21, 184, .5)
      );
    padding: 5px;
    border: none;
    border-radius: 20px;

    .paper {
      padding: 35px;
      border-radius: 15px;
      background: white;
      position: relative;
      overflow-y: scroll;
      max-height: 90vh;
      height: ${(props) => props.paperheight};

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: ${colors.blue};
        border-radius: 8px;
      }
      
      &::-webkit-scrollbar-corner {
        display: none;
      }
    }

    .close {
      position: absolute;
      top: 18px;
      right: 18px;
    }
  }
  
  @media all and (max-width: 960px) {
    .MuiDialog-paper {
      width: 80%;
    }
  }
`

const CustomDialog = ({
  open, 
  handleClose, 
  children,
  hideCloseIcon,
  paperHeight = 'auto',
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      maxWidth={false}
      paperheight={paperHeight}
      disableEnforceFocus
    >
      <div className="paper">
        {children}
      </div>
      {
        !hideCloseIcon &&
        <Icon
          className="close"
          onClick={handleClose}
        >
          <img src={svgClose} alt=''/>
        </Icon>
      }
    </StyledDialog>
  );
}

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired, 
  handleClose: PropTypes.func.isRequired,
  hideCloseIcon: PropTypes.bool,
  // maxWidth: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.bool,
  // ])
}

export default CustomDialog;
