import React, { useCallback } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dropzone from '../../../UI/Dropzone';
import DropContainer from "../../../UI/Dropzone/DropContainer";
import { useMutation } from "@apollo/react-hooks";
import createMediaRecordMutation from "../graphql/mutations/createMediaRecordMutation";
import { FileRecord } from "@reactioncommerce/file-collections";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const MediaUploader = ({
   productId,
   priority,
   onFiles,
   shopId,
   enqueueSnackbar,
   isUploading,
   setIsUploading,
   refetchMedia
}) => {
  const { t } = useTranslation();

  const { handleOpenDialog } = useFormContext();

  const [createMediaRecord] = useMutation(createMediaRecordMutation, {
    ignoreResults: true,
  });

  const isAllMediaProcessed = useCallback((ids, product) => {
    // Get media for product, variants and options

    let allMedia = [...product.media];
    if (product.variants) {
      product.variants.forEach(variant => {
        // Add variant media if set
        if (Array.isArray(variant.media)) {
          allMedia = allMedia.concat(variant.media);
        }

        // Add option media if set
        if (Array.isArray(variant.options)) {
          variant.options.forEach(option => {
            allMedia = allMedia.concat(option.media);
          });
        }
      });
    }

    const mediaItems = [];

    allMedia.forEach(media => {
      mediaItems.push({ id: media._id, thumbnailUrl: media.URLs.small });
    });

    return ids.every(uploadedMediaId => {
      const mediaItem = mediaItems.find(item => item.id === uploadedMediaId);

      // If a url has been generated, then these media items has been processed successfully.
      return mediaItem && mediaItem.thumbnailUrl !== String(null);
    });
  }, []);

  const uploadFiles = (filesArray) => {
    setIsUploading(true);

    const promises = filesArray.map(async (browserFile, index) => {
      // Paranthesis cause issues
      const newFileName = browserFile.name.replace(")", "").replace("(", "");
      const myNewFile = new File([browserFile], newFileName, { type: browserFile.type });
      const fileRecord = FileRecord.fromFile(myNewFile);

      let newPriority = priority + index;
      if (newPriority > 99) {
        newPriority = '';
      }

      fileRecord.metadata = {
        priority: newPriority,
        productId,
        variantId: undefined,
      };

      await fileRecord.upload();

      // We insert only AFTER the server has confirmed that all chunks were uploaded

      return createMediaRecord({
        variables: {
          input: {
            mediaRecord: fileRecord.document,
            shopId,
          },
        },
      });
    });

    Promise.all(promises)
      .then(responses => {
        // NOTE: This is a temporary workaround due to the fact that on the server,
        // the sharp library generates product images in an async manner.
        // A better solution would be to use subscriptions
        const uploadedMediaIds = responses.map(
          response => response.data.createMediaRecord.mediaRecord._id
        );

        // Poll server every two seconds to determine if all media has been successfully processed
        let isProcessed = false;
        const timerId = setInterval(async () => {
          await refetchMedia()
            .then((res) => {
              const newProduct = res.data.product;
              isProcessed = isAllMediaProcessed(uploadedMediaIds, newProduct);

              if (isProcessed) {
                setIsUploading(false);
                clearTimeout(timerId);
              }
            });
        }, 2000);

        // Stop polling after 30 seconds
        setTimeout(() => {
          clearTimeout(timerId);
          setIsUploading(false);
        }, 30000);

        return null;
      })
      .catch(error => {
        setIsUploading(false);
        enqueueSnackbar(t('product.image_upload_error'), { variant: "error" });
        console.log(error.message);
      });
  }

  const handleDrop = (files) => {
    if (files.length === 0) return;

    // Pass onFiles func to circumvent default uploader
    if (onFiles) {
      onFiles(files);
    } else {
      uploadFiles(files);
    }
  }

  const handleClick = (e) => {
    if (!productId) {
      handleOpenDialog();
      e.preventDefault();
    }
  }

  return (
    <>
      {
        isUploading ?
          <LinearProgress /> :
          <Dropzone
            accept="image/jpg, image/png, image/jpeg"
            onDrop={handleDrop}
            maxFiles={10}
            handleClick={handleClick}
          >
            <DropContainer/>
          </Dropzone>
      }
    </>
  );
}

export default MediaUploader;
