import styled from 'styled-components';
import { Box, Button, Typography, Menu, MenuItem } from "@material-ui/core";

export const LanguageButton = styled(Button)`
  height: 40px;
  background: none;
  padding: 8px;
  border-radius: 0;
  
  &:hover {
    background: none;
  }
`;

export const LanguageText = styled(Typography)`
  color: #222;
  font-family: Fredoka, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
`;

export const LanguageBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const LanguageArrowImg = styled(Box)`
  width: 16px;
  height: 16px;
  position: relative;
  transition: .15s;
`;

export const LanguageMenu = styled(Menu)`
  .MuiPaper-root {
    border-radius: 8px;
    border: none;
    box-shadow: 0 4px 20px 0 #D8DADF;
    max-height: 180px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: #E5E9F6;
      border-radius: 6px;
    }
    
    .MuiList-root {
      padding: 0;
    }
  }
`;

export const LanguageMenuItem = styled(MenuItem)`
  height: 40px;
  justify-content: flex-end;
  
  &.Mui-selected, &.Mui-selected:hover, &:hover {
    background: #F8F8F8;
  }
`;

export const LanguageCheckImg = styled(Box)`
  width: 16px;
  height: 16px;
  position: relative;
`;
