import {IconNavTags} from '../../icons';
import CubeIcon from "mdi-material-ui/Cube";

import TagFormPage from "./pages/TagFormPageWithData";
import TagTable from "./pages/TagTable";

import { constants } from "../../constants.js";

export default function plugin({ registerRoute, registerBlock }) {
  registerRoute({
    path: "/tags/create/:shopId",
    MainComponent: TagFormPage,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantProductManager,
      constants.ACCOUNT_TYPES.merchantShopProductManager,
    ],
  });

  registerRoute({
    path: "/tags/edit/:shopId/:tagId",
    MainComponent: TagFormPage,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantProductManager,
      constants.ACCOUNT_TYPES.merchantShopProductManager,
    ],
  });

  registerRoute({
    group: "navigation",
    path: "/tags",
    priority: 30,
    MainComponent: TagTable,
    IconComponent: IconNavTags,
    navigationItemLabel: 'menu.tags',
    showShopSelector: true,
    collapse: {
      title: 'menu.products',
      IconComponent: CubeIcon, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantProductManager,
      constants.ACCOUNT_TYPES.merchantShopProductManager,
    ],
  });
}
