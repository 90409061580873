import React from 'react';
import FormCard from "../../../UI/Form/FormCard";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import Select from "../../../UI/Select";
import { Controller, useFormContext } from "react-hook-form";
import CategorySelectorWithData from "../../../package/src/CategorySelectorWithData";
import FormNote from "../../../UI/Form/FormNote";
import FormInput from "../../../UI/Form/FormInput";
import TagSelectorWithData from "../../../package/src/TagSelectorWithData";
import { useTranslation } from "react-i18next";

const emailText = 'wondersouq@gmail.com';

const BlockSide = ({shopId}) => {
  const { control, formState: { errors }, loading } = useFormContext();
  const { t } = useTranslation();

  const statusOptions = [
    {
      value: 'invisible',
      name: t('product.invisible'),
    },
    {
      value: 'visible',
      name: t('product.visible'),
    },
  ];

  return (
    <>
      <FormCard>
        <FormHeader>{t('product.status')}</FormHeader>
        <InputLabel disabled={loading}>{t('product.choose_status')}</InputLabel>
        <Controller
          name="status"
          control={control}
          render={({ field }) => <Select
            placeholder={t('product.choose_status_lowercase')}
            value={field.value}
            onChange={field.onChange}
            options={statusOptions}
            disabled={loading}
          />}
        />
      </FormCard>

      <FormCard>
        <FormHeader helpText={t('product.help_category')}>{t('product.categories')}</FormHeader>
        <InputLabel disabled={loading} error={errors.categories}>{t('product.choose_categories')}</InputLabel>
        <Controller
          name="categories"
          control={control}
          render={({ field }) => <CategorySelectorWithData
            placeholder={t('product.choose_categories_lowercase')}
            selectAllLabel={t('product.all_categories')}
            isMultiSelect
            value={field.value}
            onChange={field.onChange}
            error={errors.categories}
            disabled={loading}
          />}
        />
        <FormNote style={{marginBottom: 0}}>
          {t('product.note_category')}<span> {emailText}</span>
        </FormNote>
      </FormCard>

      <FormCard>
        <FormHeader>{t('product.social_networks')}</FormHeader>
        <InputLabel disabled={loading} error={errors.facebook}>{t('product.facebook_message')}</InputLabel>
        <Controller
          name="facebook"
          control={control}
          render={({ field }) => <FormInput
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            error={errors.facebook}
          />}
        />
        <InputLabel disabled={loading} error={errors.twitter}>{t('product.twitter_message')}</InputLabel>
        <Controller
          name="twitter"
          control={control}
          render={({ field }) => <FormInput
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            error={errors.twitter}
          />}
        />
        <InputLabel disabled={loading} error={errors.direct}>{t('product.direct_message')}</InputLabel>
        <Controller
          name="direct"
          control={control}
          render={({ field }) => <FormInput
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            error={errors.direct}
          />}
        />
      </FormCard>

      <FormCard>
        <FormHeader helpText={t('product.help_tag')}>{t('product.tags')}</FormHeader>
        <Controller
          name="tags"
          control={control}
          render={({ field }) => <TagSelectorWithData
            placeholder={t('product.search_create_tags')}
            selectAllLabel={t('product.all_tags')}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            shopId={shopId}
          />}
        />
      </FormCard>
    </>
  )
}

export default BlockSide;
