import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Icon } from "@material-ui/core";
import Amplify, { Storage } from "aws-amplify";
import { downloadBlob } from "../../../package/src/utils/downloadBlob.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import StyledButton from '../../../UI/Button';
import DialogButtons from '../../../UI/Dialog/DialogButtons';
import Chip from '../../../UI/Chip';
import svgDownload from '../../../icons/download.svg';
import svgImport from '../../../icons/import.svg';
import {colors} from '../../../constants';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    alignItems: "center",
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "block",
  },
  helpText: {
    // marginLeft: theme.spacing(2)
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  leftChip: {
    marginRight: theme.spacing(1),
  },
  centeredContainer: {
    display: "flex",
    justifyContent: "center",
  },
  message: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 16,
    color: colors.black,
  },
  chips: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }
}));

/**
 * BulkImportCard component
 * @param {Object} props Component props
 * @returns {React.Component} A React component
 */
export default function BulkImportCard(props) {
  const {
    files,
    message,
    getInputProps,
    getRootProps,
    handleDelete,
    importFiles,
    isImportLoading,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isSampleLoading, setIsSampleLoading] = useState(false);

  useEffect(() => {
    Storage.configure({
      AWSS3: {
        bucket: Amplify._config.aws_user_files_s3_bucket,
        region: Amplify._config.aws_user_files_s3_bucket_region,
        customPrefix: {
          public: "",
        },
      },
    });
  }, []);

  const onDownloadExampleCSV = async () => {
    setIsSampleLoading(true);
    await Storage.get("templates/product-import.xlsx", {
      download: true,
    }).then(res => downloadBlob(res.Body, "product-import.xlsx"))
    .finally(() => {
      setTimeout(() => setIsSampleLoading(false), 800)
    })
  };

  return isImportLoading ? (
    <div className={classes.centeredContainer}>
      <CircularProgress />
    </div>
  ) : (files.length > 0) ? 
      <>
        <div className={classes.chips}>
          {files.map((file, index) => (
            <Chip
              label={file.name}
              key={index}
              onDelete={() => handleDelete(file.name)}
            />
          ))}
        </div>
        <DialogButtons justifyButtons='flex-end'>
          <StyledButton
            onClick={() => importFiles(files)}
          >
            {t('product.bulk_import')}
          </StyledButton>
        </DialogButtons>
      </> : 
      <>
        <div className={classes.message}>{message}</div>
        <DialogButtons>
          <StyledButton
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} />
            <Icon><img src={svgImport}/></Icon>
            {t('product.import')}
          </StyledButton>
          <StyledButton
            handleClick={onDownloadExampleCSV}
            mytype='third'
            disabled={isSampleLoading}
          >
            <Icon><img src={svgDownload}/></Icon>
            {t('product.sample_data')}
          </StyledButton>
        </DialogButtons>
      </>
}

BulkImportCard.propTypes = {
  files: PropTypes.array,
  getInputProps: PropTypes.func,
  getRootProps: PropTypes.func,
  handleDelete: PropTypes.func,
  importFiles: PropTypes.func,
  isFilterByFileVisible: PropTypes.bool,
  setFilterByFileVisible: PropTypes.func,
};
