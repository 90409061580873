import React, { useCallback, useEffect, useState } from "react";
import OverrideForm from "./OverrideForm";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const OverrideFormLayout = ({
  id,
  query,
  mutation,
  queryOutput,
  mutationOutput,
}) => {
  const [loading, setLoading] = useState(false);
  const [initialRate, setInitialRate] = useState(null);
  const [initialCommission, setInitialCommission] = useState(null);

  const apolloClient = useApolloClient();
  const {enqueueSnackbar} = useSnackbar();
  const { t } = useTranslation();

  const getOverride = () => {
    return apolloClient.query({
      query,
      variables: {
        id,
      },
      fetchPolicy: "network-only",
    })
  }

  const setRateOverride = (rateOverride) => {
    return apolloClient.mutate({
      mutation,
      variables: {
        id,
        rateOverride,
      },
    })
  }

  const setCommissionOverride = (commissionOverride) => {
    return apolloClient.mutate({
      mutation,
      variables: {
        id,
        commissionOverride,
      },
    })
  }

  useEffect(() => {
    if (!id) {
      return;
    }

    setLoading(true);

    getOverride()
      .then((res) => {
        const data = res.data?.[queryOutput];

        if (!data) {
          return;
        }

        if (data.rate !== null) {
          setInitialRate({
            enable: !data.rate.disabled,
            percentage: data.rate.changePercentage,
            period: {
              startDate: new Date(data.rate.startDate) || null,
              endDate: new Date(data.rate.endDate) || null,
            },
            notes: data.rate.notes,
          });
        }

        if (data.commission !== null) {
          setInitialCommission({
            enable: !data.commission.disabled,
            percentage: data.commission.newValue,
            period: {
              startDate: new Date(data.commission.startDate) || null,
              endDate: new Date(data.commission.endDate) || null,
            },
            notes: data.commission.notes,
          });
        }
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setLoading(false))
  }, [id, t]);

  const onSubmitRate = useCallback(async (formData) => {
    setLoading(true);

    return setRateOverride({
      disabled: !formData.enable,
      changePercentage: Number(formData.percentage),
      startDate: formData.period.startDate?.toISOString() || '',
      endDate: formData.period.endDate?.toISOString() || '',
      notes: formData.notes,
    })
      .then((res) => {
        return res.data[mutationOutput].rate;
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [mutationOutput, t]);

  const onSubmitCommission = useCallback(async (formData) => {
    setLoading(true);

    return setCommissionOverride({
      disabled: !formData.enable,
      newValue: Number(formData.percentage),
      startDate: formData.period.startDate?.toISOString() || '',
      endDate: formData.period.endDate?.toISOString() || '',
      notes: formData.notes,
    })
      .then((res) => {
        return res.data[mutationOutput].commission;
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [mutationOutput, t]);

  return (
    <>
      <OverrideForm
        title={t('common.rate')}
        loading={loading}
        data={initialRate}
        onSubmit={onSubmitRate}
        sx={{
          marginBottom: '32px',
        }}
      />
      <OverrideForm
        title={t('common.commission')}
        loading={loading}
        data={initialCommission}
        onSubmit={onSubmitCommission}
      />
    </>
  )
}

export default OverrideFormLayout;
