import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const useFinanceRecordsColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'id',
      label: t('finance.merchant'),
    },
    {
      name: 'reminder',
      label: t('finance.reminder_emails_sent'),
    },
    {
      name: 'days',
      label: t('finance.day_wnegative_balance'),
    },
    {
      name: 'orders',
      label: t('finance.orders'),
      postfix: ' (USD)',
      isBold: true,
    },
    {
      name: 'subscriptions',
      label: t('finance.subscriptions'),
      postfix: ' (USD)',
      isBold: true,
    },
    {
      name: 'transactions',
      label: t('finance.transactions'),
      postfix: ' (USD)',
      isBold: true,
    },
    {
      name: 'total',
      label: t('finance.total'),
      postfix: ' (USD)',
      isBold: true,
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
  ], [t]);
};

export default useFinanceRecordsColumns;
