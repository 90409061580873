import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import Button from "../../../UI/Button";
import styled from 'styled-components';
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import CheckboxContainer from "../../../UI/Checkbox/CheckboxContainer";
import { useTranslation } from "react-i18next";

const StyledForm = styled.form`
  width: 400px;
  
  @media all and (max-width: 959px) {
    width: 100%;
  }
`;

const StyledCardWrapper = styled.div`
  margin-bottom: 32px;
`;

const CheckoutForm = ({ onFinish, style, justifyButtons = 'flex-end' }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isCreating, setIsCreating] = useState(false);
  const [isDefault, setIsDefault] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = async event => {
    event.preventDefault();

    setIsCreating(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      setIsCreating(false);
    }

    onFinish(paymentMethod?.id, isDefault, error);
  };

  const onCheck = () => {
    setIsDefault(prev => !prev);
  }

  return (
    <StyledForm onSubmit={handleSubmit} style={style}>
      <StyledCardWrapper>
        <CardElement />
      </StyledCardWrapper>

      <CheckboxContainer>
        <Checkbox
          checked={isDefault}
          onChange={onCheck}
        />
        {t('settings.set_as_default')}
      </CheckboxContainer>

      <DialogButtons marginTop='16px' justifyButtons={justifyButtons}>
        {
          isCreating ?
            <CircularProgress /> :
            <Button
              disabled={!stripe}
              type="submit"
              width='120px'
            >
              {t('ui.save')}
            </Button>
        }
      </DialogButtons>

    </StyledForm>
  );
};

export default CheckoutForm;
