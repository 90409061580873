import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import productsQuery from "../graphql/queries/products";
import SelectAllAutocomplete from "../SelectAllAutocomplete";

/**
 * ProductSelectorWithData
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function ProductSelectorWithData({shopIds, excludeIds = [], value, onChange, shouldShowShopName = false, ...other}) {
  const [productsList, setProductsList] = useState([]);

  const { data } = useQuery(productsQuery, {
    variables: {
      shopIds,
    },
    fetchPolicy: "network-only",
    onError: (e) => {
      console.log(e.message);
    }
  });

  const handleChange = (val) => {
    onChange(val.map(item => item.value));
  }

  useEffect(() => {
    if (data) {
      const options = data.products?.nodes;
      const result = options
        .filter(product => !excludeIds.includes(product._id))
        .map(product => {
          const appendShopName = shouldShowShopName ? ` - ${product.shop.name}` : "";

          return {
            value: product._id,
            label: `${product.title}${appendShopName}`,
            selected: value.includes(product._id),
          };
        });
      setProductsList(result);
    }
  }, [data, value, shopIds])

  return (
    <SelectAllAutocomplete
      items={productsList}
      onChange={handleChange}
      {...other}
    />
  );
}

export default ProductSelectorWithData;
