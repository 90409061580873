import React, { Fragment } from "react";
import CustomDatePicker from "../../../../../package/src/DatePicker";
import { v4 } from "uuid";
import FormInput from "../../../../../UI/Form/FormInput";
import svgAdd from "../../../../../icons/variantEditor/add.svg";
import AddButtonText from "../common/AddButtonText";
import AddButton from "../common/AddButton";
import svgDelete from "../../../../../icons/delete.svg";
import InputLabelWithButton from "../common/InputLabelWithButton";

const isValid = (val) => {
  return val.every((date) => date.name.trim() && date.date);
};

const EditDate = ({ value, onChange }) => {
  const handleAddDate = () => {
    if (value) {
      const newValue = [
        ...value,
        {
          id: v4(),
          date: '',
          name: '',
        },
      ];
      onChange(newValue, false);
    }
  };

  const handleChangeDate = (id, newDate) => {
    if (value) {
      const newValue = value.map((d) => {
        if (d.id === id) {
          return {
            ...d,
            date: newDate,
          }
        }
        return d;
      })
      onChange(newValue, isValid(newValue));
    }
  };

  const handleChangeName = (id, newName) => {
    if (value) {
      const newValue = value.map((d) => {
        if (d.id === id) {
          return {
            ...d,
            name: newName,
          }
        }
        return d;
      })
      onChange(newValue, isValid(newValue));
    }
  };

  const handleDeleteDate = (id) => {
    if (value) {
      const newValue = value.filter((item) => item.id !== id);
      onChange(newValue, isValid(newValue));
    }
  };

  return (
    <>
      {
        value?.map((item, index) => <Fragment key={item.id}>
          <InputLabelWithButton
            onButtonClick={() => handleDeleteDate(item.id)}
            ButtonIconComponent={() => <img src={svgDelete} alt='delete' />}
          >
            Date {index + 1}
          </InputLabelWithButton>
          <FormInput
            value={item.name}
            margin='0 0 15px'
            onChange={(e) => handleChangeName(item.id, e.target.value)}
            placeholder='Date Name'
          />
          <CustomDatePicker
            margin='0 0 24px'
            value={item.date}
            onChange={(val) => handleChangeDate(item.id, val)}
          />
        </Fragment>)
      }
      <AddButton
        disableRipple
        onClick={handleAddDate}
        margin='0 0 24px 0'
      >
        <img src={svgAdd} alt='+' />
        <AddButtonText>Add Date</AddButtonText>
      </AddButton>
    </>
  );
};

export default EditDate;
