import { SHOP_PARTS_INFO_MAP } from "../components/Shop";

export default function createConfigForBeginning(newModel, accessory) {
  const shopConfig = {
    "shopConfig": {
      "layout": {},
    },
  };
  for (const partsKey of SHOP_PARTS_INFO_MAP.keys()) {
    if (SHOP_PARTS_INFO_MAP.get(partsKey) === accessory) {
      let k = partsKey.replace("Info", "");
      shopConfig["shopConfig"]["layout"][partsKey] = {};
      shopConfig["shopConfig"]["layout"][partsKey][k] = {
        "id": newModel,
      };
      shopConfig["shopConfig"]["layout"][partsKey]["payload"] = {};
      shopConfig["shopConfig"]["layout"][partsKey]["payload"]["color"] = undefined;
    }
  }
  return shopConfig;
};
