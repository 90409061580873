import React, { useCallback, useEffect, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid, makeStyles } from "@material-ui/core";
import MerchantSelectorWithData from "../../../package/src/MerchantSelectorWithData";
import DateRangeSelector from "../../../package/src/DateRangeSelector";
import Search from "../../../UI/Search/Search";
import StyledButton from "../../../UI/Button/Button";
import DataTable from "../../../UI/DataTable/DataTable";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { financialHistoryRecordsGQL } from "../graphql/queries";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import useFinanceRecordsColumns from "../hooks/useFinanceRecordsColumns";
import { useTranslation } from "react-i18next";
import useFinancialsModifiers from "../../financials/hooks/useFinancialsModifiers";
import { useTagsColumns } from "../../tags/hooks/useTagsColumns";

const useStyles = makeStyles(theme => ({
  searchAndExport: {
    width: '100%',
    display: 'flex',
    gap: 20,
  },
}));

const FinancialHistoryRecordsPage = ({viewer}) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState({startDate: null, endDate: null});
  const [reloadTable, setReloadTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [selectedMerchants, setSelectedMerchants] = useState({
    selectedCompanyIds: [],
    selectedShopIds: [],
  });
  const columns = useTagsColumns();
  const historyColumns = useFinanceRecordsColumns();
  const { modifyData } = useFinancialsModifiers();

  useEffect(() => {
    refetch();
  }, [dateRange, selectedMerchants])

  const onFetchData = useCallback(({pageSize}) => {
    setIsLoading(true);

    apolloClient.query({
      query: financialHistoryRecordsGQL,
      variables: {
        companyIds: selectedMerchants.selectedCompanyIds,
        genericFilters: {
          dateRange: {
            gte: dateRange.startDate,
            lte: dateRange.endDate,
            field: "createdAt",
          },
        }
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        console.log(res.data.financialHistoryRecords.nodes);
        const total = res.data.financialHistoryRecords.totalCount;
        setTableData(res.data.financialHistoryRecords.nodes);
        setPageCount(Math.ceil(total / pageSize));
        setTotalCount(total);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [selectedMerchants, t]);

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: financialHistoryRecordsGQL,
      variables: {
        companyIds: selectedMerchants.selectedCompanyIds,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyData(res.data.financialHistoryRecords.nodes, 'export', null);
        downloadCSV(columns, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [selectedMerchants, modifyData, t, columns]);

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const dataProps = useMemo(() => {
    return modifyData(tableData);
  }, [tableData, modifyData])

  return (
    <>
      <Toolbar title={t('finance.financial_history_records')} />

      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <MerchantSelectorWithData
            shouldShowShopName
            viewer={viewer}
            selectedCompanyIds={selectedMerchants.selectedCompanyIds}
            onMerchantSelectionChange={setSelectedMerchants}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DateRangeSelector
            placeholder={t('finance.choose_period')}
            dateRange={dateRange}
            onChange={setDateRange}
          />
        </Grid>
      </Grid>

      <div className={classes.searchAndExport}>
        <Search
          handleChange={handleSearch}
          onSearchClick={refetch}
          placeholder={t('ui.search')}
          useDebounce
          minWidth='270px'
        />
        <StyledButton
          width='140px'
          mytype='secondary'
          handleClick={exportData}
          disabled={exporting || !tableData.length}
        >
          {t('ui.export')}
        </StyledButton>
      </div>

      <DataTable
        columns={historyColumns}
        isLoading={isLoading}
        data={dataProps}
        handleFetchData={onFetchData}
        pageCount={pageCount}
        totalCount={totalCount}
        searchValue={searchValue}
        reloadTable={reloadTable}
        defaultSortField='createdAt'
        defaultSortOrder='desc'
        showCheckboxes={false}

        // styles for 2 rows upper panel
        maxHeight='calc(100vh - 370px)'
        maxHeightLaptop='calc(100vh - 440px)'
        maxHeightMobile='calc(100vh - 420px)'
      />
    </>
  );
}

export default FinancialHistoryRecordsPage;
