import React from "react";
import {IconNavProducts} from '../../icons';
import ProductsTable from "./layouts/ProductsTable";
import ProductDetail from "./layouts/ProductDetail";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute }) {
  // Register routes
  registerRoute({
    MainComponent: ProductDetail,
    path: "/products/create/:shopId",
    navigationItemLabel: 'menu.product_creation',
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantProductManager,
      constants.ACCOUNT_TYPES.merchantShopProductManager,
    ],
  });

  registerRoute({
    MainComponent: ProductDetail,
    path: "/products/edit/:shopId/:productId",
    navigationItemLabel: 'menu.product_edition',
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantProductManager,
      constants.ACCOUNT_TYPES.merchantShopProductManager,
    ],
  });

  registerRoute({
    group: "navigation",
    priority: 30,
    layoutComponentProps: {
      size: "wide",
    },
    path: "/products",
    MainComponent: ProductsTable,
    IconComponent: IconNavProducts,
    navigationItemLabel: 'menu.product_list',
    showShopSelector: true,
    collapse: {
      title: 'menu.products',
      IconComponent: IconNavProducts,
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantProductManager,
      constants.ACCOUNT_TYPES.merchantShopProductManager,
    ],
  });
}
