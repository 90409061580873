import gql from "graphql-tag";

export const shopConfigQuery = gql`
  query shopConfig($id: ID!) {
    shopConfig(shopId: $id) {
      layout {
      roofInfo {
        payload { 
          color
        }
        roof { 
          id
          model {
            url
            material
            textures {
              ao
              normal
            }
          }
        }
      }
      signAreaInfo {
        payload {
          color
          imageUrl
        }
        signArea {
          id
          model {
            url
            material
            textures {
              ao
              normal
            }
          }
        }
      }
      secondFloorInfo {
        payload {
          color
        }
        secondFloor {
          id
          model {
            url
            material
            textures {
              ao
              normal
            }
          }
        }
      }
      firstFloorInfo {
        payload {
          color
          imageUrl
        }
        layoutInfo {
          doorInfo {
            payload {
              color
              imageUrl
            }
            door {
              id
              model {
                url
              }
            }
          }
          umbrellaInfo {
            payload {
              color
            }
            umbrella {
              id
              model {
                url
              }
            }
          }
        }
        firstFloor {
          id
          model {
            url
            material
            textures {
              ao
              normal
            }
          }
        } 
      }
    }
    }
  }
`;