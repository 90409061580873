import React, { Fragment } from "react";
import svgAdd from "../../../../../icons/variantEditor/add.svg";
import AddButtonText from "../common/AddButtonText";
import AddButton from "../common/AddButton";
import styled from "styled-components";
import { v4 } from "uuid";
import FormInput from "../../../../../UI/Form/FormInput";
import ColorPicker from "../../../../../package/src/ColorPicker";
import InputLabelWithButton from "../common/InputLabelWithButton";
import svgDelete from "../../../../../icons/delete.svg";

const PickerBox = styled('div')`
  width: 100%;
  display: flex;
  gap: 15px;
`;

const isValid = (val) => {
  return val.every((color) => color.name.trim() && color.hex);
};

const EditColor = ({ value, onChange }) => {
  const handleAddColor = () => {
    if (value) {
      const newValue = [
        ...value,
        {
          id: v4(),
          hex: '',
          name: '',
        },
      ];
      onChange(newValue, false);
    }
  };

  const handleDeleteColor = (id) => {
    if (value) {
      const newValue = value.filter((item) => item.id !== id);
      onChange(newValue, isValid(newValue));
    }
  };

  const handleChangeColor = (id, newHex) => {
    if (value) {
      const newValue = value.map((d) => {
        if (d.id === id) {
          return {
            ...d,
            hex: newHex,
          }
        }
        return d;
      });
      onChange(newValue, isValid(newValue));
    }
  };

  const handleChangeName = (id, newName) => {
    if (value) {
      const newValue = value.map((d) => {
        if (d.id === id) {
          return {
            ...d,
            name: newName,
          }
        }
        return d;
      });
      onChange(newValue, isValid(newValue));
    }
  };

  return (
    <>
      {
        value?.map((item, index) => <Fragment key={item.id}>
          <InputLabelWithButton
            onButtonClick={() => handleDeleteColor(item.id)}
            ButtonIconComponent={() => <img src={svgDelete} alt='delete' />}
          >
            Color {index + 1}
          </InputLabelWithButton>
          <PickerBox>
            <ColorPicker
              colorList={value}
              color={item}
              onConfirmChange={handleChangeColor}
            />
            <FormInput
              value={item.name}
              onChange={(e) => handleChangeName(item.id, e.target.value)}
              placeholder='Color Name'
            />
          </PickerBox>
        </Fragment>)
      }
      <AddButton
        disableRipple
        onClick={handleAddColor}
        margin='0 0 24px 0'
      >
        <img src={svgAdd} alt='+' />
        <AddButtonText>Add Color</AddButtonText>
      </AddButton>
    </>
  );
};

export default EditColor;
