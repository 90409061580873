import { formatDateRangeFilter } from "./index";
import { isObject } from "lodash";
import isEmptyObject from "../../../package/src/utils/isEmptyObject";

export const cleanStatus = (text) => {
  if (!text) {
    return '';
  }
  const index = text.indexOf('/') + 1;
  return text.substring(index);
}

export const parseAddress = (data) => {
  return data.address1 + ', ' + data.city + ', ' + data.region + ', ' + data.postal + ', ' + data.country;
}

export const modifyFilters = (filtersByKey) => {
  const filters = {...filtersByKey};

  if (filters.createdAt) {
    filters.createdAt = formatDateRangeFilter(filters.createdAt);
  }

  for (let val in filters) {
    if (
      !filters[val] ||
      (Array.isArray(filters[val]) && !filters[val].length) ||
      (isObject(filters[val]) && isEmptyObject(filters[val]))
    ) {
      delete filters[val];
    }
  }

  return filters;
}
