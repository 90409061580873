import React from "react";
import styled from "styled-components";
import HeadingText from "../common/HeadingText";
import VariantsTable from "../TableComponents/VariantsTable";
import { TableContainer } from "@material-ui/core";
import VariantControls from "../TableComponents/VariantControls";
import VariantEditDelete from "../TableComponents/VariantEditDelete";
import { VariantScrollCss } from "../TableComponents/styles";

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 57px 1fr;
`;

const HeadingBox = styled('div')`
  width: 100%;
  border-bottom: 1px solid #E9E9E9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;

const Container = styled(TableContainer)`
  width: calc(100% + 35px);
  max-height: calc(90vh - 167px);
  padding-bottom: 12px;
  
  ${VariantScrollCss}
`;

const LayoutTable = ({ isEditable = true, shopId, refetchMedia }) => {
  return (
    <Wrapper>
      <HeadingBox>
        <HeadingText>Variants</HeadingText>
        {
          isEditable ?
            <VariantControls /> :
            <VariantEditDelete
              shopId={shopId}
              refetchMedia={refetchMedia}
            />
        }
      </HeadingBox>
      <Container>
        <VariantsTable
          isEditable={isEditable}
          shopId={shopId}
        />
      </Container>
    </Wrapper>
  );
};

export default LayoutTable;