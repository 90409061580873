import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import Search from "../../../UI/Search/Search";
import StyledButton from "../../../UI/Button/Button";
import DataTable from "../../../UI/DataTable/DataTable";
import { makeStyles } from "@material-ui/core";
import { subscriptionHistoryRecords } from "../graphql/queries";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import useFinancialsColumns from "../hooks/useFinancialsColumns";
import { useTranslation } from "react-i18next";
import useFinancialsModifiers from "../hooks/useFinancialsModifiers";

const useStyles = makeStyles(theme => ({
  searchAndExport: {
    width: '100%',
    display: 'flex',
    gap: 20,
  },
}));

const Subscriptions = ({dateRange, companyIds, viewerType}) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [exporting, setExporting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const { getSubColumns } = useFinancialsColumns();
  const { modifySubData } = useFinancialsModifiers();

  const refetch = () => {
    setReload(prev => !prev);
  }

  useEffect(() => {
    refetch();
  }, [dateRange, companyIds, viewerType])

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
    setIsLoading(true);
    apolloClient.query({
      query: subscriptionHistoryRecords,
      variables: {
        companyIds,
        filter: globalFilter,
        first: pageSize,
        //limit: (pageIndex + 1) * pageSize,
        offset: pageIndex * pageSize,
        sortOrder,
        sortBy,
        fromDate: dateRange?.startDate?.toISOString() || null,
        toDate: dateRange?.endDate?.toISOString() || null,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        // Update the state with the fetched data as an array of objects and the calculated page count
        const total = res.data.subscriptionHistoryRecords.totalCount;
        setTableData(res.data.subscriptionHistoryRecords.nodes);
        setPageCount(Math.ceil(total / pageSize));
        setTotalCount(total);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [reload, t]);

  const getColumns = useMemo(() => {
    return getSubColumns(viewerType === 'godmode');
  }, [viewerType, getSubColumns])

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: subscriptionHistoryRecords,
      variables: {
        companyIds,
        fromDate: dateRange?.startDate?.toISOString() || null,
        toDate: dateRange?.endDate?.toISOString() || null,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifySubData(res.data.subscriptionHistoryRecords.nodes, 'export', null);
        const fields = getColumns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [getColumns, dateRange, companyIds, t, modifySubData])

  const dataProps = useMemo(() => {
    return modifySubData(tableData);
  }, [tableData, modifySubData])

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  return (
    <>
      <div className={classes.searchAndExport}>
        <Search
          handleChange={handleSearch}
          onSearchClick={refetch}
          placeholder={t('ui.search')}
          useDebounce
          minWidth='270px'
        />
        <StyledButton
          width='140px'
          mytype='secondary'
          handleClick={exportData}
          disabled={exporting || !tableData.length}
        >
          {t('ui.export')}
        </StyledButton>
      </div>

      <DataTable
        columns={getColumns}
        isLoading={isLoading}
        data={dataProps}
        handleFetchData={onFetchData}
        pageCount={pageCount}
        totalCount={totalCount}
        searchValue={searchValue}
        reloadTable={reload}
        defaultSortField='date'
        defaultSortOrder='desc'
        showCheckboxes={false}

        // styles for 1 row upper panel
        maxHeight='calc(100vh - 280px)'
        maxHeightLaptop='calc(100vh - 380px)'
        maxHeightMobile='calc(100vh - 360px)'
      />
    </>
  );
}

export default Subscriptions;
