import React, { useCallback, useEffect, useState } from "react";
import {Form} from '../../../UI/Form/MyForm';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { MerchantSchema } from "../schemas";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import Button from "../../../UI/Button/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import { getMerchant } from "../graphql/queries";
import { addCompany, updateMerchant } from "../graphql/mutations";
import FormInputPhoneNumber from "../../../UI/Form/FormInputPhoneNumber";
import { useTranslation } from "react-i18next";

const FormEditMerchant = ({merchantId, apolloClient, enqueueSnackbar, declineAction, page, refetch}) => {
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState(null);
  const { t } = useTranslation();

  const { control, handleSubmit, formState: { errors, isDirty }, reset } = useForm({
    resolver: yupResolver(MerchantSchema),
    defaultValues: {
      fullName: t('merchant.new_merchant'),
      email: '',
      phoneNumber: '',
      description: '',
    },
  });

  const getOneMerchant = () => {
    if (page !== 'edit') {
      return Promise.resolve();
    }

    return apolloClient.query({
      query: getMerchant,
      variables: {
        id: merchantId,
      },
      fetchPolicy: 'network-only',
    })
  }

  const mutateMerchant = useCallback((payload) => {
    if (page === 'add') {
      return apolloClient.mutate({
        mutation: addCompany,
        variables: {
          input: {
            name: payload.fullName,
            description: payload.description,
            phoneNumber: payload.phoneNumber,
            email: payload.email,
          }
        },
      });
    }
    return apolloClient.mutate({
      mutation: updateMerchant,
      variables: {
        id: merchantId,
        input: {
          description: payload.description,
          phoneNumber: payload.phoneNumber,
          email: payload.email,
        }
      },
    });
  }, [page, merchantId])

  useEffect(() => {
    setLoading(true);
    getOneMerchant()
      .then(res => {
        const data = res?.data?.company;
        if (data) {
          reset({
            fullName: data.name || '',
            email: data.email || '',
            phoneNumber: data.phoneNumber || '',
            description: data.description || '',
          });
        }
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setLoading(false))
  }, [t])

  const onSubmit = useCallback((newData) => {
    setLoading(true);
    mutateMerchant(newData)
      .then(() => {
        enqueueSnackbar(page === 'add' ? t('merchant.merchant_create_success') : t('merchant.merchant_edit_success'), {variant: 'success'});
        setLoading(false);
        declineAction();
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
        setLoading(false);
      })
  }, [mutateMerchant, t])

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeader>{page === 'add' ? t('merchant.add_merchant') : t('merchant.edit_merchant')}</FormHeader>
      {
        page === 'add' &&
        <>
          <InputLabel error={errors.fullName} disabled={loading}>{t('merchant.full_name')}</InputLabel>
          <Controller
            control={control}
            name='fullName'
            render={({ field }) => <FormInput
              placeholder={t('merchant.write_full_name_here')}
              value={field.value}
              onChange={field.onChange}
              error={errors.fullName}
              disabled={loading}
            />}
          />
        </>
      }
      <InputLabel error={errors.email} disabled={loading}>{t('merchant.email')}</InputLabel>
      <Controller
        control={control}
        name='email'
        render={({ field }) => <FormInput
          placeholder={t('merchant.write_email_here')}
          value={field.value}
          onChange={field.onChange}
          error={errors.email}
          disabled={loading}
        />}
      />
      <InputLabel error={errors.phoneNumber} disabled={loading}>{t('merchant.phone_number')}</InputLabel>
      <Controller
        control={control}
        name='phoneNumber'
        render={({ field }) => <FormInputPhoneNumber
          placeholder={t('merchant.write_phone_number')}
          value={field.value}
          onChange={field.onChange}
          error={errors.phoneNumber}
          disabled={loading}
          phoneError={phoneError}
          setPhoneError={setPhoneError}
        />}
      />
      <InputLabel optionalText={t('ui.optional')} disabled={loading}>{t('merchant.description')}</InputLabel>
      <Controller
        name="description"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('merchant.tell_about_merchant')}
          multiline
          rows={4}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
        />}
      />

      <SaveButtons>
        <Button
          disabled={!isDirty || loading || Boolean(phoneError)}
          type='submit'
          width='180px'
        >
          {page === 'edit' ? t('ui.save_changes') : t('ui.create')}
        </Button>
        <Button
          mytype='third'
          width='180px'
          handleClick={declineAction}
          disabled={loading}
        >
          {t('ui.cancel')}
        </Button>
      </SaveButtons>
    </Form>
  );
}

export default FormEditMerchant;
