import { createContext, useCallback, useMemo, useState } from "react";
import toggleArrayValues from "../utils/toggleArrayValues";
import { v4 } from "uuid";
import processAttributeValues from "./utils/processAttributeValues";
import { cloneDeep } from "lodash";
import buildAttributesByVariants from "../../../plugins/products/helpers/buildAttributesByVariants";

const dontAskDefaults = {
  addAttribute: false,
  updateAttribute: false,
  deleteAttribute: false,
  deleteVariant: false,
};

export const ProductVariantsContext = createContext({
  openEditor: false,
  saving: false,
  isHideAttributeList: false,
  attributeValues: [],
  addMode: false,
  selectedVariants: [],
  variantAttributes: [],
  variants: [],
  originVariants: [],
  originVariantParams: {},
  variantsHaveChanged: false,
  dontAsk: dontAskDefaults,
  isChanged: false,
  media: [],
  onSetOpenEditor: (val) => {},
  onSetSaving: (val) => {},
  toggleHideAttributeList: () => {},
  onCreateAttribute: (id, value) => {},
  onUpdateAttribute: (id, newValue, newAttrId) => {},
  onDeleteAttribute: (id) => {},
  onChangeAddMode: (val) => {},
  onSelectVariant: (id) => {},
  onSelectAllVariants: (allIds) => {},
  onDeselectAllVariants: () => {},
  onSetVariantAttributes: (val, id) => {},
  onSetVariants: (val) => {},
  onCancelChanges: () => {},
  onSetOriginVariantParams: (val) => {},
  onUpdateOriginVariantParam: (field, val) => {},
  onUpdateVariantValue: (id, fieldName, newValue) => {},
  onRemoveVariants: (selectedIds) => {},
  onSetDontAsk: (field, value) => {},
  onSetOriginVariants: (val) => {},
  onSetAttributeValues: (val) => {},
  onSetMedia: (val) => {},
  onUpdateMediaPriority: (id, priority) => {},
  onRefetch: (newOriginVariants) => {},
});

export const ProductVariantsProvider = ({ children }) => {
  const [openEditor, setOpenEditor] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isHideAttributeList, setIsHideAttributeList] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [attributeValues, setAttributeValues] = useState([]); // добавленные атрибуты, готовы для создания вариантов
  /*
    attributeValues type
    [{
      id: внутренний уникальный id
      attributeId: айдишник атрибута с бэка, на основе которого создан атрибут
      attributeName: название атрибута
      inputType: тип данных
      value: значение (разный тип, в зависимости от типа данных)
    }]

    !!! при каждом изменении или добавлении / удалении атрибута нужно заново создавать таблицу вариантов
 */
  const [variantAttributes, setVariantAttributes] = useState([]); // api: product.productTypeObject.variantAttributes
  const [variants, setVariants] = useState([]); // сгенерированные варианты, на основе атрибутов в левой колонке
  const [variantsHaveChanged, setVariantsHaveChanged] = useState(false);
  /*
    variantsHaveChanged
    Нужно для вывода варнингов перед генерацией
    Меняется на true, если юзер внес изменение через инпуты в таблицу variants, либо удалил вариант
 */
  const [isChanged, setIsChanged] = useState(false); // любые изменения, совершенные юзером
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [media, setMedia] = useState([]); // api: products.media (привязаны к продуктам или вариантам)
  const [originVariants, setOriginVariants] = useState([]); // api: product.variants, отсюда берутся значения, когда юзер нажимает cancel в редакторе
  const [originVariantParams, setOriginVariantParams] = useState({}); // api: product.variants[0] параметры по умолчанию для генерации новых вариантов
  const [dontAsk, setDontAsk] = useState(dontAskDefaults);

  const onSetOpenEditor = useCallback((val) => {
    setOpenEditor(val);
  }, []);

  const onSetSaving = useCallback((val) => {
    setSaving(val);
  }, []);

  const onSetDontAsk = useCallback((field, value) => {
    setDontAsk((prev) => ({
      ...prev,
      [field]: value,
    }));
  }, []);

  const onSetOriginVariants = useCallback((val) => {
    setOriginVariants(val);
  }, []);

  const onSetMedia = useCallback((val) => {
    setMedia(val);
  }, []);
  
  const onUpdateMediaPriority = useCallback((id, priority) => {
    setMedia((prev) => prev.map(item => {
      if (item._id === id) {
        return {
          ...item,
          priority,
        }
      }
      return item;
    }))
  }, [media]);

  const generateVariants = useCallback((actualValues) => {
    // console.log('generate from', { actualValues, originVariantParams });
    // часть варианта, которая будет дублироваться для каждого созданного варианта
    const commonVariant = {
      ...originVariantParams,
      attributeValues: [],
    };

    const complexTypes = ['list', 'colorPicker', 'datePicker', 'checkbox'];

    // атрибуты, у которых значение является строкой или числом - из них может получиться только 1 вариант
    const simpleValues = [];
    // атрибуты, у которых значение является массивом или булевым - из них могут получиться комбинированные варианты
    const complexValues = [];

    actualValues.forEach((item) => {
      if (complexTypes.includes(item.inputType)) {
        complexValues.push(item);
      } else {
        simpleValues.push(item);
      }
    });

    commonVariant.attributeValues = simpleValues.map((item) => ({
      attributeId: item.attributeId,
      attributeName: item.attributeName,
      inputType: item.inputType,
      value: item.value,
    }));

    const combinedValues = processAttributeValues(complexValues);

    // console.log({
    //   commonVariant,
    //   combinedValues,
    // });

    const result = [];

    if (combinedValues.length) {
      combinedValues.forEach((combination) => {
        result.push({
          id: v4(),
          ...commonVariant,
          attributeValues: [
            ...commonVariant.attributeValues,
            ...combination,
          ],
        })
      });
    } else {
      result.push({
        id: v4(),
        ...commonVariant,
        attributeValues: [
          ...commonVariant.attributeValues,
        ],
      })
    }

    // console.log('generate result', result);
    onSetVariants(result);
    setVariantsHaveChanged(false);
    setIsChanged(true);
  }, [originVariantParams]);

  const onChangeAddMode = useCallback((val) => {
    setAddMode(val);
  }, []);

  const toggleHideAttributeList = useCallback(() => {
    setIsHideAttributeList(prev => !prev);
  }, []);

  const onCreateAttribute = useCallback((id, value) => {
    const productAttribute = variantAttributes.find((d) => d.attributeId === id);
    setAttributeValues((prev) => {
      const result = [
        ...prev,
        {
          id: v4(),
          attributeId: id,
          attributeName: productAttribute?.attribute.name || 'Attribute',
          inputType: productAttribute?.attribute.inputType,
          value,
        }
      ];
      generateVariants(result);
      return result;
    });
  }, [variantAttributes, variants, generateVariants]);

  const onUpdateAttribute = useCallback((id, newValue, newAttrId) => {
    const productAttribute = variantAttributes.find((d) => d.attributeId === newAttrId);
    setAttributeValues((prev) => {
      const result = prev.map((item) => {
        if (item.id === id) {
          return {
            id: item.id,
            attributeId: newAttrId,
            attributeName: productAttribute?.attribute.name || 'Attribute',
            inputType: productAttribute?.attribute.inputType,
            value: newValue,
          };
        }
        return item;
      });
      generateVariants(result);
      return result;
    });
  }, [generateVariants, attributeValues, variantAttributes]);

  const onDeleteAttribute = useCallback((id) => {
    setAttributeValues((prev) => {
      const result = prev.filter((item) => item.id !== id);
      generateVariants(result);
      return result;
    });
  }, [generateVariants]);

  const onSelectVariant = useCallback((id) => {
    const newArr = toggleArrayValues(selectedVariants, id);
    setSelectedVariants(newArr);
  }, [selectedVariants]);

  const onSelectAllVariants = useCallback((allIds) => {
    setSelectedVariants(allIds);
  }, []);

  const onDeselectAllVariants = useCallback(() => {
    setSelectedVariants([]);
  }, []);

  const onSetVariantAttributes = useCallback((val) => {
    setVariantAttributes(val);
  }, []);

  const onSetVariants = useCallback((val) => {
    setVariants(val);
  }, []);

  const onUpdateVariantValue = useCallback((id, fieldName, newValue) => {
    const index = variants.findIndex((d) => d.id === id);
    if (index > -1) {
      setVariants((prev) => {
        const newVariants = [...prev];
        newVariants[index][fieldName] = newValue;
        return newVariants;
      });
      setVariantsHaveChanged(true);
      setIsChanged(true);
    }
  }, [variants]);

  const onRemoveVariants = useCallback((selectedIds) => {
    setVariants((prev) => {
      const newVariants = prev.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            isDelete: true,
          }
        }
        return item;
      });
      setAttributeValues(buildAttributesByVariants(newVariants));
      return newVariants;
    });
    setSelectedVariants((prev) => prev.filter((k) => !selectedIds.includes(k)));
    setVariantsHaveChanged(true);
    setIsChanged(true);
  }, []);

  const onSetOriginVariantParams = useCallback((val) => {
    setOriginVariantParams(val);
  }, []);

  const onUpdateOriginVariantParam = useCallback((field, val) => {
    setOriginVariantParams((prev) => ({
      ...prev,
      [field]: val,
    }));
  }, []);

  const onSetAttributeValues = useCallback((val) => {
    setAttributeValues(val);
  }, []);

  const onCancelChanges = useCallback(() => {
    setIsHideAttributeList(false);
    setSelectedVariants([]);
    const newVariants = cloneDeep(originVariants);
    setVariants(newVariants);
    setAttributeValues(buildAttributesByVariants(newVariants));
    setVariantsHaveChanged(false);
    setIsChanged(false);
  }, [originVariants]);

  const onRefetch = useCallback((newOriginVariants) => {
    setIsHideAttributeList(false);
    setSelectedVariants([]);
    setVariantsHaveChanged(false);
    setIsChanged(false);
    onSetOriginVariants(newOriginVariants);
  }, []);

  const value = useMemo(() => {
    return {
      openEditor,
      onSetOpenEditor,
      saving,
      onSetSaving,
      isHideAttributeList,
      attributeValues,
      toggleHideAttributeList,
      onCreateAttribute,
      onUpdateAttribute,
      onDeleteAttribute,
      addMode,
      onChangeAddMode,
      selectedVariants,
      onSelectVariant,
      onSelectAllVariants,
      onDeselectAllVariants,
      variantAttributes,
      onSetVariantAttributes,
      variants,
      onSetVariants,
      onCancelChanges,
      originVariantParams,
      onSetOriginVariantParams,
      onUpdateVariantValue,
      onRemoveVariants,
      variantsHaveChanged,
      dontAsk,
      onSetDontAsk,
      originVariants,
      onSetOriginVariants,
      onSetAttributeValues,
      onRefetch,
      isChanged,
      media,
      onSetMedia,
      onUpdateMediaPriority,
      onUpdateOriginVariantParam,
    }
  }, [
    openEditor,
    onSetOpenEditor,
    saving,
    onSetSaving,
    isHideAttributeList,
    attributeValues,
    toggleHideAttributeList,
    onCreateAttribute,
    onUpdateAttribute,
    onDeleteAttribute,
    addMode,
    onChangeAddMode,
    selectedVariants,
    onSelectVariant,
    onSelectAllVariants,
    onDeselectAllVariants,
    variantAttributes,
    onSetVariantAttributes,
    variants,
    onSetVariants,
    onCancelChanges,
    originVariantParams,
    onSetOriginVariantParams,
    onUpdateVariantValue,
    onRemoveVariants,
    variantsHaveChanged,
    dontAsk,
    onSetDontAsk,
    originVariants,
    onSetOriginVariants,
    onSetAttributeValues,
    onRefetch,
    isChanged,
    media,
    onSetMedia,
    onUpdateMediaPriority,
    onUpdateOriginVariantParam,
  ]);

  return (
    <ProductVariantsContext.Provider value={value}>
      { children }
    </ProductVariantsContext.Provider>
  )
};
