import React, { useContext, useEffect, useState } from "react";
import { FormHeaderText, FormHeaderWrap, WosqDescriptionText, WosqHeading } from "../styles";
import LayoutContent from "../layouts/LayoutContent";
import LayoutForm from "../layouts/LayoutForm";
import { Form } from "../../../../UI/Form/MyForm";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Controller, useForm } from "react-hook-form";
import { CompleteRegSchema } from "../schemas";
import { Country } from "country-state-city";
import InputLabel from "../../../../UI/Form/InputLabel";
import FormInput from "../../../../UI/Form/FormInput";
import Select from "../../../../UI/Select/Select";
import { Grid } from "@material-ui/core";
import CustomButton from "../../../../UI/Button";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../context/AuthContext";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const PageComplete = () => {
  const [countriesList, setCountriesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation("reg");

  const { user } = useContext(AuthContext);

  const { getJwtToken } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(CompleteRegSchema),
    defaultValues: {
      companyName: "",
      country: "",
      address: "",
      address2: "",
      city: "",
      postcode: "",
    },
  });

  useEffect(() => {
    const countries = Country.getAllCountries().map(item => ({
      name: item.name,
      value: item.isoCode,
    }));
    setCountriesList(countries);
  }, []);

  const onSubmit = formData => {
    if (!user) {
      return;
    }

    setLoading(true);

    Auth.updateUserAttributes(user, {
      profile: formData.companyName,
      locale: countriesList.find(item => item.value === formData.country).name,
      "custom:city": formData.city,
      "custom:postcode": formData.postcode,
      address: formData.address,
      ...(formData.address2
        ? {
            "custom:address_2": formData.address2,
          }
        : {}),
    })
      .then(res => {
        if (res === "SUCCESS") {
          return getJwtToken();
        }
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <LayoutContent>
      <WosqHeading>{t("complete_reg")}</WosqHeading>
      <WosqDescriptionText> {t("complete_reg_finalize")}</WosqDescriptionText>
      <LayoutForm>
        <FormHeaderWrap>
          <FormHeaderText> {t("get_ahead")}</FormHeaderText>
        </FormHeaderWrap>
        <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
          <InputLabel error={errors.companyName} disabled={loading}>
            {t("company_name")}
          </InputLabel>
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                placeholder= {t("write_your_company")}
                error={errors.companyName}
                disabled={loading}
              />
            )}
          />

          <InputLabel error={errors.country} disabled={loading}>
            {t("country")}
          </InputLabel>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <Select
                placeholder={t("country")}
                value={field.value}
                onChange={field.onChange}
                error={errors.country}
                disabled={loading}
                options={countriesList}
              />
            )}
          />

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputLabel error={errors.city} disabled={loading}>
                {t("city")}
              </InputLabel>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    placeholder={t("city")}
                    disabled={loading}
                    error={errors.city}
                    marginError="-8px 0 10px 0"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel error={errors.postcode} disabled={loading}>
                {t("postcode")}
              </InputLabel>
              <Controller
                name="postcode"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    placeholder={t("postcode")}
                    disabled={loading}
                    error={errors.postcode}
                    marginError="-8px 0 10px 0"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputLabel error={errors.address} disabled={loading}>
                {t("address")} 1
              </InputLabel>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    placeholder={t("write_your_address")}
                    disabled={loading}
                    error={errors.address}
                    marginError="-8px 0 10px 0"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel disabled={loading} optionalText="optional">
                {t("address")} 2
              </InputLabel>
              <Controller
                name="address2"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    placeholder={t("write_your_address")}
                    disabled={loading}
                    marginError="-8px 0 10px 0"
                  />
                )}
              />
            </Grid>
          </Grid>

          <DialogButtons justifyButtons="flex-end" marginTop="16px">
            <CustomButton width="170px" type="submit" disabled={!isDirty || loading}>
              {t("ui.next")}
            </CustomButton>
          </DialogButtons>
        </Form>
      </LayoutForm>
    </LayoutContent>
  );
};

export default PageComplete;
