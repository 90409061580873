import React, { useContext, useEffect, useState } from "react";
import PrimaryAppBar from "../../../package/src/PrimaryAppBar/PrimaryAppBar";
import { Tab } from "@material-ui/core";
import FormPackageInformation from "./FormPackageInformation";
import MapTab from "../../shops/components/map/MapTab";
import styled from 'styled-components';
import ConfiguratorTab from "./ConfiguratorTab";
import { useTranslation } from "react-i18next";
import TabsLarge from "../../../UI/Tabs/TabsLarge";
import useZones from "../../../package/src/hooks/useZones";
import { ShopEditContext } from "../../../package/src/context/ShopEditContext";

const StyledDiv = styled.div`
  margin-top: 34px;
`

const SubscriptionDetails = ({ match, history }) => {
  const { t } = useTranslation();

  const { resetState } = useContext(ShopEditContext);

  const packageId = match?.params.packageId;

  const [value, setValue] = useState('info');

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  useZones();

  useEffect(() => {
    return () => {
      resetState();
    }
  }, []);

  const renderTab = () => {
    switch (value) {
      case 'info': return <FormPackageInformation
        packageId={packageId}
      />;
      case 'map': return <MapTab
        packageId={packageId}
        isSubscriptionMode={true}
      />
      case 'conf': return <ConfiguratorTab
        packageId={packageId}
      />;
      default: return null;
    }
  }

  return (
    <>
      <PrimaryAppBar
        title={packageId ? t('subscription.edit_subscription_package') : t('subscription.add_subscription_package')}
        onBackButtonClick={() => {
          history.push('/subscription-packages');
        }}
      />

      <TabsLarge
        value={value}
        onChange={handleChange}
      >
        <Tab label={t('subscription.information')} value='info'/>
        <Tab label={t('subscription.map')} value='map' disabled={!packageId}/>
        <Tab label={t('configurator.3d_configurator')} value='conf' disabled={!packageId}/>
      </TabsLarge>

      <StyledDiv>
        {renderTab()}
      </StyledDiv>
    </>
  );
}

export default SubscriptionDetails;
