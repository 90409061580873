import gql from "graphql-tag";

export const allProductCategoriesQuery = gql`
  query productCategories($productCategoryIds: [ID]!, $first: ConnectionLimitInt, $last: ConnectionLimitInt) {
    productCategories(productCategoryIds: $productCategoryIds, first: $first, last: $last) {
      nodes {
        _id
        name
      }
    }
  }
`;