import React, { useContext, useEffect, useMemo, useState } from "react";
import Select from "../../../../../UI/Select/Select";
import { ProductVariantsContext } from "../../../../../package/src/context/ProductVariantsContext";
import EditAutocomplete from "./EditAutocomplete";
import EditColor from "./EditColor";
import EditDate from "./EditDate";
import EditString from "./EditString";
import EditText from "./EditText";
import EditNumber from "./EditNumber";
import CustomButton from "../../../../../UI/Button";
import DialogButtons from "../../../../../UI/Dialog/DialogButtons";
import { v4 } from "uuid";
import styled from "styled-components";
import svgDelete from "../../../../../icons/delete.svg";
import InputLabelWithButton from "../common/InputLabelWithButton";
import trimAttributeValue from "../../../helpers/trimAttributeValue";

const Wrapper = styled('div')`
  width: 100%;
  padding-top: 24px;
`;

const getInputOptions = (attributes) => {
  return attributes.map((item) => ({
    value: item.attributeId,
    name: item.attribute.name,
    required: item.isRequired,
  }));
};

/**
 * attributeId - editable attribute's id, from the server
 * attributeValue - the value of this attribute
 * isInit - first attribute addition mode
 * onConfirm - confirmation of changes
 * onCancel - cancel changes
 *
 * Adding mode: attributeId === undefined, attributeValue === undefined
 * Edit mode: attributeId === string, attributeValue is truthy
 * First attribute addition mode: attributeId === undefined, attributeValue === undefined, isInit === true
 * @param attributeId
 * @param attributeValue
 * @param isInit
 * @param onConfirm
 * @param onCancel
 * @param onDelete
 * @returns {JSX.Element}
 * @constructor
 */

const AttributeEditForm = ({ attributeId, attributeValue, isInit, onConfirm, onCancel, onDelete }) => {
  const [isValid, setIsValid] = useState(false);
  const [id, setId] = useState('');
  const [stack, setStack] = useState({});

  const { variantAttributes, attributeValues, saving } = useContext(ProductVariantsContext);

  useEffect(() => {
    if (attributeId && attributeValue !== undefined) {
      setId(attributeId);
      setStack({
        [attributeId]: attributeValue,
      });
    }
  }, [attributeId, attributeValue]);

  /**
   * Do not show already created attributes
   * Always show the currently edited attribute
   * @type {*[]}
   */

  const filteredAttributes = useMemo(() => {
    const createdAttributeIds = attributeValues
      .map((item) => item.attributeId)
      .filter((id) => id !== attributeId);
    return variantAttributes.filter((item) => !createdAttributeIds.includes(item.attributeId));
  }, [attributeId, variantAttributes, attributeValues]);

  const handleSetStack = (val, isValidValue) => {
    setIsValid(isValidValue);
    setStack((prev) => ({
      ...prev,
      [id]: val,
    }));
  };
  
  const handleChangeAttribute = (e) => {
    if (isInit) {
      setStack({});
    }
    setId(e.target.value);
  };

  const selectedInput = filteredAttributes.find((item) => item.attributeId === id);

  const handleConfirm = () => {
    const trimmedValue = trimAttributeValue(selectedInput?.attribute?.inputType, stack[id]);
    onConfirm(id, trimmedValue);
  };

  const getInputComponent = () => {
    switch(selectedInput?.attribute?.inputType) {

      case 'string': {
        if (stack[id] === undefined) {
          handleSetStack('', false);
          return null;
        }
        return <EditString
          value={stack[id]}
          onChange={handleSetStack}
        />;
      }

      case 'text': {
        if (stack[id] === undefined) {
          handleSetStack('', false);
          return null;
        }
        return <EditText
          value={stack[id]}
          onChange={handleSetStack}
        />;
      }

      case 'number': {
        if (stack[id] === undefined) {
          handleSetStack('', false);
          return null;
        }
        return <EditNumber
          value={stack[id]}
          onChange={handleSetStack}
        />;
      }

      case 'list': {
        if (stack[id] === undefined) {
          handleSetStack([], false);
          return null;
        }
        const options = selectedInput.attribute.inputConfig.allowedValues.map((item) => ({
          value: item,
          label: item,
        }));
        return <EditAutocomplete
          value={stack[id]}
          onChange={handleSetStack}
          options={options}
        />;
      }

      case 'checkbox': {
        if (stack[id] === undefined) {
          handleSetStack([true, false], true);
        }
        return null;
      }

      case 'colorPicker': {
        if (stack[id] === undefined) {
          handleSetStack([{
            id: v4(),
            hex: '',
            name: '',
          }], false);
          return null;
        }
        return <EditColor
          value={stack[id]}
          onChange={handleSetStack}
        />;
      }

      case 'datePicker': {
        if (stack[id] === undefined) {
          handleSetStack([{
            id: v4(),
            date: null,
            name: '',
          }], false);
          return null;
        }
        return <EditDate
          value={stack[id]}
          onChange={handleSetStack}
        />;
      }

      default: return null;
    }
  };

  return (
    <Wrapper>
      {
        attributeId && attributeValue ?
          <InputLabelWithButton
            onButtonClick={onDelete}
            ButtonIconComponent={() => <img src={svgDelete} alt='delete' />}
          >
            Attribute
          </InputLabelWithButton> :
          <InputLabelWithButton>
            Attribute
          </InputLabelWithButton>
      }

      <Select
        placeholder='Select an attribute'
        value={id}
        onChange={handleChangeAttribute}
        options={getInputOptions(filteredAttributes)}
      />
      {
        getInputComponent()
      }
      <DialogButtons marginTop='0' >
        <CustomButton
          width='100%'
          sx={{ height: '40px' }}
          disabled={!id || !isValid || saving}
          handleClick={handleConfirm}
        >
          { isInit ? 'Done' : (attributeId ? 'Save Changes' : 'Add Attribute')}
        </CustomButton>
        {
          !isInit &&
          <CustomButton
            width='100%'
            sx={{ height: '40px' }}
            mytype='secondary'
            handleClick={onCancel}
            disabled={saving}
          >
            Cancel
          </CustomButton>
        }
      </DialogButtons>
    </Wrapper>
  );
};

export default AttributeEditForm;
