import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import SelectAllAutocomplete from "../SelectAllAutocomplete";
import { allProductCategoriesQuery } from "./graphql/queries";

/**
 * CategorySelectorWithData
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function CategorySelectorWithData({onChange, value, ...other}) {
  const [list, setList] = useState([]);

  const { data } = useQuery(allProductCategoriesQuery, {
    variables: {
      productCategoryIds: [],
      first: 200,
    },
    fetchPolicy: "network-only",
    onError: (e) => {
      console.log(e.message);
    }
  });

  const handleChange = (val) => {
    onChange(val.map(item => item.value));
  }

  useEffect(() => {
    if (data) {
      const options = data.productCategories.nodes;
      const result = options.map(category => ({
          value: category._id,
          label: category.name,
          selected: value.includes(category._id),
        }));
      setList(result);
    }
  }, [data, value])

  return (
    <SelectAllAutocomplete
      items={list}
      onChange={handleChange}
      {...other}
    />
  );
}

export default CategorySelectorWithData;
