import { createContext, useCallback, useMemo, useState } from "react";

/*
  ГЛОССАРИЙ
  Пакет подписки (далее "пакет") - тариф, который покупает юзер: based, advanced и т.п. См. админское меню Subscription Packages
  Подписка - купленный (или покупаемый) компанией пакет для управления одним магазином
  Актуальная подписка - подписка, которая годится для создания магазина (статус open)

  СТАТУСЫ ПОДПИСОК
  open - компания оформила и оплатила подписку. По этой подписке можно создать магазин
  taken - компания оформила и оплатила подписку и создала по ней магазин. Создать магазин нельзя
  pending - компания оформила подписку, но не оплатила. Ждём оплаты, создать магазин нельзя. После оплаты становится open
  нет подписок (приходит пустой массив) - показать экран со всеми пакетами

  ПРАВИЛА
  * Новая подписка сразу берет статус open, если итоговая стоимость пакета = 0
  * У одной компании единовременно может быть только одна "не taken" подписка.
  * 1 магазин = 1 подписка.
  * На один пакет может быть несколько подписок.

  СОЗДАНИЕ НОВОГО МАГАЗИНА
  Есть pending: показать экран "ждем оплату"
  Есть open, нет pending: можно создать магазин, нельзя создать подписку (магазин записывать в актуальную подписку)
  Нет open, нет pending: показать экран со всеми пакетами

  ПОКАЗ ВКЛАДОК
  Subscription: всегда
  Information: скрывать в режиме создания, если нет актуальной подписки
  Map, Configuration: скрывать в режиме создания
*/

export const ShopEditContext = createContext({
  loading: false,
  setLoading: (val) => {},
  currentTab: null,
  setCurrentTab: (val) => {},
  companyId: null,
  setCompanyId: (val) => {},
  packageId: null,
  setPackageId: (val) => {},
  packages: [],
  setPackages: (val) => {},
  selectedPackageId: null,
  setSelectedPackageId: (val) => {},
  subscriptionId: null,
  setSubscriptionId: (val) => {},
  paymentStatus: null,
  setPaymentStatus: (val) => {},
  needRefetch: false,
  setNeedRefetch: (val) => {},
  processing: false,
  setProcessing: (val) => {},
  showOnlyPackageId: null,
  setShowOnlyPackageId: (val) => {},
  paymentError: false,
  setPaymentError: (val) => {},
  paymentPending: false,
  setPaymentPending: (val) => {},
  openSubscription: null,
  setOpenSubscription: (val) => {},
  quiz: {},
  onQuizChange: (key, val) => {},
  selectedPaymentMethod: null,
  setSelectedPaymentMethod: (val) => {},
  zoneId: '',
  setZoneId: (val) => {},
  zoneOptions: [],
  setZoneOptions: (val) => {},
  resetState: () => {},
});

export const ShopEditProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(null);

  const [companyId, setCompanyId] = useState(null);
  // если мы зашли под админом в режиме редактирования магазина, то сначала запрашивается id компании этого магазина
  // в противном случае используется id компании текущего юзера

  const [packageId, setPackageId] = useState(null);
  // id пакета юзера, приходит с бэка

  const [packages, setPackages] = useState([]);
  // общий список пакетов

  const [selectedPackageId, setSelectedPackageId] = useState(null);
  // отвечает за выбранный id пакета и показ его в sub. Если null, то показать список всех пакетов

  const [subscriptionId, setSubscriptionId] = useState(null);
  // id актуальной подписки из subscriptionHistoryRecords юзера, которая нужна для добавления с помощью addSubscription
  // не путать с packageId !

  const [paymentStatus, setPaymentStatus] = useState(null);
  /*
  стейт используется в компоненте Payment - какую вкладку показывать
  null - нет подписок, показать данные для оплаты
  pending - ожидание оплаты пакета, показать ожидание оплаты
  open - показать "успешно прошел платеж, создать магазин"
  taken - магазин занят, показать данные для оплаты
   */

  const [needRefetch, setNeedRefetch] = useState(false);
  /*
  Отложенный рефетч
  Становится true после добавлении подписки методом addSubscription
  Рефетч будет произведен при нажатии на вкладку Information
  После рефетча стейт сразу становится false
   */

  const [processing, setProcessing] = useState(false);
  // loading, процесс оплаты

  const [showOnlyPackageId, setShowOnlyPackageId] = useState(null);
  // Если есть open-подписка, и мы зашли редактировать магазин, то нужно показать только указанный пакет
  // Если сюда назначить невалидный id, например '111', то пропадут все карточки, кроме active plan

  const [paymentError, setPaymentError] = useState(false);
  // Если у юзера не прошёл платеж, то на вкладке Payment показывать текст "ошибка, обратитесь к админу"

  const [paymentPending, setPaymentPending] = useState(false);
  // У юзера по какой-то причине pending статус. Показывать надпись "обратитесь к админу"

  const [openSubscription, setOpenSubscription] = useState(null);
  // Используется для:
  // 1. Проверки open подписки при создании подписки
  // 2. Показывать ли кнопки Create shop / change plan сразу после изменения или покупки подписки.

  const [quiz, setQuiz] = useState({
    eCommerce: null,
    experience: null,
    step: 1,
  });
  /*
  eCommerce: null | true | false;
  experience: null | '2d' | '3d';
  step: number
   */

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  // cart | applePay | googlePay | payPal | shopPay | stripe

  const [zoneId, setZoneId] = useState('');
  const [zoneOptions, setZoneOptions] = useState([]);

  const onQuizChange = useCallback((key, val) => {
    setQuiz((prev) => ({
      ...prev,
      [key]: val,
    }))
  }, []);

  const resetState = useCallback(() => {
    setLoading(true);
    setCurrentTab(null);
    setCompanyId(null);
    setPackageId(null);
    setPackages([]);
    setSelectedPackageId(null);
    setSubscriptionId(null);
    setPaymentStatus(null);
    setNeedRefetch(false);
    setProcessing(false);
    setShowOnlyPackageId(null);
    setPaymentError(false);
    setPaymentPending(false);
    setOpenSubscription(null);
    // quiz
    // onQuizChange
    setSelectedPaymentMethod(null);
    setZoneId('');
    setZoneOptions([]);
  }, []);

  const value = useMemo(() => {
    return {
      loading,
      currentTab,
      companyId,
      packageId,
      selectedPackageId,
      subscriptionId,
      paymentStatus,
      needRefetch,
      processing,
      showOnlyPackageId,
      paymentError,
      paymentPending,
      openSubscription,
      setCompanyId,
      setLoading,
      setCurrentTab,
      setOpenSubscription,
      setPaymentStatus,
      setPaymentPending,
      setPackageId,
      setSelectedPackageId,
      setSubscriptionId,
      setNeedRefetch,
      setProcessing,
      setShowOnlyPackageId,
      setPaymentError,
      packages,
      setPackages,
      resetState,
      quiz,
      onQuizChange,
      selectedPaymentMethod,
      setSelectedPaymentMethod,
      zoneId,
      setZoneId,
      zoneOptions,
      setZoneOptions,
    }
  }, [
    loading,
    currentTab,
    companyId,
    packageId,
    selectedPackageId,
    subscriptionId,
    paymentStatus,
    needRefetch,
    processing,
    showOnlyPackageId,
    paymentError,
    paymentPending,
    openSubscription,
    setCompanyId,
    setLoading,
    setCurrentTab,
    setOpenSubscription,
    setPaymentStatus,
    setPaymentPending,
    setPackageId,
    setSelectedPackageId,
    setSubscriptionId,
    setNeedRefetch,
    setProcessing,
    setShowOnlyPackageId,
    setPaymentError,
    packages,
    setPackages,
    resetState,
    quiz,
    onQuizChange,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    zoneId,
    setZoneId,
    zoneOptions,
    setZoneOptions,
  ])

  return (
    <ShopEditContext.Provider value={value}>
      { children }
    </ShopEditContext.Provider>
  )
};
