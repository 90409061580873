import React, { useContext, useState } from "react";
import styled from "styled-components";
import svgEdit from '../../../../../icons/variantEditor/edit.svg';
import HeadingText from "../common/HeadingText";
import { Button, Typography } from "@material-ui/core";
import { getStringFromArray } from "../../../../../package/src/utils/getStringFromArray";
import AttributeEditForm from "../AttributeEdit/AttributeEditForm";
import { ProductVariantsContext } from "../../../../../package/src/context/ProductVariantsContext";
import DialogMessages from "../../../../../UI/Dialog/DialogMessages";
import CheckboxContainer from "../../../../../UI/Checkbox/CheckboxContainer";
import Checkbox from "../../../../../UI/Checkbox/Checkbox";
import DialogButtons from "../../../../../UI/Dialog/DialogButtons";
import CustomButton from "../../../../../UI/Button";
import Dialog from "../../../../../UI/Dialog/Dialog";
import { useTranslation } from "react-i18next";

const Wrapper = styled('div')`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: max-content 1fr 40px;
  padding: 16px 0;
  border-bottom: 1px solid #E9E9E9;
`;

const AttributeBox = styled('div')`
  padding: 9px 16px 0 0;
  word-break: break-word;
  max-width: 140px;
`;

const ValuesText = styled(Typography)`
  color: #9A9A9A;
  font-family: Mulish, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding-top: 13px;
  word-break: break-word;
`;

const EditButton = styled(Button)`
  height: 40px;
  padding: 10px;
  min-width: unset;

  &:hover {
    background: none;
  }
`;

const getValue = (data) => {
  switch(data.inputType) {
    case 'checkbox': return '';
    case 'list': return getStringFromArray(data.value);
    case 'datePicker':
    case 'colorPicker': return getStringFromArray(data.value.map((color) => color.name));
    default: return data.value;
  }
};

const AttributeRow = ({ data }) => {
  const [edit, setEdit] = useState(false);
  const [dialogName, setDialogName] = useState(null); // updateAttribute, deleteAttribute
  const [openDialog, setOpenDialog] = useState(false);
  const [addId, setAddId] = useState('');
  const [addValue, setAddValue] = useState('');

  const { t } = useTranslation();

  const {
    onUpdateAttribute,
    onDeleteAttribute,
    saving,
    dontAsk,
    onSetDontAsk,
    variantsHaveChanged,
  } = useContext(ProductVariantsContext);

  const handleEditModeOn = () => {
    setEdit(true);
  };

  const handleEditModeOff = () => {
    setEdit(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeDontAsk = () => {
    onSetDontAsk(dialogName, !dontAsk[dialogName]);
  };

  const initSaveDialog = (id, value) => {
    if (variantsHaveChanged && !dontAsk.updateAttribute) {
      setDialogName('updateAttribute');
      setOpenDialog(true);
      setAddId(id);
      setAddValue(value);
    } else {
      onUpdateAttribute(data.id, value, id);
      setEdit(false);
    }
  };

  const initDeleteDialog = () => {
    if (variantsHaveChanged && !dontAsk.deleteAttribute) {
      setDialogName('deleteAttribute');
      setOpenDialog(true);
    } else {
      onDeleteAttribute(data.id);
      setEdit(false);
    }
  };

  const handleConfirmChangeAttribute = () => {
    if (dialogName === 'updateAttribute') {
      onUpdateAttribute(data.id, addValue, addId);
      setEdit(false);
    } else if (dialogName === 'deleteAttribute') {
      onDeleteAttribute(data.id);
      setEdit(false);
    }
    handleCloseDialog();
  };

  const getTitle = () => {
    if (dialogName === 'updateAttribute') {
      return 'Attribute changes will affect the composition and number of options. Save Changes?';
    }
    if (dialogName === 'deleteAttribute') {
      return 'If you delete an attribute, you will lose all changes that you have in Variants';
    }
    return '';
  };

  const getButtonText = () => {
    if (dialogName === 'updateAttribute') {
      return t('ui.save_changes');
    }
    if (dialogName === 'deleteAttribute') {
      return t('ui.delete');
    }
    return '';
  };

  return (
    edit ?
      <>
        <AttributeEditForm
          attributeId={data.attributeId}
          attributeValue={data.value}
          onConfirm={initSaveDialog}
          onCancel={handleEditModeOff}
          onDelete={initDeleteDialog}
        />
        <Dialog
          open={openDialog}
          handleClose={handleCloseDialog}
        >
          <DialogMessages
            title={getTitle()}
            titleMaxWidth='348px'
            titleTextAlign='start'
          />
          <CheckboxContainer margin='16px 0 0'>
            <Checkbox
              checked={dontAsk[dialogName]}
              onChange={handleChangeDontAsk}
            />
            Don't ask me again
          </CheckboxContainer>
          <DialogButtons justifyButtons='center'>
            <CustomButton
              width='166px'
              mytype='secondary'
              handleClick={handleCloseDialog}
            >
              {t('ui.cancel')}
            </CustomButton>
            <CustomButton
              width='166px'
              handleClick={handleConfirmChangeAttribute}
            >
              {getButtonText()}
            </CustomButton>
          </DialogButtons>
        </Dialog>
      </> :
      <Wrapper>
        <AttributeBox>
          <HeadingText>{data.attributeName}</HeadingText>
        </AttributeBox>
        <ValuesText>{getValue(data)}</ValuesText>
        <EditButton
          disableRipple
          onClick={handleEditModeOn}
          disabled={saving}
        >
          <img src={svgEdit} alt='' />
        </EditButton>
      </Wrapper>
  );
};

export default AttributeRow;
