import React from "react";
import ColorCircleBorder from "./ColorCircleBorder";
import styled from "styled-components";

const Wrapper = styled('div')`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: relative;
  background: ${props => props.background};
  margin-top: 8px;
`;

const ColorCircle = ({ hex }) => {
  return (
    <Wrapper background={hex}>
      <ColorCircleBorder />
    </Wrapper>
  );
};

export default ColorCircle;
