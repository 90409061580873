import React, { useContext } from "react";
import { FormControlLabel, makeStyles } from "@material-ui/core";
import { StyledColorButton } from "../styles";
import { LanguageContext } from "../../../package/src/context/LanguageContext";
import cn from 'classnames';

const useStyles = makeStyles(() => ({
  colorButtonInside: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "100%",
  },
  rtl: {
    marginRight: '16px',
    marginLeft: '-11px',
  }
}));

const IconFormControlLabel = ({ iconStart, isSelected, InputProps, ...props }) => {
  const classes = useStyles();
  const { isRtl } = useContext(LanguageContext);

  return (
    <StyledColorButton style={isSelected ? { background: "#F3F6FF" } : {}}>
      {iconStart ? iconStart : null}
      <FormControlLabel className={cn(classes.colorButtonInside, {[classes.rtl]: isRtl })}
                        {...props}
      />
    </StyledColorButton>
  );
};

export default IconFormControlLabel;
