import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import PageLogin from "./PageLogin";
import PageSignup from "./PageSignup";
import PageVerifyCode from "./PageVerifyCode";
import PageVerified from "./PageVerified";
import PageComplete from "./PageComplete";
import PageMFACode from "./PageMFACode";
import PageForgotPassword from "./PageForgotPassword";
import PageResetPassword from "./PageResetPassword";
import PageRequiredChangePassword from "./PageRequiredChangePassword";

const PagesList = () => {
  const { page } = useContext(AuthContext);

  switch(page) {
    case 'login': return <PageLogin />;
    case 'signup': return <PageSignup />;
    case 'mfaCode': return <PageMFACode />;
    case 'verifyCode': return <PageVerifyCode />;
    case 'verified': return <PageVerified />;
    case 'complete': return <PageComplete />;
    case 'forgotPassword': return <PageForgotPassword />;
    case 'resetPassword': return <PageResetPassword />;
    case 'requiredChangePassword': return <PageRequiredChangePassword />;
    default: return null;
  }
};

export default PagesList;