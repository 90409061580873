import { useCallback, useContext } from "react";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../context/AuthContext";
import { isEmpty } from "lodash";
import useCheckIsCompleteInfo from "./useCheckIsCompleteInfo";
import awsmobile from "../../../../aws-exports";
import { httpClient } from "../../lib/axios/HttpClient";
import setLocalstorage from "../../utils/setLocalstorage";
import { useHistory, useLocation } from "react-router-dom";

const useLogin = () => {
  const { onSetUser, onSetPage } = useContext(AuthContext);

  const { checkAndRedirect } = useCheckIsCompleteInfo();

  const location = useLocation()
  const history = useHistory()

  const login = useCallback(async (user, email) => {
    if (!user) {
      throw new Error('User not found');
    }

    onSetUser(user);

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      onSetPage('requiredChangePassword');
      return;
    }

    let isMfa = false;
    let savedParsed = {};
    const saved = localStorage.getItem('isMFA');
    if (saved) {
      savedParsed = JSON.parse(saved);
      isMfa = savedParsed[email] && user.preferredMFA === 'NOMFA';
    }

    if (isMfa) {
      await Auth.setPreferredMFA(user, 'SMS');
      const newValue = {...savedParsed};
      delete newValue[email];
      if (isEmpty(newValue)) {
        localStorage.removeItem('isMFA');
      } else {
        localStorage.setItem('isMFA', JSON.stringify(newValue));
      }
    }

    if (user.challengeName === 'SMS_MFA') {
      onSetPage('mfaCode');
      return;
    }

    return checkAndRedirect(user);
  }, []);

  const loginWithGoogle = useCallback(async (code) => {
    if (!code) {
      return Promise.resolve();
    }
    const domain = process.env.REACT_APP_HTTP_GOOGLE_AUTH;
    const clientId = awsmobile.aws_user_pools_web_client_id;
    const redirect = process.env.REACT_APP_PORTAL;
    const url = `${domain}/oauth2/token`;
    const data = `client_id=${clientId}&grant_type=authorization_code&redirect_uri=${redirect}&code=${code}`;
    const tokens = await httpClient.post(url, data);
    const userData = tokens.data;
    const urlInfo = `${domain}/oauth2/userInfo`;
    const result = await httpClient.post(urlInfo, "", {
      headers: {
        Authorization: `Bearer ${userData.access_token}`,
      },
    });
    const sub = result.data?.sub;
    setLocalstorage({
      access_token: userData.access_token,
      id_token: userData.id_token,
      refresh_token: userData.refresh_token,
      sub,
    });

    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('code')) {
      queryParams.delete('code')
      history.replace({
        search: queryParams.toString(),
      })
    }
  }, []);

  return {
    login,
    loginWithGoogle,
  };
};

export default useLogin;
