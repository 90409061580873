import React, { useContext } from "react";
import FormCard from "../../../UI/Form/FormCard";
import FormHeader from "../../../UI/Form/FormHeader";
import { Controller, useFormContext } from "react-hook-form";
import ProductSelectorWithData from "../../../package/src/ProductSelectorWithData";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import { Grid } from "@material-ui/core";
import Select from "../../../UI/Select/Select";
import { useTranslation } from "react-i18next";
import VariantEditor from "../components/VariantEditor";
import { ProductVariantsContext } from "../../../package/src/context/ProductVariantsContext";

const dimensionsUnitOptions = [
  {
    value: 'cm',
    name: 'cm',
  },
  {
    value: 'mm',
    name: 'mm',
  },
  {
    value: 'in',
    name: 'in',
  },
];

const weightUnitOptions = [
  {
    value: 'kg',
    name: 'kg',
  },
  {
    value: 'fn',
    name: 'fn',
  },
];

const BlockAttributes = ({productId, shopId, productTypes, refetchMedia }) => {
  const { t } = useTranslation();

  const {
    control,
    loading,
    formState: { errors }
  } = useFormContext();

  const { onUpdateOriginVariantParam } = useContext(ProductVariantsContext);

  const handleNumberChange = (field, e, func) => {
    let value = e.target.value;
    if (value < 0) value = 0;
    onUpdateOriginVariantParam(field, value);
    func(value);
  };

  const onChangeAttribute = (field, e, func) => {
    onUpdateOriginVariantParam(field, e.target.value);
    func(e);
  };

  return (
    <>
      <FormCard>
        <FormHeader optionalText={t('ui.optional')}>Dimensions and Weight</FormHeader>

        <InputLabel disabled={loading}>Dimensions unit</InputLabel>
        <Controller
          name="dimensionsUnit"
          control={control}
          render={({ field }) => <Select
            value={field.value}
            onChange={(e) => onChangeAttribute('dimensionsUnit', e, field.onChange)}
            options={dimensionsUnitOptions}
            disabled={loading}
          />}
        />

        <InputLabel disabled={loading}>Width</InputLabel>
        <Controller
          name="width"
          control={control}
          render={({ field }) => <FormInput
            type='number'
            value={field.value}
            onChange={(e) => handleNumberChange('width', e, field.onChange)}
            disabled={loading}
          />}
        />

        <InputLabel disabled={loading}>Height</InputLabel>
        <Controller
          name="height"
          control={control}
          render={({ field }) => <FormInput
            type='number'
            value={field.value}
            onChange={(e) => handleNumberChange('height', e, field.onChange)}
            disabled={loading}
          />}
        />

        <InputLabel disabled={loading}>Length</InputLabel>
        <Controller
          name="length"
          control={control}
          render={({ field }) => <FormInput
            type='number'
            value={field.value}
            onChange={(e) => handleNumberChange('length', e, field.onChange)}
            disabled={loading}
          />}
        />

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <InputLabel disabled={loading}>{t('product.weight')}</InputLabel>
            <Controller
              name="weight"
              control={control}
              render={({ field }) => <FormInput
                type='number'
                value={field.value}
                onChange={(e) => handleNumberChange('weight', e, field.onChange)}
                disabled={loading}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel disabled={loading}>Weight unit</InputLabel>
            <Controller
              name="weightUnit"
              control={control}
              render={({ field }) => <Select
                value={field.value}
                onChange={(e) => onChangeAttribute('weightUnit', e, field.onChange)}
                options={weightUnitOptions}
                disabled={loading}
              />}
            />
          </Grid>
        </Grid>
      </FormCard>

      <FormCard>
        <FormHeader>Variants</FormHeader>
        <InputLabel disabled={loading} error={errors.productTypeId}>Product Type</InputLabel>
        <Controller
          name="productTypeId"
          control={control}
          render={({ field }) => <Select
            placeholder='Select Product Type'
            value={field.value}
            onChange={field.onChange}
            options={productTypes?.map((item) => ({
              value: item.id,
              name: item.name,
            })) || [{
              value: field.value || '',
              name: '',
            }]}
            error={errors.productTypeId}
            disabled={Boolean(productId) || loading}
          />}
        />
        {
          productId ?
            <VariantEditor
              productId={productId}
              shopId={shopId}
              refetchMedia={refetchMedia}
            />
            : null
        }
      </FormCard>

      <FormCard>
        <FormHeader>{t('product.related_products')}</FormHeader>
        <Controller
          name="relatedProducts"
          control={control}
          render={({ field }) => <ProductSelectorWithData
            shopIds={shopId}
            excludeIds={[productId]}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
          />}
        />
      </FormCard>
    </>
  );
}

export default BlockAttributes;
