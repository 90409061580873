import React from "react";
import InputLabel from "../../../../../UI/Form/InputLabel";
import FormInput from "../../../../../UI/Form/FormInput";

const EditNumber = ({ value, onChange }) => {
  const handleChange = (e) => {
    const val = e.target.value;
    onChange(e.target.value, Boolean(val));
  }

  return (
    <>
      <InputLabel>Value</InputLabel>
      <FormInput
        placeholder='Type number'
        onChange={handleChange}
        value={value}
        type='number'
      />
    </>
  );
};

export default EditNumber;
