import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {Select} from '@material-ui/core';
import MenuItem from '../MenuItem';
import styled, {css} from 'styled-components';
import {colors} from '../../constants';
import {SvgPagesDown, SvgPagesUp} from '../../icons';
import { makeStyles } from "@material-ui/core/styles";
import { LanguageContext } from "../../package/src/context/LanguageContext";
import { useTranslation } from "react-i18next";

const textCss = css`
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: ${colors.black};
`

const svgDisableCss = css`
  fill: ${colors.brize};
`

const StyledSelect = styled(Select)`
  ${textCss}

  &.MuiInputBase-root {
    .MuiSelect-root {
      padding-right: 36px;
    }
    
    &:before {
      display: none;
    }
    
    .MuiSvgIcon-root {
      width: 14px;
      height: 8px;
      margin-left: -20px;
      pointer-events: none;
    }
    
    .MuiSelect-select:focus {
      background-color: transparent;
    }
    
    &.Mui-focused {
      &:after {
        display: none;
      }
    }
  }
  
  ${props => props.isrtl === 'true' && css`
    &.MuiInputBase-root {
      .MuiSelect-root {
        padding-right: 0;
        padding-left: 36px;
      }

      .MuiSvgIcon-root {
        margin-left: 0;
        margin-right: -20px;
      }
    }
  `}
`

const StyledTablePagination = styled.div`
    width: 100%;
    margin: 8px 0 16px 0;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;

    .pages {
      display: flex;
      gap: 10px;
      align-items: center;
      ${textCss}

      .switchButtons {
        display: flex;
        flex-direction: column;
        gap: 3px;

        button {
          outline: none;
          border: none;
          background: transparent;
          cursor: pointer;
          padding: 0;

          svg {
            width: 14px;
            height: 8px;
          }
        }
      }
    }
`

const StyledButton = styled.button`
  svg path {
    ${props => props.disabled && svgDisableCss}
  }
`

const useStyles = makeStyles(() => ({
  dropdownStyle: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 20px #D8DADF',
    borderRadius: 20,
  }
}))

const CustomTablePagination = ({
  rowsPerPageOptions, 
  pageCount, 
  rowsPerPage, 
  page, 
  onPagePrevious, 
  onPageNext,
  onRowsPerPageChange
}) => {
  const [open, setOpen] = useState(false);
  const isPrevDisable = page <= 0;
  const isNextDisable = page + 1 >= pageCount;
  const classes = useStyles();
  const { isRtl } = useContext(LanguageContext);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <StyledTablePagination>

      <StyledSelect
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={(e) => onRowsPerPageChange(e)}
        value={rowsPerPage}
        IconComponent={() => <SvgPagesDown/>}
        MenuProps={{
          PopoverClasses: {paper: classes.dropdownStyle},
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          getContentAnchorEl: null,
        }}
        isrtl={String(isRtl)}
      >
        {
          rowsPerPageOptions.map((item, index) => <MenuItem
            value={item}
            key={index}
          >
            {item + ` ${t('ui.rows')}`}
          </MenuItem>)
        }
      </StyledSelect>

      <div className="pages">
        <p>{t('ui.page')} {page + 1}</p>
        <div className="switchButtons">
          <StyledButton onClick={onPagePrevious} disabled={isPrevDisable}>
            <SvgPagesUp/>
          </StyledButton>
          <StyledButton onClick={onPageNext} disabled={isNextDisable}>
            <SvgPagesDown/>
          </StyledButton>
        </div>
        <p>{t('ui.of')} {pageCount}</p>
      </div>

    </StyledTablePagination>
  );
}

CustomTablePagination.propTypes = {
  rowsPerPageOptions: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
  pageCount: PropTypes.number.isRequired, 
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPagePrevious: PropTypes.func.isRequired,
  onPageNext: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
}

export default CustomTablePagination;
