import React, { useCallback, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid, Hidden, makeStyles } from "@material-ui/core";
import Search from "../../../UI/Search";
import StyledButton from "../../../UI/Button";
import DataTable from "../../../UI/DataTable/DataTable";
import Dialog from "../../../UI/Dialog/Dialog";
import { useSnackbar } from "notistack";
import { useApolloClient } from "@apollo/react-hooks";
import svgEdit from "../../../icons/table-edit.svg";
import svgDelete from '../../../icons/delete.svg';
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import { useTranslation } from "react-i18next";
import { getStringFromArray } from "../../../package/src/utils/getStringFromArray";
import { queryAllAttributes, queryProductTypes } from "../../product-attributes/graphql/queries";
import useProductTypesColumns from "../hooks/useProductTypesColumns";
import EditProductTypeLayout from "../components/EditProductTypeLayout";

const useStyles = makeStyles(theme => ({
  secondColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
  },
  secondColumnXS: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    margin: '28px 0',
  },
}));

const ProductTypesPage = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowId, setRowId] = useState('');
  const [tableData, setTableData] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const columns = useProductTypesColumns();

  const getAttributes = async () => {
    return apolloClient.query({
      query: queryAllAttributes,
      fetchPolicy: "network-only",
    });
  };

  const getProductTypes = (search) => {
    return apolloClient.query({
      query: queryProductTypes,
      variables: {
        input: {
          filters: {
            name: search,
          }
        }
      },
      fetchPolicy: "network-only",
    });
  }

  const onFetchData = useCallback(({ globalFilter, pageSize }) => {
    setIsLoading(true);

    Promise.allSettled([
      getProductTypes(globalFilter),
      getAttributes(),
    ])
      .then(res => {
        const productTypes = res[0].value.data?.productTypes?.data || [];
        const attributes = res[1].value.data?.attributes?.data || [];
        setTableData(productTypes);
        setAttributes(attributes);
        setPageCount(Math.ceil(productTypes.length / pageSize));
        setTotalCount(productTypes.length);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [t]);

  const productTypesOptions = useMemo(() => [
    {
      value: 'edit',
      label: 'Edit',
      icon: svgEdit,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('edit');
        setDialogOpen(true);
      },
    },
    {
      value: 'delete',
      label: 'Delete',
      icon: svgDelete,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('delete');
        setDialogOpen(true);
      },
    },
  ], [t]);

  const dataProps = useMemo(() => tableData.map((item) => {
    const linkedAttributes = item.variantAttributes?.map((k) => k.attribute.name) || [];
    let shortVersion = [];
    let difference = 0;
    if (linkedAttributes.length > 6) {
      shortVersion = linkedAttributes.slice(0, 6);
      difference = linkedAttributes.length - shortVersion.length;
    }
      return {
        id: item.id,
        name: item.name,
        linkedAttributes: {
          full: getStringFromArray(linkedAttributes),
          short: getStringFromArray(shortVersion),
          difference,
        },
        options: productTypesOptions,
      }
    }), [tableData, attributes, productTypesOptions]);

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const renderNoButton = useMemo(() =>
      <StyledButton
        width='180px'
        mytype='secondary'
        handleClick={() => setDialogOpen(false)}
      >
        {t('ui.no')}
      </StyledButton>
    , [t])

  const DialogDeleteSingle = useMemo(() => {
    return (
      <>
        <DialogMessages
          title='Delete product type?'
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              setDialogOpen(false);
              // todo
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [rowId, t])

  const handleCreateProductType = () => {
    setRowId('');
    setDialogName('create');
    setDialogOpen(true);
  }

  const getDialogComponent = () => {
    switch(dialogName) {
      case 'delete': return DialogDeleteSingle;
      case 'create':
      case 'edit': return <EditProductTypeLayout
        productType={tableData.find((k) => k.id === rowId)}
        attributes={attributes}
        loading={isLoading}
        setLoading={setIsLoading}
        onClose={() => {
          setDialogOpen(false);
          refetch();
        }}
      />;
      default: return null;
    }
  };

  const renderSecondColumn = (myClass) => {
    return <Grid item xs={12} lg={3} className={classes[myClass]}>
      <StyledButton
        onClick={handleCreateProductType}
      >
        Create Product Type
      </StyledButton>
    </Grid>
  }

  return (
    <>
      <Grid container>
        <Toolbar title='Product Types' />

        <Grid item xs={12} lg={9}>
          <Search
            handleChange={handleSearch}
            onSearchClick={refetch}
            placeholder={t('ui.search')}
            useDebounce
          />
        </Grid>

        <Hidden mdDown>
          {renderSecondColumn('secondColumn')}
        </Hidden>

        <Hidden lgUp>
          {renderSecondColumn('secondColumnXS')}
        </Hidden>

        <DataTable
          columns={columns}
          isLoading={isLoading}
          data={dataProps}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          handleFetchData={onFetchData}
          pageCount={pageCount}
          totalCount={totalCount}
          searchValue={searchValue}
          reloadTable={reloadTable}
          defaultSortField='createdAt'
          defaultSortOrder='desc'
          showCheckboxes={false}

          // styles for 1 row upper panel
          maxHeight='calc(100vh - 280px)'
          maxHeightLaptop='calc(100vh - 380px)'
          maxHeightMobile='calc(100vh - 360px)'
        />

      </Grid>

      <Dialog
        open={dialogOpen}
        handleClose={() => {
          if (!isLoading) {
            setDialogOpen(false);
          }
        }}
      >
        {getDialogComponent()}
      </Dialog>
    </>

  );
}

export default ProductTypesPage;
