export const Shopper = `
_id
shoppedFromShops
name
firstName
lastName
emailRecords {
  address
}
phoneNumber
createdAt
shippingAddresses {
  firstName
  lastName
  address1
  address2
  country
  region
  postal
  region
  phone
  email
  city
}
billingAddresses {
  firstName
  lastName
  address1
  address2
  country
  region
  postal
  region
  phone
  email
  city
}
status
`;

export const ShopperInvitation = `
_id
firstName
lastName
email
phoneNumber
createdAt
`;
