import React from 'react';
import styled from "styled-components";
import { colors } from "../../constants";
import { useTranslation } from "react-i18next";

const StyledDropContainer = styled.div`
  width: 100%;
  height: 150px;
  background: ${colors.inputNormal};
  border: 3px dashed ${colors.textGrey};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: copy;

  p {
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    width: 240px;
    color: ${colors.black};
    user-select: none;

    span {
      color: ${colors.blue};
    }
  }
`

const DropContainer = () => {
  const { t } = useTranslation();

  return (
    <StyledDropContainer>
      <p>
        <span>{t('common.click_to_upload')}</span> {t('common.or_drag_it_here')} {t('common.image_formats_list')}
      </p>
    </StyledDropContainer>
  );
}

export default DropContainer;
