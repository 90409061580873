import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const getTextColor = (props) => {
  const {disabled, hover, active} = props;
  if (disabled) {
    return '#E6E6E6';
  }
  if (active === 'true') {
    return '#2A55DE';
  }
  if (hover === 'true') {
    return '#4874FF';
  }
  return '#222';
};

export default styled(Typography)`
  color: ${props => getTextColor(props)};
  font-family: Fredoka, sans-serif;
  font-size: 18px;
  font-weight: 500;
  word-break: break-all;
  width: max-content;
  user-select: none;
  transition: color .4s;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
`;
