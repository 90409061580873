import * as JSON_KEYS from "../constants/NameConsts";

export default class StreetConnection {
  pointUuids; // array of string;
  streetUuids; // array of string;
  uuid; // string

  constructor(jsonData) {
    this.pointUuids = jsonData[JSON_KEYS.POINT_UUIDS];
    this.streetUuids = jsonData[JSON_KEYS.STREET_UUIDS];
    this.uuid = jsonData[JSON_KEYS.UUID];
  }
}
