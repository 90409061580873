import * as JSON_KEYS from "../constants/NameConsts";
// import * as THREE from "three";
import {
  HOUSE_FREE,
  HOUSE_TAKEN,
  // MODULES_API_PATH,
  BASE_HOUSE_MATERIAL_COLOR,
  CURRENT_HOLDER_MATERIAL,
  SELECTED_HOUSE_MATERIAL,
  TAKEN_MATERIAL,
  FREE_HOUSE_MATERIAL,
  UPGRADE_HOUSE_MATERIAL_TOP,
  UPGRADE_HOUSE_MATERIAL_BOTTOM
} from "../constants/constants";

export default class House {
  selected; // boolean
  houseType; // string
  uuid; // string
  holderId; // string
  id; // int
  house3d; // THREE.Mesh

  constructor(jsonData, houseId, selected) {
    this.houseType = jsonData[JSON_KEYS.HOUSE_TYPE];
    this.selected = selected;
    this.uuid = jsonData[JSON_KEYS.UUID];
    this.house3d = null;
    this.holderId = jsonData[JSON_KEYS.HOUSE_HOLDER_ID];
    this.id = houseId;
  }

  getHouseUpgradeMaterial() {
    return this.id % 2 ? UPGRADE_HOUSE_MATERIAL_BOTTOM : UPGRADE_HOUSE_MATERIAL_TOP;
  }

  updateHouseView(house3d, currentHolderId, isPackageMode, isUpgradeablePlace) {
    this.house3d = house3d;

    if (this.holderId === currentHolderId) {
      this.house3d.material.copy(CURRENT_HOLDER_MATERIAL);
      this.house3d.material.color.set(BASE_HOUSE_MATERIAL_COLOR);
      return;
    }

    if (this.houseType === HOUSE_FREE) {
      this.house3d.material.copy(
        (isUpgradeablePlace && !isPackageMode) ?
        this.getHouseUpgradeMaterial()
          : FREE_HOUSE_MATERIAL);
    }
    if (this.houseType === HOUSE_TAKEN) {
      this.house3d.material.copy(isPackageMode ? FREE_HOUSE_MATERIAL : TAKEN_MATERIAL);
    }
    this.house3d.material.color.set(BASE_HOUSE_MATERIAL_COLOR);
  }

  toggleHouseSelection() {
    this.selected = !this.selected;
  }

  updateHouseViewBySelection() {
    if (this.house3d) {
      this.house3d.material.copy(this.selected ? SELECTED_HOUSE_MATERIAL : FREE_HOUSE_MATERIAL);
      this.house3d.material.color.set(BASE_HOUSE_MATERIAL_COLOR);
    }
  }
}
