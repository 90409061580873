import React, { useCallback, useState } from "react";
import styled from 'styled-components';
import FormHeader from "../../../UI/Form/FormHeader";
import HorizontalRule from "../../../UI/other/HorizontalRule";
import Button from "../../../UI/Button";
import FormCard from "../../../UI/Form/FormCard";
import { isPaymentRiskElevated } from "../helpers";
import OrderPayment from "./OrderPayment";
import Dialog from "../../../UI/Dialog/Dialog";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import StyledButton from "../../../UI/Button/Button";
import { useTranslation } from "react-i18next";

const StyledHeading = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const OrderPayments = ({order, capturePayments, isLoading}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogName, setDialogName] = useState(null);
  const [paymentIds, setPaymentIds] = useState([]);
  const canCapturePayment = order.payments.some(payment => payment.mode !== "captured");
  const { t } = useTranslation();

  const isCommonDialogNeeded = () => {
    const paymentIdList = order.payments.map(payment => payment._id);
    // If any payment we are trying to capture has an elevated risk,
    // prompt user to make sure they want to capture payment
    return isPaymentRiskElevated(order, paymentIdList);
  }

  const handleCapturePayments = () => {
    const newPaymentIds = order.payments.map(payment => payment._id);

    if (isCommonDialogNeeded()) {
      setPaymentIds(newPaymentIds);
      setDialogName('many');
      setDialogOpen(true);
    } else {
      capturePayments(newPaymentIds);
    }
  }

  const DialogCapture = useCallback((type) => {
    let message = t('orders.elevated_risk_q');
    if (type === 'many') {
      message = t('orders.elevated_risk_many_q');
    }
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={message}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              setDialogOpen(false);
              capturePayments(paymentIds);
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          <StyledButton
            width='180px'
            mytype='secondary'
            handleClick={() => setDialogOpen(false)}
          >
            {t('ui.no')}
          </StyledButton>
        </DialogButtons>
      </>
    )
  }, [paymentIds])

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'single': return DialogCapture('single');
      case 'many': return DialogCapture('many');
      default: return null;
    }
  }

  return (
    <FormCard>
      <FormHeader>
        <StyledHeading>
          {t('orders.payments')}
          {
            canCapturePayment && <>
              <HorizontalRule/>
              <Button
                onClick={handleCapturePayments}
                disabled={isLoading}
              >
                {t('orders.capture_payment')}
              </Button>
            </>
          }
        </StyledHeading>
      </FormHeader>

      {
        order.payments.map(item => <OrderPayment
          key={item._id}
          payment={item}
        />)
      }

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </FormCard>
  );
}

export default OrderPayments;
