import * as Yup from 'yup';

export const TransactionSchema = Yup.object().shape({
  merchant: Yup.string()
    .when('godmode', {
      is: true,
      then: Yup.string().required('errors.field_required'),
    }),
  subscription: Yup.string()
    .when('type', {
      is: 'subscription',
      then: Yup.string().required('errors.field_required'),
    }),
})
