import React, { useMemo, useState } from "react";
import styled from "styled-components";
import ProjectCell from "./components/ProjectCell";
import DialogMessages from "../../../../UI/Dialog/DialogMessages";
import Dialog from '../../../../UI/Dialog';
import StyledButton from "../../../../UI/Button/Button";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CreateProjectSchema } from "./schemas";
import FormInput from "../../../../UI/Form/FormInput";
import { Form } from "../../../../UI/Form/MyForm";

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 16px;
  max-height: 68vh;
  overflow-y: auto;

  // scroll style

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E9E9E9;
    border-radius: 8px;
  }
`;

const FieldBox = styled('div')`
  width: 420px;
  margin-top: 24px;
`;

const EditorTabProjectList = ({ rooms, shopId }) => {
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeProjectId, setActiveProjectId] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const { t } = useTranslation();

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(CreateProjectSchema),
    defaultValues: {
      name: '',
    },
  });

  const handleChangeActiveId = (id) => {
    setActiveProjectId(id);
    window.open(`${process.env.REACT_APP_EDITOR_UI_URL}?shopId=${shopId}&spaceId=${id}`, '_self');
  };

  const handleDelete = (id) => {
    setSelectedProjectId(id);
    setDialogName('delete');
    setDialogOpen(true);
  };

  const handleRename = (id) => {
    setSelectedProjectId(id);
    setDialogName('rename');
    setDialogOpen(true);
  };

  const handleEdit = (id) => {
    if (id === '0') {
      window.location.href = 'https://editor.wondersouq.web3dev.group/';
    }
  };

  const closeDialog = () => {
    setDialogOpen(false);
  }

  const onSubmit = (data) => {
    console.log('rename project', data.name);
    closeDialog();
  };

  const DialogDelete = useMemo(() => {
    return (<>
      <DialogMessages
        title='Delete this file?'
      />
      <DialogButtons justifyButtons='center' marginTop='24px'>
        <StyledButton
          width='152px'
          handleClick={() => {
            console.log('delete', selectedProjectId);
            setDialogOpen(false);
          }}
        >
          {t("ui.yes")}
        </StyledButton>
        <StyledButton
          width='152px'
          mytype='secondary'
          handleClick={() => {
            setDialogOpen(false);
          }}
        >
          {t("ui.no")}
        </StyledButton>
      </DialogButtons>
    </>)
  }, [selectedProjectId]);

  const DialogRename = useMemo(() => {
    return (<>
      <DialogMessages
        title='Rename Project'
      />
        <Form
          onSubmit={handleSubmit(onSubmit)}
          width='100%'
        >
          <FieldBox>
            <Controller
              name="name"
              control={control}
              render={({ field }) => <FormInput
                placeholder='Name'
                value={field.value}
                onChange={field.onChange}
                error={errors.name}
              />}
            />
          </FieldBox>
          <DialogButtons marginTop='0'>
            <StyledButton
              mytype='secondary'
              width='100%'
              handleClick={closeDialog}
            >
              Cancel
            </StyledButton>
            <StyledButton
              type='submit'
              width='100%'
            >
              Done
            </StyledButton>
          </DialogButtons>
        </Form>
    </>)
  }, [selectedProjectId, control, errors]);

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'delete': return DialogDelete;
      case 'rename': return DialogRename;
      default: return null;
    }
  }

  // TODO: NOW ONLY ONE PROJECT
  return (
    <Wrapper>
      {/*<NewProjectCell onClick={addNewProjectHandler} />*/}
      {rooms.map((item, index) => {
        return <ProjectCell
          key={item.id}
          data={item}
          active={item.id === activeProjectId}
          disabled={index === 2}
          onClick={() => handleChangeActiveId(item.space.id)}
          onDelete={() => handleDelete(item.id)}
          onRename={() => handleRename(item.id)}
          onEdit={() => handleEdit(item.id)}
        />
      })}

      <Dialog
        open={dialogOpen}
        handleClose={closeDialog}
      >
        {getDialogComponent()}
      </Dialog>
    </Wrapper>
  );
};

export default EditorTabProjectList;
