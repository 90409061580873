import React from 'react';
import PropTypes from "prop-types";
import styled, {css} from 'styled-components';
import {colors} from '../../constants';

export const greyCss = css`
  background: ${colors.backgroundGrey};
  color: #C5C5C5;
`

export const redCss = css`
  background: ${colors.redLighter};
`

export const yellowCss = css`
  background: ${colors.yellow};
`

export const blueCss = css`
  background: ${colors.blue};
`

const defaultColorScheme = [
  {
    types: ['invisible', 'canceled', 'no', 'suspended', 'expired', 'draft'],
    css: greyCss,
  },
  {
    types: ['new', 'declined', 'banned'],
    css: redCss,
  },
  {
    types: ['processing', 'pending'],
    css: yellowCss,
  },
];

const getColorScheme = (scheme, type) => {
  const value = type?.toLowerCase();
  const myScheme = scheme || defaultColorScheme;
  for (let i = 0; i < myScheme.length; i++) {
    if (myScheme[i].types.includes(value)) {
      return myScheme[i].css;
    }
  }
}

const StyledStatus = styled.div`
  width: ${props => props.width};
  min-width: 110px;
  height: 28px;
  background: ${colors.grass}; // default background color
  border-radius: 59px;
  font-family: Fredoka, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
  user-select: none;
  
  ${props => getColorScheme(props.colorScheme, props.type)};
`;

/**
 * Colored status component
 * @param content - display text: Pending, Complete ...
 * @param type - incoming value from backend: pending, complete ...
 * @param width - custom width of the container
 * @param style - custom style
 * @param colorScheme - custom color scheme. Default scheme is high above
 * @returns {JSX.Element}
 * @constructor
 */

const Status = ({content, type, width = '120px', style, colorScheme}) => {
  return (
    <StyledStatus
      width={width}
      type={type}
      style={style}
      colorScheme={colorScheme}
    >
      {content}
    </StyledStatus>
  );
}

Status.propTypes = {
  content: PropTypes.string.isRequired,
  width: PropTypes.string,
  colorScheme: PropTypes.array,
}

export default Status;
