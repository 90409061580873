import gql from "graphql-tag";
import { Company, Shop, ShopSubscriptionPackage } from "./fragments";

export const getAllMerchants = gql`
query companies($first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $offset: Int, $sortOrder: SortOrder, $sortBy: CompanySortByField, $filter: String, $genericFilters: GenericFilterInput) {
  companies(companyIds: [], first: $first, last: $last, before: $before, after: $after, offset: $offset, sortOrder: $sortOrder, sortBy: $sortBy, filter: $filter, genericFilters: $genericFilters) {
    nodes {
      ${Company}
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
`;

export const getMerchant = gql`
  query one($id: ID!) {
    company(companyId: $id) {
      ${Company}
    }
  }
`;

export const companySubscriptionOverrides = gql`
  query companySubscriptionOverrides($id: ID!) {
    companySubscriptionOverrides(id: $id) {
      rate {
        changePercentage
        startDate
        endDate
        disabled
        notes
      }
      commission {
        newValue
        startDate
        endDate
        disabled
        notes
      }
    }
  }
`;

export const merchantShopsQuery = gql`
  query shopsWithFilters($shopIds: [ID], $companyIds: [ID], $first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $offset: Int, $sortOrder: SortOrder, $sortBy: ShopsWithFiltersSortByField, $filter: String, $genericFilters: GenericFilterInput) {
    shopsWithFilters(shopIds: $shopIds, companyIds: $companyIds, first: $first, last: $last, before: $before, after: $after, offset: $offset, sortOrder: $sortOrder, sortBy: $sortBy, filter: $filter, genericFilters: $genericFilters) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ${Shop}
        ${ShopSubscriptionPackage}
      }
      totalCount
    }
  }
`;
