import React, { useEffect, useRef, useState } from "react";
import {
  FormDescriptionText,
  FormHeaderText,
  FormHeaderWrap,
  SignupAuthNumbersLine,
  SignupNumberInput,
} from "../styles";
import RegError from "./RegError";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import CustomButton from "../../../../UI/Button";
import LayoutForm from "../layouts/LayoutForm";

const EnterCodeForm = ({ onSubmit, loading, cognitoError, headingText }) => {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();

  const [numbers, setNumbers] = useState({
    num1: '',
    num2: '',
    num3: '',
    num4: '',
    num5: '',
    num6: '',
  });
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const isNotValid = Object.values(numbers).some((num) => num === '');
    setIsValid(!isNotValid);
  }, [numbers]);

  const handleSetNumber = (field, val) => {
    setNumbers((prev) => ({
      ...prev,
      [field]: val,
    }));

    if (!val) {
      return;
    }

    switch (field) {
      case 'num1':
        ref2.current.querySelector('input').focus();
        break;
      case 'num2':
        ref3.current.querySelector('input').focus();
        break;
      case 'num3':
        ref4.current.querySelector('input').focus();
        break;
      case 'num4':
        ref5.current.querySelector('input').focus();
        break;
      case 'num5':
        ref6.current.querySelector('input').focus();
        break;
      default:
        ref1.current.querySelector('input').focus();
    }
  };

  const handlePaste = (e) => {
    if (e.clipboardData && e.clipboardData.getData) {
      const pastedText = e.clipboardData.getData('text/plain');
      if (pastedText) {
        const arr = pastedText.split('');
        setNumbers({
          num1: arr[0] || '',
          num2: arr[1] || '',
          num3: arr[2] || '',
          num4: arr[3] || '',
          num5: arr[4] || '',
          num6: arr[5] || '',
        });
      }
    }
  };

  const handleSubmit = () => {
    const code = Object.values(numbers).join('');
    onSubmit(code);
  }

  return (
    <LayoutForm>
      <FormHeaderWrap>
        <FormHeaderText>{headingText}</FormHeaderText>
      </FormHeaderWrap>
      <FormDescriptionText>Please enter your code here.</FormDescriptionText>

      <SignupAuthNumbersLine>
        <SignupNumberInput
          inputProps={{ maxLength: 1 }}
          value={numbers.num1}
          onChange={(e) => handleSetNumber('num1', e.target.value)}
          ref={ref1}
          onPaste={handlePaste}
        />
        <SignupNumberInput
          inputProps={{ maxLength: 1 }}
          value={numbers.num2}
          onChange={(e) => handleSetNumber('num2', e.target.value)}
          ref={ref2}
          onPaste={handlePaste}
        />
        <SignupNumberInput
          inputProps={{ maxLength: 1 }}
          value={numbers.num3}
          onChange={(e) => handleSetNumber('num3', e.target.value)}
          ref={ref3}
          onPaste={handlePaste}
        />
        -
        <SignupNumberInput
          inputProps={{ maxLength: 1 }}
          value={numbers.num4}
          onChange={(e) => handleSetNumber('num4', e.target.value)}
          ref={ref4}
          onPaste={handlePaste}
        />
        <SignupNumberInput
          inputProps={{ maxLength: 1 }}
          value={numbers.num5}
          onChange={(e) => handleSetNumber('num5', e.target.value)}
          ref={ref5}
          onPaste={handlePaste}
        />
        <SignupNumberInput
          inputProps={{ maxLength: 1 }}
          value={numbers.num6}
          onChange={(e) => handleSetNumber('num6', e.target.value)}
          ref={ref6}
          onPaste={handlePaste}
        />
      </SignupAuthNumbersLine>

      {
        cognitoError
          ? <RegError text={cognitoError} />
          : null
      }

      <DialogButtons justifyButtons='flex-end'>
        <CustomButton
          width='170px'
          disabled={loading || !isValid}
          handleClick={handleSubmit}
        >
          Submit
        </CustomButton>
      </DialogButtons>
    </LayoutForm>
  );
};

export default EnterCodeForm;
