import React, { useContext } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import SelectAllAutocomplete from "../SelectAllAutocomplete";
import { MultiShopSelectorContext } from "../context/MultiShopSelectorContext";
import { SingleShopSelectorContext } from "../context/SingleShopSelectorContext";
import { constants } from "../../../constants.js";
import { useTranslation } from "react-i18next";

const defaultLogo = "/reaction-logo-circular.svg";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  logo: {
    marginRight: theme.spacing(2),
  },
}));

const useAutocompleteStyles = makeStyles(theme => ({}));

/**
 * ShopSelectorWithData is a wrapper around SelectAllAutocomplete. In addition
 * to allowing for shop selection, it also saves the user's selections.
 * NOTICE: this means that  any instance of ShopSelectorWithData will
 * share the same selections! If this is not your intention, consider using
 * <SelectAllAutocomplete...>
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function ShopSelectorWithData({
  className,
  shouldShowShopName,
  linkTo,
  size,
  viewer,
  selectedShopIds,
  onShopSelectionChange,
  isMultiSelect = true,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesAutocomplete = useAutocompleteStyles();
  const { localStorageKey: multiLocalStorageKey } = useContext(MultiShopSelectorContext);
  const { localStorageKey: singleLocalStorageKey } = useContext(SingleShopSelectorContext);

  let adminUIShops = [];

  if (viewer?.adminUIShops) {
    ({ adminUIShops } = viewer);
  } else {
    return (
      <Link className={classNames(classes.root, className)} to={linkTo}>
        <img
          alt={`${constants.APP_NAME} CMS`}
          className={classes.logo}
          src={defaultLogo}
          width={size}
        />
        {shouldShowShopName && (
          <Typography className={classes.dummyShopName} component="span" variant="h3">
            {constants.APP_NAME} CMS
          </Typography>
        )}
      </Link>
    );
  }

  const onChange = selectedItems => {
    const selectedItemValues = selectedItems.map(item => item.value);

    // Save changes in localStorage
    localStorage.setItem(
      isMultiSelect ? multiLocalStorageKey : singleLocalStorageKey,
      JSON.stringify(selectedItemValues)
    );

    // Lift state up
    onShopSelectionChange(selectedItemValues);
  };

  const label = isMultiSelect ? t('common.select_shop_s') : t('common.select_shop');
  const placeholder = isMultiSelect ? t('common.search_for_shops') : t('common.search_for_shop');
  
  const itemsFiltered = adminUIShops.map(shop => ({
    label: shop.name,
    value: shop._id,
    selected: selectedShopIds.includes(shop._id),
    groupId: shop.company.name,
  }));

  return (
    <div className={classes.root}>
      <SelectAllAutocomplete
        items={itemsFiltered}
        limitTags={3}
        label={label}
        placeholder={placeholder}
        selectAllLabel={t('common.all_shops')}
        onChange={onChange}
        classes={classesAutocomplete}
        isMultiSelect={isMultiSelect}
      />
    </div>
  );
}

export default ShopSelectorWithData;
