import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PreloaderAnimated from "../../../../../../UI/Preloader";
import FormText from "../../../../../../UI/Form/FormText";
import { ShopEditContext } from "../../../../../../package/src/context/ShopEditContext";
import { Typography } from "@material-ui/core";

const PreloaderBox = styled.div`
  width: 100px;
  margin-bottom: 26px;
`;

const StatusText = styled(Typography)`
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: black;
`;

const StatusWait = () => {
  const { t } = useTranslation();
  const {
    paymentPending,
    paymentError,
  } = useContext(ShopEditContext);

  const getHeadingText = () => {
    if (paymentPending) {
      return t('payment.payment_not_completed');
    }

    if (paymentError) {
      return t('payment.payment_error');
    }

    return t('payment.wait_accept_payment');
  }

  const getText = () => {
    if (paymentPending) {
      return t('payment.payment_in_progress_message');
    }

    if (paymentError) {
      return t('payment.payment_error_message');
    }

    return '';
  }

  return (
    <>
      <PreloaderBox>
        <PreloaderAnimated />
      </PreloaderBox>
      <StatusText>
        { getHeadingText() }
      </StatusText>
      <FormText
        style={{ maxWidth: '472px', textAlign: 'center' }}
      >
        { getText() }
      </FormText>
    </>
  );
};

export default StatusWait;
