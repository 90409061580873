import gql from "graphql-tag";

export const mutationCreateProductType = gql`
  mutation createProductType($input: CreateProductTypeInput!) {
    createProductType(input: $input) {
      data {
        id
        name
      }
    }
  }
`;

export const mutationUpdateProductType = gql`
  mutation updateProductType($input: UpdateProductTypeInput!) {
    updateProductType(input: $input) {
      data {
        id
        name
      }
    }
  }
`;

export const mutationAddAttributeToType = gql`
  mutation addVariantAttributeToProductType($input: AddVariantAttributeToProductTypeInput!) {
    addVariantAttributeToProductType(input: $input) {
      data {
        id
        name
      }
    }
  }
`;

export const mutationRemoveAttributeFromType = gql`
  mutation removeVariantAttributeFromProductType($input: RemoveVariantAttributeFromProductTypeInput!) {
    removeVariantAttributeFromProductType(input: $input) {
      data {
        id
        name
      }
    }
  }
`;
