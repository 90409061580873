import * as JSON_KEYS from "../constants/NameConsts";
import * as THREE from "three";

export default class StreetPoint {
  isBegin; // Boolean
  x; // float
  y; // float
  z; // float
  uuid; // string
  connectionUuid;

  constructor(jsonData) {
    this.isBegin = jsonData[JSON_KEYS.IS_BEGIN];
    let coords = jsonData[JSON_KEYS.COORDS];
    this.x = coords[0];
    this.y = coords[1];
    this.z = coords[2];
    this.uuid = jsonData[JSON_KEYS.UUID];
    this.connectionUuid = null;
  }

  getPoint() {
    return new THREE.Vector3(this.x, this.y, this.z);
  }
}
