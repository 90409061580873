import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useOrderColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'id',
      label: t('orders.order_id'),
      sortable: false,
    },
    {
      name: 'shopName',
      label: t('orders.shop_name'),
      sortable: false,
    },
    {
      name: 'date',
      label: t('orders.date'),
      sortable: false,
    },
    {
      name: 'status',
      label: t('orders.status'),
      sortable: false,
    },
    {
      name: 'payment',
      label: t('orders.payment'),
      sortable: false,
    },
    {
      name: 'fulfilment',
      label: t('orders.fulfillment'),
      sortable: false,
    },
    {
      name: 'customer',
      label: t('orders.customer'),
      sortable: false,
    },
    {
      name: 'total',
      label: t('orders.total'),
      sortable: false,
    },
    {
      name: 'options',
    },
  ], [t]);
};
