import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useMerchantRequestsColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'request',
      label: t('merchant.request'),
      sortable: false,
    },
    {
      name: 'type',
      label: t('merchant.request_type'),
    },
    {
      name: 'shopName',
      label: t('merchant.shop_name'),
      sortable: false,
    },
    {
      name: 'email',
      label: t('merchant.email'),
      sortable: false,
    },
    {
      name: 'status',
      label: t('merchant.status'),
      sortable: 'state',
    },
    {
      name: 'shopId',
      label: t('merchant.shop_id'),
    },
    {
      name: 'accountId',
      label: t('merchant.account_id'),
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    {
      name: 'options',
    }
  ], [t]);
};
