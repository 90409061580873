import React, { useContext, useState } from "react";
import FormCard from "../../../../UI/Form/FormCard";
import FormHeader from "../../../../UI/Form/FormHeader";
import InputLabel from "../../../../UI/Form/InputLabel";
import { Controller, useForm } from "react-hook-form";
import FormInput from "../../../../UI/Form/FormInput";
import StyledButton from "../../../../UI/Button/Button";
import { Form } from "../../../../UI/Form/MyForm";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ProfileSchema } from "../schemas";
import { Auth } from "aws-amplify";
import HorizontalRule from "../../../../UI/other/HorizontalRule";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../package/src/context/AuthContext";

const ProfileChangePassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const { control, handleSubmit, formState: { errors, isDirty }, reset } = useForm({
    resolver: yupResolver(ProfileSchema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirm: '',
    },
  });

  const onSubmit = async(formData) => {
    if (!user) {
      return;
    }

    setIsSubmitting(true);

    try {
      await Auth.changePassword(user, formData.oldPassword, formData.newPassword);
      enqueueSnackbar(t('settings.change_password_success'), { variant: "success" });
      reset();
    } catch ({ message }) {
      enqueueSnackbar(message, { variant: "error" });
    }

    setIsSubmitting(false);
  }

  return (
    <Form
      width='100%'
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormCard>
        <FormHeader>{t('settings.change_password')}</FormHeader>

        <InputLabel error={errors.oldPassword}>{t('settings.old_password')}</InputLabel>
        <Controller
          name="oldPassword"
          control={control}
          render={({ field }) => <FormInput
            value={field.value}
            onChange={field.onChange}
            error={errors.oldPassword}
            type='password'
          />}
        />

        <InputLabel error={errors.oldPassword}>{t('settings.new_password')}</InputLabel>
        <Controller
          name="newPassword"
          control={control}
          render={({ field }) => <FormInput
            value={field.value}
            onChange={field.onChange}
            error={errors.newPassword}
            type='password'
          />}
        />

        <InputLabel error={errors.confirm}>{t('settings.confirm_password')}</InputLabel>
        <Controller
          name="confirm"
          control={control}
          render={({ field }) => <FormInput
            value={field.value}
            onChange={field.onChange}
            error={errors.confirm}
            type='password'
          />}
        />

        <HorizontalRule />

        <StyledButton
          width='180px'
          type="submit"
          disabled={!isDirty || isSubmitting}
          sx={{
            margin: '24px 0',
          }}
        >
          {t('ui.save')}
        </StyledButton>
      </FormCard>
    </Form>
  );
};

export default ProfileChangePassword;
