import React from "react";
import SelectAllAutocomplete from "../../../package/src/SelectAllAutocomplete";

function ExceptionSelector({value, onChange, options, ...other}) {
  const handleChange = (val) => {
    onChange(val.map(item => item.value));
  }

  return (
    <SelectAllAutocomplete
      items={options}
      onChange={handleChange}
      {...other}
    />
  );
}

export default ExceptionSelector;
