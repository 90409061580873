import React from 'react';
import FormCard from "../../../UI/Form/FormCard";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const BlockMain = () => {
  const { control, formState: { errors }, loading } = useFormContext();
  const { t } = useTranslation();

  return (
    <FormCard>
      <FormHeader>{t('product.details')}</FormHeader>
      <InputLabel
        error={errors.productName}
        disabled={loading}
      >
        {t('product.product_name')}
      </InputLabel>
      <Controller
        name="productName"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('product.write_title')}
          value={field.value}
          onChange={field.onChange}
          error={errors.productName}
          disabled={loading}
        />}
      />
      <InputLabel disabled={loading}>{t('product.subtitle')}</InputLabel>
      <Controller
        name="productSubtitle"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('product.write_subtitle')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
        />}
      />
      <InputLabel disabled={loading} error={errors.productShortDescription}>{t('product.short_description')}</InputLabel>
      <Controller
        name="productShortDescription"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('product.write_short_description')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
          error={errors.productShortDescription}
        />}
      />
      <InputLabel disabled={loading}>{t('product.long_description')}</InputLabel>
      <Controller
        name="productLongDescription"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('product.write_long_description')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
          rows={8}
          multiline
        />}
      />
      <InputLabel disabled={loading}>{t('product.purchase_notes')}</InputLabel>
      <Controller
        name="productNotes"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('product.write_purchase_notes')}
          value={field.value}
          onChange={field.onChange}
          disabled={loading}
          rows={5}
          multiline
        />}
      />

    </FormCard>
  );
}

export default BlockMain;
