const trimAttributeValue = (type, value) => {
  switch(type) {
    case 'string':
    case 'text': return value.trim();
    case 'colorPicker':
    case 'datePicker': return value.map((item) => ({
      ...item,
      name: item.name.trim(),
    }))
    default: return value;
  }
};

export default trimAttributeValue;
