import React from 'react';
import styled from 'styled-components';
import {colors} from '../../constants';

const StyledParagraph = styled.p`
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: ${colors.textGrey};
  margin: 12px 0 24px 0;
  
  span {
    color: ${colors.blue};
  }
`

const FormNote = ({children, ...other}) => {
  return (
    <StyledParagraph {...other}>
      {children}
    </StyledParagraph>
  );
}

export default FormNote;
