import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useTransactions = () => {
  const { t } = useTranslation();

  const typeOptions = useMemo(() => [
    {
      value: 'commission',
      name: t('finance.commission'),
    },
    {
      value: 'subscription',
      name: t('finance.subscription'),
    },
    {
      value: 'debit',
      name: t('finance.debit'),
    },
    {
      value: 'credit',
      name: t('finance.credit'),
    },
  ], [t]);

  const directionOptions = useMemo(() => [
    {
      value: 'merchant',
      name: t('finance.wondersouq_to_merchant'),
    },
    {
      value: 'godmode',
      name: t('finance.merchant_to_wondersouq'),
    },
  ], [t]);

  return {
    typeOptions,
    directionOptions,
  }
};

export default useTransactions;
