import gql from "graphql-tag";

export default gql`
  query shoppers(
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $before: ConnectionCursor
    $after: ConnectionCursor
    $offset: Int
    $sortBy: ShopperSortByField
    $sortOrder: SortOrder
    $filter: String
    $shopIds: [ID]
  ) {
    shoppers(
      first: $first
      last: $last
      before: $before
      after: $after
      offset: $offset
      sortBy: $sortBy
      sortOrder: $sortOrder
      filter: $filter
      shopIds: $shopIds
    ) {
      nodes {
        _id
        primaryEmailAddress
        name
      }
      totalCount
    }
  }
`;
