import { useEffect, useState } from "react";

const useInputNumber = ({ value, type, onChange, isFloatNumber }) => {
  const [innerValue, setInnerValue] = useState(''); // string

  useEffect(() => {
    if (value !== undefined) {
      setInnerValue(value);
    }
  }, [value]);

  const handleChange = (e) => {
    setInnerValue(e.target.value);
  };

  const handleConfirm = () => {
    if (type === 'number') {
      const val = Number(innerValue);
      if (val < 0) {
        setInnerValue('0');
        onChange('0');
      } else {
        const newVal = isFloatNumber ? String(val) : String(Math.round(val));
        setInnerValue(newVal);
        onChange(newVal);
      }
      return;
    }

    if (value !== innerValue) {
      onChange(innerValue.trim());
    }
  };

  const resetValue = (newVal) => {
    if (newVal === undefined) {
      setInnerValue(value);
    } else {
      setInnerValue(newVal);
    }
  };

  return {
    innerValue,
    handleChange,
    handleConfirm,
    resetValue,
  }
};

export default useInputNumber;
