import { useTranslation } from "react-i18next";
import { constants } from "../../../../constants";
import { useCallback } from "react";

const useAccountTypes = () => {
  const { t } = useTranslation();

  const getAccountTypeLabel = useCallback((value) => {
    switch(value) {
      case constants.ACCOUNT_TYPES.godmodeAdmin: return t('account.wondersouq_admin');
      case constants.ACCOUNT_TYPES.godmodeAssistantAdmin: return t('account.wondersouq_assistant_admin');
      case constants.ACCOUNT_TYPES.godmodeAccountManager: return t('account.wondersouq_account_manager');
      case constants.ACCOUNT_TYPES.godmodeFinancialManager: return t('account.wondersouq_financial_manager');
      case constants.ACCOUNT_TYPES.merchantAdmin: return t('account.admin');
      case constants.ACCOUNT_TYPES.merchantFinancialManager: return t('account.financial_manager');
      case constants.ACCOUNT_TYPES.merchantProductManager: return t('account.product_manager');
      case 'standard-permissions': return t('account.standard_permissions');
      case constants.ACCOUNT_TYPES.merchantAssistantAdmin: return t('account.assistant_admin');
      case constants.ACCOUNT_TYPES.merchantShopProductManager: return t('account.shop_product_manager');
      case constants.ACCOUNT_TYPES.merchantShopFinancialManager: return t('account.shop_financial_manager');
    }
  }, [t]);

  return {
    getAccountTypeLabel,
  }
};

export default useAccountTypes;
