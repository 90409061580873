import React, { useCallback, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid, Hidden, makeStyles } from "@material-ui/core";
import Search from "../../../UI/Search";
import StyledButton from "../../../UI/Button";
import DataTable from "../../../UI/DataTable/DataTable";
import Dialog from "../../../UI/Dialog/Dialog";
import { useSnackbar } from "notistack";
import { useApolloClient } from "@apollo/react-hooks";
import svgEdit from "../../../icons/table-edit.svg";
import svgDelete from '../../../icons/delete.svg';
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import { useTranslation } from "react-i18next";
import useProductAttributesColumns from "../hooks/useProductAttributesColumns";
import { queryAllAttributes, queryProductTypes } from "../graphql/queries";
import { isArray } from "lodash";
import { getStringFromArray } from "../../../package/src/utils/getStringFromArray";
import fromCamelCase from "../../../package/src/utils/fromCamelCase";
import EditAttribute from "../components/EditAttribute";
import { mutationRemoveAttribute } from "../graphql/mutations";

const useStyles = makeStyles(theme => ({
  secondColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
  },
  secondColumnXS: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    margin: '28px 0',
  },
}));

const ProductAttributesPage = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowId, setRowId] = useState('');
  const [tableData, setTableData] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const columns = useProductAttributesColumns();

  const getAttributes = async (search) => {
    return apolloClient.query({
      query: queryAllAttributes,
      variables: {
        input: {
          filters: {
            name: search,
          }
        }
      },
      fetchPolicy: "network-only",
    });
  };

  const getProductTypes = () => {
    return apolloClient.query({
      query: queryProductTypes,
      fetchPolicy: "network-only",
    });
  }

  const onFetchData = useCallback(({ globalFilter, pageSize }) => {
    setIsLoading(true);

    Promise.allSettled([
      getAttributes(globalFilter),
      getProductTypes(),
    ])
      .then(res => {
        const attributes = res[0].value.data?.attributes?.data || [];
        const productTypes = res[1].value.data?.productTypes?.data || [];
        setTableData(attributes);
        setProductTypes(productTypes);
        setPageCount(Math.ceil(attributes.length / pageSize));
        setTotalCount(attributes.length);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [t]);

  const attributesOptions = useMemo(() => [
    {
      value: 'edit',
      label: 'Edit',
      icon: svgEdit,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('edit');
        setDialogOpen(true);
      },
    },
    {
      value: 'delete',
      label: 'Delete',
      icon: svgDelete,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('delete');
        setDialogOpen(true);
      },
    },
  ], [t]);

  const dataProps = useMemo(() => tableData.map((item) => {
      const value = item.inputConfig?.allowedValues || '';
      const productTypesList = [];
      let shortVersion = [];
      let difference = 0;
      productTypes.forEach((type) => {
        if (type.variantAttributes.some((k) => k.attribute.id === item.id)) {
          productTypesList.push(type.name);
        }
      });
      if (productTypesList.length > 4) {
        shortVersion = productTypesList.slice(0, 4);
        difference = productTypesList.length - shortVersion.length;
      }
      return {
        id: item.id,
        attributeName: item.name,
        type: fromCamelCase(item.inputType),
        values: isArray(value) ? getStringFromArray(value) : value,
        productTypes: {
          full: getStringFromArray(productTypesList),
          short: getStringFromArray(shortVersion),
          difference,
        },
        options: attributesOptions,
      }
    }), [tableData, productTypes, attributesOptions]);

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const renderNoButton = useMemo(() =>
      <StyledButton
        width='180px'
        mytype='secondary'
        handleClick={() => setDialogOpen(false)}
      >
        {t('ui.no')}
      </StyledButton>
    , [t])

  const DialogDeleteSingle = useMemo(() => {
    return (
      <>
        <DialogMessages
          title='Delete attribute?'
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              setDialogOpen(false);
              apolloClient.mutate({
                mutation: mutationRemoveAttribute,
                variables: {
                  input: {
                    id: rowId,
                  }
                }
              })
                .then((res) => {
                  if (res) {
                    refetch();
                    enqueueSnackbar('Successfully deleted attribute', {variant: 'success'});
                  }
                })
                .catch((e) => {
                  enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                  console.log(e.message);
                })
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [rowId, t])

  const handleCreateAttribute = () => {
    setRowId('');
    setDialogName('create');
    setDialogOpen(true);
  }

  const getDialogComponent = () => {
    switch(dialogName) {
      case 'delete': return DialogDeleteSingle;
      case 'create':
      case 'edit': return <EditAttribute
        id={rowId}
        data={tableData.find((d) => d.id === rowId)}
        loading={isLoading}
        setLoading={setIsLoading}
        onClose={() => {
          setDialogOpen(false);
          refetch();
        }}
      />;
      default: return null;
    }
  };

  const renderSecondColumn = (myClass) => {
    return <Grid item xs={12} lg={3} className={classes[myClass]}>
      <StyledButton
        onClick={handleCreateAttribute}
      >
        Create Attribute
      </StyledButton>
    </Grid>
  }

  return (
    <>
      <Grid container>
        <Toolbar title='Attributes' />

        <Grid item xs={12} lg={9}>
          <Search
            handleChange={handleSearch}
            onSearchClick={refetch}
            placeholder={t('ui.search')}
            useDebounce
          />
        </Grid>

        <Hidden mdDown>
          {renderSecondColumn('secondColumn')}
        </Hidden>

        <Hidden lgUp>
          {renderSecondColumn('secondColumnXS')}
        </Hidden>

        <DataTable
          columns={columns}
          isLoading={isLoading}
          data={dataProps}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          handleFetchData={onFetchData}
          pageCount={pageCount}
          totalCount={totalCount}
          searchValue={searchValue}
          reloadTable={reloadTable}
          defaultSortField='createdAt'
          defaultSortOrder='desc'
          showCheckboxes={false}

          // styles for 1 row upper panel
          maxHeight='calc(100vh - 280px)'
          maxHeightLaptop='calc(100vh - 380px)'
          maxHeightMobile='calc(100vh - 360px)'
        />

      </Grid>

      <Dialog
        open={dialogOpen}
        handleClose={() => {
          if (!isLoading) {
            setDialogOpen(false);
          }
        }}
      >
        {getDialogComponent()}
      </Dialog>
    </>

  );
}

export default ProductAttributesPage;
