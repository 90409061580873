import { BACKEND_TO_NAMES_MAP } from "../components/Shop";

export default function transformLoadedConfiguration(configuration) {
  if (!configuration) {
    return;
  }

  const result = {};
  for (const key of BACKEND_TO_NAMES_MAP.keys()) {
    const value = BACKEND_TO_NAMES_MAP.get(key);
    const keyStr = key.toString();
    result[keyStr] = '';
    if ((configuration[value] !== null) && (configuration[value] !== undefined)) {
      result[keyStr] = configuration[value].replace(".glb", "");
    } else {
      if (keyStr === 'umbrella') {
        result[keyStr] = 'none_umbrella';
      }
    }
  }
  return result;
}
