import React, { useContext, useEffect, useState } from "react";
import SelectAllAutocomplete from "../SelectAllAutocomplete";
import { MerchantSelectorContext } from "../context/MerchantSelectorContext";
import { useTranslation } from "react-i18next";

/**
 * MerchantSelectorWithData
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function MerchantSelectorWithData({
  viewer,
  selectedCompanyIds, // a merchant on the backend is actually a company
  onMerchantSelectionChange,
  isMultiSelect = true,
  error,
  ...other
}) {
  const { t } = useTranslation();

  const [items, setItems] = useState([]);

  const { localStorageKey } = useContext(MerchantSelectorContext);
  let adminUIShops = viewer?.adminUIShops || [];

  if (viewer?.type !== "godmode") {
    // Merchants cannot select other merchants
    return null;
  } else {
    // The godmode company should not be an option
    adminUIShops = adminUIShops.filter(shop => shop.company._id !== viewer.companyId);
  }

  useEffect(() => {
    const uniqueCompanyIds = [];
    const uniqueCompanies = [];

    for (let shop of adminUIShops) {
      if (!uniqueCompanyIds.includes(shop.company._id)) {
        // this is a new company (not a dupe) --> add it to uniqueCompanies
        uniqueCompanies.push({
          _id: shop.company._id,
          name: shop.company.name,
        });
        uniqueCompanyIds.push(shop.company._id);
      }
    }

    const companyIds = Array.isArray(selectedCompanyIds) ? selectedCompanyIds : selectedCompanyIds.selectedCompanyIds;

    const items = uniqueCompanies.map(company => ({
      label: company.name,
      value: company._id,
      selected: companyIds.includes(company._id),
    }));

    // Lift state up
    setItems(items);
  }, [selectedCompanyIds]);

  const onChange = selectedItems => {
    const selectedItemValues = selectedItems.map(item => item.value);

    // Save changes in localStorage
    localStorage.setItem(localStorageKey, JSON.stringify(selectedItemValues));

    // Infer the selected shop IDs from the selected company IDs
    const selectedShopIds = adminUIShops
      .filter(shop => selectedItemValues.includes(shop.company._id))
      .map(shop => shop._id);

    // Lift state up
    onMerchantSelectionChange({
      selectedCompanyIds: selectedItemValues,
      selectedShopIds,
    });
  };

  const label = isMultiSelect ? t('common.select_merchant_s') : t('common.select_merchant');
  const placeholder = isMultiSelect ? t('common.search_merchants') : t('common.search_merchant');

  return (
    <SelectAllAutocomplete
      items={items}
      limitTags={6}
      label={label}
      placeholder={placeholder}
      selectAllLabel={t('common.all_merchants')}
      onChange={onChange}
      isMultiSelect={isMultiSelect}
      error={error?.selectedCompanyIds}
      {...other}
    />
  );
}

export default MerchantSelectorWithData;
