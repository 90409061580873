import gql from "graphql-tag";

export default gql`
  query couponTriggersQuery(
    $ids: [ID]
    $shopIds: [ID]
    $filter: String
    $after: ConnectionCursor
    $before: ConnectionCursor
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $offset: Int
    $sortOrder: SortOrder
    $sortBy: CouponTriggerSortByField
    $genericFilters: GenericFilterInput
  ) {
    couponTriggers(
      ids: $ids
      shopIds: $shopIds
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
      offset: $offset
      sortOrder: $sortOrder
      sortBy: $sortBy
      genericFilters: $genericFilters
    ) {
      nodes {
        _id
        createdAt
        code
        discount
        calculation {
          method
        }
        conditions {
          enabled
          accountLimit
          redemptionLimit
          audience
          shopperDetails {
            _id
            name
            primaryEmailAddress
          }
          products
          productDetails {
            _id
            title
            shop {
              _id
              name
            }
          }
          order {
            endDate
            startDate
          }
        }
        consumed
        discountId
      }
      totalCount
    }
  }
`;
