import {Box, styled} from '@mui/material';
import SubHeading from "./SubHeading";

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
	height: 24px;
	margin-top: 32px;

	@media (max-width: 768px) {
		margin-top: 24px;
		height: 16px;
	}
`;

const SubTextHR = styled(SubHeading)`
	margin: 0;
	font-size: 18px;

  @media (max-width: 768px) {
		margin: 0;
    font-size: 15px;
  }
`;

const StyledTextWrap = styled(Box)`
	padding: 0 17px;
	background: white;
	z-index: 1;
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	width: min-content;
	top: -15px;
`;

const StyledDivider = styled(Box)`
	width: 100%;
	border-bottom: 2px solid #E9E9E9;
	position: absolute;
	left: 0;
	top: 0;
`;

const HorizontalRuleWithText = ({ innerText, ...other }) => {
	return (
		<Wrapper {...other}>
			{
        innerText
        ? <StyledTextWrap>
						<SubTextHR>
							{innerText}
						</SubTextHR>
				</StyledTextWrap>
					: null
      }
			<StyledDivider />
		</Wrapper>
	);
};

export default HorizontalRuleWithText;
