import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { colors } from "../../../constants";
import React from "react";

const StyledTextField = styled(({ ownerState, ...otherProps }) => <TextField {...otherProps} />)`
  background-color: ${colors.inputNormal};
  border-radius: 20px;
  height: 40px;
  width: 100%;
  
  & .MuiInputBase-root {
    height: 40px;
    padding-right: 22px;
    color: #373735;
    font-family: Mulish, sans-serif;
    font-size: 16px;
    font-weight: 600;
    
    input {
      padding: 0 10px 0 22px;
      order: 1;
    }
    
    .MuiButtonBase-root {
      margin-left: 8px;
      
      &:after {
        content: '';
        position: absolute;
        right: -6px;
        top: 9px;
        height: 22px;
        border-right: 2px solid #BFBFBF;
        border-radius: 1px;
      }
    }
    
    textarea {
      padding-left: 8px;
    }
    
    &:after, &:before {
      display: none;
    }
  }

  & .Mui-focused {
    background-color: ${colors.inputFocus};
    border-radius: 20px;
  }

  fieldset {
    border: none;
  }
`;

export default StyledTextField;
