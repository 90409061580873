import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { colors } from "../../../constants";
import Autocomplete from "../../../UI/Autocomplete";
import TextField from "../../../UI/TextField/TextField";
import Popper from '../../../UI/Popper';
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  label: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    color: colors.black,
    marginLeft: 16,
  },
}));

function MerchantSelectorSingle({onChange, value, placeholder, viewer, ...other}) {
  const classesLocal = useStyles();
  const [list, setList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    let adminUIShops = viewer?.adminUIShops || [];
    adminUIShops = adminUIShops.filter(shop => shop.company._id !== viewer.companyId);
    const uniqueCompanyIds = [];
    const uniqueCompanies = [];

    for (let shop of adminUIShops) {
      if (!uniqueCompanyIds.includes(shop.company._id)) {
        // this is a new company (not a dupe) --> add it to uniqueCompanies
        uniqueCompanies.push({
          _id: shop.company._id,
          name: shop.company.name,
        });
        uniqueCompanyIds.push(shop.company._id);
      }
    }

    const items = uniqueCompanies.map(company => ({
      label: company.name,
      id: company._id,
    }));

    // Lift state up
    setList(items);
  }, [viewer]);

  const handleChange = (_, val) => {
    if (!val) {
      return;
    }
    onChange(val.id);
  }

  const inputRenderer = params => {
    return <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
      }}
      placeholder={placeholder || ''}
    />;
  }

  const PopperComponent = (props) => (
    <Popper {...props}>
      {props.children}
    </Popper>
  );

  const optionRenderer = (option) => {
    return <span className={classesLocal.label}>{option.label}</span>;
  };

  const getOptionLabel = (option) => {
    const compareOption = typeof option === 'string' ? option : option.id;
    return list.find(item => item.id === compareOption)?.label || '';
  }

  const getOptionSelected = (option) => {
    return option.id === value;
  }

  return (
    <Autocomplete
      options={list}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleChange}
      placeholder={placeholder || ''}
      renderInput={inputRenderer}
      PopperComponent={PopperComponent}
      renderOption={optionRenderer}
      value={value}
      {...other}
    />
  );
}

MerchantSelectorSingle.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default MerchantSelectorSingle;
