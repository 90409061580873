import gql from "graphql-tag";

export default gql`
  query couponTriggerQuery($id: ID!) {
    couponTrigger(id: $id) {
      _id
      code
      discount
      calculation {
        method
      }
      conditions {
        enabled
        accountLimit
        redemptionLimit
        audience
        shopperDetails {
          _id
          name
          primaryEmailAddress
        }
        products
        productDetails {
          _id
          title
          shop {
            _id
            name
          }
        }
        order {
          endDate
          startDate
        }
      }
      consumed
      discountId
    }
  }
`;
