import gql from "graphql-tag";
import { ConfiguratorParts } from "./fragments";

export const removePackage = gql`
  mutation removeSubscriptionPackage($id: ID!) {
    removeSubscriptionPackage(id: $id) {
      isDeleted
    }
  }
`;

export const updatePackage = gql`
  mutation updateSubscriptionPackage($id: ID!, $input: UpdateSubscriptionPackageInput!) {
    updateSubscriptionPackage(id: $id, input: $input) {
      _id
      baseOverride {
        enabled
        percent
        period {
          start
          end
        }
        notes
      }
    }
  }
`;

export const addPackage = gql`
  mutation addSubscriptionPackage($input: AddSubscriptionPackageInput!) {
    addSubscriptionPackage(input: $input) {
      _id
    }
  }
`;

export const setSubscriptionOverrides = gql`
  mutation setSubscriptionOverrides(
    $id: ID!,
    $baseRateOverride: OverrideByPercentageInput,
    $additionalRatePerStandardShopOverride: OverrideByPercentageInput,
    $additionalRatePerPremiumShopOverride: OverrideByPercentageInput
  ) {
    setSubscriptionOverrides(
      id: $id,
      baseRateOverride: $baseRateOverride,
      additionalRatePerStandardShopOverride: $additionalRatePerStandardShopOverride,
      additionalRatePerPremiumShopOverride: $additionalRatePerPremiumShopOverride
    ) {
      baseRate {
        changePercentage
        disabled
      }
      additionalRatePerStandardShop {
        changePercentage
        disabled
      }
      additionalRatePerPremiumShop {
        changePercentage
        disabled
      }
    }
  }
`;

export const addPartsToPackage = gql`
  mutation allowConfiguratorPartsToSubscriptionPackage($id: ID!, $input: ConfiguratorPartsInput!) {
    allowConfiguratorPartsToSubscriptionPackage(id: $id, input: $input) {
      _id
      title
      configuratorParts {
        ${ConfiguratorParts}
      }
    }
  }
`

export const removePartsToPackage = gql`
  mutation removeConfiguratorPartsFromSubscriptionPackage($id: ID!, $input: ConfiguratorPartsInput!) {
    removeConfiguratorPartsFromSubscriptionPackage(id: $id, input: $input) {
      _id
      title
      configuratorParts {
        ${ConfiguratorParts}
      }
    }
  }
`
