import { useContext, useEffect } from "react";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import svgDown from "../../icons/chevron-down-black.svg";
import styled, { css } from "styled-components";
import { LanguageContext } from "../../package/src/context/LanguageContext";
import InputErrorMessage from "../Form/InputErrorMessage";
import { useTranslation } from "react-i18next";

const WrappedPhoneInput = styled(PhoneInput)`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 24px;
  
  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    width: 50px;

    .PhoneInputCountrySelect {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0;
      opacity: 0;
      cursor: pointer;
    }

    .PhoneInputCountryIcon {
      width: 30px;
      
      &--border {
        border: 1px solid grey;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  
  .PhoneInputInput {
    width: 100%;
    height: 40px;
    background: #F3F3F3;
    border-radius: 50px;
    border: none;
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #222222;
    padding-left: 22px;
    padding-right: 22px;
    
    &:focus {
      border: none;
      outline: none;
    }
  }
`;

const StyledImage = styled('div')`
  position: absolute;
  top: 0;
  left: 38px;
  height: 46px;
  display: flex;
  align-items: center;
  
  ${(props) => props.isrtl === 'true' && css`
    left: unset;
    right: 35px;
  `}
`;

const Wrapper = styled('div')`
  position: relative;
`;

const StyledErrorBox = styled('div')`
  width: 100%;
  position: absolute;
`;

const PhoneInputWithFlag = ({
  field,
  placeholder,
  error,
  disabled,
  setPhoneError,
  phoneError,
  marginError,
}) => {
  const { isRtl } = useContext(LanguageContext);
  const { t } = useTranslation();

  useEffect(() => {
    const showInvalidError = field.value && { message: 'Invalid phone number' };
    const isPhoneError = !isValidPhoneNumber(field.value) && showInvalidError;

    if (isPhoneError) {
      setPhoneError(isPhoneError);
    } else if (error) {
      setPhoneError(error);
    } else {
      setPhoneError(null);
    }
  }, [field.value, error]);

  const handleChange = (val) => {
    setPhoneError(null);

    if (!val) {
      field.onChange('');
    } else {
      field.onChange(val);
    }
  };

  return (
    <Wrapper>
      <WrappedPhoneInput
        value={field.value}
        onChange={handleChange}
        international
        defaultCountry='US'
        countryCallingCodeEditable={false}
        placeholder={placeholder}
        disabled={disabled}
        error={String(Boolean(phoneError))}
      />
      <StyledImage
        isrtl={String(isRtl)}
      >
        <img
          src={svgDown}
          alt=''
          style={{ width: '11px' }}
        />
      </StyledImage>
      {
        phoneError
          ? <StyledErrorBox>
            <InputErrorMessage marginerror={marginError}>{`${t(phoneError.message)}`}</InputErrorMessage>
          </StyledErrorBox>
          : null
      }
    </Wrapper>

  );
};

export default PhoneInputWithFlag;
