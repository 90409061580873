import React from 'react';
import {Chip, Icon} from '@material-ui/core';
import styled from 'styled-components';
import {colors} from '../../constants';
import svgChipClose from '../../icons/chip_close.svg';

const StyledChip = styled(Chip)`
  background-color: ${colors.blue};
  height: 23px;
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: white;
  cursor: default !important;
  pointer-events: none;
  
  .MuiIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px 0 0;
    pointer-events: all;
  }
`

const CustomChip = (props) => {
  return (
    <StyledChip
      deleteIcon={<Icon>
          <img
            src={svgChipClose}
            alt='X'
          />
        </Icon>}
      {...props}
    />
  );
}

export default CustomChip;
