import { useCallback, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import useAuth from "../../hooks/useAuth";

const useCheckIsCompleteInfo = () => {
  const { onSetPage } = useContext(AuthContext);

  const { getJwtToken } = useAuth();

  const checkAndRedirect = useCallback(async (user) => {
    if (!user) {
      throw new Error('User not found');
    }

    const isCompleted = Boolean(user.attributes?.locale);
    // 'locale' field is required in the 'complete' form.
    // If it is not filled, it means that merchant did not fill out the form

    if (isCompleted) {
      return getJwtToken();
    } else {
      onSetPage('complete');
    }
  }, []);

  return {
    checkAndRedirect,
  };
};

export default useCheckIsCompleteInfo;
