import React from "react";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import StyledButton from "../../../UI/Button";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

export const DialogMutate = ({state, selectedRows, setDialogOpen, mutateRequest, refetch}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getMessage = () => {
    const count = selectedRows.length;

    if (state === 'approved') {
      return count > 1 ? t('merchant.approve_requests_q', { count }) : t('merchant.approve_request_q');
    } else {
      return count > 1 ? t('merchant.decline_requests_q', { count }) : t('merchant.decline_request_q');
    }
  };

  const getSuccessMessage = (count) => {
    if (state === 'approved') {
      return count > 1 ? t('merchant.approved_success_many', { count }) : t('merchant.approved_success');
    } else {
      return count > 1 ? t('merchant.declined_success_many', { count }) : t('merchant.declined_success');
    }
  };

  return (
    <>
      <DialogMessages
        title={t('ui.confirm')}
        message={getMessage()}
      />
      <DialogButtons justifyButtons='center'>
        <StyledButton
          width='180px'
          handleClick={async () => {
            setDialogOpen(false);
            const successes = [];

            try {
              for (const recordId of selectedRows) {
                const { data } = await mutateRequest(recordId, state);
                if (data) successes.push(data);
              }
            } catch(e) {
              enqueueSnackbar(e.message.replace('GraphQL error: ', ''), {variant: 'error'});
              console.log(e.message);
            }

            if (successes.length) {
              refetch();
              enqueueSnackbar(getSuccessMessage(successes.length), { variant: 'success' });
            }
          }}
        >
          {t('ui.yes')}
        </StyledButton>
        <StyledButton
          width='180px'
          mytype='secondary'
          handleClick={() => setDialogOpen(false)}
        >
          {t('ui.no')}
        </StyledButton>
      </DialogButtons>
    </>
  );
}

export const DialogMutateSingle = ({ state, rowId, setDialogOpen, mutateRequest, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return (
    <>
      <DialogMessages
        title={t('ui.confirm')}
        message={state === 'approved' ? t('merchant.approve_q') : t('merchant.decline_q')}
      />
      <DialogButtons justifyButtons='center'>
        <StyledButton
          width='180px'
          handleClick={() => {
            setDialogOpen(false);
            mutateRequest(rowId, state)
              .then(res => {
                if (res) {
                  refetch();
                  enqueueSnackbar(t('merchant.merchant_status_changed_success'), {variant: 'success'});
                }
              })
              .catch((e) => {
                enqueueSnackbar(e.message.replace('GraphQL error: ', ''), {variant: 'error'});
                console.log(e.message);
              })
          }}
        >
          {t('ui.yes')}
        </StyledButton>
        <StyledButton
          width='180px'
          mytype='secondary'
          handleClick={() => setDialogOpen(false)}
        >
          {t('ui.no')}
        </StyledButton>
      </DialogButtons>
    </>
  )
}
