import { addSubscription, switchSubscription } from "../graphql/mutations";
import { useCallback, useContext, useMemo } from "react";
import { ShopEditContext } from "../../../package/src/context/ShopEditContext";
import { useApolloClient } from "@apollo/react-hooks";

const useSubscriptionPayment = (shopId) => {
  const apolloClient = useApolloClient();

  const {
    setProcessing,
    setPaymentStatus,
    setSelectedPackageId,
    setPaymentPending,
    setPaymentError,
    openSubscription,
    setSubscriptionId,
    setPackageId,
    setNeedRefetch,
    selectedPackageId,
    setOpenSubscription,
    setCurrentTab,
    packages,
  } = useContext(ShopEditContext);

  const hasActive = useMemo(() => packages.some(item => item.isActive), [packages]);

  const mutationAddSubscription = useCallback((paymentMethodId) => {
    if (openSubscription) {
      // если уже есть подписка в статусе open, то не нужно создавать новую
      setSubscriptionId(openSubscription._id);
      setPackageId(openSubscription.subscriptionPackageId);
      setNeedRefetch(true);
      return Promise.resolve(openSubscription._id);
    }

    return apolloClient.mutate({
      mutation: addSubscription,
      variables: {
        id: selectedPackageId,
        paymentMethodId,
      }
    })
      .then(res => {
        const subscription = res.data.addSubscription.subscription;
        if (subscription.status === 'pending') {
          throw new Error('Pending subscription');
        }
        setSubscriptionId(subscription._id);
        setPackageId(subscription.subscriptionPackageId);
        setOpenSubscription(subscription);
        setNeedRefetch(true);
        return subscription._id;
      });
  }, [openSubscription, selectedPackageId]);

  const mutationSwitchSubscription = useCallback((id) => {
    return apolloClient.mutate({
      mutation: switchSubscription,
      variables: {
        input: {
          shopId,
          subscriptionId: id,
        },
      }
    })
  }, [shopId]);

  const onProceedPayment = useCallback(async (paymentMethodId) => {
    let error;
    setProcessing(true);

    // провести оплату
    if (hasActive && shopId) {
      // Нажата кнопка Change plan, смена пакета подписки
      // работает только если мы не находимся в режиме создания магазина
      await mutationAddSubscription(paymentMethodId)
        .then((res) => {
          return mutationSwitchSubscription(res);
        })
        .then(() => {
          // open подписки больше нет
          setOpenSubscription(null);
        })
        .catch((e) => {
          error = e;
        });
    } else {
      // Нажата кнопка Get started, добавление подписки
      await mutationAddSubscription(paymentMethodId)
        .catch((e) => {
          error = e;
        });
    }

    // эффекты после оплаты

    if (!error) {
      if (hasActive && shopId) {
        // выбран change plan, показать список планов
        setPaymentStatus('taken');
        setSelectedPackageId(null);
        setCurrentTab('sub');
      } else {
        // выбран Get started, показать панель с кнопкой Create shop
        setPaymentStatus('open');
      }
    } else {
      if (error.message === 'Pending subscription') {
        setPaymentStatus('pending');
        setPaymentPending(true);
      } else {
        setPaymentError(true);
      }
    }

    setProcessing(false);
  }, [mutationAddSubscription, mutationSwitchSubscription, hasActive]);

  return {
    onProceedPayment,
  }
};

export default useSubscriptionPayment;
