import gql from "graphql-tag";

export const gdprQuery = gql`
  query gdpr {
    gdpr {
      terms
      createdAt
      updatedAt
    }
  }
`;

export const stripeListPaymentMethodsQuery = gql`
  query stripeListPaymentMethods($input: StripeListPaymentMethodsInput!) {
    stripeListPaymentMethods(input: $input) {
      id
      brand
      isDefault
      expiryMonth
      expiryYear
      lastFourDigits
    }
  }
`;
