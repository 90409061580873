import gql from "graphql-tag";
import { MerchantRequest } from "./fragments";

export const handleMerchantRequestMutation = gql`
  mutation handleMerchantRequest($id: ID!, $state: MerchantRequestState!) {
    handleMerchantRequest(id: $id, state: $state) {
      ${MerchantRequest}
    }
  }
`;
