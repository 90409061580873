import React, { useContext } from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import CustomButton from "../../../../../UI/Button";
import { ProductVariantsContext } from "../../../../../package/src/context/ProductVariantsContext";
import CircularProgress from "@material-ui/core/CircularProgress";

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  border-bottom: 1px solid #E9E9E9;
  padding-bottom: 24px;
`;

const HeaderText = styled(Typography)`
  color: #222;
  font-family: Fredoka, sans-serif;
  font-size: 22px;
  font-weight: 500;
`;

const ButtonsBox = styled('div')`
  display: flex;
  gap: 16px;
`;

const Header = ({ onCancel, onDone }) => {
  const { saving, isChanged } = useContext(ProductVariantsContext);

  return (
    <Wrapper>
      <HeaderText>Variants</HeaderText>
      <ButtonsBox>
        <CustomButton
          mytype='secondary'
          minwidth='98px'
          handleClick={onCancel}
          disabled={saving}
        >
          Cancel
        </CustomButton>
        <CustomButton
          minwidth='86px'
          handleClick={onDone}
          disabled={saving || !isChanged}
        >
          {
            saving ?
              <CircularProgress color='inherit' size={24}/>
              : <>Done</>
          }
        </CustomButton>
      </ButtonsBox>
    </Wrapper>
  );
};

export default Header;