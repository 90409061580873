import gql from "graphql-tag";

export const createTransactionGQL = gql`
  mutation addTransactionBetweenMerchantAndGodmode(
    $input: AddTransactionBetweenMerchantAndGodmodeInput!
  ) {
    addTransactionBetweenMerchantAndGodmode(input: $input) {
      transactionBetweenMerchantAndGodmode {
        _id
      }
    }
  }
`;

export const updateTransactionGQL = gql`
  mutation updateTransactionBetweenMerchantAndGodmode(
    $id: ID!
    $input: UpdateTransactionBetweenMerchantAndGodmodeInput!
  ) {
    updateTransactionBetweenMerchantAndGodmode(id: $id, input: $input) {
      transactionBetweenMerchantAndGodmode {
        _id
      }
    }
  }
`;
