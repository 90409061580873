import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
} from "@material-ui/core";
import UIContext from "../context/UIContext";
import chevronRight from '../../../icons/chevron-right.svg';
import chevronLeft from '../../../icons/chevron-left.svg';
import { LanguageContext } from "../context/LanguageContext";
import ComplexMenu from "./ComplexMenu";
import { useTranslation } from "react-i18next";

const ComplexListItem = ({label, IconComponent, simpleListItems, classes}) => {
  const { onCloseNavigationDrawer } = useContext(UIContext);
  const { isRtl } = useContext(LanguageContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem
        button
        classes={{
          root: isRtl ? classes.listItemRootRtl : classes.listItemRoot,
          selected: classes.selected,
          button: classes.listItemButton,
        }}
        onClick={handleClick}
        selected={open}
      >
        {IconComponent && (
          <ListItemIcon className={isRtl ? classes.iconRtl : classes.icon}>
            <IconComponent />
          </ListItemIcon>
        )}
        <ListItemText disableTypography className={isRtl ? classes.listItemTextRtl : classes.listItemText}>
          {t(label)}
        </ListItemText>
        {
          open ?
            <>
              <div className={isRtl ? classes.brickRtl : classes.brick} />
              <Icon className={classes.chevron}><img src={isRtl ? chevronRight : chevronLeft} alt=""/></Icon>
            </> :
            <Icon className={classes.chevron}><img src={isRtl ? chevronLeft : chevronRight} alt=""/></Icon>
        }
      </ListItem>
      <ComplexMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        isRtl={isRtl}
      >
        {
          simpleListItems.map(item => {
            const IconComponent = item.props.IconComponent;
            return <ListItem 
              key={item.key}
              button
              onClick={() => {
                handleClose();
                history.push(item.props.href || item.props.path);
                onCloseNavigationDrawer();
              }} 
            >
              {IconComponent && (
                <ListItemIcon className={isRtl ? classes.iconCollapseRtl : classes.iconCollapse}>
                  <IconComponent />
                </ListItemIcon>
              )}
              {t(item.props.navigationItemLabel)}
              </ListItem>
            }
          )
        }
      </ComplexMenu>
    </>
  );
}

export default ComplexListItem;
