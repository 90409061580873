import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useAttributes = () => {
  const { t } = useTranslation();

  const typeOptions = useMemo(() => [
    {
      value: 'string',
      name: 'String',
    },
    {
      value: 'text',
      name: 'Text',
    },
    {
      value: 'number',
      name: 'Number',
    },
    {
      value: 'list',
      name: 'List',
    },
    {
      value: 'checkbox',
      name: 'Yes / No',
    },
    {
      value: 'colorPicker',
      name: 'Color',
    },
    {
      value: 'datePicker',
      name: 'Date',
    },
  ], [t]);

  return {
    typeOptions,
  }
};

export default useAttributes;
