const filterStreets = (selectedCats, categoryIds) => {
  // вызывается, когда меняются выбранные категории, либо опции улиц
  return selectedCats.filter((d) => {
    let isInclude = false;
    for (let i = 0; i < categoryIds.length; i++) {
      if (d.categories.includes(categoryIds[i])) {
        isInclude = true;
        break;
      }
    }
    return isInclude;
  });
}

export default filterStreets;
