import parseAttributeValue from "./parseAttributeValue";
import config from "../../../config";

/**
 * Mapping variants into a table after receiving them from the server
 * A variant is entered into the variant editor only if it has a non-empty attributes array
 * @param variants
 * @returns {*}
 */

const mapVariants = (variants) => {
  return variants
    .filter((k) => k.attributes.length)
    .map((item) => ({
      id: item._id,
      price: item.pricing.price ?? 0,
      compareAtPrice: item.pricing.compareAtPrice ?? '',
      sku: item.sku ?? '',
      barcode: item.barcode ?? '',
      weight: item.weight ?? 0,
      width: item.width ?? 0,
      length: item.length ?? 0,
      height: item.height ?? 0,
      media: item.media?.map((record) => ({
        id: record._id,
        path: `${config.PUBLIC_FILES_BASE_URL}${record.URLs.small || record.URLs.original}`,
      })) || [],
      quantity: item.simpleInventory?.inventoryInStock || 0,
      attributeValues: item.attributes.map((obj) => ({
        attributeId: obj.attributeId,
        attributeName: obj.attribute.name,
        inputType: obj.attribute.inputType,
        value: parseAttributeValue(obj.attribute.inputType, obj.value),
      })),
    }));
}

export default mapVariants;
