import styled from "styled-components";
import { ToggleButton } from "@material-ui/lab";

const StyledToggleButton = styled(ToggleButton)`
  width: 68px;
  height: 68px;
  background: #F3F3F3;
  border: 1px solid #BFBFBF !important;
  border-radius: 11px !important;

  &.Mui-selected {
    border: 2px solid #4270FF !important;
  }
`;

export default StyledToggleButton;
