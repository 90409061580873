import React from "react";
import PropTypes from "prop-types";

// Reaction Commerce
import Button from "@reactioncommerce/catalyst/Button";

// Components
import PrimaryAppBar from "./PrimaryAppBar/PrimaryAppBar";

/**
 * Tag toolbar component
 * @param {Object} props Component props
 * @returns {React.Component} Tag toolbar component
 */
function TagToolbar(props) {
  const { canBeDeleted, onDelete, onCancel, onSave, title, buttonText } = props;

  return (
    <PrimaryAppBar title={title}>
      {canBeDeleted && (
        <Button isTextOnly={true} onClick={onDelete}>
          {buttonText.delete}
        </Button>
      )}
      {onCancel && (
        <Button variant="outlined" onClick={onCancel}>
          {buttonText.cancel}
        </Button>
      )}
      {onSave && (
        <Button variant="contained" color="primary" onClick={onSave}>
          {buttonText.save}
        </Button>
      )}
    </PrimaryAppBar>
  );
}

TagToolbar.propTypes = {
  canBeDeleted: PropTypes.bool,
  isNew: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  title: PropTypes.string,
  buttonText: PropTypes.shape({
    delete: PropTypes.string,
    cancel: PropTypes.string,
    save: PropTypes.string,
  }),
};

TagToolbar.defaultProps = {
  allowsDeletion: true,
  isNew: true,
  buttonText: {
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
  },
};

export default TagToolbar;
