import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core";
import { Query } from "react-apollo";
import GenericErrorBoundary from "../GenericErrorBoundary";

const defaultLogo = "/reaction-logo-circular.svg";

const getShop = gql`
  query getShop($id: ID!) {
    shop(id: $id) {
      brandAssets {
        navbarBrandImage {
          large
        }
      }
      name
      shopLogoUrls {
        primaryShopLogoUrl
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: '348px',
    height: '39px',
  },
  logoName: {
    color: theme.palette.colors.black15,
  },
}));

/**
 * ShopLogoWithData
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function ShopLogoWithData({ shopIds, size }) {
  const classes = useStyles();

  if (!shopIds) {
    return (
      <img
        alt="Reaction Commerce"
        className={classes.logo}
        src={defaultLogo}
      />
    );
  }

  return (
    <GenericErrorBoundary>
      <Query query={getShop} variables={{ id: shopIds[0] }}>
        {({ loading, data }) => {
          if (loading) return null;
          if (data && data.shop) {
            const { shop } = data;
            const customLogoFromUpload =
              shop.brandAssets &&
              shop.brandAssets.navbarBrandImage &&
              shop.brandAssets.navbarBrandImage.large;
            const customLogoFromUrlInput =
              shop.shopLogoUrls && shop.shopLogoUrls.primaryShopLogoUrl;

            return (
              <img
                alt={shop.name}
                className={classes.logo}
                src={
                  customLogoFromUrlInput || customLogoFromUpload || defaultLogo
                }
                width={size}
              />
            );
          }

          // Return null if the shop data couldn't be found.
          return null;
        }}
      </Query>
    </GenericErrorBoundary>
  );
}

ShopLogoWithData.propTypes = {
  classes: PropTypes.object,
  shopIds: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ShopLogoWithData.defaultProps = {
  size: 60,
};

export default ShopLogoWithData;
