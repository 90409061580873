import gql from "graphql-tag";

export const getZonesQuery = gql`
  query zones {
    zones {
      data {
        id
        name
      }
    }
  }
`;
