import React, { useState } from "react";
import styled from 'styled-components';
import { IconButton, Popover } from "@material-ui/core";
import svgHelp from "../../icons/help.svg";
import { colors } from "../../constants";

const StyledIconButton = styled(IconButton)`
  padding: 2px;
`

const RenderPaper = React.forwardRef(({children, ...other}, ref) => {
  return <div
    ref={ref}
    {...other}
    style={{
      background: '#FFFFFF',
      boxShadow: '0 4px 20px #D8DADF',
      borderRadius: 20,
      padding: 18,
      maxWidth: 280,
      userSelect: 'none',
    }}
  >
    {children}
  </div>
})

const IconHelp = ({helpText}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <StyledIconButton aria-describedby={id} onClick={handleClick}>
        <img src={svgHelp} alt=''/>
      </StyledIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ component: RenderPaper}}
      >
        <p style={{
          fontFamily: 'Mulish',
          fontWeight: 400,
          fontSize: 14,
          color: colors.black,
          margin: 0,
        }}>
          {helpText}
        </p>
      </Popover>
    </>
  );
}

export default IconHelp;