const convertPossibleKey = (key) => {
  switch (key) {
    case 'doorIds': return 'door';
    case 'firstFloorIds': return 'floorfirst';
    case 'secondFloorIds': return 'floorsecond';
    case 'signAreaIds': return 'name';
    case 'roofIds': return 'roof';
    case 'umbrellaIds': return 'umbrella';
    default: return key;
  }
}

export default convertPossibleKey;
