import styled from "styled-components";
import ToggleButton from "../../configurator/components/StyledToggleButton";

const StyledToggleButtonText = styled(ToggleButton)`
  margin-bottom: 25px;
  
  .significant {
    position: absolute;
    bottom: -30px;
    margin: 0;
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    text-transform: none;
    white-space: nowrap;
  }

  &.Mui-selected {
    .significant {
      color: #4270FF;
      font-weight: 700;
      bottom: -31px;
    }
  }
`;

export default StyledToggleButtonText;
