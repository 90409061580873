import { IconNavShoppers, IconNavAddPerson } from "../../icons";
import ShoppersPage from "./pages/ShoppersPage";

import { constants } from "../../constants.js";
import InvitationsPage from "./pages/InvitationsPage";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 60,
    path: "/shoppers",
    MainComponent: ShoppersPage,
    IconComponent: IconNavShoppers,
    navigationItemLabel: 'menu.shoppers',
    showShopSelector: false,
    collapse: {
      title: 'menu.shoppers',
      IconComponent: IconNavShoppers,
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });

  registerRoute({
    group: "navigation",
    priority: 60,
    path: "/invitations",
    MainComponent: InvitationsPage,
    IconComponent: IconNavAddPerson,
    navigationItemLabel: 'menu.invitations',
    showShopSelector: false,
    collapse: {
      title: 'menu.shoppers',
      IconComponent: IconNavShoppers,
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
    ],
  });
}
