import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants';

const Wrapper = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  padding: 0;
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.blue};
  margin: ${props => props.margin};

  @media all and (max-width: 1279px) {
    margin: 0;
  }
`

const PageLink = ({ children, margin = '0 0 32px 0', handleClick}) => {
  return (
    <Wrapper
      margin={margin}
      onClick={handleClick}
      type='button'
    >
      {children}
    </Wrapper>
  );
}

export default PageLink;
