import { InputBase, styled, Typography } from "@mui/material";

export const WosqHeading = styled(Typography)`
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  color: #000000;
  margin-bottom: 18px;

  @media (max-width: 500px) {
    font-size: 24px;
  }
`;

export const EntranceVariantsContainer = styled('div')`
  max-width: 872px;
  width: 100%;
  margin: 24px auto 0;
  display: flex;
  gap: 18px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FormHeaderWrap = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 24px;
`;

export const FormHeaderText = styled(Typography)`
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: black;
`;

export const SignupAuthNumbersLine = styled('div')`
	display: flex;
	gap: 12px;
	align-items: center;
	justify-content: center;
  width: 100%;
  margin: 40px 0;

  @media all and (max-width: 500px) {
    margin: 27px 0 32px;
  }
`;

export const SignupNumberInput = styled(InputBase)`
	width: 37px;
	height: 42px;
	background: #f3f3f3;
	border-radius: 11px;
	padding: 13px;
	font-family: 'Fredoka', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
`;

export const FormDescriptionText = styled(Typography)`
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #222222;
  margin-top: -8px;
`;

export const WosqDescriptionText = styled(Typography)`
  margin: 0 0 57px;
  line-height: 1.5;
  font-family: Mulish, sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: black;
`;
