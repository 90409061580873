import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import httpApi from 'i18next-http-backend';
import i18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

const configuredI18n = i18n
  .use(initReactI18next)
  .use(httpApi)
  .use(i18nextBrowserLanguageDetector)

/**
 * Init i18next
 * @returns {undefined}
 */
export async function initI18next() {
  const namespaceResponse = await fetch('/locales/namespaces.json');
  const allTranslationNamespaces = await namespaceResponse.json();

  await configuredI18n.init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    supportedLngs: ['en', 'ar'],
    detection: {
      order: ["localStorage", "querystring", "navigator"]
    },
    ns: allTranslationNamespaces,
    defaultNS: "common",
    fallbackNS: allTranslationNamespaces,
    fallbackLng: 'en',
  });
}
