import React from 'react';
import styled from 'styled-components';
import {Box } from '@material-ui/core';

const Wrapper = styled(Box)`
  @keyframes circle1 {
    0%, 100% {
      left: 0;
      width: 10px;
      height: 10px;
      z-index: -1;
    }
    32% {
      z-index: -1;
    }
    33% {
      left: 50%;
      width: 6px;
      height: 6px;
      z-index: 0;
    }
    65% {
      z-index: 0;
    }
    66% {
      left: 100%;
      width: 10px;
      height: 10px;
      z-index: 1;
    }
    99% {
      z-index: 1;
    }
  }

  @keyframes circle2 {
    0%, 100% {
      left: 50%;
      width: 6px;
      height: 6px;
      z-index: 0;
    }
    32% {
      z-index: 0;
    }
    33% {
      left: 100%;
      width: 10px;
      height: 10px;
      z-index: 1;
    }
    65% {
      z-index: 1;
    }
    66% {
      left: 0;
      width: 10px;
      height: 10px;
      z-index: -1;
    }
    99% {
      z-index: -1;
    }
  }

  @keyframes circle3 {
    0%, 100% {
      left: 100%;
      width: 10px;
      height: 10px;
      z-index: 1;
    }
    32% {
      z-index: 1;
    }
    33% {
      left: 0;
      width: 10px;
      height: 10px;
      z-index: -1;
    }
    65% {
      z-index: -1;
    }
    66% {
      left: 50%;
      width: 6px;
      height: 6px;
      z-index: 0;
    }
    99% {
      z-index: 0;
    }
  }
  
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: transparent;
  position: relative;
  z-index: 2;

  .preloader-container {
    width: 44px;
    height: 10px;
    position: relative;

    .preloader-circle {
      border-radius: 50%;
      position: absolute;
    }

    .preloader-circle-1 {
      background-color: #ab7cff;
      animation: circle1 1.5s ease-in-out infinite;
    }

    .preloader-circle-2 {
      background-color: #32d3ea;
      animation: circle2 1.5s ease-in-out infinite;
    }

    .preloader-circle-3 {
      background-color: #7650f4;
      animation: circle3 1.5s ease-in-out infinite;
    }
  }
`;

const PreloaderAnimated = () => {
  return (
    <Wrapper>
      <div className='preloader-container'>
        <div className='preloader-circle preloader-circle-1' />
        <div className='preloader-circle preloader-circle-2' />
        <div className='preloader-circle preloader-circle-3' />
      </div>
    </Wrapper>
  );
};

export default PreloaderAnimated;
