export const BEGIN_POINT = "beginPoint";
export const END_POINT = "endPoint";
export const COORDS = "coords";
export const NAME = "name";
export const IS_BEGIN = "isBegin";
export const STREETS = "streets";
export const UUID = "uuid";
export const TYPE = "type";
export const STREET_CATEGORIES = "categories";
export const CONNECTIONS = "connections";
export const STREET_UUIDS = "streetUuids";
export const POINT_UUIDS = "pointUuids";
export const CONNECTION_UUID = "connection";
export const HOUSE_TYPE = "houseType";
export const HOUSES = "houses";
export const HOUSE_HOLDER_ID = "holderId";
export const SELECTED_PLACES = "selectedPlaces";
export const STREET_REGULAR_TYPE = "regular";
export const STREET_STATIC_TYPE = "static";
