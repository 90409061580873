import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const useProductAttributesColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'attributeName',
      label: 'Attribute Name',
      isBold: true,
      sortable: false,
    },
    {
      name: 'type',
      label: 'Type',
      sortable: false,
    },
    {
      name: 'values',
      label: 'Available options',
      sortable: false,
    },
    {
      name: 'productTypes',
      label: 'Linked Product types',
      sortable: false,
      hideValues: true,
    },
    {
      name: 'options',
    }
  ], [t]);
};

export default useProductAttributesColumns;
