import * as Yup from 'yup';

export const DiscountSchema = Yup.object().shape({
  code: Yup.string()
    .when('isRandom', {
      is: false,
      then: Yup.string().required('errors.field_required'),
    }),
  type: Yup.string()
    .required('errors.field_required'),
  amount: Yup.number()
    .transform((value, original) => {
      return (original === 0 ? undefined : value)
    })
    .required('errors.field_required'),
})

export const TriggerSchema = Yup.object().shape({
  code: Yup.string()
    .required('errors.field_required'),
  type: Yup.string()
    .required('errors.field_required'),
  amount: Yup.number()
    .transform((value, original) => {
      return (original === 0 ? undefined : value)
    })
    .required('errors.field_required'),
})

export const GlobalSchema = Yup.object().shape({
  name: Yup.string()
    .required('errors.field_required'),
  percentage: Yup.number()
    .transform((value, original) => {
      return (original === 0 ? undefined : value)
    })
    .required('errors.field_required'),
})
