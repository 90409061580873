import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import photo from '../../../../../images/Photo.png';
import ProjectCellButtons from "./ProjectCellButtons";
import ProjectCellText from "./ProjectCellText";

const Wrapper = styled('div')`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

const ImageBox = styled('div')`
  width: 315px;
  height: 177px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
`;

const Image = styled('div')`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: ${props => props.disabled || props.active === 'true' ? 'default' : 'pointer'};
  opacity: ${props => props.disabled ? 0.4 : 1};
`;

const SelectedBorder = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
  border: 2px solid #4270FF;
  pointer-events: none;
`;

const InnerBorder = styled('div')`
  width: 100%;
  height: 100%;
  border-radius: 14px;
  border: 6px solid #FFF;
`;

const ProjectCell = ({ data, onClick, disabled, active, onRename, onDelete, onEdit }) => {
  const [hover, setHover] = useState(false);
  const [hoverImage, setHoverImage] = useState(false);

  const handleClick = () => {
    if (!disabled && !active && onClick) {
      onClick(data.id);
    }
  }

  return (
    <Wrapper>
      <ImageBox>
        <Image
          onClick={handleClick}
          disabled={disabled}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseMove={() => {
            setHoverImage(true);
          }}
          onMouseLeave={() => {
            setHover(false);
            setHoverImage(false);
          }}
          style={{
            backgroundImage: `url(${photo})`,
          }}
        />
        {
          active && !disabled && <SelectedBorder>
            <InnerBorder />
          </SelectedBorder>
        }
        {
          !disabled && hoverImage && <ProjectCellButtons
            onMouseEnter={() => setHoverImage(true)}
            onMouseLeave={() => setHoverImage(false)}
            onDelete={onDelete}
            onRename={onRename}
            onEdit={onEdit}
          />
        }
      </ImageBox>
      <ProjectCellText
        onClick={handleClick}
        disabled={disabled}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        hover={String(hover)}
        active={String(active)}
      >
        {data.name}
      </ProjectCellText>
    </Wrapper>
  );
};

ProjectCell.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    picSrc: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
}

export default ProjectCell;
