import React, { useContext } from "react";
import {Autocomplete} from '@material-ui/lab';
import {Icon} from '@material-ui/core';
import styled, { css } from "styled-components";
import svgDown from '../../icons/chevron-down-black.svg';
import {colors} from '../../constants';
import svgChipClose from '../../icons/chip_close.svg';
import InputErrorMessage from "../Form/InputErrorMessage";
import { LanguageContext } from "../../package/src/context/LanguageContext";
import { useTranslation } from "react-i18next";

const errorCss = css`
  background-color: ${colors.redLight10};
  border: 1px solid ${colors.redLight};

  & .Mui-focused {
    background-color: transparent;
  }
`

const StyledAutocomplete = styled(Autocomplete)`
  box-shadow: 0 7px 20px rgba(183, 195, 211, 0.25);
  border-radius: 21px;
  margin-bottom: 24px;
  
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-left: ${props => props.multiple ? '9px' : '16px'};
  }

  &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 8px;
  }
  
  & .MuiButtonBase-root {
    margin-right: 8px;
    
    .popupIcon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  fieldset {
    border: none;
  }

  & .MuiChip-root {
    background-color: ${colors.blue};
    height: 23px;
    font-family: 'Fredoka', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    cursor: default;
    
    & .MuiIcon-root {
      margin: 0 -5px 0 0;
    }
  }

  & .MuiInputBase-root {
    > span {
      color: ${colors.textGrey};
    }
    ${props => props.error && errorCss}
  }
  
  ${props => props.isrtl === 'true' && css`
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
      right: unset;
      left: 9px;

      .MuiButtonBase-root {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  `}
`

const StyledIconChipClose = styled(Icon)`
  height: 100%;
  display: flex;
  align-items: center;
`;

const CustomAutocomplete = ({ error, ...other }) => {
  const { isRtl } = useContext(LanguageContext);
  const { t } = useTranslation();

  return (
    <>
      <StyledAutocomplete
        {...other}
        isrtl={String(isRtl)}
        error={error}
        popupIcon={<span className='popupIcon'><img src={svgDown} alt=''/></span>}
        ChipProps={{
          deleteIcon: <StyledIconChipClose>
            <img src={svgChipClose} alt=''/>
          </StyledIconChipClose>
        }}
        noOptionsText={t('ui.no_options')}
      />
      {
        error ?
          <InputErrorMessage>{t(error.message)}</InputErrorMessage>
          : null
      }
    </>
  );
}

export default CustomAutocomplete;
