import React, { useEffect, useState } from "react";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import StyledButton from "../../../UI/Button/Button";
import cancelOrderItemMutation from "../graphql/mutations/cancelOrderItem";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "../../../package/src";
import { useTranslation } from "react-i18next";

const DialogCancelOrder = ({ declineAction, order, refetch }) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  // order by referenceId, NOT by _id
  // 'order' can be empty object

  useEffect(() => {
    // wait until full version of 'order' is loaded
    if (Object.keys(order).length) {
      setLoading(false);
    }
  }, [order])

  const cancelOrderMutation = (item, orderId) => {
    return apolloClient.mutate({
      mutation: cancelOrderItemMutation,
      variables: {
        cancelQuantity: item.quantity,
        itemId: item._id,
        orderId,
        reason: t('orders.canceled_reason'),
      },
    })
  }

  return (
    <>
      <DialogMessages
        title={t('orders.cancel_order')}
        message={t('orders.cancel_order_q')}
      />
      <DialogButtons justifyButtons='center'>
        <StyledButton
          width='180px'
          disabled={loading}
          handleClick={async() => {
            declineAction();
            const { fulfillmentGroups } = order;
            const successes = [];

            try {
              for (const group of fulfillmentGroups) {
                for (const item of group.items.nodes) {
                  const {data} = await cancelOrderMutation(item, order._id);
                  if (data) successes.push(data);
                }
              }
            } catch(e) {
              if (e.message.includes("is not one of: new")) {
                enqueueSnackbar(t('orders.cancel_order_error'), {variant: 'error'});
              } else {
                enqueueSnackbar(e.message.replace('GraphQL error: ', ''), {variant: 'error'});
              }
              console.log(e.message);
            }

            if (successes.length) {
              enqueueSnackbar(t('orders.cancel_order_success'), {variant: 'success'});
              refetch();
            }
          }}
        >
          {t('ui.yes')}
        </StyledButton>
        <StyledButton
          width='180px'
          mytype='secondary'
          handleClick={declineAction}
        >
          {t('ui.no')}
        </StyledButton>
      </DialogButtons>
    </>
  );
}

export default DialogCancelOrder;
