import * as Yup from 'yup';

export const TagSchema = Yup.object().shape({
  displayTitle: Yup.string()
    .required('errors.field_required'),
  slug: Yup.string(),
  isStorefront: Yup.boolean(),
  uploadPreview: Yup.string(),
  mediaURL: Yup.string(),
  keywords: Yup.string(),
  products: Yup.array(),
  description: Yup.string(),
  graphTitle: Yup.string(),
  graphDescription: Yup.string(),
  graphURL: Yup.string(),
  graphImageURL: Yup.string(),
  graphLocate: Yup.string(),
  graphFacebook: Yup.string(),
})
