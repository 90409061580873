import Status, { blueCss, greyCss, redCss, yellowCss } from "../../../UI/Status/Status";
import moment from "moment/moment";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { constants } from "../../../constants";
import getStatus from "../../../package/src/utils/getStatus";

const statusColorScheme = [
  {
    types: ['draft'],
    css: greyCss,
  },
  {
    types: ['banned'],
    css: redCss,
  },
  {
    types: ['suspended'],
    css: yellowCss,
  },
  {
    types: ['pending'],
    css: blueCss,
  },
];

const renderStatus = (statusObj, isExport) => {
  if (!statusObj.value) {
    return '';
  }
  if (isExport) {
    return statusObj.label;
  }
  return <Status
    type={statusObj.value}
    content={statusObj.label}
    colorScheme={statusColorScheme}
  />;
}

const filterTransitionButton = (value, optionValue, godmode) => {
  // if status has a specific value, we need to hide multiple option buttons
  switch (optionValue) {
    case constants.OPTIONS.approve: return value === 'pending' && godmode; // only admin can see these options
    case constants.OPTIONS.decline: return value === 'pending' && godmode;
    case constants.OPTIONS.ban: return value === 'active' && godmode;
    case constants.OPTIONS.sentToApprove: return value === 'banned';
    default: return true;
  }
}

export const useModifiers = () => {
  const { t } = useTranslation();

  const getVisible = useCallback((isActive, statusValue) => {
    const myDefault = {
      value: 'invisible',
      label: t('shops.invisible'),
    };
    if (!isActive) {
      return myDefault;
    }
    const arr = ['draft', 'pending', 'banned', 'suspended'];
    if (arr.includes(statusValue)) {
      return myDefault;
    }
    return {
      value: 'visible',
      label: t('shops.visible'),
    };
  }, [t])

  const modifyData = useCallback((godmode, data, method, options) => {
    if (!data) {
      return [];
    }
    return data.map(item => {
      const eCommerceType = item.eCommerceEnabled ? 'yes' : 'no';
      const eCommerceText = item.eCommerceEnabled ? t('ui.yes') : t('ui.no');
      const allStatuses = t('statuses', { returnObjects: true });
      const statusObj = getStatus(allStatuses, item.status);
      const isExport = method === 'export';
      const visibleObj = getVisible(item.active, statusObj.value);
      const result = {
        id: item._id,
        shopName: item.name,
        category: item.getShopCategory?.name || '',
        eCommerce: isExport ? eCommerceText : <Status
          content={eCommerceText}
          type={eCommerceType}
        />,
        merchant: item.company.name,
        ratePre: '$' + item.subscription.preOverride?.rate || 0,
        ratePost: '$' + item.subscription.postOverride?.rate || 0,
        commissionPre: item.subscription.preOverride?.commissionPercentage || 0,
        commissionPost: item.subscription.postOverride?.commissionPercentage || 0,
        billing: item.subscription.billingPeriodInDays,
        isVisible: isExport ? visibleObj.label : <Status
          content={visibleObj.label}
          type={visibleObj.value}
        />,
        status: renderStatus(statusObj, isExport),
        createdAt: moment(item.createdAt).format("MM.DD.YYYY"),
      }

      // if table
      if (!isExport && options) {
        result.options = options;

        result.modifyOptions = (list) => {
          const isShopHasPlaceAtMap = item.subscriptionPackage?.placesOnStreets
            .some(street => street.places
              .some(place => place.holder?.holderId === item._id)
            );

          return list.filter(option => {
            if (option.value === constants.OPTIONS.editOverrides) {
              return Boolean(godmode);
            }

            if (option.value === constants.OPTIONS.copyLink) {
              return isShopHasPlaceAtMap;
            }

            return filterTransitionButton(statusObj.value, option.value, godmode);
          });
        }

        return result;
      }

      // if 'export' button
      result.__typename = item.__typename;
      return result;
    });
  }, [t, getVisible]);

  return {
    modifyData,
  }
};
