import gql from "graphql-tag";

export const mediaRecordsQuery = gql`
  query mediaRecords(
    $mediaRecordIds: [ID]
    $shopId: ID!
    $first: ConnectionLimitInt
    $last: ConnectionLimitInt
    $before: ConnectionCursor
    $after: ConnectionCursor
    $offset: Int
    $filter: String
    $sortOrder: SortOrder
    $sortBy: MediaGalleryItemSortByField
    $genericFilters: GenericFilterInput
  ) {
    mediaRecords(
      mediaRecordIds: $mediaRecordIds
      shopId: $shopId
      first: $first
      last: $last
      before: $before
      after: $after
      offset: $offset
      filter: $filter
      sortOrder: $sortOrder
      sortBy: $sortBy
      genericFilters: $genericFilters
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        _id
        metadata {
          ownerId
        }
        original {
          name
          size
          type
          updatedAt
          uploadedAt
        }
        imageURLs {
          original
          small
        }
      }
      totalCount
    }
  }
`;
