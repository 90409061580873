import gql from "graphql-tag";

export const mutationCreateAttribute = gql`
  mutation createAttribute($input: CreateAttributeInput!) {
    createAttribute(input: $input) {
      data {
        id
        name
        inputType
      }
    }
  }
`;

export const mutationUpdateAttribute = gql`
  mutation updateAttribute($input: UpdateAttributeInput!) {
    updateAttribute(input: $input) {
      data {
        id
        name
        inputType
      }
    }
  }
`;

export const mutationRemoveAttribute = gql`
  mutation removeAttribute($input: RemoveAttributeInput!) {
    removeAttribute(input: $input) {
      data {
        id
      }
    }
  }
`;
