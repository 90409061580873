import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useSubscriptionColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'title',
      label: t('subscription.title'),
      isBold: true,
    },
    {
      name: 'description',
      label: t('subscription.description'),
    },
    {
      name: 'visibility',
      label: t('subscription.visibility'),
      sortable: 'isVisibleToMerchants',
    },
    {
      name: 'rate',
      label: t('subscription.rate_per_period_uppercase'),
      postfix: ' (USD)',
      sortable: 'baseRate',
    },
    {
      name: 'period',
      label: t('subscription.period'),
      postfix: ` (${t('subscription.in_days')})`,
      sortable: 'durationInDays',
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    {
      name: 'options'
    }
  ], [t]);
};
