import { constants } from "../../../../constants.js";

/**
 * This function will take all possible routes and return only the ones that
 * this `viewer` is actually allowed to see
 * @param {*} routes all registered routes by all plugins
 * @param {*} viewer user information
 * @returns
 */
const filterRoutesBasedOnAccountType = (routes, viewer) => {
  // Only godmode gets to see godmodeOnly routes
  if (viewer?.type !== "godmode") {
    routes = routes.filter(route => !route.godmodeOnly);
  }

  // Only merchant gets to see merchantOnly routes
  if (viewer?.type !== "merchant") {
    routes = routes.filter(route => !route.merchantOnly);
  }

  // Get only the slugs from the viewer's groups
  const viewerGroupSlugs = viewer?.groups?.nodes?.map(group => group.slug) || [];

  // Filter keeps the route if value returned is true and discard the route if
  // value returned is false
  routes = routes.filter(
    // Some will return true if at least one of the returns is true
    // Note: always allow system-manager to access everything (that is the god of all gods - ZEUS!!!!!)
    route =>
      !route?.groupSlugs ||
      [...route?.groupSlugs, constants.ACCOUNT_TYPES.systemManager].some(groupSlug =>
        viewerGroupSlugs.find(gs => gs === groupSlug)
      )
  );

  return routes;
};

export default filterRoutesBasedOnAccountType;
