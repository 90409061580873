import React from 'react';
import {TextField} from '@material-ui/core';
import styled from 'styled-components';
import {colors} from '../../constants';

const StyledTextField = styled(TextField)`
  background-color: ${colors.inputNormal};
  border-radius: 11px;
  height: 42px;
  width: 42px;
  
  & .MuiInputBase-root {
    height: 42px;

    input {
      padding: 0;
      text-align: center;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  & .Mui-focused {
    background-color: ${colors.inputFocus};
    border-radius: 11px;
  }

  fieldset {
    border: none;
  }
`

const FormInputMiniNumber = ({...other}) => {
  return (
    <StyledTextField
      {...other}
      type='number'
      inputProps={{ min: 0, max: 99 }}
      variant="outlined"
    />
  );
}

export default FormInputMiniNumber;