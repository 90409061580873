import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/core";
import { defaultTheme } from "@reactioncommerce/catalyst";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { LanguageProvider } from "../context/LanguageContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import 'dayjs/locale/en';
import AppPages from "./AppPages";
import { AuthProvider } from "../context/AuthContext";

/**
 * BAse application component containing providers for theme, auth, routing and more
 * @param {Object} props Props for configuring various providers and base components
 * @returns {React.ReactElement} App
 */

function App(props) {
  return <LanguageProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en'>
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <AuthProvider>
            <ApolloProvider client={props.apolloClient}>
              <AppPages {...props} />
            </ApolloProvider>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  </LanguageProvider>;
}

App.propTypes = {
  DashboardComponent: PropTypes.elementType,
  apolloClient: PropTypes.object,
  dashboardComponentProps: PropTypes.object,
  plugins: PropTypes.arrayOf(PropTypes.object),
  snackbarProviderProps: PropTypes.object
};

export default App;
