import React from 'react';
import styled from 'styled-components';

const StyledImg = styled.img`
  max-width: 85px;
  max-height: 85px;
`

const GalleryImage = (props) => <StyledImg
    {...props}
  />

export default GalleryImage;