import * as Yup from 'yup';

const linkValidation = /^http(s?):\/\/(.+)\.(.+)/;

export const ShopSchema = Yup.object().shape({
  shopName: Yup.string()
    .trim()
    .required('errors.field_required'),
  shopCategoryId: Yup.string()
    .required('errors.field_required'),
  productCategoryIds: Yup.array(),
  brandIds: Yup.array(),
  email: Yup.string()
    .email('errors.invalid_email')
    .required('errors.field_required'),
  phoneNumber: Yup.string(),
  whatsapp: Yup.string(),
  sortBy: Yup.string(),
  facebook: Yup.string()
    .nullable()
    .transform(value => value === '' ? null : value)
    .matches(linkValidation, 'errors.invalid_link'),
  instagram: Yup.string()
    .nullable()
    .transform(value => value === '' ? null : value)
    .matches(linkValidation, 'errors.invalid_link'),
  twitter: Yup.string()
    .nullable()
    .transform(value => value === '' ? null : value)
    .matches(linkValidation, 'errors.invalid_link'),
  isHostedForOBuy: Yup.boolean(),
  isVisible: Yup.boolean(),
  eCommerceLink: Yup.string()
    .nullable()
    .transform(value => value === '' ? null : value)
    .matches(linkValidation, 'errors.invalid_link')
    .when('isHostedForOBuy', {
      is: false,
      then: Yup.string()
        .nullable()
        .required('errors.field_required'),
    }),
});
