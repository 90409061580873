import styled from "styled-components";
import { makeStyles } from "@material-ui/core";

export const StyledDivScroll = styled.div`
  background: #FFFFFF;
  box-shadow: 0 7px 20px rgba(183, 195, 211, 0.25);
  border-radius: 40px;
  padding: 62px;
  width: 100%;
  max-height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;

  // scroll style

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E9E9E9;
    border-radius: 8px;
  }
`;

export const StyledColorButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
  background-color: #FFFFFF;

  &:hover {
    background-color: #F8F8F8;
  }
`;

export const useStylesScene = makeStyles(() => ({
  sceneContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  expandButton: {
    padding: "19px 0px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  colorButton: {
    display: "flex",
    justifyContent: "center",
    background: "#1e79a6",
  },
  toggleGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  svgIcon: {
    overflow: "visible",
  },
  beginOfCreating: {
    display: "flex",
    justifyContent: "center",
    top: "50%",
    position: "relative",
    fontSize: "2em",
    color: "#BFBFBF",
  },
}));
