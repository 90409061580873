import React from "react";
import {Menu} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  dropdownStyle: {
    minWidth: '200px',
    background: '#FFFFFF',
    boxShadow: '0px 7px 20px 0px rgba(183, 195, 211, 0.25)',
    borderRadius: 16,
    marginTop: 4,
    '& ul.MuiList-padding': {
      padding: '6px 0',
    },
  },
}))

const StyledMenu = ({children, ...other}) => {
  const classes = useStyles();

  return (
    <Menu
      PopoverClasses={{ paper: classes.dropdownStyle }}
      getContentAnchorEl={null}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      {...other}
    >
      {children}
    </Menu>
  );
};

export default StyledMenu;
