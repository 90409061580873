import React, { useContext, useState } from "react";
import { WosqHeading } from "../styles";
import LayoutContent from "../layouts/LayoutContent";
import ResendCode from "../components/ResendCode";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../context/AuthContext";
import EnterCodeForm from "../components/EnterCodeForm";
import useCheckIsCompleteInfo from "../hooks/useCheckIsCompleteInfo";
import { useTranslation } from "react-i18next";


const PageMFACode = () => {
  const [loading, setLoading] = useState(false);
  const [cognitoError, setCognitoError] = useState(null);

  const { user } = useContext(AuthContext);
  const { t } = useTranslation("reg");


  const { checkAndRedirect } = useCheckIsCompleteInfo();

  const handleSubmit = async (code) => {
    if (!user || !code) {
      return;
    }

    setLoading(true);

    if (user) {
      Auth.confirmSignIn(user, code)
        .then(() => {
          return checkAndRedirect(user);
        })
        .catch((e) => {
          console.log(e);
          setCognitoError(e.message);
          setLoading(false);
        });
    }
  };

  return (
    <LayoutContent>
      <WosqHeading>{t("multi_factor")}</WosqHeading>
      <EnterCodeForm
        onSubmit={handleSubmit}
        loading={loading}
        cognitoError={cognitoError}
        headingText={t("confirm_login")}
      />
      <ResendCode />
    </LayoutContent>
  );
};

export default PageMFACode;