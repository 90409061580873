import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useProductColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'shopName',
      label: t('product.shop_name'),
      isBold: true,
    },
    {
      name: 'productId',
      label: t('product.product_id'),
      sortable: '_id'
    },
    {
      name: 'productName',
      label: t('product.product_name'),
      sortable: 'title',
    },
    {
      name: 'status',
      label: t('product.status'),
      sortable: 'isVisible',
    },
    {
      name: 'price',
      label: t('product.price'),
      isBold: true,
      sortable: 'priceMin',
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    {
      name: 'options'
    }
  ], [t]);
};
