import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useDisplayStatuses = () => {
  const { t } = useTranslation();

  const getDisplayStatuses = useCallback((key) => {
    const statuses = {
      approved: t('orders.approved'),
      canceled: t('orders.canceled_auth_before_capture'),
      completed: t('orders.captured'),
      created: t('orders.awaiting_approval'),
      error: t('orders.error'),
      partialRefund: t('orders.partially_refunded'),
      refunded: t('orders.fully_refunded'),
    };

    return statuses[key];
  }, [t]);

  const getRiskLevelText = useCallback((riskLevel) => {
    return t(`orders.${riskLevel || 'error'}`);
  }, [t]);

  return {
    getDisplayStatuses,
    getRiskLevelText,
  }
};

export default useDisplayStatuses;
