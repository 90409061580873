import React from 'react';
import styled, {css} from 'styled-components';
import {colors} from '../../constants';

const contentCss = css`
  align-items: flex-end;
  margin-bottom: 20px;
`

const StyledDiv = styled.div`
  display: ${props => props.isList ? 'block' : 'flex'};
  justify-content: space-between;
  width: 100%;
  
  ${props => props.isContent && contentCss}

  .title {
    font-family: 'Fredoka', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: ${colors.black};
    margin: 12px 0;

    &--margin4 {
      margin: 0 0 4px 0;
    }

    &--marginPrint {
      margin: 0 0 24px 0;
    }

    &--margin24 {
      margin: 24px 0 15px 0;
    }
    
    &--marginHeading {
      margin: 12px 0 24px 0;
    }
  }

  .check {
    p {
      font-family: 'Mulish', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: ${colors.black};
      margin: 4px 0;
    }
  }

  .text {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: ${colors.black};
    margin: 0 0 12px 0;

    span {
      font-family: 'Fredoka', sans-serif;
      font-weight: 500;
    }

    &--margin24 {
      margin-bottom: 24px;
    }
  }
`

const OrderContainer = ({children, isList, isContent, ...other}) => {
  return (
    <StyledDiv
      isList={isList}
      isContent={isContent}
      {...other}
    >
      {children}
    </StyledDiv>
  );
}

export default OrderContainer;
