import React, { useState } from "react";
import ButtonWide from "./ButtonWide";
import Popover from "@material-ui/core/Popover";
import FormInput from "../../../../../UI/Form/FormInput";
import useInputNumber from "../hooks/useInputNumber";
import styled from "styled-components";
import CustomButton from "../../../../../UI/Button";
import { InputAdornment } from "@material-ui/core";
import { IconClose } from "../../../../../icons";

const ButtonsBox = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

const StyledInputAdornment = styled(InputAdornment)`
  cursor: pointer;
  margin-left: 8px;
`;

const RenderPaper = React.forwardRef(({children, ...other}, ref) => {
  return <div
    ref={ref}
    {...other}
    style={{
      background: '#FFFFFF',
      boxShadow: '0 7px 20px 0 rgba(183, 195, 211, 0.25)',
      borderRadius: 20,
      padding: 24,
      userSelect: 'none',
      marginTop: '6px',
    }}
  >
    {children}
  </div>
})

const PopoverInputNumber = ({ onSave, value, isFloatNumber = false, buttonText }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'input-number-popover' : undefined;

  const handleSave = (newVal) => {
    setAnchorEl(null);
    onSave(newVal);
  }

  const { handleChange, innerValue, handleConfirm, resetValue } = useInputNumber({
    value,
    onChange: handleSave,
    isFloatNumber,
    type: 'number'
  });

  const handleClose = () => {
    resetValue();
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <ButtonWide
        aria-describedby={id}
        onClick={handleClick}
      >
        {buttonText}
      </ButtonWide>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ component: RenderPaper}}
      >
        <FormInput
          placeholder='Type number'
          onChange={handleChange}
          value={innerValue}
          type='number'
          style={{ width: '312px' }}
          InputProps={{
            endAdornment: (
              <StyledInputAdornment
                onClick={() => resetValue('')}
              >
                <IconClose />
              </StyledInputAdornment>
            )
          }}
        />
        <ButtonsBox>
          <CustomButton
            width='100%'
            mytype='secondary'
            mysize='small'
            handleClick={handleClose}
          >
            Cancel
          </CustomButton>
          <CustomButton
            width='100%'
            mysize='small'
            disabled={innerValue === value}
            handleClick={handleConfirm}
          >
            Save
          </CustomButton>
        </ButtonsBox>
      </Popover>
    </>
  );
};

export default PopoverInputNumber;
