import React, { forwardRef } from "react";
import styled from "styled-components";
import { colors } from "../../constants";

const StyledMessage = styled.div`
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.black};
  margin-left: 16px;
`

const PageStatusMessage = forwardRef(({children}, ref) => {
  return (
    <StyledMessage ref={ref}>
      {children}
    </StyledMessage>
  );
});

export default PageStatusMessage;
