import React, { useContext } from "react";
import styled, { css } from "styled-components";
import Header from "../Header";
import AttributeList from "../AttributeList";
import { ProductVariantsContext } from "../../../../../package/src/context/ProductVariantsContext";
import LayoutAttributeInit from "./LayoutAttributeInit";
import LayoutTable from "./LayoutTable";

const Wrapper = styled('div')`
  width: 1296px;
  height: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 67px 1fr;
  row-gap: 8px;
`;

const GridContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 400px 1fr;
  transition: .2s;

  @media all and (max-width: 1340px) {
    grid-template-columns: 316px 1fr;
  }

  ${(props) => !props.open && css`
    grid-template-columns: 40px 1fr;
  `}
`;

const LayoutEditor = ({ onCancel, onDone, shopId, refetchMedia }) => {
  const { isHideAttributeList, variants, attributeValues } = useContext(ProductVariantsContext);

  const isInitMode = !variants.length && !attributeValues.length;

  /*
    isInitMode === true if the product does not have any configured variants (just created), and the user did not create attributes
    The configured variant is the variant where the attributes array is not empty
    When isInitMode is true, a screen opens on which the user is asked to generate the first option
    After generation, we go to normal mode, where a list of attributes and a table are displayed
   */

  return (
    <Wrapper>
      <Header
        onCancel={onCancel}
        onDone={onDone}
      />
      <GridContainer open={!isHideAttributeList}>
        {
          isInitMode ?
            <LayoutAttributeInit /> :
            <AttributeList />
        }
        {
          !isInitMode && <LayoutTable
            shopId={shopId}
            refetchMedia={refetchMedia}
          />
        }
      </GridContainer>
    </Wrapper>
  );
};

export default LayoutEditor;
