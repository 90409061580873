export default function getStreetByUuid(streets, uuid) {
  for (let street of streets) {
    if (street["id"] === uuid) {
      return street;
    }
    if (street["uuid"] === uuid) {
      return street;
    }
  }
  return null;
}
