const languageList = [
  {
    value: 'en',
    label: 'En',
  },
  {
    value: 'ar',
    label: 'Ar',
  },
];

export default languageList;
