import gql from "graphql-tag";
import { ShopCategory } from "./fragments";

export const addShopToShopCategoryMutation = gql`
  mutation AssignShopCategory($shopCategoryId: ID!, $shopId: ID!) {
    assignCategoryToShop(shopCategoryId: $shopCategoryId, shopId: $shopId) {
      shopCategory {
        name
      }
    }
  }
`;

export const addShopCategoryMutation = gql`
  mutation addShopCategory($input: AddShopCategoryInput!) {
    addShopCategory(input: $input) {
      shopCategory {
        ${ShopCategory}
      }
    }
  }
`;

export const updateShopCategoryMutation = gql`
  mutation updateShopCategory($id: ID!, $input: UpdateShopCategoryInput!) {
    updateShopCategory(id: $id, input: $input) {
      shopCategory {
        ${ShopCategory}
      }
    }
  }
`;

export const removeShopCategoryMutation = gql`
  mutation removeShopCategory($id: ID!) {
    removeShopCategory(id: $id) {
      shopCategory {
        ${ShopCategory}
      }
    }
  }
`;
