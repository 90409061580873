import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../constants";
import Grid from "@material-ui/core/Grid";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import { useTranslation } from "react-i18next";
import formatMoney from "../../../package/src/utils/formatMoney";

const StyledRefundInfo = styled.div`
  margin-bottom: 32px;
  
  p {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.black};
    margin: .4em;
    
    span {
      font-weight: 700;
    }
    
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  
  .error {
    color: ${colors.redLight};
  }
  
  .ok {
    color: ${colors.grass};
  }
`

const RefundRow = ({order, payment, onChange}) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0);

  const isPaymentRefundable = payment.method.canRefund === true;
  const isPaymentFullyRefunded = payment.status === "refunded";
  // previous refunds
  const paymentPreviousRefundTotal = payment.refunds.reduce(
    (acc, refund) => acc + refund.amount.amount,
    0
  );
  const paymentPreviousRefundTotalDisplay = formatMoney(
    paymentPreviousRefundTotal,
    order.currencyCode
  );
  // available to refund

  const paymentAmountAvailableForRefund = payment.amount.amount - paymentPreviousRefundTotal;
  const paymentAmountAvailableForRefundDisplay = formatMoney(
    paymentAmountAvailableForRefund,
    order.currencyCode
  );

  const handleChange = (e) => {
    const val = e.target.value;
    setAmount(val);
    onChange(val);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <StyledRefundInfo>
          <p>{t("orders.refunded_to")} <span>{payment.displayName}</span></p>
          {
            !isPaymentRefundable ?
            <p className='error'>{t("orders.refunds_not_supported")}</p> :
              (
                !isPaymentFullyRefunded ?
                <>
                  <p>{t("orders.available_to_refund")}: <span>{paymentAmountAvailableForRefundDisplay}</span></p>
                  {
                    paymentPreviousRefundTotal && paymentPreviousRefundTotal > 0 ? (
                      <p>{t("orders.previously_refunded")}: <span>{paymentPreviousRefundTotalDisplay}</span></p>
                    ) : null
                  }
                </> :
                <p className='ok'>{t("orders.payment_refunded")}</p>
              )
          }
        </StyledRefundInfo>
      </Grid>
      {
        isPaymentRefundable && !isPaymentFullyRefunded &&
        <Grid item xs={12} lg={6}>
          <InputLabel optionalText='USD'>{t('orders.amount_to_refund')}</InputLabel>
          <FormInput
            value={amount}
            onChange={handleChange}
            type='number'
            inputProps={{ step: '0.01', min: 0, max: paymentAmountAvailableForRefund}}
          />
        </Grid>
      }
    </Grid>
  );
}

export default RefundRow;
