import React from "react";
import svgAdd from "../../../../../icons/add-thin.svg";
import styled from "styled-components";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const HeadingBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const HeadingText = styled(Typography)`
  color: #000;
  font-family: Fredoka, sans-serif;
  font-size: 18px;
  font-weight: 500;
`;

const AddButton = styled(Button)`
  color: #9A9A9A;
  font-family: Fredoka, sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  padding: 0 8px;
  background: none;
  border-radius: 20px;
  
  p {
    margin: 0 0 0 8px;
  }
  
  &:hover {
    background: none;
  }
`;

const BillingListHeader = ({ onAddCart }) => {
  const { t } = useTranslation();

  return (
    <HeadingBox>
      <HeadingText>Payment</HeadingText>
      <AddButton
        onClick={onAddCart}
      >
        <img src={svgAdd} alt='+'/>
        <p>{t('payment.add_card')}</p>
      </AddButton>
    </HeadingBox>
  );
};

export default BillingListHeader;