import Status from "../../../UI/Status";
import moment from "moment/moment";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useModifiers = () => {
  const { t } = useTranslation();

  const modifyData = useCallback((data, method, options) => {
    return data.map(item => {
      const isExport = method === 'export';
      const statusText = item.isVisibleToMerchants ? t('subscription.visible') : t('subscription.invisible');
      const statusType = item.isVisibleToMerchants ? 'visible' : 'invisible';

      const result = {
        id: item._id,
        title: item.title,
        description: item.description,
        visibility: isExport ? statusText : <Status
          content={statusText}
          type={statusType}
        />,
        rate: item.baseRate,
        period: item.duration,
        createdAt: moment(item.createdAt).format("MM.DD.YYYY"),
      }

      // if table
      if (!isExport && options) {
        result.options = options;
        return result;
      }

      // if 'export' button
      result.__typename = item.__typename;
      return result;
    });
  }, [t]);

  return {
    modifyData,
  }
};

export default useModifiers;
