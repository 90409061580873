import { constants } from "../../../constants";
import Status from "../../../UI/Status";
import moment from "moment/moment";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import getStatus from "../../../package/src/utils/getStatus";

const useModifiers = () => {
  const { t } = useTranslation();

  const getRequestStatus = useCallback((state) => {
    const allStatuses = t('statuses', { returnObjects: true });
    return getStatus(allStatuses, state);
  }, [t]);

  const modifyData = useCallback((data, method, options) => {
    return data.map(item => {

      const isExport = method === 'export';
      const statusObj = getRequestStatus(item.state);

      let request = "";
      if (item.type === constants.MERCHANT_REQUESTS.TYPE.E_COMMERCE_ENABLED) {
        // yes, changeTo and changeFrom are strings
        request = item.changeTo === "true" ? t('merchant.start_hosting_ecommerce') : t('merchant.stop_hosting_ecommerce');
      } else {
        // SHOP_CATEGORY
        const oldCategory = item.fromShopCategory[0]?.name || t('merchant.no_category');
        const newCategory = item.toShopCategory[0]?.name || t('merchant.no_category');
        request = t('merchant.change_category_from_to', { oldCategory, newCategory });
      }

      const result = {
        id: item._id,
        request,
        type: item.type,
        shopName: item.shop.length ? item.shop[0].name : '',
        email: item.account.length ? item.account[0].primaryEmailAddress : '',
        status: isExport ? statusObj.label : <Status
          content={statusObj.label}
          type={statusObj.value}
        />,
        shopId: item.shopId,
        accountId: item.accountId,
        createdAt: moment(item.createdAt).format("MM.DD.YYYY"),
      }

      // if table
      if (!isExport && options) {
        result.options = options;
        return result;
      }

      // if 'export' button
      result.__typename = item.__typename;
      return result;
    });
  }, [getRequestStatus]);

  return {
    modifyData,
  }
};

export default useModifiers;
