import * as Yup from "yup";

export const MerchantSchema = Yup.object().shape({
  fullName: Yup.string()
    .required('errors.field_required'),
  email: Yup.string()
    .email('errors.invalid_email')
    .required('errors.field_required'),
  phoneNumber: Yup.string()
    .required('errors.field_required'),
})
