import React from "react";
import styled from 'styled-components';
import {colors} from '../../constants';
import IconHelp from "./IconHelp";

const getColor = ({error, disabled}) => {
  if (error) {
    return colors.redLight;
  }
  if (disabled) {
    return colors.textGrey;
  }
  return 'black';
}

const StyledInputLabel = styled.div`
  padding: 0;
  margin-bottom: 15px;
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: ${props => getColor(props)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .helpText {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: ${colors.black};
  }

  .optional {
    color: ${colors.textGrey};
  }
`

const InputLabel = ({
    children,
    error,
    helpText,
    optionalText,
    disabled,
    style
  }) => {

  return (
    <StyledInputLabel
      error={Boolean(error)}
      helpText={Boolean(helpText)}
      disabled={disabled}
      style={style}
    >
      <div>
        {children}
        {
          optionalText ?
            <span className='optional'> ({optionalText})</span> : null
        }
      </div>
        {
          helpText ?
            <IconHelp helpText={helpText}/> : null
        }
    </StyledInputLabel>
  );
}

export default InputLabel;
