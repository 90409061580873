import styled, { css } from "styled-components";
import { TableCell, TableRow, TableSortLabel, Typography, Table } from "@material-ui/core";

export const VariantTableStyled = styled(Table)`
  &.MuiTable-stickyHeader {
    border-collapse: collapse;
  }
`;

export const VariantTableHeadingCellCheckbox = styled(TableCell)`
  padding: 5px 8px;
  width: 80px;

  //&.MuiTableCell-stickyHeader {
  //  background-color: #FFF;
  //}
`;

export const VariantTableHeadingCell = styled(TableCell)`
  padding: 5px 8px;
  min-width: 120px;
  max-width: 280px;
  overflow: hidden;

  //&.MuiTableCell-stickyHeader {
  //  background-color: #FFF;
  //}
`;

export const VariantTableCellCheckbox = styled(TableCell)`
  width: 80px;
  padding: 10px 8px;
`;

export const VariantTableCell = styled(TableCell)`
  padding: 10px 8px;
  min-width: 120px;
  max-width: 280px;
  overflow: hidden;
`;

export const VariantAllBox = styled('div')`
  width: 80px;
  display: flex;
  align-items: center;
`;

export const VariantHeadingText = styled(Typography)`
  color: #222;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VariantTableSortLabel = styled(TableSortLabel)`
  color: #222;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-weight: 400;
  transition: .2s;
  
  &:hover {
    color: #666;
  }
`;

export const VariantTableRow = styled(TableRow)`
  border-bottom: 1px solid #E9E9E9;
  background: ${(props) => props.selected ? '#f3f6ff' : '#fff'};
`;

export const VariantScrollCss = css`
  &::-webkit-scrollbar {
    height: 10px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E9E9E9;
    border-radius: 8px;
  }
`;
