import React, { useContext, useMemo, useState } from "react";
import { TableBody, TableHead } from "@material-ui/core";
import CheckboxAlternate from "../../../../../UI/Checkbox/CheckboxAlternate";
import { ProductVariantsContext } from "../../../../../package/src/context/ProductVariantsContext";
import SortIconComponent from "./SortIconComponent";
import {
  VariantAllBox,
  VariantHeadingText,
  VariantTableHeadingCellCheckbox,
  VariantTableHeadingCell,
  VariantTableRow,
  VariantTableSortLabel,
  VariantTableCellCheckbox,
  VariantTableCell,
  VariantTableStyled,
} from "./styles";
import { useTableColumns } from "../hooks/useTableColumns";
import CellComponentInput from "./cell-styles/CellComponentInput";
import CellComponentColor from "./cell-styles/CellComponentColor";
import CellComponentText from "./cell-styles/CellComponentText";
import CellComponentTextBold from "./cell-styles/CellComponentTextBold";
import { getComparator, stableSort } from "./utils";
import CellComponentMedia from "./cell-styles/CellComponentMedia";
import Dialog from "../../../../../UI/Dialog/Dialog";
import MediaGallerySelectorDialog from "../../MediaGallerySelector/MediaGallerySelectorDialog";
import { flatten } from "lodash";

const sortableTypes = ['list', 'colorPicker', 'dataPicker', 'checkbox'];

const VariantsTable = ({ isEditable, shopId }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowId, setRowId] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const {
    selectedVariants,
    onDeselectAllVariants,
    onSelectAllVariants,
    onSelectVariant,
    variants,
    onUpdateVariantValue,
    saving,
    media,
  } = useContext(ProductVariantsContext);

  const filterVariants = variants.filter((k) => !k.isDelete);

  const columns = useTableColumns();
  const isAllSelected = Boolean(filterVariants.length && selectedVariants.length === filterVariants.length);

  const handleAll = () => {
    if (isAllSelected) {
      onDeselectAllVariants();
    } else {
      onSelectAllVariants(filterVariants.map((d) => d.id));
    }
  };

  const handleSelectRow = (id) => {
    onSelectVariant(id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleOpenMediaGallery = (id) => {
    setRowId(id);
    setDialogOpen(true);
  };

  const handleCloseMediaGallery = () => {
    setDialogOpen(false);
  };

  const handleSelectMedia = (pics) => { // [{ id, path }]
    onUpdateVariantValue(rowId, 'media', pics);
    handleCloseMediaGallery();
  };

  const handleDeleteMedia = (id) => {
    onUpdateVariantValue(id, 'media', []);
  };

  const getColumnVariants = useMemo(() => {
    const columnVariants = [];
    filterVariants.forEach((item) => {
      item.attributeValues.forEach((k) => {
        if (!columnVariants.find((variant) => variant.attributeId === k.attributeId)) {
          columnVariants.push({
            attributeId: k.attributeId,
            attributeName: k.attributeName,
            inputType: k.inputType,
          });
        }
      });
    });
    return columnVariants;
  }, [filterVariants]);

  const getAttributeCellValue = (cell) => {
    switch(cell.inputType) {
      case 'checkbox': return <CellComponentTextBold>
        { cell.value ? 'Yes' : 'No' }
      </CellComponentTextBold>;
      case 'colorPicker': return <CellComponentColor
        hex={cell.value.hex}
        name={cell.value.name}
      />;
      case 'datePicker': return <CellComponentTextBold>
        { cell.value.date }
      </CellComponentTextBold>;
      default: return <CellComponentTextBold>
        { cell.value }
      </CellComponentTextBold>;
    }
  };

  const renderAttributeCell = (attributeValues) => {
    return getColumnVariants.map((column) => {
      const found = attributeValues.find((k) => k.attributeId === column.attributeId);
      if (found) {
        return <VariantTableCell key={found.attributeId}>
          {getAttributeCellValue(found)}
        </VariantTableCell>;
      }
      return <VariantTableCell key={column.attributeId}>&mdash;</VariantTableCell>;
    });
  };

  const visibleRows = stableSort(filterVariants, getComparator(order, orderBy));
  const usedMediaIds = flatten(visibleRows.map((k) => k.media.map((d) => d.id)));
  const existingIds = media
    .filter((d) => !d.variantId)
    .map((k) => k._id)
    .concat(usedMediaIds);

  return (
    <>
      <VariantTableStyled stickyHeader>
        <TableHead>
          <VariantTableRow>
            <VariantTableHeadingCellCheckbox>
              {
                isEditable &&
                <VariantAllBox>
                  <CheckboxAlternate
                    checked={isAllSelected}
                    onChange={handleAll}
                  />
                  <VariantHeadingText>
                    All
                  </VariantHeadingText>
                </VariantAllBox>
              }
            </VariantTableHeadingCellCheckbox>
            <VariantTableCellCheckbox />
            {
              getColumnVariants.map((item) => {
                if (sortableTypes.includes(item.inputType)) {
                  return <VariantTableHeadingCell
                    key={item.attributeId}
                    sortDirection={orderBy === item.attributeId ? order : false}
                  >
                    <VariantTableSortLabel
                      active={orderBy === item.attributeId}
                      direction={orderBy === item.attributeId ? order : 'asc'}
                      onClick={createSortHandler(item.attributeId)}
                      hideSortIcon
                      IconComponent={() => <SortIconComponent
                        reversed={order === 'asc'}
                      />}
                    >
                      {item.attributeName}
                    </VariantTableSortLabel>
                  </VariantTableHeadingCell>;
                }
                return <VariantTableHeadingCell key={item.attributeId}>
                  <VariantHeadingText>
                    {item.attributeName}
                  </VariantHeadingText>
                </VariantTableHeadingCell>;
              })
            }
            {
              columns.map((item) => <VariantTableHeadingCell
                key={item.name}
                sortDirection={orderBy === item.name ? order : false}
              >
                <VariantTableSortLabel
                  active={orderBy === item.name}
                  direction={orderBy === item.name ? order : 'asc'}
                  onClick={createSortHandler(item.name)}
                  hideSortIcon
                  IconComponent={() => <SortIconComponent
                    reversed={order === 'asc'}
                  />}
                >
                  {item.label}
                </VariantTableSortLabel>
              </VariantTableHeadingCell>)
            }
          </VariantTableRow>
        </TableHead>
        <TableBody>
          {
            visibleRows.map((row, index) => {
              if (row.isDelete) {
                return null;
              }

              const selected = selectedVariants.includes(row.id);

              return <VariantTableRow
                key={row.id}
                selected={selected}
              >
                <VariantTableCellCheckbox>
                  <VariantAllBox>
                    {
                      isEditable &&
                      <CheckboxAlternate
                        checked={selected}
                        onChange={() => handleSelectRow(row.id)}
                      />
                    }
                    <CellComponentTextBold>
                      {index + 1}
                    </CellComponentTextBold>
                  </VariantAllBox>
                </VariantTableCellCheckbox>
                <VariantTableCellCheckbox>
                  <CellComponentMedia
                    picSrc={row.media[0]?.path}
                    onEdit={() => handleOpenMediaGallery(row.id)}
                    onDelete={() => handleDeleteMedia(row.id)}
                    isEditable={isEditable}
                  />
                </VariantTableCellCheckbox>
                {
                  renderAttributeCell(row.attributeValues)
                }
                {
                  columns.map((cell) => {
                    const value = String(row[cell.name] ?? '');
                    if (value !== undefined) {
                      return <VariantTableCell key={cell.name}>
                        {
                          isEditable ?
                            <CellComponentInput
                              value={value}
                              onChange={(val) => onUpdateVariantValue(row.id, cell.name, val)}
                              type={cell.inputType === 'number' ? 'number' : 'text'}
                              isFloatNumber={cell.name !== 'quantity'}
                              disabled={saving}
                            /> :
                            <CellComponentText>
                              { value }
                            </CellComponentText>
                        }
                      </VariantTableCell>;
                    }
                    return null;
                  })
                }
              </VariantTableRow>;
            })
          }
        </TableBody>
      </VariantTableStyled>

      <Dialog
        open={dialogOpen}
        handleClose={handleCloseMediaGallery}
      >
        <MediaGallerySelectorDialog
          shopId={shopId}
          onSelect={handleSelectMedia}
          existingIds={existingIds}
          singleSelect
        />
      </Dialog>
    </>
  );
};

export default VariantsTable;
