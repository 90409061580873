import styled, { css } from "styled-components";
import { Button, makeStyles, Typography } from "@material-ui/core";

export const QuizStyledPaper = styled('div')`
  width: 100%;
  border-radius: 99px;
  background: #FFF;
  box-shadow: 0 7px 20px 0 rgba(183, 195, 211, 0.25);
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  padding: 8px;
`;

export const QuizShopCircle = styled('div')`
  display: flex;
  width: 72px;
  height: 72px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
  background: #E5E9F6;
`;

export const QuizColumn = styled('div')`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const QuizQuestionText = styled(Typography)`
  color: #9A9A9A;
  font-family: Fredoka, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  
  &:hover {
    color: #4270FF;
  }
  
  ${(props) => props.selected && css`
    color: #222;
  `}

  ${(props) => props.disabled && css`
    cursor: default;
    color: #E6E6E6;

    &:hover {
      color: #E6E6E6;
    }
  `}
`;

export const BigBlueButtonWrapper = styled(Button)`
  width: 100%;
  height: 72px;
  border-radius: 50px;
  background: #4270FF;
  color: #FFF;
  font-family: Fredoka, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  position: relative;

  svg {
    fill: #FFF;
  }
  
  &:hover {
    background: #4874FF;
  }
  
  ${(props) => props.selected && css`
    background: #2A55DE;
  `}

  ${(props) => props.isdisabled === 'true' && css`
    background: #E6E6E6;
    color: #C5C5C5;
    cursor: default;

    &:hover {
      background: #E6E6E6;
    }

    svg {
      fill: #C5C5C5;
    }
  `}
`;

export const BigBlueButtonFlexBox = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const BigBlueButtonTooltipPaper = styled('div')`
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0 4px 20px 0 #D8DADF;
  padding: 16px;
  color: var(--txt-black, #222);
  font-family: Mulish, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const bigBlueButtonUseStyles = makeStyles(() => ({
  popper: {
    '& .MuiTooltip-tooltipPlacementBottom': {
      margin: '8px 0',
      padding: '0',
    },
  },
  tooltip: {
    backgroundColor: 'transparent',
  }
}));
