import gql from "graphql-tag";

export const viewerQuery = gql`
  query getViewer {
    viewer {
      _id
      firstName
      language
      lastName
      name
      primaryEmailAddress
      type
      companyId
      numPendingMerchantRequests
      groups {
        nodes {
          _id
          slug
          shop {
            _id
          }
        }
      }
      adminUIShops {
        _id
        company {
          _id
          name
        }
        brandAssets {
          navbarBrandImage {
            large
          }
        }
        name
        shopLogoUrls {
          primaryShopLogoUrl
        }
      }
    }
  }
`;
