/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import { Reaction, registerPlugin, App } from "./package/src";
import config from "./config";
import { constants } from "./constants.js";

// Init Amplify
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

// Main CSS
import "./index.css";

import Products from "./plugins/products";
import ShopCategories from "./plugins/shop-categories";
import Shops from "./plugins/shops";
import MerchantRequests from "./plugins/merchant-requests";
import Accounts from "./plugins/accounts/src";
import ProductBrands from "./plugins/product-brands";
import ProductCategories from "./plugins/product-categories";
import Orders from "./plugins/orders";
import SubscriptionPackages from "./plugins/subscription-packages";
import Tags from "./plugins/tags";
import MediaGallery from "./plugins/media-gallery";
import Discounts from "./plugins/discounts";
import Financials from "./plugins/financials";
import FinancialHistoryRecords from "./plugins/financial-history-records";
import Merchants from "./plugins/merchants";
import ActivityHistory from "./plugins/activity-history";
import Shoppers from "./plugins/shoppers";
import Settings from "./plugins/settings";
import Attributes from "./plugins/product-attributes";
import ProductTypes from "./plugins/product-types";

import { FileRecord } from "@reactioncommerce/file-collections";

registerPlugin(Products);
registerPlugin(ShopCategories);
registerPlugin(Shops);
registerPlugin(MerchantRequests);
registerPlugin(Accounts);
registerPlugin(ProductBrands);
registerPlugin(ProductCategories);
registerPlugin(Orders);
registerPlugin(SubscriptionPackages);
registerPlugin(Tags);
registerPlugin(MediaGallery);
registerPlugin(Discounts);
registerPlugin(Financials);
registerPlugin(FinancialHistoryRecords);
registerPlugin(Merchants);
registerPlugin(ActivityHistory);
registerPlugin(Shoppers);
registerPlugin(Settings);
registerPlugin(Attributes);
registerPlugin(ProductTypes);

FileRecord.downloadEndpointPrefix = "/assets/files";
FileRecord.uploadEndpoint = "/assets/uploads";
FileRecord.absoluteUrlPrefix = config.PUBLIC_FILES_BASE_URL;

// Configure and "start" the Reaction webapp
Reaction({
  AppComponent: App,
  title: constants.APP_NAME,
  config,
});
