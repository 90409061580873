import React, { useContext, useEffect, useState } from "react";
import { FormHeaderText, FormHeaderWrap, WosqHeading } from "../styles";
import LayoutContent from "../layouts/LayoutContent";
import LayoutForm from "../layouts/LayoutForm";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { RequiredChangePasswordSchema } from "../schemas";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Form } from "../../../../UI/Form/MyForm";
import InputLabel from "../../../../UI/Form/InputLabel";
import FormInput from "../../../../UI/Form/FormInput";
import RegError from "../components/RegError";
import CustomButton from "../../../../UI/Button";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import { AuthContext } from "../../context/AuthContext";
import { Auth } from "aws-amplify";
import useAuth from "../../hooks/useAuth";
import DialogMessages from "../../../../UI/Dialog/DialogMessages";
import Dialog from "../../../../UI/Dialog/Dialog";
import { useTranslation } from "react-i18next";

const PageRequiredChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [cognitoError, setCognitoError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { t } = useTranslation("reg");
  const { user } = useContext(AuthContext);
  const { logout } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(RequiredChangePasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const watchFields = useWatch({ control, name: ["password", "confirmPassword"] });

  useEffect(() => {
    setCognitoError(null);
  }, [watchFields]);

  const onSubmit = formData => {
    if (!user) {
      return;
    }

    setLoading(true);

    Auth.completeNewPassword(user, formData.password)
      .then(() => {
        setDialogOpen(true);
      })
      .catch(err => {
        setCognitoError(err.message);
        console.log(err);
        setLoading(false);
      });
  };

  const handleBack = async () => {
    setLoading(true);
    await logout();
  };

  const handleCloseDialog = async () => {
    setDialogOpen(false);
    setLoading(true);
    await logout();
  };

  return (
    <LayoutContent>
      <WosqHeading>{t("change_password")}</WosqHeading>
      <LayoutForm>
        <FormHeaderWrap>
          <FormHeaderText>{t("change_password")}</FormHeaderText>
        </FormHeaderWrap>
        <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
          <InputLabel error={errors.password} disabled={loading}>
            {t("new_password")}
          </InputLabel>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="password"
                placeholder={t("write_new_password")}
                error={errors.password}
                disabled={loading}
              />
            )}
          />

          <InputLabel error={errors.confirmPassword} disabled={loading}>
            {t("confirm_password")}
          </InputLabel>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="password"
                placeholder={t("confirm_password")}
                error={errors.confirmPassword}
                disabled={loading}
              />
            )}
          />

          {cognitoError ? <RegError text={cognitoError} /> : null}

          <DialogButtons justifyButtons="space-between">
            <CustomButton
              width="170px"
              mytype="secondary"
              disabled={loading}
              handleClick={handleBack}
            >
              {t("ui.back")}
            </CustomButton>
            <CustomButton width="170px" type="submit" disabled={!isDirty || loading}>
              {t("ui.save")}
            </CustomButton>
          </DialogButtons>
        </Form>
      </LayoutForm>

      <Dialog open={dialogOpen} handleClose={handleCloseDialog}>
        <DialogMessages title={t("reset_password_success")} />
        <DialogButtons justifyButtons="center">
          <CustomButton width="170px" handleClick={handleCloseDialog}>
            {t("ui.ok")}
          </CustomButton>
        </DialogButtons>
      </Dialog>
    </LayoutContent>
  );
};

export default PageRequiredChangePassword;
