import React from 'react';
import {Icon} from '@material-ui/core';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const CustomIcon = (props) => {
  return (
    <StyledIcon
      {...props}
    >
      {props.children}
    </StyledIcon>
  );
}

export default CustomIcon;