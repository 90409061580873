import React, { Fragment, useEffect } from "react";
import Helmet from "react-helmet";
import Typography from "@material-ui/core/Typography";
import ShopLogoWithData from "../ShopLogoWithData";
import { constants } from "../../../constants.js";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

/**
 * LandingPage
 * @param {Object} props Component props
 * @returns {Node} React component
 */

const DescriptionText = styled(Typography)`
  color: #BFBFBF;
  text-align: center;
  font-family: Fredoka, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-top: 72px;
`;

function LandingPage() {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet title={`${constants.APP_NAME} CMS`} />
      <Wrapper>
        <ShopLogoWithData shopIds={null} />
        <DescriptionText>
          {t('common.welcome_to')} {constants.APP_NAME}!<br/>
          {t('common.to_start_your_journey')}
        </DescriptionText>
      </Wrapper>
    </Fragment>
  );
}

export default LandingPage;
