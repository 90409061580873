import React, { useContext } from "react";
import { Collapse, ListItem, makeStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { ToggleButtonGroup } from "@material-ui/lab";
import Icon from "../../../UI/Icon/Icon";
import StyledLine from "./StyledLine";
import StyledLabel from "./StyledLabel";
import StyledToggleButton from "./StyledToggleButton";
import ConfiguratorTooltip from "./tooltip/ConfiguratorTooltip";
import svgLock from '../../../icons/lock.svg';
import { ShopEditContext } from "../../../package/src/context/ShopEditContext";

const useStyles = makeStyles(() => ({
  expandButton: {
    padding: "19px 0px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  toggleGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  svgIcon: {
    overflow: "visible",
  },
}));

const AccessoryGroup = ({
  expandGroup,
  setExpandGroup,
  mainText,
  onChangeMethod,
  accessories,
  currentValue,
  shopId,
}) => {
  const {
    setCurrentTab,
  } = useContext(ShopEditContext);

  const classes = useStyles();

  const upgradeHandler = () => {
    setCurrentTab('sub');
  };

  return (
    <div>
      <StyledLine />
      <ListItem className={classes.expandButton} onClick={() => {
        setExpandGroup(!expandGroup);
      }}
      >
        <StyledLabel>{mainText}</StyledLabel>
        {expandGroup ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={expandGroup} timeout="auto" unmountOnExit>
        <ToggleButtonGroup
          size="large"
          aria-label="selected accessory"
          value={currentValue}
          exclusive
          className={classes.toggleGroup}
          onChange={onChangeMethod}
        >
          {accessories.map(item => {
            if (item.isPermit) {
              return <StyledToggleButton
                value={item.id}
                key={item.id}
              >
                <Icon
                  className={classes.svgIcon}
                >
                  <img src={item.icon} alt="" />
                </Icon>
              </StyledToggleButton>
            } else {
              return <ConfiguratorTooltip
                key={item.id}
                onUpgrade={upgradeHandler}
                shopId={shopId}
              >
                <StyledToggleButton
                  value={item.id}
                  disabled
                >
                  <Icon
                    className={classes.svgIcon}
                  >
                    <img src={svgLock} alt="" />
                  </Icon>
                </StyledToggleButton>
              </ConfiguratorTooltip>
            }
          })}
        </ToggleButtonGroup>
      </Collapse>
    </div>
  );
};


export default AccessoryGroup;
