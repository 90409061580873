import React from "react";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import FormNote from "../../../UI/Form/FormNote";

const camelCaseToSentence = string => {
  let result = string.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const FormFinancials = ({loading, setting, handleChange}) => {
  return (
    <>
      <InputLabel disabled={loading}>{camelCaseToSentence(setting.name)}</InputLabel>
      <FormInput
        value={setting.value}
        onChange={(e) => handleChange(setting.name, e.target.value)}
        disabled={loading}
      />
      {
        setting.description ?
        <FormNote style={{ marginTop: 0 }}>{setting.description}</FormNote>
          : null
      }
    </>
  );
}

export default FormFinancials;