import Axios from 'axios';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  withCredentials: true,
};

export const axios = Axios.create(config);
