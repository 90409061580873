import React from "react";
import {Menu} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  dropdownStyle: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 20px #D8DADF',
    borderRadius: 20,
    marginTop: 4,
  }
}))

const CustomMenu = ({children, ...other}) => {
  const classes = useStyles();

  return (
    <Menu
      PopoverClasses={{ paper: classes.dropdownStyle }}
      getContentAnchorEl={null}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      id="basic-menu"
      {...other}
    >
      {children}
    </Menu>
  );
};

export default CustomMenu;
