import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useMediaColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'image',
      label: t('media.image'),
      sortable: false,
    },
    {
      name: 'name',
      label: t('media.name'),
    },
    {
      name: 'id',
      label: 'ID',
      sortable: false,
    },
    {
      name: 'uploadedAt',
      label: t('ui.uploaded_on'),
    },
    {
      name: 'options',
    }
  ], [t]);
};
