import gql from "graphql-tag";

export default gql`
  fragment DiscountCodeCommon on DiscountCode {
    _id
    createdAt
    code
    discount
    label
    calculation {
      method
    }
    conditions {
      enabled
      accountLimit
      redemptionLimit
      audience
      products
      order {
        endDate
        startDate
      }
    }
    description
    discountMethod
  }
`;
