import gql from "graphql-tag";
import { ShopCategory } from "./fragments";

export const getShopCategoriesQuery = gql`
  query getShopCategories($shopCategoryIds: [ID], $first: ConnectionLimitInt, $last:  ConnectionLimitInt, $before: ConnectionCursor, $after: ConnectionCursor, $filter: String, $offset: Int, $sortOrder: SortOrder, $sortBy: ShopCategorySortByField, $genericFilters: GenericFilterInput) {
    getShopCategories(shopCategoryIds: $shopCategoryIds, first: $first, last: $last, before: $before, after: $after, filter: $filter, offset: $offset, sortOrder: $sortOrder, sortBy: $sortBy, genericFilters: $genericFilters) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ${ShopCategory}
      }
      totalCount
    }
  }
`;

export const getShopCategory = gql`
  query getShopCategory($shopCategoryId: ID) {
    getShopCategory(shopCategoryId: $shopCategoryId) {
      name
      description
    }
  }
`;
