import React, { useCallback, useMemo, useState } from "react";
import FormCard from "../../../UI/Form/FormCard";
import { CircularProgress, IconButton } from "@material-ui/core";
import FormHeader from "../../../UI/Form/FormHeader";
import svgPlus from '../../../icons/plusButton.svg';
import BillingCard from "./BillingCard";
import Dialog from "../../../UI/Dialog/Dialog";
import BillingForm from "./BillingForm";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import StyledButton from "../../../UI/Button/Button";
import { useMutation } from "@apollo/react-hooks";
import { stripeMakePaymentMethodDefault, stripeRemovePaymentMethodMutation } from "../graphql/mutations";
import { useSnackbar } from "notistack";
import styled from 'styled-components';
import Button from "../../../UI/Button/Button";
import { useTranslation } from "react-i18next";

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BillingInformation = ({ paymentMethods, refetchPayments, companyId, loading }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogName, setDialogName] = useState('');
  const [cardId, setCardId] = useState(null);
  const [settingDefault, setSettingDefault] = useState(false);

  const {enqueueSnackbar} = useSnackbar();
  const { t } = useTranslation();

  const handleAdd = () => {
    setCardId(null);
    setDialogName('add');
    setDialogOpen(true);
  }

  const handleRemoveCard = useCallback((id) => {
    setCardId(id);
    setDialogName('remove');
    setDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const [stripeRemovePaymentMethod] = useMutation(stripeRemovePaymentMethodMutation, {
    ignoreResults: true,
    onCompleted() {
      enqueueSnackbar(t('settings.removed_payment_success'), { variant: "success" });
      refetchPayments();
      closeDialog();
    },
    onError(error) {
      enqueueSnackbar(error.message.replace("GraphQL error:", ""), { variant: "error" });
      closeDialog();
    },
  });

  const [mutationSetAsDefault] = useMutation(stripeMakePaymentMethodDefault, {
    ignoreResults: true,
    onCompleted() {
      refetchPayments();
    },
    onError(error) {
      enqueueSnackbar(error.message.replace("GraphQL error:", ""), { variant: "error" });
    },
  })

  const handleSetDefault = async () => {
    setSettingDefault(true);

    await mutationSetAsDefault({
      variables: {
        input: {
          companyId,
          paymentMethodId: cardId,
        }
      }
    });

    setSettingDefault(false);
  }

  const DialogRemove = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('settings.remove_credit_card')}
          message={t('settings.remove_credit_card_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={async () => {
              closeDialog();
              if (!cardId) {
                return;
              }

              await stripeRemovePaymentMethod({
                variables: {
                  input: {
                    companyId,
                    paymentMethodId: cardId,
                  },
                },
              });

              setCardId(null);
              refetchPayments();
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          <StyledButton
            width='180px'
            mytype='secondary'
            handleClick={closeDialog}
          >
            {t('ui.no')}
          </StyledButton>
        </DialogButtons>
      </>
    );
  }, [companyId, cardId, refetchPayments]);

  const renderDialog = () => {
    switch (dialogName) {
      case 'remove': return DialogRemove;
      case 'add': return <BillingForm
        cardId={cardId}
        refetchPayments={refetchPayments}
        closeDialog={closeDialog}
        companyId={companyId}
      />;
      default: return null;
    }
  }

  return (
    <>
      <FormCard style={{ paddingBottom: '16px'}}>
        <FormHeader>
          <span>{t('settings.billing_info')}</span>
          {
            !loading &&
            <IconButton
              onClick={handleAdd}
            >
              <img src={svgPlus} alt='+'/>
            </IconButton>
          }
        </FormHeader>
        {
          loading ?
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper> :
            <>
              {
                paymentMethods.map(card => <BillingCard
                  key={card.id}
                  brand={card.brand}
                  isDefault={card.isDefault}
                  cardNumber={card.lastFourDigits}
                  handleRemoveCard={() => handleRemoveCard(card.id)}
                  handleClick={() => setCardId(card.id)}
                  isSelected={card.id === cardId}
                />)
              }
              {
                paymentMethods.length > 0
                && <Button
                  width='180px'
                  disabled={
                    !cardId ||
                    settingDefault ||
                    paymentMethods.find((k) => k.id === cardId)?.isDefault
                  }
                  handleClick={handleSetDefault}
                >
                  {t('settings.set_as_default')}
                </Button>
              }
            </>
        }
      </FormCard>

      <Dialog
        open={dialogOpen}
        handleClose={closeDialog}
      >
        {renderDialog()}
      </Dialog>
    </>

  );
};

export default BillingInformation;
