import React from "react";
import { useHistory } from "react-router-dom";
import PrimaryAppBar from "../../../package/src/PrimaryAppBar/PrimaryAppBar";
import { useTranslation } from "react-i18next";

function OrderToolbarPrint({shopId, referenceId}) {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <PrimaryAppBar
      title={t('orders.order_printable_invoice', { referenceId })}
      onBackButtonClick={() => {
        history.push(`/orders/${shopId}/${referenceId}`);
      }}
    />
  );
}

export default OrderToolbarPrint;
