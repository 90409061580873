import React, { useCallback, useContext, useEffect, useState } from "react";
import { Collapse, Grid, ListItem, Radio, RadioGroup } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { ToggleButtonGroup } from "@material-ui/lab";
import doorSVG from "../../../icons/door.svg";
import firstFloorSVG from "../../../icons/firstfloor.svg";
import roofSVG from "../../../icons/roof.svg";
import nameSVG from "../../../icons/name.svg";
import secondFloorSVG from "../../../icons/secondfloor.svg";
import umbrellaSVG from "../../../icons/ubrella.svg";
import FormHeader from "../../../UI/Form/FormHeader";
import Icon from "../../../UI/Icon/Icon";
import InputLabel from "../../../UI/Form/InputLabel";
import CircleIcon from "./CircleIcon";
import DragDropFile from "./DragDropFile";
import ToggleButton from "./StyledToggleButton";
import StyledLine from "./StyledLine";
import StyledLabel from "./StyledLabel";
import AccessoryGroup from "./AccessoryGroup";
import { shopConfigQuery } from "../graphql/queries";
import { addShopConfig, updateShopConfig } from "../graphql/mutations";
import SaveButtons from "../../../UI/Button/SaveButtons";
import Button from "../../../UI/Button/Button";
import { useApolloClient } from "@apollo/react-hooks";
import { StyledDivScroll, useStylesScene } from "../styles";
import checkConfigForFullness from "../utils/checkConfigForFullness";
import triggerScene from "../utils/triggerScene";
import { ConfiguratorScene } from "./ConfiguratorScene";
import IconFormControlLabel from "./IconFormControlLabel";
import { COLORS } from "../constants";
import PageStatusMessage from "../../../UI/other/PageStatusMessage";
import { getSubscriptionPackageConfigurator } from "../../subscription-packages/graphql/queries";
import transformLoadedConfiguration from "../utils/transformLoadedConfiguration";
import createConfigForBeginning from "../utils/createConfigForBeginning";
import getAccessories from "../utils/getAccessories";
import { useTranslation } from "react-i18next";
import { ShopEditContext } from "../../../package/src/context/ShopEditContext";
import useGetPackages from "../../shops/hooks/useGetPackages";

function Scene({ shopId, companyId, godmode }) {
  const classes = useStylesScene();
  const canvasRef = React.createRef();
  const { t } = useTranslation();
  const apolloClient = useApolloClient();

  const {
    packageId,
  } = useContext(ShopEditContext);

  const [accessory, setAccessory] = useState("door");
  const [currentAccessories, setCurrentAccessories] = useState({
    "door": "",
    "roof": "",
    "floorfirst": "",
    "floorsecond": "",
    "name": "",
    "umbrella": "",
  });
  const [beginCreating, setBeginCreating] = useState(true);
  const [selectedColorValue, setSelectedColorValue] = useState("#e5cdc4");
  const [colorOpen, setColorOpen] = useState(false);
  const [expandPic, setExpandPic] = useState(false);
  const [expandState, setExpandState] = useState(false);
  const [canSafeChanges, setCanSafeChanges] = useState(false);
  const [config, setConfig] = useState(null);
  const [updateChanges, setUpdateChanges] = useState(false);
  const [loadingModules, setLoadingModules] = useState(true);
  const [permittedModules, setPermittedModules] = useState(null);

  useGetPackages(companyId, godmode);

  const getShopConfiguration = () => {
    return apolloClient.query({
      query: shopConfigQuery,
      variables: {
        id: shopId,
      },
      fetchPolicy: "network-only",
    });
  };

  const getPackagePermissions = () => {
    return apolloClient.query({
      query: getSubscriptionPackageConfigurator,
      variables: {
        id: packageId,
      },
      fetchPolicy: "network-only",
    })
  }

  const updateShopConfiguration = useCallback((data, isUpdate) => {
    data.shopId = shopId;

    if (isUpdate) {
      return apolloClient.mutate({
        mutation: updateShopConfig,
        variables: {
          input: data,
        },
      });
    }

    return apolloClient.mutate({
      mutation: addShopConfig,
      variables: {
        input: data,
      },
    });
  }, [shopId]);

  const handleColorClick = () => {
    setColorOpen(!colorOpen);
  };

  const handlePictureClick = () => {
    setExpandPic(!expandPic);
  };

  const handleAccessory = (event, newAccessory) => {
    setAccessory(newAccessory);
    triggerScene("accessoryTypeChanged", { accessory: newAccessory });
  };

  const handleColorChanged = (event, newColor) => {
    setSelectedColorValue(newColor);
    triggerScene("colorChanged", { color: newColor, accessory: accessory });
  };

  const handleModelChanged = (event, newModel) => {
    if (beginCreating) {
      setConfig(createConfigForBeginning(newModel, accessory));
      setBeginCreating(false);
    } else {
      if (newModel) {
        triggerScene("modelChanged", { model: newModel, accessory: accessory });
      }
    }
  };

  const handleSaveChanges = (event) => {
    triggerScene("saveChanges", event);
  };

  const handleSettingsOfAsset = (configuration) => {
    if (configuration.color) {
      // Perhaps in the future this will come in handy when they want to change colors individually again.
      // setSelectedColorValue(configuration.color);
    }
  };

  const convertAccessoryMainText = (key) => {
    switch (key) {
      case 'door': return t('configurator.door');
      case 'floorfirst': return t('configurator.first_floor');
      case 'floorsecond': return t('configurator.second_floor');
      case 'name': return t('configurator.name');
      case 'roof': return t('configurator.roof');
      case 'umbrella': return t('configurator.umbrella');
      default: return key;
    }
  }

  useEffect(() => {
    if (!packageId || !shopId || !beginCreating) {
      return;
    }

    Promise.all([
      getPackagePermissions(),
    ])
      .then(res => {
        const permissions = res[0].data.subscriptionPackage.configuratorParts;
        setPermittedModules(permissions);
        Promise.all([
          getShopConfiguration(),
        ])
          .then(res => {
            const config = res[0].data;
            setConfig(config);
            setUpdateChanges(true);
            setCanSafeChanges(checkConfigForFullness(config));
            setBeginCreating(false);
          })
          .catch(e => console.log(e.message))
          .finally(() => setLoadingModules(false));
      })
      .catch(e => console.log(e.message));

  }, [packageId, shopId, beginCreating])

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    let viewGl = new ConfiguratorScene(canvasRef.current);

    if (!beginCreating) {
      let result = viewGl.loadConfig(config, permittedModules);
      const accessories = transformLoadedConfiguration(result);
      setCurrentAccessories(accessories);
      setSelectedColorValue(viewGl.shop.currentColor.toLowerCase());
    }

    const handleResize = () => {
      viewGl.onWindowResize(window.innerWidth, window.innerHeight);
    };

    const handleColor = (event) => {
      viewGl.setAllAccessoriesColor(event.detail.color);
      setCanSafeChanges(viewGl.checkCanSafe());
    };

    const handleModel = (event) => {
      if (event.detail.accessory === 'umbrella' && event.detail.model === 'none_umbrella') {
        viewGl.setAccessoryModel(event.detail.accessory, null, null);
      } else {
        viewGl.setAccessoryModel(event.detail.accessory, event.detail.model, null);
      }
      setCanSafeChanges(viewGl.checkCanSafe());
      const accessories = transformLoadedConfiguration(viewGl.getModelMap());
      setCurrentAccessories(accessories);
    };

    const handleImages = (event) => {
      viewGl.setNewImage(event.detail.img);
      setCanSafeChanges(viewGl.checkCanSafe());
    };

    const handleAccessoryType = (event) => {
      if (event.detail.accessory !== null) {
        viewGl.setCurrentAccessoryType(event.detail.accessory);
        handleSettingsOfAsset(viewGl.getSettingsOfAsset(event.detail.accessory));
      }
    };

    const handleSaveChanges = (event) => {
      if (!viewGl.checkCanSafe()) {
        return;
      }

      setCanSafeChanges(false);
      updateShopConfiguration(viewGl.getCurrentConfiguration(), updateChanges)
        .catch(e => console.log(e.message));
    };

    document.addEventListener("saveChanges", handleSaveChanges);
    document.addEventListener("accessoryTypeChanged", handleAccessoryType);
    document.addEventListener("imgChanged", handleImages);
    document.addEventListener("modelChanged", handleModel);
    document.addEventListener("colorChanged", handleColor);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("saveChanges", handleSaveChanges);
      document.removeEventListener("colorChanged", handleColor);
      document.removeEventListener("modelChanged", handleModel);
      document.removeEventListener("imgChanged", handleImages);
      document.removeEventListener("accessoryTypeChanged", handleAccessoryType);
    };
  }, [beginCreating]);

  const renderAccessoryGroup = (accessory) => {
    const mainText = convertAccessoryMainText(accessory);
    if (permittedModules === null) {
      return <div/>
    }
    const mappedAccessories = getAccessories(accessory, permittedModules);
    return <AccessoryGroup
      expandGroup={expandState}
      setExpandGroup={setExpandState}
      mainText={mainText}
      onChangeMethod={handleModelChanged}
      accessories={mappedAccessories}
      currentValue={currentAccessories[accessory]}
      shopId={shopId}
    />
  }

  return (
    <Grid container spacing={5}>

      <Grid item xs={12} lg={7}>
        {beginCreating ? <div className={classes.beginOfCreating}>{t('configurator.select_item_message')}</div> :
          <div
            className={classes.sceneContainer}
            id="sceneContainerId"
          >
            <canvas ref={canvasRef}>
            </canvas>
          </div>
        }
      </Grid>

      <Grid item xs={12} lg={5}>

        <StyledDivScroll>
          {
            loadingModules ?
              <PageStatusMessage>{t('ui.loading')}...</PageStatusMessage> :
              <>
                <FormHeader>{t('configurator.design_your_store')}</FormHeader>
                <InputLabel>{t('configurator.modules')}</InputLabel>
                <ToggleButtonGroup
                  size="large"
                  aria-label="selected accessory"
                  value={accessory}
                  exclusive
                  onChange={handleAccessory}
                  className={classes.toggleGroup}
                >
                  <ToggleButton value="door" aria-label="Door">
                    <Icon
                      className={classes.svgIcon}
                    >
                      <img src={doorSVG} alt="" />
                    </Icon>
                  </ToggleButton>

                  <ToggleButton value="floorfirst" aria-label="first floor">
                    <Icon
                      className={classes.svgIcon}
                    >
                      <img src={firstFloorSVG} alt="" />
                    </Icon>
                  </ToggleButton>
                  <ToggleButton value="floorsecond" aria-label="second floor">
                    <Icon
                      className={classes.svgIcon}
                    >
                      <img src={secondFloorSVG} alt="" />
                    </Icon>
                  </ToggleButton>
                  <ToggleButton value="name" aria-label="shop name">
                    <Icon
                      className={classes.svgIcon}
                    >
                      <img src={nameSVG} alt="" />
                    </Icon>
                  </ToggleButton>
                  <ToggleButton value="roof" aria-label="Roof">
                    <Icon
                      className={classes.svgIcon}
                    >
                      <img src={roofSVG} alt="" />
                    </Icon>
                  </ToggleButton>
                  <ToggleButton value="umbrella" aria-label="umbrella">
                    <Icon
                      className={classes.svgIcon}
                    >
                      <img src={umbrellaSVG} alt="" />
                    </Icon>
                  </ToggleButton>

                </ToggleButtonGroup>

                {
                  accessory ? renderAccessoryGroup(accessory) : <div/>
                }

                <StyledLine />

                <ListItem onClick={handleColorClick} className={classes.expandButton}>
                  <StyledLabel>{t('configurator.color')}</StyledLabel>
                  {colorOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={colorOpen} timeout="auto" unmountOnExit>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selectedColorValue}
                    name="radio-buttons-group"
                    onChange={handleColorChanged}
                  >
                    {COLORS.map((colorName) => (
                      <IconFormControlLabel iconStart={<CircleIcon style={{ color: colorName }} />}
                                            isSelected={colorName === selectedColorValue}
                                            key={colorName}
                                            value={colorName}
                                            control={<Radio />}
                                            label={colorName}
                                            labelPlacement="start"
                      />
                    ))}
                  </RadioGroup>
                </Collapse>

                {(accessory === "name" || accessory === "door" || accessory === "floorfirst") ? ((
                    <div>
                      <StyledLine />
                      <ListItem onClick={handlePictureClick} className={classes.expandButton}>
                        <StyledLabel>{t('configurator.logo')}</StyledLabel>
                        {expandPic ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={expandPic} timeout="auto" unmountOnExit>
                        <DragDropFile />
                      </Collapse>
                    </div>)
                ) : null}
              </>
          }

        </StyledDivScroll>

      </Grid>

      <SaveButtons margin="24px 0">
        <Button
          disabled={!canSafeChanges || loadingModules}
          type="submit"
          width="180px"
          onClick={handleSaveChanges}
        >
          {t('ui.save_changes')}
        </Button>
      </SaveButtons>
    </Grid>
  );
}

export default Scene;
