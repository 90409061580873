import * as Yup from "yup";

export const InviteSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('errors.field_required'),
  lastName: Yup.string()
    .required('errors.field_required'),
  email: Yup.string()
    .email('errors.invalid_email')
    .required('errors.field_required'),
  phoneNumber: Yup.string()
    .required('errors.field_required'),
})
