import { useContext } from "react";
import { SingleShopSelectorContext } from "../context/SingleShopSelectorContext";

/**
 * React Hook that gets the globally current shop IDs from localStorage.
 * If localStorage is empty, set it to first item in viewer.adminUIShopIds
 * @return {Array} [currentShopIds]
 */
export default function useCurrentSingleShopIds(viewer) {
  const { localStorageKey } = useContext(SingleShopSelectorContext);
  let selectedShopIds = JSON.parse(localStorage.getItem(localStorageKey)) || [];

  // If no selection saved by user --> pick first in adminUIShops
  if (!selectedShopIds || selectedShopIds.length === 0) {
    selectedShopIds =
      viewer?.adminUIShops && viewer.adminUIShops.length ? [viewer.adminUIShops[0]._id] : [];
    localStorage.setItem(localStorageKey, JSON.stringify(selectedShopIds));
  }

  return selectedShopIds;
}
