import React from "react";
import styled from 'styled-components';
import {colors} from '../../../../../constants';
import { IconButton } from "@material-ui/core";

const getColor = ({error, disabled}) => {
  if (error) {
    return colors.redLight;
  }
  if (disabled) {
    return colors.textGrey;
  }
  return 'black';
}

const StyledInputLabel = styled.div`
  padding: 0;
  margin-bottom: 15px;
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: ${props => getColor(props)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledIconButton = styled(IconButton)`
  width: 23px;
  height: 23px;
  background: none;
  padding: 0;
  margin-right: 8px;
  
  &:hover {
    background: none;
  }
`;

const InputLabelWithButton = ({
  children,
  error,
  disabled,
  style,
  onButtonClick,
  ButtonIconComponent,
}) => {
  return (
    <StyledInputLabel
      error={Boolean(error)}
      disabled={disabled}
      style={style}
    >
      {children}
      {
        onButtonClick && ButtonIconComponent &&
          <StyledIconButton
            disableRipple
            disabled={disabled}
            onClick={onButtonClick}
          >
            <ButtonIconComponent />
          </StyledIconButton>
      }
    </StyledInputLabel>
  );
}

export default InputLabelWithButton;
