const cleanNumber = (value) => {
  if (value === '') {
    return 0;
  }

  const converted = Number(value);

  if (isNaN(converted)) {
    return 0;
  }

  return converted;
};

export default cleanNumber;
