import React from "react";
import styled from "styled-components";

const Wrapper = styled('div')`
  background: #FFFFFF;
  border: 5px solid #EAEAEA;
  border-radius: 20px;
  width: 100%;
  height: 140px;
  padding: 32px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
  
  .text {
    font-family: 'Fredoka', sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: black;
    line-height: 70px;
    z-index: 2;
    position: relative;
    margin: 0;
    user-select: none;
    text-align: left;
  }

  .image-wrapper {
    position: absolute;
    display: flex;
    z-index: 1;
    transition: .2s;
    user-select: none;
  }
  
  .image-wrapper__1 {
    width: 277px;
    height: 100%;
    right: ${(props) => (props.imageshift ? props.imageshift : 0)};
    top: 0;
  }

  @media all and (max-width: 767px) {
    height: 80px;
    padding: 20px;
    overflow-y: hidden;
    
    .text {
      font-size: 18px;
      line-height: 30px;
    }
    
    .image-wrapper {
      width: 276px;
      height: 123px;
    }
  }

  @media all and (max-width: 500px) {
    .image-wrapper {
      width: 200px;
      height: 100%;
    }
  }
  
  &:hover {
    background: #F4F6FA;
    
    .image-wrapper {
      transform: scale(1.1);
    }
  }
  
  &:active {
    background: #EAEAEA;
    border-color: #E9EBF0;
    
    .image-wrapper {
      transform: scale(1.1);
    }
  }
`;

const ChooseCard = ({ text, imgUrl, handleClick, disabled }) => {
  const onClick = () => {
    if (!disabled) {
      handleClick();
    }
  };

  return (
    <Wrapper
      onClick={onClick}
    >
      <p className='text'>{text}</p>
      <div className='image-wrapper image-wrapper__1'>
        <img
          src={imgUrl}
          alt=''
        />
      </div>
    </Wrapper>
  );
};

export default ChooseCard;
