const includeNumber = (val) => {
  if (!val) {
    return;
  }

  const result = Number(val);

  if (isNaN(result)) {
    return;
  }

  return result;
};

export default includeNumber;
