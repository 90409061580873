import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 32px;

  @media all and (max-width: 960px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

/* use it in children

<Grid container spacing={2}>
  <Grid item xs={12} lg={7}>
 */

const TwoColumnsPage = ({children}) => {
  return (
    <StyledDiv>
      {children}
    </StyledDiv>
  );
}

export default TwoColumnsPage;