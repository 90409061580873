import React, { useState } from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import StyledButton from "../../../../UI/Button/Button";
import Dialog from "../../../../UI/Dialog/Dialog";
import { Form } from '../../../../UI/Form/MyForm';
import DialogMessages from "../../../../UI/Dialog/DialogMessages";
import FormInput from "../../../../UI/Form/FormInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CreateProjectSchema } from "./schemas";
import { useMutation } from "@apollo/react-hooks";
import { createProject } from "../../graphql/mutations";

const Wrapper = styled('div')`
  height: 68vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

const CreateText = styled(Typography)`
  color: #222;
  font-family: Fredoka, sans-serif;
  font-size: 24px;
  font-weight: 500;
`;

const DescribeText = styled(Typography)`
  color: #BFBFBF;
  text-align: center;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 480px;
`;

const FieldBox = styled('div')`
  width: 420px;
  margin-top: 24px;
`;

const EditorTabStart = ({ setIsListPage, shopId, setRooms }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(CreateProjectSchema),
    defaultValues: {
      name: '',
    },
  });

  const [createProjectMutation] = useMutation(createProject);

  const onSubmit = async (data) => {
    try {
      await createProjectMutation({
        variables: {
          input: {
            shopId,
            name: data.name
          }
        }
      }).then((data) => {
        console.log(data);
        setIsListPage(true);
        setRooms([data.data.addRoom.data]);
      })
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Wrapper>
      <Content>
        <CreateText>
          Get started your first 3D interior
        </CreateText>
        <DescribeText>
          While there are no projects to showcase just yet, we're excited to help you bring your vision to life into our 3D editor
        </DescribeText>
        <StyledButton
          width='140px'
          onClick={() => setOpenDialog(true)}
        >
          Create
        </StyledButton>
      </Content>

      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
      >
        <DialogMessages
          title='Enter the Name'
        />
        <Form
          onSubmit={handleSubmit(onSubmit)}
          width='100%'
        >
          <FieldBox>
            <Controller
              name="name"
              control={control}
              render={({ field }) => <FormInput
                placeholder='Name'
                value={field.value}
                onChange={field.onChange}
                error={errors.name}
              />}
            />
          </FieldBox>
          <StyledButton
            type='submit'
            width='100%'
          >
            Submit
          </StyledButton>
        </Form>
      </Dialog>
    </Wrapper>
  );
};

export default EditorTabStart;
