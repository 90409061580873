import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import FormHeader from "../../../UI/Form/FormHeader";
import FormDetails from "../../../UI/Form/FormDetails";
import { IconButton } from "@material-ui/core";
import svgEdit from "../../../icons/table-edit.svg";
import FormInput from "../../../UI/Form/FormInput";
import { parseAddress } from "../helpers/modifiers";
import SaveButtonsSimple from "../../../UI/Button/SaveButtonsSimple";
import OrderItemSingle from "./OrderItemSingle";
import { useTranslation } from "react-i18next";

const OrderItem = ({group, saveQuantity, saveTrackingNumber, isLoading}) => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [editMode, setEditMode] = useState(false);
  const trackingOriginalValue = group.tracking || '';
  const { t } = useTranslation();

  useEffect(() => {
    setTrackingNumber(trackingOriginalValue);
  }, [group])

  const {
    data: { shippingAddress },
  } = group;

  const handleCancel = () => {
    setTrackingNumber(trackingOriginalValue);
    setEditMode(false);
  }

  const handleSave = () => {
    setEditMode(false);
    saveTrackingNumber({
      orderFulfillmentGroupId: group._id,
      tracking: trackingNumber,
    });
  }

  return (
    <>
      <FormHeader>{t('orders.items')}</FormHeader>
      <Grid container spacing={6}>

        <Grid item xs={12} lg={6}>
          {
            group.items.nodes.map((item, index, arr) => <OrderItemSingle
                key={item._id}
                data={item}
                saveQuantity={saveQuantity}
                isLastItem={arr.length === index + 1}
                isLoading={isLoading}
              />)
          }
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormDetails isList>
            <p className='title title--marginHeading'>{t('orders.shipping_address')}</p>
            <p className='text'><span>{t('orders.full_name')}: </span>{shippingAddress.fullName}</p>
            <p className='text'><span>{t('orders.shipping_address')}: </span>{parseAddress(shippingAddress)}</p>
            <p className='text'><span>{t('orders.shipping_method')}: </span> {group.selectedFulfillmentOption.fulfillmentMethod.carrier} -&nbsp;
              {group.selectedFulfillmentOption.fulfillmentMethod.displayName}</p>
          </FormDetails>

            <FormDetails>
              <p className='title'>{t('orders.tracking_number')}:</p>
              {
                editMode ? <SaveButtonsSimple
                    onSaveClick={handleSave}
                    onCancelClick={handleCancel}
                    disabled={isLoading}
                  /> :
                  <IconButton
                    onClick={() => setEditMode(true)}
                  ><img src={svgEdit} alt=''/></IconButton>
              }
            </FormDetails>

            <FormInput
              placeholder={t('orders.write_tracking_number')}
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
              style={{marginBottom: 0}}
              disabled={!editMode || isLoading}
            />
          </Grid>
      </Grid>
    </>
  );
}

export default OrderItem;
