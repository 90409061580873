import React from "react";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import StyledButton from "../../../UI/Button/Button";
import { useTranslation } from "react-i18next";

const DialogButtonsContainer = ({confirmAction, declineAction}) => {
  const { t } = useTranslation();

  return (
    <DialogButtons justifyButtons='center'>
      <StyledButton
        width='180px'
        handleClick={confirmAction}
      >
        {t("ui.yes")}
      </StyledButton>
      <StyledButton
        width='180px'
        mytype='secondary'
        handleClick={declineAction}
      >
        {t("ui.no")}
      </StyledButton>
    </DialogButtons>
  )
}

export const RemoveMedia = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogMessages
        title={t('product.remove_media')}
        message={t('product.remove_media_q')}
      />
      <DialogButtonsContainer
        {...props}
      />
    </>
  )
}

export const DialogPublish = ({ selectedRows, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogMessages
        title={t('product.publish_title_plural', { count: selectedRows.length })}
        message={t("product.publish_message_plural")}
      />
      <DialogButtonsContainer
        {...props}
      />
    </>
  )
}

export const DialogMakeVisibleSingle = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogMessages
        title={t("product.make_visible_title", {count: 1})}
        message={t("product.make_visible_message", {count: 1})}
      />
      <DialogButtonsContainer
        {...props}
      />
    </>
  )
}

export const DialogMakeHiddenSingle = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogMessages
        title={t("product.make_hidden_title", {count: 1})}
        message={t("product.make_hidden_message", {count: 1})}
      />
      <DialogButtonsContainer
        {...props}
      />
    </>
  )
}

export const DialogDeleteSingle = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogMessages
        title={t('product.delete_product_q')}
        message={t('product.delete_product_message')}
      />
      <DialogButtonsContainer
        {...props}
      />
    </>
  )
}

export const DialogMakeVisible = ({ selectedRows, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogMessages
        title={t("product.make_visible_title_plural", {
          count: selectedRows.length,
        })}
        message={t("product.make_visible_message_plural", {
          count: selectedRows.length,
        })}
      />
      <DialogButtonsContainer
        {...props}
      />
    </>
  );
}

export const DialogMakeHidden = ({ selectedRows, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogMessages
        title={t("product.make_hidden_title_plural", {
          count: selectedRows.length,
        })}
        message={t("product.make_hidden_message_plural", {
          count: selectedRows.length,
        })}
      />
      <DialogButtonsContainer
        {...props}
      />
    </>
  );
}

export const DialogDuplicate = ({ selectedRows, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogMessages
        title={t("product.duplicate_q", {
          count: selectedRows.length,
        })}
        message={t("product.duplicate_message", {
          count: selectedRows.length,
        })}
      />
      <DialogButtonsContainer
        {...props}
      />
    </>
  );
}

export const DialogDelete = ({ selectedRows, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogMessages
        title={t('product.delete_product_q_plural', { count: selectedRows.length })}
        message={t('product.delete_product_message_plural')}
      />
      <DialogButtonsContainer
        {...props}
      />
    </>
  );
}
