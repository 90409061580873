import React, { useState } from "react";
import FormHeader from "../../../UI/Form/FormHeader";
import formatDateRange from "../../../package/src/utils/formatDateRange";
import DateRangeSelector from "../../../package/src/DateRangeSelector";
import styled from 'styled-components';
import Button from "../../../UI/Button/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  width: 792px;
  
  @media all and (max-width: 960px) {
    width: 100%;
  }
`;

const BlockShopper = ({ onAccept, loading }) => {
  const { t } = useTranslation();

  const [date, setDate] = useState({ startDate: null, endDate: null });

  const handleChange = (value) => {
    setDate(value);
  }

  const handleAccept = () => {
    onAccept(date);
  }

  return (
    <Wrapper>
      <FormHeader>{t('shoppers.block_shopper')}</FormHeader>

      <DateRangeSelector
        placeholder={t('shoppers.validity_period')}
        dateRange={formatDateRange(date)}
        onChange={handleChange}
        onlyFromToday
        disabled={loading}
      />

      <SaveButtons margin='16px 0 0 0'>
        <Button
          width='180px'
          onClick={handleAccept}
          disabled={loading}
        >
          {t('ui.accept')}
        </Button>
      </SaveButtons>
    </Wrapper>
  );
};

export default BlockShopper;
