import { styled, Typography } from '@mui/material';

const Wrapper = styled(Typography)`
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: ${(props) => (props.error === 'true' ? 'red' : 'black')};
  margin-bottom: 57px;
  
  @media all and (max-width: 768px) {
    font-size: 14px;  
    margin-bottom: 32px;
  }
`;

export const SubHeading = ({ children, error, ...other }) => {
    return (
        <Wrapper error={error} {...other}>
            { children }
        </Wrapper>
    );
};

export default SubHeading;
