import React, { useMemo, useState } from "react";
import styled from 'styled-components';
import FormHeader from "../../../UI/Form/FormHeader";
import FormCard from "../../../UI/Form/FormCard";
import OrderItem from "./OrderItem";
import Grid from "@material-ui/core/Grid";
import Button from "../../../UI/Button/Button";
import Dropdown from "../../../UI/Dropdown";
import { cleanStatus } from "../helpers/modifiers";
import Dialog from "../../../UI/Dialog/Dialog";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import StyledButton from "../../../UI/Button/Button";
import { useTranslation } from "react-i18next";
import getStatus from "../../../package/src/utils/getStatus";
import { renderStatus } from "../hooks/useModifiers";

const StyledRightColumn = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 32px;
  
  @media all and (max-width: 1279px) {
    justify-content: flex-start;
  }
`

const StyledLeftColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 8px;
  
  @media all and (max-width: 1279px) {
    flex-direction: row;
    gap: 16px;
    margin-bottom: 12px;
  }
`

const OrderGroup = ({group, currentGroupCount, totalGroupsCount, saveQuantity, saveTrackingNumber, isLoading, onCancelGroup, onUpdateGroupStatus}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogName, setDialogName] = useState(null);
  const { t } = useTranslation();

  const statusText = cleanStatus(group.status);
  const allStatuses = t('statuses', { returnObjects: true });
  const statusObj = getStatus(allStatuses, statusText);

  const updateList = useMemo(() => [
    {
      content: t('orders.new'),
      action: () => onUpdateGroupStatus('new'),
    },
    {
      content: t('orders.created'),
      action: () => onUpdateGroupStatus('coreOrderWorkflow/created'),
    },
    {
      content: t('orders.processing'),
      action: () => onUpdateGroupStatus('coreOrderWorkflow/processing'),
    },
    {
      content: t('orders.completed'),
      action: () => onUpdateGroupStatus('coreOrderWorkflow/completed'),
    },
    {
      content: t('orders.picked'),
      action: () => onUpdateGroupStatus('coreOrderWorkflow/picked'),
    },
    {
      content: t('orders.packed'),
      action: () => onUpdateGroupStatus('coreOrderWorkflow/packed'),
    },
    {
      content: t('orders.labeled'),
      action: () => onUpdateGroupStatus('coreOrderWorkflow/labeled'),
    },
    {
      content: t('orders.shipped'),
      action: () => onUpdateGroupStatus('coreOrderWorkflow/shipped'),
    },
  ], [t])

  const handleCancelGroup = () => {
    if (group.items.nodes.some(item => item.quantity < 1)) {
      setDialogName('warning');
    } else {
      setDialogName('cancel');
    }
    setDialogOpen(true);
  }

  const DialogCancel = useMemo(() => {
    return <>
      <DialogMessages
        title={t('orders.cancel_group')}
        message={t('orders.cancel_group_q')}
      />
      <DialogButtons justifyButtons='center'>
        <StyledButton
          width='180px'
          handleClick={() => {
            setDialogOpen(false);
            onCancelGroup();
          }}
        >
          {t('orders.cancel_group')}
        </StyledButton>
        <StyledButton
          width='180px'
          mytype='secondary'
          handleClick={() => setDialogOpen(false)}
        >
          {t('ui.close')}
        </StyledButton>
      </DialogButtons>
    </>
  }, [t])

  const DialogWarning = useMemo(() => {
    return <>
      <DialogMessages
        title={t('ui.warning')}
        message={t('orders.quantity_least_1')}
      />
      <DialogButtons justifyButtons='center'>
        <StyledButton
          width='180px'
          handleClick={() => setDialogOpen(false)}
        >
          {t('ui.ok')}
        </StyledButton>
      </DialogButtons>
    </>
  }, [t])

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'cancel': return DialogCancel;
      case 'warning': return DialogWarning;
      default: return null;
    }
  }

  return (
    <>
      <FormCard>
        <Grid container spacing={2}>
          <StyledLeftColumn item xs={12} lg={6}>
            <FormHeader noHorizontalRule>
              {`${t('orders.fulfillment_group')} ${currentGroupCount} ${t('orders.of')} ${totalGroupsCount}`}
            </FormHeader>
            {
              renderStatus(statusObj)
            }
          </StyledLeftColumn>
          <StyledRightColumn item xs={12} lg={6}>
            {
              group.status !== "coreOrderWorkflow/canceled" &&
              <>
                <Button
                  width='160px'
                  mytype='redHollow'
                  disabled={isLoading}
                  handleClick={handleCancelGroup}
                >
                  {t('orders.cancel_group')}
                </Button>
                <Dropdown
                  title={t('orders.update_group_status')}
                  itemsList={updateList}
                  variant='blue'
                  styleButton={{height: 38}}
                  disabled={isLoading}
                />
              </>
            }
          </StyledRightColumn>
        </Grid>

        <OrderItem
          group={group}
          saveQuantity={saveQuantity}
          saveTrackingNumber={saveTrackingNumber}
          isLoading={isLoading}
        />
      </FormCard>

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>
  );
}

export default OrderGroup;
