import { useTranslation } from "react-i18next";
import { formatTotal } from "../utils/formatTotal";
import React, { useCallback } from "react";
import calculateAccountingValues from "../utils/calculateAccountingValues";
import getKeyByValue from "../utils/getKeyByValue";
import Status from "../../../UI/Status";
import moment from "moment/moment";
import numberWithCommas from "../utils/numberWithCommas";
import getStatus from "../../../package/src/utils/getStatus";
import { constants } from "../../../constants";

const ORDER_STATUS = {
  NEW: "new",
  CREATED: "coreOrderWorkflow/created",
  PROCESSING: "coreOrderWorkflow/processing",
  COMPLETED: "coreOrderWorkflow/completed",
  CANCELED: "coreOrderWorkflow/canceled",
  PICKED: "coreOrderWorkflow/picked",
  PACKED: "coreOrderWorkflow/packed",
  LABELED: "coreOrderWorkflow/labeled",
  SHIPPED: "coreOrderWorkflow/shipped",
};

const useFinancialsModifiers = () => {
  const { t } = useTranslation();

  const getChangeOrRenewal = useCallback((val) => {
    if (val === 'change') {
      return t('finance.change');
    }
    if (val === 'renewal') {
      return t('finance.renewal');
    }
    return '';
  }, [t]);

  const getTransactionType = useCallback((val) => {
    if (val === 'subscription') {
      return t('finance.subscription');
    }
    if (val === 'credit') {
      return t('finance.credit');
    }
    return '';
  }, [t]);

  const modifyStatProps = useCallback((data) => {
    if (!data) {
      return [];
    }

    return [
      {
        id: '0',
        totals: t('finance.total'),
        description: t('finance.total_info'),
        amount: formatTotal(data.transaction),
      },
      {
        id: '1',
        totals: t('finance.shipping'),
        description: t('finance.shipping_info'),
        amount: formatTotal(data.shipping),
      },
      {
        id: '2',
        totals: t('finance.items'),
        description: t('finance.items_info'),
        amount: formatTotal(data.total),
      },
      {
        id: '3',
        totals: t('finance.merchant_cut'),
        description: t('finance.merchant_cut_info'),
        amount: formatTotal(data.merchantCut),
      },
      {
        id: '4',
        totals: t('finance.wondersouq_cut'),
        description: t('finance.wondersouq_cut_info'),
        amount: formatTotal(data.godmodeCut),
      },
    ];
  }, [t]);

  const modifyData = useCallback((data, method, options, viewerType) => {
    return data.map(node => {
      const isExport = method === 'export';
      const total = node.summary.total.amount;
      const fulfillment = node.summary?.fulfillmentTotal?.amount;
      const amount = total - fulfillment; // godmode doesn't take cut on delivery charge

      const commissionPercentage = node.commissionPercentage;
      const isOrderComplete = node.status === ORDER_STATUS.COMPLETED;
      const currency =
        node.summary.itemTotal.currency.code === "USD"
          ? "$"
          : `${node.summary.itemTotal.currency.code} `;
      const paymentMethod = node?.payments[0].method?.name === "cash" ? "Offline" : "Online";
      const paymentMethodText = node?.payments[0].method?.name === "cash" ? t('finance.offline') : t('finance.online');

      let accounting = {
        godmodeCut: 0,
        merchantCut: 0,
        pay: 0,
        collect: 0,
      };

      if (isOrderComplete)
        accounting = calculateAccountingValues(
          amount,
          commissionPercentage,
          paymentMethod,
          viewerType
        );

      const status = getKeyByValue(ORDER_STATUS, node?.status);
      const allStatuses = t('statuses', { returnObjects: true });
      const statusObj = getStatus(allStatuses, status);

      const result = {
        id: node.referenceId,
        status: isExport ? statusObj.label : <Status
          content={statusObj.label}
          type={statusObj.value}
        />,
        date: moment(node.createdAt).format("MM.DD.YYYY"),
        shopperId: node?.shopper?._id,
        shopperName: node?.fulfillmentGroups[0]?.data?.shippingAddress?.fullName,
        merchantId: node.shop.company._id,
        merchantName: node.shop.company.name,
        shopId: node.shop._id,
        shopName: node.shop.name,
        shopCategory: node.shop.getShopCategory?.name,
        payment: paymentMethodText,
        total: `${currency}${numberWithCommas(total)}`,
        items: `${currency}${numberWithCommas(amount)}`,
        shipping: `${currency}${numberWithCommas(fulfillment)}`,
        commission: node.commissionPercentage,
        oBuyCut: `${currency}${numberWithCommas(accounting.godmodeCut)}`,
        merchantCut: `${currency}${numberWithCommas(accounting.merchantCut)}`,
        pay: `${currency}${numberWithCommas(accounting.pay)}`,
        collect: `${currency}${numberWithCommas(accounting.collect)}`,
      };

      // if table
      if (!isExport && options) {
        result.options = options;
        const valueToDisable = status === 'canceled' ? constants.OPTIONS.cancelOrder : '';
        result.modifyOptions = (list) => {
          return list.map(option => {
            if (option.value === valueToDisable) {
              return {
                ...option,
                disabled: true,
              }
            }
            return option;
          });
        }
        return result;
      }

      // if 'export' button
      result.__typename = node.__typename;
      return result;
    });
  }, [t]);

  const modifySubData = useCallback((data, method, options) => {
    return data.map(node => {
      const isExport = method === 'export';
      const allStatuses = t('statuses', { returnObjects: true });
      const statusObj = getStatus(allStatuses, node.paymentStatus);
      const result = {
        id: node._id,
        merchant: node.company.name,
        changeOrRenewal: getChangeOrRenewal(node.type),
        description: node.description,
        status: isExport ? statusObj.label : <Status
          content={statusObj.label}
          type={statusObj.value}
        />,
        amount: node.paymentAmount,
        createdAt: moment(node.date).format("DD.MM.YYYY"),
      }

      // if table
      if (!isExport && options) {
        result.options = options;
        return result;
      }

      // if 'export' button
      result.__typename = node.__typename;
      return result;
    });
  }, [t, getChangeOrRenewal]);

  const modifyTransData = useCallback((data, method, options) => {
    return data.map(node => {
      const paymentMethodText = node.paymentMethod === "offline" ? t('finance.offline') : t('finance.online');
      const result = {
        id: node._id,
        from: node.recipient === "merchant" ? constants.APP_NAME : node.companyName,
        to: node.recipient === "merchant" ? node.companyName : constants.APP_NAME,
        amount: node.amount,
        payment: paymentMethodText,
        type: getTransactionType(node.type),
        recordId: node.subscriptionId,
        notes: node.notes,
        createdAt: moment(node.date).format("DD.MM.YYYY"),
      }

      // if table
      if (method !== 'export' && options) {
        result.options = options;
        return result;
      }

      // if 'export' button
      result.__typename = node.__typename;
      return result;
    });
  }, [t, getTransactionType]);

  return {
    modifyStatProps,
    modifyData,
    modifySubData,
    modifyTransData,
  }
};

export default useFinancialsModifiers;
