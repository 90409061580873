import React, { useState } from "react";
import CardSubscriptionsList from "../../../configurator/components/tooltip/CardSubscriptionsList";
import CardUpgrade from "../../../configurator/components/tooltip/CardUpgrade";
import { ClickAwayListener } from "@material-ui/core";

const UpgradeSubscriptionTooltip = ({ shopId, onUpgrade, onClickAway }) => {
  const [isList, setIsList] = useState(false);

  const chooseSubHandler = () => {
    setIsList(true);
  };

  const closeSubHandler = () => {
    setIsList(false);
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div>
        {
          isList
            ? <CardSubscriptionsList
              onClose={closeSubHandler}
              shopId={shopId}
            />
            : <CardUpgrade
              onUpgrade={onUpgrade}
              onChooseSub={chooseSubHandler}
            />
        }
      </div>
    </ClickAwayListener>
  );
};

export default UpgradeSubscriptionTooltip;