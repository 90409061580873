import gql from "graphql-tag";

export default gql`
  mutation bulkProductImport($companyId: ID!, $shopId: ID!, $products: [BulkProductUnitInput]!) {
    bulkProductImport(companyId: $companyId, shopId: $shopId, products: $products) {
      totalMutatedCount
      totalInputCount
    }
  }
`;
