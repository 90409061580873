import React, { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UIContext from "../context/UIContext";
import Badge from "@material-ui/core/Badge";
import {
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import styled from 'styled-components';
import { LanguageContext } from "../context/LanguageContext";

const StyledBadge = styled(Badge)`
  width: 100%;
  
  .MuiBadge-badge {
    background-color: #F06969;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #FFFFFF;
    top: 50%;
    right: 23px;
  }
`;

const SimpleListItem = ({
  path,
  href,
  badgeContent,
  IconComponent,
  navigationItemLabel,
  isNested = false,
  classes
}) => {
  const history = useHistory();
  const routeMatch = useRouteMatch("/:any");
  const { t } = useTranslation();
  const isSelected = routeMatch && ((href && href.startsWith(routeMatch.url)) || path.startsWith(routeMatch.url));
  const { onCloseNavigationDrawer } = useContext(UIContext);
  const { isRtl } = useContext(LanguageContext);

  return (
    <ListItem
      button
      className={isNested ? classes.nestedListItem : undefined}
      classes={{
        root: isRtl ? classes.listItemRootRtl : classes.listItemRoot,
        selected: classes.selected,
        button: classes.listItemButton,
      }}
      key={path}
      selected={isSelected}
      onClick={() => {
        history.push(href || path);
        onCloseNavigationDrawer();
      }}
    >
      <StyledBadge
        badgeContent={badgeContent}
      >
        {IconComponent && (
          <ListItemIcon className={isRtl ? classes.iconRtl : classes.icon}>
            <IconComponent />
          </ListItemIcon>
        )}
        <ListItemText disableTypography className={isRtl ? classes.listItemTextRtl : classes.listItemText}>
          {t(navigationItemLabel)}
        </ListItemText>
      </StyledBadge>
      {
        isSelected && <div className={isRtl ? classes.brickRtl : classes.brick}/>
      }
    </ListItem>
  )
};

export default SimpleListItem;
