import {IconNavShops} from '../../icons';
import ShopsPage from "./pages/ShopsPage";
import ShopEditPage from "./pages/ShopEditPage";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 7,
    path: "/shops",
    MainComponent: ShopsPage,
    IconComponent: IconNavShops,
    navigationItemLabel: 'menu.shop_list',
    showShopSelector: false,
    collapse: {
      title: 'menu.shops',
      IconComponent: IconNavShops, // icon to use for the collapse itself
    },
  });

  registerRoute({
    path: "/shops/create",
    MainComponent: ShopEditPage,
    showShopSelector: false,
  });

  registerRoute({
    path: "/shops/edit/:shopId",
    MainComponent: ShopEditPage,
    showShopSelector: false,
  });
}
