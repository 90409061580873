import { INSIDE_SHOP_PARTS, MAIN_SHOP_PARTS } from "../components/Shop";

export default function checkConfigForFullness(data) {
  for (const mainPartKey of Object.keys(MAIN_SHOP_PARTS)) {
    if (!data[mainPartKey]) {
      return false;
    }
  }
  let firstFloorLayout = data["firstFloorInfo"]["layoutInfo"];
  for (const internalKey of Object.keys(INSIDE_SHOP_PARTS)) {
    if (!firstFloorLayout[internalKey]) {
      return false;
    }
  }
  return true;
};
