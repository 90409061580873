import cardVisa from "../../../images/cards/visa.png";
import cardMC from "../../../images/cards/masterCard.png";

const getCardImg = (str) => {
  switch (str) {
    case 'visa': return cardVisa;
    case 'mastercard': return cardMC;
    default: return false;
  }
}

export default getCardImg;
