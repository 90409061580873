import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { InviteSchema } from "../schemas";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import { Form } from '../../../UI/Form/MyForm';
import SaveButtons from "../../../UI/Button/SaveButtons";
import Button from "../../../UI/Button/Button";
import { addShopper } from "../graphql/mutations";
import FormInputPhoneNumber from "../../../UI/Form/FormInputPhoneNumber";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const FormShopper = ({declineAction}) => {
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState(null);

  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    resolver: yupResolver(InviteSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
  });

  const onSubmit = useCallback((formData) => {
    setLoading(true);

    apolloClient.mutate({
      mutation: addShopper,
      variables: {
        input: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneNumber: formData.phoneNumber,
          emails: [
            {
              address: formData.email,
              provides: "email",
              verified: false,
            },
          ],
        },
      }
    })
      .then(() => {
        setLoading(false);
        enqueueSnackbar(t('shoppers.invite_success'), { variant: "success" });
        declineAction();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
        setLoading(false);
      })
  }, [])

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeader>{t('shoppers.invite_shopper')}</FormHeader>

      <InputLabel error={errors.firstName} disabled={loading}>{t('shoppers.first_name')}</InputLabel>
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('shoppers.write_first_name')}
          value={field.value}
          onChange={field.onChange}
          error={errors.firstName}
          disabled={loading}
        />}
      />

      <InputLabel error={errors.firstName} disabled={loading}>{t('shoppers.last_name')}</InputLabel>
      <Controller
        name="lastName"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('shoppers.write_last_name')}
          value={field.value}
          onChange={field.onChange}
          error={errors.lastName}
          disabled={loading}
        />}
      />

      <InputLabel error={errors.email} disabled={loading}>{t('shoppers.email')}</InputLabel>
      <Controller
        name="email"
        control={control}
        render={({ field }) => <FormInput
          placeholder={t('shoppers.write_email')}
          value={field.value}
          onChange={field.onChange}
          error={errors.email}
          disabled={loading}
        />}
      />

      <InputLabel error={errors.phoneNumber} disabled={loading}>{t('shoppers.phone_number')}</InputLabel>
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => <FormInputPhoneNumber
          placeholder={t('shoppers.write_phone_number')}
          value={field.value}
          onChange={field.onChange}
          error={errors.phoneNumber}
          disabled={loading}
          phoneError={phoneError}
          setPhoneError={setPhoneError}
        />}
      />

      <SaveButtons>
        <Button
          disabled={!isDirty || Boolean(phoneError)}
          type='submit'
          width='180px'
        >
          {t('shoppers.invite')}
        </Button>
        <Button
          mytype='third'
          width='180px'
          handleClick={declineAction}
          disabled={loading}
        >
          {t('ui.cancel')}
        </Button>
      </SaveButtons>
    </Form>
  );
}

export default FormShopper;
