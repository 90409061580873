import React, { useCallback, useEffect, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid } from "@material-ui/core";
import Search from "../../../UI/Search";
import StyledButton from "../../../UI/Button";
import DataTable from "../../../UI/DataTable/DataTable";
import Dialog from "../../../UI/Dialog/Dialog";
import { useSnackbar } from "notistack";
import { useApolloClient } from "@apollo/react-hooks";
import svgQuestion from '../../../icons/help.svg';
import { jsonFormat } from "../modifiers";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import { activityHistoryRecordsQuery } from "../graphql/queries";
import { modifyData } from "../modifiers";
import MerchantSelectorWithData from "../../../package/src/MerchantSelectorWithData";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import FormHeader from "../../../UI/Form/FormHeader";
import { useTranslation } from "react-i18next";
import { useActivityHistoryColumns } from "../hooks/useActivityHistoryColumns";
import { constants } from "../../../constants";

const ActivityHistoryPage = ({ viewer }) => {
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const columns = useActivityHistoryColumns();

  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [requestData, setRequestData] = useState('');
  const [tableData, setTableData] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [selectedMerchants, setSelectedMerchants] = useState({
    selectedCompanyIds: viewer.type !== "godmode" ? [viewer.companyId] : [],
    selectedShopIds:
      viewer.type !== "godmode"
        ? [
          ...viewer.adminUIShops
            .filter(shop => shop.company._id === viewer.companyId)
            .map(shop => shop._id),
        ]
        : [],
  });

  useEffect(() => {
    refetch();
  }, [selectedMerchants])

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
    setIsLoading(true);

    apolloClient.query({
      query: activityHistoryRecordsQuery,
      variables: {
        companyIds: selectedMerchants.selectedCompanyIds,
        filter: globalFilter,
        first: pageSize,
        //limit: (pageIndex + 1) * pageSize,
        offset: pageIndex * pageSize,
        sortOrder,
        sortBy,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        // Update the state with the fetched data as an array of objects and the calculated page count
        const total = res.data.activityHistoryRecords.totalCount;
        setTableData(res.data.activityHistoryRecords.nodes);
        setPageCount(Math.ceil(total / pageSize));
        setTotalCount(total);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [selectedMerchants, t]);

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const rowOptions = useMemo(() => [
    {
      label: t('history.request_variables'),
      value: constants.OPTIONS.requestVariables,
      icon: svgQuestion,
      handleOptionClick: (rowId) => {
        const data = tableData.find(item => item._id === rowId);
        setRequestData(jsonFormat(data.variables));
        setDialogOpen(true);
      },
    },
    {
      label: t('history.request_query'),
      value: constants.OPTIONS.requestQueries,
      icon: svgQuestion,
      handleOptionClick: (rowId) => {
        const data = tableData.find(item => item._id === rowId);
        setRequestData(data.query);
        setDialogOpen(true);
      },
    },
  ], [tableData, t])

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: activityHistoryRecordsQuery,
      variables: {
        companyIds: selectedMerchants.selectedCompanyIds,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyData(res.data.activityHistoryRecords.nodes, 'export');
        const fields = columns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [selectedMerchants, t, modifyData]);

  const dataProps = useMemo(() => {
    return modifyData(tableData, '', rowOptions);
  }, [tableData, modifyData])

  return (
    <>
      <Grid container>
        <Toolbar title={t('history.user_activity_history')} />

        <Grid container spacing={2}>

          <Grid item xs={8}>
            <MerchantSelectorWithData
              shouldShowShopName
              size={32}
              viewer={viewer}
              selectedCompanyIds={selectedMerchants.selectedCompanyIds}
              onMerchantSelectionChange={setSelectedMerchants}
            />
          </Grid>

          <Grid item xs={4}>
            <StyledButton
              width='140px'
              mytype='secondary'
              handleClick={exportData}
              disabled={exporting || !tableData.length}
            >
              {t('ui.export')}
            </StyledButton>
          </Grid>
        </Grid>

        <Search
          handleChange={handleSearch}
          onSearchClick={refetch}
          placeholder={t('history.filter_activity_by_name')}
          useDebounce
        />

        <DataTable
          columns={columns}
          isLoading={isLoading}
          data={dataProps}
          handleFetchData={onFetchData}
          pageCount={pageCount}
          totalCount={totalCount}
          searchValue={searchValue}
          reloadTable={reloadTable}
          defaultSortField='createdAt'
          defaultSortOrder='desc'
          showCheckboxes={false}

          // styles for 2 rows upper panel, no wrapping
          maxHeight='calc(100vh - 350px)'
          maxHeightLaptop='calc(100vh - 350px)'
          maxHeightMobile='calc(100vh - 350px)'
          marginTopMobile='16px'
        />

      </Grid>

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        <FormHeader>{t('history.request_variables')}</FormHeader>
        <pre>
          {
            requestData
          }
        </pre>
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            mytype='secondary'
            handleClick={() => setDialogOpen(false)}
          >
            {t('ui.close')}
          </StyledButton>
        </DialogButtons>
      </Dialog>
    </>

  );
}

export default ActivityHistoryPage;
