export const getFilters = (dateRange) => {
  const gte = dateRange?.startDate?.toISOString() || "";
  const lte = dateRange?.endDate?.toISOString() || "";

  const createdAt = {};

  if (gte !== "") createdAt.gte = gte;
  if (lte !== "") createdAt.lte = lte;

  return Object.keys(createdAt)?.length > 0 ? { createdAt } : {};
}