import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useMerchantsColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: 'id',
      label: 'ID',
      sortable: '_id',
    },
    {
      name: 'fullName',
      label: t('merchant.merchant_name'),
      sortable: 'name',
    },
    {
      name: 'email',
      label: t('merchant.email'),
    },
    {
      name: 'phoneNumber',
      label: t('merchant.phone_number'),
    },
    {
      name: 'shopName',
      label: t('merchant.shop_name'),
      sortable: false,
    },
    {
      name: 'category',
      label: t('merchant.category'),
      sortable: false,
    },
    {
      name: 'description',
      label: t('merchant.description'),
    },
    {
      name: 'createdAt',
      label: t('ui.created_on'),
    },
    {
      name: 'options'
    }
  ], [t]);
};
