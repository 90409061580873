import { IconNavAddPerson, IconNavStaff } from "../../../icons";
import Accounts from "./components/Accounts";
import Profile from "./components/Profile";
import { constants } from "../../../constants.js";
import Invitations from "./components/Invitations";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  registerRoute({
    group: "navigation",
    path: "/accounts",
    priority: 65,
    MainComponent: Accounts,
    IconComponent: IconNavStaff,
    navigationItemLabel: 'menu.staff_members',
    showSingleShopSelector: true,
    collapse: {
      title: 'menu.staff',
      IconComponent: IconNavStaff,
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
    ],
  });

  registerRoute({
    group: "navigation",
    path: "/accounts-invitations",
    priority: 65,
    MainComponent: Invitations,
    IconComponent: IconNavAddPerson,
    navigationItemLabel: 'menu.invitations',
    showSingleShopSelector: true,
    collapse: {
      title: 'menu.staff',
      IconComponent: IconNavStaff,
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
    ],
  });

  registerRoute({
    path: "/profile",
    MainComponent: Profile,
  });
}
