import moment from 'moment';

/*
{startDate: 'ISO string', endDate: 'ISO string'}
 */

function convertISOtoData(str) {
  if (str && typeof str === 'string') {
    return moment(str).toDate();
  }

  return str;
}

export default function formatDateRange(obj) {
  return {
    startDate: convertISOtoData(obj.startDate),
    endDate: convertISOtoData(obj.endDate),
  }
}
