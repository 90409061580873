/**
 * @summary Queries for tags the match the provided user query
 * @param {Object} apolloClient The apolloClient
 * @param {String} query Query provided by the user
 * @returns {Array} An array of options formatted for use with react-select
 */
import { GET_TAGS } from "../../../../package/src/TagSelectorWithData/graphql/queries";

export async function getTags(apolloClient, query, shopId) {
  const variables = {
    shopId,
  };

  if (query) variables.filter = query;

  const { data, error } = await apolloClient.query({
    query: GET_TAGS,
    variables,
    fetchPolicy: "network-only",
  });

  let options = [];
  if (!error && data) {
    options = data.tags.edges.map(({ node }) => ({
      label: node.name,
      value: node._id,
    }));
  }

  return options;
}
