import {
  IconNavDiscounts,
  IconNavCoupons,
  IconNavCouponSale,
  IconNavCouponTriggers,
  IconNavGlobalDiscount,
} from "../../icons";
import DiscountCodesPage from "./pages/DiscountCodesPage.js";
import EditCodePage from "./pages/EditCodePage.js";
import CouponTriggersPage from "./pages/CouponTriggersPage.js";
import SalePage from "./pages/SalePage.js";
import { constants } from "../../constants.js";
import GlobalDiscountsPage from "./pages/GlobalDiscountsPage";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Coupon Codes
  registerRoute({
    group: "navigation",
    path: "/discounts",
    priority: 67,
    MainComponent: DiscountCodesPage,
    IconComponent: IconNavCoupons,
    navigationItemLabel: 'menu.coupons',
    showSingleShopSelector: true,
    collapse: {
      title: 'menu.discounts',
      IconComponent: IconNavDiscounts, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  registerRoute({
    path: "/discounts/create/:shopId",
    MainComponent: EditCodePage,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  registerRoute({
    path: "/discounts/edit/:shopId/:discountCodeId",
    MainComponent: EditCodePage,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  // Coupon Triggers
  registerRoute({
    group: "navigation",
    path: "/coupon-triggers",
    priority: 68,
    MainComponent: CouponTriggersPage,
    IconComponent: IconNavCouponTriggers,
    navigationItemLabel: 'menu.coupon_triggers',
    showSingleShopSelector: true,
    collapse: {
      title: 'menu.discounts',
      IconComponent: IconNavDiscounts, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  // Sale
  registerRoute({
    group: "navigation",
    path: "/sale",
    priority: 69,
    MainComponent: SalePage,
    IconComponent: IconNavCouponSale,
    navigationItemLabel: 'menu.sale',
    showSingleShopSelector: true,
    collapse: {
      title: 'menu.discounts',
      IconComponent: IconNavDiscounts, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  // Global discount
  registerRoute({
    group: "navigation",
    path: "/global-discount",
    priority: 70,
    MainComponent: GlobalDiscountsPage,
    IconComponent: IconNavGlobalDiscount,
    navigationItemLabel: 'menu.global_discount',
    showShopSelector: true,
    collapse: {
      title: 'menu.discounts',
      IconComponent: IconNavDiscounts, // icon to use for the collapse itself
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });
}
