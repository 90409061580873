import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { colors } from "../../../constants";

export const StreetMapHeading = styled('div')`
  padding: 32px 32px 0;
  margin-bottom: 13px;
`;

export const StreetMapHorizontalRule = styled('div')`
  width: 100%;
  border-bottom: 1px solid #E9E9E9;
  margin: 15px 0 0;
`;

export const StreetMapScrollArea = styled('div')`
  width: 100%;
  max-height: 50vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E9E9E9;
    border-radius: 8px;
  }
`;

export const StreetMapBlueText = styled(Typography)`
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: normal;
  color: ${colors.blue};
`;

export const StreetMapZoneBox = styled('div')`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;
`;

export const StreetMapRowBox = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

export const StreetMapGreyText = styled(Typography)`
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.textGrey};
  line-height: normal;
  user-select: none;
`;

export const StreetMapBlackText = styled(Typography)`
  line-height: normal;
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: ${(props) => props.disabled ? colors.textGrey : colors.black};
`;

export const SelectAllButton = styled('button')`
  cursor: pointer;
  border: none;
  background: transparent;
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.isAllHousesSelected ? colors.textGrey : colors.blue};
  padding-right: 0;
  margin-bottom: -20px;
`;
