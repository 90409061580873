import React, { useCallback, useEffect, useState } from "react";
import FormHeader from "../../../UI/Form/FormHeader";
import { Form } from '../../../UI/Form/MyForm';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { DiscountSchema } from '../schemas';
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import FormCard from "../../../UI/Form/FormCard";
import PrimaryAppBar from "../../../package/src/PrimaryAppBar/PrimaryAppBar";
import TwoColumnsPage from "../../../package/src/TwoColumnsPage";
import { Grid } from "@material-ui/core";
import FormNote from "../../../UI/Form/FormNote";
import DateRangeSelector from "../../../package/src/DateRangeSelector";
import Checkbox from "../../../UI/Checkbox";
import CheckboxContainer from "../../../UI/Checkbox/CheckboxContainer";
import Select from "../../../UI/Select";
import SaveButtons from "../../../UI/Button/SaveButtons";
import Button from "../../../UI/Button";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import createDiscountCodes from "../graphql/mutations/createDiscountCodes";
import discountCodeQuery from '../graphql/queries/discountCode';
import updateDiscountCode from '../graphql/mutations/updateDiscountCode';
import ProductSelectorWithData from "../../../package/src/ProductSelectorWithData";
import ShopperSelectorWithData from "../../../package/src/ShopperSelectorWithData";
import formatDateRange from "../../../package/src/utils/formatDateRange";
import useDiscounts from "../hooks/useDiscounts";
import { useTranslation } from "react-i18next";

export default function EditCodePage(props) {
  const shopId = props?.match?.params?.shopId;
  const discountId = props?.match?.params?.discountCodeId;
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { appliesOptions, eligibilityOptions, typeOptions} = useDiscounts();

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, formState: { errors, isDirty }, reset, watch } = useForm({
    resolver: yupResolver(DiscountSchema),
    defaultValues: {
      isRandom: false,
      code: '',
      numberToGenerate: 1,
      period: {startDate: null, endDate: null},
      enableLimit: false,
      enableTotalLimit: false,
      limit: 1,
      totalLimit: 100,
      type: '',
      amount: 0,
      appliesTo: 'all',
      eligibility: 'all',
      products: [],
      customers: [],
    },
  });

  const watchType = watch('type');
  const watchEnableLimit = watch('enableLimit');
  const watchEnableTotalLimit = watch('enableTotalLimit');
  const watchIsRandom = watch('isRandom');
  const watchAppliesTo = watch('appliesTo');
  const watchEligibility = watch('eligibility');

  useEffect(() => {
    if (discountId) {
      setLoading(true);
      apolloClient.query({
        query: discountCodeQuery,
        variables: {
          id: discountId,
        },
        fetchPolicy: "network-only",
      })
        .then(res => {
          const data = res?.data?.discountCode;
          if (!data) {
            return;
          }
          const products = data.conditions?.productDetails?.map(product => product._id) || [];
          const customers = data.conditions?.shopperDetails?.map(shopper => shopper._id) || [];
          const limit = data.conditions?.accountLimit;
          const totalLimit = data.conditions?.redemptionLimit;

          let type = '';
          if (data.calculation?.method === 'discount') {
            type = 'percentage';
          } else if (data.calculation?.method === 'credit') {
            type = 'flat';
          }

          let eligibility = '';
          if (data.conditions?.shopperDetails?.length > 0) {
            eligibility = 'specific';
          } else if (data.conditions?.shopperDetails?.length === 0) {
            eligibility = 'all';
          }

          let appliesTo = '';
          if (data.conditions?.productDetails?.length > 0) {
            appliesTo = 'specific';
          } else if (data.conditions?.productDetails?.length === 0) {
            appliesTo = 'all';
          }

          const resetValues = {
            code: data.code,
            period: {
              startDate: data.conditions?.order?.startDate || null,
              endDate: data.conditions?.order?.endDate || null,
            },
            enableLimit: Boolean(limit),
            enableTotalLimit: Boolean(totalLimit),
            limit: limit ? limit : 1,
            totalLimit: totalLimit ? totalLimit : 100,
            type,
            amount: data.discount || 0,
            appliesTo,
            eligibility,
            products,
            customers,
          }

          reset(resetValues);
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setLoading(false))
    }
  }, [t])

  const handleNumberChange = (e, func) => {
    let value = e.target.value;
    if (value < 0) value = 0;
    func(value);
  }

  const handleQuantityChange = (e, func) => {
    let value = e.target.value;
    if (value < 1) value = 1;
    func(value);
  }

  const declineAction = () => {
    props.history.push('/discounts');
  }

  const mutateCoupon = useCallback((isRandom, discountCode, numberToGenerate) => {
    if (discountId) {
      return apolloClient.mutate({
        mutation: updateDiscountCode,
        variables: {
          input: {
            shopId,
            discountCode,
            discountCodeId: discountId,
          },
        },
      })
    }
    return apolloClient.mutate({
      mutation: createDiscountCodes,
      variables: {
        input: {
          shopId,
          numberToGenerate,
          isRandom,
          discountCode,
        },
      },
    })
  }, [discountId, shopId])

  const onSubmit = useCallback((formData) => {
    setLoading(true);
    const {
      isRandom,
      code,
      period: {startDate, endDate},
      enableLimit,
      enableTotalLimit,
      limit,
      totalLimit,
      type,
      amount,
      appliesTo,
      eligibility,
      products,
      customers,
      //numberToGenerate
    } = formData;

    const isConditions = enableLimit || enableTotalLimit || appliesTo !== 'all' || eligibility !== 'all' || startDate || endDate;

    if (isConditions) {
      formData.conditions = {};

      if (!enableTotalLimit) {
        formData.conditions.redemptionLimit = 0;
      } else {
        formData.conditions.redemptionLimit = Number(totalLimit);
      }

      formData.conditions.accountLimit = Number(limit);
      formData.conditions.enabled = true;
      formData.conditions.audience = customers.length ? customers : null;
      formData.conditions.products = products.length ? products : null;
    }

    if (startDate || endDate) {
      formData.conditions.order = {};
      formData.conditions.order.min = 0;

      if (startDate) {
        formData.conditions.order.startDate = startDate;
      }

      if (endDate) {
        formData.conditions.order.endDate = endDate;
      }
    }

    const discountCode = {
      code,
      discount: Number(amount),
      discountMethod: 'code',
      calculation: {
        method: type === 'flat' ? 'credit' : 'discount',
      }
    }

    if (isConditions) {
      discountCode.conditions = formData.conditions;
    }

    const numberToGenerate = Number(formData.numberToGenerate);

    mutateCoupon(isRandom, discountCode, numberToGenerate)
      .then(() => {
        enqueueSnackbar(discountId ? t('discount.edit_code_success') : t('discount.create_code_success'), {variant: 'success'});
        setLoading(false);
        declineAction();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
        setLoading(false);
      })
  }, [mutateCoupon, t])

  return (
    <>
      <PrimaryAppBar
        title={discountId ? t('discount.edit_coupon') : t('discount.create_coupon')}
        onBackButtonClick={declineAction}
      />
      <TwoColumnsPage>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          width='100%'
        >
          <Grid container spacing={2}>

            <Grid item xs={12} lg={7}>
              <FormCard>
                <FormHeader>{t('discount.discount_code')}</FormHeader>
                <InputLabel error={errors.code} disabled={loading}>{t('discount.coupon_code')}</InputLabel>
                <CheckboxContainer>
                  <Controller
                    name="isRandom"
                    control={control}
                    render={({ field }) => <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                      disabled={loading}
                    />}
                  />
                  {t('discount.random_generation')}
                </CheckboxContainer>
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => <FormInput
                    placeholder={t('discount.write_coupon_code_here')}
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.code}
                    disabled={watchIsRandom || loading}
                  />}
                />
                {/*<InputLabel error={errors.numberToGenerate}>Number of Coupons to Generate</InputLabel>*/}
                {/*<Controller*/}
                {/*  name="numberToGenerate"*/}
                {/*  control={control}*/}
                {/*  render={({ field }) => <FormInput*/}
                {/*    value={field.value}*/}
                {/*    onChange={(e) => handleNumberChange(e, field.onChange)}*/}
                {/*    error={errors.numberToGenerate}*/}
                {/*    type='number'*/}
                {/*  />}*/}
                {/*/>*/}
                <FormNote style={{ margin: 0}}>{t('discount.customers_will_enter')}</FormNote>
              </FormCard>

              {
                !discountId ?
                  <FormCard>
                    <FormHeader>{t('discount.amount_of_coupons')}</FormHeader>
                    <InputLabel disabled={loading}>{t('discount.number_of_coupons')}</InputLabel>
                    <Controller
                      name="numberToGenerate"
                      control={control}
                      render={({ field }) => <FormInput
                        value={field.value}
                        disabled={loading}
                        onChange={(e) => handleQuantityChange(e, field.onChange)}
                        type='number'
                      />}
                    />
                  </FormCard> : null
              }

              <FormCard>
                <FormHeader>{t('discount.calculation_method')}</FormHeader>
                <InputLabel error={errors.type} disabled={loading}>{t('discount.type')}</InputLabel>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => <Select
                    placeholder={t('discount.choose_coupon_type')}
                    value={field.value}
                    onChange={field.onChange}
                    options={typeOptions}
                    error={errors.type}
                    disabled={loading}
                  />}
                />
              </FormCard>

              <FormCard>
                <FormHeader>{t('discount.discount_amount')}</FormHeader>
                <InputLabel
                  error={errors.amount}
                  optionalText={watchType ? (watchType === 'flat' ? '$' : '%') : undefined}
                  disabled={!watchType || loading}
                >
                  {t('discount.value')}
                </InputLabel>
                <Controller
                  control={control}
                  name='amount'
                  render={({ field }) => <FormInput
                    value={field.value}
                    onChange={(e) => handleNumberChange(e, field.onChange)}
                    type='number'
                    error={errors.amount}
                    disabled={!watchType || loading}
                  />}
                />
              </FormCard>

              <FormCard>
                <FormHeader>{t('discount.applies_to')}</FormHeader>
                <InputLabel disabled={loading}>{t('discount.applies_to')}</InputLabel>
                <Controller
                  name="appliesTo"
                  control={control}
                  render={({ field }) => <Select
                    placeholder={t('discount.choose_applies_to')}
                    value={field.value}
                    onChange={field.onChange}
                    options={appliesOptions}
                    disabled={loading}
                  />}
                />
                <InputLabel disabled={watchAppliesTo === 'all' || loading}>{t('discount.products')}</InputLabel>
                <Controller
                  name='products'
                  control={control}
                  render={({ field }) => <ProductSelectorWithData
                    shouldShowShopName
                    shopIds={[shopId]}
                    placeholder={t('discount.choose_products')}
                    selectAllLabel={t('discount.all_products')}
                    value={field.value}
                    onChange={field.onChange}
                    isMultiSelect
                    disabled={watchAppliesTo === 'all' || loading}
                  />}
                />
              </FormCard>

              <FormCard>
                <FormHeader>{t('discount.customer_eligibility')}</FormHeader>
                <InputLabel disabled={loading}>{t('discount.customer_eligibility')}</InputLabel>
                <Controller
                  name="eligibility"
                  control={control}
                  render={({ field }) => <Select
                    placeholder={t('discount.choose_customer_eligibility')}
                    value={field.value}
                    onChange={field.onChange}
                    options={eligibilityOptions}
                    disabled={loading}
                  />}
                />
                <InputLabel disabled={watchEligibility === 'all' || loading}>{t('discount.customers')}</InputLabel>
                <Controller
                  name='customers'
                  control={control}
                  render={({ field }) => <ShopperSelectorWithData
                    shouldShowEmail
                    shopIds={[shopId]}
                    placeholder={t('discount.search_customers')}
                    selectAllLabel={t('discount.all_customers')}
                    value={field.value}
                    onChange={field.onChange}
                    isMultiSelect
                    disabled={watchEligibility === 'all' || loading}
                  />}
                />
              </FormCard>
            </Grid>

            <Grid item xs={12} lg={5}>
              <FormCard>
                <FormHeader>{t('discount.validity_period')}</FormHeader>
                <InputLabel optionalText={t('ui.optional')} disabled={loading}>{t('discount.validity_period')}</InputLabel>
                <Controller
                  name="period"
                  control={control}
                  render={({ field }) => <DateRangeSelector
                    placeholder={t('discount.validity_period')}
                    dateRange={formatDateRange(field.value)}
                    onChange={field.onChange}
                    disabled={loading}
                  />}
                />
              </FormCard>

              <FormCard>
                <FormHeader>{t('discount.usage_limits')}</FormHeader>
                <CheckboxContainer>
                  <Controller
                    name="enableTotalLimit"
                    control={control}
                    render={({ field }) => <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                      disabled={loading}
                    />}
                  />
                  {t('discount.use_limits_total_note')}
                </CheckboxContainer>
                {
                  watchEnableTotalLimit &&
                  <Controller
                    control={control}
                    name='totalLimit'
                    render={({ field }) => <FormInput
                      value={field.value}
                      onChange={(e) => handleNumberChange(e, field.onChange)}
                      type='number'
                      disabled={loading}
                    />}
                  />
                }
                <CheckboxContainer>
                  <Controller
                    name="enableLimit"
                    control={control}
                    render={({ field }) => <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                      disabled={loading}
                    />}
                  />
                  {t('discount.use_limits_account_note')}
                </CheckboxContainer>
                {
                  watchEnableLimit &&
                  <Controller
                    control={control}
                    name='limit'
                    render={({ field }) => <FormInput
                      value={field.value}
                      onChange={(e) => handleNumberChange(e, field.onChange)}
                      type='number'
                      disabled={loading}
                    />}
                  />
                }
              </FormCard>
            </Grid>

            <SaveButtons justifyContent='flex-end'>
              <Button
                disabled={!isDirty || loading}
                type='submit'
                width='180px'
              >
                {discountId ? t('ui.save_changes') : t('discount.create_coupon')}
              </Button>
              <Button
                mytype='third'
                width='180px'
                handleClick={declineAction}
                disabled={loading}
              >
                {t('ui.cancel')}
              </Button>
            </SaveButtons>

          </Grid>
        </Form>
      </TwoColumnsPage>
    </>
  );
}
