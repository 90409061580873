import React, { useContext, useState } from "react";
import {
  LanguageArrowImg,
  LanguageBox,
  LanguageButton,
  LanguageCheckImg,
  LanguageMenu,
  LanguageMenuItem,
  LanguageText,
} from "./style";
import arrowImg from '../../../icons/languageSwitcher/arrow-down.svg';
import checkImg from '../../../icons/languageSwitcher/check.svg';
import languageList from "./languageList";
import i18next from "i18next";
import { LanguageContext } from "../context/LanguageContext";

const LanguageSwitch = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { language, onChangeLanguage } = useContext(LanguageContext);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    if (open) {
      handleClose();
    } else {
      setAnchorEl(e.currentTarget);
    }
  }

  const handleChange = (val) => {
    if (val === 'ar') {
      document.body.dir = 'rtl';
    } else {
      document.body.dir = 'ltr';
    }
    i18next.changeLanguage(val);
    onChangeLanguage(val);
    handleClose();
  };

  const current = languageList.find((item) => item.value === language);

  return (
    <>
      <LanguageButton
        disableRipple
        aria-owns={anchorEl ? "language-simple-menu" : null}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <LanguageBox>
          <LanguageArrowImg
            sx={{
              transform: open ? 'rotate(180deg)' : 'rotate(0)',
            }}
          >
            <img
              src={arrowImg}
              alt=""
            />
          </LanguageArrowImg>
          <LanguageText>
            { current?.label || '' }
          </LanguageText>
        </LanguageBox>
      </LanguageButton>

      <LanguageMenu
        id='language-simple-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        {
          languageList.map((item) => <LanguageMenuItem
            key={item.value}
            onClick={() => handleChange(item.value)}
          >
            <LanguageBox>
              <LanguageCheckImg
                style={{
                  visibility: item.value === language ? "visible" : "hidden",
                }}
              >
                <img
                  src={checkImg}
                  alt=""
                />
              </LanguageCheckImg>
              <LanguageText>
                {item.label}
              </LanguageText>
            </LanguageBox>
          </LanguageMenuItem>)
        }
      </LanguageMenu>
    </>
  );
};

export default LanguageSwitch;
