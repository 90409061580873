import React from "react";
import InputLabel from "../../../../../UI/Form/InputLabel";
import SelectAllAutocomplete from "../../../../../package/src/SelectAllAutocomplete";

const EditAutocomplete = ({ value, onChange, options }) => {
  const handleSetAttributeValues = (val) => {
    const newValue = val.map(item => item.value);
    const isValid = Boolean(newValue.length);
    onChange(newValue, isValid);
  }

  return (
    <>
      <InputLabel>Value</InputLabel>
      <SelectAllAutocomplete
        items={options}
        value={value}
        onChange={handleSetAttributeValues}
        placeholder='Select values'
        selectAllLabel='All'
        isMultiSelect
      />
    </>
  );
};

export default EditAutocomplete;
