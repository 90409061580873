import React from 'react';
import styled from "styled-components";
import SvgEnabled from '../../../../icons/package-enabled.svg';
import SvgDisabled from '../../../../icons/package-disabled.svg';
import { Icon } from "@material-ui/core";
import { colors } from '../../../../constants';

const Wrapper = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  margin-bottom: 12px;
`

const WrappedText = styled.div`
  p {
    margin: 0;
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: ${props => props.disabled ? '#C5C5C5 !important' : colors.black};
    
    span {
      font-weight: 700;
    }
  }
`

const StyledIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PackageFeature = ({ text, disabled}) => {
  return (
    <Wrapper>
      {
        disabled ?
          <StyledIcon><img src={SvgDisabled} alt=''/></StyledIcon> :
          <StyledIcon><img src={SvgEnabled} alt=''/></StyledIcon>
      }
      <WrappedText disabled={disabled}>
        {text}
      </WrappedText>
    </Wrapper>
  );
}

export default PackageFeature;
