import React from 'react';
import {Tabs} from '@material-ui/core';
import styled from 'styled-components';
import {colors} from '../../constants';

const StyledTabs = styled(Tabs)`
  width: 100%;
  
  & .MuiButtonBase-root {
    font-family: Mulish, sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: ${colors.black};
    opacity: 1;
    padding-bottom: 16px;
    min-height: unset;
    
    &.Mui-disabled {
      color: ${colors.textGrey};
    }
  }
  
  .MuiTabs-indicator {
    background-color: ${colors.blue};
    height: 4px;
    border-radius: 8px 8px 0 0;
  }
`;

const TabsLarge = ({children, ...other}) => {
  return (
    <StyledTabs
      {...other}
    >
      {children}
    </StyledTabs>
  );
}

export default TabsLarge;
