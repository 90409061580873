import React, { forwardRef } from "react";
import styled from "styled-components";
import { IconButton, Paper, Typography } from "@material-ui/core";
import svgClose from '../../../icons/close-popover.svg';

const Wrapper = styled(Paper)`
  padding: 16px 0 0;
  border-radius: 20px;
  border-bottom: 1px solid #E5E7EB;
  background: #FFF;
  box-shadow: 0 7px 20px 0 rgba(183, 195, 211, 0.25);

  .MuiPickersCalendarHeader-label {
    color: #000;
    font-family: Fredoka, sans-serif;
    font-size: 18px;
    font-weight: 500;
  }

  .MuiDayCalendar-header .MuiTypography-root {
    color: #9A9A9A;
    font-family: Mulish, sans-serif;
    font-size: 12px;
    font-weight: 400;
  }
  
  .MuiPickersSlideTransition-root {
    .MuiPickersDay-root {
      color: #222;
      font-family: Mulish, sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
    
    .Mui-selected, .Mui-selected:focus {
      color: #FFF;
      background-color: #4270FF;
    }
  }
  
  .MuiYearCalendar-root {
    .Mui-selected, .Mui-selected:focus {
      color: #FFF;
      background-color: #4270FF;
    }
    
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #E9E9E9;
      border-radius: 8px;
    }
  }
`;

const HeaderContainer = styled('div')`
  padding: 0 24px;
`;

const HeaderBox = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E9E9E9;
  padding-bottom: 3px;
`;

const HeadingText = styled(Typography)`
  color: #222;
  font-family: Fredoka, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`;

const CloseButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  
  &:hover {
    background: none;
  }
`;

const PaperComponent = forwardRef(({ children, ownerState, onClose, ...otherProps }, ref) => {
  return (
    <Wrapper ref={ref} {...otherProps}>
      <HeaderContainer>
        <HeaderBox>
          <HeadingText>Date</HeadingText>
          <CloseButton
            disableRipple
            onClick={onClose}
          >
            <img src={svgClose} alt='x' />
          </CloseButton>
        </HeaderBox>
      </HeaderContainer>
      { children }
    </Wrapper>
  );
});

export default PaperComponent;
