import React from 'react';
import styled from "styled-components";

const Wrapper = styled('button')`
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => (props.disabled ? '#9a9a9a' : '#4270ff')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  background: none;
  border: none;
`;

const LinkButton = ({ children, onClick, disabled }) => {
  return (
    <Wrapper
      onClick={onClick}
      disabled={disabled}
      type='button'
    >
      { children }
    </Wrapper>
  )
};

export default LinkButton;
