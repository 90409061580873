import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import svgHide from '../../../../../icons/variantEditor/hide.svg';

const StyledButton = styled(Button)`
  padding: 8px;
  color: #9A9A9A;
  font-family: Fredoka, sans-serif;
  font-size: 16px;
  font-weight: 500;
  min-width: unset;
  
  img {
    margin-right: ${props => props.reverse === 'true' ? '8px' : '0' };
    transform: rotate(${props => props.reverse === 'true' ? '0deg' : '180deg' });
  }
  
  &:hover {
    background: none;
  }
`;

const HideButton = ({ onClick, open }) => {
  return (
    <StyledButton
      disableRipple
      onClick={onClick}
      reverse={String(open)}
    >
      <img src={svgHide} alt='' />
      {
        open && <>Hide</>
      }
    </StyledButton>
  );
};

export default HideButton;
