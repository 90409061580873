import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import {colors} from '../../constants';

const StyledDialogMessages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;

  .title {
    font-family: 'Fredoka', sans-serif;
    font-weight: 500;
    font-size: 22px;
    max-width: ${props => props.titleMaxWidth};
    text-align: ${props => props.titleTextAlign};
  }

  .message {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: ${colors.black};
    max-width: ${props => props.messageMaxWidth};
    text-align: ${props => props.messageTextAlign};
  }
`

const DialogMessages = ({
  title,
  message,
  titleMaxWidth = '660px',
  messageMaxWidth = '660px',
  messageTextAlign = 'center',
  titleTextAlign = 'center',
  ...other
}) => {
  return (
    <StyledDialogMessages
      titleMaxWidth={titleMaxWidth}
      messageMaxWidth={messageMaxWidth}
      messageTextAlign={messageTextAlign}
      titleTextAlign={titleTextAlign}
      {...other}
    >
      {title ? <div className="title">{title}</div> : null}
      {message ? <div className="message">{message}</div> : null}
    </StyledDialogMessages>
  );
}

DialogMessages.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
}

export default DialogMessages;
