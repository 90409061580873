import cleanNumber from "../../../package/src/utils/cleanNumber";

/**
 * Prepare a list of variant fields for transmission to the server (except attributes!)
 * @param variant
 * @returns {{quantity: (number|*), price: {price: (number|*), compareAtPrice: (number|*)}, weight: {unit: string, value: (number|*)}, sku: *, dimensions: {unit: string, width: (number|*), length: (number|*), height: (number|*)}}}
 */

const prepareVariantFields = (variant) => ({
  price: {
    compareAtPrice: cleanNumber(variant.compareAtPrice),
    price: cleanNumber(variant.price),
  },
  quantity: cleanNumber(variant.quantity),
  sku: variant.sku,
  dimensions: {
    unit: variant.dimensionsUnit,
    height: cleanNumber(variant.height),
    width: cleanNumber(variant.width),
    length: cleanNumber(variant.length),
  },
  weight: {
    unit: variant.weightUnit,
    value: cleanNumber(variant.weight),
  },
});

export default prepareVariantFields;
