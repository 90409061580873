import React, { useContext } from "react";
import { Typography } from "@material-ui/core";
import svgAdd from '../../../../../icons/variantEditor/add.svg';
import styled from "styled-components";
import AddButton from "../common/AddButton";
import AddButtonText from "../common/AddButtonText";
import { ProductVariantsContext } from "../../../../../package/src/context/ProductVariantsContext";

const Text = styled(Typography)`
  color: #222;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
`;

const LayoutEmptyVariants = ({ onAdd }) => {
  const { onSetOpenEditor } = useContext(ProductVariantsContext);

  const handleOpenEditor = () => {
    onSetOpenEditor(true);
  };

  return (
    <>
      <Text>Add Variant for the product if it has several different versions.<br />For example, color, size or shape.</Text>
      <AddButton
        disableRipple
        onClick={handleOpenEditor}
      >
        <img src={svgAdd} alt='+' />
        <AddButtonText>Add Variant</AddButtonText>
      </AddButton>
    </>
  );
};

export default LayoutEmptyVariants;