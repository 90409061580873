import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';

const StyledDialogButtons = styled.div`
  width: 100%;
  margin-top: ${props => props.marginTop};
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: ${props => props.justifyButtons};
`

const DialogButtons = ({justifyButtons = 'flex-start', marginTop = '40px', children, ...other}) => {
  return (
    <StyledDialogButtons
      justifyButtons={justifyButtons}
      marginTop={marginTop}
      {...other}
    >
      {children}
    </StyledDialogButtons>
  );
}

DialogButtons.propTypes = {
  justifyButtons: PropTypes.string,
  marginTop: PropTypes.string,
}

export default DialogButtons;