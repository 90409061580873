import React, { useCallback, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid, Hidden, makeStyles } from "@material-ui/core";
import Search from "../../../UI/Search/Search";
import StyledButton from "../../../UI/Button/Button";
import IconButtons from "../../../UI/IconButtons";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { getShopperInvitations } from "../graphql/queries";
import { modifyInvitationsData } from "../modifiers";
import DataTable from "../../../UI/DataTable/DataTable";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import { useMediaColumns } from "../../media-gallery/hooks/useMediaColumns";
import { useShoppersColumns } from "../hooks/useShoppersColumns";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  columnLeft: {
    display: 'flex',
    gap: 20,
  },
  flexEnd: {
    display: 'flex',
    gap: 20,
    justifyContent: 'flex-end',
  },
  flexStart: {
    display: 'flex',
    gap: 20,
  },
}));

const InvitationsPage = () => {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const columns = useMediaColumns();

  const [searchValue, setSearchValue] = useState('');
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  //const [selectedRows, setSelectedRows] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [rowId, setRowId] = useState('');
  const { columnsInvitations } = useShoppersColumns();

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
      setIsLoading(true);

      apolloClient.query({
        query: getShopperInvitations,
        variables: {
          filter: globalFilter,
          first: pageSize,
          //limit: (pageIndex + 1) * pageSize,
          offset: pageIndex * pageSize,
          sortOrder,
          sortBy,
        },
        fetchPolicy: "network-only",
      })
        .then(res => {
          // Update the state with the fetched data as an array of objects and the calculated page count
          const total = res.data.shopperInvitations.totalCount;
          setTableData(res.data.shopperInvitations.nodes);
          setPageCount(Math.ceil(total / pageSize));
          setTotalCount(total);
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setIsLoading(false))
    }, [t]);

  const rowOptions = useMemo(() => [
    // todo
  ], [])

  const dataProps = useMemo(() => {
    return modifyInvitationsData(tableData, '', rowOptions);
  }, [tableData])

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: getShopperInvitations,
      variables: {
        shopId: [],
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyInvitationsData(res.data.shopperInvitations.nodes, 'export');
        const fields = columns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, []);

  // const renderRightColumn = (myClass) => {
  //   return <Grid item xs={12} lg={5} className={classes[myClass]}>
  //     <IconButtons
  //       handleClick={handleDeleteShopper}
  //       disabled={selectedRows.length === 0}
  //     />
  //   </Grid>
  // }

  return (
    <>
      <Toolbar title={t('shoppers.invitations')} />
      <Grid container spacing={2}>

        <Grid item xs={12} className={classes.columnLeft}>
          <Search
            handleChange={handleSearch}
            onSearchClick={refetch}
            placeholder={t('ui.search')}
            useDebounce
          />

          <StyledButton
            width='140px'
            mytype='secondary'
            handleClick={exportData}
            disabled={exporting || !tableData.length}
          >
            {t('ui.export')}
          </StyledButton>
        </Grid>

        {/*<Hidden lgUp>*/}
        {/*  {renderRightColumn('flexStart')}*/}
        {/*</Hidden>*/}

        {/*<Hidden mdDown>*/}
        {/*  {renderRightColumn('flexEnd')}*/}
        {/*</Hidden>*/}

        <DataTable
          columns={columnsInvitations}
          isLoading={isLoading}
          data={dataProps}
          // setSelectedRows={setSelectedRows}
          // selectedRows={selectedRows}
          handleFetchData={onFetchData}
          pageCount={pageCount}
          totalCount={totalCount}
          searchValue={searchValue}
          reloadTable={reloadTable}
          defaultSortField='createdAt'
          defaultSortOrder='desc'
          showCheckboxes={false}

          // styles for 1 row upper panel, no wrapping
          maxHeight='calc(100vh - 280px)'
          maxHeightLaptop='calc(100vh - 280px)'
          maxHeightMobile='calc(100vh - 280px)'
          marginTopMobile='16px'
        />

      </Grid>
    </>
  );
}

export default InvitationsPage;
