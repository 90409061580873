import React from "react";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";

const Wrapper = styled(IconButton)`
  width: 40px;
  height: 40px;
  background: #F6F6F6;
  border-radius: 20px;
  padding: 0;
`;

const ButtonRounded = ({ imgSrc, onClick }) => {
  return (
    <Wrapper
      onClick={onClick}
    >
      <img src={imgSrc} alt='' />
    </Wrapper>
  );
};

export default ButtonRounded;
