import gql from "graphql-tag";
import { PlacesOnStreet } from "./fragments";

export const takePlaceOnStreet = gql`
  mutation takePlaceOnStreet($input: TakePlaceOnStreetInput! ) {
    takePlaceOnStreet(input: $input) {
      placeId
      holderId
    }
  } 
`;

export const freePlaceOnStreet = gql`
  mutation freePlaceOnStreet($input: FreePlaceOnStreetInput!) {
    freePlaceOnStreet(input: $input) {
      streetId
      placeId
    }
  } 
`;

export const addAllowedPlaces = gql`
  mutation addAllowedPlaces($id: ID!, $input: AllowedPlacesInput!) {
    addAllowedPlaces(id: $id, input: $input) {
      _id
      title
      ${PlacesOnStreet}
    }
  } 
`;

export const removeAllowedPlaces = gql`
  mutation removeAllowedPlaces($id: ID!, $input: AllowedPlacesInput!) {
    removeAllowedPlaces(id: $id, input: $input) {
      _id
      title
      ${PlacesOnStreet}
    }
  } 
`;
