import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useShopColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      name: "id",
      label: "ID",
      sortable: "_id",
    },
    {
      name: "shopName",
      label: t('shops.shop_name'),
      sortable: "name",
    },
    {
      name: "category",
      label: t('shops.category'),
      sortable: "shopCategoryId",
    },
    {
      name: "eCommerce",
      label: t('shops.ecommerce'),
      sortable: "eCommerceEnabled",
    },
    {
      name: "merchant",
      label: t('shops.merchant'),
      sortable: "companyId",
    },
    {
      name: "ratePre",
      label: t('shops.rate_pre_override'),
      postfix: " (USD)",
      sortable: false,
    },
    {
      name: "ratePost",
      label: t('shops.rate_post_override'),
      postfix: " (USD)",
      sortable: false,
    },
    {
      name: "commissionPre",
      label: t('shops.commission_per_override'),
      postfix: " (%)",
      sortable: false,
    },
    {
      name: "commissionPost",
      label: t('shops.commission_post_override'),
      postfix: " (%)",
      sortable: false,
    },
    {
      name: "billing",
      label: t('shops.billing_period'),
      postfix: "(in days)",
      sortable: false,
    },
    {
      name: "isVisible",
      label: t('shops.visible_for_shoppers'),
      sortable: false,
    },
    {
      name: "status",
      label: t('shops.status'),
      sortable: "statusOrder",
    },
    {
      name: "createdAt",
      label: t('ui.created_on'),
    },
    {
      name: "options",
    },
  ], [t]);
};
