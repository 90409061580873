import gql from "graphql-tag";

export const gdprDistortMutation = gql`
  mutation gdprDistort($accountIds: [ID]) {
    gdprDistort(accountIds: $accountIds)
  }
`;

export const gdprUpdateTermsMutation = gql`
  mutation gdprUpdateTerms($input: GdprUpdateTermsInput!) {
    gdprUpdateTerms(input: $input) {
      terms
      createdAt
      updatedAt
    }
  }
`;

export const settingsQuery = gql`
  query settings($settingIds: [ID], $shopIds: [ID]) {
    settings(settingIds: $settingIds, shopIds: $shopIds) {
      _id
      name
      description
      value
      shopId
    }
  }
`;

export const updateSettingsMutation = gql`
  mutation updateSettings($input: UpdateSettingsInput) {
    updateSettings(input: $input) {
      _id
      name
    }
  }
`;

export const stripeAddPaymentMethodMutation = gql`
  mutation stripeAddPaymentMethod($input: StripeAddPaymentMethodInput!) {
    stripeAddPaymentMethod(input: $input)
  }
`;

export const stripeRemovePaymentMethodMutation = gql`
  mutation stripeRemovePaymentMethod($input: StripeRemovePaymentMethodInput!) {
    stripeRemovePaymentMethod(input: $input)
  }
`;

export const stripeMakePaymentMethodDefault = gql`
  mutation stripeMakePaymentMethodDefault($input: StripeMakePaymentMethodDefaultInput!) {
    stripeMakePaymentMethodDefault(input: $input)
  }
`;
