import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const Wrapper = styled.div`
  height: 32px;
  background: #FFF;
  width: 100%;
  padding: 0 12px;
  border-bottom: 1px solid #E9E9E9;
  display: flex;
  align-items: center;
`;

const CardText = styled(Typography)`
  color: #222;
  font-family: Mulish, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  user-select: none;
  cursor: pointer;
  width: 100%;
`;

const CardSubscription = ({ title, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <CardText>{title}</CardText>
    </Wrapper>
  );
};

export default CardSubscription;
