import React, { useState } from "react";
import styled from "styled-components";
import svgEmpty from '../../../../../../icons/variantEditor/empty.svg';
import svgEdit from '../../../../../../icons/variantEditor/edit-white.svg';
import svgRemove from '../../../../../../icons/variantEditor/remove-media.svg';

const Wrapper = styled('div')`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #F6F6F6;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
`;

const MainImage = styled('img')`
  max-height: 40px;
  max-width: 40px;
  border-radius: 8px;
`;

const EditButton = styled('button')`
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.4);
  opacity: ${(props) => props.opacity};
  transition: .2s;
  border: none;
  cursor: pointer;
`;

const DeleteButton = styled('button')`
  position: absolute;
  z-index: 1;
  right: -10px;
  top: -10px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 4px 11.429px 0 rgba(199, 149, 158, 0.25);
  opacity: ${(props) => props.opacity};
  border: none;
  cursor: pointer;
`;

const CellComponentMedia = ({ picSrc, onEdit, onDelete, isEditable }) => {
  const [hover, setHover] = useState(false);

  const opacity = hover ? '1' : '0';

  return (
    <Wrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <MainImage
        src={picSrc || svgEmpty}
        alt=''
      />
      {
        isEditable &&
        <>
          <EditButton
            opacity={opacity}
            onClick={onEdit}
          >
            <img
              src={svgEdit}
              alt='edit'
            />
          </EditButton>
          {
            picSrc ? <DeleteButton
                opacity={opacity}
                onClick={onDelete}
              >
                <img
                  src={svgRemove}
                  alt='x'
                />
              </DeleteButton>
              : null
          }
        </>
      }
    </Wrapper>
  );
};

export default CellComponentMedia;
