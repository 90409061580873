import React, { useEffect, useState } from "react";
import imgPicker from "../../../icons/color-picker.png";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import PopoverContent from "./PopoverContent";
import ColorCircleBorder from "./ColorCircleBorder";

const ColorPickerButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  padding: 0;
  background: #FFF;
  
  img {
    width: 40px;
    height: 40px;
    pointer-events: none;
  }
  
  &:hover {
    background: #FFF;
  }
`;

const StyledPopover = styled('div')`
  width: 380px;
  padding: 16px 24px 24px 24px;
  border-radius: 20px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #E5E7EB;
  box-shadow: 0 7px 20px 0 rgba(183, 195, 211, 0.25);
  background: #FFF;
  margin: -13px 0 0 13px;
`;

const ColorPicker = ({ colorList, color, onConfirmChange }) => {
  const [innerColor, setInnerColor] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (color?.hex) {
      setInnerColor(color.hex);
    }
  }, [color]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onConfirmChange(color.id, innerColor);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ColorPickerButton
        onClick={handleClick}
        style={{
          background: innerColor,
        }}
      >
        <ColorCircleBorder />
        {
          !innerColor && <img src={imgPicker} alt='pick' />
        }
      </ColorPickerButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          component: StyledPopover,
        }}
      >
        <PopoverContent
          onClose={handleClose}
          addedColors={colorList.filter((item) => item.id !== color.id)}
          innerColor={innerColor}
          setInnerColor={setInnerColor}
        />
      </Popover>
    </>
  );
};

export default ColorPicker;
