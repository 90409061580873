import awsmobile from "../../../aws-exports";

const setLocalstorage = ({ access_token, id_token, refresh_token, sub }) => {
  const prefix = 'CognitoIdentityServiceProvider';
  const clientId = awsmobile.aws_user_pools_web_client_id;

  const content = [
    {
      name: `${prefix}.${clientId}.LastAuthUser`,
      value: sub,
    },
    {
      name: `${prefix}.${clientId}.${sub}.clockDrift`,
      value: 0,
    },
    {
      name: `${prefix}.${clientId}.${sub}.accessToken`,
      value: access_token,
    },
    {
      name: `${prefix}.${clientId}.${sub}.idToken`,
      value: id_token,
    },
    {
      name: `${prefix}.${clientId}.${sub}.refreshToken`,
      value: refresh_token,
    },
  ];

  content.forEach(({ name, value }) => {
    localStorage.setItem(name, value);
  });
};

export default setLocalstorage;
