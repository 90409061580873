import React, { forwardRef, useContext } from "react";
import svgHelpClose from "../../../../icons/help-close.svg";
import styled from "styled-components";
import { StyledIconButton } from "./StyledIconButton";
import CardSubscription from "./CardSubscription";
import { ShopEditContext } from "../../../../package/src/context/ShopEditContext";
import DialogMessages from "../../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import StyledButton from "../../../../UI/Button/Button";
import Dialog from "../../../../UI/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import useSubscriptionPayment from "../../../shops/hooks/useSubscriptionPayment";
import useSelectPackage from "../../../shops/hooks/useSelectPackage";

const Paper = styled.div`
  background: #FFF;
  width: 268px;
  height: 174px;
  border-radius: 16px;
  box-shadow: 0 7px 20px 0 rgba(183, 195, 211, 0.25);
  position: relative;
  overflow: hidden;
`;

const ScrollArea = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 174px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E9E9E9;
    border-radius: 8px;
  }
`;

const CardSubscriptionsList = ({ onClose, shopId }, ref) => {
  const { t } = useTranslation();

  const {
    packages,
  } = useContext(ShopEditContext);

  const { onProceedPayment } = useSubscriptionPayment(shopId);

  const {
    onPackageCardClick,
    dialogOpen,
    closeDialog,
  } = useSelectPackage();

  return (
    <Paper ref={ref}>
      <ScrollArea>
        {
          packages
            .filter((item) => !item.isActive)
            .map((item) => <CardSubscription
            key={item.id}
            title={item.title}
            onClick={() => onPackageCardClick(item.id)}
          />)
        }
      </ScrollArea>
      <StyledIconButton
        onClick={onClose}
      >
        <img src={svgHelpClose} alt='X'/>
      </StyledIconButton>

      <Dialog
        open={dialogOpen}
        handleClose={closeDialog}
      >
        <DialogMessages
          title={t('ui.confirm')}
          message={t('subscription.sub_already_exists_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={async () => {
              closeDialog();
              await onProceedPayment(null);
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          <StyledButton
            width='180px'
            mytype='secondary'
            handleClick={closeDialog}
          >
            {t('ui.no')}
          </StyledButton>
        </DialogButtons>
      </Dialog>
    </Paper>
  );
};

export default forwardRef(CardSubscriptionsList);
