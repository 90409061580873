import React, { useCallback, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid, makeStyles } from "@material-ui/core";
import Dropdown from "../../../UI/Dropdown";
import Search from "../../../UI/Search";
import StyledButton from "../../../UI/Button";
import DataTable from "../../../UI/DataTable/DataTable";
import { colors, constants, standardPageStyles } from "../../../constants";
import Dialog from "../../../UI/Dialog/Dialog";
import { useSnackbar } from "notistack";
import { useApolloClient } from "@apollo/react-hooks";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import svgApprove from "../../../icons/table-approve.svg";
import svgClose from "../../../icons/close-red.svg";
import { merchantRequestsQuery } from "../graphql/queries";
import SelectAllAutocomplete from "../../../package/src/SelectAllAutocomplete";
import { handleMerchantRequestMutation } from "../graphql/mutations";
import { DialogMutateSingle, DialogMutate } from '../components/dialogs';
import { useMerchantRequestsColumns } from "../hooks/useMerchantRequestsColumns";
import { useTranslation } from "react-i18next";
import useModifiers from "../hooks/useModifiers";

const useStyles = makeStyles(theme => ({
  ...standardPageStyles,
  redText: {
    color: colors.red,
    fontWeight: 700,
  },
}));

const selectAllStatusesForAPI = ['pending', 'approved', 'declined'];
const LOCAL_STORAGE_KEY = "merchant-requests-selected-states";

const MerchantRequestsPage = ({ viewer }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const columns = useMerchantRequestsColumns();
  const { modifyData } = useModifiers();

  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowId, setRowId] = useState('');
  const [tableData, setTableData] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [exporting, setExporting] = useState(false);

  const requestList = useMemo(() => [
    {
      label: t('merchant.pending'),
      value: 'pending',
    },
    {
      label: t('merchant.approved'),
      value: 'approved',
    },
    {
      label: t('merchant.declined'),
      value: 'declined',
    },
  ], [t]);

  const [request, setRequest] = useState([]);
  const [requestStatuses, setRequestStatuses] = useState(requestList);
  const [recovered, setRecovered] = useState(false);

  const recoverStatuses = useCallback(() => {
    // Get the user selections from localStorage if they exist.
    // Default to "pending" otherwise.
    const selected = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || [
      constants.MERCHANT_REQUESTS.STATE.PENDING,
    ];

    const newList = requestStatuses.map(item => {
      if (selected.includes(item.value)) {
        return {
          ...item,
          selected: true,
        }
      }
      return item;
    });

    setRequestStatuses(newList);
    setRecovered(true);
    return selected;
  }, [])

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
      setIsLoading(true);

      let myRequest = request;
      if (!recovered) {
        myRequest = recoverStatuses();
      }

      apolloClient.query({
        query: merchantRequestsQuery,
        variables: {
          states: myRequest.length ? myRequest : selectAllStatusesForAPI,
          filter: globalFilter,
          first: pageSize,
          //limit: (pageIndex + 1) * pageSize,
          offset: pageIndex * pageSize,
          sortOrder,
          sortBy,
        },
        fetchPolicy: "network-only",
      })
        .then(res => {
          // Update the state with the fetched data as an array of objects and the calculated page count
          const total = res.data.merchantRequests.totalCount;
          setTableData(res.data.merchantRequests.nodes);
          setPageCount(Math.ceil(total / pageSize));
          setTotalCount(total);
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setIsLoading(false))
    }, [request, t]);

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: merchantRequestsQuery,
      variables: {
        states: request,
        offset: 0,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyData(res.data.merchantRequests.nodes, 'export', null);
        const fields = columns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [viewer, request, t, modifyData]);

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const rowOptions = useMemo(() => [
    {
      value: constants.OPTIONS.approve,
      label: t('merchant.approve'),
      icon: svgApprove,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('approveSingle');
        setDialogOpen(true);
      },
    },
    {
      value: constants.OPTIONS.decline,
      label: t('merchant.decline'),
      icon: svgClose,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('declineSingle');
        setDialogOpen(true);
      },
    },
  ], [t])

  const itemsListActions = useMemo(() => [
    {
      content: t('merchant.approve'),
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('approve');
        setDialogOpen(true);
      },
    },
    {
      content: <span className={classes.redText}>{t('merchant.decline')}</span>,
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('decline');
        setDialogOpen(true);
      },
    },
  ], [selectedRows, t])

  const mutateRequest = (id, state) => {
    return apolloClient.mutate({
      mutation: handleMerchantRequestMutation,
      variables: {
        id,
        state,
      }
    });
  }

  const dataProps = useMemo(() => {
    return modifyData(tableData, '', rowOptions);
  }, [tableData, modifyData])

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'approveSingle':
      case 'declineSingle': return <DialogMutateSingle
        state={dialogName === 'approveSingle' ? 'approved' : 'declined'}
        rowId={rowId}
        setDialogOpen={setDialogOpen}
        mutateRequest={mutateRequest}
        refetch={refetch}
      />
      case 'approve':
      case 'decline': return <DialogMutate
        state={dialogName === 'approve' ? 'approved' : 'declined'}
        selectedRows={selectedRows}
        setDialogOpen={setDialogOpen}
        mutateRequest={mutateRequest}
        refetch={refetch}
      />
      default: return null;
    }
  }

  const handleChangeState = (val) => {
    const newList = val.map(k => k.value);
    // Changes are also saved in localStorage for better UI/UX (to save
    // the user's choices) but also because successful `actions` will
    // trigger a re-render
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newList));
    setRequest(newList);
    refetch();
  }

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Toolbar title={t('merchant.merchant_requests')} />

        <div className={classes.firstRow}>
          <SelectAllAutocomplete
            placeholder={t('merchant.select_statuses')}
            items={requestStatuses}
            onChange={handleChangeState}
            limitTags={6}
            selectAllLabel={t('merchant.all_statuses')}
            style={{marginBottom: 0}}
          />
          <StyledButton
            width='140px'
            mytype='secondary'
            handleClick={exportData}
            disabled={exporting || !tableData.length}
          >
            {t('ui.export')}
          </StyledButton>
        </div>

        <div className={classes.secondRow}>
          <Dropdown
            title={t('ui.actions')}
            itemsList={itemsListActions}
          />
          <Search
            handleChange={handleSearch}
            onSearchClick={refetch}
            placeholder={t('ui.search')}
            useDebounce
          />
        </div>

        <DataTable
          columns={columns}
          isLoading={isLoading}
          data={dataProps}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          handleFetchData={onFetchData}
          pageCount={pageCount}
          totalCount={totalCount}
          searchValue={searchValue}
          reloadTable={reloadTable}
          defaultSortField='createdAt'
          defaultSortOrder='desc'

          // styles for 2 rows upper panel + checkboxes
          maxHeight='calc(100vh - 390px)'
          maxHeightLaptop='calc(100vh - 460px)'
          maxHeightMobile='calc(100vh - 440px)'
        />

      </Grid>

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>

  );
}

export default MerchantRequestsPage;
