import gql from "graphql-tag";
import { Tag } from "./fragments";

export const tagListingQuery = gql`
  query getTags(
    $shopId: ID,
    $shopIds: [ID],
    $filter: String,
    $first: ConnectionLimitInt,
    $last:  ConnectionLimitInt,
    $before: ConnectionCursor,
    $after: ConnectionCursor,
    $sortBy: TagSortByField,
    $sortOrder: SortOrder,
    $genericFilters: GenericFilterInput
  ) {
    tags(
      shopId: $shopId,
      shopIds: $shopIds,
      filter: $filter,
      first: $first,
      last: $last,
      before: $before,
      after: $after,
      shouldIncludeInvisible: true,
      sortBy: $sortBy,
      sortOrder: $sortOrder,
      genericFilters: $genericFilters
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ${Tag}
        shop {
          _id
          name
        }
      }
      totalCount
    }
  }
`;

export const getTag = gql`
  query getTag($shopId: ID!, $slugOrId: String!) {
    tag(slugOrId: $slugOrId, shopId: $shopId, shouldIncludeInvisible: true) {
      ${Tag}
    }
  }
`;

export const tagProductsQueryString = gql`
  query getTagProducts(
    $shopId: ID!
    $first: ConnectionLimitInt
    $tagId: ID!
    $last: ConnectionLimitInt
    $before: ConnectionCursor
    $after: ConnectionCursor
  ) {
    productsByTagId(
      shopId: $shopId
      tagId: $tagId
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      nodes {
        _id
        title
        position
      }
    }
  }
`;

export const tagProductsQuery = gql`
  ${tagProductsQueryString}
`;
