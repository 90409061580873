import React from 'react';
import {Checkbox} from '@material-ui/core';
import {IconCheckOn, IconCheckOff} from '../../icons';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  & .MuiSvgIcon-root {
    fill: none;
  }
`

const CustomCheckbox = (props) => {
  return (
    <StyledCheckbox
      checkedIcon={<IconCheckOn/>}
      icon={<IconCheckOff/>}
      {...props}
    />
  );
}

export default CustomCheckbox;