import { useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

// GraphQL Queries / Mutations
import ArchiveProductsMutation from "../graphql/mutations/archiveProducts";
import ArchiveProductVariantsMutation from "../graphql/mutations/archiveProductVariants";
import CloneProductsMutation from "../graphql/mutations/cloneProducts";
import CloneProductVariantsMutation from "../graphql/mutations/cloneProductVariants";
import CreateProductVariantMutation from "../graphql/mutations/createProductVariant";
import ProductQuery from "../graphql/queries/product";
import MediaFromProductQuery from "../graphql/queries/mediaFromProduct";
import PublishProductsToCatalogMutation from "../graphql/mutations/publishProductsToCatalog";
import UpdateProductMutation from "../graphql/mutations/updateProduct";
import UpdateProductVariantMutation from "../graphql/mutations/updateProductVariant";
import UpdateProductVariantPricesMutation from "../graphql/mutations/updateProductVariantPrices";
import updateSimpleInventoryMutation from "../graphql/mutations/updateSimpleInventory";
import updateRelatedProducts from "../graphql/mutations/updateRelatedProducts";
import { queryProductTypes } from "../../product-attributes/graphql/queries";

/**
 * @method useProduct
 * @summary useProduct hook
 * @param {Object} args input arguments
 * @param {String} args.productId Product Id to load product data for
 * @param {String} args.variantId Variant Id to load product data for
 * @param {String} args.optionId Option Id to load product data for
 * @returns {Object} Result containing the product and other helpers for managing that product
 */
function useProduct(args = {}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { productId: productIdProp, variantId: variantIdProp, optionId: optionIdProp, shopId } = args;
  const [newMetaField, setNewMetaField] = useState({ key: "", value: "" });
  const history = useHistory();
  const routeParams = useParams();
  const [updateProduct] = useMutation(UpdateProductMutation);
  const [archiveProducts] = useMutation(ArchiveProductsMutation);
  const [cloneProducts] = useMutation(CloneProductsMutation);
  const [createProductVariant] = useMutation(CreateProductVariantMutation);
  const [updateProductVariant] = useMutation(UpdateProductVariantMutation);
  const [cloneProductVariants] = useMutation(CloneProductVariantsMutation);
  const [archiveProductVariants] = useMutation(ArchiveProductVariantsMutation);
  const [publishProductsToCatalog] = useMutation(PublishProductsToCatalogMutation);
  const [updateProductVariantPrices] = useMutation(UpdateProductVariantPricesMutation);
  const [updateSimpleInventory] = useMutation(updateSimpleInventoryMutation);
  const [updateRelated] = useMutation(updateRelatedProducts);

  const productId = routeParams.productId || productIdProp;
  const variantId = routeParams.variantId || variantIdProp;
  const optionId = routeParams.optionId || optionIdProp;
  const shopIds = [routeParams.shopId] || [shopId];

  const { data: productQueryResult, loading, refetch: refetchProduct } = useQuery(ProductQuery, {
    variables: {
      productId,
      shopId: shopIds[0],
    },
    fetchPolicy: "network-only",
    skip: !shopIds.length || !productId,
  });

  const { product } = productQueryResult || {};

  let variant;
  let option;

  if (product && variantId) {
    variant = product.variants.find(({ _id }) => _id === variantId);
  }

  if (product && variantId && optionId) {
    option = variant.options.find(({ _id }) => _id === optionId);
  }

  const { data: mediaDataQueryResult, refetch: refetchMedia } = useQuery(MediaFromProductQuery, {
    variables: {
      productId,
      shopId: shopIds[0],
    },
    fetchPolicy: "network-only",
    skip: !shopIds.length || !productId,
  });

  const { data: productTypesQueryResult, loading: loadingTypes } = useQuery(queryProductTypes, {
    fetchPolicy: "network-only",
  });

  const onPublishProduct = useCallback(
    async ({ productId: productIdLocal = product._id }) => {
      try {
        await publishProductsToCatalog({
          variables: {
            productIds: [productIdLocal],
          },
        });

        // Refetch on success to force a cache update
        refetchProduct();

        enqueueSnackbar(t("admin.catalogProductPublishSuccess"), {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(error.toString().replace("GraphQL error:", ""), { variant: "error" });
      }
    },
    [product, publishProductsToCatalog, refetchProduct, enqueueSnackbar, t]
  );

  const onArchiveProduct = useCallback(
    async (productLocal, redirectUrl) => {
      try {
        await archiveProducts({
          variables: {
            input: { shopId: shopIds[0], productIds: [productLocal] },
          },
        });
        enqueueSnackbar("Product(s) deleted successfully.", {
          variant: "success",
        });
        history.push(redirectUrl);
      } catch (error) {
        enqueueSnackbar("Unable to delete product(s).", {
          variant: "success",
        });
      }
    },
    [enqueueSnackbar, history, archiveProducts, shopIds, t]
  );

  const onCloneProduct = useCallback(
    async productLocal => {
      try {
        await cloneProducts({
          variables: {
            input: { shopId: shopIds[0], productIds: [productLocal] },
          },
        });
        enqueueSnackbar(t("productDetailEdit.cloneProductSuccess"), {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(t("productDetailEdit.cloneProductFail"), {
          variant: "error",
        });
      }
    },
    [cloneProducts, enqueueSnackbar, shopIds, t]
  );

  const onCreateVariant = useCallback(
    async ({
      parentId: parentIdLocal = product._id,
      shopId: shopIdLocal = shopIds[0],
      redirectOnCreate = false,
    }) => {
      try {
        const { data } = await createProductVariant({
          variables: {
            input: {
              productId: parentIdLocal,
              shopId: shopIdLocal,
              variant: {
                isVisible: true,
              },
            },
          },
        });

        // Optionally redirect to the new variant or option on create
        if (redirectOnCreate) {
          if (data && parentIdLocal === product._id) {
            const newVariantId =
              data.createProductVariant &&
              data.createProductVariant.variant &&
              data.createProductVariant.variant._id;
            history.push(`/products/${product.shop._id}/${product._id}/${newVariantId}`);
          } else {
            const newOptionId =
              data.createProductVariant &&
              data.createProductVariant.variant &&
              data.createProductVariant.variant._id;
            history.push(
              `/products/${product.shop._id}/${product._id}/${parentIdLocal}/${newOptionId}`
            );
          }
        }

        // Refetch product data when we adda new variant
        refetchProduct();

        // Because of the way GraphQL and meteor interact when creating a new variant,
        // we can't immediately redirect a user to the new variant as GraphQL is too quick
        // and the meteor subscription isn't yet updated. Once this page has been updated
        // to use GraphQL for data fetching, add a redirect to the new variant when it's created
        enqueueSnackbar(t("productDetailEdit.addVariant"), {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(t("productDetailEdit.addVariantFail"), {
          variant: "error",
        });
      }
    },
    [createProductVariant, enqueueSnackbar, history, product, refetchProduct, shopIds, t]
  );

  const onToggleProductVisibility = useCallback(async () => {
    try {
      await updateProduct({
        variables: {
          input: {
            productId: product._id,
            shopId: shopIds[0],
            product: {
              isVisible: !product.isVisible,
            },
          },
        },
      });

      enqueueSnackbar(t("productDetailEdit.updateProductFieldSuccess"), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("productDetailEdit.updateProductFieldFail"), {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, product, shopIds, t, updateProduct]);

  /**
   * @method onUpdateProduct
   * @param {Object} args
   * @param {Object} args.product Product fields to update
   * @param {Object} [args.productId] Product ID to update. Leave blank for current product.
   * @param {Object} [args.shopId] Shop ID of the product to update. Leave blank for current shop.
   */
  const onUpdateProduct = useCallback(
    async ({
      product: productLocal,
      productId: productIdLocal = product._id,
      shopId: shopIdLocal = shopIds[0],
      variantOptions,
    }) => {
      try {
        const data = await updateProduct({
          variables: {
            input: {
              productId: productIdLocal,
              shopId: shopIdLocal,
              product: productLocal,
              variantOptions,
            },
          },
        });

        if (data) {
          enqueueSnackbar('Successfully updated product', {
            variant: "success",
          });

          return data;
        }
      } catch (error) {
        enqueueSnackbar('Error while updating product', {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, product, shopIds, t, updateProduct]
  );

  const onUpdateRelatedProducts = useCallback(async ({
    productId: productIdLocal = product._id,
    shopId: shopIdLocal = shopIds[0],
    relatedProductIds,
  }) => {
    try {
      const data = await updateRelated({
        variables: {
          productId: productIdLocal,
          shopId: shopIdLocal,
          relatedProductIds,
        },
      });

      if (data) {
        enqueueSnackbar('Successfully updated related products', {
          variant: "success",
        });

        return data;
      }
    } catch (error) {
      enqueueSnackbar('Related products update error', {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, product, shopIds, updateRelated]);

  const handleDeleteProductTag = useCallback(
    async ({
      tag: tagLocal,
      product: productLocal = product,
      productId: productIdLocal = product._id,
      shopId: shopIdLocal = shopIds[0],
    }) => {
      const filteredTagIds = productLocal.tags.nodes
        .filter(({ _id }) => _id !== tagLocal._id)
        .map(({ _id }) => _id);

      try {
        await updateProduct({
          variables: {
            input: {
              productId: productIdLocal,
              shopId: shopIdLocal,
              product: {
                tagIds: filteredTagIds,
              },
            },
          },
        });

        enqueueSnackbar(t("productDetailEdit.removeProductTagSuccess"), {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(t("productDetailEdit.removeProductTagFail"), {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, product, shopIds, t, updateProduct]
  );

  const onUpdateProductVariant = useCallback(
    async ({
      variant: variantLocal,
      variantId: variantIdLocal,
      shopId: shopIdLocal = shopIds[0],
    }) => {
      try {
        await updateProductVariant({
          variables: {
            input: {
              shopId: shopIdLocal,
              variant: variantLocal,
              variantId: variantIdLocal,
            },
          },
        });

        enqueueSnackbar(t("productVariant.updateVariantSuccess"), {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(t("productVariant.updateVariantFail"), {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, shopIds, t, updateProductVariant]
  );

  const onUpdateProductVariantPrices = useCallback(
    async ({
      variantPrices: variantPricesLocal,
      variantId: variantIdLocal,
      shopId: shopIdLocal = shopIds[0],
    }) => {
      const { price, compareAtPrice } = variantPricesLocal;
      try {
        await updateProductVariantPrices({
          variables: {
            input: {
              shopId: shopIdLocal,
              prices: {
                price,
                compareAtPrice: compareAtPrice.amount,
              },
              variantId: variantIdLocal,
            },
          },
        });

      } catch (error) {
        enqueueSnackbar(t("productVariant.updateVariantPricesFail"), {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, shopIds, updateProductVariantPrices, t]
  );

  const onUpdateProductVariantStock = useCallback(
    async ({
      variantStock: variantStockLocal,
      productId: productIdLocal = product._id,
      variantId: variantIdLocal,
      shopId: shopIdLocal = shopIds[0],
    }) => {
      const { inventoryInStock, canBackorder } = variantStockLocal;

      try {
        await updateSimpleInventory({
          variables: {
            input: {
              isEnabled: true,
              canBackorder,
              inventoryInStock,
              productConfiguration: {
                productId: productIdLocal,
                productVariantId: variantIdLocal,
              },
              shopId: shopIdLocal,
            },
          },
        });

        // Refetch product data to get variant with new inventory info
        refetchProduct();

        // TODO translation
        enqueueSnackbar(t("Variant stock updated successfully"), {
          variant: "success",
        });
      } catch (error) {
        // TODO translation
        enqueueSnackbar("Unable to update variant stock", { variant: "error" });
      }
    },
    [enqueueSnackbar, shopIds, updateSimpleInventory, t]
  );

  const onToggleVariantVisibility = useCallback(
    async ({ variant: variantLocal, shopId: shopIdLocal = shopIds[0] }) => {
      try {
        await updateProductVariant({
          variables: {
            input: {
              variantId: variantLocal._id,
              shopId: shopIdLocal,
              variant: {
                isVisible: !variantLocal.isVisible,
              },
            },
          },
        });

        enqueueSnackbar(t("productDetailEdit.updateProductFieldSuccess"), {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(t("productDetailEdit.updateProductFieldFail"), {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, shopIds, updateProductVariant, t]
  );

  const onCloneProductVariants = useCallback(
    async ({ variantIds: variantIdsLocal, shopId: shopIdLocal = shopIds[0] }) => {
      try {
        await cloneProductVariants({
          variables: {
            input: {
              shopId: shopIdLocal,
              variantIds: variantIdsLocal,
            },
          },
        });

        // Refetch product data when we adda new variant
        refetchProduct();

        enqueueSnackbar(t("productDetailEdit.cloneProductSuccess"), {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(t("productDetailEdit.cloneProductFail"), {
          variant: "error",
        });
      }
    },
    [cloneProductVariants, enqueueSnackbar, refetchProduct, shopIds, t]
  );

  const onArchiveProductVariants = useCallback(
    async ({
      variantIds: variantIdsLocal,
      shopId: shopIdLocal = shopIds[0],
      redirectOnArchive = false,
    }) => {
      try {
        await archiveProductVariants({
          variables: {
            input: {
              shopId: shopIdLocal,
              variantIds: variantIdsLocal,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar("Unable to delete variant(s).", {
          variant: "error",
        });
      }

      if (redirectOnArchive) {
        let redirectUrl;

        if (option) {
          redirectUrl = `/products/${product.shop._id}/${product._id}/${variant._id}`;
        } else {
          redirectUrl = `/products/${product.shop._id}/${product._id}`;
        }

        history.push(redirectUrl);
      }

      // Refetch product data when we adda new variant
      refetchProduct();

      enqueueSnackbar("Variant(s) deleted successfully", {
        variant: "success",
      });
    },
    [
      archiveProductVariants,
      enqueueSnackbar,
      history,
      option,
      product,
      refetchProduct,
      shopIds,
      t,
      variant,
    ]
  );

  // Convert the social metadata to a format better suited for forms
  if (product && Array.isArray(product.socialMetadata)) {
    product.socialMetadata.forEach(({ service, message }) => {
      product[`${service}Msg`] = message;
    });
  }

  const isPublished = product?.currentProductHash === product?.publishedProductHash;

  return {
    currentVariant: option || variant,
    newMetaField,
    isLoading: loading || loadingTypes,
    handleDeleteProductTag,
    onArchiveProduct,
    onArchiveProductVariants,
    onCloneProduct,
    onCloneProductVariants,
    onCreateVariant,
    onPublishProduct,
    onUpdateProduct,
    onUpdateProductVariantPrices,
    onUpdateProductVariantStock,
    onUpdateRelatedProducts,
    option,
    onRestoreProduct: () => {}, // TODO: implement product archive restore function
    onToggleProductVisibility,
    onToggleVariantVisibility,
    onUpdateProductVariant,
    product: productQueryResult && productQueryResult.product,
    mediaData: mediaDataQueryResult && mediaDataQueryResult.product.media,
    productTypes: productTypesQueryResult && productTypesQueryResult.productTypes.data,
    refetchProduct,
    refetchMedia,
    setNewMetaField,
    shopIds,
    variant,
    isPublished,
  };
}

export default useProduct;
