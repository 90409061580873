import * as Yup from "yup";

export const SettingsSchema = Yup.object().shape({
  companyName: Yup.string()
    .required("errors.field_required"),
  email: Yup.string()
    .email('errors.invalid_email'),
  phoneNumber: Yup.string()
    .required('errors.field_required'),
  country: Yup.string()
    .required('errors.field_required'),
  province: Yup.string()
    .required('errors.field_required'),
  city: Yup.string()
    .required('errors.field_required'),
})

export const SettingsInfoSchema = Yup.object().shape({
  companyName: Yup.string()
    .required("errors.field_required"),
  email: Yup.string()
    .email('errors.invalid_email'),
})

export const SettingsShippingSchema = Yup.object().shape({
  shippingNickname: Yup.string()
    .required("errors.field_required"),
  shippingName: Yup.string()
    .required("errors.field_required"),
  shippingSurname: Yup.string()
    .required("errors.field_required"),
  shippingCountry: Yup.string()
    .required("errors.field_required"),
  shippingProvince: Yup.string()
    .required("errors.field_required"),
  shippingCity: Yup.string()
    .required("errors.field_required"),
  shippingStreet: Yup.string()
    .required("errors.field_required"),
  shippingHouseNumber: Yup.string()
    .required("errors.field_required"),
  shippingPostcode: Yup.string()
    .required("errors.field_required"),
  billingIsSameAsShipping: Yup.boolean(),
  billingName: Yup.string()
    .when('billingIsSameAsShipping', {
      is: false,
      then: Yup.string().required('errors.field_required'),
    }),
  billingSurname: Yup.string()
    .when('billingIsSameAsShipping', {
      is: false,
      then: Yup.string().required('errors.field_required'),
    }),
  billingCountry: Yup.string()
    .when('billingIsSameAsShipping', {
      is: false,
      then: Yup.string().required('errors.field_required'),
    }),
  billingProvince: Yup.string()
    .when('billingIsSameAsShipping', {
      is: false,
      then: Yup.string().required('errors.field_required'),
    }),
  billingCity: Yup.string()
    .when('billingIsSameAsShipping', {
      is: false,
      then: Yup.string().required('errors.field_required'),
    }),
  billingStreet: Yup.string()
    .when('billingIsSameAsShipping', {
      is: false,
      then: Yup.string().required('errors.field_required'),
    }),
  billingHouseNumber: Yup.string()
    .when('billingIsSameAsShipping', {
      is: false,
      then: Yup.string().required('errors.field_required'),
    }),
  billingPostcode: Yup.string()
    .when('billingIsSameAsShipping', {
      is: false,
      then: Yup.string().required('errors.field_required'),
    }),
});
