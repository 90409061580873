import { v4 } from "uuid";

/**
 * Build a list of attributes based on variants received from the server
 * @param variants
 * @returns {unknown[]}
 */

const buildAttributesByVariants = (variants) => {
  if (!variants.length) {
    return [];
  }

  const collection = {};

  const complexTypes = ['list', 'colorPicker', 'datePicker'];

  variants.forEach((variant) => {
    if (!variant.isDelete) {
      variant.attributeValues.forEach((obj) => {
        if (collection.hasOwnProperty(obj.attributeId)) {
          const current = collection[obj.attributeId].value;
          if (obj.inputType === 'list') {
            const newValue = [...collection[obj.attributeId].value];
            if (!current.includes(obj.value)) {
              newValue.push(obj.value);
            }
            collection[obj.attributeId] = {
              ...collection[obj.attributeId],
              value: newValue,
            }
          } else if (obj.inputType === 'colorPicker') {
            const newValue = [...collection[obj.attributeId].value];
            if (!current.find((k) => k.hex === obj.value.hex && k.name === obj.value.name)) {
              newValue.push(obj.value);
            }
            collection[obj.attributeId] = {
              ...collection[obj.attributeId],
              value: newValue,
            }
          } else if (obj.inputType === 'datePicker') {
            const newValue = [...collection[obj.attributeId].value];
            if (!current.find((k) => k.date === obj.value.date)) {
              newValue.push(obj.value);
            }
            collection[obj.attributeId] = {
              ...collection[obj.attributeId],
              value: newValue,
            }
          }
        } else {
          const isComplex = complexTypes.includes(obj.inputType);
          collection[obj.attributeId] = {
            id: v4(),
            attributeId: obj.attributeId,
            attributeName: obj.attributeName,
            inputType: obj.inputType,
            value: isComplex ? [obj.value] : (obj.inputType === 'checkbox' ? [true, false]: obj.value),
          };
        }
      });
    }
  });

  return Object.values(collection);
};

export default buildAttributesByVariants;
