import gql from "graphql-tag";
import ProductVariant from "./productVariant";

export default gql`
  fragment Product on Product {
    _id
    productTypeObject {
      id
      name
      variantAttributes {
        attributeId
        attribute {
          id
          name
          valueType
          inputType
          inputConfig {
            ...on ListInputConfig {
              allowedValues
            }
          }
        }
        isRequired
      }
    }
    currentProductHash
    description
    isDeleted
    isVisible
    metaDescription
    metafields {
      key
      value
    }
    originCountry
    pageTitle
    pricing {
      compareAtPrice {
        amount
      }
      price
    }
    purchaseNotes
    productType
    publishedAt
    publishedProductHash
    shop {
      _id
    }
    slug
    socialMetadata {
      message
      service
    }
    supportedFulfillmentTypes
    tagIds
    tags {
      nodes {
        _id
        name
      }
    }
    title
    updatedAt
    vendor
    variants {
      ...ProductVariant
      options {
        ...ProductVariant
      }
    }
    variantOptions {
      inventory {
        quantity
        isEnabled
        canBackorder
      }
      sku
      pricing {
        price
        compareAtPrice
      }
      dimensions {
        width
        height
        length
        unit
      }
      weight {
        unit
        value
      }
    }
    productBrands {
      _id
      name
    }
    productCategories {
      _id
      name
    }
    relatedProducts {
      _id
      title
    }
  }
  ${ProductVariant}
`;
