import photo from '../../images/Photo.png';
import Cart from '../../icons/payments/Cart.svg';
import ApplePay from '../../icons/payments/ApplePay.svg';
import GooglePay from '../../icons/payments/GooglePay.svg';
import Paypal from '../../icons/payments/Paypal.svg';
import Stripe from '../../icons/payments/Stripe.svg';
import ShopPay from '../../icons/payments/ShopPay.svg';

export const mockProjects = [
  {
    id: '0',
    picSrc: photo,
    name: 'Edit me',
  },
  {
    id: '1',
    picSrc: photo,
    name: 'Project 2',
  },
  {
    id: '2',
    picSrc: photo,
    name: 'Project 3',
  },
  {
    id: '3',
    picSrc: photo,
    name: 'Project 4',
  },
  {
    id: '4',
    picSrc: photo,
    name: 'Project 5',
  },
  {
    id: '5',
    picSrc: photo,
    name: 'Project 6',
  },
];

export const mockPaymentMethods = [
  {
    id: 'Cart',
    picSrc: Cart,
    title: 'Debit or credit cart',
  },
  {
    id: 'ApplePay',
    picSrc: ApplePay,
  },
  {
    id: 'GooglePay',
    picSrc: GooglePay,
  },
  {
    id: 'Paypal',
    picSrc: Paypal,
  },
  {
    id: 'ShopPay',
    picSrc: ShopPay,
  },
  {
    id: 'Stripe',
    picSrc: Stripe,
  },
];
