import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useShoppersColumns = () => {
  const { t } = useTranslation();

  const columns = useMemo(() => [
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'name',
      label: t('shoppers.full_name'),
    },
    {
      name: 'email',
      label: t('shoppers.email'),
    },
    {
      name: 'phoneNumber',
      label: t('shoppers.phone_number'),
    },
    {
      name: 'status',
      label: t('shoppers.block_status'),
      sortable: false,
    },
    {
      name: 'createdAt',
      label: t('shoppers.registered_on'),
    },
    {
      name: 'options',
    }
  ], [t]);

  const columnsInvitations = useMemo(() => [
    {
      name: 'name',
      label: t('shoppers.full_name'),
      sortable: false,
    },
    {
      name: 'email',
      label: t('shoppers.email'),
    },
    {
      name: 'phoneNumber',
      label: t('shoppers.phone_number'),
    },
    {
      name: 'createdAt',
      label: t('shoppers.invited_on'),
    },
  ], [t]);

  return {
    columns,
    columnsInvitations,
  }
};
