import gql from "graphql-tag";

export const linkMediaRecordsToProductMutation = gql`
  mutation linkMediaRecordsToProduct($input: LinkMediaRecordsToProductInput!) {
    linkMediaRecordsToProduct(input: $input)
  }
`;

export const linkMediaRecordsToVariantMutation = gql`
  mutation linkMediaRecordsToVariant($input: LinkMediaRecordsToVariantInput!) {
    linkMediaRecordsToVariant(input: $input)
  }
`;
