import * as JSON_KEYS from "../constants/NameConsts";
import * as THREE from "three";
import generateUUID from "./generateUUID";
import findAllConnections from "./findAllConnections";

function getCategoriesNames(categoriesIds, streetsCategories) {
  let categoriesNames = [];
  categoriesIds.forEach((cid) => {
    streetsCategories.forEach((cat)=>{
      if (cat._id === cid) {
        categoriesNames.push(cat.name);
      }
    });
  });
  return categoriesNames;
}

export default function convertStreets(jsonData, placesOnStreets, streetsCategories) {
  let result = {};
  result[JSON_KEYS.STREETS] = [];

  let connections = [];
  let checkedStreetIndex = 0;
  let startVector = new THREE.Vector3(1, 0, 0);

  for (const jStreet of jsonData) {
    let rStreet = {};
    rStreet[JSON_KEYS.STREET_CATEGORIES] = getCategoriesNames(jStreet['categories'], streetsCategories);
    rStreet[JSON_KEYS.UUID] = jStreet["id"];
    rStreet[JSON_KEYS.NAME] = jStreet[JSON_KEYS.NAME];
    rStreet[JSON_KEYS.TYPE] = jStreet[JSON_KEYS.TYPE];
    rStreet[JSON_KEYS.BEGIN_POINT] = {
      "isBegin": true,
      "coords": [
        0,
        0,
        0,
      ],
      "uuid": generateUUID(),
    };

    if (checkedStreetIndex === 0) {
      rStreet[JSON_KEYS.END_POINT] = {
        "isBegin": false,
        "coords": [
          startVector.x,
          startVector.y,
          startVector.z,
        ],
        "uuid": generateUUID(),
      };
      connections.push({
        "streetUuids": [rStreet[JSON_KEYS.UUID]],
        "pointUuids": [rStreet[JSON_KEYS.END_POINT][JSON_KEYS.UUID]],
        "uuid": generateUUID()
      });
    }
    checkedStreetIndex += 1;

    rStreet[JSON_KEYS.HOUSES] = [];
    let index = 0;
    for (const jPlace of jStreet["places"]) {
      let rHouse = {};
      rHouse["id"] = index;
      index += 1;
      rHouse[JSON_KEYS.UUID] = jPlace["id"];
      if (jPlace["holder"] === null) {
        rHouse[JSON_KEYS.HOUSE_HOLDER_ID] = null;
        rHouse[JSON_KEYS.HOUSE_TYPE] = "HOUSE-IS-FREE";
      } else {
        rHouse[JSON_KEYS.HOUSE_HOLDER_ID] = jPlace["holder"][JSON_KEYS.HOUSE_HOLDER_ID];
        rHouse[JSON_KEYS.HOUSE_TYPE] = "HOUSE-IS-TAKEN";
      }
      rStreet[JSON_KEYS.HOUSES].push(rHouse);
    }

    const foundStreet = placesOnStreets.find(item => item.street.id === jStreet.id);
    if (foundStreet) {
      rStreet[JSON_KEYS.SELECTED_PLACES] = foundStreet.places.map(place => place.id);
    }

    result[JSON_KEYS.STREETS].push(rStreet);
  }

  findAllConnections(connections, result[JSON_KEYS.STREETS], jsonData, jsonData[0]["id"]);

  result[JSON_KEYS.CONNECTIONS] = connections;

  return result;
}
