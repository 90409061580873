import { useContext, useEffect, useState } from "react";
import { getAllPackages } from "../../subscription-packages/graphql/queries";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { ShopEditContext } from "../../../package/src/context/ShopEditContext";
import { useTranslation } from "react-i18next";

const useGetPackages = (companyId, godmode) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const {
    showOnlyPackageId,
    packageId,
    setPackages,
    quiz: { experience },
  } = useContext(ShopEditContext);

  useEffect(() => {
    if (!companyId) {
      return;
    }

    setLoading(true);

    apolloClient.query({
      query: getAllPackages,
      fetchPolicy: "network-only",
      ...(experience === '3d' ? {
        variables: {
          filters: {
            allowsRooms: true,
          },
        }
      } : {}),
    })
      .then(res => {
        const allPackages = res.data.subscriptionPackages.nodes;

        setPackages(
          allPackages
            .filter(item => {
              const filter1 = godmode || item.isVisibleToMerchants;

              let filter2 = true;

              if (showOnlyPackageId) {
                filter2 = item._id === packageId || item._id === showOnlyPackageId;
              }

              return filter1 && filter2;
            })
            .sort((a, b) => a.baseRate - b.baseRate)
            .map(item => ({
              id: item._id,
              title: item.title,
              currency: item.currency === 'USD-id' ? '$' : '', // todo
              subtitle: 'Subtitle', // todo
              baseRate: item.baseRate,
              duration: item.duration,
              isActive: item._id === packageId,
              description: item.description,
            }))
        );
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setLoading(false))
  }, [companyId, packageId, showOnlyPackageId, experience]);

  return {
    loading,
  };
};

export default useGetPackages;
