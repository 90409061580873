import React from "react";
import styled, {css} from 'styled-components';
import svgClose from '../../../icons/table-close.svg';
import { IconButton } from "@material-ui/core";
import capitalizeString from "@reactioncommerce/api-utils/lib/capitalizeString";
import getCardImg from "../../../package/src/utils/getCardImg";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  width: 100%;
  background: ${props => props.whiteBackground ? '#FFFFFF' : '#F6F6F6'};
  border-radius: 20px;
  border: 2px solid #F6F6F6;
  padding: 18px 22px 14px 22px;
  margin-bottom: 18px;
  transition: .2s;
  cursor: pointer;
  
  .cardFirstRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    
    p {
      font-family: 'Mulish', sans-serif;
      font-weight: 400;
      font-size: 15px;
      color: #222222;
      margin: 0;
      
      &.cardDefault {
        color: #BFBFBF;
      }
    }
  }
  
  .cardSecondRow {
    width: 100%;
    display: flex;
    align-items: center;
    
    > img {
      max-width: 50px;
      margin-right: 16px;
    }
    
    > p {
      flex-grow: 1;
      font-family: 'Mulish', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #222222;
      margin: 0;
      user-select: none;
    }
    
    .cardBrandText {
      max-width: 50px;
      word-break: break-all;
    }
    
    > button {
      width: 50px;
      margin-right: -10px;
    }
  }
  
  &:hover {
    border: 2px solid #2A55DE;
    background: #EFF2FB;
  }
  
  @media all and (max-width: 768px) {
    border-radius: 16px;
    padding: 14px 18px 10px 18px;
    margin-bottom: 16px;

    .cardFirstRow {
      margin-bottom: 12px;
      
      p {
        font-size: 14px;
      }
    }

    .cardSecondRow {
      > img {
        max-width: 40px;
        margin-right: 16px;
      }

      > p {
        font-size: 15px;
      }
    }
  }
  
  ${props => props.isSelected && css`
    border: 2px solid #4270FF;
    background: #F3F6FF;
  `}
`

const BillingCard = ({
  isDefault,
  brand,
  cardNumber,
  handleRemoveCard,
  handleClick,
  isSelected,
  whiteBackground
}) => {
  const { t } = useTranslation();

  const onRemove = (e) => {
    e.stopPropagation();
    handleRemoveCard();
  };

  const img = getCardImg(brand);

  return (
    <Wrapper
      onClick={handleClick}
      isSelected={isSelected}
      whiteBackground={whiteBackground}
    >
      <div className='cardFirstRow'>
        <p>
          {capitalizeString(brand, { titleCase: true })}
        </p>
        {
          isDefault && <p className='cardDefault'>{t('settings.default')}</p>
        }
      </div>
      <div className='cardSecondRow'>
        {
          img
          ? <img src={img} alt='Card logo'/>
          : null
        }
        <p>**** **** **** {cardNumber}</p>
        {
          handleRemoveCard
          ? <IconButton
              onClick={onRemove}
            >
              <img src={svgClose} alt='remove'/>
            </IconButton>
            : null
        }
      </div>
    </Wrapper>
  );
};

export default BillingCard;
