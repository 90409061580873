import { constants } from "../../../../constants";
import Status from "../../../../UI/Status";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useModifiers = () => {
  const { t } = useTranslation();

  const modifyData = useCallback(({output, groups}) => {
    const data = [];

    output.map(account => {
      const accountGroups = [];

      account.groups.nodes.map(item => {
        if (groups.map(group => group._id).includes(item._id)) {
          const groupName = item?.slug.includes("godmode")
            ? `${constants.APP_NAME} ${item?.name}`
            : item?.name || "";
          const shopName = item?.shop?.name || "";

          accountGroups.push({
            groupName,
            shopName,
          });
        }
      });

      accountGroups.map((group, index) => {
        const statusText = account.disabled ? t('account.suspended') : t('account.enabled');
        const statusType = account.disabled ? 'Suspended' : 'Enabled';
        data.push({
          ...group,
          email: account.emailRecords[0].address,
          phoneNumber: account.phoneNumber,
          name: account.name,
          accountId: account._id,
          id: account._id + index,
          createdAt: account.createdAt,
          status: <Status
            content={statusText}
            type={statusType}
          />,
          modifyOptions: (list) => {
            const valueToHide = account.disabled ? constants.OPTIONS.suspend : constants.OPTIONS.enable;
            return list.filter(option => option.value !== valueToHide);
          }
        });
      });
    });
    return data;
  }, [t]);

  return { modifyData };
};

export default useModifiers;
