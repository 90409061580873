import React, { useCallback, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid, Hidden, makeStyles } from "@material-ui/core";
import Search from "../../../UI/Search";
import StyledButton from "../../../UI/Button";
import DataTable from "../../../UI/DataTable/DataTable";
import Dialog from "../../../UI/Dialog/Dialog";
import { useSnackbar } from "notistack";
import { useApolloClient } from "@apollo/react-hooks";
import svgEdit from "../../../icons/table-edit.svg";
import svgDelete from '../../../icons/delete.svg';
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import { productCategories } from "../graphql/queries";
import IconButtons from "../../../UI/IconButtons";
import { addProductCategory, removeProductCategory, updateProductCategory } from "../graphql/mutations";
import moment from 'moment';
import DialogCreateEditCategory from '../components/FormCategories';
import { useTranslation } from "react-i18next";
import { constants } from "../../../constants";
import useProductCategoriesColumns from "../hooks/useProductCategoriesColumns";

const useStyles = makeStyles(theme => ({
  secondColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
  },
  secondColumnXS: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    margin: '28px 0',
  },
}));

const ProductCategoriesPage = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowId, setRowId] = useState('');
  const [tableData, setTableData] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const columns = useProductCategoriesColumns();

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
      setIsLoading(true);

      apolloClient.query({
        query: productCategories,
        variables: {
          productCategoryIds: [],
          filter: globalFilter,
          first: pageSize,
          limit: (pageIndex + 1) * pageSize,
          offset: pageIndex * pageSize,
          sortOrder,
          sortBy,
        },
        fetchPolicy: "network-only",
      })
        .then(res => {
          // Update the state with the fetched data as an array of objects and the calculated page count
          const total = res.data.productCategories.totalCount;
          setTableData(res.data.productCategories.nodes);
          setPageCount(Math.ceil(total / pageSize));
          setTotalCount(total);
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setIsLoading(false))
    }, [t]);

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const createCategory = (name) => {
    return apolloClient.mutate({
      mutation: addProductCategory,
      variables: {
        name,
      },
    });
  }

  const editCategory = (id, name) => {
    return apolloClient.mutate({
      mutation: updateProductCategory,
      variables: {
        id,
        input: {
          name,
        }
      },
    });
  }

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const categoriesOptions = useMemo(() => [
    {
      value: constants.OPTIONS.editCategory,
      label: t('product.edit_category'),
      icon: svgEdit,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('edit');
        setDialogOpen(true);
      },
    },
    {
      value: constants.OPTIONS.deleteCategory,
      label: t('product.delete_category'),
      icon: svgDelete,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('deleteSingleCategory');
        setDialogOpen(true);
      },
    },
  ], [t])

  const deleteCategory = useCallback(async (catId) => {
    return await apolloClient.mutate({
      mutation: removeProductCategory,
      variables: {
        id: catId,
      },
    });
  }, [])

  const renderNoButton = useMemo(() =>
      <StyledButton
        width='180px'
        mytype='secondary'
        handleClick={() => setDialogOpen(false)}
      >
        {t('ui.no')}
      </StyledButton>
    , [t])

  const DialogDeleteSingle = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('product.delete_category_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              setDialogOpen(false);
              deleteCategory(rowId)
                .then(res => {
                  if (res) {
                    refetch();
                    enqueueSnackbar(t('product.delete_category_success'), {variant: 'success'});
                  }
                })
                .catch((e) => {
                  enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                  console.log(e.message);
                })
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [rowId, t])

  const DialogDelete = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('product.delete_categories_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={async () => {
              setDialogOpen(false);
              const successes = [];

              try {
                for (const rowId of selectedRows) {
                  const {data} = await deleteCategory(rowId);
                  if (data) successes.push(data);
                }
              } catch(e) {
                enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                console.log(e.message);
              }

              if (successes.length) {
                refetch();
                enqueueSnackbar(t('product.delete_categories_success'), {variant: 'success'});
              }
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    );
  }, [selectedRows]);

  const confirmAction = (page, id, name) => {
    setIsLoading(true);
    setDialogOpen(false);
    if (page === 'create') {
      createCategory(name)
        .then(() => refetch())
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setIsLoading(false))
    } else if (page === 'edit') {
      editCategory(id, name)
        .then(() => refetch())
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setIsLoading(false))
    }
  }

  const dataProps = useMemo(() => tableData.map(item => ({
      id: item._id,
      name: item.name,
      createdBy: item.createdBy.name,
      email: item.createdBy.primaryEmailAddress,
      createdAt: moment(item.createdAt).format('DD.MM.YYYY'),
      options: categoriesOptions,
    })
  ), [tableData]);

  const handleCreateCategory = () => {
    setDialogName('create');
    setDialogOpen(true);
  }

  const handleDeleteCategories = () => {
    setDialogName('deleteCategories');
    setDialogOpen(true);
  }

  const renderSecondColumn = (myClass) => {
    return <Grid item xs={12} lg={5} className={classes[myClass]}>
      <IconButtons
        handleClick={handleDeleteCategories}
        disabled={selectedRows.length === 0}
      />
      <StyledButton
        onClick={handleCreateCategory}
      >
        {t('product.add_product_category')}
      </StyledButton>
    </Grid>
  }

  const getCategoryName = (id) => tableData.find(item => item._id === id)?.name || "";

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'deleteSingleCategory': return DialogDeleteSingle;
      case 'deleteCategories': return DialogDelete;
      case 'edit':
      case 'create': return <DialogCreateEditCategory
        page={dialogName}
        catId={rowId}
        name={getCategoryName(rowId)}
        declineAction={() => setDialogOpen(false)}
        confirmAction={confirmAction}
      />;
      default: return null;
    }
  }

  return (
    <>
      <Grid container>
        <Toolbar title={t('product.product_categories')} />

        <Grid item xs={12} lg={7}>
          <Search
            handleChange={handleSearch}
            onSearchClick={refetch}
            placeholder={t('ui.search')}
            useDebounce
          />
        </Grid>

        <Hidden mdDown>
          {renderSecondColumn('secondColumn')}
        </Hidden>

        <Hidden lgUp>
          {renderSecondColumn('secondColumnXS')}
        </Hidden>

        <DataTable
          columns={columns}
          isLoading={isLoading}
          data={dataProps}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          handleFetchData={onFetchData}
          pageCount={pageCount}
          totalCount={totalCount}
          searchValue={searchValue}
          reloadTable={reloadTable}
          defaultSortField='createdAt'
          defaultSortOrder='desc'

          // styles for 1 row upper panel
          maxHeight='calc(100vh - 280px)'
          maxHeightLaptop='calc(100vh - 380px)'
          maxHeightMobile='calc(100vh - 360px)'
        />

      </Grid>

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>

  );
}

export default ProductCategoriesPage;
