import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import { SelectAllButton, StreetMapBlackText, StreetMapGreyText, StreetMapRowBox } from "./styles";
import HousesField from "./HousesField";

const Wrapper = styled('div')`
  width: 100%;
  padding: 11px 32px 7px;
  background: ${props => props.isSelected ? '#F3F6FF' : 'white'};
  
  &:hover {
    background: ${colors.white8};
  }
  
  ${props => props.disabled && css`
    background: white;
  
    &:hover {
      background: white;
    }
  `}
`;

const StreetMapTableRow = ({
  isSelected,
  name,
  houseQuantity,
  isDetailed,
  handleClick,
  handleSelectAllHouses,
  isAllHousesSelected,
}) => {
  const { t } = useTranslation();

  const disabled = isDetailed && houseQuantity < 1;

  const handleClickSelect = () => {
    if (disabled) {
      return;
    }
    handleClick();
  }

  return (
    <Wrapper
      isSelected={isSelected}
      onClick={handleClickSelect}
      disabled={disabled}
    >
      <StreetMapRowBox>
        <StreetMapGreyText>{t('street.chosen_street')}</StreetMapGreyText>
        {
          isDetailed ?
            <StreetMapGreyText>{t('street.availability')}</StreetMapGreyText> :
            <SelectAllButton
              onClick={() => handleSelectAllHouses(!isAllHousesSelected)}
              isAllHousesSelected={isAllHousesSelected}
            >
              {isAllHousesSelected ? t('street.deselect_all') : t('street.select_all')}
            </SelectAllButton>
        }
      </StreetMapRowBox>
      <StreetMapRowBox>
        <StreetMapBlackText disabled={disabled}>{name}</StreetMapBlackText>
        {
          isDetailed &&
          <HousesField num={houseQuantity}/>
        }
      </StreetMapRowBox>
    </Wrapper>
  );
}

export default StreetMapTableRow;
