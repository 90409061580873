import React, { useCallback, useContext, useMemo, useState } from "react";
import { Hidden, makeStyles, Grid } from "@material-ui/core";
import { standardPageStyles } from "../../../constants";
import Toolbar from "../../../package/src/Toolbar";
import Search from "../../../UI/Search";
import StyledButton from "../../../UI/Button";
import IconButtons from "../../../UI/IconButtons";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import DataTable from "../../../UI/DataTable/DataTable";
import { mediaRecordsQuery } from "../graphql/queries";
import Dialog from "../../../UI/Dialog/Dialog";
import { deleteMediaRecordMutation } from "../graphql/mutations";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import { modifyData } from "../modifiers";
import MediaGalleryUploader from "../components/MediaGalleryUploader";
import { LanguageContext } from "../../../package/src/context/LanguageContext";
import { useTranslation } from "react-i18next";
import { useMediaColumns } from "../hooks/useMediaColumns";

const useStyles = makeStyles(theme => ({
  ...standardPageStyles,
  deleteAndAdd: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  }
}));

const MediaGalleryPage = ({selectedShopIds: shopIds}) => {
  const classes = useStyles();
  const { isRtl } = useContext(LanguageContext);
  const rtl = isRtl ? 'Rtl' : '';

  const [searchValue, setSearchValue] = useState('');
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [rowId, setRowId] = useState('');
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const columns = useMediaColumns();

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
      setIsLoading(true);
      apolloClient.query({
        query: mediaRecordsQuery,
        variables: {
          shopId: shopIds[0],
          filter: globalFilter,
          first: pageSize,
          limit: (pageIndex + 1) * pageSize,
          offset: pageIndex * pageSize,
          sortOrder,
          sortBy,
        },
        fetchPolicy: "network-only",
      })
        .then(res => {
          // Update the state with the fetched data as an array of objects and the calculated page count
          const total = res.data.mediaRecords.totalCount;
          setTableData(res.data.mediaRecords.nodes);
          setPageCount(Math.ceil(total / pageSize));
          setTotalCount(total);
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setIsLoading(false))
    }, [shopIds]);

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const mediaOptions = useMemo(() => ({
    buttonType: 'deleteSimple',
    handleOptionClick: (rowId) => {
      setRowId(rowId);
      setDialogName('deleteSingle');
      setDialogOpen(true);
    }
  }), [])

  const dataProps = useMemo(() => {
    return modifyData(tableData, '', mediaOptions);
  }, [tableData, modifyData])

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: mediaRecordsQuery,
      variables: {
        shopId: shopIds[0],
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyData(res.data.mediaRecords.nodes, 'export');
        const fields = columns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [shopIds, modifyData]);

  const handleDeleteRecords = () => {
    setDialogName('delete');
    setDialogOpen(true);
  }

  const renderColumn = (myClass) => {
    return <Grid item xs={12} lg={5} className={classes[myClass]}>
      <StyledButton
        width='140px'
        mytype='secondary'
        handleClick={exportData}
        disabled={exporting || !tableData.length}
      >
        {t('ui.export')}
      </StyledButton>
      <div className={classes.deleteAndAdd}>
        <IconButtons
          handleClick={handleDeleteRecords}
          disabled={selectedRows.length === 0}
        />
        <MediaGalleryUploader
          shopId={shopIds[0]}
          onSuccess={refetch}
        />
      </div>

    </Grid>
  }

  const renderNoButton = useMemo(() =>
      <StyledButton
        width='180px'
        mytype='secondary'
        handleClick={() => setDialogOpen(false)}
      >
        {t('ui.no')}
      </StyledButton>
    , [])

  const deleteRecord = (recordId) => {
    return apolloClient.mutate({
      mutation: deleteMediaRecordMutation,
      variables: {
        input: {
          mediaRecordId: recordId,
          shopId: shopIds[0],
        },
      },
    });
  }

  const DialogDeleteSingle = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={t('media.delete_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              setDialogOpen(false);
              deleteRecord(rowId)
                .then(res => {
                  if (res) {
                    refetch();
                    enqueueSnackbar(t('media.delete_success'), {variant: 'success'});
                  }
                })
                .catch((e) => {
                  enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                  console.log(e.message);
                })
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [rowId])

  const DialogDelete = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={t('media.delete_q_many', { count: selectedRows.length })}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={async () => {
              setDialogOpen(false);
              const successes = [];

              try {
                for (const recordId of selectedRows) {
                  const { data } = await deleteRecord(recordId);
                  if (data) successes.push(data);
                }
              } catch(e) {
                enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                console.log(e.message);
              }

              if (successes.length) {
                refetch();
                enqueueSnackbar(
                  successes.length > 1 ?
                    t('media.delete_success_many', { count: successes.length })
                  : t('media.delete_success'),
                  {variant: 'success'}
                );
              }
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    );
  }, [selectedRows]);

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'delete': return DialogDelete;
      case 'deleteSingle': return DialogDeleteSingle;
      default: return null;
    }
  }

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Toolbar title={t('media.media_gallery')} />

        {
          shopIds.length === 1 && <>
            <Hidden mdDown>
              <Grid container className={classes.grid}>
                <Grid item lg={7} className={classes.gridEmpty}/>
                {renderColumn(`secondColumn${rtl}`)}
              </Grid>
            </Hidden>

            <Hidden lgUp>
              {renderColumn('secondColumnXS')}
            </Hidden>

            <div className={classes.secondRow}>
              <Search
                handleChange={handleSearch}
                onSearchClick={refetch}
                placeholder={t('ui.search')}
                useDebounce
              />
            </div>
          </>
        }

        <Grid item sm={12}>
          {shopIds.length !== 1 ? (
            <span className={classes.selectSomeShops}>{t('media.select_one_shop')}</span>
          ) :
            <DataTable
              columns={columns}
              isLoading={isLoading}
              data={dataProps}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              handleFetchData={onFetchData}
              pageCount={pageCount}
              totalCount={totalCount}
              searchValue={searchValue}
              reloadTable={reloadTable}
              defaultSortField='uploadedAt'
              defaultSortOrder='desc'

              // styles for 2 rows upper panel + checkboxes
              maxHeight='calc(100vh - 390px)'
              maxHeightLaptop='calc(100vh - 460px)'
              maxHeightMobile='calc(100vh - 440px)'
            />
          }
        </Grid>
      </Grid>

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>
  );
}

export default MediaGalleryPage;
