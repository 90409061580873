import Status from "../../../UI/Status";
import moment from "moment/moment";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useModifiers = () => {
  const { t } = useTranslation();

  const modifyData = useCallback((data, method, options) => {
    return data.map(item => {
      const isExport = method === 'export';
      const statusText = item.isVisible ? t('tags.visible') : t('tags.invisible');
      const statusType = item.isVisible ? 'visible' : 'invisible';

      const result = {
        id: item._id,
        tagName: item.displayTitle,
        slug: item.slug,
        shop: item.shop.name,
        isVisible: isExport ? statusText : <Status
          content={statusText}
          type={statusType}
        />,
        createdAt: moment(item.createdAt).format("MM.DD.YYYY"),
      }

      // if table
      if (!isExport && options) {
        result.options = options;
      }

      // if 'export' button
      result.__typename = item.__typename;
      return result;
    });
  }, [t]);

  return {
    modifyData,
  }
};

export default useModifiers;
