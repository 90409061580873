import React, { useCallback, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid, Hidden, makeStyles } from "@material-ui/core";
import StyledButton from "../../../UI/Button";
import Search from "../../../UI/Search";
import DataTable from "../../../UI/DataTable";
import svgEdit from "../../../icons/table-edit.svg";
import svgPercent from '../../../icons/percent.svg';
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import { getAllPackages } from "../graphql/queries";
import Dialog from "../../../UI/Dialog/Dialog";
import FormSubscriptionOverride from "../components/FormSubscriptionOverride";
import { useTranslation } from "react-i18next";
import { useSubscriptionColumns } from "../hooks/useSubscriptionColumns";
import { constants } from "../../../constants";
import useModifiers from "../hooks/useModifiers";

const useStyles = makeStyles(theme => ({
  columnLeft: {
    display: 'flex',
    gap: 20,
  },
  flexEnd: {
    display: 'flex',
    gap: 20,
    justifyContent: 'flex-end',
  },
  flexStart: {
    display: 'flex',
    gap: 20,
  },
}));

const SubscriptionPackagesPage = ({ history }) => {
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [rowId, setRowId] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  const columns = useSubscriptionColumns();
  const { modifyData } = useModifiers();

  const rowOptions = useMemo(() => [
    {
      value: constants.OPTIONS.editPackage,
      label: t('subscription.edit_package'),
      icon: svgEdit,
      handleOptionClick: (rowId) => {
        history.push(`/subscription-packages/edit/${rowId}`);
      },
    },
    {
      value: constants.OPTIONS.editOverrides,
      label: t('subscription.edit_overrides'),
      icon: svgPercent,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogOpen(true);
      },
    },
  ], [t]);

  const dataProps = useMemo(() => {
    return modifyData(tableData, '', rowOptions);
  }, [tableData, modifyData])

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
    setIsLoading(true);
    apolloClient.query({
      query: getAllPackages,
      variables: {
        filter: globalFilter,
        first: pageSize,
        //limit: (pageIndex + 1) * pageSize,
        offset: pageIndex * pageSize,
        sortOrder,
        sortBy,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        // Update the state with the fetched data as an array of objects and the calculated page count
        const total = res.data.subscriptionPackages.totalCount;
        setTableData(res.data.subscriptionPackages.nodes);
        setPageCount(Math.ceil(total / pageSize));
        setTotalCount(total);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [t]);

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: getAllPackages,
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyData(res.data.subscriptionPackages.nodes, 'export', null);
        const fields = columns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [t, modifyData]);

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const handleAddSub = () => {
    history.push('/subscription-packages/create');
  }

  const renderRightColumn = (myClass) => {
    return <Grid item xs={12} lg={5} className={classes[myClass]}>
      <StyledButton
        minwidth='250px'
        width='250px'
        handleClick={handleAddSub}
      >
        {t('subscription.add_subscription_package')}
      </StyledButton>
    </Grid>
  }

  return (
    <>
      <Grid container spacing={2}>
        <Toolbar title={t('subscription.subscription_packages')} />

        <Grid item xs={12} lg={7} className={classes.columnLeft}>
          <Search
            handleChange={handleSearch}
            onSearchClick={refetch}
            placeholder={t('ui.search')}
            useDebounce
          />

          <StyledButton
            width='140px'
            mytype='secondary'
            handleClick={exportData}
            disabled={exporting || !tableData.length}
          >
            {t('ui.export')}
          </StyledButton>
        </Grid>

        <Hidden lgUp>
          {renderRightColumn('flexStart')}
        </Hidden>

        <Hidden mdDown>
          {renderRightColumn('flexEnd')}
        </Hidden>

        <DataTable
          columns={columns}
          isLoading={isLoading}
          data={dataProps}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          handleFetchData={onFetchData}
          pageCount={pageCount}
          totalCount={totalCount}
          searchValue={searchValue}
          reloadTable={reloadTable}
          defaultSortField='createdAt'
          defaultSortOrder='desc'
          showCheckboxes={false}

          // styles for 1 row upper panel
          maxHeight='calc(100vh - 280px)'
          maxHeightLaptop='calc(100vh - 380px)'
          maxHeightMobile='calc(100vh - 360px)'
        />
      </Grid>

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        <FormSubscriptionOverride
          id={rowId}
        />
      </Dialog>
    </>
  );
};

export default SubscriptionPackagesPage;
