import Settings from '../settings/pages/Settings';

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute }) {
  // Register a route
  registerRoute({
    group: "settings",
    path: "/settings",
    MainComponent: Settings,
  });
}
