import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import City from "./City";
import triggerScene from "../utils/triggerScene";
import { STREET_VIEW_TYPE, mapSceneBaseSettings, HOUSES_VIEW_TYPE, HOUSE_HEIGHT } from '../constants/constants';

export default class MapScene extends THREE.EventDispatcher {
  width;
  height;
  raycaster;
  pointer;
  renderer;
  camera;
  scene;
  sceneStreets; // THREE.Scene
  sceneHouses; // THREE.Scene
  controls;
  viewType;
  city;
  currentShopId;
  temporaryHighlightObject;
  isPackageMode;
  isMouseInsideScene;
  placesOnStreets;
  showStreets;

  constructor(canvasRef, isPackageMode) {
    super();
    let contenedor = document.getElementById("mapContainerId");
    this.width = contenedor ? (contenedor.clientWidth) : 800;
    this.height = contenedor ? (contenedor.clientHeight) : 600;
    this.pointer = new THREE.Vector2(1, 1);
    this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas: canvasRef });
    this.raycaster = new THREE.Raycaster();
    this.sceneStreets = new THREE.Scene();
    this.sceneHouses = new THREE.Scene();
    this.scene = this.sceneStreets;
    let aspect = this.width / this.height;
    this.isMouseInsideScene = true;
    let frustumSize = 2;
    this.camera = new THREE.OrthographicCamera(
      frustumSize * aspect / -2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / -2, 0.1, 10);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.sceneStreets.background = new THREE.Color(mapSceneBaseSettings.backgroundColor);
    this.sceneHouses.background = new THREE.Color(mapSceneBaseSettings.backgroundColor);
    this.axes = new THREE.AxesHelper(mapSceneBaseSettings.axesHelperSize);

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.sceneStreets.add(this.axes);

    this.axes.visible = false;

    this.setCameraAndControls();

    this.city = null;
    this.viewType = STREET_VIEW_TYPE;
    this.temporaryHighlightObject = null;
    this.isPackageMode = isPackageMode;

    this.update();
  }

  clearMemory() {
    if (!this.scene) {
      return;
    }

    while(this.sceneStreets.children.length > 0){
      this.sceneStreets.remove(this.sceneStreets.children[0]);
    }
    while(this.sceneHouses.children.length > 0){
      this.sceneHouses.remove(this.sceneHouses.children[0]);
    }
    // this.sceneStreets = null;
    // this.sceneHouses = null;
    // this.renderer = null;
  }

  loadCity(data, shopId, placesOnStreets, subscriptionAvailableStreets, locales) {
    this.currentShopId = shopId;
    this.placesOnStreets = placesOnStreets;
    this.city = new City(
      data,
      this.sceneStreets,
      this.sceneHouses,
      this.isPackageMode,
      this.placesOnStreets,
      subscriptionAvailableStreets,
      locales
    );
    this.city.currentShopId = shopId;
  }

  createTestPlane() {
    const geometry = new THREE.PlaneGeometry(1, 1);
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00, side: THREE.DoubleSide });
    const plane = new THREE.Mesh(geometry, material);
    this.scene.add(plane);

    const geometry2 = new THREE.PlaneGeometry(1, 1);
    const material2 = new THREE.MeshBasicMaterial({ color: 0xff0000, side: THREE.DoubleSide });
    const plane2 = new THREE.Mesh(geometry2, material2);
    plane2.position.set(1, 1, 0);
    this.scene.add(plane2);
  }

  setCameraAndControls() {
    this.controls.dampingFactor = mapSceneBaseSettings.controlsDampingFactor;
    this.controls.minDistance = mapSceneBaseSettings.controlsMinDistance;
    this.controls.maxDistance = mapSceneBaseSettings.controlsMaxDistance;
    this.controls.minPolarAngle = mapSceneBaseSettings.controlsMaxPolarAngle;
    this.controls.maxPolarAngle = mapSceneBaseSettings.controlsMaxPolarAngle;
    this.controls.minZoom = mapSceneBaseSettings.minZoom;
    this.controls.maxZoom = mapSceneBaseSettings.maxZoom;
    this.controls.enablePan = true;
    this.controls.target.set(0, 0, 0);
    this.controls.minAzimuthAngle = 0;
    this.controls.maxAzimuthAngle = 0;
    this.camera.up.set(0, 0, -1);
    // let dist = 2 / Math.tan(Math.PI * 90 / 360);
    this.camera.position.set(0, 0, 1);
  }

  update() {
    if (this.renderer){
      this.camera.updateMatrixWorld();
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.update.bind(this));
    }
  }

  onWindowResize(w, h) {
    let contenedor = document.getElementById("mapContainerId");
    if (contenedor) {
      this.renderer.setSize(contenedor.clientWidth, contenedor.clientHeight);
      let aspect = contenedor.clientWidth / contenedor.clientHeight;
      const frustumSize = (this.viewType === HOUSES_VIEW_TYPE) ? 3 : 1;
      this.camera.left = (frustumSize * aspect) / -1;
      this.camera.right = (frustumSize * aspect);
      this.camera.top = frustumSize;
      this.camera.bottom = frustumSize / -1;
      // this.camera.aspect = aspect;
      this.camera.updateProjectionMatrix();
    }
  }

  getHouseTopPositionInMouseCoordinates(house) {
    const canvas = this.renderer.domElement;
    // const divRect = canvas.getBoundingClientRect();
    let vector = house.object.position.clone();
    vector.y += HOUSE_HEIGHT/2;

    vector.project(this.camera);

    // addition for test
    // console.log("offsets", canvas.offsetLeft, canvas.offsetTop);
    // console.log("left-top", divRect.left, divRect.top);
    // console.log("pixelRatio", window.devicePixelRatio);

    vector.x = ( vector.x + 1) * canvas.clientWidth / 2 + canvas.offsetLeft;
    vector.y = - ( vector.y - 1) * canvas.clientHeight / 2 + canvas.offsetTop;
    vector.z = 0;

    // console.log("vector", vector.x, vector.y)

    return vector;
  }

  onPointerMove(event) {
    let container = document.getElementById("mapContainerId");
    const divRect = container.getBoundingClientRect();
    this.isMouseInsideScene = (event.clientX >= divRect.left && event.clientX <= divRect.right &&
      event.clientY >= divRect.top && event.clientY <= divRect.bottom);
    if (!this.isMouseInsideScene){
      return;
    }

    if (container) {
      this.pointer.x = (event.clientX - this.renderer.domElement.offsetLeft) /
        this.renderer.domElement.clientWidth *
        2 -
        1;
      this.pointer.y = -(
          (event.clientY - this.renderer.domElement.offsetTop) /
          this.renderer.domElement.clientHeight
        ) *
        2 +
        1;

      this.raycaster.setFromCamera(this.pointer, this.camera);

      if (!this.city) {
        return;
      }

      const intersectsHouses = this.raycaster.intersectObjects(this.city.houses3d, false);

      if (this.viewType === HOUSES_VIEW_TYPE) {
        if ((intersectsHouses.length > 0) && (intersectsHouses[0].object.visible)) {
          // есть пересечение дома с курсором, и он визибл
          // const isSelected = this.city.isHouseSelected(intersectsHouses[0].object.userData.houseId);

          if (this.isPackageMode) {
            const houseMousePos = this.getHouseTopPositionInMouseCoordinates(intersectsHouses[0])
            // если мы в модуле subscription, то открыть tooltip с данными подписки
            triggerScene('handlePlaceToolTip', {
              isOpen: true,
              placeId: intersectsHouses[0].object.userData.houseId,
              mousePosition: houseMousePos,
            });
          }
          if (this.temporaryHighlightObject !== null) {
            // хайлайт уже есть
            if (this.temporaryHighlightObject.uuid === intersectsHouses[0].object.uuid) {
              // id хайлайта совпадает с id наведенного дома - ничего не делать
              return;
            }
            // id хайлайта НЕ совпадает с id наведенного дома - сбросить хайлайт
            this.temporaryHighlightObject.material.color.set(0xffffff);
          }
          // выполняется, если хайлайта нет, либо он есть, но не совпадает c id наведенного дома
          this.temporaryHighlightObject = intersectsHouses[0].object;
          this.temporaryHighlightObject.material.color.set(0xAAAAAA);
        } else {
          // нет пересечения дома с курсором - сбросить хайлайт
          if (this.temporaryHighlightObject) {
            this.temporaryHighlightObject.material.color.set(0xffffff);
          }
          this.temporaryHighlightObject = null;

          // закрыть тултип подписки
          if (this.isPackageMode) {
            triggerScene('handlePlaceToolTip', { isOpen: false });
          }
        }
      }
    }

  }

  onClick() {
    if (!this.isMouseInsideScene) {
      return;
    }
    this.camera.updateMatrixWorld();
    this.raycaster.setFromCamera(this.pointer, this.camera);

    if (this.city == null) {
      return;
    }

    const intersectsStreets = this.raycaster.intersectObjects(this.city.streets3d, false);
    const intersectsHouses = this.raycaster.intersectObjects(this.city.houses3d, false);

    if (this.viewType === STREET_VIEW_TYPE) {
      if ((intersectsStreets.length > 0) && (intersectsStreets[0].object.visible)) {
        const [oldSelectedId, selectedStreetId] = this.city.onStreetClick(intersectsStreets[0].object);
        if (selectedStreetId === oldSelectedId && selectedStreetId){
          triggerScene("selectedStreetDoubleClicked", { selectedStreetId: selectedStreetId });
        } else {
          triggerScene("selectedStreetIdChanged", { selectedStreetId: selectedStreetId });
        }
      }
    }

    if (this.viewType === HOUSES_VIEW_TYPE) {
      if ((intersectsHouses.length > 0) && (intersectsHouses[0].object.visible)) {
        this.city.onHouseClick(intersectsHouses[0].object);
      }
    }

  }

  updateHousesView(placement) {
    this.city.updateCurrentHolderPlacement(placement);
  }

  toggleView(placesNumbers) {
    if (this.viewType === HOUSES_VIEW_TYPE) {
      this.goOutSide();
    } else {
      this.goInside(placesNumbers);
    }
  }

  moveCameraToCenter() {
    let contenedor = document.getElementById("mapContainerId");
    let aspect = contenedor.clientWidth / contenedor.clientHeight;
    const center = this.city.getCenter();
    this.scene = this.sceneHouses;
    this.viewType = HOUSES_VIEW_TYPE;
    this.controls.target.set(center.x, 0, 0);
    this.camera.zoom = 0.55;
    this.camera.position.set(center.x, 0, 10);
    this.camera.bottom = -3;
    this.camera.top = 3;
    this.camera.right = 3 * aspect;
    this.camera.left = -3 * aspect;
    this.camera.updateProjectionMatrix();
    this.camera.updateMatrix();
  }

  goInside(placesNumbers) {
    if (this.city === null) {
      return;
    }
    if (this.city.getSelectedStreet() === null) {
      return;
    }
    this.city.updateHousesBySelectedStreet(placesNumbers);
    this.moveCameraToCenter();
  }

  goOutSide() {
    this.scene = this.sceneStreets;
    this.viewType = STREET_VIEW_TYPE;

    let contenedor = document.getElementById("mapContainerId");
    let aspect = contenedor.clientWidth / contenedor.clientHeight;
    this.camera.zoom = 1.0;
    this.controls.target.set(0, 0, 0);
    this.camera.position.set(0, 0, 10);
    this.camera.bottom = -1;
    this.camera.top = 1;
    this.camera.right = aspect;
    this.camera.left = -1 * aspect;
    this.camera.updateProjectionMatrix();
    this.camera.updateMatrix();
  }

  zoomInFunction = (e) => {
    this.camera.zoom *= 1.1;
    this.camera.updateProjectionMatrix();
  };

  zoomOutFunction = (e) => {
    this.camera.zoom /= 1.1;
    this.camera.updateProjectionMatrix();
  };

  clickZoom = (value, zoomType) => {
    if (value >= 20 && zoomType === "zoomIn") {
      return value - 5;
    } else if (value <= 75 && zoomType === "zoomOut") {
      return value + 5;
    } else {
      return value;
    }
  };

  getFov = () => {
    return Math.floor(
      (2 *
        Math.atan(this.camera.getFilmHeight() / 2 / this.camera.getFocalLength()) *
        180) /
      Math.PI,
    );
  };

  selectStreet(uuid, placesNumbers) {
    if (!this.city) {
      return;
    }
    this.city.selectStreet(uuid);
    if (this.viewType === HOUSES_VIEW_TYPE) {
      this.city.updateHousesBySelectedStreet(placesNumbers);
      this.moveCameraToCenter();
    }
  }

  selectAllHouses(data) {
    const street = this.city.getSelectedStreet(data.streetId);
    street.updateAllHousesSelectionView(data.isSelectAll);
  }

  updatePlacesNumbers(numbers) {
    if (!this.city) {
      return;
    }

    this.city.updateHousesBySelectedStreet(numbers);
  }
}
