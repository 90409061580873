import React, { useContext } from "react";
import {
  AppBar,
  Box,
  Drawer,
  Hidden,
  List,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import IconButtons from '../../../UI/IconButtons';
import ShopLogo from "../ShopLogo";
import UIContext from "../context/UIContext";
import ComplexListItem from './ComplexListItem';
import SimpleListItem from './SimpleListItem';
import {colors} from '../../../constants';
import { LanguageContext } from "../context/LanguageContext";

export const menuItemActiveCss = 'linear-gradient(268.39deg, rgba(132, 56, 192, .3) 0%, rgba(57, 69, 174, .3) 100%)';

const iconCommonCss = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: 'white',
  borderRadius: '16px',
}

const useStyles = makeStyles(theme => ({
  closeButton: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.darkBlue500,
    "&:hover": {
      backgroundColor: theme.palette.colors.darkBlue600,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: theme.palette.colors.darkBlue500,
      },
    },
  },
  icon: {
    ...iconCommonCss,
    minWidth: 32,
    height: 32,
    marginRight: '20px',
    '& .MuiSvgIcon-root': {
      maxHeight: 16,
      maxWidth: 20,
      color: colors.black,
    }
  },
  iconRtl: {
    ...iconCommonCss,
    minWidth: 32,
    height: 32,
    marginLeft: '20px',
    '& .MuiSvgIcon-root': {
      maxHeight: 16,
      maxWidth: 20,
      color: colors.black,
    }
  },
  iconCollapseRtl: {
    ...iconCommonCss,
    minWidth: 26,
    height: 26,
    marginLeft: '16px',
    '& .MuiSvgIcon-root': {
      maxHeight: 14,
      maxWidth: 18,
      color: colors.black,
      marginRight: '0 !important',
    }
  },
  iconCollapse: {
    ...iconCommonCss,
    minWidth: 26,
    height: 26,
    marginRight: '16px',
    '& .MuiSvgIcon-root': {
      maxHeight: 14,
      maxWidth: 18,
      color: colors.black,
      marginRight: '0 !important',
    }
  },
  toolbar: {
    paddingLeft: '40px',
    paddingRight: theme.spacing(2),
  },
  listItemRoot: {
    height: 52,
    paddingLeft: '40px',
    paddingRight: theme.spacing(2),
    "&$focusVisible": {
      color: theme.palette.text.secondaryActive,
      fontWeight: theme.typography.fontWeightSemiBold,
      backgroundColor: theme.palette.colors.darkBlue600,
    },
    "&$selected, &$selected:hover": {
      color: theme.palette.text.secondaryActive,
      fontWeight: theme.typography.fontWeightSemiBold,
      background: menuItemActiveCss,
    },
    "&$selected $icon, &$selected:hover $icon": {
      '& .MuiSvgIcon-root': {
        color: '#4270FF',
      }
    },
  },
  listItemRootRtl: {
    height: 52,
    paddingRight: '40px',
    paddingLeft: theme.spacing(2),
    "&$focusVisible": {
      color: theme.palette.text.secondaryActive,
      fontWeight: theme.typography.fontWeightSemiBold,
      backgroundColor: theme.palette.colors.darkBlue600,
    },
    "&$selected, &$selected:hover": {
      color: theme.palette.text.secondaryActive,
      fontWeight: theme.typography.fontWeightSemiBold,
      background: menuItemActiveCss,
    },
    "&$selected $icon, &$selected:hover $icon": {
      '& .MuiSvgIcon-root': {
        color: '#4270FF',
      }
    },
  },
  listItemText: {
    fontFamily: 'Fredoka',
    paddingLeft: 0,
    fontSize: '18px',
    fontWeight: 500,
    color: 'white',
  },
  listItemTextRtl: {
    fontFamily: 'Fredoka',
    paddingLeft: 0,
    fontSize: '18px',
    fontWeight: 500,
    color: 'white',
    textAlign: 'right',
  },
  listItemButton: {
    "&:hover": {
      textDecoration: "none",
      background: menuItemActiveCss,
    },
  },
  /* Pseudo-class applied to the `component`'s `focusVisibleClassName` prop if `button={true}`. */
  focusVisible: {},
  /* Pseudo-class applied to the root element if `selected={true}`. */
  selected: {},
  nestedListItem: {
    paddingLeft: theme.spacing(4),
    paddingTop: "3px",
    paddingBottom: "3px",
    // TODO eventually figure out what theme color to use for this
    backgroundColor: menuItemActiveCss,
  },
  drawer: {
      background: 'linear-gradient(199.68deg, #2352C6 -10.17%, #E988F1 109.02%)',
      borderRadius: '0 40px 40px 0',
      width: 370,
  },
  drawerRtl: {
      background: 'linear-gradient(199.68deg, #2352C6 -10.17%, #E988F1 109.02%)',
      borderRadius: '40px 0 0 40px',
      width: 370,
  },
  appBar: {
    background: 'transparent',
  },
  brick: {
    position: 'absolute',
    left: 0,
    width: '5px',
    height: '36px',
    background: 'white',
    borderRadius: '0 10px 10px 0',
  },
  brickRtl: {
    position: 'absolute',
    right: 0,
    width: '5px',
    height: '36px',
    background: 'white',
    borderRadius: '10px 0 0 10px',
  },
  chevron: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      maxHeight: 14,
    }
  }
}));

/**
 * Navigation Drawer component
 * @returns {React.Component} NavigationDrawer component
 */
function NavigationDrawer({ routes: primaryRoutes, viewer }) {
  const classes = useStyles();
  const { isMobile, isNavigationDrawerOpen, onCloseNavigationDrawer } = useContext(UIContext);
  const { isRtl } = useContext(LanguageContext);

  let drawerProps = {
    classes: {
      paper: isRtl ? classes.drawerRtl : classes.drawer,
    },
    open: true,
    variant: "persistent",
    anchor: isRtl ? 'right' : 'left',
  };

  if (isMobile) {
    drawerProps = {
      variant: "temporary",
      anchor: "left",
      open: isNavigationDrawerOpen,
      onClose: onCloseNavigationDrawer,
      ModalProps: {
        keepMounted: true, // Better open performance on mobile.
      },
    };
  }

  // Form the list item that will be rendered. Some will be
  // simple buttons (i.e. <SimpleListItem/>),
  // others will be a collapse containing multiple buttons (
  // i.e. A <ComplexListItem> which contains multiple <SimpleListItem/>).
  const listItemsToRender = [];
  const alreadyRenderedCollapses = [];

  for (let route of primaryRoutes) {
    const { IconComponent, href, path, navigationItemLabel, collapse = null } = route;

    // If the route specifies "route.badge", we read viewer[route.badge]
    // to determine the value to place inside the Badge
    let badgeContent = 0;
    if (route?.badge) {
      badgeContent = route.badge in viewer ? viewer[route.badge] : 0;
    }

    let numberOfRoutesUnderSameCollapse = 0;
    if (route?.collapse?.title) {
      numberOfRoutesUnderSameCollapse = primaryRoutes.filter(
        el => el?.collapse?.title === route.collapse.title
      ).length;
    }

    // Check if this route has a `collapse` property AND there are more than 1
    // route with this `collapse.title`.
    // If so -> group all routes with the same collapse.title together
    // in a <Collapse>.
    // If not -> render it as a simple <ListItem>.
    if (!collapse || numberOfRoutesUnderSameCollapse === 1) {
      listItemsToRender.push(
        <SimpleListItem
          key={path}
          path={path}
          href={href}
          badgeContent={badgeContent}
          IconComponent={IconComponent}
          navigationItemLabel={navigationItemLabel}
          classes={classes}
        />
      );
    } else {
      // If this collapse was already tackled and rendered (i.e. this item
      // was already rendered within this collapse) --> skip it
      if (!alreadyRenderedCollapses.includes(collapse.title)) {
        // Add the collapse we just rendered so we don't re-render it again
        alreadyRenderedCollapses.push(collapse.title);

        const subListItemsToRender = [];

        for (let subRoute of primaryRoutes) {
          let subRouteBadgeContent = 0;
          if (subRoute?.badge) {
            subRouteBadgeContent = subRoute.badge in viewer ? viewer[subRoute.badge] : 0;
          }

          if (subRoute?.collapse?.title === collapse.title) {
            subListItemsToRender.push(
              <SimpleListItem
                key={subRoute.path}
                path={subRoute.path}
                href={subRoute.href}
                badgeContent={subRouteBadgeContent}
                IconComponent={subRoute.IconComponent}
                navigationItemLabel={subRoute.navigationItemLabel}
                isNested={true}
                classes={classes}
              />
            );
          }
        }

        listItemsToRender.push(
          <ComplexListItem
            key={collapse.title}
            label={collapse.title}
            IconComponent={collapse.IconComponent}
            simpleListItems={subListItemsToRender}
            classes={classes}
          />
        );
      }
    }
  }

  return (
    <Drawer {...drawerProps}>
      <AppBar color="secondary" elevation={0} position="sticky" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Box flex={1} marginRight={2}>
            <ShopLogo className={classes.shopLogo} shouldShowShopName size={32} />
          </Box>

          <Hidden mdUp>
            <IconButtons
              handleClick={onCloseNavigationDrawer}
              type='close'
            />
          </Hidden>
        </Toolbar>
      </AppBar>
      <List disablePadding>{listItemsToRender}</List>
    </Drawer>
  );
}

export default NavigationDrawer;
