import moment from "moment";
import React from "react";

const camelToSpaceCase = str => str.replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`);
export const jsonFormat = str => JSON.stringify(JSON.parse(str), null, 2); // spacing level = 2

export const modifyData = (data, method, options) => {
  return data.map(item => {
    const result = {
      id: item._id,
      shopName: item.shop?.name || '',
      activity: camelToSpaceCase(item.operationName),
      merchant: item.company.name,
      email: `${item.account?.primaryEmailAddress} - ${item.account?.name}`,
      variables: jsonFormat(item.variables),
      query: item.query,
      createdAt: moment(item.createdAt).format("DD.MM.YYYY"),
    }

    // if table
    if (method !== 'export' && options) {
      result.options = options;
      return result;
    }

    // if 'export' button
    result.__typename = item.__typename;
    return result;
  });
}