import styled from "styled-components";

const LayoutContent = styled("div")`
  width: 100%;
  padding: 0 30px 30px;
  box-sizing: border-box;
  height: 100%;
  @media (max-width: 500px) {
    padding: 0 20px;
    width: 100vw;
  }
`;

export default LayoutContent;
