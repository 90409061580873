import React, { useCallback, useEffect, useState } from "react";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import { Controller, useForm } from "react-hook-form";
import FormInput from "../../../UI/Form/FormInput";
import { Form } from '../../../UI/Form/MyForm';
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Select from "../../../UI/Select";
import Button from "../../../UI/Button/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import { TransactionSchema } from "../utils/schemas";
import { subscriptionHistoryRecords, transactionQuery } from "../graphql/queries";
import { createTransactionGQL, updateTransactionGQL } from "../graphql/mutations";
import moment from "moment";
import Dialog from "../../../UI/Dialog/Dialog";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import StyledButton from "../../../UI/Button/Button";
import MerchantSelectorSingle from "../../../package/src/MerchantSelectorWithData/MerchantSelectorSingle";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import useTransactions from "../hooks/useTransactions";
import { useTranslation } from "react-i18next";

const TransactionsForm = ({transactionId, page, declineAction, refetch, viewer}) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState({});

  const { typeOptions, directionOptions} = useTransactions();

  const { control, handleSubmit, formState: { errors, isDirty }, watch, getValues, setValue } = useForm({
    resolver: yupResolver(TransactionSchema),
    defaultValues: {
      merchant: '',
      type: 'commission',
      subscription: '',
      amount: 0,
      paymentDirection: 'godmode',
      notes: '',
      godmode: false,
    },
  });

  const watchType = watch('type');
  const watchMerchants = watch('merchant');
  const watchGodmode = watch('godmode');

  useEffect(() => {
    setSubscriptionsLoading(true);
    setValue('subscription', '');

    apolloClient.query({
      query: subscriptionHistoryRecords,
      variables: {
        // The selected companyId if godmode, our current companyId if merchant
        // since a merchant can only pay from himself
        companyIds: watchGodmode ? [getValues('merchant')] : [viewer.companyId],
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const subscriptionsData = res?.data?.subscriptionHistoryRecords.nodes;
        if (!subscriptionsData) {
          return;
        }
        const options = subscriptionsData.map(node => {
            if (node.paymentStatus !== "pending") return null;

            return {
              name: `${node._id}: ${moment(node.date).format("MMMM Do YYYY, h:mm:ss a")} - $${
                node.paymentAmount
              } - ${node.description}`,
              value: node._id,
            };
          }
        );
        // Filter out `null` entries (i.e. the ones that are already settled)
        setSubscriptionOptions(options.filter(option => !!option));
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setSubscriptionsLoading(false))
  }, [watchMerchants, t])

  useEffect(() => {
    const isGodmode = viewer.type === "godmode";
    setValue('godmode', isGodmode);

    if (page !== 'edit') {
      return;
    }

    setLoading(true);
    apolloClient.query({
      query: transactionQuery,
      variables: {
        id: transactionId,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const data = res?.data?.transactionBetweenMerchantAndGodmode;
        if (!data) {
          return;
        }

        const merchantIds = data.companyId ? [data.companyId] : [];

        setValue('merchant', merchantIds[0] || '');
        setValue('type', data.type || 'commission');
        setValue('subscription', data.subscriptionId || '');
        setValue('amount', data.amount || 0);
        setValue('paymentDirection', data.recipient || 'godmode');
        setValue('notes', data.notes || '');
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setLoading(false))
  }, [t])

  const handleNumberChange = (e, func) => {
    let value = e.target.value;
    if (value < 0) value = 0;
    func(value);
  }

  const mutate = useCallback((payload) => {
    if (page === 'edit') {
      return apolloClient.mutate({
        mutation: updateTransactionGQL,
        variables: {
          id: transactionId,
          input: payload,
        },
      })
    }
    return apolloClient.mutate({
      mutation: createTransactionGQL,
      variables: {
        input: payload,
      },
    })
  }, [transactionId, page])

  const onSubmit = (formData) => {
    if (watchGodmode) {
      confirmSubmit(formData);
    } else {
      setFormData(formData);
      setDialogOpen(true);
    }
  }

  const confirmSubmit = useCallback((formData) => {
    setLoading(true);

    const payload = {
      type: formData.type,
      companyId: watchGodmode ? formData.merchant : viewer.companyId,
      recipient: formData.type === "subscription" ? "godmode" : formData.paymentDirection,
      subscriptionId: formData.subscription,
      paymentMethod: watchGodmode ? "offline" : "online",
      amount: Number(formData.amount),
      notes: formData.notes,
    };

    // A merchant can only pay to godmode
    if (!watchGodmode) {
      payload.recipient = "godmode";
    }

    mutate(payload)
      .then(() => {
        enqueueSnackbar(page === 'edit' ? t('finance.transaction_edit_success') : t('finance.transaction_create_success'), {variant: 'success'});
        setLoading(false);
        declineAction();
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
        setLoading(false);
      })
  }, [mutate, viewer, watchGodmode, t])

  const closeDialog = () => {
    setDialogOpen(false);
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormHeader>{page === 'create' ? t('finance.create_transaction') : t('finance.edit_transaction')}</FormHeader>

        {
          watchGodmode &&
          <>
            <InputLabel error={errors.merchant} disabled={loading}>{t('finance.select_merchant')}</InputLabel>
            <Controller
              name='merchant'
              control={control}
              render={({ field }) => <MerchantSelectorSingle
                value={field.value}
                onChange={field.onChange}
                disabled={loading}
                viewer={viewer}
                error={errors.merchant}
              />}
            />
          </>
        }

        <InputLabel disabled={loading}>{t('finance.transaction_type')}</InputLabel>
        <Controller
          name="type"
          control={control}
          render={({ field }) => <Select
            placeholder={t('finance.choose_type')}
            value={field.value}
            onChange={field.onChange}
            options={typeOptions}
            disabled={loading}
          />}
        />

        {
          watchType === 'subscription' ?
          <>
            <InputLabel disabled={loading || subscriptionsLoading} error={errors.subscription}>
              {t('finance.subscription')}
            </InputLabel>
            <Controller
              name="subscription"
              control={control}
              render={({ field }) => <Select
                placeholder={t('finance.choose_subscription')}
                value={field.value}
                onChange={field.onChange}
                options={subscriptionOptions}
                disabled={loading || subscriptionsLoading}
                error={errors.subscription}
              />}
            />
          </> :
          <>
            {
              watchGodmode &&
              <>
                <InputLabel disabled={loading}>{t('finance.payment_direction')}</InputLabel>
                <Controller
                  name="paymentDirection"
                  control={control}
                  render={({ field }) => <Select
                    placeholder={t('finance.choose_payment_direction')}
                    value={field.value}
                    onChange={field.onChange}
                    options={directionOptions}
                    disabled={loading}
                  />}
                />
              </>
            }
            <InputLabel optionalText='USD' disabled={loading}>{t('finance.amount')}</InputLabel>
            <Controller
              control={control}
              name='amount'
              render={({ field }) => <FormInput
                value={field.value}
                onChange={(e) => handleNumberChange(e, field.onChange)}
                type='number'
                disabled={loading}
              />}
            />
          </>
        }



        <InputLabel optionalText={t('ui.optional')} disabled={loading}>
          {t('finance.notes')}
        </InputLabel>
        <Controller
          name="notes"
          control={control}
          render={({ field }) => <FormInput
            placeholder={t('finance.write_note_here')}
            multiline
            rows={4}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
          />}
        />

        <SaveButtons>
          <Button
            disabled={!isDirty || loading}
            type='submit'
            width='180px'
          >
            {page === 'edit' ? t('ui.save_changes') : t('ui.create')}
          </Button>
          <Button
            mytype='third'
            width='180px'
            handleClick={declineAction}
            disabled={loading}
          >
            {t('ui.cancel')}
          </Button>
        </SaveButtons>
      </Form>

      <Dialog
        open={dialogOpen}
        handleClose={closeDialog}
      >
        <DialogMessages
          title={t('finance.confirm_payment')}
          message={t('finance.confirm_payment_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              closeDialog();
              confirmSubmit(formData);
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          <StyledButton
            width='180px'
            mytype='secondary'
            handleClick={closeDialog}
          >
            {t('ui.no')}
          </StyledButton>
        </DialogButtons>
      </Dialog>
    </>
  );
}

export default TransactionsForm;
