import {IconNavOrders} from "../../icons";

import Orders from "./components/OrdersTable";
import Order from "./containers/OrderContainer";
import OrderPrint from "./components/OrderPrint";
import "./helpers";
import { constants } from "../../constants.js";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute }) {
  // Register order related routes
  /*
   * Single order page route
   */
  registerRoute({
    path: "/orders/:shopId/:_id",
    MainComponent: Order,
    showShopSelector: false,
    layoutComponentProps: {
      size: "standard",
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  /*
   * Single order print layout route
   */
  registerRoute({
    path: "/orders/print/:shopId/:_id",
    MainComponent: OrderPrint,
    showShopSelector: false,
    layoutComponentProps: {
      size: "standard",
    },
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });

  /*
   * Orders table route
   */
  registerRoute({
    group: "navigation",
    priority: 10,
    layoutComponentProps: {
      size: "wide",
    },
    MainComponent: Orders,
    path: "/orders",
    IconComponent: IconNavOrders,
    // navigationItemLabel: "admin.dashboard.ordersLabel",
    navigationItemLabel: 'menu.orders',
    showShopSelector: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeAccountManager,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
      constants.ACCOUNT_TYPES.merchantAdmin,
      constants.ACCOUNT_TYPES.merchantAssistantAdmin,
      constants.ACCOUNT_TYPES.merchantFinancialManager,
      constants.ACCOUNT_TYPES.merchantShopFinancialManager,
    ],
  });
}
