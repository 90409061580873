import styled from "styled-components";

const LayoutContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr 1fr;
  overflow-y: auto;
  position: relative;
`;

export default LayoutContainer;
