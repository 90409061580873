import React from 'react';
import {IconButton} from '@material-ui/core';
import svgArrowDown from '../../icons/arrow-down.svg';
import svgDelete from '../../icons/delete.svg';
import svgDeleteGrey from '../../icons/delete-grey.svg';
import svgDots from '../../icons/dots.svg';
import svgClose from '../../icons/close.svg';
import svgEdit from "../../icons/table-edit.svg";
import styled, {css} from 'styled-components';
import {colors} from '../../constants';
import PropTypes from "prop-types";
import Icon from '../Icon';

const deleteCss = css`
  box-shadow: 0px 7px 20px rgba(199, 149, 158, 0.25);
  background: white;
  width: 42px;
  height: 42px;
  filter: none;

  &:hover {
    background: white;
  }
`

const deleteSimpleCss = css`
  box-shadow: none;
  background: white;
  width: 42px;
  height: 42px;
  filter: none;

  &:hover {
    background: white;
  }
`

const deleteImageCss = css`
  box-shadow: none;
  background: ${colors.brizeLight};
  width: 42px;
  height: 42px;
  filter: none;
  border-radius: 11px;

  &:hover {
    background: ${colors.brizeLight};
  }
`

const dotsCss = css`
  filter: none;
  background: transparent;
  width: 28px;
  height: 28px;

  &:hover {
    background: transparent;
  }
`

const disabledCss = css`
  &.MuiIconButton-root {
    background-color: ${colors.backgroundGrey};
    filter: none;
  }
`

const StyledIconButton = styled(IconButton)`
  background: ${colors.blue};
  width: 70px;
  height: 70px;
  filter: drop-shadow(5px 7px 30px rgba(78, 102, 178, 0.5));

  &:hover {
    background: ${colors.blue};
  }

  ${props => {
    switch(props.mytype) {
      case 'delete': 
      case 'close': return deleteCss;
      case 'dots': return dotsCss;
      case 'edit':
      case 'deleteSimple': return deleteSimpleCss;
      case 'deleteImage': return deleteImageCss;
      default: return null;
    }
  }}
  
  ${props => props.disabled && disabledCss}
`

const IconButtons = ({handleClick, type = 'delete', disabled, ...other }) => {
  const getSvg = () => {
    switch(type) {
      case 'download': return svgArrowDown;
      case 'dots': return svgDots;
      case 'close': return svgClose;
      case 'deleteImage': return svgDeleteGrey;
      case 'edit': return svgEdit;
      default: return svgDelete;
    }
  }

  return (
    <StyledIconButton
      onClick={(e) => handleClick && handleClick(e)}
      mytype={type}
      disabled={disabled}
      {...other}
    >
      <Icon>
        <img src={getSvg()} alt=''/>
      </Icon>
    </StyledIconButton>
  );
}

IconButtons.propTypes = {
  handleClick: PropTypes.func,
  type: PropTypes.oneOf(['download', 'delete', 'dots', 'close', 'deleteSimple', 'deleteImage', 'edit']),
}

export default IconButtons;