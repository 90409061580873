import React, { useCallback, useMemo, useState } from "react";
import Toolbar from "../../../package/src/Toolbar";
import { Grid, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import Search from "../../../UI/Search/Search";
import StyledButton from "../../../UI/Button/Button";
import IconButtons from "../../../UI/IconButtons";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { getAllShoppers } from "../graphql/queries";
import svgEdit from "../../../icons/reset.svg";
import svgDelete from "../../../icons/delete.svg";
import svgBlock from "../../../icons/block.svg";
import svgUnblock from "../../../icons/unlock.svg";
import DataTable from "../../../UI/DataTable/DataTable";
import Dialog from "../../../UI/Dialog/Dialog";
import FormShopper from "../components/FormShopper";
import downloadCSV from "../../../package/src/utils/downloadCSV";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import { blockShopper, removeShopper, resetPassword, unblockShopper } from "../graphql/mutations";
import { gdprDistortMutation } from "../../settings/graphql/mutations";
import BlockShopper from "../components/BlockShopper";
import MenuItem from "../../../UI/MenuItem/MenuItem";
import FormControlLabel from "../../../UI/FormControlLabel/FormControlLabel";
import Dropdown from "../../../UI/Dropdown";
import { useShoppersColumns } from "../hooks/useShoppersColumns";
import { useTranslation } from "react-i18next";
import { constants } from "../../../constants";
import useModifiers from "../hooks/useModifiers";

const defaultFilters = {
  status: '',
};

const useStyles = makeStyles(theme => ({
  columnLeft: {
    display: 'flex',
    gap: 20,
  },
  flexEnd: {
    display: 'flex',
    gap: 20,
    justifyContent: 'flex-end',
  },
  flexStart: {
    display: 'flex',
    gap: 20,
  },
}));


const ShoppersPage = () => {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { modifyData } = useModifiers();

  const [searchValue, setSearchValue] = useState('');
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [rowId, setRowId] = useState('');
  const [filters, setFilters] = useState(defaultFilters);
  const { columns } = useShoppersColumns();

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy, filtersByKey }) => {
      setIsLoading(true);

      apolloClient.query({
        query: getAllShoppers,
        variables: {
          shopIds: [],
          filter: globalFilter,
          first: pageSize,
          //limit: (pageIndex + 1) * pageSize,
          offset: pageIndex * pageSize,
          sortOrder,
          sortBy,
          ...(filtersByKey.status ? {
            status: [filtersByKey.status],
          } : {}),
        },
        fetchPolicy: "network-only",
      })
        .then(res => {
          const total = res.data.shoppers.totalCount;
          setTableData(res.data.shoppers.nodes);
          setPageCount(Math.ceil(total / pageSize));
          setTotalCount(total);
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setIsLoading(false))
    }, [t]);

  const handleUnblockShopper = (rowId) => {
    setIsLoading(true);

    apolloClient.mutate({
      mutation: unblockShopper,
      variables: {
        input: {
          id: rowId,
        }
      }
    })
      .then(() => {
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
        setIsLoading(false);
      })
  }

  const shoppersOptions = useMemo(() => [
    {
      value: constants.OPTIONS.resetPassword,
      label: t('shoppers.reset_password'),
      icon: svgEdit,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('reset');
        setDialogOpen(true);
      },
    },
    {
      value: constants.OPTIONS.blockShopper,
      label: t('shoppers.block_shopper_lowercase'),
      icon: svgBlock,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('block');
        setDialogOpen(true);
      },
    },
    {
      value: constants.OPTIONS.unblockShopper,
      label: t('shoppers.unblock_shopper'),
      icon: svgUnblock,
      handleOptionClick: (rowId) => {
        handleUnblockShopper(rowId);
      },
    },
    {
      value: constants.OPTIONS.deleteShopper,
      label: t('shoppers.delete_shopper'),
      icon: svgDelete,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('deleteSingle');
        setDialogOpen(true);
      },
    },
  ], [t])

  const handleCloseDialog = () => {
    setDialogOpen(false);
  }

  const handleInviteShopper = () => {
    setDialogName('invite');
    setDialogOpen(true);
  }

  const dataProps = useMemo(() => {
    return modifyData(tableData, '', shoppersOptions);
  }, [tableData, modifyData])

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const handleDeleteShopper = () => {
    setDialogName('delete');
    setDialogOpen(true);
  }

  const handleBlockShopper = (date) => {
    setIsLoading(true);

    apolloClient.mutate({
      mutation: blockShopper,
      variables: {
        input: {
          id: rowId,
          ...(date.endDate || date.startDate ? {
            blockingPeriod: {
              start: date.startDate,
              end: date.endDate,
            }
          }: {}),
        }
      }
    })
      .then(() => {
        handleCloseDialog();
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
        setIsLoading(false);
      })
  }

  const exportData = useCallback(() => {
    setExporting(true);
    apolloClient.query({
      query: getAllShoppers,
      variables: {
        shopIds: [],
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        const result = modifyData(res.data.shoppers.nodes, 'export', null);
        const fields = columns.filter(item => item.name !== 'options');
        downloadCSV(fields, result);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setExporting(false))
  }, [t, modifyData])

  const renderNoButton = useMemo(() =>
      <StyledButton
        width='180px'
        mytype='secondary'
        handleClick={handleCloseDialog}
      >
        {t('ui.no')}
      </StyledButton>
    , [t])

  const DialogResetPassword = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={t('shoppers.reset_password_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              handleCloseDialog();
              apolloClient.mutate({
                mutation: resetPassword,
                variables: {
                  id: rowId,
                }
              })
                .then(res => {
                  if (!res.data.resetShopperPassword || res.data.resetShopperPassword === 'error') {
                    throw new Error('resetShopperPassword error');
                  } else {
                    enqueueSnackbar(t('shoppers.reset_password_success'), { variant: 'success' });
                  }
                })
                .catch((e) => {
                  enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                  console.log(e.message);
                })
            }}
          >
            {t('ui.no')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [rowId, t])

  const deleteShopper = (ids) => {
    return apolloClient.mutate({
      mutation: removeShopper,
      variables: {
        id: ids,
      },
    })
      .then(() => {
        return apolloClient.mutate({
          mutation: gdprDistortMutation,
          variables: {
            accountIds: ids,
          }
        });
      });
  }

  const DialogDeleteSingle = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('ui.confirm')}
          message={t('shoppers.delete_shopper_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              handleCloseDialog();
              deleteShopper([rowId])
                .then(() => {
                  refetch();
                  enqueueSnackbar(t('shoppers.delete_shopper_success'), {variant: 'success'});
                })
                .catch((e) => {
                  enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                  console.log(e.message);
                })
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    )
  }, [rowId, t])

  const DialogDelete = useMemo(() => {
    return (
      <>
        <DialogMessages
          title={t('shoppers.delete_shoppers_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={async () => {
              handleCloseDialog();
              deleteShopper(selectedRows)
                .then(() => {
                  refetch();
                  enqueueSnackbar(t('shoppers.delete_shoppers_success'), {variant: 'success'});
                })
                .catch((e) => {
                  enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
                  console.log(e.message);
                })
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          {renderNoButton}
        </DialogButtons>
      </>
    );
  }, [selectedRows, t]);

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'invite': return <FormShopper
        declineAction={handleCloseDialog}
      />;
      case 'reset': return DialogResetPassword;
      case 'deleteSingle': return DialogDeleteSingle;
      case 'delete': return DialogDelete;
      case 'block': return <BlockShopper
        onAccept={handleBlockShopper}
        loading={isLoading}
      />
      default: return null;
    }
  }

  const handleFilterRadio = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const filtersList = useMemo(() => [
    {
      content: <RadioGroup
        onChange={(e) => handleFilterRadio('status', e.target.value)}
        value={filters.status}
      >
        <MenuItem><FormControlLabel value="active" control={<Radio />} label={t('shoppers.active')} /></MenuItem>
        <MenuItem><FormControlLabel value="blocked" control={<Radio />} label={t('shoppers.blocked')} /></MenuItem>
      </RadioGroup>
    },
    {
      isFooter: true,
      content: t('ui.clear_filters'),
      action: () => setFilters(defaultFilters),
    }
  ], [filters, t])

  return (
    <>
      <Toolbar title={t('shoppers.shoppers')} />
      <Grid container spacing={2}>

        <Grid item xs={12} className={classes.columnLeft}>
          <Search
            handleChange={handleSearch}
            onSearchClick={refetch}
            placeholder={t('ui.search')}
            useDebounce
          />
          <IconButtons
            handleClick={handleDeleteShopper}
            disabled={selectedRows.length === 0}
          />
          <StyledButton
            minwidth='160px'
            handleClick={handleInviteShopper}
          >
            {t('shoppers.invite_shopper')}
          </StyledButton>
        </Grid>

        <Grid item className={classes.columnLeft}>
          <Dropdown
            title={filters.status ? t(`shoppers.${filters.status}`) : t('shoppers.filter_block_status')}
            itemsList={filtersList}
          />
          <StyledButton
            width='140px'
            mytype='secondary'
            handleClick={exportData}
            disabled={exporting || !tableData.length}
          >
            {t('ui.export')}
          </StyledButton>
        </Grid>

        <DataTable
          columns={columns}
          isLoading={isLoading}
          data={dataProps}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          handleFetchData={onFetchData}
          pageCount={pageCount}
          totalCount={totalCount}
          searchValue={searchValue}
          reloadTable={reloadTable}
          defaultSortField='createdAt'
          defaultSortOrder='desc'
          filtersByKey={filters}

          // styles for 1 row upper panel + checkboxes
          maxHeight='calc(100vh - 280px)'
          maxHeightLaptop='calc(100vh - 340px)'
          maxHeightMobile='calc(100vh - 340px)'
        />

      </Grid>

      <Dialog
        open={dialogOpen}
        handleClose={handleCloseDialog}
      >
        {getDialogComponent()}
      </Dialog>
    </>
  );
}

export default ShoppersPage;
