import React, { useCallback, useEffect, useState } from "react";
import FormHeader from "../../../UI/Form/FormHeader";
import InputLabel from "../../../UI/Form/InputLabel";
import { Controller, useForm } from "react-hook-form";
import FormInput from "../../../UI/Form/FormInput";
import { Form } from '../../../UI/Form/MyForm';
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { TriggerSchema } from "../schemas";
import Select from "../../../UI/Select/Select";
import DateRangeSelector from "../../../package/src/DateRangeSelector";
import {Grid} from '@material-ui/core';
import ProductSelectorWithData from "../../../package/src/ProductSelectorWithData";
import Button from "../../../UI/Button/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import discountCodeQuery from '../graphql/queries/discountCode';
import createDiscountCode from "../graphql/mutations/createDiscountCode";
import updateDiscountCode from "../graphql/mutations/updateDiscountCode";
import formatDateRange from "../../../package/src/utils/formatDateRange";
import useDiscounts from "../hooks/useDiscounts";
import { useTranslation } from "react-i18next";

const SaleForm = ({shopId, saleId, page, enqueueSnackbar, apolloClient, declineAction, refetch}) => {
  const [loading, setLoading] = useState(false);
  const { typeOptions } = useDiscounts();
  const { t } = useTranslation();

  const { control, handleSubmit, formState: { errors, isDirty }, reset, watch } = useForm({
    resolver: yupResolver(TriggerSchema),
    defaultValues: {
      code: '',
      type: '',
      amount: 0,
      period: {startDate: null, endDate: null},
      products: [],
    },
  });

  const watchType = watch('type');

  useEffect(() => {
    if (page === 'edit') {
      setLoading(true);
      getSale()
        .then(res => {
          const data = res?.data?.discountCode;
          if (!data) {
            return;
          }
          const products = data.conditions?.productDetails?.map(product => product._id) || [];

          let type = '';
          if (data.calculation?.method === 'discount') {
            type = 'percentage';
          } else if (data.calculation?.method === 'credit') {
            type = 'flat';
          }

          const resetValues = {
            code: data.code,
            period: {
              startDate: data.conditions?.order?.startDate || null,
              endDate: data.conditions?.order?.endDate || null,
            },
            type,
            amount: data.discount || 0,
            products,
          }

          reset(resetValues);
        })
        .catch((e) => {
          enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
          console.log(e.message);
        })
        .finally(() => setLoading(false))
    }
  }, [t])

  const getSale = () => {
    return apolloClient.query({
      query: discountCodeQuery,
      variables: {
        id: saleId,
      },
      fetchPolicy: "network-only",
    })
  }

  const handleNumberChange = (e, func) => {
    let value = e.target.value;
    if (value < 0) value = 0;
    func(value);
  }

  const mutateSale = useCallback((payload) => {
    if (page === 'edit') {
      return apolloClient.mutate({
        mutation: updateDiscountCode,
        variables: {
          input: {
            shopId,
            discountCodeId: saleId,
            discountCode: payload,
          },
        },
      })
    }
    return apolloClient.mutate({
      mutation: createDiscountCode,
      variables: {
        input: {
          shopId,
          discountCode: payload,
        },
      },
    })
  }, [saleId, shopId])

  const onSubmit = useCallback((formData) => {
    setLoading(true);
    const {
      code,
      period: {startDate, endDate},
      type,
      amount,
      products,
    } = formData;

    const isConditions = products.length || startDate || endDate;

    if (isConditions) {
      formData.conditions = {};
      formData.conditions.enabled = true;
      formData.conditions.products = products.length ? products : null;
    }

    if (startDate || endDate) {
      formData.conditions.order = {};
      formData.conditions.order.min = 0;

      if (startDate) {
        formData.conditions.order.startDate = startDate;
      }

      if (endDate) {
        formData.conditions.order.endDate = endDate;
      }
    }

    const payload = {
      code,
      discount: Number(amount),
      discountMethod: 'rate',
      calculation: {
        method: type === 'flat' ? 'credit' : 'discount',
      }
    }

    if (isConditions) {
      payload.conditions = formData.conditions;
    }

    mutateSale(payload)
      .then(() => {
        enqueueSnackbar(page === 'edit' ? t('discount.sale_edit_success') : t('discount.sale_create_success'), {variant: 'success'});
        setLoading(false);
        declineAction();
        refetch();
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
        setLoading(false);
      })
  }, [mutateSale, t])

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeader>{page === 'create' ? t('discount.create_sale') : t('discount.edit_sale')}</FormHeader>
      <InputLabel error={errors.code} disabled={loading}>{t('discount.sale_name')}</InputLabel>
      <Controller
        control={control}
        name='code'
        render={({ field }) => <FormInput
          placeholder={t('discount.write_sale_name')}
          value={field.value}
          onChange={field.onChange}
          error={errors.code}
          disabled={loading}
        />}
      />

      <InputLabel error={errors.type} disabled={loading}>{t('discount.type')}</InputLabel>
      <Controller
        name="type"
        control={control}
        render={({ field }) => <Select
          placeholder={t('discount.choose_type')}
          value={field.value}
          onChange={field.onChange}
          options={typeOptions}
          error={errors.type}
          disabled={loading}
        />}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <InputLabel
            error={errors.amount}
            optionalText={watchType ? (watchType === 'flat' ? '$' : '%') : undefined}
            disabled={!watchType || loading}
          >
            {t('discount.value')}
          </InputLabel>
          <Controller
            control={control}
            name='amount'
            render={({ field }) => <FormInput
              value={field.value}
              onChange={(e) => handleNumberChange(e, field.onChange)}
              type='number'
              error={errors.amount}
              disabled={!watchType || loading}
            />}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <InputLabel optionalText={t('ui.optional')} disabled={loading}>{t('discount.validity_period')}</InputLabel>
          <Controller
            name="period"
            control={control}
            render={({ field }) => <DateRangeSelector
              placeholder={t('discount.validity_period')}
              dateRange={formatDateRange(field.value)}
              onChange={field.onChange}
              disabled={loading}
            />}
          />
        </Grid>
      </Grid>

      <InputLabel optionalText={t('ui.optional')} disabled={loading}>{t('discount.products')}</InputLabel>
      <Controller
        name='products'
        control={control}
        render={({ field }) => <ProductSelectorWithData
          shouldShowShopName
          shopIds={[shopId]}
          placeholder={t('discount.choose_products')}
          selectAllLabel={t('discount.all_products')}
          value={field.value}
          onChange={field.onChange}
          isMultiSelect
          disabled={loading}
        />}
      />

      <SaveButtons style={{ marginTop: 48 }}>
        <Button
          disabled={!isDirty || loading}
          type='submit'
          width='180px'
        >
          {page === 'edit' ? t('ui.save_changes') : t('ui.create')}
        </Button>
        <Button
          mytype='third'
          width='180px'
          handleClick={declineAction}
          disabled={loading}
        >
          {t('ui.cancel')}
        </Button>
      </SaveButtons>
    </Form>
  );
}

export default SaleForm;
