import {IconNavSubPack} from '../../icons';
import SubscriptionPackagesPage from "./pages/SubscriptionPackagesPage";
import { constants } from "../../constants.js";
import SubscriptionDetails from "./components/SubscriptionDetails";

/**
 * Register plugin
 * @param {Object} params Utils for registering features of the plugin
 * @returns {undefined}
 */
export default function plugin({ registerRoute, registerBlock }) {
  // Register a route
  registerRoute({
    group: "navigation",
    priority: 80,
    path: "/subscription-packages",
    MainComponent: SubscriptionPackagesPage,
    navigationItemLabel: 'menu.subscription',
    IconComponent: IconNavSubPack,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });

  registerRoute({
    path: "/subscription-packages/create",
    MainComponent: SubscriptionDetails,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });

  registerRoute({
    path: "/subscription-packages/edit/:packageId",
    MainComponent: SubscriptionDetails,
    showShopSelector: false,
    godmodeOnly: true,
    groupSlugs: [
      constants.ACCOUNT_TYPES.godmodeAdmin,
      constants.ACCOUNT_TYPES.godmodeAssistantAdmin,
      constants.ACCOUNT_TYPES.godmodeFinancialManager,
    ],
  });
}
