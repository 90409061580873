import React from "react";
import { TextField } from "@material-ui/core";
import styled from 'styled-components';
import useInputNumber from "../../hooks/useInputNumber";

const StyledTextField = styled(TextField)`
  background-color: #F6F6F6;
  border-radius: 15px;
  height: 30px;
  width: 100%;

  & .MuiInputBase-root {
    height: 30px;
    color: #BFBFBF;
    font-family: Mulish, sans-serif;
    font-size: 16px;
    font-weight: 400;

    input {
      padding: 0 8px 0 16px;

      &:focus {
        color: #222;
      }
    }
  }

  fieldset {
    border: none;
  }
`;

const CellComponentInput = ({ value, onChange, type = 'text', isFloatNumber, ...props}) => {
  const { innerValue, handleChange, handleConfirm } = useInputNumber({
    value,
    onChange,
    type,
    isFloatNumber,
  });

  return (
    <StyledTextField
      variant="outlined"
      value={innerValue}
      onChange={handleChange}
      onBlur={handleConfirm}
      type={type}
      inputProps={{
        maxLength: 32,
      }}
      {...props}
    />
  );
};

export default CellComponentInput;
