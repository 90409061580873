import { styled } from '@mui/material';
import InputErrorMessage from "../../../../UI/Form/InputErrorMessage";

const Wrapper = styled('div')`
  width: 100%;
  border: 1px solid #DF5656;
  background: rgba(223, 86, 86, 0.1);
  padding: 13px 22px;
  margin: 16px 0;
  box-sizing: border-box;
  
  > p {
    margin: 0;
  }
  
  @media all and (max-width: 768px) {
    padding: 10px 16px;
  }
`;

const RegError = ({ text }) => {
  return (
    <Wrapper>
      <InputErrorMessage>{text}</InputErrorMessage>
    </Wrapper>
  );
};

export default RegError;
